import React from 'react';
import MenuInDrawer from './MenuInDrawer';
import { TConfigMenu } from '../config_menu';
import { useQueryCustom } from 'hooks';
import { GET_ALL_UNREAD_PATIENT_COMMENT } from 'gql';
import {
  GetAllUnreadPatientCommentQuery,
  GetAllUnreadPatientCommentQueryVariables,
} from 'types.d';
import { useCustomPermission, useCheckLogin } from 'hooks';
type Props = {
  item: TConfigMenu;
  hasPadding: boolean;
};

export const PatientQuestion: React.FC<Props> = ({ item, hasPadding }) => {
  const isLogin = useCheckLogin();

  const { isUser } = useCustomPermission();
  //get all unread from patient(apply for navigator)
  const { data: unreadCommentPatient } = useQueryCustom<
    GetAllUnreadPatientCommentQuery,
    GetAllUnreadPatientCommentQueryVariables
  >({
    api: GET_ALL_UNREAD_PATIENT_COMMENT,
    fetchPolicy: 'cache-only',
    skip: !isLogin || isUser,
  });

  return (
    <MenuInDrawer
      unreadCommentPatient={
        unreadCommentPatient?.getUnreadCommentsPatientList?.edges[0]?.node
          ?.unreadCount || 0
      }
      hasPadding={hasPadding}
      detail={item}
    />
  );
};

export default React.memo(PatientQuestion);
