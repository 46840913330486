import React, { useRef, useMemo, useContext } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Tooltip, Box } from '@material-ui/core';
import { formatDate, isOnLastWeek } from 'share/utils';
import { surveyColorTag, renderOutline } from 'share/utils';
import { TypoThreeDot, ChipStyled, TypographyBold } from 'share/component_css';
import { SurveyResultEmbeddedFragment } from 'types.d';
import { usePopover, useToogleDialog } from 'hooks';
import DialogAllQuestions from '../DialogAllQuestions';
import { PatientTagsContext } from 'share/context';
import PopoverSurveyResult from '../Survey/PopoverSurveyResult';

type Props = {
  item: SurveyResultEmbeddedFragment;
};

export const SurveyResultTag: React.FC<Props> = ({ item }) => {
  const [open, close, DefaultPopover, defaultPopoverProps] = usePopover();

  const [isShowDialog, setIsShowDialog] = useToogleDialog();

  const isUpdate = useRef(false);

  const surveyResultSelected = useRef<SurveyResultEmbeddedFragment | undefined>(
    undefined,
  );

  const patientTagsContext = useContext(PatientTagsContext);

  //check survey tab is opened
  const isOnTab = useMemo(() => {
    return patientTagsContext?.isExtend && patientTagsContext?.indexTab === 3;
  }, [patientTagsContext]);

  const openDialogSurvey = (setIsUpdate: boolean) => {
    isUpdate.current = setIsUpdate;
    close();
    setIsShowDialog(true);
  };

  const handleOpenUpdate = () => {
    if (isOnTab) {
      patientTagsContext?.handleSetDialogOpen(`editSurveyResult`, item);
      return;
    }
    openDialogSurvey(true);
  };

  return (
    <>
      {isShowDialog && (
        <DialogAllQuestions
          isUpdate={isUpdate.current}
          detailSurveyResult={item}
          idSurvey={item.surveyDetail?.surveyId}
          open={isShowDialog}
          toogleDialog={setIsShowDialog}
          surveyName={item.surveyDetail?.name}
          isExportPdf
        />
      )}
      <DefaultPopover {...defaultPopoverProps}>
        <PopoverSurveyResult
          item={surveyResultSelected.current}
          close={close}
          openDialogSurvey={openDialogSurvey}
        />
      </DefaultPopover>
      <ChipStyled
        className={`cursor-pointer ${renderOutline(item)}`}
        onClick={handleOpenUpdate}
        label={
          <Tooltip
            title={
              <Box display="flex" flexDirection="column">
                {patientTagsContext?.generateInformation([
                  {
                    name: 'Filled At',
                    info: item.answerDateTime
                      ? formatDate(item.answerDateTime, true)
                      : 'N/A',
                  },
                  {
                    name: 'Created At',
                    info: formatDate(item.createdAt, true),
                  },
                  {
                    name: 'Updated At',
                    info: formatDate(item.updatedAt, true),
                  },
                  {
                    name: 'Updated By',
                    info: `${
                      item?.updatedBy
                        ? `${item.updatedBy.firstName} ${item.updatedBy
                            .middleName || ''} ${item.updatedBy.lastName}`
                        : 'N/A'
                    }`,
                  },
                  {
                    name: 'Survey Name',
                    info: item.surveyDetail?.name || '',
                  },
                  {
                    name: 'Version',
                    info: item.surveyDetail?.version?.toString() || '',
                  },
                ])}
                {isOnLastWeek(item) && (
                  <TypographyBold variant="subtitle1" align="center">
                    Survey result was done in last week
                  </TypographyBold>
                )}
              </Box>
            }
          >
            <TypoThreeDot variant="subtitle2">
              {`${item.surveyDetail?.name} (v${
                item.surveyDetail?.version
              }) - ${formatDate(item.updatedAt, true)}`}
            </TypoThreeDot>
          </Tooltip>
        }
        onDelete={e => {
          open(e);
          surveyResultSelected.current = item;
        }}
        deleteIcon={!patientTagsContext?.isInactive ? <MoreHorizIcon /> : <></>}
        backgroundcolor={surveyColorTag(item)}
      />
    </>
  );
};

export default React.memo(SurveyResultTag);
