import React, { useCallback, useContext, useRef } from 'react';
import { ChipStyled } from 'share/component_css';
import { PatientTagsContext } from 'share/context';
import AddIcon from '@material-ui/icons/Add';
import LoadingItem from './LoadingTags';
import { PatientFollowUpFragment, SurveyResultEmbeddedFragment } from 'types.d';
import { Box } from '@material-ui/core';
import { useToogleDialog } from 'hooks';
import DialogSurvey from '../Survey/DialogAddSurvey';
import SurveyResultTag from './SurveyResultTag';
import BoxShowMore from './BoxShowMore';
import { ScrollStyled } from 'components';

type Props = {
  loadingDataGroup: boolean;
  patientSurveyResult?: SurveyResultEmbeddedFragment[];
};

export const SurveyTag: React.FC<Props> = ({
  loadingDataGroup,
  patientSurveyResult,
}) => {
  const patientTagsContext = useContext(PatientTagsContext);

  const [isOpenDialogSurvey, setOpenDialogSurvey] = useToogleDialog();

  const followUpSelected = useRef<PatientFollowUpFragment | undefined>(
    undefined,
  );

  const openAddDialog = useCallback(() => {
    //open dialog from task tab
    if (patientTagsContext?.isExtend && patientTagsContext?.indexTab === 3) {
      patientTagsContext.handleSetDialogOpen('add-survey');
      return;
    }
    //open dialog from task tag
    setOpenDialogSurvey(true);
    followUpSelected.current = undefined;
  }, [patientTagsContext, setOpenDialogSurvey]);

  return (
    <>
      {isOpenDialogSurvey && (
        <DialogSurvey
          open={isOpenDialogSurvey}
          toogleDialog={setOpenDialogSurvey}
        />
      )}
      {!patientTagsContext?.isInactive && (
        <ChipStyled
          colortext="true"
          className="mr-8"
          onClick={openAddDialog}
          label={
            <Box display="flex">
              <AddIcon fontSize="small" />
            </Box>
          }
        />
      )}
      {patientSurveyResult?.length !== 0 && <BoxShowMore index={3} />}
      {loadingDataGroup && <LoadingItem />}
      {patientSurveyResult &&
        patientSurveyResult?.length > 0 &&
        !loadingDataGroup && (
          <ScrollStyled
            loading={loadingDataGroup}
            listLength={patientSurveyResult?.length}
          >
            {patientSurveyResult.map(item => {
              return <SurveyResultTag key={item._id} item={item} />;
            })}
          </ScrollStyled>
        )}
    </>
  );
};

export default React.memo(SurveyTag);
