import { useQuery } from '@apollo/react-hooks';
import { ME_CLIENT } from 'gql';
import { rolePermission } from 'CONST';
import { UserRole } from 'types.d';

export const useCheckRole = (roles?: string | string[]) => {
  const { data: dataMeClient } = useQuery(ME_CLIENT);

  const checkRole = () => {
    if (roles) {
      const allPermissionByRole = rolePermission[dataMeClient.meClient?.role];
      return allPermissionByRole
        ? allPermissionByRole.includes(roles! as string)
        : false;
    } else {
      return true;
    }
  };

  const isHavePermission = (idOwner?: string) => {
    return (
      idOwner === dataMeClient.meClient?.id ||
      dataMeClient.meClient?.role === UserRole.Admin
    );
  };
  return {
    checkRole: checkRole(),
    checkLogin: !!dataMeClient?.meClient?.email,
    role: dataMeClient?.meClient?.role,
    isHavePermission,
  };
};
