import { jsPDF } from 'jspdf';
import { font } from 'font';
import { useRef } from 'react';
import { useEffectOnlyOnce } from './useEffectOnlyOnce';
import { WIDTH_A4, LIST_GENDER, HEIGHT_A4 } from 'CONST';
import { PatientFragment, Gender } from 'types.d';
import { formatPhoneNumber, isBotMail, formatDoB } from 'share/utils';
import autoTable, { Styles, RowInput, CellHook } from 'jspdf-autotable';
import { ExtendedPatientTimeReportFragment } from 'modules/patients/components/TimeRecord/DialogBilling';
import { format } from 'date-fns';
import { formatDate, renderTime } from 'share/utils';
const colorHeadTextTable = '#252727';
const colorHeadTable = '#868688';
const colorTotal = '#e3e3e8';
const tMargin = 10;
const lMargin = 5;
const rMargin = 5;
const widthA4mm = 2480;
const heightA4Page =
  (widthA4mm * (HEIGHT_A4 - 2 * tMargin)) / WIDTH_A4 - lMargin - rMargin;

export const useCommonPdf = () => {
  let height = useRef(tMargin);
  const doc = useRef(new jsPDF('p', 'mm', 'a4'));

  useEffectOnlyOnce(() => {
    doc.current.addFileToVFS('WorkSans-normal.ttf', font);
    doc.current.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal');
    doc.current.setFont('WorkSans');
  });

  const setCssForLabel = () => {
    doc.current.setFont('WorkSans', 'bold');
    doc.current.setFontSize(13);
    doc.current.setTextColor(89, 89, 89);
  };
  const setCssNormal = () => {
    doc.current.setTextColor(89, 89, 89);
    doc.current.setFont('WorkSans', 'normal');
    doc.current.setFontSize(9);
  };

  const formatDateTime = (date: string) => {
    return format(new Date(date), 'HH:mm:ss');
  };

  const drawLabel = (text: string) => {
    setCssForLabel();
    const patientInfomationText = doc.current.splitTextToSize(
      text,
      WIDTH_A4 - lMargin - rMargin,
    );
    doc.current.text(patientInfomationText, lMargin, height.current);
    height.current =
      height.current +
      doc.current.getTextDimensions(patientInfomationText).h +
      4;
  };

  const drawLine = () => {
    doc.current.setDrawColor(133, 134, 136);
    doc.current.line(
      lMargin,
      height.current,
      WIDTH_A4 - rMargin,
      height.current,
    );
  };
  const getHeight = () => {
    return height.current;
  };

  const addSpace = (space: number) => {
    //5 is space between previous part with current part
    //8 is space between current part with label
    height.current = height.current + space;
  };

  const setHeight = (newHeight: number) => {
    height.current = newHeight;
  };

  const drawInfomationPatient = ({
    patientDetail,
    mrn,
    gender,
  }: {
    patientDetail?: PatientFragment;
    gender: Gender | string;
    mrn?: string;
  }) => {
    const { owner } = patientDetail as PatientFragment;

    const phones =
      owner?.phones?.map(
        item =>
          `${formatPhoneNumber(item?.phone)} ${
            item?.ext ? ` - ${item?.ext}` : ''
          }`,
      ) || [];

    const inforPatient = [
      [
        {
          label: 'Name',
          text: `${owner?.firstName} ${owner?.middleName || ''} ${
            owner?.lastName
          }`,
        },
        { label: 'MRN', text: `${mrn || owner?.mrn || 'N/A'}` },
        {
          label: 'Gender',
          text: `${
            gender
              ? LIST_GENDER[gender]
              : LIST_GENDER[owner?.gender as Gender] || 'N/A'
          }`,
        },
      ],
      [
        {
          label: 'Email',
          text: `${isBotMail(owner?.email) ? 'N/A' : owner?.email}`,
        },
        {
          label: 'Date Of Birth',
          text: `${owner?.dob ? formatDoB(owner?.dob) : 'N/A'}`,
        },
        {
          label: 'Address',
          text: `${owner?.address || 'N/A'}`,
        },
      ],
      [
        {
          label: 'Provider',
          text: `${owner?.provider?.map(item => item.provider) || 'N/A'}`,
        },
        {
          label: 'Phones',
          text: `${phones.length === 0 ? 'N/A' : phones.join(', ')}`,
        },
      ],
    ];

    //add text patient infomation
    drawLabel('Patient Information');
    //draw patient infomation
    setCssNormal();
    inforPatient.forEach((item, indexInfo) => {
      const currentHeight = height.current;
      const arrHeightItem = item
        .map(info => {
          const text = doc.current.splitTextToSize(
            `${info.label}: ${info.text}`,
            ((WIDTH_A4 - lMargin - rMargin) / 3) *
              (info.label === 'Phones' ? 2 : 1) -
              2,
          );
          return { height: doc.current.getTextDimensions(text).h, text };
        })
        .sort((a, b) => {
          return b.height - a.height;
        });
      item.forEach((_, indexItem) => {
        doc.current.text(
          arrHeightItem[indexItem].text,
          lMargin + ((WIDTH_A4 - lMargin - rMargin) / 3) * indexItem,
          height.current,
        );
      });
      setHeight(
        currentHeight +
          arrHeightItem[0].height +
          (indexInfo === inforPatient.length - 1 ? 0 : 5),
      );
    });
  };

  const drawAutoTable = ({
    head,
    body,
    columnStyles,
    willDrawCell,
  }: {
    head: RowInput;
    body: RowInput[];
    columnStyles?: {
      [key: string]: Partial<Styles>;
    };
    willDrawCell?: CellHook;
  }) => {
    autoTable(doc.current, {
      styles: {
        fontSize: 9,
        font: 'WorkSans',
      },
      theme: 'grid',
      margin: { left: lMargin, top: tMargin + 5 },
      startY: getHeight(),
      tableWidth: WIDTH_A4 - lMargin - rMargin,
      head: [head],
      body: body,
      columnStyles: columnStyles,
      willDrawCell: data => {
        if (willDrawCell) {
          willDrawCell(data);
        }
      },
      didParseCell: function(table) {
        if (table.section === 'head') {
          table.cell.styles.fillColor = colorHeadTable;
          table.cell.styles.textColor = colorHeadTextTable;
        }
      },
    });
  };

  const renderBody = (records?: ExtendedPatientTimeReportFragment[] | null) => {
    if (records && records?.length !== 0) {
      const body = records.map((item, indexRecord) => {
        const numberOfNoteTypes = item.patientNoteTypes?.length || 0;
        if (numberOfNoteTypes !== 0) {
          const noteTypes =
            item?.patientNoteTypes?.map(noteType => {
              const noteSelected = item?.patientNoteTypeSettings?.find(
                note => note._id === noteType?.id?._id,
              );

              return [noteSelected?.name, noteType?.description || 'N/A'];
            }) || [];
          let rowRecord: RowInput = [
            {
              rowSpan: numberOfNoteTypes + (indexRecord === 0 ? 1 : 0),
              content: formatDate(item?.startedAt),
            },
            {
              rowSpan: numberOfNoteTypes + (indexRecord === 0 ? 1 : 0),
              content: formatDateTime(item?.startedAt),
            },
            {
              rowSpan: numberOfNoteTypes + (indexRecord === 0 ? 1 : 0),
              content: item?.stoppedAt ? formatDateTime(item?.stoppedAt) : '',
            },
            {
              rowSpan: numberOfNoteTypes + (indexRecord === 0 ? 1 : 0),
              content: renderTime(item?.second || 0),
            },
            {
              rowSpan: numberOfNoteTypes + (indexRecord === 0 ? 1 : 0),
              content: `${item.owner?.firstName || ''} ${item.owner
                ?.middleName || ''} ${item.owner?.lastName || ''}`,
            },
          ];
          const [firstNoteType, ...restNoteTypes] = noteTypes;
          rowRecord =
            indexRecord === 0
              ? [
                  ...rowRecord,
                  {
                    content: 'Name',
                    styles: {
                      fillColor: colorHeadTable,
                      textColor: colorHeadTextTable,
                    },
                  },
                  {
                    content: 'Description',
                    styles: {
                      fillColor: colorHeadTable,
                      textColor: colorHeadTextTable,
                    },
                  },
                ]
              : [
                  ...rowRecord,
                  firstNoteType[0] as string,
                  firstNoteType[1] as string,
                ];
          return [
            rowRecord,
            ...(indexRecord === 0 ? noteTypes : restNoteTypes),
          ];
        }
        if (!item.startedAt) {
          return [
            [
              {
                colSpan: 3,
                content: item.label,
                styles: {
                  fillColor: colorTotal,
                  textColor: colorHeadTextTable,
                },
              },
              {
                colSpan: 7,
                content: renderTime(item?.second || 0),
                styles: {
                  fillColor: colorTotal,
                  textColor: colorHeadTextTable,
                },
              },
            ],
          ];
        }
        return [
          [
            formatDate(item?.startedAt),
            formatDateTime(item?.startedAt),
            item?.stoppedAt ? formatDateTime(item?.stoppedAt) : '',
            renderTime(item?.second || 0),
            `${item.owner?.firstName || ''} ${item.owner?.middleName ||
              ''} ${item.owner?.lastName || ''}`,
            '',
          ],
        ];
      });
      return body;
    }
    return [];
  };

  return {
    doc: doc.current,
    addSpace,
    drawLine,
    drawLabel,
    setCssNormal,
    height: height.current,
    setHeight,
    lMargin,
    rMargin,
    tMargin,
    getHeight,
    drawInfomationPatient,
    heightA4Page,
    drawAutoTable,
    colorTotal,
    colorHeadTextTable,
    colorHeadTable,
    renderBody,
  };
};
