import React, {
  useContext,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import {
  GetSurveyResultGroupQuery,
  GetSurveyResultGroupQueryVariables,
  SurveyResultEmbeddedFragment,
} from 'types.d';
import { PaperBorder, TableContainerStyled } from 'share/component_css';
import Survey from './Survey';
import { GET_PATIENT_SURVEYS } from 'gql';
import {
  useQueryCustom,
  useToogleDialog,
  usePagination,
  InfoPagination,
  useUpdateMeClient,
} from 'hooks';
import { PatientDetailContext } from 'share/context';
import {
  LoadingTable,
  NoDataTable,
  ButtonLoading,
  DeleteConfirm,
} from 'components';
import AddIcon from '@material-ui/icons/Add';
import DialogAddSurvey from './DialogAddSurvey';
import DialogAllQuestions from '../DialogAllQuestions';
import { useServices } from '../../services';
type Props = {};

export const Surveys: React.FC<Props> = () => {
  const patientDetailContext = useContext(PatientDetailContext);

  const { meClient } = useUpdateMeClient();

  const [openAddSurvey, toogleOpenAddSurvey] = useToogleDialog();

  const [openUpdateSurvey, toogleOpenUpdateSurvey] = useToogleDialog();

  const [isOpenDelete, toogleDialogDelete] = useToogleDialog();

  const idSurveyResultDelete = useRef('');

  const surveyResultUpdate = useRef<SurveyResultEmbeddedFragment | undefined>(
    undefined,
  );

  const { handleDeleteSurveyResult, loadingDeleteSurveyResult } = useServices();

  const callbackFetchData = (infoPagination: InfoPagination) => {
    fetchMoreSurvey(infoPagination);
  };

  const {
    infoPagination,
    DefaultPagination,
    defaultPaginationProps,
    handleSetInfoPagination,
  } = usePagination(callbackFetchData);

  const {
    data: dataSurvey,
    loading: loadingSurvey,
    fetchMore,
  } = useQueryCustom<
    GetSurveyResultGroupQuery,
    GetSurveyResultGroupQueryVariables
  >({
    api: GET_PATIENT_SURVEYS,
    variables: {
      params: {
        page: 1,
        limit: meClient.setting?.perPage || 5,
        patientId: patientDetailContext?.patient.owner?.idOwner,
      },
    },
  });

  const fetchMoreSurvey = useCallback(
    (infoPagination: InfoPagination) => {
      const { page, limit } = infoPagination;
      fetchMore({
        variables: {
          params: {
            page,
            limit,
            patientId: patientDetailContext?.patient.owner?.idOwner,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return {
            getSurveyResultGroup: {
              ...prev.getSurveyResultGroup,
              nodes: fetchMoreResult!.getSurveyResultGroup!.nodes!,
              meta: fetchMoreResult!.getSurveyResultGroup!.meta!,
            },
          };
        },
      });
    },
    [fetchMore, patientDetailContext],
  );

  const openDialogDelete = (id: string) => {
    idSurveyResultDelete.current = id;
    toogleDialogDelete(true);
  };

  const openDialogAddSurvey = useCallback(() => {
    toogleOpenAddSurvey(true);
  }, [toogleOpenAddSurvey]);

  //open adding survey dialog from outside (button "+" on task tag )
  useEffect(() => {
    //open adding dialog
    if (patientDetailContext?.dialogOpen === 'add-survey') {
      openDialogAddSurvey();
      return;
    }
    //open delete dialog
    if (patientDetailContext?.dialogOpen.includes('deleteSurveyResult')) {
      openDialogDelete(patientDetailContext?.dialogOpen.split('-')[1]);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialogAddSurvey, patientDetailContext?.dialogOpen]);

  const callbackAfterModify = () => {
    if (infoPagination.page === 1) {
      fetchMoreSurvey(infoPagination);
    }
  };

  const handleDelete = () => {
    handleDeleteSurveyResult(idSurveyResultDelete.current, () => {
      handleSetInfoPagination({ ...infoPagination, page: 1 }, true);
    });
    toogleDialogDelete(false);
    idSurveyResultDelete.current = '';
  };

  //open updating dialog from outside
  useEffect(() => {
    if (patientDetailContext?.dialogOpen === 'editSurveyResult') {
      surveyResultUpdate.current = patientDetailContext?.dataForOpenDialog as SurveyResultEmbeddedFragment;
      toogleOpenUpdateSurvey(true);
    }
  }, [patientDetailContext, toogleOpenUpdateSurvey]);

  const isInactive = useMemo(() => {
    return patientDetailContext?.isInactive;
  }, [patientDetailContext]);

  const handleToogleDialogDelete = useCallback(
    (status: boolean) => {
      toogleDialogDelete(status);
      if (!status) {
        patientDetailContext?.handleSetDialogOpen('');
      }
    },
    [patientDetailContext, toogleDialogDelete],
  );

  return (
    <>
      {/* dialog for update survey result from survey result tag */}
      {openUpdateSurvey && (
        <DialogAllQuestions
          isUpdate={true}
          detailSurveyResult={surveyResultUpdate.current}
          idSurvey={surveyResultUpdate.current?.surveyDetail?.surveyId}
          open={openUpdateSurvey}
          toogleDialog={toogleOpenUpdateSurvey}
          surveyName={surveyResultUpdate.current?.surveyDetail?.name}
          isExportPdf
          callbackAfterModify={callbackAfterModify}
        />
      )}
      <DeleteConfirm
        title="Are you sure you want to delete this survey result"
        loading={loadingDeleteSurveyResult}
        handleConfirm={handleDelete}
        open={isOpenDelete}
        toogle={handleToogleDialogDelete}
      />
      {openAddSurvey && (
        <DialogAddSurvey
          callbackAdd={() => {
            handleSetInfoPagination({ ...infoPagination, page: 1 }, true);
          }}
          open={openAddSurvey}
          toogleDialog={toogleOpenAddSurvey}
        />
      )}
      {!patientDetailContext?.isAnonymous &&
        patientDetailContext?.patient.owner &&
        !isInactive && (
          <ButtonLoading
            className="mb-8"
            Icon={<AddIcon />}
            text="Add Survey"
            callbackClick={openDialogAddSurvey}
          />
        )}
      <Grid xs={12} item>
        <TableContainerStyled component={PaperBorder}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Updated Date</TableCell>
                <TableCell>Version</TableCell>
                {!isInactive && <TableCell align="right">Resend</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loadingSurvey &&
                dataSurvey?.getSurveyResultGroup?.nodes?.length === 0 && (
                  <NoDataTable colSpan={isInactive ? 3 : 4} />
                )}
              {loadingSurvey && <LoadingTable colSpan={isInactive ? 3 : 4} />}
              {!loadingSurvey &&
                dataSurvey?.getSurveyResultGroup?.nodes.map((survey, index) => {
                  return (
                    <Survey
                      callbackAfterModify={callbackAfterModify}
                      key={index}
                      item={survey}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainerStyled>

        {dataSurvey?.getSurveyResultGroup.meta?.total !== 0 && (
          <DefaultPagination
            count={dataSurvey?.getSurveyResultGroup.meta?.total || 0}
            {...defaultPaginationProps}
          />
        )}
      </Grid>
    </>
  );
};

export default React.memo(Surveys);
