import React from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SvgIconProps } from '@material-ui/core';
type Props = SvgIconProps & {
  isExpand: boolean;
  toogleExpand?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

export const ToogleExpand: React.FC<Props> = ({
  isExpand,
  color,
  fontSize,
  toogleExpand,
}) => {
  const handleToogleExpand = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    if (toogleExpand) {
      toogleExpand(event);
    }
  };
  return (
    <>
      {isExpand ? (
        <ExpandMoreIcon
          onClick={handleToogleExpand}
          fontSize={fontSize || 'default'}
          color={color || 'action'}
        />
      ) : (
        <ChevronRightIcon
          onClick={handleToogleExpand}
          fontSize={fontSize || 'default'}
          color={color || 'action'}
        />
      )}
    </>
  );
};

export default React.memo(ToogleExpand);
