import React, { useRef, useState } from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import { FieldRadiusStyled } from 'share/component_css';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

type Props = {
  handleSearch?: (search: string) => void;
};

export const Search: React.FC<Props> = ({ handleSearch }) => {
  const [searchValue, setSearchValue] = useState('');

  const textInput = useRef<HTMLInputElement | null>(null);
  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSearch!(searchValue.trim());
  };
  return (
    <form className="w-100" noValidate onSubmit={handleSubmit}>
      <FieldRadiusStyled
        autoFocus
        placeholder="Search"
        variant="outlined"
        size="small"
        value={searchValue}
        onChange={handleChange}
        fullWidth
        type="text"
        inputRef={textInput}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton aria-label="search" type="submit" size="medium">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: searchValue && (
            <IconButton
              aria-label="clear"
              color="primary"
              onClick={() => {
                setSearchValue('');
                textInput.current?.focus();
              }}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
    </form>
  );
};
export default Search;
