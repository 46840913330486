import { styled, Theme } from '@material-ui/core/styles';
import {
  IconButton,
  TextareaAutosize,
  TableCell,
  TableRow,
  Tab,
  Box,
  Chip,
  Grid,
  InputAdornment,
  DialogContent,
  Fab,
} from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import {
  TableContainerStyled,
  TypographyBold,
  PaperBorder,
} from 'share/component_css';
import CheckIcon from '@material-ui/icons/Check';

export const IconButtonStyled = styled(IconButton)({
  '& .MuiSvgIcon-root': {
    fontSize: '1.2rem',
  },
});

export const TextareaAutosizeStyled = styled(TextareaAutosize)(({ theme }) => ({
  minWidth: theme.spacing(50),
  borderRadius: theme.spacing(1),
}));

export const BoxTagStyled = styled(Box)(
  ({
    theme,
    backgroundcolor,
    color,
  }: {
    theme: Theme;
    backgroundcolor?: string;
    color?: string;
  }) => ({
    backgroundColor: backgroundcolor || theme.palette.text.disabled,
    color:
      color === 'true'
        ? theme.palette.common.black
        : theme.palette.common.white,
  }),
);
export const FabStyled = styled(Fab)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  border: `solid 1px ${theme.palette.common.white}`,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));
export const IconButtonExtendStyled = styled(IconButton)({
  zIndex: 10,
  right: 16,
  top: 5,
});

export const IconButtonEditStyled = styled(IconButton)(
  ({ right }: { right: number }) => ({
    zIndex: 10,
    right: right,
    top: 5,
  }),
);

export const TableContainerForStageStyled = styled(TableContainerStyled)(
  ({ theme }) => ({
    maxHeight: theme.spacing(40),
    marginBottom: theme.spacing(5),
    overflowY: 'scroll',
  }),
);

export const TableRowStyled = styled(TableRow)(({ theme }) => ({
  maxHeight: theme.spacing(25),
  overflow: 'auto',
}));

export const TableCellStyled = styled(TableCell)({
  width: '55%',
});

export const TabPatientStyled = styled(Tab)({
  textTransform: 'none',
  fontWeight: 600,
  '& .MuiTab-wrapper': {
    height: '100%',
  },
});

export const TabQuestionStyled = styled(TabPatientStyled)({
  paddingTop: '6px',
  '& .MuiTab-wrapper': {
    flexDirection: 'row-reverse',
    '& div': {
      marginBottom: '0 !important',
    },
  },
});

const colorByType: { [key: string]: string } = {
  auto: blue[500],
  manual: grey[500],
};
export const ChipAttachStyled = styled(Chip)(
  ({ theme, type }: { theme: Theme; type: string }) => ({
    backgroundColor: colorByType[type],
    color: theme.palette.common.white,
  }),
);

export const ChipStatus = styled(Chip)(
  ({ theme, bgcolor }: { theme: Theme; bgcolor: string }) => ({
    backgroundColor: bgcolor,
    color: theme.palette.common.white,
    '&:focus': {
      backgroundColor: `${bgcolor || theme.palette.text.disabled}`,
    },
  }),
);

export const DialogContentStyled = styled(DialogContent)(
  ({ isadding }: { isadding: string }) => ({
    '&.MuiDialogContent-root': {
      paddingTop: isadding === 'true' ? '16px' : '0px',
    },
  }),
);

export const GridErrorImportStyled = styled(Grid)(({ theme }) => ({
  zIndex: 2,
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.common.white,
}));

export const GridDropActiveStyled = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.grey[200],
  zIndex: 3,
  opacity: '0.9',
}));
export const GridWrapTabStyled = styled(Grid)(({ theme }) => ({
  position: 'relative',
}));

export const BoxPatientDragStyled = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
export const TitleDesPatientStyled = styled(TypographyBold)(({ theme }) => ({
  marginRight: theme.spacing(1),
  width: theme.spacing(13),
  display: 'inline',
  flexShrink: 0,
}));

export const TypoTitleAddDashboard = styled(TypographyBold)(
  ({ theme, column }: { theme: Theme; column: string }) => ({
    gridColumn: column,
    marginBottom: theme.spacing(1),
  }),
);

export const InputAdornmentStyled = styled(InputAdornment)(
  ({ theme }: { theme: Theme }) => ({
    position: 'absolute',
    right: theme.spacing(3.5),
  }),
);
export const BoxWrapSurvey = styled(Box)({
  position: 'absolute',
  opacity: 0,
  top: 70,
  left: 0,
});
export const TabStyled = styled(Tab)(({ theme }) => ({
  minWidth: theme.spacing(12),
}));

export const GridStickyStyled = styled(Grid)(
  ({ top, theme }: { top: number; theme: Theme }) => ({
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    top: `${top}px`,
    zIndex: 11,
  }),
);

export const BoxCheckedStyled = styled(Box)(
  ({ check, theme }: { check: string; theme: Theme }) => ({
    borderStyle: 'solid',
    borderWidth: theme.spacing(2.5),
    borderTopColor:
      check === 'true' ? theme.palette.primary.main : theme.palette.grey[400],
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor:
      check === 'true' ? theme.palette.primary.main : theme.palette.grey[400],
    cursor: 'pointer',
    borderTopLeftRadius: '7px',
  }),
);

export const CheckIconStyled = styled(CheckIcon)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(0.25),
  left: theme.spacing(0.25),
  color: theme.palette.common.white,
  cursor: 'pointer',
}));

export const PaperStyled = styled(PaperBorder)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));
