import React, { useContext } from 'react';
import { ScrollingItemStyled } from 'share/component_css';
import { VisibilityContext, ScrollMenu } from 'react-horizontal-scrolling-menu';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

type Props = { children: any; loading: boolean; listLength: number };

export const ScrollStyled: React.FC<Props> = ({
  children,
  loading,
  listLength,
}) => {
  const LeftArrow = () => {
    const {
      scrollPrev,
      visibleItemsWithoutSeparators,
      isFirstItemVisible,
    } = useContext(VisibilityContext);
    return (
      !loading &&
      visibleItemsWithoutSeparators?.length < listLength && (
        <KeyboardArrowLeftIcon
          className={`${isFirstItemVisible ? '' : 'cursor-pointer'}`}
          onClick={() => scrollPrev()}
        />
      )
    );
  };

  const RightArrow = () => {
    const {
      scrollNext,
      visibleItemsWithoutSeparators,
      isLastItemVisible,
    } = useContext(VisibilityContext);
    return (
      !loading &&
      visibleItemsWithoutSeparators?.length < listLength && (
        <KeyboardArrowRightIcon
          className={`${isLastItemVisible ? '' : 'cursor-pointer'}`}
          onClick={() => scrollNext()}
        />
      )
    );
  };

  return (
    <ScrollingItemStyled display="flex" alignItems="center">
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {children || <></>}
      </ScrollMenu>
    </ScrollingItemStyled>
  );
};

export default React.memo(ScrollStyled);
