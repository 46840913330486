import React, { useState, useContext } from 'react';
import { useMutationCustom } from 'hooks';
import { Box } from '@material-ui/core';
import { ColorBox, Color, createColor } from 'material-ui-color';
import { DialogButton } from 'components';
import { DEFAULT_COLOR_TAG } from 'CONST';
import { UPDATE_PATIENT_NOTE } from 'gql';
import {
  UpdatePatientNoteMutation,
  UpdatePatientNoteMutationVariables,
  SubNoteFragment,
} from 'types.d';
import { PatientDetailContext } from 'share/context';

type Props = {
  updatedNote?: SubNoteFragment | null;
  patientNoteId: string;
  close: () => void;
};

export const PickColorNote: React.FC<Props> = ({
  updatedNote,
  patientNoteId,
  close,
}) => {
  const [color, setColor] = useState(updatedNote?.color || DEFAULT_COLOR_TAG);

  const patientDetailContext = useContext(PatientDetailContext);

  const handleChange = (newValue: Color) => {
    setColor(newValue.css.backgroundColor as string);
  };

  const [updateColor, { loading: loadingUpdateColor }] = useMutationCustom<
    UpdatePatientNoteMutation,
    UpdatePatientNoteMutationVariables
  >({
    api: UPDATE_PATIENT_NOTE,
    textSuccess: 'Update color successfully',
    callbackSuccess: data => {
      close();
    },
  });

  const handleChangeColorTag = () => {
    updateColor({
      variables: {
        params: {
          noteId: patientNoteId,
          patientId: patientDetailContext?.patient.owner?.idOwner,
          subNoteId: updatedNote?.idNote,
          description: updatedNote?.description,
          notedAt: updatedNote?.notedAt,
          color,
        },
      },
    });
  };

  return (
    <>
      <ColorBox value={createColor(color)} onChange={handleChange} />
      <Box display="flex" flexDirection="row-reverse">
        <DialogButton
          className="ml-8"
          loading={loadingUpdateColor}
          onClickButton={handleChangeColorTag}
        />
        <DialogButton isCancel onClickButton={close} />
      </Box>
    </>
  );
};

export default React.memo(PickColorNote);
