import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { LinkRouterStyled } from 'share/component_css';
import { handleScrollClick, renderMedia } from 'share/utils';
import { styled } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import QuickViewModal from './QuickViewModal';
import { trackButtonClickEvent } from 'modules/google_tag_manager';
import { LayoutContext } from 'share/context';

const CardStyled = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  cursor: 'pointer',
  height: '100%',
  border: 'none',
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}));

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  padding: '8px 10px',
  '&:last-child': {
    paddingBottom: '8px !important',
  },
}));

const CardTitleStyled = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
}));

const ReactPlayerStyled = styled(ReactPlayer)({
  '& video': {
    borderRadius: '10px',
    objectFit: 'cover',
  },
});

const QuickView = styled(Button)({
  width: '100%',
  bottom: '0px',
  color: '#1976D2',
  '&:hover': {
    background: '#fff',
    opacity: 1,
  },
});

const QuickViewImageStyled = styled(QuickView)({
  background: '#fff',
  position: 'absolute',
  opacity: '0.9',
});

const QuickViewContentStyled = styled(QuickView)({
  borderRadius: '0px 0px 10px 10px',
  opacity: '0.7',
});

type Props = {
  slug: any;
  mainImage: any;
  name: any;
  shortDescription: any;
  childrenLength?: any;
  chatbot?: any;
  mainVideo?: any;
  actions?: any;
  description: any;
  content: any;
  subsections?: any;
  bannerImage?: String | null;
  isSingleImage?: Boolean;
};

const mediaHeights: {
  [key: string]: string;
} = {
  '1': '400px',
  '2': '350px',
  '3': '250px',
  '4': '190px',
};

const LinkComponent = (props: any) => {
  return (
    <LinkRouterStyled
      className={props.slug && props.chatbot ? 'rltd' : ''}
      to={
        props.slug
          ? props.chatbot
            ? `/treatment/${props.slug}?chatbot=true`
            : `/treatment/${props.slug}`
          : '#'
      }
      onClick={handleScrollClick}
    >
      {props.children}
    </LinkRouterStyled>
  );
};

export const TreatmentCard: React.FC<Props> = ({
  slug,
  mainImage,
  name,
  shortDescription,
  childrenLength,
  chatbot,
  mainVideo,
  bannerImage,
  actions,
  isSingleImage,
}) => {
  const layoutContext = useContext(LayoutContext);

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [quickView, setQuickView] = useState(false);

  const renderShortDescription = () => {
    if (!shortDescription) return '';

    let slicePoint = childrenLength === 3 ? 250 : 250;
    if (childrenLength <= 2 || shortDescription.length <= slicePoint) {
      return shortDescription;
    }

    const lastSpaceIndex = shortDescription.lastIndexOf(' ', slicePoint);
    return lastSpaceIndex > -1
      ? `${shortDescription.slice(0, lastSpaceIndex)}...`
      : shortDescription;
  };

  const handleQuickView = () => {
    const updatedQuickViewCards = [...layoutContext?.quickViewCards];
    updatedQuickViewCards.push(slug);
    layoutContext?.setQuickViewCards(updatedQuickViewCards);

    setQuickView(true);
    trackButtonClickEvent('Quick View');
  };

  const ShortDescription = () => (
    <Typography variant="body2">{renderShortDescription()}</Typography>
  );

  return (
    <>
      <CardStyled
        onMouseEnter={() => setIsHovered2(true)}
        onMouseLeave={() => setIsHovered2(false)}
      >
        <Box>
          {mainVideo ? (
            <Box
              style={{
                paddingTop: isSingleImage ? '28.41%' : '56.25%',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <ReactPlayerStyled
                loop
                playing
                playsinline
                controls
                isdisplay="true"
                url={renderMedia(mainVideo)}
                width="100%"
                height="100%"
                muted={true}
                config={{
                  file: {
                    attributes: {
                      muted: true,
                    },
                  },
                  youtube: {
                    playerVars: { showinfo: 1 },
                  },
                }}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
            </Box>
          ) : (
            <Box
              position={'relative'}
              onMouseEnter={() => {
                setIsHovered(true);
                setIsHovered2(false);
              }}
              onMouseLeave={() => setIsHovered(false)}
              style={{
                paddingTop: isSingleImage ? '28.41%' : '56.25%',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <LinkComponent slug={slug} chatbot={chatbot}>
                <CardMedia
                  component="img"
                  alt="Image"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '10px',
                  }}
                  image={renderMedia(
                    childrenLength === 1 && bannerImage
                      ? bannerImage
                      : mainImage,
                  )}
                />
              </LinkComponent>
              {isHovered && (
                <QuickViewImageStyled
                  variant="outlined"
                  onClick={handleQuickView}
                >
                  Quick View
                </QuickViewImageStyled>
              )}
            </Box>
          )}
          <CardContentStyled onMouseEnter={() => setIsHovered2(true)}>
            <CardTitleStyled variant="h6">
              <Box display={'flex'} alignItems={'center'} m={0} p={0}>
                <LinkComponent slug={slug} chatbot={chatbot}>
                  {name}
                </LinkComponent>
                {actions && <Box>{actions}</Box>}
              </Box>
            </CardTitleStyled>
            <LinkComponent slug={slug} chatbot={chatbot}>
              {shortDescription.length > 250 && childrenLength >= 3 ? (
                <Tooltip
                  title={
                    <Box padding={'5px'} fontSize={13} lineHeight={1.3}>
                      {shortDescription}
                    </Box>
                  }
                  placement="right-end"
                  arrow
                >
                  {ShortDescription()}
                </Tooltip>
              ) : (
                <>{ShortDescription()}</>
              )}
            </LinkComponent>
          </CardContentStyled>
        </Box>

        <Box height={'36.5px'}>
          {isHovered2 && (
            <QuickViewContentStyled
              variant="outlined"
              onClick={handleQuickView}
            >
              Quick View
            </QuickViewContentStyled>
          )}
        </Box>
      </CardStyled>

      <QuickViewModal
        quickView={quickView}
        setQuickView={setQuickView}
        slug={
          layoutContext?.quickViewCards.length > 0
            ? layoutContext?.quickViewCards[
            layoutContext.quickViewCards.length - 1
            ]
            : ''
        }
      />
    </>
  );
};

export default React.memo(TreatmentCard);
