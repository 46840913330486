import gql from 'graphql-tag';

export const GET_ORGANIZATION_BY_USER = gql`
  query GetOrganizationByUser {
    getOrganizationByUser {
      _id
      name
      sftpUser
      createdAt
    }
  }
`;

export const GET_LINK_DOWNLOAD_KEY_PAIR = gql`
  query GetLinkDownloadKeyPair {
    getLinkDownloadKeyPair
  }
`;
