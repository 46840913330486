import gql from 'graphql-tag';
import { MetaFragment, TwilioLogFragment } from './fragments';

export const GET_TWILIO_LOGS = gql`
  query GetTwilioLogs($params: GetTwilioLogInput!) {
    getTwilioLogs(params: $params) {
      nodes {
        ...TwilioLog
      }
      meta {
        ...Meta
      }
    }
  }
  ${TwilioLogFragment}
  ${MetaFragment}
`;
