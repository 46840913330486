import React from 'react';
import MenuInDrawer from './MenuInDrawer';
import { TConfigMenu } from '../config_menu';
import { GET_UNREAD_ALL_COMMENT } from 'gql';
import {
  GetUnreadAllCommentQuery,
  GetUnreadAllCommentQueryVariables,
} from 'types.d';
import { useQueryCustom, useCheckLogin } from 'hooks';

type Props = {
  item: TConfigMenu;
  hasPadding: boolean;
};

export const TreatmentQuestion: React.FC<Props> = ({ item, hasPadding }) => {
  const isLogin = useCheckLogin();

  const { data: unreadCommentTreatment } = useQueryCustom<
    GetUnreadAllCommentQuery,
    GetUnreadAllCommentQueryVariables
  >({
    api: GET_UNREAD_ALL_COMMENT,
    fetchPolicy: 'cache-only',
    skip: !isLogin,
  });

  return (
    <MenuInDrawer
      unreadCommentTreatment={
        unreadCommentTreatment?.getUnreadAllComment[0].unreadCount || 0
      }
      hasPadding={hasPadding}
      detail={item}
    />
  );
};

export default React.memo(TreatmentQuestion);
