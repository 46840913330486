import React, { useRef, useState } from 'react';
import {
  FollowUpTypeSettingFragment,
  PatientNoteTypeSettingFragment,
  RemoveOneTreatmentMutation,
  RemoveOneTreatmentMutationVariables,
  RemoveOneTreatmentNoteTypeMutation,
  RemoveOneTreatmentNoteTypeMutationVariables,
} from 'types.d';
import { Avatar, Box, IconButton } from '@material-ui/core';
import { ChipTaskTypeStyled } from 'share/component_css';
import { DEFAULT_COLOR_TAG } from 'CONST';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import grey from '@material-ui/core/colors/grey';
import { useCustomPermission, useMutationCustom, useToogleDialog } from 'hooks';
import { REMOVE_ONE_TREATMENT, REMOVE_ONE_TREATMENT_NOTE_TYPE } from 'gql';
import DeleteConfirm from './DeleteConfirm';
import { DialogSelectTreatment } from 'modules/follow_up_types/components/DIalogSelectTreatment';
type Props = {
  item: FollowUpTypeSettingFragment | PatientNoteTypeSettingFragment;
  callbackDelete: () => void;
  listTreatment:
    | FollowUpTypeSettingFragment['treatment']
    | PatientNoteTypeSettingFragment['treatments'];
  isNoteType?: boolean;
};

export const ChipTypeStyled: React.FC<Props> = ({
  callbackDelete,
  item,
  listTreatment,
  isNoteType,
}) => {
  const { isNavigator } = useCustomPermission();

  const [isFolder, setFolder] = useState(false);

  const [openRemoveTreatment, setOpenRemoveTreatment] = useToogleDialog();

  const [openSelectTreatment, setOpenSelectTreatment] = useToogleDialog();

  const treatmentDeleted = useRef('');

  const isLastTreatment = useRef(false);

  const [
    removeTreatment,
    { loading: loadingRemoveTreament },
  ] = useMutationCustom<
    RemoveOneTreatmentMutation,
    RemoveOneTreatmentMutationVariables
  >({
    api: REMOVE_ONE_TREATMENT,
    textSuccess: `Remove ${isFolder ? 'folder' : 'treatment'} successfully`,
    callbackSuccess: () => {
      setOpenRemoveTreatment(false);
      if (isLastTreatment.current && isNavigator) {
        callbackDelete();
      }
      isLastTreatment.current = false;
      treatmentDeleted.current = '';
    },
  });
  const [
    removeTreatmentNoteType,
    { loading: loadingRemoveTreamentNoteType },
  ] = useMutationCustom<
    RemoveOneTreatmentNoteTypeMutation,
    RemoveOneTreatmentNoteTypeMutationVariables
  >({
    api: REMOVE_ONE_TREATMENT_NOTE_TYPE,
    textSuccess: `Remove ${isFolder ? 'folder' : 'treatment'} successfully`,
    callbackSuccess: () => {
      setOpenRemoveTreatment(false);
      if (isLastTreatment.current) {
        callbackDelete();
      }
      isLastTreatment.current = false;
      treatmentDeleted.current = '';
    },
  });

  const handleDelete = () => {
    if (isNoteType) {
      removeTreatmentNoteType({
        variables: {
          params: {
            patientNoteTypeId: item._id,
            treatmentId: treatmentDeleted.current,
          },
        },
      });
      return;
    }
    removeTreatment({
      variables: {
        params: {
          followUpTypeId: item._id,
          treatmentId: treatmentDeleted.current,
        },
      },
    });
  };

  return (
    <>
      <DeleteConfirm
        loading={loadingRemoveTreament || loadingRemoveTreamentNoteType}
        handleConfirm={handleDelete}
        open={openRemoveTreatment}
        toogle={setOpenRemoveTreatment}
      />
      {openSelectTreatment && (
        <DialogSelectTreatment
          isAddingFolder={Boolean(item?.folder && item.folder?.length !== 0)}
          taskTypeId={
            !isNoteType ? (item as FollowUpTypeSettingFragment)._id : ''
          }
          noteTypeId={
            isNoteType ? (item as PatientNoteTypeSettingFragment)._id : ''
          }
          open={openSelectTreatment}
          toogleDialog={setOpenSelectTreatment}
          addedTreatments={
            item?.folder && item.folder?.length !== 0
              ? item.folder?.map(item => item._id) || []
              : listTreatment?.map(item => item._id) || []
          }
        />
      )}
      <Box>
        {item?.folder && item?.folder?.length !== 0 ? (
          <>
            <IconButton
              aria-label="select treatment"
              color="default"
              onClick={() => setOpenSelectTreatment(true)}
              className="p-4"
            >
              <AddCircleIcon fontSize="large" />
            </IconButton>
            {item?.folder?.map(folder => {
              return (
                <ChipTaskTypeStyled
                  avatar={<Avatar>F</Avatar>}
                  backgroundcolor={grey[700]}
                  key={folder._id}
                  className="m-4"
                  label={folder.name}
                  onDelete={e => {
                    treatmentDeleted.current = folder._id;
                    if (item?.folder && item?.folder?.length === 1) {
                      isLastTreatment.current = true;
                    }
                    setOpenRemoveTreatment(true);
                    setFolder(true);
                  }}
                  deleteIcon={<DeleteIcon />}
                />
              );
            })}
          </>
        ) : (
          <>
            {(!listTreatment || listTreatment?.length === 0) && (
              <Box ml={1}>
                <ChipTaskTypeStyled
                  avatar={<Avatar>T</Avatar>}
                  backgroundcolor={DEFAULT_COLOR_TAG}
                  label="All"
                />
              </Box>
            )}
            {listTreatment && listTreatment?.length !== 0 && (
              <IconButton
                aria-label="select treatment"
                color="primary"
                onClick={() => setOpenSelectTreatment(true)}
                className="p-4"
              >
                <AddCircleIcon fontSize="large" />
              </IconButton>
            )}
            {listTreatment?.map(treatment => {
              return (
                <ChipTaskTypeStyled
                  avatar={<Avatar>T</Avatar>}
                  backgroundcolor={DEFAULT_COLOR_TAG}
                  key={treatment._id}
                  className="m-4"
                  label={treatment.name}
                  onDelete={e => {
                    treatmentDeleted.current = treatment._id;
                    if (listTreatment && listTreatment?.length === 1) {
                      isLastTreatment.current = true;
                    }
                    setOpenRemoveTreatment(true);
                    setFolder(false);
                  }}
                  deleteIcon={<DeleteIcon />}
                />
              );
            })}
          </>
        )}
      </Box>
    </>
  );
};

export default React.memo(ChipTypeStyled);
