import React from 'react';
import { Grid, Box, Divider } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { PaperBorder } from 'share/component_css';
type Props = {};

export const LoadingOrganization: React.FC<Props> = () => {
  return (
    <Grid container spacing={2} item>
      {[0, 1, 2, 3].map(item => {
        return (
          <Grid key={item} item xs={12} sm={6} md={3}>
            <PaperBorder className={`position-relative p-16 `}>
              <Grid container spacing={2}>
                <Grid item xs={12} container alignItems="center">
                  <Skeleton variant="circle" width={40} height={40} />
                  <Skeleton variant="text" width="80%" className="ml-16" />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rect" width="100%" height={40} />
                </Grid>
                {[8, 9, 10].map(item => (
                  <Box
                    alignItems="center"
                    display="flex"
                    className="p-8"
                    width="100%"
                    key={item}
                  >
                    <Skeleton variant="text" width="100%" />
                  </Box>
                ))}
              </Grid>
            </PaperBorder>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default LoadingOrganization;
