import React, { useContext, useMemo } from 'react';
import { Grid, MenuItem, Box, TextField } from '@material-ui/core';
import { DialogButton, ButtonLoading } from 'components';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useSnackbar } from 'notistack';
import { LIST_GENDER } from 'CONST';
import { Gender } from 'types.d';
import { PatientDetailContext } from 'share/context';
import { useForm, Controller, FormContextValues } from 'react-hook-form';
import { useToogleDialog } from 'hooks';

type MrnComponentProps = {
  methods: FormContextValues<FormData>;
  setOpenForm: (status: boolean) => void;
};

type FormData = {
  mrn: string;
  gender: Gender | string;
};

type ExtendMrnComponentProps = MrnComponentProps & {
  handleCallback: (params: { mrn: string; gender: string | Gender }) => void;
  loading: boolean;
};

export const useAddMrn = (): {
  openForm: boolean;
  setOpenForm: (status: boolean) => void;
  mrnComponentProps: MrnComponentProps;
  MrnComponent: any;
} => {
  const [openForm, setOpenForm] = useToogleDialog();

  const methods = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      mrn: '',
      gender: '',
    },
  });

  const mrnComponentProps = {
    methods,
    setOpenForm,
  };

  return {
    openForm,
    setOpenForm,
    mrnComponentProps,
    MrnComponent,
  };
};

export const MrnComponent: React.FC<ExtendMrnComponentProps> = ({
  setOpenForm,
  methods,
  handleCallback,
  loading,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { register, errors, control, getValues } = methods;

  const patientDetailContext = useContext(PatientDetailContext);

  const patientDetail = useMemo(() => {
    return patientDetailContext?.patient;
  }, [patientDetailContext]);

  const handleClick = () => {
    if (Object.values(errors).length !== 0) {
      enqueueSnackbar(Object.values(errors)[0]?.message, { variant: 'error' });
      return;
    }
    const { mrn, gender } = getValues();
    handleCallback({ mrn: mrn || '', gender: gender || '' });
  };

  return (
    <Grid container spacing={1}>
      {!patientDetail?.owner?.mrn && (
        <Grid item xs={patientDetail?.owner?.gender ? 8 : 4}>
          <TextField
            required
            fullWidth
            label="MRN"
            variant="outlined"
            inputProps={{ maxLength: 255 }}
            size="small"
            autoFocus
            name="mrn"
            inputRef={register({
              pattern: {
                value: /[0-9]+-[0-9]+/,
                message: 'Mrn field is wrong format',
              },
              validate: {
                required: value =>
                  value.trim() !== '' || 'Mrn field is required',
              },
            })}
            InputLabelProps={{ shrink: true }}
            defaultValue=""
          />
        </Grid>
      )}
      {!patientDetail?.owner?.gender && (
        <Grid item xs={patientDetail?.owner?.mrn ? 8 : 4}>
          <Controller
            rules={{
              validate: {
                required: value => value !== '' || 'Gender field is required',
              },
            }}
            name="gender"
            control={control}
            defaultValue=""
            as={
              <TextField
                size="small"
                fullWidth
                label="Gender"
                InputLabelProps={{ shrink: true }}
                select
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  },
                  displayEmpty: true,
                }}
              >
                {Object.keys(LIST_GENDER).map(item => {
                  return (
                    <MenuItem key={item} value={item}>
                      {LIST_GENDER[item]}
                    </MenuItem>
                  );
                })}
              </TextField>
            }
          />
        </Grid>
      )}
      <Grid item xs={4}>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          flexDirection="row-reverse"
        >
          <ButtonLoading
            className="button-fit-input"
            text="Send to EMR"
            Icon={<CloudUploadIcon />}
            callbackClick={handleClick}
          />
          <DialogButton
            className="mr-8 button-fit-input"
            isCancel
            onClickButton={() => setOpenForm(false)}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
