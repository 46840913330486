import React, { useMemo } from 'react';
import { Theme, styled } from '@material-ui/core/styles';
import {
  Drawer,
  List,
  Hidden,
  Typography,
  Box,
  Divider,
} from '@material-ui/core';
import { configMenu, TConfigMenu } from '../config_menu';
import { CheckRole, GpsIcon } from 'components';
import { useQuery } from '@apollo/react-hooks';
import {
  MENU_STATUS,
  BANNER_STATUS,
  SHOWING_COMMENT_FROM,
  SHOWING_MENU_ADMINISTRATION,
  SHOWING_MENU_SETTING,
  ME_CLIENT,
} from 'gql';
import { ButtonInstallPwa } from 'components';
import MenuInDrawer from './MenuInDrawer';
import { useCheckRole } from 'hooks';
import {
  ShowingCommentFromQuery,
  ShowingMenuAdministrationQuery,
  ShowingMenuSettingQuery,
} from 'types.d';
import QuestionMenu from './QuestionMenu';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PatientQuestion from './PatientQuestion';
import TreatmentQuestion from './TreatmentQuestion';
import { getLocalStorage } from 'share/utils';
import { TypographyItalic } from 'share/component_css';
import { grey } from '@material-ui/core/colors';
const drawerWidth = '300px';
const drawWidthClose = '4%';

const DrawerPermanentStyled = styled(Drawer)(
  ({
    theme,
    statusopen,
    hasbanner,
  }: {
    theme: Theme;
    statusopen: string;
    hasbanner?: string;
  }) => ({
    width: Object.is(statusopen, 'true') ? drawerWidth : drawWidthClose,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderRight: 0,
    '& .MuiDrawer-paper': {
      borderRight: '1px solid rgb(218, 220, 224)',
      width: Object.is(statusopen, 'true') ? drawerWidth : drawWidthClose,
      paddingTop: Object.is(hasbanner, 'true')
        ? theme.spacing(18)
        : theme.spacing(10),
      background: grey[50],
    },
    overflowX: 'hidden',
  }),
);

const DrawerTemporaryStyled = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: theme.spacing(30),
  },
}));

const InstallButtonStyled = styled(Box)(({ theme }) => ({
  textAlign: 'center',
}));

type Props = {
  handleDrawerToogle: () => void;
};
export const DrawerComponent: React.FC<Props> = ({ handleDrawerToogle }) => {
  const { data: dataMenuStatus } = useQuery(MENU_STATUS);

  const { data: dataBannerStatus } = useQuery(BANNER_STATUS);

  const { data: dataShowingCommentFrom } = useQuery<ShowingCommentFromQuery>(
    SHOWING_COMMENT_FROM,
  );

  const theme = useTheme();

  const isToogleMenu = useMediaQuery(theme.breakpoints.down('md'));

  const { data: dataShowingMenuAdministration } = useQuery<
    ShowingMenuAdministrationQuery
  >(SHOWING_MENU_ADMINISTRATION);

  const { data: dataShowingMenuSetting } = useQuery<ShowingMenuSettingQuery>(
    SHOWING_MENU_SETTING,
  );

  const { data: dataMeClient } = useQuery(ME_CLIENT);

  const informationTimezone = useMemo(() => {
    if (dataMeClient.meClient.timezone) {
      return `Timezone ${dataMeClient.meClient.timezone.label}`;
    }
    return '';
  }, [dataMeClient.meClient.timezone]);

  const { role } = useCheckRole();

  const renderMenu = (item: TConfigMenu) => {
    if (item.url === '/question/') {
      return <QuestionMenu item={item} />;
    }
    if (item.url === '/question-patient/') {
      return dataShowingCommentFrom?.showingCommentFrom &&
        ['NAVIGATOR', 'ADMIN'].includes(role) ? (
        <PatientQuestion
          hasPadding={
            dataMenuStatus.menuStatus ||
            (!dataMenuStatus.menuStatus && isToogleMenu)
          }
          item={item}
        />
      ) : null;
    }
    if (item.url === '/question-treatment/') {
      return dataShowingCommentFrom?.showingCommentFrom ? (
        <TreatmentQuestion
          hasPadding={
            dataMenuStatus.menuStatus ||
            (!dataMenuStatus.menuStatus && isToogleMenu)
          }
          item={item}
        />
      ) : null;
    }
    if (
      [
        '/users/',
        '/note-type/',
        '/tag/',
        '/task-type/',
        '/condition/',
        '/organizations/',
        '/group-users/',
        '/providers/',
        '/administration/emr-logs/',
        '/administration/release-notes/',
        '/calendar-management/',
        '/group-settings/',
        '/chat-analysis/',
        '/treatment-flows/',
        '/pdf-conversion/',
        '/module/',
        '/work-type/',
      ].includes(item.url)
    ) {
      return dataShowingMenuAdministration?.showingMenuAdministration ? (
        <MenuInDrawer
          hasPadding={
            dataMenuStatus.menuStatus ||
            (!dataMenuStatus.menuStatus && isToogleMenu)
          }
          detail={item}
        />
      ) : null;
    }
    if (
      [
        '/setting/providers/',
        '/setting/task-type/',
        '/setting/tag/',
        '/setting/group-settings/',
        '/setting/chat-analysis/',
        '/setting/treatment-flows/',
        '/setting/pdf-conversion/',
        '/emr-logs/',
        '/twilio-logs/',
        '/common-configs/',
        '/organization-information/',
        '/release-notes/',
      ].includes(item.url)
    ) {
      return dataShowingMenuSetting?.showingMenuSetting ? (
        <MenuInDrawer
          hasPadding={
            dataMenuStatus.menuStatus ||
            (!dataMenuStatus.menuStatus && isToogleMenu)
          }
          detail={item}
        />
      ) : null;
    }
    return <MenuInDrawer detail={item} />;
  };

  const renderAllItemOnMenu = () => {
    return configMenu.map(item => {
      return item.role ? (
        <CheckRole
          displayWhenNotLogin={item.url === '/assigned-treatments/'}
          key={item.url}
          roles={item.role}
          type={item.type}
        >
          {renderMenu(item)}
        </CheckRole>
      ) : (
        <MenuInDrawer key={item.url} detail={item} />
      );
    });
  };
  return (
    <>
      <Hidden mdDown>
        <DrawerPermanentStyled
          variant="permanent"
          statusopen={dataMenuStatus.menuStatus ? 'true' : 'false'}
          open={dataMenuStatus.menuStatus}
          hasbanner={
            dataBannerStatus.bannerStatus ||
              JSON.parse(getLocalStorage('remoteUser')!)
              ? 'true'
              : 'false'
          }
        >
          <List>{renderAllItemOnMenu()}</List>
          <InstallButtonStyled px={2}>
            <ButtonInstallPwa />
          </InstallButtonStyled>
          <TypographyItalic
            className={`mt-8 ${!dataMenuStatus.menuStatus
              ? 'p-4 font-weight-bold font-size-12'
              : 'pl-16 pr-16'
              }`}
            variant="subtitle2"
            align="center"
            color="textSecondary"
          >
            {informationTimezone}
          </TypographyItalic>
        </DrawerPermanentStyled>
      </Hidden>
      <Hidden lgUp>
        <DrawerTemporaryStyled
          variant="temporary"
          // open={!dataMenuStatus.menuStatus}
          open={dataMenuStatus.menuStatus}
          onClose={handleDrawerToogle}
        >
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            m={2}
          >
            <GpsIcon />
            <Typography color="primary" variant="h6">
              TreatmentGPS
            </Typography>
          </Box>
          <Divider />
          <List>{renderAllItemOnMenu()}</List>
          <Box px={2}>
            <ButtonInstallPwa />
          </Box>
        </DrawerTemporaryStyled>
      </Hidden>
    </>
  );
};

export default React.memo(DrawerComponent);
