import React from 'react';
import {
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { ButtonLoading } from 'components';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useHistory } from 'react-router-dom';

type Props = {
  open: boolean;
};

export const DialogNoTreatmentDefault: React.FC<Props> = ({ open }) => {
  const history = useHistory();

  const goToTreatmentDesigner = () => {
    history.push('/treatment-designer/');
  };
  return (
    <Dialog
      open={open!}
      maxWidth="sm"
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
    >
      <DialogTitle>Set Treatment Default</DialogTitle>
      <DialogContent className="pt-0">
        <DialogContentText>
          This Organization doesn't have any treatment default, please go to
          treatment designer to select one
        </DialogContentText>
        <ButtonLoading
          className="float-right"
          Icon={<ArrowForwardIcon />}
          text="Go to treatment designer"
          callbackClick={goToTreatmentDesigner}
        />
      </DialogContent>
    </Dialog>
  );
};
export default DialogNoTreatmentDefault;
