import React from 'react';
import { Typography, Box } from '@material-ui/core';
import DraftsRoundedIcon from '@material-ui/icons/DraftsRounded';

type Props = {
  text?: string;
};

export const NoData: React.FC<Props> = ({ text }) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
        mt={2}
      >
        <DraftsRoundedIcon color="disabled" fontSize="large" />
        <Typography variant="body1" color="textSecondary">
          {text ? text : 'No data to display'}
        </Typography>
      </Box>
    </>
  );
};
export default NoData;
