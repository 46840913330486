import React from 'react';
import { styled } from '@material-ui/core/styles';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { formatDistance } from 'date-fns';
import DescriptionIcon from '@material-ui/icons/Description';
import { NotificationFragment } from 'types.d';
import { LinkRouterStyled } from 'share/component_css';

type Props = {
  notification: NotificationFragment;
  closePopover: () => void;
};

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  margin: theme.spacing(0.5, 0),
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}));

const NotificationItem: React.FC<Props> = ({ notification, closePopover }) => {
  const { seen, createdAt, _id, treatment } = notification;

  return (
    <LinkRouterStyled
      onClick={closePopover}
      to={`/assigned-treatments/treatment/${treatment?._id}/`}
    >
      <ListItemStyled
        style={{ backgroundColor: seen ? '' : 'aliceblue' }}
        alignItems="flex-start"
        key={_id}
        button
      >
        <ListItemAvatar>
          <Avatar color="primary">
            <DescriptionIcon />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={
            <React.Fragment>
              <Typography component="div" variant="body2" color="textPrimary">
                Treatment <strong>{treatment?.name}</strong> has been assigned
                to you
              </Typography>
            </React.Fragment>
          }
          secondary={formatDistance(new Date(createdAt), new Date(), {
            includeSeconds: true,
          })}
        />
      </ListItemStyled>
    </LinkRouterStyled>
  );
};

export default React.memo(NotificationItem);
