import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import {
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Divider,
  Box,
  Button,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
  LOGIN,
  LOGIN_GOOGLE,
  LOGIN_FACEBOOK,
  LOGIN_PHONE,
  RESEND_OTP,
} from 'gql';
import { useForm } from 'react-hook-form';
import {
  GoogleAuthMutation,
  GoogleAuthMutationVariables,
  LoginMutation,
  LoginMutationVariables,
  FacebookAuthMutation,
  FacebookAuthMutationVariables,
  ResponseLoginFragment,
  LoginPhoneMutation,
  LoginPhoneMutationVariables,
  ResendOtpMutation,
  ResendOtpMutationVariables,
  UserRole,
} from 'types.d';
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import {
  useUpdateMeClient,
  useMutationCustom,
  useAdingTreatmentSidebars,
} from 'hooks';
import { styled } from '@material-ui/core/styles';
import {
  redirectAfterLogin,
  handleError,
  getSessionStorage,
  getLocalStorage,
  removeSessionStorage,
  removeLocalStorage,
} from 'share/utils';
import {
  KEY_LOCAL_NO_LOG_KEY,
  KEY_SESSION_ORGANIZATION_ID,
  NO_SPACING_REGEX,
} from 'CONST';
import { TypographyCursor, ButtonSizeIcon } from 'share/component_css';
import { useTheme } from '@material-ui/core/styles';
import { Google, Facebook } from 'mdi-material-ui';
import { grey, pink } from '@material-ui/core/colors';
import { LayoutContext } from 'share/context';
import { UserInfo } from './ModalLogin';
import { useSnackbar } from 'notistack';
import EmailPhoneInput from './EmailPhoneInput';
import { loginEvent } from 'modules/google_tag_manager';

type Props = {
  handleCloseModal?: (status: boolean) => void;
  setTab: React.Dispatch<React.SetStateAction<string>>;
  setUserInfo: (value: UserInfo) => void;
  setIsUnverifyAccount: (status: boolean) => void;
};

export const GoogleIcon = styled(Google)({
  backgroundColor: pink[400],
  color: grey[50],
  borderRadius: '12px 12px 12px 12px',
  fontSize: '1.5em',
  marginRight: '2px',
});

export const FacebookIcon = styled(Facebook)({
  backgroundColor: grey[50],
  marginRight: '2px',
  fontSize: '1.7em',
});

const FormStyled = styled('form')(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '100%',
}));

const ButtonGoogleStyled = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  padding: '6px 4px',
  fontSize: '0.875rem',
  textTransform: 'none',
  borderRadius: theme.spacing(0.25),
  '& .MuiSvgIcon-root': {
    backgroundColor: 'transparent',
  },
}));

const useStyles = makeStyles(theme => ({
  loginFacebookButton: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#3a81df',
    color: theme.palette.common.white,
    padding: '6px 4px',
    fontSize: '0.875rem',
    fontFamily: 'Helvetica',
    minWidth: '64px',
    boxSizing: 'border-box',
    lineHeight: '1.75',
    borderRadius: theme.spacing(0.25),
    letterSpacing: '0.02857em',
    border: 'none',
    boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '& .MuiSvgIcon-root': {
      marginRight: '4px',
      color: 'white',
      backgroundColor: 'transparent',
    },
  },
}));

const ForgotPasswordStyled = styled(TypographyCursor)({
  float: 'right',
});

type FormData = {
  email: string;
  phoneNumber: string;
  password: string;
};

export const LoginForm: React.FC<Props> = ({
  handleCloseModal,
  setTab,
  setUserInfo,
  setIsUnverifyAccount,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showPassword, setShowPassword] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const layoutContext = useContext(LayoutContext);

  const history = useHistory();

  const { control, register, handleSubmit, errors, setValue, watch } = useForm<
    FormData
  >();

  const { getSubTreaments } = useAdingTreatmentSidebars(true);

  const [resendOTP] = useMutationCustom<
    ResendOtpMutation,
    ResendOtpMutationVariables
  >({
    api: RESEND_OTP,
  });

  const [doLogin] = useMutation<LoginMutation, LoginMutationVariables>(LOGIN, {
    onCompleted(data) {
      if (data?.login?.role !== UserRole.User) {
        layoutContext?.toogleDialogLogin(false);
        if (layoutContext?.isRedirectingToHome) {
          history.push(redirectAfterLogin());
        }
      }
    },
    onError(error) {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
      const arrKeyError = handleError(error?.graphQLErrors[0]!, true);
      setUserInfo({
        email: undefined,
        userId:
          error?.graphQLErrors[0]?.extensions?.exception.validationErrors[0]
            .value,
      });
      if (arrKeyError?.includes('IsUserNotActivated')) {
        setTab('loginFailed');
      }
    },
  });

  const [doLoginPhone] = useMutation<
    LoginPhoneMutation,
    LoginPhoneMutationVariables
  >(LOGIN_PHONE, {
    onError(error) {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
      const arrKeyError = handleError(error?.graphQLErrors[0]!, true);
      setUserInfo({
        phone: `+${watch('phoneNumber')}`,
        userId:
          error?.graphQLErrors[0]?.extensions?.exception.validationErrors[0]
            .value,
      });
      if (arrKeyError?.includes('IsUserNotActivated')) {
        resendOTP({
          variables: {
            params: { phone: `+${watch('phoneNumber')}` as string },
          },
        });
        setTab('confirmPhone');
        setIsUnverifyAccount(true);
      }
    },
  });

  const [loginGoogle] = useMutationCustom<
    GoogleAuthMutation,
    GoogleAuthMutationVariables
  >({ api: LOGIN_GOOGLE });

  const [loginFacebook] = useMutationCustom<
    FacebookAuthMutation,
    FacebookAuthMutationVariables
  >({ api: LOGIN_FACEBOOK });

  const { functionAfterLogin } = useUpdateMeClient();

  const onSubmit = handleSubmit(({ email, password, phoneNumber }) => {
    if (phoneNumber) {
      doLoginPhone({
        variables: { params: { phone: `+${phoneNumber}`, password } },
        update(cache, { data }) {
          handleAfterLogin(data?.loginPhone);
        },
      });
      return;
    }
    doLogin({
      variables: { params: { email: email.toLowerCase(), password } },
      update(cache, { data }) {
        handleAfterLogin(data?.login);
      },
    });
  });

  const responseGoogle = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) => {
    const idToken = (response as GoogleLoginResponse).tokenId;
    if (idToken) {
      loginGoogle({
        variables: { idToken },
        update(cache, { data }) {
          handleAfterLogin(data!.googleAuth);
        },
      });
    }
  };

  const responseFacebook = (response: ReactFacebookLoginInfo) => {
    const idToken = (response as ReactFacebookLoginInfo).accessToken;
    if (idToken) {
      loginFacebook({
        variables: { idToken },
        update(cache, { data }) {
          handleAfterLogin(data!.facebookAuth);
        },
      });
    }
  };

  const handleAfterLogin = async (
    dataUpdate?: ResponseLoginFragment | null,
  ) => {
    if (dataUpdate) {
      loginEvent(dataUpdate.role);
    }
    var orgId = getSessionStorage(KEY_SESSION_ORGANIZATION_ID)!;
    var noLogKey = getLocalStorage(KEY_LOCAL_NO_LOG_KEY)!;
    if (orgId) {
      removeSessionStorage(KEY_SESSION_ORGANIZATION_ID);
    }
    if (noLogKey) {
      removeLocalStorage(KEY_LOCAL_NO_LOG_KEY);
    }
    functionAfterLogin(dataUpdate);
    getSubTreaments();
  };

  const handleSetValue = (value: string, isEmail?: boolean) => {
    if (isEmail) {
      setValue('email', value);
      return;
    }
    setValue('phoneNumber', value);
  };

  return (
    <>
      <FormStyled onSubmit={onSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} className="mb-4">
            <EmailPhoneInput
              watch={watch}
              handleSetValue={handleSetValue}
              register={register}
              errorsEmail={errors.email}
              errorsPhone={errors.phoneNumber}
              control={control}
            />
          </Grid>
        </Grid>
        <TextField
          tabIndex={2}
          required
          inputProps={{ maxLength: 255 }}
          size="small"
          id="password"
          label="Password"
          inputRef={register({
            required: {
              value: true,
              message: 'This field is required',
            },
            pattern: {
              value: NO_SPACING_REGEX,
              message: 'Your password can’t start or end with a blank space',
            },
          })}
          error={!!errors.password}
          type={showPassword ? 'text' : 'password'}
          name="password"
          margin="dense"
          fullWidth
          variant="outlined"
          placeholder="Enter your password"
          helperText={!!errors.password ? errors.password.message : ''}
          autoComplete="new-password"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  aria-label="Toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <ForgotPasswordStyled
              onClick={() => setTab('forgotPassword')}
              variant="subtitle2"
              color="primary"
            >
              Forgot password?
            </ForgotPasswordStyled>
          </Grid>
          <Grid item xs={12}>
            <Box my={1}>
              <ButtonSizeIcon
                fullWidth
                tabIndex={6}
                type="submit"
                variant="outlined"
                color="primary"
                onClick={onSubmit}
              >
                Sign In
              </ButtonSizeIcon>
            </Box>
          </Grid>
        </Grid>
      </FormStyled>
      <Typography
        align="center"
        variant="body2"
        color="textSecondary"
        className="pt-8 pb-8"
      >
        Or
      </Typography>
      <Box
        display="grid"
        gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
        gridGap={4}
        mt={1}
      >
        <GoogleLogin
          clientId={process.env.REACT_APP_ID_GOOGLE!}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          render={renderProps => (
            <ButtonGoogleStyled
              startIcon={<GoogleIcon color="secondary" />}
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              fullWidth
              variant="contained"
              color="secondary"
            >
              Sign in with Google
            </ButtonGoogleStyled>
          )}
        />
        <FacebookLogin
          isMobile={false}
          appId={process.env.REACT_APP_ID_FACEBOOK!}
          fields="name,email,picture"
          callback={responseFacebook}
          cssClass={classes.loginFacebookButton}
          textButton="Sign in with Facebook"
          icon={<FacebookIcon color={'primary'} />}
        />
      </Box>
      <Box textAlign="center" py={2}>
        <Divider />
      </Box>
      <Box textAlign="center">
        <TypographyCursor
          onClick={() => setTab('register')}
          variant="subtitle2"
          color="primary"
        >
          Don't have an account? Sign Up
        </TypographyCursor>
      </Box>
    </>
  );
};
export default LoginForm;
