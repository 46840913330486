import React from 'react';
import * as Survey from 'survey-react';
import { useCheckLogin, useEffectOnlyOnce } from 'hooks';
import { useTheme } from '@material-ui/core/styles';
import { CREATE_ANONYMOUS_SURVEY_RESULT, CREATE_SURVEY_RESULT } from 'gql';
import { useMutationCustom, useCustomPermission } from 'hooks';
import {
  CreateSurveyResultMutation,
  CreateSurveyResultMutationVariables,
} from 'types.d';
import { BackdropComponent } from 'components';
import { getStatusByAnswer } from 'share/utils';

type Props = {
  surveyData: string;
  idSurvey: string;
  isPrivate?: boolean | null;
};
export const SurveyDisplay: React.FC<Props> = ({
  surveyData,
  idSurvey,
  isPrivate,
}) => {
  const theme = useTheme();

  const isLogin = useCheckLogin();

  const { isNavigator, isAdmin } = useCustomPermission();

  let surveyJson = {};

  try {
    // const condition = isPrivate ? Boolean((!isPrivate && isPatient)) : isPatient;

    surveyJson = {
      ...JSON.parse(surveyData),
      focusFirstQuestionAutomatic: false,
      // mode: condition ? 'edit' : 'display',
      mode: !isNavigator && !isAdmin ? 'edit' : 'display',
      showCompletedPage: false,
    };
  } catch (e) {
    surveyJson = {};
  }

  const [createSurvey, { loading }] = useMutationCustom<
    CreateSurveyResultMutation,
    CreateSurveyResultMutationVariables
  >({
    api: CREATE_SURVEY_RESULT,
    textSuccess: 'Thank you for completing survey',
  });

  const [
    createAnonymousSurvey,
    { loading: anonymousloading },
  ] = useMutationCustom<
    CreateSurveyResultMutation,
    CreateSurveyResultMutationVariables
  >({
    api: CREATE_ANONYMOUS_SURVEY_RESULT,
    textSuccess: 'Thank you for completing survey',
  });

  useEffectOnlyOnce(() => {
    const mainColor = theme.palette.primary.main;
    //set main color for creator survey
    const defaultThemeColorsSurvey =
      Survey.StylesManager.ThemeColors['default'];
    defaultThemeColorsSurvey['$main-color'] = mainColor;
    Survey.StylesManager.applyTheme();
  });

  const onComplete = (survey: Survey.SurveyModel, options: any) => {
    const createSurveyFunction = isLogin ? createSurvey : createAnonymousSurvey;

    const surveyParams = {
      variables: {
        params: {
          data: survey.data,
          survey: idSurvey,
          completedStatus: getStatusByAnswer(survey),
        },
      },
    };

    createSurveyFunction(surveyParams).then(data => {
      survey.clear(true);
    });
  };

  return (
    <>
      <BackdropComponent loading={loading || anonymousloading} />
      <Survey.Survey json={surveyJson} onComplete={onComplete} />
    </>
  );
};

export default React.memo(SurveyDisplay);
