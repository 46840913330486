import React from 'react';
import { Typography } from '@material-ui/core';
import { formatDate } from 'share/utils';
import { DefaultTable } from 'components';
import { TreatmentRegimenAddPatientFragment } from 'types.d';
import { TypoThreeDotsLine } from 'share/component_css';
type Props = {
  group?: TreatmentRegimenAddPatientFragment | null;
};

export const TableTreatmentGroup: React.FC<Props> = ({ group }) => {
  return (
    <DefaultTable
      isOnComponent
      minWidth="100%"
      isWrapped
      heads={[{ text: 'Name' }, { text: 'Created At' }]}
      loading={false}
      content={
        group?.regimenConfig.map(row => {
          return {
            key: row.treatment?._id || row.task?._id,
            cells: [
              <TypoThreeDotsLine variant="subtitle2">
                {row?.treatment?.name || row?.task?.name}
              </TypoThreeDotsLine>,
              <Typography variant="subtitle2">
                {formatDate(row.treatment?.createdAt || row.task?.createdAt)}
              </Typography>,
            ],
          };
        }) || []
      }
    />
  );
};

export default React.memo(TableTreatmentGroup);
