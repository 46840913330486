import React, {
  useState,
  useRef,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import * as Survey from 'survey-react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useApolloClient } from '@apollo/react-hooks';
import {
  useMutationCustom,
  useAddMrn,
  useTimeRecord,
  useToogleDialog,
  useCapturePdf,
} from 'hooks';
import {
  UpdateSurveyResultMutation,
  UpdateSurveyResultMutationVariables,
  SurveyResultEmbeddedFragment,
  ExportEmrInfoMutation,
  ExportEmrInfoMutationVariables,
  GetSurveyResultTaskQuery,
  GetSurveyResultTaskQueryVariables,
  SurveyResultStatus,
  Gender,
  PatientTimeRecordFragment,
} from 'types.d';
import {
  UPDATE_SURVEY_RESULT,
  GET_SURVEY_DATA_BY_PATIENT,
  EXPORT_EMR_INFO,
  GET_SURVEY_RESULT_TASK,
} from 'gql';
import { ButtonLoading, DialogButton, DialogTitleClose } from 'components';
import Skeleton from '@material-ui/lab/Skeleton';
import EditIcon from '@material-ui/icons/Edit';
import gql from 'graphql-tag';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { jsPDF } from 'jspdf';
import { formatDate, getStatusByAnswer, slugify } from 'share/utils';
import { PatientDetailContext } from 'share/context';
import { PictureAsPdf } from '@material-ui/icons';
import SurveyDisplayByType from './SurveyDisplayByType';
import Checklist from './Checklist';
import FollowUp from './FollowUp';
import DialogNote from './Note/DialogNote';
import { useDialogNote } from '../services/hooks';
import { useServices } from '../services';
import DialogFilterSendEmr from './SendEmr/DialogFilterSendEmr';
import { PaperBorder } from 'share/component_css';

export type SendEmrType = {
  gender: Gender | string;
  mrn: string | undefined;
  tasks: string[];
  checklists: string[];
  conditions: string[];
  notes: string[];
  records: string[];
};

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
  idSurvey?: string;
  detailSurveyResult?: SurveyResultEmbeddedFragment;
  surveyName?: string | null;
  isUpdate?: boolean;
  isExportPdf?: boolean;
  callbackAfterModify?: () => void;
};

export const DialogAllQuestions: React.FC<Props> = ({
  open,
  toogleDialog,
  idSurvey,
  detailSurveyResult,
  surveyName,
  isUpdate,
  isExportPdf,
  callbackAfterModify,
}) => {
  const [tabIndex, setTabIndex] = useState(0);

  const [isCreatePdf, setIsCreatePdf] = useState(false);

  const patientDetailContext = useContext(PatientDetailContext);

  const patientDetail = useMemo(() => {
    return patientDetailContext?.patient;
  }, [patientDetailContext]);

  const paramsGetAllData = useMemo(() => {
    return {
      paramsSurveyVersion: {
        surveyId: detailSurveyResult?.surveyDetail?.originSurvey,
        version: detailSurveyResult?.surveyDetail?.version,
      },
      paramsTask: {
        page: 1,
        patientId: patientDetail?.owner?.idOwner,
      },
      paramsChecklist: {
        patientId: patientDetail?.owner?.idOwner,
        checklistExists: true,
      },
      paramsPatientStage: {
        patientId: patientDetail?.owner?.idOwner,
      },
      paramsTimeRecord: {
        patient: patientDetail?.owner?.idOwner,
      },
    };
  }, [detailSurveyResult, patientDetail]);

  const { isLoading, handleGeneratePdf, checklistModels } = useCapturePdf(
    `${surveyName} (v${
      detailSurveyResult?.surveyDetail?.version
    }) (${formatDate(detailSurveyResult?.createdAt)})`,
    patientDetail,
  );

  const [survey, setSurvey] = useState<Survey.ReactSurveyModel | undefined>(
    undefined,
  );

  const [status, setStatus] = useState<SurveyResultStatus>(
    SurveyResultStatus.New,
  );

  const [isOpenSelectDialog, setOpenSelectDialog] = useToogleDialog();

  const [dataSendEmr, setDataSendEmr] = useState<SendEmrType | undefined>(
    undefined,
  );

  const client = useApolloClient();

  const { convertDateTimeByTimezone } = useTimeRecord();

  const {
    openForm,
    setOpenForm,
    MrnComponent,
    mrnComponentProps,
  } = useAddMrn();

  const [renderSignedNotesButton] = useDialogNote();

  const { variablesSurveyTags } = useServices();

  const moreInfoUpdated = useRef<{
    mrn?: string;
    gender: Gender | string;
  }>({ mrn: undefined, gender: '' });

  const [updateSurvey, { loading: loadingUpdateSurvey }] = useMutationCustom<
    UpdateSurveyResultMutation,
    UpdateSurveyResultMutationVariables
  >({
    api: UPDATE_SURVEY_RESULT,
    refetchQueries: [
      {
        query: GET_SURVEY_DATA_BY_PATIENT,
        variables: variablesSurveyTags,
      },
    ],
    textSuccess:
      isCreatePdf || dataSendEmr ? '' : 'Update survey result sucessfully',
    callbackSuccess: data => {
      try {
        //update survey result group
        client.writeFragment({
          id: `SurveyResultGroupRes:${idSurvey}`,
          fragment: gql`
            fragment SurveyResultGroupRes on SurveyResultGroupRes {
              updatedAt
            }
          `,
          data: {
            updatedAt: data.updateSurveyResult?.updatedAt,
            __typename: 'SurveyResultGroupRes',
          },
        });

        //update survey result embedded
        client.writeFragment({
          id: `SurveyResultEmbedded:${detailSurveyResult?._id}`,
          fragment: gql`
            fragment SurveyResultEmbeddedUpdate on SurveyResultEmbedded {
              updatedAt
              updatedBy {
                firstName
                lastName
              }
              data
              workingStatus
            }
          `,
          data: {
            data: survey?.data,
            updatedAt: data.updateSurveyResult?.updatedAt,
            updatedBy: {
              firstName: data.updateSurveyResult?.updatedBy?.firstName,
              lastName: data.updateSurveyResult?.updatedBy?.lastName,
              __typename: 'User',
            },
            workingStatus:
              data.updateSurveyResult?.workingStatus || SurveyResultStatus.New,
            __typename: 'SurveyResultEmbedded',
          },
        });
      } catch (err) {
        console.log(err);
      }
      if (callbackAfterModify) {
        callbackAfterModify();
      }
      cancelDialog();
    },
  });

  const [sendEmr, { loading: loadingEmr }] = useMutationCustom<
    ExportEmrInfoMutation,
    ExportEmrInfoMutationVariables
  >({
    api: EXPORT_EMR_INFO,
    textSuccess: 'Send to EMR successfully',
    callbackSuccess: () => {
      cancelDialog();
      const { mrn, gender } = moreInfoUpdated.current;
      if (Object.values(moreInfoUpdated.current).filter(Boolean).length !== 0) {
        client.writeFragment({
          id: `PatientRes:${patientDetail?._id}`,
          fragment: gql`
            fragment PatientMrnUpdated on PatientRes {
              owner {
                mrn
                gender
              }
            }
          `,
          data: {
            owner: {
              mrn,
              gender,
              __typename: 'User',
            },
            __typeName: 'PatientRes',
          },
        });
        moreInfoUpdated.current = { mrn: undefined, gender: '' };
      }
    },
  });

  const [
    getSurveyData,
    { loading: loadSurveyData, data: dataSurveyTask },
  ] = useLazyQuery<GetSurveyResultTaskQuery, GetSurveyResultTaskQueryVariables>(
    GET_SURVEY_RESULT_TASK,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        if (data.getSurveyVersion) {
          const surveyData = detailSurveyResult?.data;
          const survey = new Survey.Model(
            data.getSurveyVersion?.nodes[0]?.surveyData,
          );
          survey.data = surveyData;
          survey.showNavigationButtons = false;
          survey.mode = isUpdate ? 'edit' : 'display';
          survey.showCompletedPage = false;
          setSurvey(survey);
        }
      },
    },
  );

  const handleSetStatus = (status: SurveyResultStatus) => {
    setStatus(status);
  };

  const patientName = useMemo(() => {
    return patientDetail?.owner
      ? `${patientDetail.owner.firstName || ''} ${patientDetail.owner
          .middleName || ''} ${patientDetail.owner.lastName || ''}`
      : 'N/A';
  }, [patientDetail]);

  const onEnter = () => {
    getSurveyData({
      variables: paramsGetAllData,
    });
    handleSetStatus(
      detailSurveyResult?.workingStatus || SurveyResultStatus.New,
    );
  };

  const handleUpdateSurvey = () => {
    if (survey && survey.completeLastPage()) {
      updateSurvey({
        variables: {
          params: {
            surveyResultId: detailSurveyResult?._id,
            data: survey?.data,
            completedStatus: getStatusByAnswer(survey),
            workingStatus: status,
            patientId: patientDetail?._id,
          },
        },
      });
    }
  };

  //get module and reocords selected
  const getRecordPdf = (idRecord: string[]) => {
    return dataSurveyTask?.getPatientTimeRecord?.nodes?.reduce(
      (recordsPdf, record) => {
        if (record?.timeReport) {
          const matchingTimeReports = record?.timeReport
            .filter(timeEl => idRecord.includes(timeEl._id))
            .map(item => {
              return {
                ...item,
                startedAt: convertDateTimeByTimezone(item.startedAt),
                stoppedAt: convertDateTimeByTimezone(item.stoppedAt),
              };
            });
          if (matchingTimeReports.length > 0 && matchingTimeReports) {
            (recordsPdf as PatientTimeRecordFragment[]).push({
              ...record,
              timeReport: matchingTimeReports,
            });
          }
        }
        return recordsPdf;
      },
      [],
    );
  };

  const downloadPdf = (dataPdf: SendEmrType) => {
    setOpenSelectDialog(false);
    const {
      mrn,
      tasks,
      checklists,
      conditions,
      gender,
      notes,
      records,
    } = dataPdf;

    handleGeneratePdf({
      typePdf: 'createPdf',
      callbackCreatePdf: (doc: jsPDF) => {
        if (doc) {
          doc.save(
            slugify(
              `${surveyName} v${detailSurveyResult?.surveyDetail?.version}
                ${formatDate(detailSurveyResult?.createdAt)}`,
            ),
          );
        }
        if (survey && survey.completeLastPage()) {
          updateSurvey({
            variables: {
              params: {
                surveyResultId: detailSurveyResult?._id,
                data: survey?.data,
                completedStatus: getStatusByAnswer(survey),
                workingStatus: SurveyResultStatus.Review,
                patientId: patientDetail?._id,
              },
            },
          });
        }
        cancelDialog();
      },
      gender,
      mrn,
      tasks: dataSurveyTask?.getPatientFollowUps?.nodes.filter(item =>
        tasks.includes(item._id),
      ),
      checklists: dataSurveyTask?.getPatientChecklists?.nodes?.filter(item =>
        checklists.includes(item.patientChecklists?.patientChecklistId),
      ),
      conditions: dataSurveyTask?.getListPatientStages.nodes?.filter(item =>
        conditions.includes(item._id),
      ),
      notes: detailSurveyResult?.patientNote?.notes?.filter(item =>
        notes.includes(item.idNote),
      ),
      records: getRecordPdf(records),
    });
  };

  const createPdf = () => {
    setOpenSelectDialog(true);
    setIsCreatePdf(true);
  };

  const cancelDialog = useCallback(() => {
    toogleDialog(false);
    patientDetailContext?.handleSetDialogOpen('');
  }, [patientDetailContext, toogleDialog]);

  const onChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    getSurveyData({
      variables: paramsGetAllData,
    });
    setTabIndex(newValue);
  };

  const handleSendEmr = (dataEmr: SendEmrType) => {
    setOpenSelectDialog(false);
    setOpenForm(false);
    setDataSendEmr(dataEmr);
  };

  useEffect(() => {
    //send emr after closing dialog
    if (dataSendEmr) {
      const {
        mrn,
        tasks,
        checklists,
        conditions,
        gender,
        notes,
        records,
      } = dataSendEmr;
      handleGeneratePdf({
        typePdf: 'sendEmr',
        callbackCreatePdf: (doc: jsPDF) => {
          if (doc) {
            let params: ExportEmrInfoMutationVariables['params'] = {
              filename: `${patientName}_${surveyName}_${new Date().getTime()}`.replaceAll(
                ' ',
                '_',
              ),
              patientId: patientDetail?.owner?.idOwner,
              surveyResultId: detailSurveyResult?._id,
            };
            if (mrn) {
              params = { ...params, mrn };
              moreInfoUpdated.current = { ...moreInfoUpdated.current, mrn };
            }
            if (gender) {
              params = { ...params, gender: gender as Gender };
              moreInfoUpdated.current = { ...moreInfoUpdated.current, gender };
            }
            sendEmr({
              variables: {
                params: params,
                file: doc.output('blob'),
              },
            });
            if (survey && survey.completeLastPage()) {
              updateSurvey({
                variables: {
                  params: {
                    surveyResultId: detailSurveyResult?._id,
                    data: survey?.data,
                    completedStatus: getStatusByAnswer(survey),
                    workingStatus: SurveyResultStatus.Review,
                    patientId: patientDetail?._id,
                  },
                },
              });
            }
            doc.save(
              slugify(
                `${surveyName} v${detailSurveyResult?.surveyDetail?.version}
                  ${formatDate(detailSurveyResult?.createdAt)}`,
              ),
            );
          }
        },
        gender,
        mrn,
        tasks: dataSurveyTask?.getPatientFollowUps?.nodes.filter(item =>
          tasks.includes(item._id),
        ),
        checklists: dataSurveyTask?.getPatientChecklists?.nodes?.filter(item =>
          checklists.includes(item.patientChecklists?.patientChecklistId),
        ),
        conditions: dataSurveyTask?.getListPatientStages.nodes?.filter(item =>
          conditions.includes(item._id),
        ),
        notes: detailSurveyResult?.patientNote?.notes?.filter(item =>
          notes.includes(item.idNote),
        ),
        records: getRecordPdf(records),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSendEmr]);

  const checkSendEmr = () => {
    if (
      dataSurveyTask?.getPatientFollowUps?.nodes.length === 0 &&
      dataSurveyTask?.getPatientChecklists?.nodes.length === 0 &&
      dataSurveyTask?.getListPatientStages?.nodes.length === 0 &&
      detailSurveyResult?.patientNote?.notes?.length === 0
    ) {
      if (patientDetail?.owner?.mrn && patientDetail?.owner?.gender) {
        sendAllDataForEmr({
          mrn: patientDetail?.owner?.mrn,
          gender: patientDetail?.owner?.gender,
        });
      } else {
        setOpenForm(true);
      }
      return;
    }
    setIsCreatePdf(false);
    setOpenSelectDialog(true);
  };
  const sendAllDataForEmr = (params: {
    gender: Gender | string;
    mrn: string;
  }) => {
    const { gender, mrn } = params;
    handleSendEmr({
      gender,
      mrn,
      tasks: [],
      conditions: [],
      checklists: [],
      notes: [],
      records: [],
    });
  };

  return (
    <>
      <DialogFilterSendEmr
        open={isOpenSelectDialog}
        toogleDialog={setOpenSelectDialog}
        tasks={dataSurveyTask?.getPatientFollowUps}
        checklists={dataSurveyTask?.getPatientChecklists?.nodes}
        conditions={dataSurveyTask?.getListPatientStages.nodes}
        records={dataSurveyTask?.getPatientTimeRecord?.nodes?.filter(
          item => item?.totalSecond,
        )}
        notes={detailSurveyResult?.patientNote?.notes}
        sendEmr={handleSendEmr}
        surveyId={detailSurveyResult?.surveyDetail?.surveyId}
        surveyName={surveyName || ''}
        loadingEmr={isLoading === 'sendEmr' || loadingEmr}
        isCreatePdf={isCreatePdf}
        downloadPdf={downloadPdf}
      />
      <Dialog onEnter={onEnter} open={open} maxWidth="md" fullWidth>
        <DialogTitleClose
          title={`${isExportPdf ? '' : 'Update'} Survey Result `}
          onClose={cancelDialog}
        />
        <DialogContent>
          <PaperBorder square>
            <Tabs
              indicatorColor="primary"
              value={tabIndex}
              onChange={onChangeTab}
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab className="text-tranform-none" label="Survey Result" />
              <Tab className="text-tranform-none" label="Tasks" />
              <Tab className="text-tranform-none" label="Checklists" />
              <Tab className="text-tranform-none" label="Notes" />
            </Tabs>
          </PaperBorder>
          {Object.is(tabIndex, 0) && (
            <>
              <SurveyDisplayByType
                survey={survey}
                surveyName={surveyName}
                detailSurveyResult={detailSurveyResult}
                version={detailSurveyResult?.surveyDetail?.version}
                handleSetStatus={handleSetStatus}
                status={status}
                checklists={checklistModels}
              />
            </>
          )}

          {Object.is(tabIndex, 1) && (
            <Box mt={1}>
              <FollowUp tabIndexDialog={tabIndex} />
            </Box>
          )}

          {Object.is(tabIndex, 2) && (
            <Box mt={1}>
              <Checklist />
            </Box>
          )}

          {Object.is(tabIndex, 3) && (
            <Box mt={5}>
              <DialogNote
                noteSelected={detailSurveyResult?.patientNote || undefined}
                infoSurveyWhenCreate={
                  detailSurveyResult?.patientNote
                    ? undefined
                    : {
                        result: detailSurveyResult?._id,
                        survey: detailSurveyResult?.surveyDetail?.surveyId,
                      }
                }
                notAbleToUpdateSurvey
                isOnDialog
              />
            </Box>
          )}

          {loadSurveyData && Object.is(tabIndex, 0) && (
            <Skeleton
              className="mt-16"
              variant="rect"
              width="100%"
              height={300}
            />
          )}
        </DialogContent>
        <DialogActions>
          {openForm && (
            <MrnComponent
              handleCallback={sendAllDataForEmr}
              {...mrnComponentProps}
            />
          )}
          <>
            <Box mr="auto">
              {tabIndex === 0 && !patientDetailContext?.isInactive && (
                <>
                  {isExportPdf && (
                    <ButtonLoading
                      text="Create PDF"
                      loading={isLoading === 'createPdf'}
                      Icon={<PictureAsPdf />}
                      callbackClick={createPdf}
                    />
                  )}
                  <ButtonLoading
                    loading={isLoading === 'sendEmr' || loadingEmr}
                    className="ml-16"
                    text="Send to EMR"
                    Icon={<CloudUploadIcon />}
                    callbackClick={checkSendEmr}
                  />
                </>
              )}
            </Box>

            <DialogButton isCancel onClickButton={cancelDialog} />
            {isUpdate &&
              !patientDetailContext?.isInactive &&
              tabIndex === 0 && (
                <ButtonLoading
                  callbackClick={handleUpdateSurvey}
                  Icon={<EditIcon />}
                  text={'Update Survey'}
                  loading={loadingUpdateSurvey}
                />
              )}
            {tabIndex === 3 && !patientDetailContext?.isInactive && (
              <>{renderSignedNotesButton()}</>
            )}
          </>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(DialogAllQuestions);
