import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  ButtonLoading,
  DialogButton,
  DialogTitleClose,
  NoDataTable,
} from 'components';
import { UPDATE_PATIENT_TIME_RECORD } from 'gql';
import {
  useChecked,
  useMutationCustom,
  usePrintBilling,
  useTimeRecord,
} from 'hooks';
import { useSnackbar } from 'notistack';
import { TypographyItalic } from 'share/component_css';
import {
  PatientTimeReportFragment,
  UpdatePatientTimeRecordMutation,
  UpdatePatientTimeRecordMutationVariables,
} from 'types.d';
import { useServices } from '../../services';
import RowTableBilling from './RowTableBilling';
import { formatDate } from 'share/utils';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { PatientDetailContext } from 'share/context';
import { format, isWithinInterval, subMonths } from 'date-fns';
import { FILTER_BILLING_TYPE } from 'CONST';
import { customEvent } from 'modules/google_tag_manager';
type Props = {
  openDialogBilling: boolean;
  setOpenDialogBilling: (open: boolean) => void;
  listRecord: PatientTimeReportFragment[];
  code?: string | null;
};

export type ExtendedPatientTimeReportFragment = PatientTimeReportFragment & {
  label?: string;
};

export const DialogBilling: React.FC<Props> = ({
  openDialogBilling,
  setOpenDialogBilling,
  listRecord,
  code,
}) => {
  const {
    checkItem,
    checkedAll,
    checkAll,
    checkedList,
    setCheckedList,
  } = useChecked(listRecord || []);

  const patientDetailContext = useContext(PatientDetailContext);

  const { createBillingPdf } = usePrintBilling(patientDetailContext?.patient);

  const { refetchQueriesTimeReport } = useServices();

  const { enqueueSnackbar } = useSnackbar();

  const [checked, setChecked] = useState<string>('');

  const { dataByMonth, convertDateTimeByTimezone } = useTimeRecord();

  const recordsLast6Month = useMemo(() => {
    const lastSixMonths = subMonths(new Date(), 6);
    return listRecord?.filter(item =>
      isWithinInterval(new Date(item?.startedAt), {
        start: lastSixMonths,
        end: new Date(convertDateTimeByTimezone(new Date().toISOString())),
      }),
    );
  }, [listRecord, convertDateTimeByTimezone]);

  const recordsLastMonth = useMemo(() => {
    return listRecord?.filter(
      item =>
        format(new Date(item?.startedAt), 'MM/yyyy') ===
        format(new Date(listRecord[0]?.startedAt), 'MM/yyyy'),
    );
  }, [listRecord]);

  const [updateRecord] = useMutationCustom<
    UpdatePatientTimeRecordMutation,
    UpdatePatientTimeRecordMutationVariables
  >({
    refetchQueries: refetchQueriesTimeReport(),
    api: UPDATE_PATIENT_TIME_RECORD,
    callbackSuccess: () => {
      customEvent("BillingRecordPD", "NAVIGATOR")
    },
    textSuccess: 'Billing record successfully',
  });

  const handleCancel = () => {
    setOpenDialogBilling(false);
  };

  const onEnter = () => {
    setChecked('lastMonth');
    setCheckedList(recordsLastMonth?.map(item => item?._id));
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (checked) {
      if (event.target.value === 'lastMonth') {
        setCheckedList(recordsLastMonth?.map(item => item?._id));
      }
      if (event.target.value === 'lastSixMonths') {
        setCheckedList(recordsLast6Month?.map(item => item?._id));
      }
      setChecked(event.target.value);
      return;
    }
    setCheckedList([]);
    setChecked('');
  };

  useEffect(() => {
    if (
      checkedList.length === recordsLastMonth.length &&
      recordsLastMonth?.length === recordsLast6Month?.length
    ) {
      setChecked(checked);
      return;
    }
    if (
      checkedList.length === recordsLastMonth.length &&
      recordsLastMonth
        ?.map(item => item?._id)
        .every(el => checkedList.includes(el))
    ) {
      setChecked('lastMonth');
      return;
    }
    if (
      checkedList.length === recordsLast6Month.length &&
      recordsLast6Month
        ?.map(item => item?._id)
        .every(el => checkedList.includes(el))
    ) {
      setChecked('lastSixMonths');
      return;
    }
    setChecked('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList, recordsLast6Month, recordsLastMonth, setChecked]);

  const printPdf = () => {
    if (checkedList?.length === 0) {
      enqueueSnackbar('Need to select at least one record for billing', {
        variant: 'error',
      });
      return;
    }

    updateRecord({
      variables: {
        params: {
          records: checkedList?.map(item => {
            return { _id: item, status: true };
          }),
        },
      },
    }).then(() => {
      const doc = createBillingPdf({
        records: dataByMonth(
          listRecord.filter(item => checkedList.includes(item?._id)),
        )
          ?.map(item => {
            return [
              ...item,
              {
                _id: null,
                startedAt: null,
                stoppedAt: null,
                second: item.reduce(
                  (acc, curr) => acc + (curr.second ? curr?.second : 0),
                  0,
                ),
                owner: { firstName: '', lastName: '', middleName: '' },
                patientNoteTypes: null,
                label: `Total - ${format(
                  new Date(item[0].startedAt),
                  'MM/yy',
                )}`,
              } as ExtendedPatientTimeReportFragment,
            ];
          })
          ?.flat(),
      });
      doc.save(`${code} - ${formatDate(new Date().toISOString())}`);
      setOpenDialogBilling(false);
    });
  };

  return (
    <>
      <Dialog
        open={openDialogBilling}
        onClose={handleCancel}
        onEnter={onEnter}
        maxWidth="md"
        fullWidth
      >
        <DialogTitleClose title="Billing" onClose={handleCancel} />
        <DialogContent>
          <Box ml={1.5} display="flex" alignItems="center">
            <TypographyItalic variant="subtitle2" color="textSecondary">
              * Select record to change status to Billed
            </TypographyItalic>
            <Box ml={2}>
              {FILTER_BILLING_TYPE?.map(item => (
                <FormControlLabel
                  key={item?.value}
                  control={
                    <Checkbox
                      size="medium"
                      color="primary"
                      value={item?.value}
                      checked={checked === item?.value}
                      onChange={handleChange}
                    />
                  }
                  label={
                    <Typography variant="subtitle2">{item.label}</Typography>
                  }
                />
              ))}
            </Box>
          </Box>
          <TableContainer style={{ maxHeight: 400 }} component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={checkedAll}
                      onChange={checkAll}
                      color="primary"
                      className="mr-16"
                    />
                  </TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Time Spent</TableCell>
                  <TableCell>Navigator</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Note Type</TableCell>
                  <TableCell>Note Type Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listRecord?.length === 0 && <NoDataTable colSpan={6} />}
                {listRecord &&
                  dataByMonth(listRecord)?.map((row, index) => {
                    return (
                      <RowTableBilling
                        key={index}
                        listByMonth={row}
                        checkItem={checkItem}
                        checkedList={checkedList}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Box mr="auto">
            <ButtonLoading
              Icon={<PictureAsPdfIcon />}
              text="Print Pdf"
              callbackClick={printPdf}
            />
          </Box>
          <DialogButton isCancel onClickButton={handleCancel} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(DialogBilling);
