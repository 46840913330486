/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { styled } from '@material-ui/core/styles';
import {
  Avatar,
  Badge,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
  GET_NOTIFICATION_SURVEY,
  GET_NOTIFICATION_NUMBER,
  MARK_SEEN_NOTIFICATION,
} from 'gql';
import { useMutationCustom, useQueryCustom, usePopover } from 'hooks';
import {
  GetNotificationsQuery,
  GetNotificationsQueryVariables,
  GetNumberOfNotificationQuery,
  GetNumberOfNotificationQueryVariables,
  SeenNotificationMutation,
  SeenNotificationMutationVariables,
  NotificationFragment,
} from 'types.d';
import LoadingNotification from './LoadingNotification';
import { useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import NotificationItem from './NotificationItem';
type Props = {};

const ListStyled = styled(List)(({ theme }) => ({
  '@media screen and (min-width:500px)': {
    width: theme.spacing(50),
  },
}));

const NotificationWrapper = styled('div')(({ theme }) => ({
  maxHeight: theme.spacing(50),
  overflowY: 'scroll',
  '@media screen and (min-width:500px)': {
    width: theme.spacing(50),
    '&::-webkit-scrollbar': {
      width: theme.spacing(1),
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  color: theme.palette.primary.dark,
  background: 'none',
  overflow: 'visible',
  cursor: 'pointer',
  margin: theme.spacing(0, 1),
}));

export const NotificationDropdown: React.FC<Props> = () => {
  const client = useApolloClient();

  const [open, close, DefaultPopover, defaultPopoverProps] = usePopover();

  const [loadNotifications, { data, loading }] = useLazyQuery<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >(GET_NOTIFICATION_SURVEY, {
    variables: {
      params: {},
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const unseenIds = data.getNotifications.nodes
        .filter(item => !item.seen)
        .map(item => item._id);

      markAsSeen(unseenIds);
    },
  });

  const { data: dataNumberUnread } = useQueryCustom<
    GetNumberOfNotificationQuery,
    GetNumberOfNotificationQueryVariables
  >({
    api: GET_NOTIFICATION_NUMBER,
  });

  const [seenNotification] = useMutationCustom<
    SeenNotificationMutation,
    SeenNotificationMutationVariables
  >({
    api: MARK_SEEN_NOTIFICATION,
    callbackSuccess: data => {
      client.writeQuery({
        query: GET_NOTIFICATION_NUMBER,
        data: {
          getNumberOfNotification: data.seenNotification,
        },
      });
    },
  });

  const notifications = data?.getNotifications.nodes;
  const unreadNumber = dataNumberUnread?.getNumberOfNotification;

  const markAsSeen = (unseenIds: NotificationFragment['_id'][]) => {
    seenNotification({
      variables: {
        params: {
          ids: [...unseenIds],
        },
      },
    });
  };

  return (
    <>
      <DefaultPopover {...defaultPopoverProps}>
        <Box boxShadow={3} borderRadius={5} padding={1}>
          <ListStyled
            subheader={
              <ListSubheader
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                }}
              >
                Notifications
              </ListSubheader>
            }
          >
            <NotificationWrapper>
              {loading ? (
                <LoadingNotification />
              ) : notifications && notifications.length ? (
                notifications.map(notification => (
                  <NotificationItem
                    closePopover={close}
                    notification={notification}
                  />
                ))
              ) : (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <NotificationsIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText secondary="You have no notification." />
                </ListItem>
              )}
            </NotificationWrapper>
          </ListStyled>
        </Box>
      </DefaultPopover>

      <AvatarStyled
        onClick={e => {
          open(e);
          if (!data) {
            loadNotifications();
          }
        }}
        aria-haspopup="true"
        aria-controls="dropdown-notification"
      >
        <Badge badgeContent={unreadNumber || null} color="primary">
          <NotificationsIcon />
        </Badge>
      </AvatarStyled>
    </>
  );
};

export default React.memo(NotificationDropdown);
