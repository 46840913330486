import React, { useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Box,
  DialogContent,
} from '@material-ui/core';
import { ButtonLoading } from 'components';
import { TypographyItalic } from 'share/component_css';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  GetReleaseNoteQuery,
  GetReleaseNoteQueryVariables,
  ReleaseNoteSettingFragment,
} from 'types.d';
import CancelIcon from '@material-ui/icons/Cancel';
import ReactMarkdown from 'react-markdown';
import { styled } from '@material-ui/core/styles';
import { GET_RELEASE_NOTE } from 'gql';
import { useLazyQuery } from '@apollo/react-hooks';

type Props = {
  open: boolean;
  refresh: () => void;
  cancel: () => void;
  releaseNote?: ReleaseNoteSettingFragment;
};

const ReactMarkdownStyled = styled(ReactMarkdown)({
  '& h3': {
    margin: 0,
  },
  '& ul': {
    marginTop: 0,
  },
  '& ul li': {
    fontSize: '1rem',
    paddingBottom: '4px',
  },
  '& ul li::marker': {
    fontSize: '1.5rem',
  },
});

export const DialogNewFeatures: React.FC<Props> = ({
  open,
  refresh,
  cancel,
  releaseNote,
}) => {
  const [getReleaseNotes, { loading, data }] = useLazyQuery<
    GetReleaseNoteQuery,
    GetReleaseNoteQueryVariables
  >(GET_RELEASE_NOTE, {
    fetchPolicy: 'cache-and-network',
  });

  const renderContent = useMemo(() => {
    if (releaseNote) {
      return releaseNote;
    }
    if (data && data?.getReleaseNote?.nodes?.length !== 0) {
      return data?.getReleaseNote?.nodes[0];
    }
  }, [data, releaseNote]);

  const onEnter = () => {
    getReleaseNotes({
      variables: {
        params: { limit: 1 },
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={cancel}
      onEnter={onEnter}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {renderContent?.version
          ? `Version ${renderContent?.version}`
          : 'Release note'}
      </DialogTitle>
      <DialogContent className="pt-0">
        {loading ? (
          <Skeleton width={'100%'} variant="rect" height={300} />
        ) : (
          <>
            {' '}
            {!releaseNote && (
              <TypographyItalic
                className="mb-8"
                variant="subtitle1"
                color="textSecondary"
              >
                Please refresh page to have the latest update version
              </TypographyItalic>
            )}
            <Box display="grid">
              <ReactMarkdownStyled source={renderContent?.releaseNote} />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {releaseNote ? (
          <ButtonLoading
            Icon={<CancelIcon color="disabled" />}
            color="default"
            text="Close"
            callbackClick={cancel}
          />
        ) : (
          <ButtonLoading text="Refresh" callbackClick={refresh} />
        )}
      </DialogActions>
    </Dialog>
  );
};
export default React.memo(DialogNewFeatures);
