import React, { useContext } from 'react';
import * as Survey from 'survey-react';
import { SurveyResultEmbeddedFragment, SurveyResultStatus } from 'types.d';
import { DataEditSurveyResultInterface } from '../interfaces';
import { formatDate } from 'share/utils';
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from '@material-ui/core';
import { TypographyBold, TypographyGreenStyled } from 'share/component_css';
import PatientInfo from './PatientDetail/PatientInfo';
import SurveyDisplay from './SurveyDisplay';
import { TableNoteSurvey } from './TableNoteSurvey';
import { BoxWrapSurvey } from '../styles';
import { PatientDetailContext } from 'share/context';
type Props = {
  detailSurveyResult?:
    | SurveyResultEmbeddedFragment
    | DataEditSurveyResultInterface;
  surveyName?: string | null;
  survey?: Survey.ReactSurveyModel;
  handleSetStatus?: (status: SurveyResultStatus) => void;
  status?: SurveyResultStatus;
  checklists?: Survey.ReactSurveyModel[];
  version?: number | null;
};

export const SurveyDisplayByType: React.FC<Props> = ({
  detailSurveyResult,
  surveyName,
  survey,
  status,
  handleSetStatus,
  checklists,
  version,
}) => {
  const isEditAllQuestion = (
    data?: SurveyResultEmbeddedFragment | DataEditSurveyResultInterface,
  ): data is SurveyResultEmbeddedFragment => {
    return (data as SurveyResultEmbeddedFragment)?._id !== undefined;
  };

  const patientDetailContext = useContext(PatientDetailContext);

  return (
    <>
      {survey && (
        <>
          <Grid container spacing={1}>
            {status && (
              <>
                <Grid item xs={12}>
                  <TypographyBold className="mt-8" variant="h6">
                    Status
                  </TypographyBold>
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    value={status}
                    onChange={(e, value) =>
                      handleSetStatus!(value as SurveyResultStatus)
                    }
                    row
                  >
                    <FormControlLabel
                      disabled={
                        (isEditAllQuestion(detailSurveyResult) &&
                          detailSurveyResult.workingStatus ===
                            SurveyResultStatus.Review) ||
                        patientDetailContext?.isInactive
                      }
                      value={SurveyResultStatus.New}
                      control={<Radio color="primary" />}
                      label="New"
                    />
                    <FormControlLabel
                      disabled={patientDetailContext?.isInactive}
                      value={SurveyResultStatus.Review}
                      control={<Radio color="primary" />}
                      label="Reviewed"
                    />
                  </RadioGroup>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TypographyBold className="mt-8" variant="h6">
                Patient Information
              </TypographyBold>
            </Grid>
            <PatientInfo exportPdf />
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" className="mt-8">
                <TypographyBold variant="h6">{surveyName}</TypographyBold>
                <TypographyGreenStyled
                  className="ml-8 mr-4"
                  variant="subtitle2"
                >
                  {`v${version} `}
                </TypographyGreenStyled>
                <TypographyBold variant="h6">
                  -{' '}
                  {formatDate(
                    isEditAllQuestion(detailSurveyResult)
                      ? detailSurveyResult.createdAt
                      : detailSurveyResult?.response.createdAt,
                  )}
                </TypographyBold>
              </Box>
            </Grid>
            <Grid id="main-survey" item xs={12}>
              <SurveyDisplay survey={survey} />
            </Grid>
            {checklists?.map((item, index) => {
              return (
                <Grid className="checklist-items" key={index} item xs={12}>
                  <SurveyDisplay survey={item} />
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <TableNoteSurvey
                detailSurveyResult={detailSurveyResult}
                isEdit={isEditAllQuestion}
              />
            </Grid>
          </Grid>
        </>
      )}
      <BoxWrapSurvey zIndex="-1" width="100%">
        <Box className="sv_main sv_default_css">
          <Box className="sv_container">
            <Box id="sp_100" className="sv_body">
              <Box className="sv_p_root">
                <Box id="generate-question"></Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </BoxWrapSurvey>
    </>
  );
};

export default React.memo(SurveyDisplayByType);
