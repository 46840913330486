import React, { useContext, useEffect, useRef } from 'react';
import {
  DialogActions,
  DialogContent,
  Dialog,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Box,
} from '@material-ui/core';
import {
  ButtonLoading,
  DialogTitleClose,
  TextfieldSelect,
  DefaultTable,
} from 'components';
import DialogButton from 'components/DialogButton';
import { PatientDetailContext } from 'share/context';
import { Controller, useForm } from 'react-hook-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { USER_STATUS } from 'CONST';
import SearchIcon from '@material-ui/icons/Search';
import { GET_PATIENTS } from 'gql';
import { GetPatientsQuery, GetPatientsQueryVariables } from 'types.d';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  convertFilterPatient,
  getStartOfDay,
  isBotMail,
  formatDate,
  formatPhoneNumber,
  renderStatusUser,
} from 'share/utils';
import RowPatientInfo from './RowPatientInfo';
import { styled } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { usePagination, InfoPagination } from 'hooks';

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
  treatmentSelected?: string;
  handleClose: () => void;
};

export const GridStyled = styled(Grid)(({ theme }) => ({
  position: 'sticky',
  backgroundColor: 'rgb(255, 255, 255)',
  zIndex: 11,
  top: 0,
}));

type FormData = {
  type: string;
  operator: string;
  dob: string | null;
  valueFilter: string;
  status: string;
};

const labelFilterPatient: { [key: string]: string } = {
  lastName: 'Last Name',
  firstName: 'First Name',
  mrn: 'MRN',
  email: 'Email',
  phone: 'Phone',
};

export const DialogSearchPatient: React.FC<Props> = ({
  open,
  toogleDialog,
  treatmentSelected,
  handleClose,
}) => {
  const methods = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      type: 'firstName',
      operator: 'equal',
      status: '',
      dob: null,
    },
  });

  const currentSearch = useRef<FormData>();

  const { handleSubmit, control, watch, setValue } = methods;

  const callbackFetchData = (infoPagination: InfoPagination) => {
    if (currentSearch.current) {
      const params = renderParams(currentSearch.current, infoPagination);
      getListPatient({
        variables: {
          params,
        },
      });
    }
  };

  const {
    infoPagination,
    DefaultPagination,
    defaultPaginationProps,
    handleSetInfoPagination,
  } = usePagination(callbackFetchData);

  const [
    getListPatient,
    { loading: loadingPatients, data: listPatient },
  ] = useLazyQuery<GetPatientsQuery, GetPatientsQueryVariables>(GET_PATIENTS, {
    fetchPolicy: 'cache-and-network',
  });
  const watchType = watch('type');

  const watchDob = watch('dob');

  useEffect(() => {
    if (watchType) {
      setValue('valueFilter', '');
      setValue('operator', 'equal');
    }
  }, [setValue, watchType]);

  const patientDetailContext = useContext(PatientDetailContext);

  const handleSearch = handleSubmit(data => {
    const params = renderParams(data);
    currentSearch.current = data;
    getListPatient({
      variables: {
        params,
      },
    });
    handleSetInfoPagination({ page: 1, limit: infoPagination.limit });
  });

  const renderParams = (data: FormData, pagination?: InfoPagination) => {
    let newRule: { [key: string]: any } = {};
    const objRule: { [key: string]: any } = {
      firstName: {
        key: 'owner.firstName',
        value: convertFilterPatient(
          data.valueFilter as string,
          data.operator as string,
        ),
      },
      lastName: {
        key: 'owner.lastName',
        value: convertFilterPatient(
          data.valueFilter as string,
          data.operator as string,
        ),
      },
      email: {
        key: 'owner.email',
        value: convertFilterPatient(
          data.valueFilter as string,
          data.operator as string,
        ),
      },
      dob: {
        key: 'owner.dob',
        value:
          data.dob && !isNaN(new Date(data.dob).getTime())
            ? getStartOfDay(data.dob)
            : null,
      },
      mrn: {
        key: 'owner.mrn',
        value: convertFilterPatient(
          data.valueFilter as string,
          data.operator as string,
        ),
      },
      phone: {
        key: 'owner.phones',
        value: {
          $elemMatch: {
            phone: convertFilterPatient(
              data.valueFilter as string,
              data.operator as string,
            ),
          },
        },
      },
      status: {
        key: 'owner.status',
        value: data?.status === '' ? undefined : data?.status,
      },
    };
    newRule[objRule[watchType].key] = objRule[watchType].value;
    const rules = [{ ...newRule }];
    const defaultParams = {
      order: { createdAt: -1 },
      page: pagination?.page || 1,
      limit: pagination?.limit || infoPagination.limit,
    };
    return watchType === 'dob' && !watchDob
      ? defaultParams
      : {
          ...defaultParams,
          condition: {
            $and: rules,
          },
        };
  };

  const handleClosePopover = () => {
    toogleDialog(false);
    handleClose();
    patientDetailContext?.handleSetDialogOpen('');
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    handleSearch();
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitleClose title="Search Patient" onClose={handleClosePopover} />
      <DialogContent className="pt-0">
        <form onSubmit={onSubmit}>
          <GridStyled container spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
              <TextfieldSelect control={control} label="Type" name="type" small>
                <MenuItem value="dob">DoB</MenuItem>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="firstName">First Name</MenuItem>
                <MenuItem value="lastName">Last Name</MenuItem>
                <MenuItem value="mrn">MRN</MenuItem>
                <MenuItem value="phone">Phone</MenuItem>
                <MenuItem value="status">Status</MenuItem>
              </TextfieldSelect>
            </Grid>
            {watchType !== 'status' && watchType !== 'dob' && (
              <Grid item xs={12} sm={4} md={3}>
                <TextfieldSelect
                  control={control}
                  label="Operator"
                  name="operator"
                  small
                >
                  <MenuItem value="equal">=</MenuItem>
                  <MenuItem value="contain">Contains</MenuItem>
                  <MenuItem value="startWith">Starts With</MenuItem>
                  <MenuItem value="endWith">Ends With</MenuItem>
                </TextfieldSelect>
              </Grid>
            )}
            <Grid item xs={12} sm={4} md={4}>
              {Object.is(watchType, 'dob') && (
                <Controller
                  as={
                    <KeyboardDatePicker
                      placeholder="mm/dd/yyyy"
                      inputVariant="outlined"
                      label="Date of Birth"
                      format="MM/dd/yyyy"
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      value={() => {}}
                      onChange={() => {}}
                      variant="inline"
                      autoOk
                      keyboardIcon={<DateRangeIcon />}
                    />
                  }
                  name="dob"
                  control={control}
                  rules={{
                    validate: {
                      pattern: value => {
                        return (
                          !isNaN(new Date(value).getTime()) ||
                          'Invalid date format'
                        );
                      },
                    },
                  }}
                />
              )}
              {watchType !== 'dob' && watchType !== 'status' && (
                <TextField
                  label={labelFilterPatient[watchType as string]}
                  inputProps={{ maxLength: 255 }}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  inputRef={methods!.register()}
                  name="valueFilter"
                />
              )}
              {watchType === 'status' && (
                <TextfieldSelect
                  control={control}
                  label="Status"
                  name="status"
                  small
                >
                  <MenuItem value="">All</MenuItem>
                  {Object.keys(USER_STATUS).map(item => {
                    return (
                      <MenuItem key={item} value={item}>
                        {USER_STATUS[item]}
                      </MenuItem>
                    );
                  })}
                </TextfieldSelect>
              )}
            </Grid>
            <Grid item xs={2}>
              <ButtonLoading
                className="p-6"
                textTransform="initial"
                text="Search"
                color="primary"
                callbackClick={e => onSubmit(e)}
                fullWidth
                size="large"
                Icon={<SearchIcon />}
                loading={loadingPatients}
              />
            </Grid>
          </GridStyled>
        </form>
        <Box mt={3}>
          <DefaultTable
            heads={[
              { text: 'Name', width: '200px' },
              { text: 'MRN' },
              { text: 'Email', width: '250px' },
              { text: 'Date of Birth' },
              { text: 'Phone' },
              { text: 'Status' },
              { text: 'Actions' },
            ]}
            isOnComponent
            minWidth="100%"
            isWrapped
            maxHeight={400}
            loading={loadingPatients}
            content={
              listPatient?.getPatients?.nodes.map(item => {
                return {
                  key: item._id,
                  cells: [
                    <Typography
                      className="word-break-all"
                      variant="subtitle2"
                    >{`${item?.owner?.firstName} ${item?.owner?.middleName ||
                      ''} ${item?.owner?.lastName}`}</Typography>,
                    <Typography className="word-break-all" variant="subtitle2">
                      {item?.owner?.mrn || '--'}
                    </Typography>,
                    <Typography className="word-break-all" variant="subtitle2">
                      {!isBotMail(item?.owner?.email)
                        ? item?.owner?.email
                        : '--'}
                    </Typography>,
                    <>
                      {item?.owner?.dob ? formatDate(item?.owner?.dob) : '--'}
                    </>,
                    <>
                      {item?.owner?.phones && item?.owner?.phones?.length !== 0
                        ? formatPhoneNumber(
                            item?.owner?.phones?.find(item => item.default)
                              ?.phone || item?.owner?.phones[0]?.phone,
                          )
                        : '--'}
                    </>,
                    <>
                      {item?.owner?.status
                        ? renderStatusUser(item?.owner?.status as string)
                        : '--'}
                    </>,
                    <RowPatientInfo
                      item={item}
                      treatmentSelected={treatmentSelected}
                    />,
                  ],
                };
              }) || []
            }
          />
          <DefaultPagination
            loading={loadingPatients}
            {...defaultPaginationProps}
            count={listPatient?.getPatients?.meta?.total || 0}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogButton isCancel onClickButton={handleClosePopover} />
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(DialogSearchPatient);
