import { useState, useCallback, useEffect } from 'react';
import { useToogleDialog } from './useToogleDialog';

export interface ObjectData {
  [key: string]: any;
  createdAt?: any | null;
}

export const useChecked = <T extends ObjectData>(
  listData: T[],
  isNeedData?: boolean,
) => {
  const [checkedAll, setCheckedAll] = useToogleDialog();

  const [checkedList, setCheckedList] = useState<string[]>([]);

  const [checkedDataList, setCheckedDataList] = useState<any>([]);

  const checkItem = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement> | boolean,
      value: string,
      data?: any,
    ) => {
      const checked = typeof event === 'boolean' ? event : event.target.checked;
      if (checked) {
        setCheckedAll([...checkedList, value].length === listData.length);
        setCheckedList([...checkedList, value]);
        if (isNeedData) {
          setCheckedDataList([...checkedDataList, data]);
        }
        return;
      }
      setCheckedAll(false);
      setCheckedList([...checkedList].filter(item => item !== value));
      if (isNeedData) {
        setCheckedDataList(
          [...checkedDataList].filter(item => {
            return item._id !== value;
          }),
        );
      }
    },
    [checkedList, listData.length, setCheckedAll, checkedDataList, isNeedData],
  );
  const checkAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedAll(event.target.checked);
      const checkedIds = event.target.checked
        ? Array.from(
            new Set([
              ...checkedList,
              ...(listData.map(item => item._id || item) || []),
            ]),
          )
        : checkedList.filter(
            item => !listData.map(item => item._id || item).includes(item),
          );
      setCheckedList(checkedIds);
      if (isNeedData) {
        setCheckedDataList(
          Object.values(
            [...checkedDataList, ...listData].reduce((total, current) => {
              return { ...total, [current._id]: current };
            }, {}),
          ).filter((item: any) => {
            return checkedIds.includes(item._id);
          }),
        );
      }
    },
    [checkedList, listData, setCheckedAll, checkedDataList, isNeedData],
  );

  const resetCheckbox = useCallback(() => {
    setCheckedAll(false);
    setCheckedList([]);
    if (isNeedData) {
      setCheckedDataList([]);
    }
  }, [setCheckedAll, setCheckedDataList, isNeedData]);

  useEffect(() => {
    const listId = listData.map(item => item._id || item);

    if (
      listId.length !== 0 &&
      new Set([...listId, ...checkedList]).size === checkedList.length
    ) {
      setCheckedAll(true);
      return;
    }
    setCheckedAll(false);
  }, [checkedList, listData, setCheckedAll]);
  return {
    checkItem,
    checkAll,
    checkedAll,
    setCheckedAll,
    checkedList,
    setCheckedList,
    resetCheckbox,
    checkedDataList,
  };
};
