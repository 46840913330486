import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';

type Props = {
  onClickButton?: Function;
  isCancel?: boolean;
  loading?: boolean;
  className?: string;
  isSubmit?: boolean;
  disabled?: boolean;
};
export const DialogButton: React.FC<Props> = ({
  onClickButton,
  isCancel,
  loading,
  className,
  isSubmit,
  disabled,
}) => {
  const handleClick = (e: any) => {
    e.stopPropagation();
    if (onClickButton) {
      onClickButton();
    }
  };
  return isCancel ? (
    <Button
      className={className || ''}
      startIcon={<CancelIcon color="disabled" />}
      onClick={handleClick}
      variant="contained"
    >
      Cancel
    </Button>
  ) : (
    <Button
      className={className || ''}
      variant="contained"
      color="primary"
      type={isSubmit ? 'submit' : 'button'}
      onClick={handleClick}
      disabled={loading || disabled}
      startIcon={
        loading ? <CircularProgress size={20} color="inherit" /> : <CheckIcon />
      }
    >
      Save
    </Button>
  );
};

export default React.memo(DialogButton);
