import React, {
  useRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {
  ButtonLoading,
  ButtonDelete,
  NoDataTable,
  LoadingTable,
} from 'components';
import EditIcon from '@material-ui/icons/Edit';
import {
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import {
  PatientNoteFragment,
  GetPatientNotesQuery,
  GetPatientNotesQueryVariables,
  DeletePatientNoteMutation,
  DeletePatientNoteMutationVariables,
} from 'types.d';
import { formatDate, generateLabelNote } from 'share/utils';
import { GET_PATIENT_NOTES, DELETE_PATIENT_NOTE, GET_NOTE_TAG } from 'gql';
import { PatientDetailContext } from 'share/context';
import DialogNote from './DialogNote';
import {
  useQueryCustom,
  useMutationCustom,
  useToogleDialog,
  useUpdateMeClient,
} from 'hooks';
import {
  TableContainerStyled,
  TableCellMaxWidthStyled,
  TypoThreeDotsLine,
  PaperBorder,
} from 'share/component_css';
import { useApolloClient } from '@apollo/react-hooks';
import AddIcon from '@material-ui/icons/Add';
import { useServices } from '../../services';
type Props = {};

export const Note: React.FC<Props> = () => {
  const [isOpen, toogleDialog] = useToogleDialog();

  const { updateSurveyResultLocal } = useServices();

  const patientDetailContext = useContext(PatientDetailContext);

  const { meClient } = useUpdateMeClient();

  const client = useApolloClient();

  const noteSelected = useRef<PatientNoteFragment | undefined>(undefined);

  const idNoteDeleted = useRef<string | undefined>(undefined);

  const surveyResultOfNoteDeleted = useRef<string | undefined>(undefined);

  const { data: dataNotes, loading: loadingNotes } = useQueryCustom<
    GetPatientNotesQuery,
    GetPatientNotesQueryVariables
  >({
    api: GET_PATIENT_NOTES,
    variables: {
      params: {
        patientId: patientDetailContext?.patient.owner?.idOwner,
      },
    },
  });

  const updateCacheNote = useCallback(
    (newListNote: PatientNoteFragment[]) => {
      client.writeQuery({
        query: GET_PATIENT_NOTES,
        variables: {
          params: {
            patientId: patientDetailContext?.patient.owner?.idOwner,
          },
        },
        data: {
          getPatientNotes: {
            ...(dataNotes?.getPatientNotes || {}),
            nodes: newListNote,
          },
        },
      });
    },
    [client, dataNotes, patientDetailContext],
  );

  const [
    deletePatientNote,
    { loading: loadingDeletePatientNote },
  ] = useMutationCustom<
    DeletePatientNoteMutation,
    DeletePatientNoteMutationVariables
  >({
    api: DELETE_PATIENT_NOTE,
    textSuccess: 'Delete note successfully',
    callbackSuccess: data => {
      //update cache all notes
      const newNotes = [
        ...(dataNotes?.getPatientNotes?.nodes.filter(
          item => item._id !== idNoteDeleted.current,
        ) || []),
      ];
      updateCacheNote(newNotes);

      //update cache notes on tag
      client.writeQuery({
        query: GET_NOTE_TAG,
        variables: {
          params: {
            patientId: patientDetailContext?.patient.owner?.idOwner,
            page: 1,
            limit: meClient.setting?.perPage || 5,
          },
        },
        data: {
          getPatientNotes: {
            ...(dataNotes?.getPatientNotes || {}),
            nodes: newNotes.slice(0, meClient.setting?.perPage || 5),
          },
        },
      });
      //remove patient note of survey result local
      updateSurveyResultLocal(surveyResultOfNoteDeleted.current);
      idNoteDeleted.current = undefined;
      surveyResultOfNoteDeleted.current = undefined;
    },
  });

  const editNote = (note: PatientNoteFragment) => {
    noteSelected.current = note;
    toogleDialog(true);
  };

  const addNote = () => {
    toogleDialog(true);
    noteSelected.current = undefined;
  };

  const handleDeleteNote = (key: string, note: PatientNoteFragment) => {
    idNoteDeleted.current = key;
    surveyResultOfNoteDeleted.current = note.surveyResult?.surveyResultId;
    deletePatientNote({
      variables: {
        params: {
          noteId: key,
          patientId: patientDetailContext?.patient.owner?.idOwner,
        },
      },
    });
  };

  //open adding or updating dialog from outside (button + on task )
  useEffect(() => {
    //open adding dialog
    if (patientDetailContext?.dialogOpen === 'add-note') {
      addNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNote, patientDetailContext?.dialogOpen]);

  const isShowAddIcon = useMemo(() => {
    return (
      !patientDetailContext?.isAnonymous &&
      patientDetailContext?.patient.owner &&
      !patientDetailContext.isInactive
    );
  }, [patientDetailContext]);

  const isInactive = useMemo(() => {
    return patientDetailContext?.isInactive;
  }, [patientDetailContext]);

  return (
    <>
      {isOpen && (
        <DialogNote
          noteSelected={noteSelected.current}
          open={isOpen}
          toogleDialog={toogleDialog}
        />
      )}
      {isShowAddIcon && (
        <ButtonLoading
          className="mb-8"
          Icon={<AddIcon />}
          text="Add note"
          callbackClick={addNote}
        />
      )}
      <TableContainerStyled component={PaperBorder}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell align={!isInactive ? 'inherit' : 'right'}>
                Survey
              </TableCell>
              {!isInactive && <TableCell align="right">Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loadingNotes &&
              dataNotes?.getPatientNotes?.nodes?.length === 0 && (
                <NoDataTable colSpan={isInactive ? 3 : 4} />
              )}
            {loadingNotes && <LoadingTable colSpan={isInactive ? 3 : 4} />}
            {!loadingNotes &&
              dataNotes?.getPatientNotes?.nodes?.map(note => {
                return (
                  <TableRow key={note._id}>
                    <TableCellMaxWidthStyled maxwidth="200px">
                      <TypoThreeDotsLine variant="subtitle2">
                        {generateLabelNote(note.notes) !== null
                          ? generateLabelNote(note.notes)?.description
                          : 'N/A'}
                      </TypoThreeDotsLine>
                    </TableCellMaxWidthStyled>
                    <TableCell>
                      {note?.createdAt
                        ? formatDate(note?.createdAt, true)
                        : 'N/A'}
                    </TableCell>
                    <TableCell align={!isInactive ? 'inherit' : 'right'}>
                      {note.survey?.name || 'N/A'}
                    </TableCell>
                    {!isInactive && (
                      <TableCell align="right">
                        <Tooltip title="Edit Note">
                          <IconButton
                            onClick={() => editNote(note)}
                            color="primary"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>

                        <ButtonDelete
                          loadingDelete={loadingDeletePatientNote}
                          deleteItem={key => handleDeleteNote(key, note)}
                          id={note?._id}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainerStyled>
    </>
  );
};
export default React.memo(Note);
