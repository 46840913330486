import React, { useMemo } from 'react';
import {
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Box,
  Typography,
} from '@material-ui/core';
import { ResponseShareTreatmentResFragment } from 'types.d';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {
  PaperBorder,
  TableCellMaxWidthStyled,
  TableFullWidthStyled,
  TypoThreeDotsLine,
} from 'share/component_css';
import { styled } from '@material-ui/core/styles';
import {
  capitalizeFirstLetter,
  formatPhoneNumber,
  isBotMail,
} from 'share/utils';
import NoDataTable from './NoDataTable';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

type Props = {
  list: ResponseShareTreatmentResFragment[];
  listCreateNew?: string[];
  isSendSMS?: boolean;
};

const CheckIconStyled = styled(CheckCircleOutlineIcon)(({ theme }) => ({
  color: theme.palette.success.main,
}));
export const TableListSuccess: React.FC<Props> = ({
  list,
  listCreateNew,
  isSendSMS,
}) => {
  const listSortBySuccess = useMemo(() => {
    return [...list].sort((a, b) => {
      return a === b ? 0 : a ? -1 : 1;
    });
  }, [list]);

  const renderIcon = (status: boolean) => {
    if (status) {
      return <CheckIconStyled className="mr-8" />;
    }
    return <ErrorOutlineIcon color="secondary" className="mr-8" />;
  };

  const listSucces = (item: ResponseShareTreatmentResFragment) => {
    const defaulMessage = ['Send successfully'];
    if (
      listCreateNew?.includes(
        item?.user?.email ||
          (item?.user?.phones && item?.user?.phones[0]?.phone),
      )
    ) {
      return [...defaulMessage, 'Create account successfully'];
    }
    return defaulMessage;
  };

  return (
    <>
      <TableContainer component={PaperBorder}>
        <TableFullWidthStyled stickyHeader>
          <TableHead>
            <TableRow>
              {!isSendSMS && <TableCell>Email</TableCell>}
              {(isSendSMS || !listCreateNew) && <TableCell>Mobile</TableCell>}
              <TableCell>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!listSortBySuccess ||
              (listSortBySuccess?.length === 0 && <NoDataTable colSpan={4} />)}
            {listSortBySuccess.map((item, index) => {
              return (
                <TableRow key={index}>
                  {!isSendSMS && (
                    <TableCellMaxWidthStyled maxwidth="300px">
                      <Box display="flex" alignItems="center">
                        {item?.user?.sendEmail ? (
                          <>
                            {renderIcon(item?.result)}
                            {item?.user?.email && !isBotMail(item?.user?.email)
                              ? item.user?.email
                              : 'N/A'}
                          </>
                        ) : (
                          <Typography className="ml-8">--</Typography>
                        )}
                      </Box>
                    </TableCellMaxWidthStyled>
                  )}
                  {(isSendSMS || !listCreateNew) && (
                    <TableCellMaxWidthStyled maxwidth="300px">
                      <Box display="flex" alignItems="center">
                        {item?.user?.sendSMS ? (
                          <>
                            {renderIcon(item?.result)}
                            {item?.user?.phones
                              ? formatPhoneNumber(item.user.phones[0]?.phone)
                              : 'N/A'}
                          </>
                        ) : (
                          <Typography className="ml-8">--</Typography>
                        )}
                      </Box>
                    </TableCellMaxWidthStyled>
                  )}
                  <TableCellMaxWidthStyled maxwidth="300px">
                    {item?.userError && item?.userError?.length !== 0
                      ? item?.userError[0]
                      : listSucces(item)?.map(message => {
                          return (
                            <TypoThreeDotsLine
                              variant="subtitle2"
                              linenumber={2}
                            >
                              {`${listSucces?.length === 1 ? '' : '-'} ${
                                message ? capitalizeFirstLetter(message) : ''
                              }`}
                            </TypoThreeDotsLine>
                          );
                        })}
                  </TableCellMaxWidthStyled>
                </TableRow>
              );
            })}
          </TableBody>
        </TableFullWidthStyled>
      </TableContainer>
    </>
  );
};
export default React.memo(TableListSuccess);
