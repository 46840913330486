/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useMemo, useCallback } from 'react';
import {
  Box,
  IconButton,
  Typography,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import { formatDate, convertDate } from 'share/utils';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { TextFieldDisabledBlack } from 'share/component_css';
import { OPTIONS_DATE_RANGE } from 'CONST';
import { ButtonLoading } from './ButtonLoading';
import DialogFilterDate from 'modules/dashboard/components/Dialogs/DialogFilterDate';
import { DataFilterDate } from 'modules/dashboard/interfaces';
type Props = {
  label?: string;
  date?: DataFilterDate | string;
  filterDate: (date?: DataFilterDate | string) => void;
  disabled?: boolean;
  isButton?: boolean;
  isIconButton?: boolean;
  //when you choose date range options such as today, lastweek,... the date will be rendered by today
  isFlexibleValue?: boolean;
};

export const DateRange: React.FC<Props> = ({
  label,
  date,
  filterDate,
  disabled,
  isButton,
  isIconButton,
  isFlexibleValue,
}) => {
  const [
    anchorElDateRange,
    setAnchorElDateRange,
  ] = React.useState<HTMLButtonElement | null>(null);
  const anchorTarget = useRef<any>(null);

  const handleClick = useCallback(
    (event: any) => {
      if (!!disabled) {
        return;
      }
      setAnchorElDateRange(event.currentTarget);
      event.persist();
      anchorTarget.current = event.currentTarget;
    },
    [disabled],
  );

  const handleFilterDate = (date?: DataFilterDate | string) => {
    filterDate(date);
    setAnchorElDateRange(null);
  };
  const renderValue = useCallback(
    (isTooltip?: boolean) => {
      if (typeof date === 'string') {
        const titleOption = OPTIONS_DATE_RANGE.find(item => item.value === date)
          ?.title;
        if (date === 'allDates') {
          return titleOption;
        }
        const dateConverted = convertDate(date);
        return `${isTooltip ? `${titleOption} : ` : ''} ${formatDate(
          dateConverted?.startDate as string,
        )} - ${formatDate(dateConverted?.endDate as string)}`;
      }
      return date?.startDate && date?.endDate
        ? `${formatDate(date?.startDate)} - ${formatDate(date?.endDate)}`
        : OPTIONS_DATE_RANGE.find(item => item.value === 'allDates')?.title;
    },
    [date],
  );

  const renderTypeFilter = useMemo(() => {
    if (isButton) {
      return (
        <ButtonLoading
          text="Filter"
          callbackClick={handleClick}
          Icon={<DateRangeIcon />}
          color="default"
        />
      );
    }
    if (isIconButton) {
      return (
        <Box
          display="flex"
          alignItems="center"
          minWidth={195}
          justifyContent="end"
        >
          <Typography variant="subtitle2" color="textSecondary">
            {renderValue() || 'All Dates'}
          </Typography>
          <Tooltip title="Date filter">
            <IconButton aria-label="date filter" onClick={handleClick}>
              <DateRangeIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      );
    }
    return (
      <Tooltip arrow title={renderValue(true) || ''}>
        <TextFieldDisabledBlack
          disabledtext={disabled ? 'true' : undefined}
          label={label}
          size="small"
          fullWidth
          disabled
          placeholder="mm/dd/yyyy : mm/dd/yyyy"
          value={renderValue()}
          variant="outlined"
          onClick={handleClick}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <DateRangeIcon style={{ cursor: 'pointer' }} />
              </InputAdornment>
            ),
          }}
        />
      </Tooltip>
    );
  }, [disabled, handleClick, isButton, isIconButton, label, renderValue]);

  return (
    <>
      <DialogFilterDate
        anchorEl={anchorElDateRange}
        handleClose={() => setAnchorElDateRange(null)}
        filterData={handleFilterDate}
        dateSelected={date as DataFilterDate | undefined}
        isFlexibleValue={isFlexibleValue}
      />

      {renderTypeFilter}
    </>
  );
};

export default React.memo(DateRange);
