import React, { useMemo, useContext } from 'react';
import { useToogleDialog } from 'hooks';
import { isIOS } from 'share/utils';
import GetAppIcon from '@material-ui/icons/GetApp';
import { ButtonLoading } from 'components';
import { DialogIosPwaInstall } from './DialogIosPwaInstall';
import { useQuery } from '@apollo/react-hooks';
import { useTheme } from '@material-ui/core/styles';
import { MENU_STATUS } from 'gql';
import { useMediaQuery, Tooltip } from '@material-ui/core';
import { PromptToInstallContext } from 'share/context';

type Props = {};

export const ButtonInstallPwa: React.FC<Props> = () => {
  const theme = useTheme();

  const promtToInstallContext = useContext(PromptToInstallContext);

  const { data: dataMenuStatus } = useQuery(MENU_STATUS);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [isOpenInstallIos, setOpenInstallIOs] = useToogleDialog();

  const installPwa = () => {
    if (isIOS()) {
      setOpenInstallIOs(true);
    } else {
      promtToInstallContext?.handleWebInstallAccepted();
    }
  };

  const isIcon = useMemo(() => {
    if (!isMobile) {
      return !dataMenuStatus.menuStatus;
    }
    return false;
  }, [dataMenuStatus, isMobile]);
  return (
    <>
      <DialogIosPwaInstall
        open={isOpenInstallIos}
        toogleDialog={setOpenInstallIOs}
      />
      {(promtToInstallContext?.webInstallPrompt || isIOS()) && (
        <>
          {isIcon ? (
            <Tooltip arrow title="Install App">
              <GetAppIcon
                className="cursor-pointer"
                onClick={installPwa}
                color="action"
              />
            </Tooltip>
          ) : (
            <ButtonLoading
              fullWidth
              Icon={<GetAppIcon />}
              text="Install App"
              callbackClick={installPwa}
            />
          )}
        </>
      )}
    </>
  );
};

export default React.memo(ButtonInstallPwa);
