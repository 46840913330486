import React, { useContext, useCallback, useMemo } from 'react';
import { Typography, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  GetPatientTagsQuery,
  GetPatientTagsQueryVariables,
  GetTagInfoQuery,
  GetTagInfoQueryVariables,
  TagFragment,
} from 'types.d';
import { GET_PATIENT_TAGS, GET_TAG_INFO } from 'gql';
import { ColorPicker, Color, createColor } from 'material-ui-color';
import { PatientDetailContext } from 'share/context';
import { debounce } from 'share/utils';
import { DEFAULT_COLOR_TAG } from 'CONST';

type Props = {
  setTagCreate: (tag: TagFragment | null) => void;
  tagCreate: TagFragment | null;
  setColor: (color: string) => void;
  color: string;
  error?: string;
  validationtag?: (tag: any) => void;
  isPatientDashboard?: boolean;
  existTags?: string[];
};

export const AutoCompleteAddTag: React.FC<Props> = ({
  setTagCreate,
  tagCreate,
  setColor,
  color,
  error,
  validationtag,
  isPatientDashboard,
  existTags,
}) => {
  const patientDetailContext = useContext(PatientDetailContext);

  const [getTags, { data: listAllTag }] = useLazyQuery<
    GetPatientTagsQuery,
    GetPatientTagsQueryVariables
  >(GET_PATIENT_TAGS, {
    fetchPolicy: 'cache-and-network',
  });

  const [getTagsCombine, { data: listTagsCombine }] = useLazyQuery<
    GetTagInfoQuery,
    GetTagInfoQueryVariables
  >(GET_TAG_INFO, {
    fetchPolicy: 'cache-and-network',
  });

  const onChangeTagCreate = (value: any) => {
    setTagCreate(value);
    if (validationtag) {
      validationtag(value);
    }
    if (!value) {
      setColor(DEFAULT_COLOR_TAG);
      return;
    }
    if (typeof value !== 'string') {
      setColor((value as TagFragment).color || DEFAULT_COLOR_TAG);
      return;
    }
    if (typeof value === 'string' && tagCreate?._id) {
      setColor(DEFAULT_COLOR_TAG);
      return;
    }
  };

  const searchTagsCombine = (value: string) => {
    getTagsCombine({
      variables: {
        params: {
          limit: 20,
          search: value,
          sortByOrder: {
            name: 1,
          },
          selectedTag: existTags,
        },
      },
    });
  };

  const handleTextInput = (text: string) => {
    if (text) {
      if (isPatientDashboard) {
        getTags({
          variables: {
            params: {
              limit: 20,
              name: text,
              sortByOrder: {
                name: 1,
              },
              patientId: patientDetailContext?.patient.owner?.idOwner || null,
            },
          },
        });
        return;
      }
      searchTagsCombine(text);
    }
  };

  const handleChange = (newValue: Color) => {
    setColor(newValue.css.backgroundColor as string);
  };

  const setInformation = () => {
    if (validationtag) {
      validationtag(tagCreate);
    }
  };
  const debounceOnChange = useCallback(debounce(400, handleTextInput), [
    existTags,
  ]);

  const listTagCombines = useMemo(() => {
    return listTagsCombine?.getTagInfo?.nodes?.filter(
      item => !existTags?.includes(item?._id),
    );
  }, [existTags, listTagsCombine]);

  return (
    <>
      <Autocomplete
        renderOption={option => <Typography noWrap>{option.name}</Typography>}
        freeSolo
        options={listAllTag?.getPatientTags?.nodes || listTagCombines || []}
        getOptionLabel={option => {
          return typeof option === 'string' ? option : option.name;
        }}
        onChange={(event: any, newValue: any) => {
          onChangeTagCreate(newValue);
        }}
        style={{
          width: isPatientDashboard ? '200px' : '92%',
          marginRight: isPatientDashboard ? 0 : '8px',
        }}
        value={tagCreate}
        renderInput={params => {
          const inputProps = { ...params.inputProps, maxLength: 255 };
          return (
            <TextField
              InputLabelProps={{ shrink: true }}
              {...{
                InputProps: params.InputProps,
                id: params.id,
                disabled: params.disabled,
                inputProps,
              }}
              label={isPatientDashboard ? 'Tag' : 'Replace by'}
              size="small"
              variant="outlined"
              fullWidth
              required
              error={!!error}
              helperText={error}
              onBlur={setInformation}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeTagCreate(e.target.value);
                debounceOnChange(e.target.value);
              }}
            />
          );
        }}
      />
      <ColorPicker
        hideTextfield
        value={createColor(color)}
        onChange={handleChange}
      />
    </>
  );
};

export default React.memo(AutoCompleteAddTag);
