import { Checkbox, TableCell, TableRow } from '@material-ui/core';
import React, { Fragment } from 'react';
import { formatDate, renderTime, renderTotalRow } from 'share/utils';
import { PatientTimeReportFragment } from 'types.d';
import { format } from 'date-fns';
type Props = {
  listByMonth: PatientTimeReportFragment[];
  checkItem: (
    event: boolean | React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => void;
  checkedList: string[];
};

export const RowTableBilling: React.FC<Props> = ({
  listByMonth,
  checkItem,
  checkedList,
}) => {
  const renderNoteTypes = (record: PatientTimeReportFragment) => {
    return record?.patientNoteTypes && record?.patientNoteTypes?.length > 0
      ? record?.patientNoteTypes?.map((item, index) => {
          const noteSelected = record?.patientNoteTypeSettings?.find(
            note => note?._id === item?.id?._id,
          );
          return {
            name: noteSelected?.name || '--',
            description: item?.description || '--',
            _id: item?.id._id,
          };
        })
      : [];
  };
  return (
    <>
      {listByMonth?.map(row => {
        const noteTypes = renderNoteTypes(row);
        return (
          <Fragment key={row?._id}>
            <TableRow>
              <TableCell rowSpan={noteTypes.length || 1} align="left">
                <Checkbox
                  onChange={e => checkItem(e, row._id)}
                  color="primary"
                  checked={checkedList.includes(row._id)}
                />
              </TableCell>
              <TableCell rowSpan={noteTypes.length || 1}>
                {row.startedAt ? formatDate(row.startedAt) : 'N/A'}
              </TableCell>
              <TableCell rowSpan={noteTypes.length || 1}>
                {row?.second || row?.second === 0
                  ? renderTime(row?.second)
                  : 'N/A'}
              </TableCell>
              <TableCell rowSpan={noteTypes.length || 1}>
                {`${row?.owner?.firstName || ''} ${row?.owner?.middleName ||
                  ''} ${row?.owner?.lastName || ''}` || 'N/A'}
              </TableCell>
              <TableCell rowSpan={noteTypes.length || 1}>
                {row?.status ? 'Billed' : 'Unbill'}
              </TableCell>
              {noteTypes.length !== 0 ? (
                <>
                  <TableCell>{noteTypes[0].name}</TableCell>
                  <TableCell>{noteTypes[0].description}</TableCell>
                </>
              ) : (
                <>
                  <TableCell>--</TableCell>
                  <TableCell>--</TableCell>
                </>
              )}
            </TableRow>
            {noteTypes.length > 1 && (
              <>
                {noteTypes.slice(1, noteTypes.length).map((item, index) => {
                  return (
                    <TableRow key={item._id}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.description}</TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
          </Fragment>
        );
      })}

      {renderTotalRow(
        format(new Date(listByMonth[0].startedAt), 'MM/yy'),
        listByMonth,
        true,
      )}
    </>
  );
};

export default React.memo(RowTableBilling);
