import React, { useState, useMemo } from 'react';
import { Controller, FormContextValues } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { TextField, InputAdornment, Box } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import CheckIcon from '@material-ui/icons/Check';
import { DatePicker } from '@material-ui/pickers';
import { usePopover } from 'hooks';
import { OPTION_DATE_FILTER } from 'CONST';
import { MenuItemStyled, TypographyBold } from 'share/component_css';
import {
  convertDateFromOption,
  convertDateOptionToDate,
  isValidDate,
} from 'share/utils';
import { ButtonLoading } from 'components';
import format from 'date-fns/format';
type DateControlProps = {
  label: string;
  name: string;
  disable?: boolean;
  methods: FormContextValues<any>;
  moreRules?: { [key: string]: (value: any) => boolean | string };
};

export const KeyboardDateOption: React.FC<DateControlProps> = ({
  label,
  name,
  disable,
  methods,
  moreRules,
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const {
    errors,
    control,
    setValue,
    getValues,
    triggerValidation,
    watch,
  } = methods;
  const [open, close, DefaultPopover, defaultPopoverProps] = usePopover();

  const watchDate = watch(name);

  const clickToSetDate = (value: string) => {
    setSelectedOption(value);
    setSelectedDate(convertDateFromOption(value) as Date);
  };

  const callbackEnter = () => {
    const allValues = getValues();
    setSelectedOption(
      allValues[name] ? convertDateOptionToDate(allValues[name], true) : '',
    );
    if (isValidDate(allValues[name])) {
      setSelectedDate(new Date(allValues[name]));
      return;
    }
    setSelectedDate(null);
  };

  const resetDate = () => {
    setSelectedDate(null);
    setValue(name, '');
    triggerValidation(name);
    close();
  };

  const saveDate = () => {
    setValue(name, selectedDate ? format(selectedDate, 'MM/dd/yyyy') : '');
    triggerValidation(name);
    close();
  };

  const titleOption = useMemo(() => {
    if (watchDate) {
      return convertDateOptionToDate(watchDate);
    }
    return 'Custom';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDate]);

  const onChangeDatePicker = (date: Date | null) => {
    setSelectedDate(date);
    if (date) {
      setSelectedOption(
        convertDateOptionToDate(format(date, 'MM/dd/yyyy'), true),
      );
    }
  };

  return (
    <>
      <DefaultPopover callbackEnter={callbackEnter} {...defaultPopoverProps}>
        <Box display="flex">
          <Box
            display="flex"
            flexDirection="column"
            pt={2}
            borderColor="grey.400"
            border={1}
            borderLeft={0}
            borderTop={0}
            borderBottom={0}
          >
            {OPTION_DATE_FILTER.map(item => {
              return (
                <MenuItemStyled
                  onClick={() => clickToSetDate(item.value)}
                  key={item.value}
                  value={item.value}
                  selected={item.value === selectedOption}
                >
                  {item.title}
                </MenuItemStyled>
              );
            })}
          </Box>
          <DatePicker
            autoOk
            variant="static"
            openTo="date"
            disableToolbar
            value={selectedDate}
            onChange={date => onChangeDatePicker(date)}
          />
        </Box>
        <Box
          display="flex"
          py={1}
          pr={1}
          borderColor="grey.400"
          border={1}
          borderLeft={0}
          borderRight={0}
          borderBottom={0}
          justifyContent="flex-end"
        >
          <ButtonLoading
            text="Reset"
            className="mr-8"
            callbackClick={resetDate}
            Icon={<RotateLeftIcon />}
            color="default"
          />
          <ButtonLoading
            text="Save"
            callbackClick={saveDate}
            Icon={<CheckIcon />}
          />
        </Box>
      </DefaultPopover>
      <Controller
        as={
          <InputMask disabled={disable} mask="99/99/9999">
            <TextField
              label={label}
              placeholder="mm/dd/yyyy"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    onClick={e => {
                      !disable && open(e);
                    }}
                    position="end"
                  >
                    <DateRangeIcon color="action" className="cursor-pointer" />
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <TypographyBold variant="subtitle2" className="mr-4">
                      {titleOption}
                    </TypographyBold>
                  </InputAdornment>
                ),
              }}
              size="small"
              variant="outlined"
              fullWidth
              error={!!errors[name as string]}
              helperText={errors[name as string]?.message || ''}
            />
          </InputMask>
        }
        name={name as string}
        control={control}
        rules={{
          validate: {
            required: value => value !== null || 'This field is required',
            pattern: value => {
              return !isNaN(new Date(value).getTime()) || 'Invalid date format';
            },
            ...(moreRules || {}),
          },
        }}
      />
    </>
  );
};
