import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Box } from '@material-ui/core';

type Props = {
  marginTop?: any;
};

export const LoadingChatbot: React.FC<Props> = (props: Props) => {
  return (
    <Box margin={1}>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" width={'90%'} />
      <Skeleton animation="wave" width={'80%'} />
    </Box>
  );
};

export default LoadingChatbot;
