import React from 'react';
import { styled } from '@material-ui/core/styles';
import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { formatDistance } from 'date-fns';
import DescriptionIcon from '@material-ui/icons/Description';
import { NavigatorNotificationFragment } from 'types.d';

type Props = {
  notification: NavigatorNotificationFragment;
  openInfomationPatient: (
    e: any,
    patientId: string,
    notificationId: string,
  ) => void;
};

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  margin: theme.spacing(0.5, 0),
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}));

const NavigatorNotificationItem: React.FC<Props> = ({
  notification,
  openInfomationPatient,
}) => {
  const { isSeen, surveyName, owner, createdAt, _id } = notification;

  return (
    <>
      <Box onClick={e => openInfomationPatient(e, owner?._id, _id)}>
        <ListItemStyled
          style={{ backgroundColor: isSeen ? '' : 'aliceblue' }}
          alignItems="flex-start"
          key={_id}
          button
        >
          <ListItemAvatar>
            <Avatar color="primary">
              <DescriptionIcon />
            </Avatar>
          </ListItemAvatar>

          <ListItemText
            primary={
              <React.Fragment>
                <Typography component="div" variant="body2" color="textPrimary">
                  <strong>
                    {owner?.firstName} {owner?.lastName}
                  </strong>{' '}
                  completed <strong>{surveyName}</strong>
                </Typography>
              </React.Fragment>
            }
            secondary={formatDistance(new Date(createdAt), new Date(), {
              includeSeconds: true,
            })}
          />
        </ListItemStyled>
      </Box>
    </>
  );
};

export default React.memo(NavigatorNotificationItem);
