import React, { useCallback, useContext, useMemo } from 'react';
import { Grid, Box, Typography, Tooltip } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import { useQueryCustom } from 'hooks';
import { GET_GROUP_DETAIL_PATIENT } from 'gql';
import {
  GetGroupDetailPatientQuery,
  GetGroupDetailPatientQueryVariables,
  SurveyResultStatus,
  PatientFragment,
  GetSurveyDataByPatientInput,
} from 'types.d';
import { TitleDesPatientStyled } from '../../styles';
import { ChipStyled, TypographyBold } from 'share/component_css';
import ChecklistTag from './ChecklistTag';
import SurveyTag from './SurveyTag';
import TaskTag from './TaskTag';
import NoteTag from './NoteTag';
import ConditionTag from './ConditionTag';
import { generateInformation as generateInfoPatient } from 'share/utils';
import { PatientTagsContext, ContentPatientContext } from 'share/context';
import TimeRecordsTag from './TimeRecordsTag';
type Props = {
  ignoredItems?: string[];
  patient: PatientFragment;
  indexTab: number;
  isExtend: boolean;
  handleSetDialogOpen: (nameDialog: string, data?: any) => void;
  isInactive: boolean;
};

export const PatientTags: React.FC<Props> = ({
  ignoredItems,
  patient,
  indexTab,
  isExtend,
  handleSetDialogOpen,
  isInactive,
}) => {
  const contentPatientContext = useContext(ContentPatientContext);
  const allParams = useMemo(() => {
    return {
      page: 1,
      limit: 5,
      patientId: patient.owner?.idOwner,
    };
  }, [patient.owner]);

  const paramsSurvey = useMemo(() => {
    let params: GetSurveyDataByPatientInput = {
      ...allParams,
      order: { updatedAt: -1 },
      isArchive: false,
      workingStatus: SurveyResultStatus.New,
    };
    if (contentPatientContext?.dataFilterSurvey) {
      params.match = contentPatientContext?.dataFilterSurvey;
    }
    return params;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPatientContext?.dataFilterForm]);

  const { data: dataGroup, loading: loadingDataGroup } = useQueryCustom<
    GetGroupDetailPatientQuery,
    GetGroupDetailPatientQueryVariables
  >({
    api: GET_GROUP_DETAIL_PATIENT,
    variables: {
      paramsFollowUp: {
        patientId: patient.owner?.idOwner,
        status: { $nin: ['CLOSED', 'CANCELLED'] },
      },
      paramsNote: {
        ...allParams,
      },
      paramsSurvey,
      paramsChecklist: {
        patientId: patient.owner?.idOwner,
        checklistExists: true,
      },
      paramsCondition: {
        ...allParams,
        isCurrentStage: true,
      },
      paramsTimeRecord: {
        patient: patient.owner?.idOwner,
        page: 1,
        limit: 5,
      },
    },
  });

  const generateInformation = useCallback(
    (
      information: {
        name: string;
        info: string;
      }[],
    ) => {
      return generateInfoPatient(information);
    },
    [],
  );
  const renderNoData = (text: string) => {
    return (
      <ChipStyled
        colortext="true"
        avatar={<BlockIcon />}
        label={<Typography variant="subtitle2">No {text}</Typography>}
      />
    );
  };
  const renderTitle = (text: string) => {
    return (
      <TitleDesPatientStyled color="textSecondary" variant="subtitle2">
        {text}
      </TitleDesPatientStyled>
    );
  };

  return (
    <PatientTagsContext.Provider
      value={{
        indexTab,
        handleSetDialogOpen,
        isExtend,
        isInactive,
        patient,
        generateInformation,
      }}
    >
      {ignoredItems?.includes('followUp') && (
        <Grid item xs={12}>
          <Box display="flex" flexWrap="nowrap" alignItems="center">
            {renderTitle('Tasks')}
            <TaskTag
              loadingDataGroup={loadingDataGroup}
              patientFollowUps={dataGroup?.getPatientFollowUps?.nodes}
            />
            {!loadingDataGroup &&
              dataGroup?.getPatientFollowUps?.nodes.length === 0 &&
              renderNoData('Task')}
          </Box>
        </Grid>
      )}
      {ignoredItems?.includes('note') && (
        <Grid item xs={12}>
          <Box display="flex" flexWrap="no-wrap" alignItems="center">
            {renderTitle('Notes')}
            <NoteTag
              loadingDataGroup={loadingDataGroup}
              patientNotes={dataGroup?.getPatientNotes?.nodes}
            />
            {!loadingDataGroup &&
              dataGroup?.getPatientNotes?.nodes.length === 0 &&
              renderNoData('Note')}
          </Box>
        </Grid>
      )}
      {ignoredItems?.includes('surveyResult') && (
        <Grid item xs={12}>
          <Box display="flex" flexWrap="nowrap" alignItems="center">
            <Tooltip
              title={
                <Box display="flex" flexDirection="column">
                  <TypographyBold display="inline" variant="subtitle2">
                    Interpreting color indicators:
                  </TypographyBold>
                  {generateInformation([
                    {
                      name: '- Grey:',
                      info:
                        'The survey has been sent out, and there has been no initial response.',
                    },
                    {
                      name: '- Grey with a Purple Outline:',
                      info:
                        'The patient has not filled out the survey for 3 days.',
                    },
                    {
                      name: '- Purple:',
                      info:
                        'The patient has not filled out the survey for 7 days.',
                    },
                    {
                      name: '- Green:',
                      info:
                        'The patient has successfully completed the survey.',
                    },
                    {
                      name: '- Red:',
                      info:
                        'It has been 7 days since the patient completed the survey.',
                    },
                    {
                      name: '- Green with a Red Outline:',
                      info: 'The navigator/admin has started the survey.',
                    },
                  ])}
                </Box>
              }
            >
              {renderTitle('Survey Results')}
            </Tooltip>
            <SurveyTag
              loadingDataGroup={loadingDataGroup}
              patientSurveyResult={dataGroup?.getSurveyDataByPatient.nodes}
            />
            {!loadingDataGroup &&
              dataGroup?.getSurveyDataByPatient?.nodes.length === 0 &&
              renderNoData('Survey Result')}
          </Box>
        </Grid>
      )}
      {ignoredItems?.includes('checklist') && (
        <Grid item xs={12}>
          <Box display="flex" flexWrap="nowrap" alignItems="center">
            {renderTitle('Checklists')}
            <ChecklistTag
              loadingDataGroup={loadingDataGroup}
              patientChecklists={dataGroup?.getPatientChecklists?.nodes}
            />
            {!loadingDataGroup &&
              dataGroup?.getPatientChecklists.nodes.length === 0 &&
              renderNoData('Checklist')}
          </Box>
        </Grid>
      )}
      {ignoredItems?.includes('condition') && (
        <Grid item xs={12}>
          <Box display="flex" flexWrap="nowrap" alignItems="center">
            {renderTitle('Conditions')}
            <ConditionTag
              loadingDataGroup={loadingDataGroup}
              conditions={dataGroup?.getListPatientStages?.nodes}
            />
            {!loadingDataGroup &&
              dataGroup?.getListPatientStages?.nodes.length === 0 &&
              renderNoData('Condition')}
          </Box>
        </Grid>
      )}
      {ignoredItems?.includes('timeRecord') && (
        <Grid item xs={12}>
          <Box display="flex" flexWrap="nowrap" alignItems="center">
            {renderTitle('Time records')}
            <TimeRecordsTag
              loadingDataGroup={loadingDataGroup}
              records={dataGroup?.getPatientTimeRecord?.nodes}
            />
            {!loadingDataGroup &&
              dataGroup?.getPatientTimeRecord?.nodes?.length === 0 &&
              renderNoData('Record')}
          </Box>
        </Grid>
      )}
    </PatientTagsContext.Provider>
  );
};

export default React.memo(PatientTags);
