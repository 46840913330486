import React, { useEffect, useRef } from 'react';
import { TextField, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { UserListFragment } from 'types.d';
import { ButtonLoading } from 'components';
import { EMAIL_REGEX } from 'CONST';
import { useForm } from 'react-hook-form';
import { trimObjectValue } from 'share/utils';
import TableEmailAlias from './TableEmailAlias';
import { TypographyItalic } from 'share/component_css';
type Alias = {
  secondaryEmail: string;
};
type SecondaryEmail = UserListFragment['secondaryEmail'];

type Props = {
  listEmailAlias: SecondaryEmail | null;
  loadingAdd: boolean;
  loadingSetPrimary: boolean;
  loadingDelete: boolean;
  handleRemoveEmailAlias: (secondaryEmail: string) => void;
  addEmailAlias: (dataTrim: Alias) => void;
  handleSetPrimaryEmail: (secondaryEmail: string) => void;
};

const FormAddEmailAlias: React.FC<Props> = ({
  listEmailAlias,
  loadingAdd,
  loadingDelete,
  handleRemoveEmailAlias,
  addEmailAlias,
  handleSetPrimaryEmail,
  loadingSetPrimary,
}) => {
  const emailPrevious = useRef<SecondaryEmail>(listEmailAlias || []);

  const { register, handleSubmit, errors, setValue } = useForm<Alias>({
    mode: 'onBlur',
  });

  const submitForm = (dataForm: Alias) => {
    const dataTrim = {
      ...trimObjectValue(dataForm),
      secondaryEmail: dataForm.secondaryEmail,
    };
    addEmailAlias(dataTrim);
  };
  const onSubmit = handleSubmit(data => {
    submitForm(data);
  });

  useEffect(() => {
    if ((emailPrevious.current?.length || 0) < (listEmailAlias?.length || 0)) {
      setValue('secondaryEmail', '');
    }
    emailPrevious.current = listEmailAlias || [];
  }, [listEmailAlias, setValue]);
  return (
    <>
      <TypographyItalic
        className="mb-8"
        color="textSecondary"
        variant="subtitle2"
      >
        * Each account has a maximum of 3 email alias
      </TypographyItalic>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField
              required
              size="small"
              inputProps={{ maxLength: 250 }}
              fullWidth
              InputLabelProps={{ shrink: true }}
              id="secondaryEmail"
              label="Email alias"
              type="text"
              name="secondaryEmail"
              disabled={!!(listEmailAlias && listEmailAlias?.length > 2)}
              InputProps={{
                inputProps: { tabIndex: 3 },
              }}
              variant="outlined"
              error={!!errors.secondaryEmail}
              inputRef={register({
                validate: {
                  required: value =>
                    value.trim() !== '' || 'This field is required',
                },
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Invalid email address',
                },
              })}
              helperText={errors.secondaryEmail?.message || ''}
            />
          </Grid>
          <Grid item xs={3}>
            <ButtonLoading
              fullWidth
              text="Add"
              callbackClick={onSubmit}
              Icon={<AddIcon />}
              color="primary"
              loading={loadingAdd}
              className="button-fit-input"
              disabled={
                listEmailAlias && listEmailAlias?.length > 2 ? true : false
              }
            />
          </Grid>
        </Grid>
      </form>

      {listEmailAlias?.length !== 0 && (
        <TableEmailAlias
          loadingDelete={loadingDelete}
          listEmailAlias={listEmailAlias}
          loadingSetPrimary={loadingSetPrimary}
          handleSetPrimaryEmail={handleSetPrimaryEmail}
          handleRemoveEmailAlias={handleRemoveEmailAlias}
        />
      )}
    </>
  );
};
export default FormAddEmailAlias;
