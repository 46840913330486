import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@material-ui/core';
import { LoadingTable, NoDataTable } from 'components';
import { MIN_WIDTH_TABLE } from 'CONST';
import { TableContainerStyled } from 'share/component_css';

type TDefaultProps = {
  loading: boolean;
  heads: { width?: string; text: string | React.ReactNode }[];
  content: { key: string; cells: React.ReactNode[]; bgColor?: string }[];
  minWidth?: string | number;
  maxHeight?: string | number;
  isOnComponent?: boolean;
  isWrapped?: boolean;
};

export const DefaultTable: React.FC<TDefaultProps> = ({
  loading,
  heads,
  content,
  minWidth,
  isOnComponent,
  isWrapped,
  maxHeight,
}) => {
  const align = (index: number) => {
    if (heads.length === 2) {
      return 'left';
    }
    return index === heads.length - 1 ? 'right' : 'left';
  };
  return (
    <TableContainerStyled
      className={maxHeight ? '' : 'no-scroll'}
      component={isWrapped ? Paper : undefined}
      style={maxHeight ? { maxHeight: maxHeight } : {}}
    >
      <Table
        style={{ tableLayout: 'fixed', minWidth: minWidth || MIN_WIDTH_TABLE }}
        stickyHeader={!!maxHeight}
      >
        <TableHead>
          <TableRow>
            {heads.map((item, index) => {
              return (
                <TableCell
                  style={{ width: item.width || 'auto' }}
                  key={typeof item.text === 'string' ? item?.text : ''}
                  align={align(index)}
                >
                  {item.text}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && <LoadingTable colSpan={heads.length} />}
          {!loading && content && (
            <>
              {content.length === 0 && (
                <NoDataTable
                  colSpan={heads.length}
                  isPage={!isOnComponent}
                  marginTop={15}
                />
              )}
              {content.map(item => {
                return (
                  <TableRow
                    style={{
                      backgroundColor: item.bgColor || 'transparent',
                    }}
                    key={item.key}
                  >
                    {item.cells.map((cell, index) => {
                      return (
                        <TableCell key={index} align={align(index)}>
                          {cell}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainerStyled>
  );
};
