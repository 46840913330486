import React, { useMemo, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  TextField,
  Typography,
  MenuItem,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Checkbox,
} from '@material-ui/core';
import { ButtonLoading } from 'components';
import {
  TreatmentAssignSyncMutationVariables,
  TreatmentAssignSyncMutation,
  AssignTreatmentResFragment,
} from 'types.d';
import { TREATMENT_ASSIGN_SYNC } from 'gql';
import {
  setDataLocalStorage,
  getLocalAssignTreatment,
  removeLocalStorage,
  handleError,
} from 'share/utils';
import { KEY_OPEN_SYNC_TREATMENT, KEY_LOCAL_ASSIGN_TREATMENT } from 'CONST';
import CancelIcon from '@material-ui/icons/Cancel';
import SyncIcon from '@material-ui/icons/Sync';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import {
  TableContainerStyled,
  TableCellMaxWidthStyled,
  TypoThreeDotsLine,
  TableFullWidthStyled,
  PaperBorder,
} from 'share/component_css';
import { useChecked } from 'hooks';
import { useHistory } from 'react-router-dom';

type Props = {
  open?: boolean;
  handleCloseModal?: (status: boolean) => void;
  callbackSync?: () => void;
};

export const ModalSyncTreatment: React.FC<Props> = ({
  open,
  handleCloseModal,
  callbackSync,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [orgSelected, setOrgSelected] = useState('');

  const history = useHistory();

  const [
    treatmentAssignSync,
    { loading: loadingTreatmentAssignSync },
  ] = useMutation<
    TreatmentAssignSyncMutation,
    TreatmentAssignSyncMutationVariables
  >(TREATMENT_ASSIGN_SYNC);

  const syncTreatment = () => {
    treatmentAssignSync({
      variables: {
        params: listTreatment
          .filter(item => checkedList.includes(item._id))
          .map(item => {
            return { _id: item._id, token: item.token };
          }),
      },
    })
      .then(() => {
        handleCloseModal!(false);
        removeLocalStorage(KEY_OPEN_SYNC_TREATMENT);
        removeLocalStorage(KEY_LOCAL_ASSIGN_TREATMENT);
        if (callbackSync) {
          callbackSync();
          return;
        }
        history.push('/assigned-treatments/');
      })
      .catch(error => {
        const arrError = handleError(error.graphQLErrors[0]!);
        enqueueSnackbar(arrError.join(', '), { variant: 'error' });
        handleCloseModal!(false);
        removeLocalStorage(KEY_OPEN_SYNC_TREATMENT);
        removeLocalStorage(KEY_LOCAL_ASSIGN_TREATMENT);
      });
  };

  const handleCancel = () => {
    handleCloseModal!(false);
    setDataLocalStorage(KEY_OPEN_SYNC_TREATMENT, 'true');
  };

  const localAssignTreatments = useMemo(() => {
    const objAssignTreatment: {
      [key: string]: (AssignTreatmentResFragment & {
        email: string;
      })[];
    } = {};
    return getLocalAssignTreatment().reduce((init, item) => {
      let obj = { ...init };
      obj[`${item.organization}:${item.orgName}`] = [
        ...(obj[`${item.organization}:${item.orgName}`] || []),
        item,
      ];
      return obj;
    }, objAssignTreatment);
  }, []);

  const listOrg = useMemo(() => {
    return (
      Object.keys(localAssignTreatments).map(item => {
        return { value: item, label: item.split(':')[1] };
      }) || []
    );
  }, [localAssignTreatments]);
  const listTreatment = useMemo(() => {
    return localAssignTreatments[orgSelected] || [];
  }, [localAssignTreatments, orgSelected]);

  const {
    checkItem,
    checkedAll,
    checkAll,
    checkedList,
    resetCheckbox,
  } = useChecked(listTreatment || []);

  const handleClose = () => {
    handleCloseModal!(false);
    resetCheckbox();
  };
  return (
    <>
      <Dialog
        open={open!}
        onClose={handleClose}
        maxWidth="sm"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
      >
        <DialogTitle>Sync Treatments</DialogTitle>
        <DialogContent className="pt-0">
          <DialogContentText>
            You have some treatments on your browser’s storage. Do you want to
            sync them to your account?
          </DialogContentText>
          <TextField
            fullWidth
            label="Organization"
            size="small"
            InputLabelProps={{ shrink: true }}
            select
            variant="outlined"
            onChange={e => setOrgSelected(e.target.value)}
            SelectProps={{
              displayEmpty: true,
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              },
              renderValue: value => {
                return value ? (
                  <>{(value as string).split(':')[1]}</>
                ) : (
                  <Typography variant="subtitle1" color="textSecondary">
                    Choose
                  </Typography>
                );
              },
            }}
            value={orgSelected}
          >
            {listOrg.map(item => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </TextField>
          {listTreatment.length !== 0 && (
            <TableContainerStyled className="mt-16" component={PaperBorder}>
              <TableFullWidthStyled stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={checkedAll}
                        onChange={checkAll}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell align="right">Treatment Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listTreatment.map(item => (
                    <TableRow key={item._id}>
                      <TableCell>
                        <Checkbox
                          onChange={e => checkItem(e, item._id)}
                          color="primary"
                          checked={checkedList.includes(item._id)}
                        />
                      </TableCell>
                      <TableCellMaxWidthStyled maxwidth="300px">
                        <TypoThreeDotsLine
                          className="float-right"
                          variant="subtitle2"
                        >
                          {item.treatmentName}
                        </TypoThreeDotsLine>
                      </TableCellMaxWidthStyled>
                    </TableRow>
                  ))}
                </TableBody>
              </TableFullWidthStyled>
            </TableContainerStyled>
          )}
        </DialogContent>
        <DialogActions className="pt-0">
          <ButtonLoading
            Icon={<CancelIcon color="disabled" />}
            text="Cancel"
            color="default"
            callbackClick={handleCancel}
          />
          <ButtonLoading
            Icon={<SyncIcon />}
            text="Sync"
            disabled={checkedList.length === 0}
            callbackClick={syncTreatment}
            loading={loadingTreatmentAssignSync}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ModalSyncTreatment;
