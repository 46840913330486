import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { ColorBox, Color, createColor } from 'material-ui-color';
import { DialogButton } from 'components';
import { TagFragment } from 'types.d';
import { useSnackbar } from 'notistack';
import { TagForImport } from '../../interfaces';
import { DEFAULT_COLOR_TAG } from 'CONST';
import { customEvent } from 'modules/google_tag_manager';

type Props = {
  item?: TagFragment | TagForImport | null;
  close: () => void;
  updateTagColor: (
    inforTag: { tagName: string; color: string },
    callback: Function,
  ) => void;
  loading?: boolean;
  isFolder?: boolean;
};

export const PickColorTag: React.FC<Props> = ({
  item,
  close,
  updateTagColor,
  loading,
  isFolder,
}) => {
  const [color, setColor] = useState(item?.color || DEFAULT_COLOR_TAG);

  const { enqueueSnackbar } = useSnackbar();

  const isTagFragment = (
    tag: TagFragment | TagForImport,
  ): tag is TagFragment => {
    return (tag as TagFragment)._id !== undefined;
  };

  const handleChange = (newValue: Color) => {
    setColor(newValue.css.backgroundColor as string);
  };

  const handleChangeColorTag = () => {
    if (item) {
      updateTagColor({ tagName: item?.name, color }, () => {
        close();
        if (isTagFragment(item) && !isFolder) {
          customEvent("UpdateTagPD", "NAVIGATOR")
          enqueueSnackbar('Update tag successfully', { variant: 'success' });
        }
      });
    }
  };

  return (
    <>
      <ColorBox value={createColor(color)} onChange={handleChange} />
      <Box display="flex" flexDirection="row-reverse">
        <DialogButton
          className="ml-8"
          loading={!!loading}
          onClickButton={handleChangeColorTag}
        />
        <DialogButton isCancel onClickButton={close} />
      </Box>
    </>
  );
};

export default React.memo(PickColorTag);
