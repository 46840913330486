import gql from 'graphql-tag';
import { MetaFragment, TagInfoFragment } from './fragments';

export const GET_TAG_INFO = gql`
  query GetTagInfo($params: GetTagInput!) {
    getTagInfo(params: $params) {
      nodes {
        ...TagInfo
      }
      meta {
        ...Meta
      }
    }
  }
  ${TagInfoFragment}
  ${MetaFragment}
`;

export const CREATE_TAG = gql`
  mutation CreateTag($params: CreateTagInput!) {
    createTag(params: $params) {
      ...TagInfo
    }
  }
  ${TagInfoFragment}
`;

export const DELETE_TAG = gql`
  mutation DeleteTag($params: DeleteTagInput!) {
    deleteTag(params: $params)
  }
`;

export const UPDATE_TAG_INFO = gql`
  mutation UpdateTagInfo($params: UpdateTagInput!) {
    updateTagInfo(params: $params) {
      ...TagInfo
    }
  }
  ${TagInfoFragment}
`;

export const REPLACE_TAG = gql`
  mutation ReplaceTag($params: ReplaceTagInput!) {
    replaceTag(params: $params)
  }
`;
