import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { SuccessIcon, TypographyBold } from 'share/component_css';
import { styled } from '@material-ui/core/styles';

type Props = {
  text?: React.ReactNode;
  component?: React.ReactElement;
  title?: string;
};
export const BoxStyled = styled(Box)({
  '&.MuiBox-root': {
    padding: 16,
    paddingTop: 0,
  },
});

export const SuccessLayout: React.FC<Props> = ({ text, component, title }) => {
  return (
    <BoxStyled alignItems="center" display="flex" flexDirection="column">
      <SuccessIcon />
      <TypographyBold align="center" variant="h6">
        {title || 'Success!'}
      </TypographyBold>
      {text && (
        <Typography className="mt-16" align="center">
          {text}
        </Typography>
      )}
      {component}
    </BoxStyled>
  );
};
