import React from 'react';
import { ClipboardSearchOutline } from 'mdi-material-ui';
import { styled } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { TypographyItalic } from 'share/component_css';

type Props = {
  marginTop: number;
  text?: string;
};

export const ClipboardSearchOutlineStyled = styled(ClipboardSearchOutline)(
  ({ theme }) => ({
    fontSize: '10em',
    color: grey[400],
    marginBottom: theme.spacing(1.5),
  }),
);

export const NoDataPage: React.FC<Props> = ({ marginTop, text }) => {
  return (
    <>
      <Box
        mt={marginTop}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <ClipboardSearchOutlineStyled />
        <Typography variant="h4" color="primary">
          No Results Found
        </Typography>
        <TypographyItalic variant="body1" color="textSecondary">
          {text ||
            ` Hang in there! No results were found this time, but they're there
          somewhere`}
        </TypographyItalic>
      </Box>
    </>
  );
};

export default React.memo(NoDataPage);
