import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Box,
  Typography,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  Checkbox,
} from '@material-ui/core';
import { DialogTitleClose, DialogButton, TextfieldSelect } from 'components';
import {
  useToogleDialog,
  useFormPhone,
  Phone,
  useOnlyChecked,
  useInputMentions,
  useMutationCustom,
} from 'hooks';
import { useForm, Controller, FormContext } from 'react-hook-form';
import 'react-phone-input-2/lib/material.css';
import { TypographyBold, TypographyItalic } from 'share/component_css';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  CommonTreatmentFragment,
  GetUsersForNavigatorQuery,
  GetUsersForNavigatorQueryVariables,
  UserFragment,
  CreateNewPatientMutation,
  CreateNewPatientMutationVariables,
  CreateNewPatientInput,
  SameNewPatientFragment,
  Gender,
  Race,
  DuplicateUserFragment,
  SurveyForAddSurveyFragment,
  CreateCalendarConfigMutation,
  CreateCalendarConfigMutationVariables,
  ScheduleConfigEmbedded,
  TreatmentFragment,
  SubTreatmentFragment,
  TreatmentType,
  TreatmentRegimenAddPatientFragment,
  CreateCalendarConfigByRegimenMutation,
  CreateCalendarConfigByRegimenMutationVariables,
} from 'types.d';
import {
  GET_USERS_FOR_NAVIGATOR,
  CREATE_NEW_PATIENT,
  CREATE_CALENDAR_CONFIG,
  CREATE_CALENDAR_CONFIG_BY_REGIMEN,
} from 'gql';
import {
  trimObjectValue,
  debounce,
  convertDateWithoutUTC,
  handleError,
  optionWeekly,
  optionDay,
  optionByNWeekday,
} from 'share/utils';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useSnackbar } from 'notistack';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DialogSamePatients from './DialogSamePatients';
import { LIST_GENDER, LIST_RACE, EMAIL_REGEX } from 'CONST';
import { InputAdornmentStyled } from '../styles';
import CloseIcon from '@material-ui/icons/Close';
import { useProvider, OptionEmrProvider } from '../services/useProvider';
import SendTreatments from './SendTreatments';
import AssignTreatments from './AssignTreatments';
import { addDays, format, getHours, getMinutes, set } from 'date-fns';
import { DataCalendar } from './Survey/DialogSendSurvey';
import { RRule } from 'rrule';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { PatientInfo } from '../../treatment_tree/components/DialogShareAssignTreatment';
import { customEvent, trackButtonClickEvent } from 'modules/google_tag_manager';
import { CogSyncOutline } from 'mdi-material-ui';

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
  callback?: () => void;
  treatmentSelected?: TreatmentFragment | SubTreatmentFragment | null;
  handleClose?: () => void;
  handleAddInfor?: (data: FormData, email: string) => void;
  patientInfo?: UserFragment & PatientInfo;
};

export enum AssignBy {
  TREATMENT = 'treatment',
  GROUP = 'group',
  SURVEY = 'survey',
  FAVORITES = 'favorites',
}

export type FormData = {
  dob: string | null;
  mrn: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  phones: Phone[];
  treatmentAssign: CommonTreatmentFragment;
  treatmentSend: CommonTreatmentFragment;
  gender: Gender | string;
  race: Race | string;
  provider?: OptionEmrProvider;
  emrProvider?: OptionEmrProvider;
  address: string;
  group: TreatmentRegimenAddPatientFragment;
  patientId?: string;
  userId?: string;
  email?: string;
  treatment?: CommonTreatmentFragment;
  surveyAssign?: SurveyForAddSurveyFragment;
  surveySend?: SurveyForAddSurveyFragment;
  sendingDate?: string;
  sendingTime?: string;
};

export type TreatmentIds = {
  treatmentIds?: string[];
  surveyIds?: string[];
  group?: TreatmentRegimenAddPatientFragment;
  messageTreatment?: CommonTreatmentFragment[];
  isRemind?: boolean;
};

export const DialogImportPatient: React.FC<Props> = ({
  open,
  toogleDialog,
  callback,
  treatmentSelected,
  handleClose,
  handleAddInfor,
  patientInfo,
}) => {
  const samePatients = useRef<SameNewPatientFragment[] | null>([]);

  const sameUsers = useRef<DuplicateUserFragment[] | null>([]);

  const [dataCalendar, setDataCalendar] = useState<DataCalendar | null>(null);

  const [emailOptSelected, setEmailOptSelected] = useState(true);

  const treatmentHomePage = useMemo(() => {
    return treatmentSelected
      ? ({
          _id: treatmentSelected._id,
          name: treatmentSelected.name,
          type: TreatmentType.Treatment,
          smsMessage: treatmentSelected.smsMessage,
        } as CommonTreatmentFragment)
      : undefined;
  }, [treatmentSelected]);

  const methods = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      middleName: '',
      mrn: '',
      dob: null,
      treatmentAssign: treatmentHomePage,
      phones: [],
      treatment: treatmentHomePage,
      group: undefined,
      sendingDate: format(new Date(), 'MM/dd/yyyy'),
      sendingTime: new Date(0, 0, 0, 9, 0).toISOString(),
    },
  });

  const { FormPhoneComponent, formPhoneComponentProps, remove } = useFormPhone<
    FormData
  >(methods);

  const {
    providerComponentProps,
    ProviderComponent,
    getProviders,
  } = useProvider(undefined, methods);

  const {
    handleSubmit,
    errors,
    register,
    control,
    watch,
    setValue,
    reset,
  } = methods;

  const emailFromTextField = useRef<{
    value: UserFragment | null;
    hasValue: boolean;
  }>({ value: null, hasValue: false });

  const watchGroup = watch('group');

  const watchTreatment = watch('treatment');

  const watchRace = watch('race');

  const watchSurveySend = watch('surveySend');

  const watchTreatmentSend = watch('treatmentSend');

  const watchSendingDate = watch('sendingDate');

  const watchSendingTime = watch('sendingTime');

  const [emailValue, setEmailValue] = useState<UserFragment | null>(null);

  const [recurrenceDay, setRecurrenceDay] = useState('notRepeat');

  const [errorEmail, setErrorEmail] = useState('');

  const [isOpenSamePatient, setOpenSamePatient] = useToogleDialog();

  const { enqueueSnackbar } = useSnackbar();

  const [typeEmail, setTypeEmail] = useState('personal');

  const [patientId, setPatientId] = useState('');

  const [userId, setUserId] = useState('');

  const {
    checked,
    setChecked,
    defaulCheckedProps,
    OnlyChecked,
  } = useOnlyChecked(formPhoneComponentProps.fields?.length === 0);

  const renderOptionAssign = useMemo(() => {
    if (watchTreatment) {
      return [watchTreatment];
    }
    if (watchTreatmentSend) {
      return [watchTreatmentSend];
    }
    if (watchGroup)
      return watchGroup?.regimenConfig
        .filter(item => {
          return item.treatmentType === TreatmentType.Treatment;
        })
        .map(item => item.treatment) as CommonTreatmentFragment[];
  }, [watchGroup, watchTreatment, watchTreatmentSend]);

  const {
    valueCustom,
    defaulInputMentionsProps,
    InputMentions,
  } = useInputMentions(true, '', renderOptionAssign);

  const [getListUser, { data: listUser }] = useLazyQuery<
    GetUsersForNavigatorQuery,
    GetUsersForNavigatorQueryVariables
  >(GET_USERS_FOR_NAVIGATOR, {
    fetchPolicy: 'cache-and-network',
  });

  const [createCalendarConfig] = useMutationCustom<
    CreateCalendarConfigMutation,
    CreateCalendarConfigMutationVariables
  >({
    api: CREATE_CALENDAR_CONFIG,
  });

  const [createCalendarConfigByRegimen] = useMutationCustom<
    CreateCalendarConfigByRegimenMutation,
    CreateCalendarConfigByRegimenMutationVariables
  >({
    api: CREATE_CALENDAR_CONFIG_BY_REGIMEN,
    callbackSuccess: data => {},
  });

  const optionFreq = useMemo(() => {
    if (
      (dataCalendar && dataCalendar.freq === 'day') ||
      ['notRepeat', 'daily'].includes(recurrenceDay)
    ) {
      return RRule.DAILY;
    }
    if (
      (dataCalendar && dataCalendar.freq === 'week') ||
      ['weekly', 'allWeek'].includes(recurrenceDay)
    ) {
      return RRule.WEEKLY;
    }
    if (
      (dataCalendar && dataCalendar.freq === 'month') ||
      recurrenceDay === 'monthly'
    ) {
      return RRule.MONTHLY;
    }
    if (recurrenceDay === 'annually') {
      return RRule.YEARLY;
    }
    return RRule.YEARLY;
  }, [dataCalendar, recurrenceDay]);

  const optionByWeekDay = useMemo(() => {
    if (recurrenceDay === 'weekly') {
      const numberOfDay = (watchSendingDate
        ? new Date(watchSendingDate)
        : new Date()
      ).getDay();
      return [numberOfDay === 0 ? numberOfDay + 6 : numberOfDay - 1];
    }
    if (recurrenceDay === 'allWeek') {
      return [0, 1, 2, 3, 4];
    }
    if (dataCalendar && dataCalendar.freq === 'week') {
      return [optionWeekly(dataCalendar.repeatWeek)];
    }
    return [0];
  }, [dataCalendar, recurrenceDay, watchSendingDate]);

  const paramsCreateEvent = useMemo(() => {
    const dtstart = set(new Date(watchSendingDate as string), {
      hours: getHours(new Date(watchSendingTime as string)),
      minutes: getMinutes(new Date(watchSendingTime as string)),
    });
    let defaultParams = {
      dtstart,
      freq: optionFreq,
      interval: dataCalendar?.amount ? +dataCalendar?.amount : 1,
    } as ScheduleConfigEmbedded;
    if (dataCalendar?.endDate) {
      defaultParams.until = new Date(
        convertDateWithoutUTC(dataCalendar?.endDate),
      );
    }
    if (dataCalendar?.after || recurrenceDay === 'notRepeat') {
      defaultParams.count = dataCalendar?.after ? +dataCalendar?.after : 1;
    }
    if (
      recurrenceDay === 'weekly' ||
      recurrenceDay === 'allWeek' ||
      (dataCalendar && dataCalendar.freq === 'week')
    ) {
      defaultParams.byweekday = optionByWeekDay;
    }
    if (
      recurrenceDay === 'monthly' ||
      (dataCalendar && dataCalendar.repeatMonth === 'monthly')
    ) {
      defaultParams.byNweekDay = {
        weekday: optionDay(watchSendingDate || ''),
        n: optionByNWeekday(watchSendingDate || ''),
      };
    }
    return defaultParams;
  }, [
    dataCalendar,
    optionByWeekDay,
    optionFreq,
    recurrenceDay,
    watchSendingDate,
    watchSendingTime,
  ]);

  const [createPatient, { loading: loadingCreatePatient }] = useMutation<
    CreateNewPatientMutation,
    CreateNewPatientMutationVariables
  >(CREATE_NEW_PATIENT, {
    onCompleted(data) {
      if (data.createNewPatient.data) {
        sameUsers.current = null;
        samePatients.current = data.createNewPatient.data;
        setOpenSamePatient(true);
        return;
      }
      if (data.createNewPatient.userExists) {
        sameUsers.current = data.createNewPatient.userExists;
        setOpenSamePatient(true);
        return;
      }
      if (patientId) {
        enqueueSnackbar('Patient update', {
          variant: 'success',
        });
        customEvent('patientUpdatedOnPD', 'NAVIGATOR');
      } else {
        enqueueSnackbar('Patient added', {
          variant: 'success',
        });
        customEvent('patientAddedOnPD', 'NAVIGATOR');
      }
      samePatients.current = null;
      sameUsers.current = null;
      setOpenSamePatient(false);
      toogleDialog(false);
      if (callback) {
        callback();
      }
      if (handleClose) {
        handleClose();
      }
      const defaultParams = {
        patientInfo: [
          {
            id: data?.createNewPatient?.patient?._id,
            email: data?.createNewPatient?.patient?.email,
            phone: (data?.createNewPatient?.patient?.phones as Phone[])?.find(
              item => item.default,
            )?.phone,
            sendSMS: checked.includes('sendSMS'),
            sendEmail: checked.includes('sendEmail'),
          },
        ],
        smsMessage: valueCustom,
        scheduleConfig: paramsCreateEvent,
        resend48h: checked?.includes('remind'),
      };

      //create config calendar for patient
      if (watchSurveySend) {
        createCalendarConfig({
          variables: {
            params: {
              ...defaultParams,
              treatmentId: watchSurveySend?.idSurvey,
            },
          },
        });
        return;
      }
      if (watchTreatmentSend) {
        createCalendarConfig({
          variables: {
            params: {
              ...defaultParams,
              treatmentId: watchTreatmentSend?._id,
            },
          },
        });
        return;
      }
      if (watchGroup) {
        createCalendarConfigByRegimen({
          variables: {
            params: {
              patientInfo: defaultParams.patientInfo,
              treatmentRegimenId: watchGroup?._id,
              smsMessage: valueCustom,
              startDate: paramsCreateEvent.dtstart,
            },
          },
        });
      }
    },
    onError(error) {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
  });

  const isObjectUser = (item: UserFragment | string | null) => {
    return (item as UserFragment)?.email !== undefined;
  };

  const checkConditionForSending = (data: FormData) => {
    if (data.treatmentSend || data.surveySend || data.group) {
      return checked.length === 0;
    }
    return false;
  };

  const handleSave = handleSubmit(data => {
    const { sendingDate, sendingTime, treatment, phones, ...restValue } = data;
    if (handleAddInfor) {
      handleAddInfor(data, patientInfo?.email as string);
      toogleDialog(false);
      return;
    }
    if (checkConditionForSending(data)) {
      enqueueSnackbar(
        'Need at least one method to send survey with customize sending time',
        {
          variant: 'error',
        },
      );
      return;
    }

    if (phones && formPhoneComponentProps.indexDefault !== undefined) {
      phones[formPhoneComponentProps.indexDefault].default = true;
    }
    const paramsPhones = phones?.map(item => {
      return { ...item, phone: `+${item.phone}` };
    });
    if (Object.is(typeEmail, 'personal')) {
      if (emailValue) {
        savePatient({
          ...restValue,
          phones: paramsPhones,
          userId: userId?.length !== 0 ? userId : undefined,
        });
        return;
      }
      return;
    }

    savePatient({
      ...restValue,
      phones: paramsPhones,
      userId: userId?.length !== 0 ? userId : undefined,
    });
  });

  const updateMatchPatient = handleSubmit(data => {
    const {
      sendingDate,
      sendingTime,
      surveyAssign,
      treatment,
      phones,
      ...restValue
    } = data;
    if (phones && formPhoneComponentProps.indexDefault !== undefined) {
      phones[formPhoneComponentProps.indexDefault].default = true;
    }
    savePatient({
      ...restValue,
      phones,
      userId: userId?.length !== 0 ? userId : undefined,
      patientId: patientId,
    });
  });

  const handleClosePopover = () => {
    toogleDialog(false);
    if (handleClose) {
      handleClose();
    }
  };

  const savePatient = (data: FormData) => {
    if (errorEmail.length === 0) {
      const dataTrim = trimObjectValue(data);
      const { surveyAssign, treatmentAssign, ...restData } = dataTrim;
      const dataCreate = {
        ...restData,
        middleName: restData?.middleName ? restData?.middleName : null,
        email: isObjectUser(emailValue)
          ? emailValue?.email
          : typeEmail === 'noEmail'
          ? null
          : emailValue,
        emrProvider: restData?.emrProvider?._id
          ? [restData?.emrProvider?._id]
          : null,
        provider: restData?.provider?._id ? [restData?.provider?._id] : null,
        treatmentIds: data.treatmentAssign ? [data.treatmentAssign._id] : null,
        surveyIds: data?.surveyAssign ? [data?.surveyAssign.idSurvey] : null,
        dob: convertDateWithoutUTC(restData.dob as string),
        mrn: restData.mrn || null,
        ignoreDuplicate: samePatients.current?.length !== 0,
        race: restData.race === '' ? null : (restData.race as Race),
        patientId: restData.patientId || null,
        sendSMS: checked.includes('sendSMS'),
        sendEmail: checked.includes('sendEmail'),
        smsMessage: valueCustom,
      };
      const {
        group,
        race,
        treatmentSend,
        surveySend,
        ...paramsCreate
      } = dataCreate;
      if (race) {
        (paramsCreate as CreateNewPatientInput).race = race as Race;
      }
      //create new patient
      createPatient({
        variables: {
          params: {
            ...paramsCreate,
          } as CreateNewPatientInput,
        },
      });
    }
  };

  const handleChangetypeEmail = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setErrorEmail('');
    setTypeEmail((event.target as HTMLInputElement).value);
  };

  const renderDefaultChecked = useMemo(() => {
    let defaultValue = [];
    defaultValue = [...checked, 'remind'];
    if (
      ['notRepeat', 'daily', 'weekly', 'allWeek'].includes(recurrenceDay) ||
      //repeat every with day less than 7  freq=3(RRule.WEEKLY)
      (dataCalendar?.freq === 'day' && dataCalendar?.amount <= 7) ||
      //every week: freq=2(RRule.WEEKLY)
      (dataCalendar?.freq === 'week' && +dataCalendar?.amount === 1) ||
      //if end date <= one week from send on.
      new Date(dataCalendar?.endDate || '').getTime() <=
        new Date(addDays(new Date(watchSendingDate || ''), 7)).getTime()
    ) {
      defaultValue = defaultValue.filter(item => item !== 'remind');
      return defaultValue;
    }
    return defaultValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurrenceDay, watchSendingDate, dataCalendar]);

  useEffect(() => {
    setChecked([...renderDefaultChecked]);
  }, [renderDefaultChecked, setChecked]);

  const enterDialog = () => {
    if (patientInfo) {
      setEmailValue(patientInfo);
      setDefaultValue(patientInfo);
    }
    getProviders({
      variables: {
        params: {
          page: 1,
          limit: 10,
          sortByOrder: { provider: 1 },
        },
      },
    });
  };

  const handleTextInput = (text: string) => {
    if (text?.length === 2) {
      getListUser({
        variables: { params: { search: text, sortByOrder: { firstName: 1 } } },
      });
    }
  };

  const setDefaultEmpty = useCallback(() => {
    setValue('firstName', '');
    setValue('lastName', '');
    setValue('dob', null);
    setValue('mrn', '');
    setValue('phones', []);
    setValue('gender', '');
    setValue('race', '');
    setValue('provider', undefined);
    setValue('emrProvider', undefined);
    setValue('address', '');
    if (formPhoneComponentProps.fields.length) {
      remove(Array.from(Array(formPhoneComponentProps.fields.length).keys()));
    }
  }, [formPhoneComponentProps.fields.length, remove, setValue]);

  const setDefaultValue = useCallback(
    (objUser: UserFragment | string) => {
      if (typeof objUser !== 'string') {
        reset({
          firstName: objUser.firstName as string,
          lastName: objUser.lastName as string,
          middleName: objUser.middleName || '',
          dob: objUser.dob,
          mrn: objUser.mrn as string,
          phones: objUser.phones || [],
          gender: patientInfo?.gender,
          provider: patientInfo?.provider,
          emrProvider: patientInfo?.emrProvider,
          address: patientInfo?.address,
          race: patientInfo?.race,
        });
      } else {
        setDefaultEmpty();
      }
    },
    [patientInfo, reset, setDefaultEmpty],
  );

  const validationEmail = (email: any) => {
    if (!email) {
      setErrorEmail('This field is required');
      return;
    }
    if (email) {
      const sendEmailOnSystem = !!listUser?.getUsersForNavigator.nodes.find(
        item => item.email === email,
      );

      if (!EMAIL_REGEX.test(email as string)) {
        setErrorEmail('Email is invalid');
        return;
      }
      if (sendEmailOnSystem && emailFromTextField.current.hasValue) {
        setErrorEmail(
          'This email is already exists, You need to select this in system',
        );
        return;
      }
    }

    setErrorEmail('');
  };

  const onChangeEmail = (value: any, isValueFromTextField: boolean) => {
    if (isValueFromTextField || typeof value === 'string') {
      emailFromTextField.current = { value, hasValue: true };
      return;
    }
    emailFromTextField.current = { value: null, hasValue: false };
    const email = isObjectUser(value) ? (value as UserFragment).email : value;

    if (value) {
      if (value._id !== emailValue?._id) {
        setDefaultValue(value);
      }
    } else {
      setDefaultEmpty();
    }
    setEmailValue(value);
    validationEmail(email);
    // setEmailOptSelected(false)
    // console.log("SDsdds")
  };

  const setInformation = () => {
    if (emailFromTextField.current.hasValue) {
      validationEmail(emailFromTextField.current.value);
      setEmailValue(emailFromTextField.current.value);
      if (isObjectUser(emailValue)) {
        setDefaultEmpty();
      }
      return;
    }
  };

  const cancelCreatePatient = () => {
    setPatientId('');
    setUserId('');
    samePatients.current = [];
    setOpenSamePatient(false);
  };

  const debounceOnChange = useCallback(debounce(400, handleTextInput), []);

  return (
    <>
      <DialogSamePatients
        setPatientId={setPatientId}
        setUserId={setUserId}
        userId={userId}
        sameUsers={sameUsers.current}
        open={isOpenSamePatient}
        toogleDialog={setOpenSamePatient}
        patients={samePatients.current}
        updatePatient={updateMatchPatient}
        createPatient={handleSave}
        callbackCancel={cancelCreatePatient}
        patientId={patientId}
        loading={loadingCreatePatient}
      />
      <FormContext {...methods}>
        <Dialog open={open} onEnter={enterDialog} maxWidth="md" fullWidth>
          <DialogTitleClose title="Add Patient" onClose={handleClosePopover} />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TypographyBold variant="subtitle1">Information</TypographyBold>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  inputProps={{ maxLength: 255 }}
                  label="First Name"
                  required
                  size="small"
                  name="firstName"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  inputRef={register({
                    validate: {
                      required: value =>
                        value.trim() !== '' || 'This field is required',
                    },
                  })}
                  error={!!errors.firstName}
                  helperText={errors.firstName ? errors.firstName.message : ''}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  inputRef={register({
                    validate: {
                      required: value =>
                        value.trim() !== '' || 'This field is required',
                    },
                  })}
                  inputProps={{ maxLength: 255 }}
                  label="Last Name"
                  size="small"
                  required
                  name="lastName"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  error={!!errors.lastName}
                  helperText={errors.lastName ? errors.lastName.message : ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  inputProps={{ maxLength: 255 }}
                  label="Middle Name"
                  size="small"
                  name="middleName"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  as={
                    <KeyboardDatePicker
                      required
                      placeholder="mm/dd/yyyy"
                      inputVariant="outlined"
                      label="Date of Birth"
                      format="MM/dd/yyyy"
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      value={() => {}}
                      onChange={() => {}}
                      variant="inline"
                      autoOk
                      error={!!errors?.dob}
                      helperText={
                        errors?.dob
                          ? errors?.dob?.types?.message || errors?.dob.message
                          : ''
                      }
                      keyboardIcon={<DateRangeIcon />}
                    />
                  }
                  name="dob"
                  control={control}
                  rules={{
                    validate: {
                      required: value =>
                        value !== null || 'This field is required',
                      pattern: value => {
                        return (
                          !isNaN(new Date(value).getTime()) ||
                          'Invalid date format'
                        );
                      },
                    },
                  }}
                />
              </Grid>
              {!patientInfo && (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <TypographyBold variant="subtitle1">
                      Prefered Communication
                    </TypographyBold>
                    <TypographyItalic color="textSecondary" variant="caption">
                      (Need to select at least one method to send treatment,
                      survey or group)
                    </TypographyItalic>
                  </Grid>
                  <Grid item xs={12}>
                    <RadioGroup
                      row
                      name="typeEmail"
                      value={typeEmail}
                      onChange={handleChangetypeEmail}
                      className="ml-8"
                    >
                      <FormControlLabel
                        value="personal"
                        control={<Radio color="primary" />}
                        label="Personal Email"
                      />
                      <FormControlLabel
                        value="noEmail"
                        control={<Radio color="primary" />}
                        label="No Email"
                      />
                    </RadioGroup>
                  </Grid>
                </>
              )}
              {Object.is(typeEmail, 'personal') && (
                <>
                  {!patientInfo && (
                    <Grid item xs={1}>
                      <OnlyChecked
                        value={[{ value: 'sendEmail' }]}
                        {...defaulCheckedProps}
                      />
                    </Grid>
                  )}
                  {!patientInfo?.phones && (
                    <Grid item xs={patientInfo ? 12 : 11}>
                      <Autocomplete
                        open={emailOptSelected}
                        onOpen={() => setEmailOptSelected(true)}
                        onClose={() => setEmailOptSelected(false)}
                        renderOption={(option, { selected }) => {
                          const optionSet = option as UserFragment;
                          return (
                            <>
                              {/* <Checkbox
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                className="mr-8"
                                checked={selected}
                              /> */}
                              <Box display="flex" flexDirection="column">
                                <Typography variant="h6" gutterBottom>
                                  {optionSet.firstName +
                                    (optionSet.middleName || '') +
                                    ' ' +
                                    optionSet.lastName}
                                </Typography>
                                <Box display="flex" alignItems="center">
                                  <Typography
                                    className="mr-8"
                                    color="primary"
                                    variant="subtitle1"
                                  >
                                    {optionSet.email}
                                  </Typography>
                                </Box>
                              </Box>
                            </>
                          );
                        }}
                        freeSolo
                        options={listUser?.getUsersForNavigator.nodes || []}
                        getOptionLabel={option => {
                          return typeof option === 'string'
                            ? option
                            : option.email;
                        }}
                        onChange={(event: any, newValue: any) => {
                          onChangeEmail(newValue, false);
                        }}
                        disabled={!!patientInfo}
                        value={emailValue}
                        renderInput={params => (
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            {...{
                              InputProps: params.InputProps,
                              id: params.id,
                              disabled: params.disabled,
                              inputProps: params.inputProps,
                            }}
                            required
                            label="Email"
                            size="small"
                            variant="outlined"
                            fullWidth
                            error={!!errorEmail}
                            helperText={errorEmail}
                            onBlur={setInformation}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              onChangeEmail(e.target.value, true);
                              debounceOnChange(e.target.value);
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </>
              )}
              {!patientInfo && (
                <Grid item xs={1}>
                  <OnlyChecked
                    disabled={formPhoneComponentProps.fields.length === 0}
                    value={[{ value: 'sendSMS' }]}
                    {...defaulCheckedProps}
                  />
                </Grid>
              )}
              {!patientInfo?.email && (
                <Grid
                  item
                  xs={patientInfo ? 12 : 11}
                  container
                  spacing={patientInfo ? 0 : 2}
                >
                  <FormPhoneComponent
                    disabledActions={!!patientInfo}
                    isTreatmentDesign={!!patientInfo}
                    {...formPhoneComponentProps}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <TextfieldSelect
                  control={control}
                  name="gender"
                  defaultValue=""
                  required
                  label="Gender"
                  selectProps={{
                    renderValue: value => {
                      return value ? (
                        <>{LIST_GENDER[value as Gender]}</>
                      ) : (
                        <Box color="text.secondary">Choose</Box>
                      );
                    },
                  }}
                  error={!!errors?.gender}
                >
                  {Object.keys(LIST_GENDER).map(item => {
                    return (
                      <MenuItem key={item} value={item}>
                        {LIST_GENDER[item]}
                      </MenuItem>
                    );
                  })}
                </TextfieldSelect>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="MRN"
                  size="small"
                  name="mrn"
                  inputProps={{ maxLength: 255 }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  inputRef={methods!.register({
                    pattern: {
                      value: /[0-9]+-[0-9]+/,
                      message: 'This field is wrong format',
                    },
                  })}
                  error={!!errors.mrn}
                  helperText={errors.mrn ? errors.mrn.message : ''}
                />
              </Grid>
              <ProviderComponent {...providerComponentProps} />
              <Grid item xs={6}>
                <TextField
                  inputProps={{ maxLength: 255 }}
                  label="Address"
                  size="small"
                  name="address"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="race"
                  control={control}
                  defaultValue=""
                  as={
                    <TextField
                      placeholder="Choose"
                      size="small"
                      fullWidth
                      label="Race"
                      InputLabelProps={{ shrink: true }}
                      select
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <>
                            {watchRace !== '' ? (
                              <InputAdornmentStyled
                                position="end"
                                className="cursor-pointer"
                                onClick={() => {
                                  setValue('race', '');
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </InputAdornmentStyled>
                            ) : null}
                          </>
                        ),
                      }}
                      SelectProps={{
                        displayEmpty: true,
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        },
                        renderValue: value => {
                          return value ? (
                            <>{LIST_RACE[value as Race]}</>
                          ) : (
                            <Box color="text.secondary">Choose</Box>
                          );
                        },
                      }}
                    >
                      {Object.keys(LIST_RACE).map(item => {
                        return (
                          <MenuItem key={item} value={item}>
                            {LIST_RACE[item]}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  }
                />
              </Grid>

              {!handleAddInfor && (
                <>
                  <AssignTreatments
                    methods={methods}
                    treatmentHomePage={treatmentHomePage}
                  />
                  {checked.length !== 0 && (
                    <>
                      <SendTreatments
                        methods={methods}
                        dataCalendar={dataCalendar}
                        setDataCalendar={setDataCalendar}
                        setRecurrenceDay={setRecurrenceDay}
                        recurrenceDay={recurrenceDay}
                        OnlyChecked={OnlyChecked}
                        defaulCheckedProps={defaulCheckedProps}
                        treatmentHomePage={treatmentHomePage}
                      />
                      {checked.includes('sendSMS') && (
                        <InputMentions {...defaulInputMentionsProps} />
                      )}
                    </>
                  )}
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <DialogButton isCancel onClickButton={handleClosePopover} />
            <DialogButton
              loading={loadingCreatePatient}
              onClickButton={() => {
                if (!emailValue && Object.is(typeEmail, 'personal')) {
                  validationEmail(null);
                }
                if (!errorEmail) {
                  handleSave();
                }
              }}
            />
          </DialogActions>
        </Dialog>
      </FormContext>
    </>
  );
};

export default React.memo(DialogImportPatient);
