import React, { useState, useRef, useContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import { DialogButton } from 'components';
import { DialogTitleClose } from 'components';
import {
  UpdateStageChangedLogMutation,
  UpdateStageChangedLogMutationVariables,
  TreatmentStagesFragment,
  CurrentStageFragment,
} from 'types.d';
import {
  CurrentStageFragment as CurrentStageFragmentGql,
  UPDATE_STAGE_CHANGEG_LOG,
} from 'gql';
import { useMutationCustom } from 'hooks';
import { TypographyItalic } from 'share/component_css';
import { useSnackbar } from 'notistack';
import { PatientDetailContext, PatientStageContext } from 'share/context';

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
  dataStage: CurrentStageFragment | null;
  updateStageForRoot: (
    dataUpdate: UpdateStageChangedLogMutation['updateStageChangedLog'],
  ) => void;
};

export const DialogUpdateStage: React.FC<Props> = ({
  open,
  toogleDialog,
  dataStage,
  updateStageForRoot,
}) => {
  const [stageSelected, setStageSelected] = useState('');

  const patientDetailContext = useContext(PatientDetailContext);

  const patientStageContext = useContext(PatientStageContext);

  const previousStageSelected = useRef('');

  const { enqueueSnackbar } = useSnackbar();

  const [
    updatePatientStage,
    { loading: loadingUpdatePatientStage, client },
  ] = useMutationCustom<
    UpdateStageChangedLogMutation,
    UpdateStageChangedLogMutationVariables
  >({
    api: UPDATE_STAGE_CHANGEG_LOG,
    textSuccess: 'Update stage for this patient successfully',
    callbackSuccess: data => {
      toogleDialog(false);
      try {
        // update current stage
        client?.writeFragment({
          id: `CurrentStageRes:${dataStage?._id}`,
          fragment: CurrentStageFragmentGql,
          fragmentName: 'CurrentStage',
          data: {
            ...dataStage,
            stage: data.updateStageChangedLog?.treatmentStageEmbed,
          },
        });
        //refresh stage change log
        patientStageContext?.refreshStageChangeLog();
      } catch (err) {
        console.log(err);
      }
    },
  });

  const onSubmit = () => {
    if (previousStageSelected.current === stageSelected && stageSelected) {
      toogleDialog(false);
      return;
    }
    if (stageSelected) {
      updatePatientStage({
        variables: {
          params: {
            rootTreatmentId: dataStage?._id,
            nextStageId: stageSelected,
            userId: patientDetailContext?.patient?.owner?.idOwner ?? '',
          },
        },
      });
    } else {
      enqueueSnackbar('You need to select at least one stage', {
        variant: 'error',
      });
    }
  };

  const enterDialog = () => {
    setStageSelected(dataStage?.stage?.treatmentStage?._id || '');
    previousStageSelected.current = dataStage?.stage?.treatmentStage?._id || '';
  };
  const changeStage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStageSelected(event.target.value);
  };

  const renderCheckBox = (item: TreatmentStagesFragment) => {
    return (
      <FormControlLabel
        className="w-100"
        key={item?.treatmentStage?.stageId}
        value={item?.treatmentStage?.stageId}
        control={<Radio onChange={changeStage} color="primary" />}
        checked={stageSelected === item?.treatmentStage?.stageId}
        label={`${item.name} ${` - ${item.treatmentStage?.linkTo?.name ||
          'N/A'}`}`}
        disabled={!item.treatmentStage?.linkTo}
        name="stages"
      />
    );
  };

  return (
    <>
      <Dialog onEnter={enterDialog} open={open} maxWidth="xs" fullWidth>
        <DialogTitleClose
          title="Update stage"
          onClose={() => toogleDialog(false)}
        />

        <DialogContent>
          <Box>
            <Box>
              {dataStage?.rootTreatment?.treatmentStages?.map(item => {
                return renderCheckBox(item);
              })}
              {!dataStage?.rootTreatment?.treatmentStages?.length && (
                <TypographyItalic
                  align="center"
                  variant="subtitle1"
                  color="textSecondary"
                >
                  There are no Stages
                </TypographyItalic>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <DialogButton isCancel onClickButton={() => toogleDialog(false)} />
          <DialogButton
            loading={loadingUpdatePatientStage}
            onClickButton={onSubmit}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(DialogUpdateStage);
