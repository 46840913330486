import React, { useState, useMemo } from 'react';

import {
  Box,
  MenuItem,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
  Grid,
  RadioGroup,
  FormControlLabel,
  DialogActions,
  Radio,
} from '@material-ui/core';
import format from 'date-fns/format';
import { OPTIONS_RECURRENCE, OPTIONS_DAY } from 'CONST';
import {
  getOptionMonth,
  optionWeekly,
  getByNWeekDay,
  optionByNWeekday,
  optionDay,
  getRRuleToText,
} from 'share/utils';
import { FormContextValues, useForm } from 'react-hook-form';
import {
  DialogButton,
  DialogTitleClose,
  TextfieldSelect,
  KeyboardDateOption,
} from 'components';
import { TypographyBold, TypographyItalic } from 'share/component_css';
import { DataCalendar } from 'modules/patients/components/Survey/DialogSendSurvey';
import { RRule } from 'rrule';

type FormData = {
  endDate: string | null;
  amount: number;
  after?: number;
  before?: number;
  freq: string;
  repeatWeek: string;
  repeatMonth: string;
};

type DataCalendarProps<T> = {
  customMethods: FormContextValues<FormData>;
  typeEnd: string;
  setTypeEnd: (value: string) => void;
};

type OutSideProps = {
  isOpenDialog: boolean;
  setOpenDialogCustom: (status: boolean) => void;
  sendOn: string;
  setDataCalendar: (data: DataCalendar) => void;
  dataCalendar: DataCalendar;
  isUpdate: boolean;
  setRecurrenceDay: (value: string) => void;
};

export const useCustomPopup = <T extends FormData>(): {
  dataCalendarProps: DataCalendarProps<T>;
  CustomTime: any;
} => {
  const [typeEnd, setTypeEnd] = useState('never');

  const customMethods = useForm<FormData>({
    defaultValues: {
      amount: 1,
      endDate: '',
      after: undefined,
      before: undefined
    },
  });

  const dataCalendarProps = {
    customMethods,
    typeEnd,
    setTypeEnd,
  };

  return {
    dataCalendarProps,
    CustomTime,
  };
};

export const CustomTime: React.FC<DataCalendarProps<any> & OutSideProps> = ({
  isOpenDialog,
  setOpenDialogCustom,
  customMethods,
  typeEnd,
  setTypeEnd,
  sendOn,
  setDataCalendar,
  dataCalendar,
  isUpdate,
  setRecurrenceDay,
}) => {
  const handleChangetypeEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeEnd((event.target as HTMLInputElement).value);
  };

  const { errors, setValue, watch } = customMethods;

  const handleClose = () => {
    setOpenDialogCustom(false);
    if (!isUpdate && !dataCalendar) {
      setRecurrenceDay('notRepeat');
    }
  };

  const watchFreq = watch('freq');

  const watchRepeatWeek = watch('repeatWeek');

  const watchRepeatMonth = watch('repeatMonth');

  const watchAmount = watch('amount');

  const watchEndDate = watch('endDate');

  const watchAfter = watch('after');

  const watchBefore = watch('before');

  const optionFreq = useMemo(() => {
    if (watchFreq === 'day') {
      return RRule.DAILY;
    }
    if (watchFreq === 'week') {
      return RRule.WEEKLY;
    }
    if (watchFreq === 'month') {
      return RRule.MONTHLY;
    }
    return RRule.YEARLY;
  }, [watchFreq]);

  const optionByWeekDay = useMemo(() => {
    if (watchFreq === 'week') {
      return [optionWeekly(watchRepeatWeek || dataCalendar?.repeatWeek)];
    }
    if (
      watchFreq === 'month' &&
      (watchRepeatMonth || dataCalendar?.repeatMonth) === 'monthly'
    ) {
      return [getByNWeekDay(optionDay(sendOn)).nth(optionByNWeekday(sendOn))];
    }
  }, [dataCalendar, sendOn, watchFreq, watchRepeatMonth, watchRepeatWeek]);

  const hintText = useMemo(() => {
    if (!sendOn) {
      return;
    }
    let rruleObject = {
      amount: watchAmount,
      freq: watchFreq,
      repeatWeek: watchRepeatWeek,
      repeatMonth: watchRepeatMonth,
    } as DataCalendar;
    if (watchAfter && typeEnd === 'after') {
      rruleObject.after = watchAfter;
    }
    if (
      watchEndDate &&
      !isNaN(new Date(watchEndDate).getTime()) &&
      !watchEndDate.includes('_') &&
      typeEnd === 'on'
    ) {
      rruleObject.endDate = watchEndDate;
    }
    return getRRuleToText(
      rruleObject,
      optionFreq,
      sendOn,
      optionByWeekDay as number[],
    );
  }, [
    sendOn,
    typeEnd,
    watchEndDate,
    watchAmount,
    watchAfter,
    watchFreq,
    watchRepeatWeek,
    watchRepeatMonth,
    optionFreq,
    optionByWeekDay,
  ]);

  const enterDialog = () => {
    setValue('amount', dataCalendar?.amount || 1);
    if (dataCalendar?.endDate) {
      setTypeEnd('on');
      setTimeout(function () {
        setValue(
          'endDate',
          format(
            dataCalendar.endDate ? new Date(dataCalendar.endDate) : new Date(),
            'MM/dd/yyyy',
          ),
        );
      }, 100);
    }
    if (dataCalendar?.after) {
      setTypeEnd('after');
      setTimeout(function () {
        setValue('after', dataCalendar.after);
      }, 100);
    }
  };

  const handleSave = customMethods.handleSubmit(data => {
    const { endDate, amount, after, freq, repeatWeek, repeatMonth } = data;
    if (!errors.amount && !errors.after && !errors.endDate) {
      setOpenDialogCustom(false);
    }
    setDataCalendar({
      endDate: endDate,
      amount: amount,
      after: after,
      freq: freq,
      repeatWeek: repeatWeek,
      repeatMonth: repeatMonth,
    });
  });

  return (
    <Dialog
      open={isOpenDialog}
      onEnter={enterDialog}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitleClose title="Custom Recurrence" onClose={handleClose} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TypographyBold>Repeat every</TypographyBold>
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="number"
              label="Amount"
              variant="outlined"
              name="amount"
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
              size="small"
              helperText={!!errors.amount ? 'Value is invalid' : ''}
              error={!!errors.amount}
              inputRef={customMethods.register({
                validate: {
                  required: value =>
                    value.trim() !== '' || 'This field is required',
                },
                min: 1,
              })}
              inputProps={{ min: 1 }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextfieldSelect
              label="Repeat every"
              name="freq"
              small
              control={customMethods.control}
              defaultValue={dataCalendar?.freq ? dataCalendar?.freq : 'day'}
            >
              {OPTIONS_RECURRENCE.map(item => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.title}
                  </MenuItem>
                );
              })}
            </TextfieldSelect>
          </Grid>
          {customMethods.watch('freq') === 'week' && (
            <Grid item xs={6}>
              <TextfieldSelect
                label="Repeat on"
                name="repeatWeek"
                small
                control={customMethods.control}
                defaultValue={
                  dataCalendar?.repeatWeek ? dataCalendar?.repeatWeek : 'Monday'
                }
              >
                {OPTIONS_DAY.map(item => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </TextfieldSelect>
            </Grid>
          )}
          {customMethods.watch('freq') === 'month' && (
            <Grid item xs={6}>
              <TextfieldSelect
                label="Repeat on"
                name="repeatMonth"
                small
                control={customMethods.control}
                defaultValue={
                  dataCalendar?.repeatMonth === 'monthly'
                    ? getOptionMonth(sendOn)[1].value
                    : 'daily'
                }
              >
                {getOptionMonth(sendOn).map(item => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </TextfieldSelect>
            </Grid>
          )}
          <Grid item xs={12}>
            <TypographyBold>Ends</TypographyBold>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex">
              <Grid item xs={6}>
                <RadioGroup
                  row
                  name="typeEmail"
                  value={typeEnd}
                  onChange={handleChangetypeEnd}
                >
                  <FormControlLabel
                    value="never"
                    control={<Radio color="primary" />}
                    label="Never"
                  />
                  <FormControlLabel
                    value="on"
                    control={<Radio color="primary" />}
                    label="On"
                  />
                  <FormControlLabel
                    value="after"
                    control={<Radio color="primary" />}
                    label="After"
                  />
                </RadioGroup>
              </Grid>
              {typeEnd === 'on' && (
                <Grid item xs={6} className="pl-8">
                  <KeyboardDateOption
                    methods={customMethods}
                    name="endDate"
                    label="Ends On"
                    moreRules={{
                      compare: value =>
                        new Date(value).getTime() >=
                        new Date(sendOn || '').getTime() ||
                        'The end date need to be greater than submission date',
                    }}
                  />
                </Grid>
              )}
              {typeEnd === 'after' && (
                <Grid item xs={6} className="pl-8">
                  <TextField
                    required
                    type="number"
                    label="After"
                    variant="outlined"
                    name="after"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    size="small"
                    helperText={!!errors.after ? 'Value is invalid' : ''}
                    error={!!errors.after}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          Occurrences
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ min: 1 }}
                    inputRef={customMethods.register({
                      min: 1,
                      validate: {
                        required: value =>
                          value.trim() !== '' || 'This field is required',
                      },
                    })}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TypographyItalic color="textSecondary" variant="subtitle2">
              {hintText}
            </TypographyItalic>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogButton isCancel onClickButton={handleClose} />
        <DialogButton onClickButton={handleSave} />
      </DialogActions>
    </Dialog>
  );
};
