import React, {
  useContext,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core';
import {
  useQueryCustom,
  useToogleDialog,
  useMutationCustom,
  InfoPagination,
  usePagination,
  useSortTable,
  useUpdateMeClient,
} from 'hooks';
import { GET_LIST_PATIENT_STAGE, DELETE_PATIENT_STAGE } from 'gql';
import {
  GetListPatientStagesQuery,
  GetListPatientStagesQueryVariables,
  PatientCondStageFragment,
  DeletePatientStageMutation,
  DeletePatientStageMutationVariables,
  OrderBy,
} from 'types.d';
import { PatientDetailContext } from 'share/context';
import {
  NoDataTable,
  LoadingTable,
  ButtonLoading,
  DeleteConfirm,
} from 'components';
import { formatDate } from 'share/utils';
import { TableContainerStyled, PaperBorder } from 'share/component_css';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DialogConditionStage from './DialogConditionStage';
import { useServices } from '../../services';
import DeleteIcon from '@material-ui/icons/Delete';

type Props = {};

export const ConditionStage: React.FC<Props> = () => {
  const patientDetailContext = useContext(PatientDetailContext);

  const { meClient } = useUpdateMeClient();

  const conditionStageSelected = useRef<PatientCondStageFragment | undefined>(
    undefined,
  );

  const {
    sortTableComponentProps,
    SortTableComponent,
    typeSort,
  } = useSortTable();

  const [isOpenDelete, handleToogleDialogDelete] = useToogleDialog();

  const { refetchQueriesCondition } = useServices();

  const [
    openDialogConditionStage,
    setOpenDialogConditionStage,
  ] = useToogleDialog();

  const callbackFetchData = (infoPagination: InfoPagination) => {
    fetchMoreConditionType(infoPagination);
  };

  const {
    infoPagination,
    DefaultPagination,
    defaultPaginationProps,
    handleSetInfoPagination,
  } = usePagination(callbackFetchData);

  const [
    deletePatientStage,
    { loading: loadingDeletePatientStage },
  ] = useMutationCustom<
    DeletePatientStageMutation,
    DeletePatientStageMutationVariables
  >({
    api: DELETE_PATIENT_STAGE,
    refetchQueries: refetchQueriesCondition(),
    textSuccess: 'Remove condition successfully',
    callbackSuccess: () => {
      if (
        (dataListPatientStage?.getListPatientStages.nodes?.length || 0) === 1
      ) {
        handleSetInfoPagination({ ...infoPagination, page: 1 }, true);
      } else {
        fetchMoreConditionType(infoPagination);
      }
      conditionStageSelected.current = undefined;
      handleToogleDialogDelete(false);
      patientDetailContext?.handleSetDialogOpen('');
    },
  });

  const {
    data: dataListPatientStage,
    loading: loadingListPatientStage,
    fetchMore,
  } = useQueryCustom<
    GetListPatientStagesQuery,
    GetListPatientStagesQueryVariables
  >({
    api: GET_LIST_PATIENT_STAGE,
    variables: {
      params: {
        page: 1,
        limit: meClient.setting?.perPage || 5,
        patientId: patientDetailContext?.patient.owner?.idOwner,
        sort: { startDate: OrderBy.Desc },
      },
    },
  });

  const fetchMoreConditionType = useCallback(
    (infoPagination: InfoPagination, sort?: OrderBy) => {
      const { page, limit } = infoPagination;
      let params: GetListPatientStagesQueryVariables['params'] = {
        page,
        limit,
        patientId: patientDetailContext?.patient.owner?.idOwner,
      };
      if (sort || typeSort) {
        params.sort = { startDate: (sort || typeSort) as OrderBy };
      }
      fetchMore({
        variables: {
          params,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            getListPatientStages: {
              ...prev.getListPatientStages,
              nodes: fetchMoreResult!.getListPatientStages!.nodes!,
              meta: fetchMoreResult!.getListPatientStages!.meta!,
            },
          };
        },
      });
    },
    [fetchMore, patientDetailContext, typeSort],
  );

  const callback = useCallback(() => {
    handleSetInfoPagination({ ...infoPagination, page: 1 }, true);
  }, [handleSetInfoPagination, infoPagination]);

  const getStageName = (conditionType: PatientCondStageFragment) => {
    return (
      conditionType?.condition?.stages?.find(
        item => item.stageId === conditionType.stageId,
      )?.name || 'N/A'
    );
  };

  const editConditionStage = useCallback(
    (item: PatientCondStageFragment) => {
      conditionStageSelected.current = item;
      setOpenDialogConditionStage(true);
    },
    [setOpenDialogConditionStage],
  );

  const deleteConditionType = () => {
    deletePatientStage({
      variables: {
        params: {
          condStageId: conditionStageSelected.current?._id,
          patientId: patientDetailContext?.patient.owner?.idOwner,
          conditionId: conditionStageSelected.current?.condition?.conditionId,
        },
      },
    });
  };

  const openAddCondition = useCallback(() => {
    setOpenDialogConditionStage(true);
    conditionStageSelected.current = undefined;
  }, [setOpenDialogConditionStage]);

  const deleteCondition = (item: PatientCondStageFragment) => {
    conditionStageSelected.current = item;
    handleToogleDialogDelete(true);
  };

  const callbackSort = (type: OrderBy) => {
    fetchMoreConditionType(infoPagination, type);
  };

  //open adding condition dialog from outside (button "+" on tag )
  useEffect(() => {
    //open adding dialog
    if (patientDetailContext?.dialogOpen === 'add-condition') {
      openAddCondition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAddCondition, patientDetailContext?.dialogOpen]);

  //open updating condition dialog from outside
  useEffect(() => {
    //open updating dialog
    if (patientDetailContext?.dialogOpen === 'edit-condition') {
      setOpenDialogConditionStage(true);
      conditionStageSelected.current = patientDetailContext?.dataForOpenDialog;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetailContext?.dialogOpen]);

  //open updating condition dialog from outside
  useEffect(() => {
    //open deleting dialog
    if (patientDetailContext?.dialogOpen === 'delete-condition') {
      handleToogleDialogDelete(true);
      conditionStageSelected.current = patientDetailContext?.dataForOpenDialog;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetailContext?.dialogOpen]);

  const isInactive = useMemo(() => {
    return patientDetailContext?.isInactive;
  }, [patientDetailContext]);
  return (
    <>
      <DeleteConfirm
        loading={loadingDeletePatientStage}
        handleConfirm={deleteConditionType}
        open={isOpenDelete}
        toogle={handleToogleDialogDelete}
      />
      {openDialogConditionStage && (
        <DialogConditionStage
          conditionStageSelected={conditionStageSelected.current}
          callback={callback}
          open={openDialogConditionStage}
          closeDialog={() => setOpenDialogConditionStage(false)}
        />
      )}
      {!patientDetailContext?.isAnonymous && !isInactive && (
        <ButtonLoading
          Icon={<AddIcon />}
          className="mb-8 text-tranform-none"
          text="Add Condition"
          callbackClick={openAddCondition}
        />
      )}
      <Grid xs={12} item>
        <TableContainerStyled component={PaperBorder}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Condition</TableCell>
                <TableCell>Stage</TableCell>
                <SortTableComponent
                  callbackSort={callbackSort}
                  {...sortTableComponentProps}
                  name="Start Date"
                />
                <TableCell>End Date</TableCell>
                {!isInactive && <TableCell align="right">Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loadingListPatientStage &&
                dataListPatientStage?.getListPatientStages?.nodes?.length ===
                  0 && <NoDataTable colSpan={isInactive ? 4 : 5} />}
              {loadingListPatientStage && (
                <LoadingTable colSpan={isInactive ? 4 : 5} />
              )}
              {!loadingListPatientStage &&
                dataListPatientStage?.getListPatientStages.nodes.map(item => {
                  return (
                    <TableRow key={item._id}>
                      <TableCell>{item.condition?.name || 'N/A'}</TableCell>
                      <TableCell>{getStageName(item)}</TableCell>
                      <TableCell>
                        {item.startDate
                          ? formatDate(item.startDate, true)
                          : 'N/A'}
                      </TableCell>
                      <TableCell>
                        {item.endDate ? formatDate(item.endDate, true) : 'N/A'}
                      </TableCell>
                      {!isInactive && (
                        <TableCell align="right">
                          <IconButton
                            onClick={() => editConditionStage(item)}
                            color="primary"
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => deleteCondition(item)}
                            aria-label="delete"
                            color="secondary"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainerStyled>
        {dataListPatientStage?.getListPatientStages?.meta?.total !== 0 && (
          <DefaultPagination
            count={dataListPatientStage?.getListPatientStages?.meta?.total || 0}
            {...defaultPaginationProps}
          />
        )}
      </Grid>
    </>
  );
};

export default React.memo(ConditionStage);
