import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Box, Tabs, Tab } from '@material-ui/core';
import { DefaultTable } from 'components';
import { BoxWrapFilterTable, TypoThreeDotsLine } from 'share/component_css';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  GetAllFormsDataQuery,
  GetAllFormsDataQueryVariables,
  GetAnonymousSurveyQuery,
} from 'types.d';
import { GET_ALL_FORMS_DATA, GET_ANONYMOUS_SURVEY_RESULTS } from 'gql';
import {
  InfoPagination,
  useEffectOnlyOnce,
  usePagination,
  useUpdateMeClient,
} from 'hooks';
import { formatDate, getFilters } from 'share/utils';
import Survey from './components/RowSurvey';
import RowDynamicSuvey from './components/RowDynamicSuvey';
import {
  FormData,
  useFilterDynamicSurvey,
} from './components/useFilterDynamicSurvey';

type Props = {};

export const SurveyDashboard: React.FC<Props> = () => {
  const {
    FilterComponent,
    filterComponentProps,
    allValues,
  } = useFilterDynamicSurvey();

  const callbackFetchData = (infoPagination: InfoPagination) => {
    fetchMorePDFs(infoPagination, allValues as FormData);
  };

  const { meClient } = useUpdateMeClient();

  const {
    infoPagination,
    DefaultPagination,
    defaultPaginationProps,
    handleSetInfoPagination,
  } = usePagination(callbackFetchData);

  const [valueTab, setValueTab] = useState(0);

  const [
    getAnonymousSurveyResults,
    {
      loading: loadingAnonymousSurveyResults,
      data: dataAnonymousSurveyResults,
    },
  ] = useLazyQuery<GetAnonymousSurveyQuery>(GET_ANONYMOUS_SURVEY_RESULTS, {
    fetchPolicy: 'cache-and-network',
    onError(error) {},
  });

  const [
    getAllFormsData,
    { loading: loadingFormsData, data: formsData, fetchMore },
  ] = useLazyQuery<GetAllFormsDataQuery, GetAllFormsDataQueryVariables>(
    GET_ALL_FORMS_DATA,
    {
      fetchPolicy: 'cache-and-network',
      onError(error) {},
    },
  );
  const fetchMorePDFs = useCallback(
    (infoPagination: InfoPagination, valueFilter?: FormData) => {
      fetchMore({
        variables: {
          params: {
            ...infoPagination,
            ...getFilters(valueFilter),
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            getAllFormsData: {
              ...prev.getAllFormsData,
              nodes: fetchMoreResult!.getAllFormsData!.nodes!,
              meta: fetchMoreResult!.getAllFormsData!.meta!,
            },
          };
        },
      });
    },
    [fetchMore],
  );

  const callbackAfterAddNew = useCallback(() => {
    handleSetInfoPagination({ page: 1, limit: infoPagination.limit }, true);
  }, [handleSetInfoPagination, infoPagination]);

  const callbackDelete = () => {
    if ((formsData?.getAllFormsData?.nodes?.length || 0) === 1) {
      handleSetInfoPagination({ page: 1, limit: infoPagination.limit }, true);
    } else {
      callbackFetchData(infoPagination);
    }
  };

  const callbackReset = useCallback(
    (data: FormData) => {
      handleSetInfoPagination({ page: 1, limit: meClient.setting?.perPage });
      fetchMorePDFs({ page: 1, limit: meClient.setting?.perPage }, data);
    },
    [fetchMorePDFs, handleSetInfoPagination, meClient.setting],
  );

  const callbackClickFilter = (data: FormData) => {
    handleSetInfoPagination({ ...infoPagination, page: 1 });
    fetchMorePDFs({ ...infoPagination, page: 1 }, data);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === 1) {
      getAnonymousSurveyResults();
    }

    setValueTab(newValue);
  };

  useEffect(() => {
    getAllFormsData({
      variables: {
        params: {
          page: 1,
          limit: meClient.setting?.perPage || 5,
        },
      },
    });
  }, []);

  return (
    <>
      <Tabs
        value={valueTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        <Tab label="Dynamic Surveys" />
        <Tab label="Public Surveys" />
      </Tabs>

      <BoxWrapFilterTable>
        <Box display="flex" alignItems="center" gridGap={8}>
          <Typography className="font-weight-bold" variant="h5">
            {valueTab === 0 ? 'Dynamic Surveys' : 'Public Surveys'}
          </Typography>
        </Box>
        {valueTab === 0 && (
          <FilterComponent
            callbackReset={callbackReset}
            callbackClickFilter={callbackClickFilter}
            loading={loadingFormsData}
            {...filterComponentProps}
          />
        )}
      </BoxWrapFilterTable>

      {Object.is(valueTab, 0) && (
        <>
          <DefaultTable
            heads={[
              { text: 'Name', width: '30%' },
              { text: 'Status', width: '50%' },
              { text: 'Actions', width: '20%' },
            ]}
            loading={loadingFormsData}
            content={
              formsData?.getAllFormsData?.nodes?.map(row => {
                return {
                  key: row._id,
                  cells: [
                    <TypoThreeDotsLine variant="subtitle2">
                      {JSON.parse(row.formJson).title}
                    </TypoThreeDotsLine>,
                    <Typography variant="subtitle2">{row.status}</Typography>,
                    <RowDynamicSuvey
                      item={row}
                      callbackDelete={callbackDelete}
                    />,
                  ],
                };
              }) || []
            }
          />
          {formsData?.getAllFormsData?.nodes?.length !== 0 && (
            <DefaultPagination
              loading={loadingFormsData}
              {...defaultPaginationProps}
              count={formsData?.getAllFormsData?.meta?.total || 0}
            />
          )}
        </>
      )}

      {Object.is(valueTab, 1) && (
        <DefaultTable
          heads={[
            { text: 'Name', width: '30%' },
            { text: 'Created At', width: '50%' },
            { text: 'Actions', width: '20%' },
          ]}
          loading={loadingAnonymousSurveyResults}
          content={
            dataAnonymousSurveyResults?.getAnonymousSurveyResults?.map(row => {
              return {
                key: row._id,
                cells: [
                  <TypoThreeDotsLine variant="subtitle2">
                    {row?.surveyName}
                  </TypoThreeDotsLine>,
                  <Typography variant="subtitle2">
                    {formatDate(row.createdAt)}
                  </Typography>,
                  <Survey item={row} />,
                ],
              };
            }) || []
          }
        />
      )}
    </>
  );
};

export default React.memo(SurveyDashboard);
