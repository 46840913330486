import { useQuery } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import { QueryResult } from '@apollo/react-common';

export const useQueryCustom = <TypeQuery, TypeVariables>(arg: {
  skip?: boolean;
  displayName?: string;
  api: DocumentNode;
  variables?: TypeVariables;
  callbackSuccess?: (data: TypeQuery) => void;
  callbackError?: () => void;
  fetchPolicy?:
    | 'cache-first'
    | 'network-only'
    | 'cache-only'
    | 'no-cache'
    | 'standby'
    | 'cache-and-network';
}): QueryResult<TypeQuery, TypeVariables> => {
  const {
    api,
    variables,
    callbackSuccess,
    callbackError,
    fetchPolicy,
    displayName,
    skip,
  } = arg;
  let variablesAll = {
    displayName,
    variables,
    fetchPolicy: fetchPolicy || 'cache-and-network',
    onCompleted(data: any) {
      if (callbackSuccess) {
        callbackSuccess(data);
      }
    },
    onError() {
      if (callbackError) {
        callbackError();
      }
    },
    skip: !!skip,
  };
  if (displayName) {
    variablesAll = { displayName, ...variablesAll };
  }
  const dataQuery = useQuery<TypeQuery, TypeVariables>(api, {
    ...variablesAll,
  });
  return { ...dataQuery };
};
