import React, { useContext, useEffect, useRef } from 'react';
import AddTag from './AddTag';
import {
  CreatePatientTagMutation,
  CreatePatientTagMutationVariables,
  TagEmbeddedOnPatientFragment,
  GetPatientTagsByIdsQuery,
  GetPatientTagsByIdsQueryVariables,
  TagFragment,
  UnTagOnPatientMutation,
  UnTagOnPatientMutationVariables,
  UpdateTagTreatmentTreeEmbeddedFragment,
  UpdateTagForTreatmentTreeMutation,
  UpdateTagForTreatmentTreeMutationVariables,
} from 'types.d';
import {
  CREATE_PATIENT_TAG,
  GET_PATIENT_TAGS_BY_IDS,
  UN_TAG_ON_PATIENT,
  PatientFragment as PatientFragmentGql,
  UPDATE_TAG_FOR_TREATMENT_TREE,
} from 'gql';
import {
  CreateUpdateTreatmentContext,
  PatientDetailContext,
} from 'share/context';
import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import { useMutationCustom } from 'hooks';
import Tag from './Tag';
import { TagForImport } from 'modules/patients/interfaces';
import { ChipStyled } from 'share/component_css';
import LoadingItem from '../PatientTags/LoadingTags';
import { Typography } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { ScrollStyled } from 'components';
import { handleError } from 'share/utils';

type Props = {
  tags?: UpdateTagTreatmentTreeEmbeddedFragment[];
  setTags?: (value: UpdateTagTreatmentTreeEmbeddedFragment[]) => void;
  isFolder?: boolean;
  isTreatment?: boolean;
  idFolder?: string;
};

export const Tags: React.FC<Props> = ({ isFolder, isTreatment, idFolder }) => {
  const patientDetailContext = useContext(PatientDetailContext);

  const createUpdateTreatmentContext = useContext(CreateUpdateTreatmentContext);

  const tags = createUpdateTreatmentContext?.tags;

  const setTags = createUpdateTreatmentContext?.setTags;

  const idTagDeleted = useRef('');

  const client = useApolloClient();

  const { enqueueSnackbar } = useSnackbar();

  const [getPatientTags, { data: listPatientTag, loading }] = useLazyQuery<
    GetPatientTagsByIdsQuery,
    GetPatientTagsByIdsQueryVariables
  >(GET_PATIENT_TAGS_BY_IDS, {
    fetchPolicy: 'cache-and-network',
  });

  const [updateTag] = useMutation<
    UpdateTagForTreatmentTreeMutation,
    UpdateTagForTreatmentTreeMutationVariables
  >(UPDATE_TAG_FOR_TREATMENT_TREE, {
    onError(error) {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
  });

  const [createPatientTag, { loading: loadingCreatePatientTag }] = useMutation<
    CreatePatientTagMutation,
    CreatePatientTagMutationVariables
  >(CREATE_PATIENT_TAG, {});

  const [untagOnPatient] = useMutationCustom<
    UnTagOnPatientMutation,
    UnTagOnPatientMutationVariables
  >({
    api: UN_TAG_ON_PATIENT,
    textSuccess: 'Remove tag of this patient successfully',
    callbackSuccess: () => {
      client.writeFragment({
        id: `PatientRes:${patientDetailContext?.patient.owner?.idOwner}`,
        fragment: PatientFragmentGql,
        fragmentName: 'Patient',
        data: {
          ...patientDetailContext?.patient,
          owner: {
            ...patientDetailContext?.patient.owner,
            tags:
              [
                ...((patientDetailContext?.patient.owner?.tags ||
                  []) as TagEmbeddedOnPatientFragment[]).filter(
                  item => item?.tag._id !== idTagDeleted.current,
                ),
              ] || [],
          },
        },
      });
    },
  });

  useEffect(() => {
    if (
      patientDetailContext?.patient.owner?.tags &&
      patientDetailContext?.patient.owner?.tags.length !== 0
    ) {
      getPatientTags({
        variables: {
          params: {
            tagIds: patientDetailContext?.patient.owner?.tags?.map(
              item => item?.tag._id,
            ),
          },
        },
      });
    } else {
      getPatientTags({
        variables: {
          params: {
            tagIds: [],
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientTags, patientDetailContext?.patient.owner?.tags]);

  const removeTag = (tag: TagFragment | TagForImport) => {
    const tagDelete = tag as TagFragment;
    idTagDeleted.current = tagDelete._id;
    if (idFolder) {
      const tagRemove = tags?.find(item => item?.tag?._id === tagDelete._id);
      updateTag({
        variables: {
          params: {
            tagEmbedded: {
              tagId: tagDelete._id,
              name: tagDelete.name,
              isAssignTag: tagRemove?.isAssignTag,
            },
            treatmentNodeId: idFolder,
            isRemove: true,
          },
        },
      });
      enqueueSnackbar(`Remove tag successfully`, {
        variant: 'success',
      });
    }
    if (isFolder && setTags && tags) {
      setTags(tags?.filter(item => item?.tag?._id !== tagDelete?._id));
      return;
    }
    untagOnPatient({
      variables: {
        params: {
          tagId: tagDelete._id,
          tagName: tagDelete.name,
          patientId: patientDetailContext?.patient.owner?.idOwner,
        },
      },
    });
  };

  const createTagWhenUpdate = (tag: TagFragment) => {
    updateTag({
      variables: {
        params: {
          tagEmbedded: {
            tagId: tag?._id,
            name: tag?.name,
            isAssignTag: false,
          },
          treatmentNodeId: idFolder,
          isAddOne: true,
        },
      },
    });
  };

  const updateTagColor = (
    infoTag: { tagName: string; color: string },
    callback: Function,
  ) => {
    createPatientTag({
      variables: {
        params: {
          name: infoTag.tagName,
          patientId: patientDetailContext?.patient.owner?.idOwner,
          color: infoTag.color,
        },
      },
    }).then(() => {
      callback();
    });
  };

  const updateTagWhenUpdate = (tag: TagFragment) => {
    const tagSelected = tags?.find(item => item?.tag?._id === tag?._id);
    updateTag({
      variables: {
        params: {
          tagEmbedded: {
            tagId: tag._id,
            name: tag.name,
            isAssignTag: !tagSelected?.isAssignTag,
          },
          treatmentNodeId: idFolder,
          isUpdate: true,
        },
      },
    });
  };

  const renderTag = (item: TagFragment) => {
    return (
      !loading && (
        <Tag
          updateTagColor={updateTagColor}
          removeTag={removeTag}
          key={item._id}
          tag={item}
          loading={loadingCreatePatientTag}
          isInactive={patientDetailContext?.isInactive}
          isFolder={isFolder}
          updateTagWhenUpdate={updateTagWhenUpdate}
        />
      )
    );
  };

  return (
    <>
      {!patientDetailContext?.isInactive && !isTreatment && (
        <AddTag idFolder={idFolder} createTagWhenUpdate={createTagWhenUpdate} />
      )}
      {loading && <LoadingItem />}
      {!loading &&
        !isFolder &&
        listPatientTag?.getPatientTagsByIds.length === 0 &&
        !isTreatment && (
          <ChipStyled
            colortext="true"
            avatar={<BlockIcon />}
            label={<Typography variant="subtitle2">No Tag</Typography>}
          />
        )}
      {tags ? (
        tags
          ?.map(item => item?.tag)
          ?.map(item => {
            return renderTag(item);
          })
      ) : listPatientTag?.getPatientTagsByIds &&
        listPatientTag?.getPatientTagsByIds?.length > 0 ? (
        <ScrollStyled
          loading={loading}
          listLength={listPatientTag?.getPatientTagsByIds.length}
        >
          {listPatientTag?.getPatientTagsByIds?.map(item => renderTag(item))}
        </ScrollStyled>
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(Tags);
