import gql from 'graphql-tag';
import { Phone } from 'hooks';
import { ConfigEmbedded, Timezone } from 'types.d';
type SecondaryEmail = {
  email: string;
  isVerified: boolean;
};

export type SecondaryEmails = SecondaryEmail[];

export type Phones = Phone[];

export type Setting = ConfigEmbedded | null;

export type TimezoneSetting = Timezone | null;

export const UPDATE_ME_CLIENT = gql`
  mutation UpdateMeClient(
    $email: String
    $firstName: String
    $middleName: String
    $lastName: String
    $role: String
    $_id: ID
    $organization: String
    $isRegister: Boolean
    $patient: String
    $hasDefaultTreatment: Boolean
    $organizationId: String
    $secondaryEmails: SecondaryEmails
    $setting: Setting
    $timezone: TimezoneSetting
    $phones: Phones
  ) {
    updateMeClient(
      email: $email
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      role: $role
      _id: $_id
      organization: $organization
      isRegister: $isRegister
      patient: $patient
      hasDefaultTreatment: $hasDefaultTreatment
      organizationId: $organizationId
      secondaryEmails: $secondaryEmails
      setting: $setting
      timezone: $timezone
      phones: $phones
    ) @client
  }
`;
export const ME_CLIENT = gql`
  {
    meClient @client {
      email
      firstName
      lastName
      middleName
      role
      _id
      organization
      isRegister
      patient
      hasDefaultTreatment
      organizationId
      secondaryEmails
      setting
      phones
      timezone
    }
  }
`;
