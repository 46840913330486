import gql from 'graphql-tag';
import { MetaFragment, UploadedPDFFragment } from './fragments';

export const GET_ALL_CONVERTED_PDFS = gql`
  query GetAllConvertedPdfs($params: GetPdfsInput!) {
    getAllConvertedPdfs(params: $params) {
      nodes {
        ...UploadedPDF
      }
      meta {
        ...Meta
      }
    }
  }
  ${UploadedPDFFragment}
  ${MetaFragment}
`;

export const CONVERT_PDF_TO_MARKDOWN = gql`
  mutation ConvertPdfToMarkdown($file: Upload!, $params: UploadedPDFInput!) {
    convertPdfToMarkdown(file: $file, params: $params) {
      ...UploadedPDF
    }
  }
  ${UploadedPDFFragment}
`;

export const UPDATE_PAGE_CONTENT = gql`
  mutation UpdatePageContent($params: UpdatePdfInput!) {
    updatePageContent(params: $params) {
      ...UploadedPDF
    }
  }
  ${UploadedPDFFragment}
`;

export const GET_CONVERTED_PDF_BY_ID = gql`
  query GetConvertedPdfbyId($params: GetPdfByIDInput!) {
    getConvertedPdfbyId(params: $params) {
      ...UploadedPDF
    }
  }
  ${UploadedPDFFragment}
`;

export const DELETE_DYNAMIC_FORM_DATA = gql`
  mutation DeleteDynamicFormData($params: FormOperationById!) {
    deleteDynamicFormData(params: $params)
  }
`;
