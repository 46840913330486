import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useContext,
} from 'react';
import { Grid } from '@material-ui/core';
import { PatientFragment, UserStatus } from 'types.d';
import PatientInfo from './PatientInfo';
import { PatientContext, PatientDetailContext } from 'share/context';
import PatientTags from '../PatientTags';
import { useToogleDialog } from 'hooks';
import GroupActions from './GroupActions';
import TabsTable from '../TabsTable';
import { BoxCheckedStyled, CheckIconStyled, PaperStyled } from '../../styles';

type Props = {
  item: PatientFragment;
  index: number;
  isOnDialog?: boolean;
  ignoredItems?: string[];
  callbackDelete?: () => void;
  checkItem?: (
    event: React.ChangeEvent<HTMLInputElement> | boolean,
    value: string,
    data?: any,
  ) => void;
  checked?: boolean;
  collapseCard?: boolean;
  refetchQueriesGetPatient?: any;
};

export const PatientDetail: React.FC<Props> = ({
  item,
  isOnDialog,
  index,
  ignoredItems,
  callbackDelete,
  checked,
  checkItem,
  collapseCard,
  refetchQueriesGetPatient,
}) => {
  const [isExtend, setIsExtend] = useState(!!isOnDialog);

  const [isRefreshingChecklist, setIsRefreshChecklist] = useToogleDialog();

  const patientConText = useContext(PatientContext);

  const [dialogOpen, setDialogOpen] = useState('');

  const dataForOpenDialog = useRef<any | null>(null);

  const [indexTab, setIndexTab] = useState(0);

  const [currentDate, setCurrentDate] = useState<string | null>(null);

  const handleSetIndexTab = useCallback((index: number) => {
    setIndexTab(index);
  }, []);

  const toogleExpand = useCallback((status: boolean) => {
    setIsExtend(status);
  }, []);

  const handleSetDialogOpen = useCallback((nameDialog: string, data?: any) => {
    dataForOpenDialog.current = data;
    setDialogOpen(nameDialog);
  }, []);

  const enableTab = useCallback((index: number) => {
    setIsExtend(true);
    setIndexTab(index);
  }, []);

  const handleCollapse = useCallback(
    (isExpand: boolean) => {
      setIndexTab(0);
      toogleExpand(isExpand);
    },
    [toogleExpand],
  );

  const isInactive = useMemo(() => {
    return item?.owner?.user?.status === UserStatus.Inactive;
  }, [item]);

  const changeChecked = () => {
    checkItem && checkItem(!checked, item._id, item);
  };
  return (
    <PatientDetailContext.Provider
      value={{
        patient: item,
        isAnonymous: !item.owner,
        indexTab,
        enableTab,
        handleSetDialogOpen,
        dialogOpen,
        isExtend,
        dataForOpenDialog: dataForOpenDialog.current,
        isRefreshingChecklist,
        setIsRefreshChecklist,
        isInactive,
        refetchQueriesGetPatient,
        currentDate,
        setCurrentDate,
      }}
    >
      <PaperStyled
        className={`position-relative ${
          index === 0 ? '' : 'mt-16'
        } mb-16 p-16 ${checked ? 'bg-main' : 'bg-white'}`}
      >
        {patientConText?.dialogConfirmStop()}
        {!isInactive && !isOnDialog && (
          <>
            <BoxCheckedStyled
              onClick={changeChecked}
              width="0"
              height="0"
              position="absolute"
              top={0}
              left={0}
              check={checked ? 'true' : 'false'}
            />
            <CheckIconStyled onClick={changeChecked} />
          </>
        )}
        <GroupActions
          callbackDelete={callbackDelete}
          patient={item}
          isExtend={isExtend}
          isCollaspeCard={!collapseCard}
          handleCollapse={handleCollapse}
          isOnDialog={isOnDialog}
        />
        <Grid container spacing={2}>
          <PatientInfo isCollapse={!collapseCard} />
          {!collapseCard && (
            <>
              <PatientTags
                indexTab={indexTab}
                isExtend={isExtend}
                handleSetDialogOpen={handleSetDialogOpen}
                patient={item}
                ignoredItems={ignoredItems}
                isInactive={isInactive}
              />
              {isExtend && (
                <>
                  <TabsTable
                    isOnDialog={isOnDialog}
                    patient={item}
                    indexTab={indexTab}
                    handleCollapse={handleCollapse}
                    setIndexTab={handleSetIndexTab}
                  />
                </>
              )}
            </>
          )}
        </Grid>
      </PaperStyled>
    </PatientDetailContext.Provider>
  );
};

export default React.memo(PatientDetail);
