import React from 'react';
import { Box, Checkbox } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

type Props = {
  checked: boolean;
  option: React.ReactNode;
};
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const CheckboxList: React.FC<Props> = ({ checked, option }) => {
  return (
    <Box display="flex" alignItems="center">
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        className="mr-8"
        checked={checked}
      />
      {option}
    </Box>
  );
};
export default CheckboxList;
