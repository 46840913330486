import React from 'react';
import { Route } from 'react-router-dom';
import { TConfigRouter } from './interfaces';
import { ErrorPage } from 'components';
import { useCheckRole } from 'hooks';
export const PubicRoute: React.FC<TConfigRouter & { dataMeClient: any }> = ({
  dataMeClient,
  component: Component,
  layout: Layout,
  keyPermission,
  accessNoLogin,
  ...rest
}) => {
  const { checkRole } = useCheckRole(keyPermission!);
  const check = keyPermission?.includes("My") ||
    keyPermission?.includes("All") ||
    keyPermission?.includes("Fav") ||
    keyPermission?.includes("Pub") ||
    keyPermission?.includes("Priv")
  return (
    <Route
      {...rest}
      render={() => (
        <Layout>{
          (check
          )
            ?
            <Component />
            :
            checkRole ? <Component /> : <ErrorPage error="401" />}</Layout>
      )}
    />
  );
};
export default PubicRoute;
