import React, { useContext, useMemo } from 'react';
import { Grid, Box, Typography, Chip, IconButton } from '@material-ui/core';
import { ContactMethod, PatientOwnerFragment } from 'types.d';
import { formatDoB, formatPhoneNumber, getDataOwner } from 'share/utils';
import DialogUpdateInfo from '../Dialogs/DialogUpdateInfo';
import { PatientContext, PatientDetailContext } from 'share/context';
import PhoneOfficeIcon from '../icons/PhoneOfficeIcon';
import MobileIcon from '../icons/MobileIcon';
import TelephoneIcon from '../icons/TelephoneIcon';
import { useToogleDialog } from 'hooks';
import { TypographyBold, TypoThreeDot } from 'share/component_css';
import { Link, Tooltip } from '@material-ui/core';
import { TitleDesPatientStyled } from '../../styles';
import EmailIcon from '@material-ui/icons/Email';
import AssignTreatment from '../Dialogs/DialogAssignTreatment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Tags from '../Tags/Tags';
import { LIST_GENDER } from 'CONST';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DialogSendSurvey from '../Survey/DialogSendSurvey';
import { isBotMail } from 'share/utils';
import { customEvent } from 'modules/google_tag_manager';
type Props = {
  exportPdf?: boolean;
  isCollapse?: boolean;
};

export const PatientInfo: React.FC<Props> = ({ exportPdf, isCollapse }) => {
  const [isOpen, toogleDialog] = useToogleDialog();

  const [
    isOpenAssignTreatment,
    toogleDialogAssignTreatment,
  ] = useToogleDialog();

  const [isOpenSendSMS, toogleDialogSendSMS] = useToogleDialog();

  const patientDetailContext = useContext(PatientDetailContext);

  const patientContext = useContext(PatientContext);

  const isHide = patientContext?.hidePatientInfo;

  const patientDetail = useMemo(() => {
    return patientDetailContext?.patient;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetailContext?.patient]);

  const isInactive = useMemo(() => {
    return patientDetailContext?.isInactive;
  }, [patientDetailContext]);

  const contactConfig =
    patientDetail?.owner?.patientConfig?.contactConfig?.method;

  const renderIcon = (typePhone: string, isHightLight: boolean) => {
    switch (typePhone) {
      case 'home':
        return <TelephoneIcon isHightLight={isHightLight} />;
      case 'work':
        return <PhoneOfficeIcon isHightLight={isHightLight} />;
      case 'mobile':
        return <MobileIcon isHightLight={isHightLight} />;
      default:
        return <TelephoneIcon />;
    }
  };

  return (
    <>
      {isOpen && (
        <DialogUpdateInfo
          open={isOpen}
          infoPatient={patientDetail}
          toogleDialog={toogleDialog}
          idPatient={patientDetail?._id}
        />
      )}

      {isOpenAssignTreatment && (
        <AssignTreatment
          patientDetail={patientDetail}
          open={isOpenAssignTreatment}
          toogleDialog={toogleDialogAssignTreatment}
        />
      )}

      {isOpenSendSMS && (
        <DialogSendSurvey
          open={isOpenSendSMS}
          toogleDialog={toogleDialogSendSMS}
        />
      )}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} className="d-flex items-center">
            <TypographyBold
              color="textSecondary"
              className="mr-8"
              variant="subtitle2"
            >
              Name
            </TypographyBold>
            <TypoThreeDot
              variant="subtitle2"
              className={`${isHide ? 'blurred' : ''}`}
            >
              {patientDetail?.owner
                ? `${patientDetail.owner?.firstName || '--'} ${patientDetail
                  .owner?.middleName || ''} ${patientDetail.owner?.lastName ||
                  '--'}`
                : 'Anonymous'}
            </TypoThreeDot>
            {isInactive && (
              <Chip className="ml-8" label="Inactive" color="default" />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={2} className="d-flex items-center">
            <TypographyBold
              color="textSecondary"
              className="mr-8 d-inline"
              variant="subtitle2"
            >
              MRN
            </TypographyBold>
            <TypoThreeDot
              variant="subtitle2"
              className={`flexgrow-1 ${isHide ? 'blurred' : ''}`}
            >
              {getDataOwner('mrn', patientDetail?.owner)}
            </TypoThreeDot>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="d-flex items-center">
            <TypographyBold
              color="textSecondary"
              className="mr-6"
              variant="subtitle2"
            >
              Email
            </TypographyBold>
            <TypoThreeDot
              variant="subtitle2"
              style={{ maxWidth: '75%' }}
              className={
                isHide
                  ? 'blurred'
                  : contactConfig?.includes(ContactMethod.Email)
                    ? 'bg-main p-4 radius-6'
                    : ''
              }
              color={
                contactConfig?.includes(ContactMethod.Email)
                  ? 'primary'
                  : 'initial'
              }
            >
              {isBotMail(patientDetail?.owner?.email)
                ? 'N/A'
                : getDataOwner('email', patientDetail?.owner)}
            </TypoThreeDot>

            {!exportPdf &&
              !Object.is(
                getDataOwner('email', patientDetail?.owner),
                'N/A',
              ) && (
                <>
                  {!isBotMail(patientDetail?.owner?.email) && !isInactive && (
                    <>
                      <Tooltip title="Send mail" arrow>
                        <Link
                          aria-label='send email'
                          onClick={() => customEvent("sendEmailToPatientOnPD", "NAVIGATOR")}
                          href={`mailto:${getDataOwner(
                            'email',
                            patientDetail?.owner,
                          )}`}
                          style={{ marginLeft: 6, height: 20 }}
                        >
                          <EmailIcon fontSize="small" color="primary" />
                        </Link>
                      </Tooltip>
                    </>
                  )}

                  {!isInactive && (
                    <>
                      <Tooltip title="Assign treatments" arrow>
                        <AssignmentIcon
                          onClick={() => toogleDialogAssignTreatment(true)}
                          className="cursor-pointer"
                          color="primary"
                          fontSize="small"
                          style={{ marginLeft: 6 }}
                        />
                      </Tooltip>
                      <Tooltip arrow title="Send">
                        <IconButton
                          onClick={() => toogleDialogSendSMS(true)}
                          color="primary"
                        >
                          <AssignmentTurnedInIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </>
              )}
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="d-flex items-center">
            <TypographyBold
              color="textSecondary"
              className="mr-8 d-inline"
              variant="subtitle2"
            >
              Address
            </TypographyBold>
            <Tooltip
              title={getDataOwner('address', patientDetail?.owner)}
              placement="bottom-start"
            >
              <TypoThreeDot
                variant="subtitle2"
                className={`flexgrow-1 ${isHide ? 'blurred' : ''}`}
              >
                {getDataOwner('address', patientDetail?.owner)}
              </TypoThreeDot>
            </Tooltip>
          </Grid>

          <Grid item xs={12} sm={6} md={3} className="d-flex items-center">
            <TypographyBold
              color="textSecondary"
              className="mr-8 d-inline"
              variant="subtitle2"
            >
              Organization
            </TypographyBold>
            <TypoThreeDot
              variant="subtitle2"
              className={`flexgrow-1 ${isHide ? 'blurred' : ''}`}
            >
              {patientDetail?.owner?.organization?.name || 'N/A'}
            </TypoThreeDot>
          </Grid>
          <Grid item xs={12} sm={6} md={2} className="d-flex items-center">
            <TypographyBold
              color="textSecondary"
              className="mr-8 d-inline"
              variant="subtitle2"
            >
              Gender
            </TypographyBold>
            <TypoThreeDot
              variant="subtitle2"
              className={`flexgrow-1 ${isHide ? 'blurred' : ''}`}
            >
              {patientDetail?.owner?.gender
                ? LIST_GENDER[patientDetail?.owner?.gender]
                : 'N/A'}
            </TypoThreeDot>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="d-flex items-center">
            <TypographyBold
              color="textSecondary"
              className="mr-8 d-inline"
              variant="subtitle2"
            >
              Provider
            </TypographyBold>
            <TypoThreeDot
              variant="subtitle2"
              className={`flexgrow-1 ${isHide ? 'blurred' : ''}`}
            >
              {patientDetail?.owner?.provider
                ? patientDetail?.owner?.provider[0].provider
                : 'N/A'}
            </TypoThreeDot>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="d-flex items-center">
            <TypographyBold
              color="textSecondary"
              className="mr-8 d-inline"
              variant="subtitle2"
            >
              DoB
            </TypographyBold>
            <Typography
              variant="subtitle2"
              className={`flexgrow-1 ${isHide ? 'blurred' : ''}`}
            >
              {Object.is(getDataOwner('dob', patientDetail?.owner), 'N/A')
                ? getDataOwner('dob', patientDetail?.owner)
                : formatDoB(getDataOwner('dob', patientDetail?.owner))}
            </Typography>
          </Grid>
          {isCollapse && (
            <>
              <Grid item xs={12}>
                <Box display="flex" flexWrap="wrap" alignItems="center">
                  <TypographyBold
                    color="textSecondary"
                    className="mr-8 d-inline"
                    variant="subtitle2"
                  >
                    Phones
                  </TypographyBold>
                  {patientDetail?.owner?.phones?.map((phone, index) => {
                    return (
                      <Box
                        display="flex"
                        alignItems="center"
                        flexWrap="wrap"
                        mx={1}
                        className={
                          contactConfig?.includes(ContactMethod.Sms) &&
                            phone?.default
                            ? 'bg-main p-4 radius-6'
                            : ''
                        }
                        key={index}
                      >
                        {phone?.phone ? (
                          <>
                            <Link
                              aria-label=''
                              href={`tel:${phone?.phone}`}
                              style={{ marginRight: '5px' }}
                            >
                              {renderIcon(
                                phone?.typePhone,
                                phone?.default &&
                                (contactConfig?.includes(
                                  ContactMethod.Sms,
                                ) as boolean),
                              )}
                            </Link>
                            <Typography
                              variant="subtitle2"
                              className={isHide ? 'blurred' : ''}
                              color={
                                contactConfig?.includes(ContactMethod.Sms) &&
                                  phone?.default
                                  ? 'primary'
                                  : 'initial'
                              }
                            >
                              {`${formatPhoneNumber(phone?.phone)} ${phone?.ext ? ` - ${phone?.ext}` : ''
                                }`}
                            </Typography>
                          </>
                        ) : (
                          <></>
                        )}
                      </Box>
                    );
                  })}
                  {(!patientDetail?.owner?.phones ||
                    (patientDetail?.owner?.phones &&
                      !patientDetail?.owner?.phones[0]?.phone)) && (
                      <Typography
                        variant="subtitle2"
                        className={isHide ? 'blurred' : ''}
                      >
                        N/A
                      </Typography>
                    )}
                </Box>
              </Grid>
              {!exportPdf && !patientDetailContext?.isAnonymous && (
                <Grid item xs={12}>
                  <Box display="flex" flexWrap="nowrap" alignItems="center">
                    <TitleDesPatientStyled
                      color="textSecondary"
                      variant="subtitle2"
                    >
                      Tags
                    </TitleDesPatientStyled>
                    <Tags />
                  </Box>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(PatientInfo);
