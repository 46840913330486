/* eslint-disable no-loop-func */
import React, { useContext, useState } from 'react';
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import * as Survey from 'survey-react';
import { useMutationCustom } from 'hooks';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  GetDetailSurveyDataQuery,
  GetDetailSurveyDataQueryVariables,
  UpdateSurveyResultMutation,
  UpdateSurveyResultMutationVariables,
  SurveyResultStatus,
} from 'types.d';
import { GET_DETAIL_SURVEY_DATA, UPDATE_SURVEY_RESULT } from 'gql';
import { useApolloClient } from '@apollo/react-hooks';
import { DataEditSurveyResultInterface } from '../interfaces';
import { ButtonLoading, DialogButton, DialogTitleClose } from 'components';
import Skeleton from '@material-ui/lab/Skeleton';
import { getAllQuestion, getStatusByAnswer } from 'share/utils';
import EditIcon from '@material-ui/icons/Edit';
import gql from 'graphql-tag';
import SurveyDisplayByType from './SurveyDisplayByType';
import { PatientDetailContext } from 'share/context';

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
  idSurvey?: string;
  detailSurveyResult?: DataEditSurveyResultInterface;
  surveyName?: string | null;
};

export const DialogCustomQuestion: React.FC<Props> = ({
  open,
  toogleDialog,
  idSurvey,
  detailSurveyResult,
  surveyName,
}) => {
  const [survey, setSurvey] = useState<Survey.ReactSurveyModel | undefined>(
    undefined,
  );

  const client = useApolloClient();

  const patientDetailContext = useContext(PatientDetailContext);

  const [updateSurvey, { loading: loadingUpdateSurvey }] = useMutationCustom<
    UpdateSurveyResultMutation,
    UpdateSurveyResultMutationVariables
  >({
    api: UPDATE_SURVEY_RESULT,
    textSuccess: 'Update survey result sucessfully',
    callbackSuccess: data => {
      toogleDialog(false);
      try {
        //update cache client for edit some questions on survey response tab
        client.writeFragment({
          id: `PatientDataRes:${detailSurveyResult?.response._id}`,
          fragment: gql`
            fragment PatientDataRes on PatientDataRes {
              result
            }
          `,
          data: {
            result: {
              ...detailSurveyResult?.response.result,
              ...survey?.data,
            },
            __typename: 'PatientDataRes',
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
  });

  const [getSurveyData, { loading: loadSurveyData }] = useLazyQuery<
    GetDetailSurveyDataQuery,
    GetDetailSurveyDataQueryVariables
  >(GET_DETAIL_SURVEY_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      //detailSurveyResult.result
      const surveyData = detailSurveyResult?.response.result;
      const survey = new Survey.Model(
        JSON.stringify({
          pages: [
            {
              name: 'page1',
              elements: [
                getAllQuestion(data.getTreatment?.surveyData as string).find(
                  item => item.name === detailSurveyResult?.question,
                ),
              ],
            },
          ],
        }),
      );
      survey.data = surveyData;
      survey.showNavigationButtons = false;
      survey.showCompletedPage = false;
      setSurvey(survey);
    },
  });

  const onEnter = () => {
    getSurveyData({ variables: { params: { _id: idSurvey as string } } });
  };

  const handleUpdateSurvey = () => {
    if (survey && survey.completeLastPage()) {
      updateSurvey({
        variables: {
          params: {
            surveyResultId: detailSurveyResult?.response._id,
            data: { ...detailSurveyResult?.response.result, ...survey.data },
            completedStatus: getStatusByAnswer(survey),
            workingStatus: SurveyResultStatus.Review,
            patientId: patientDetailContext?.patient?._id,
          },
        },
      });
    }
  };

  return (
    <>
      <Dialog onEnter={onEnter} open={open} maxWidth="md" fullWidth>
        <DialogTitleClose
          title={`Update Survey Result`}
          onClose={() => toogleDialog(false)}
        />
        <DialogContent>
          <SurveyDisplayByType
            survey={survey}
            surveyName={surveyName}
            detailSurveyResult={detailSurveyResult}
            version={detailSurveyResult?.version}
          />
          {loadSurveyData && (
            <Skeleton
              className="mt-16"
              variant="rect"
              width="100%"
              height={300}
            />
          )}
        </DialogContent>
        <DialogActions>
          <DialogButton isCancel onClickButton={() => toogleDialog(false)} />
          <ButtonLoading
            callbackClick={handleUpdateSurvey}
            Icon={<EditIcon />}
            text={'Update Survey Result'}
            loading={loadingUpdateSurvey}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(DialogCustomQuestion);
