import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
type Props = {
  loading: boolean;
};

const BackdropStyled = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));

export const BackdropComponent: React.FC<Props> = ({ loading }) => {
  return (
    <BackdropStyled open={loading}>
      <CircularProgress color="inherit" />
    </BackdropStyled>
  );
};
export default BackdropComponent;
