import React, { useState } from 'react';
import { TableSortLabel, TableCell } from '@material-ui/core';
import { OrderBy } from 'types.d';

const objTypeSort: { [key: string]: string } = {
  [OrderBy.Asc]: 'asc',
  [OrderBy.Desc]: 'desc',
};

type Direction = 'desc' | 'asc';

type SortTableProps = {
  typeSort: OrderBy;
  setTypeSort: (type: OrderBy) => void;
};

type ExtendSortTableProps = SortTableProps & {
  name: string;
  callbackSort: (type: OrderBy) => void;
};

export const useSortTable = () => {
  const [typeSort, setTypeSort] = useState<OrderBy>(OrderBy.Desc);

  const sortTableComponentProps = {
    typeSort,
    setTypeSort,
  };

  return {
    sortTableComponentProps,
    SortTableComponent,
    typeSort,
  };
};

export const SortTableComponent: React.FC<ExtendSortTableProps> = ({
  typeSort,
  setTypeSort,
  name,
  callbackSort,
}) => {
  const handleSort = () => {
    const sort = typeSort === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc;
    setTypeSort(sort);
    callbackSort(sort);
  };
  return (
    <TableCell>
      <TableSortLabel
        active
        direction={objTypeSort[typeSort as string] as Direction}
        onClick={handleSort}
      >
        {name}
      </TableSortLabel>
    </TableCell>
  );
};
