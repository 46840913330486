import { Grid, MenuItem, TextField } from '@material-ui/core';
import { AutoCompleteSelect, TextfieldSelect } from 'components';
import { ASSIGN_TREATMENT_MESSAGE, SEND_SURVEY_MESSAGE } from 'CONST';
import React, { useEffect, useState } from 'react';
import { InputTypeMentions } from 'components/InputTypeMentions';
import { CommonTreatmentFragment } from 'types.d';

type InputMentions = {
  valueCustom: string;
  setValueCustom: (value: string) => void;
  messageType: string;
  setMessageType: (value: string) => void;
  optionTreatment?: CommonTreatmentFragment[] | null;
  treatmentSelected: string | undefined;
  setTreatmentSelected: (treatment: string | undefined) => void;
  isUpdateSchedule?: boolean;
};

export const useInputMentions = (
  actionType: boolean,
  favoriteValue?: string,
  optionTreatment?: CommonTreatmentFragment[] | null,
  isUpdateSchedule?: boolean,
): {
  valueCustom: string;
  defaulInputMentionsProps: InputMentions;
  InputMentions: any;
  setValueCustom: (value: string) => void;
} => {
  const [valueCustom, setValueCustom] = useState('');

  const [messageType, setMessageType] = useState(
    isUpdateSchedule ? 'custom' : 'favorite',
  );

  const [treatmentSelected, setTreatmentSelected] = useState<
    string | undefined
  >(undefined);
  useEffect(() => {
    if (optionTreatment?.length === 0 && messageType === 'favorite') {
      setValueCustom('');
      return;
    }
    if (
      optionTreatment &&
      optionTreatment?.length === 1 &&
      messageType === 'favorite'
    ) {
      setValueCustom(optionTreatment[0]?.smsMessage || '');
      return;
    }
    if (messageType === 'custom' && !isUpdateSchedule) {
      setValueCustom('');
      return;
    }
    if (messageType === 'default') {
      if (actionType) {
        setValueCustom(ASSIGN_TREATMENT_MESSAGE);
        return;
      }
      setValueCustom(SEND_SURVEY_MESSAGE);
      return;
    }
    if (messageType === 'favorite') {
      if (treatmentSelected) {
        setValueCustom(
          optionTreatment?.find(item => item._id === treatmentSelected)
            ?.smsMessage || '',
        );
        return;
      }
      if (favoriteValue) {
        setValueCustom(favoriteValue);
        return;
      }
      setValueCustom('');
      return;
    }
  }, [
    actionType,
    favoriteValue,
    isUpdateSchedule,
    messageType,
    optionTreatment,
    treatmentSelected,
  ]);

  const defaulInputMentionsProps = {
    valueCustom,
    setValueCustom,
    messageType,
    setMessageType,
    optionTreatment,
    treatmentSelected,
    setTreatmentSelected,
    isUpdateSchedule,
  };

  return {
    valueCustom,
    defaulInputMentionsProps,
    InputMentions,
    setValueCustom,
  };
};

export const InputMentions: React.FC<InputMentions> = ({
  valueCustom,
  setValueCustom,
  messageType,
  setMessageType,
  optionTreatment,
  treatmentSelected,
  setTreatmentSelected,
  isUpdateSchedule,
}) => {
  return (
    <>
      <Grid item xs={3}>
        <TextfieldSelect
          label="Message Type"
          name="type"
          small
          callbackChangeValue={e => setMessageType(e.target.value)}
          value={messageType}
          disabled={isUpdateSchedule}
        >
          <MenuItem value="custom">Custom</MenuItem>
          <MenuItem value="default">Default</MenuItem>
          <MenuItem value="favorite">Favorite</MenuItem>
        </TextfieldSelect>
      </Grid>
      {optionTreatment &&
        messageType === 'favorite' &&
        optionTreatment.length > 1 && (
          <Grid item xs={5}>
            <AutoCompleteSelect
              small
              disablePortal
              name="messageFrom"
              label="Message From"
              nameOption="name"
              defaultValue={treatmentSelected}
              options={optionTreatment || []}
              callbackChangeValue={(event, newValue) => {
                setTreatmentSelected(newValue?._id);
              }}
            />
          </Grid>
        )}

      <Grid item xs={12}>
        {messageType === 'custom' ? (
          <InputTypeMentions
            valueCustom={valueCustom}
            setValueCustom={setValueCustom}
            disable={isUpdateSchedule}
          />
        ) : (
          <TextField
            inputProps={{ maxLength: 255 }}
            disabled={!(messageType === 'custom')}
            multiline
            label="SMS Message"
            size="small"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
            rows={5}
            value={valueCustom}
            onChange={e => setValueCustom(e.target.value)}
          />
        )}
      </Grid>
    </>
  );
};
