import { useEffect, useState } from 'react';

export const useZoomDetection = () => {
  const [zoomLevel, setZoomLevel] = useState(window.devicePixelRatio * 100);

  useEffect(() => {
    const handleResize = () => {
      setZoomLevel(window.devicePixelRatio * 100);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return zoomLevel;
};
