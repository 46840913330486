import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Tooltip } from '@material-ui/core';

type Props = {
  props?: SvgIconProps;
  isResend: boolean;
  fontSize?: 'inherit' | 'default' | 'small' | 'large';
};

export const ResendIcon: React.FC<Props> = ({ props, isResend, fontSize }) => {
  return (
    <Tooltip arrow title={isResend ? 'Resend email' : 'Remind'}>
      <SvgIcon
        color="primary"
        viewBox="0 0 612 512"
        fontSize={fontSize || 'default'}
        {...props}
      >
        <g>
          <path
            d="M534 525 c4 -13 -8 -15 -74 -15 -58 0 -83 -4 -94 -16 -21 -20 -21
-64 -1 -64 9 0 15 9 15 25 0 25 1 25 86 25 71 0 84 -2 75 -13 -19 -24 2 -28
26 -5 28 26 29 40 5 61 -23 21 -46 22 -38 2z"
          />
          <path
            d="M570 401 c0 -19 -6 -34 -15 -37 -22 -9 -18 -24 5 -24 23 0 43 32 38
62 -5 35 -28 34 -28 -1z"
          />
          <path
            d="M172 310 c57 -49 105 -90 108 -90 3 0 36 26 74 57 62 52 66 58 45 61
-32 5 -49 20 -49 43 0 18 -10 19 -140 19 l-141 0 103 -90z"
          />
          <path
            d="M382 383 c3 -15 14 -18 68 -18 51 0 65 3 65 15 0 12 -16 16 -68 18
-62 3 -68 1 -65 -15z"
          />
          <path
            d="M40 250 c0 -72 3 -130 8 -130 9 0 147 121 147 130 0 8 -140 130 -149
130 -3 0 -6 -58 -6 -130z"
          />
          <path
            d="M415 295 l-50 -44 70 -65 c39 -36 73 -65 78 -66 4 0 7 50 7 110 l0
110 -28 0 c-19 0 -44 -14 -77 -45z"
          />
          <path
            d="M142 164 l-73 -64 213 0 c177 0 210 2 198 13 -8 8 -43 37 -77 66
l-62 53 -30 -23 -31 -23 -30 22 c-16 12 -30 22 -32 21 -2 0 -36 -29 -76 -65z"
          />
        </g>
      </SvgIcon>
    </Tooltip>
  );
};
export default ResendIcon;
