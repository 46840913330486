import React, { useEffect, useState } from 'react';
import {
    Box, Dialog, DialogContent, Typography
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { DialogTitleClose } from 'components';
import { SliderStyled } from 'share/component_css';
import VoicesComponent from 'routers/components/VoicesComponent';
import { getLocalStorage, setDataLocalStorage } from 'share/utils';
import { DEFAULT_VOICE_SETTINGS } from 'CONST';

type Props = {
    voiceSettings: any,
    setVoiceSettings: Function
    open: boolean,
    setOpen: Function,
    setPlayed: Function
};

const BoxStyled = styled(Box)(({ theme }) => ({
    padding: '5px',
    background: 'white',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align items to the start (left) of the container
    '& > *:first-child': {
        minWidth: '100%',
        alignSelf: 'center', // First child takes up remaining space
    },
}));

export const SettingsModal: React.FC<Props> = (props: Props) => {

    const voices = window.speechSynthesis.getVoices();

    const [rate, setRate] = useState(props.voiceSettings.rate);

    const [pitch, setPitch] = useState(props.voiceSettings.pitch);

    const [vol, setVolume] = useState(props.voiceSettings.vol);

    const [voice, setVoice] = useState<any>(voices[props.voiceSettings.voice].name);

    var default_voice_settings = JSON.parse(getLocalStorage(DEFAULT_VOICE_SETTINGS)!)

    const marks = [
        {
            value: 0,
        },
        {
            value: 0.5,
        },
        {
            value: 1,
        },
        {
            value: 1.5,
        },
        {
            value: 2,
        },
    ];

    useEffect(() => {
        const fetchVoices = () => {
            const voices = window.speechSynthesis.getVoices();
            setVoice(voices[props.voiceSettings.voice].name);
        };
        // Ensure voices are loaded before setting the default voice
        if (window.speechSynthesis.onvoiceschanged !== undefined) {
            window.speechSynthesis.onvoiceschanged = fetchVoices;
        } else {
            fetchVoices();
        }
    }, []);

    function saveRate(event: any, rate: any) {
        props.voiceSettings.rate = rate
        setRate(rate);
        props.setPlayed(undefined)
        props.setVoiceSettings(props.voiceSettings);
        if (default_voice_settings.rate !== rate) {
            default_voice_settings.rate = rate;
            setDataLocalStorage(DEFAULT_VOICE_SETTINGS, JSON.stringify(default_voice_settings))
        }
    }


    function savePitch(event: any, pitch: any) {
        props.voiceSettings.pitch = pitch
        setPitch(pitch)
        props.setPlayed(undefined)
        props.setVoiceSettings(props.voiceSettings)
        if (default_voice_settings.pitch !== pitch) {
            default_voice_settings.pitch = pitch;
            setDataLocalStorage(DEFAULT_VOICE_SETTINGS, JSON.stringify(default_voice_settings))
        }
    }


    function saveVolume(event: any, vol: any) {
        // props.voiceSettings.vol = vol
        // props.setVoiceSettings(props.voiceSettings)
    }


    function handleSelectedVoiceChange(event: React.ChangeEvent<{ value: unknown }>) {
        var index = voices.findIndex((voc) => voc.name === event.target.value)
        props.voiceSettings.voice = index;
        setVoice(voices[index].name)
        props.setPlayed(undefined)
        props.setVoiceSettings(props.voiceSettings)
        if (default_voice_settings.voice !== index) {
            default_voice_settings.voice = index;
            setDataLocalStorage(DEFAULT_VOICE_SETTINGS, JSON.stringify(default_voice_settings))
        }
    }

    return (
        <>
            <Dialog
                open={props.open}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitleClose title="Voice Settings" onClose={() => props.setOpen(false)} />
                <DialogContent>
                    <BoxStyled>
                        <Typography
                            variant="body2"
                            style={{ fontWeight: 500 }}
                        >Rate</Typography>
                        <SliderStyled
                            defaultValue={rate}
                            max={2}
                            min={0}
                            step={0.1}
                            value={rate}
                            valueLabelDisplay="auto"
                            marks={marks}
                            onChange={saveRate}
                        />
                        <Typography
                            variant="body2"
                            style={{ fontWeight: 500 }}
                        >Pitch</Typography>
                        <SliderStyled
                            defaultValue={pitch}
                            max={2}
                            min={0}
                            step={0.1}
                            valueLabelDisplay="auto"
                            marks={marks}
                            value={pitch}
                            onChange={savePitch}
                        />
                        {/* <Typography
                    variant="body2"
                    style={{ fontWeight: 500 }}
                >Volume</Typography>
                <Slider
                    defaultValue={vol}
                    max={2}
                    min={0}
                    step={0.1}
                    valueLabelDisplay="auto"
                    marks={marks}
                    value={vol}
                    onChange={saveVolume}
                /> */}
                        {voices && voices.length > 0 &&
                            <VoicesComponent voices={voices} voice={voice} handleSelectedVoiceChange={handleSelectedVoiceChange} />
                        }
                    </BoxStyled>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default React.memo(SettingsModal);

