import gql from 'graphql-tag';
import {
  UserFragment,
  MetaFragment,
  ResponseLoginFragment,
  UserListFragment,
} from './fragments';

export const USER_LIST = gql`
  query UserList($params: GetUsersInput!) {
    getUsers(params: $params) {
      nodes {
        ...UserList
      }
      meta {
        ...Meta
      }
    }
  }
  ${UserListFragment}
  ${MetaFragment}
`;

export const GET_USER_LIST_BASIC = gql`
  query GetUserListBasic($params: GetUsersInput!) {
    getUsers(params: $params) {
      nodes {
        _id
        firstName
        lastName
        middleName
        email
      }
    }
  }
`;

export const GET_USERS_FOR_NAVIGATOR = gql`
  query GetUsersForNavigator($params: GetUsersForNavigatorInput!) {
    getUsersForNavigator(params: $params) {
      nodes {
        ...User
      }
    }
  }
  ${UserFragment}
`;

export const USER_ADD = gql`
  mutation UserAdd($params: AddUserInput!) {
    addUser(params: $params) {
      _id
    }
  }
`;

export const INACTIVE_USER = gql`
  mutation InactiveUser($params: InactiveUserInput!) {
    inactiveUser(params: $params)
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($params: IdInputType!) {
    deleteUser(params: $params)
  }
`;

export const USER_DETAIL = gql`
  query UserDetail($params: IdInputType!) {
    getUser(params: $params) {
      ...User
    }
  }
  ${UserFragment}
`;

export const USER_UPDATE_PROFILE = gql`
  mutation UserUpdate($params: UpdateUserInput!) {
    updateUserProfile(params: $params) {
      ...ResponseLogin
    }
  }
  ${ResponseLoginFragment}
`;

export const USER_UPDATE_INFO = gql`
  mutation UserUpdateInfo($params: UpdateUserInfoInput!) {
    updateUserInfo(params: $params) {
      idUser: _id
      firstName
      middleName
      lastName
      status
      phones
    }
  }
`;

export const SET_NAVIGATOR = gql`
  mutation SetNavigator($params: SetRoleInput!) {
    setNavigator(params: $params) {
      idNavigator: _id
      role
      organization {
        idOrg: _id
        name
      }
    }
  }
`;

export const ADD_SECONDARY_EMAIL = gql`
  mutation AddSecondaryEmail($params: AddSecondaryEmailInput!) {
    addSecondaryEmail(params: $params) {
      ...ResponseLogin
    }
  }
  ${ResponseLoginFragment}
`;

export const REMOVE_SECONDARY_EMAIL = gql`
  mutation RemoveSecondaryEmail($params: RemoveSecondaryEmailInput!) {
    removeSecondaryEmail(params: $params) {
      ...ResponseLogin
    }
  }
  ${ResponseLoginFragment}
`;
export const SET_PRIMARY_EMAIL = gql`
  mutation SetPrimaryEmail($params: SetPrimaryEmailInput!) {
    setPrimaryEmail(params: $params) {
      ...ResponseLogin
    }
  }
  ${ResponseLoginFragment}
`;

export const ADD_USER_SECONDARY_EMAIL = gql`
  mutation AddUserSecondaryEmail($params: AddUserSecondaryEmailInput!) {
    addUserSecondaryEmail(params: $params) {
      secondaryEmail {
        email
        isVerified
      }
      _id
    }
  }
`;
export const REMOVE_USER_SECONDARY_EMAIL = gql`
  mutation RemoveUserSecondaryEmail($params: RemoveUserSecondaryEmailInput!) {
    removeUserSecondaryEmail(params: $params) {
      secondaryEmail {
        email
        isVerified
      }
      _id
    }
  }
`;

export const SET_USER_PRIMARY_EMAIL = gql`
  mutation SetUserPrimaryEmail($params: SetUserPrimaryEmailInput!) {
    setUserPrimaryEmail(params: $params) {
      secondaryEmail {
        email
        isVerified
      }
      email
      _id
    }
  }
`;

export const INACTIVATE_NAVIGATOR = gql`
  mutation InactivateNavigator($params: UpdateUserInfoInput!) {
    updateUserInfo(params: $params) {
      ...UserList
    }
  }
  ${UserListFragment}
`;
