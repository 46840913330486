import React, { useState, useEffect, useRef } from 'react';
import Email from '@material-ui/icons/Email';
import { TextField, InputAdornment } from '@material-ui/core';
import { Control, Controller, FieldError } from 'react-hook-form';
import { TypographyRedStyled, BoxStyled } from 'share/component_css';
import { countryCode, containsAnyLetters } from 'share/utils';
import { EMAIL_REGEX } from 'CONST';
import PhoneInput from 'react-phone-input-2';

type Props = {
  watch: any;
  handleSetValue: (value: string, isEmail?: boolean) => void;
  register: any;
  errorsEmail: FieldError | undefined;
  errorsPhone: FieldError | undefined;
  control?: Control;
  setIsShowEmail?: (value: boolean) => void;
  isRegisterForm?: boolean;
};

export const EmailPhoneInput: React.FC<Props> = ({
  watch,
  handleSetValue,
  register,
  errorsEmail,
  errorsPhone,
  control,
  setIsShowEmail,
  isRegisterForm,
}) => {
  const optionCountryCode = countryCode();

  const [isLoginWithEmail, setLoginType] = useState(false);

  const valuePhone = useRef<string | null>(null);

  const isFocus = useRef(false);

  const handleChangePhone = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key.length !== 1) {
      valuePhone.current = null;
      return;
    }
    if (event.key.length === 1 && containsAnyLetters(event.key)) {
      setLoginType(false);
      if (setIsShowEmail) {
        setIsShowEmail(true);
      }
      isFocus.current = true;
      valuePhone.current = watch('phoneNumber');
    }
  };

  useEffect(() => {
    if (
      (!watch('phoneNumber') || watch('phoneNumber') === '1') &&
      watch('email')?.length >= 2 &&
      watch('email')?.charCodeAt() < 127 &&
      !containsAnyLetters(watch('email'))
    ) {
      setLoginType(true);
      if (setIsShowEmail) {
        setIsShowEmail(false);
      }
      handleSetValue(`${watch('email')}`);
    }
  }, [handleSetValue, setIsShowEmail, watch]);

  useEffect(() => {
    if (valuePhone.current && watch('email')?.length === 0) {
      handleSetValue(valuePhone.current.substring(1), true);
    }
  }, [handleSetValue, isLoginWithEmail, watch]);

  useEffect(() => {
    if (!watch('phoneNumber') && isLoginWithEmail === true) {
      handleSetValue('', true);
      setLoginType(false);
      if (setIsShowEmail) {
        setIsShowEmail(true);
      }
      isFocus.current = true;
    }
  }, [handleSetValue, isLoginWithEmail, setIsShowEmail, watch]);

  return (
    <>
      {!isLoginWithEmail ? (
        <TextField
          tabIndex={3}
          required
          autoFocus={!!(isFocus.current || !isRegisterForm)}
          inputProps={{ maxLength: 255 }}
          id="email"
          label="Email or Mobile"
          variant="outlined"
          name="email"
          placeholder="Enter email or mobile phone"
          inputRef={register({
            required: {
              value: true,
              message: 'Email is required',
            },
            pattern: {
              value: EMAIL_REGEX,
              message: 'Invalid email address',
            },
          })}
          helperText={!!errorsEmail ? errorsEmail.message : ''}
          error={!!errorsEmail}
          size="small"
          InputProps={{
            endAdornment: isRegisterForm ? (
              <></>
            ) : (
              <InputAdornment position="end">
                <Email color="action" />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      ) : (
        <BoxStyled error={errorsPhone ? 'true' : 'false'}>
          <Controller
            control={control}
            name="phoneNumber"
            rules={{
              validate: {
                required: value =>
                  value !== undefined || 'Phone number is required',
              },
            }}
            as={
              <PhoneInput
                inputProps={{
                  autoFocus: true,
                  tabIndex: 3,
                }}
                country={'us'}
                enableAreaCodeStretch
                onlyCountries={optionCountryCode.countryCodes}
                autoFormat={true}
                disableDropdown={
                  !(process.env.REACT_APP_HAS_VN_CODE === 'true')
                }
                containerClass="container-phone"
                countryCodeEditable={true}
                specialLabel="Phone"
                onKeyDown={handleChangePhone}
              />
            }
          />
          {errorsPhone && (
            <TypographyRedStyled variant="caption" display="block">
              {errorsPhone.message}
            </TypographyRedStyled>
          )}
        </BoxStyled>
      )}
    </>
  );
};
export default EmailPhoneInput;
