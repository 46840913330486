import { Box, FormControl, MenuItem, OutlinedInput, Select, Typography } from "@material-ui/core";
import React from "react";

type Props = {
    voices: any,
    voice: any,
    handleSelectedVoiceChange: any
};

export const VoiceComponent: React.FC<Props> = (props: Props) => {

    return (
        <>
            <Box
                style={{ width: "100%" }}
            >
                <FormControl style={{ minWidth: '200px' }} size="small">
                    <Typography
                        variant="body2"
                        style={{ fontWeight: 500 }}
                    >Voice</Typography>
                    <Select
                        fullWidth
                        onChange={props.handleSelectedVoiceChange}
                        value={props.voice}
                        label="Voice"
                        input={<OutlinedInput />}
                    >
                        {props.voices?.map((voice: any) =>
                            <MenuItem key={voice.voiceURI} value={voice.name}>{`[${voice.lang}] ${voice.name || voice.voiceURI}`}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Box>
        </>
    )
}


export default React.memo(VoiceComponent);
