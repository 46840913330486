import React, { useState } from 'react';
import { DialogContent, Dialog } from '@material-ui/core';
import { DialogTitleClose } from 'components';
import SurveyDisplay from 'modules/patients/components/SurveyDisplay';
import {
  GetSurveyById,
  GetSurveyByIdQueryVariables,
  SurveyResultFragment,
} from 'types.d';
import { GET_SRUVEY_BY_ID } from 'gql';
import { useLazyQuery } from '@apollo/react-hooks';
import { useEffectOnlyOnce } from 'hooks';
import * as Survey from 'survey-react';
import LoadingForm from 'modules/treatment_tree/components/Form/LoadingForm';

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
  item: SurveyResultFragment;
};

export const DialogSurveyView: React.FC<Props> = ({
  open,
  toogleDialog,
  item,
}) => {
  const [survey, setSurvey] = useState<Survey.ReactSurveyModel | undefined>(
    undefined,
  );

  const [getSurvey, { loading }] = useLazyQuery<
    GetSurveyById,
    GetSurveyByIdQueryVariables
  >(GET_SRUVEY_BY_ID, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const survey = new Survey.Model(data.getSurveyById.surveyData);
      survey.data = item?.data;
      survey.showNavigationButtons = false;
      survey.mode = 'display';
      survey.showCompletedPage = false;
      setSurvey(survey);
    },
  });

  useEffectOnlyOnce(() => {
    getSurvey({
      variables: {
        params: {
          _id: item?.survey?._id,
        },
      },
    });
  });

  return (
    <>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitleClose
          title={'Survey Response'}
          onClose={() => toogleDialog(false)}
        />
        <DialogContent>
          {!loading ? (
            survey && <SurveyDisplay survey={survey} />
          ) : (
            <LoadingForm />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default React.memo(DialogSurveyView);
