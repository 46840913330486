import {
  TreatmentTreeFragment,
  ResponseModifyTreatmentFragment,
  TreatmentForAddUpdateFragment,
  PatientChecklistFragment,
  PatientChecklistSurveyFragment,
  MetaFragment,
  PatientChecklistEmbeddedFragment,
} from './fragments';
import gql from 'graphql-tag';

export const CREATE_CHECK_LIST = gql`
  mutation CreateChecklist($params: CreateChecklistInput!) {
    createChecklist(params: $params) {
      ...ResponseModifyTreatment
    }
  }
  ${ResponseModifyTreatmentFragment}
`;

export const UPDATE_CHECK_LIST = gql`
  mutation UpdateChecklist($params: UpdateChecklistInput!) {
    updateChecklist(params: $params) {
      ...ResponseModifyTreatment
    }
  }
  ${ResponseModifyTreatmentFragment}
`;

export const DELETE_CHECKLIST = gql`
  mutation DeleteChecklist($params: IdInputType!) {
    deleteChecklist(params: $params)
  }
`;

export const GET_CHECKLIST_FOR_ATTACH = gql`
  query GetChecklistForAttach($params: GetChecklistForPatientInput!) {
    getChecklistForPatient(params: $params) {
      nodes {
        ...TreatmentForAddUpdate
      }
    }
  }
  ${TreatmentForAddUpdateFragment}
`;

export const ATTACH_CHECK_LIST = gql`
  mutation AttachChecklist($params: AttachChecklistInput!) {
    attachChecklist(params: $params) {
      ...TreatmentTree
    }
  }
  ${TreatmentTreeFragment}
`;

export const DETACH_CHECK_LIST = gql`
  mutation DetachChecklist($params: AttachChecklistInput!) {
    detachChecklist(params: $params) {
      ...TreatmentTree
    }
  }
  ${TreatmentTreeFragment}
`;

export const GET_PATIENT_CHECKLISTS = gql`
  query GetPatientChecklists($params: GetPatientChecklistInput!) {
    getPatientChecklists(params: $params) {
      nodes {
        ...PatientChecklist
      }
      meta {
        ...Meta
      }
    }
  }
  ${PatientChecklistFragment}
  ${MetaFragment}
`;

export const GET_PATIENT_CHECKLIST = gql`
  query GetPatientChecklist($params: GetAPatientChecklistInput!) {
    getPatientChecklist(params: $params) {
      ...PatientChecklistSurvey
    }
  }
  ${PatientChecklistSurveyFragment}
`;

export const ATTACH_PATIENT_CHECKLIST = gql`
  mutation AttachPatientChecklist($params: UpdatePatientChecklistInput!) {
    attachPatientChecklist(params: $params) {
      ...PatientChecklist
    }
  }
  ${PatientChecklistFragment}
`;

export const UPDATE_CHECKLIST_RESULT = gql`
  mutation UpdateChecklistResult($params: UpdateChecklistResultInput!) {
    updateChecklistResult(params: $params) {
      ...PatientChecklist
    }
  }
  ${PatientChecklistFragment}
`;

export const DETACH_PATIENT_CHECKLIST = gql`
  mutation DetachPatientChecklist($params: DetachPatientChecklistInput!) {
    detachPatientChecklist(params: $params) {
      ...PatientChecklist
    }
  }
  ${PatientChecklistFragment}
`;

export const DELETE_PATIENT_CHECKLIST = gql`
  mutation DeletePatientChecklist($params: DetachPatientChecklistInput!) {
    deletePatientChecklist(params: $params)
  }
`;

export const GET_LATEST_CHECKLIST_DATA = gql`
  query GetLatestChecklistData($params: GetPatientChecklistInput!) {
    getLatestChecklistData(params: $params) {
      _id
      status
      checklistResult
    }
  }
`;

export const GET_LIST_CHECKLIST = gql`
  query GetListChecklist(
    $params: GetTreatmentsInput! = { type: CHECKLIST, sortByOrder: { name: 1 } }
  ) {
    getTreatments(params: $params) {
      nodes {
        _id
        name
      }
    }
  }
`;

export const GET_CHECKLIST_DATA = gql`
  query GetTreatment($params: GetTreatmentInput!) {
    getTreatment(params: $params) {
      _id
      name
      surveyData
    }
  }
`;

export const GET_DETAIL_CHECKLIST = gql`
  query GetDetailChecklist(
    $paramsChecklist: GetTreatmentInput!
    $paramsResultsChecklist: GetPatientChecklistByChecklist!
  ) {
    getTreatment(params: $paramsChecklist) {
      _id
      name
      surveyData
    }
    getPatientChecklistData(params: $paramsResultsChecklist) {
      _id
      user {
        _id
        firstName
        middleName
        lastName
      }
      patientChecklistDatas {
        ...PatientChecklistEmbedded
      }
    }
  }
  ${PatientChecklistEmbeddedFragment}
`;

//get detail checklist included survey data and result of checklist from config(apply for display on dashboard)
export const GET_DETAIL_CHECKLIST_FROM_CONFIG = gql`
  query GetDetailChecklistFromConfig(
    $paramsChecklist: GetPatientChecklistFromChartConfigInput!
    $paramsResultsChecklist: GetPatientChecklistFromChartConfigInput!
  ) {
    getChecklistFromChartConfig(params: $paramsChecklist) {
      _id
      surveyData
      name
    }
    getPatientChecklistFromChartConfig(params: $paramsResultsChecklist) {
      _id
      user {
        _id
        firstName
        lastName
        middleName
      }
      patientChecklistDatas {
        ...PatientChecklistEmbedded
      }
    }
  }
  ${PatientChecklistEmbeddedFragment}
`;
