import gql from 'graphql-tag';
import { MetaFragment, ReleaseNoteSettingFragment } from './fragments';

export const GET_RELEASE_NOTE = gql`
  query GetReleaseNote($params: GetReleaseNoteInput!) {
    getReleaseNote(params: $params) {
      nodes {
        ...ReleaseNoteSetting
      }
      meta {
        ...Meta
      }
    }
  }
  ${ReleaseNoteSettingFragment}
  ${MetaFragment}
`;
