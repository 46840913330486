import { styled } from '@material-ui/core/styles';
import { Box, Avatar, Tabs, Typography } from '@material-ui/core';

export const ProfileStyled = styled('div')({
  fontSize: '1.125rem',
  fontWeight: 600,
  display: 'flex',
});

export const AvatarStyled = styled(Avatar)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

export const BoxProfileStyled = styled(Box)(({ theme }) => ({
  background: `linear-gradient(${theme.palette.primary.light} 0%,${theme.palette.primary.light} 35%, ${theme.palette.common.white} 35%, ${theme.palette.common.white} 100%)`,
}));

export const BoxFieldStyled = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const IconStyled = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  margin: '0 auto',
  marginBottom: theme.spacing(0, 5),
}));

export const TabsStyled = styled(Tabs)({
  '& .MuiTabs-flexContainer': {
    justifyContent: 'center',
  },
});

export const TypographyStyled = styled(Typography)({
  overflowWrap: 'break-word',
});
