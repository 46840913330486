import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import { ButtonFlexStart } from 'share/component_css';
import { CONFIRM_COLOR } from 'CONST';

type Props = {
  disabled?: boolean;
  text?: string;
  Icon?: React.ReactNode;
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  loading?: boolean;
  callbackClick?: (e: any) => void;
  className?: string;
  fullWidth?: boolean;
  tabIndex?: number;
  isSubmit?: boolean;
  size?: 'large' | 'medium' | 'small';
  textTransform?:
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'initial'
    | 'inherit';
  flexStart?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  width?: number | string;
  colorCustom?: string;
};

export const ButtonLoading: React.FC<Props> = ({
  text,
  Icon,
  color,
  loading,
  callbackClick,
  className,
  fullWidth,
  isSubmit,
  tabIndex,
  size,
  textTransform,
  disabled,
  flexStart,
  variant,
  width,
  colorCustom,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (callbackClick) {
      callbackClick(event);
    }
  };
  const buttonProps: ButtonProps = {
    tabIndex: tabIndex || 0,
    type: isSubmit ? 'submit' : 'button',
    className: className || '',
    onClick: handleClick,
    size: size || 'medium',
    color: color || 'primary',
    disabled: disabled || loading,
    fullWidth: fullWidth,
    startIcon: Icon ? (
      loading ? (
        <CircularProgress size={20} color="inherit" />
      ) : (
        Icon
      )
    ) : (
      <></>
    ),
    style: {
      textTransform: textTransform,
      width: width ? width : fullWidth ? '100%' : '',
      backgroundColor: colorCustom ? CONFIRM_COLOR[colorCustom] : '',
    },
    variant: variant || 'contained',
  };
  return flexStart ? (
    <ButtonFlexStart {...buttonProps}>{text}</ButtonFlexStart>
  ) : (
    <Button {...buttonProps}>{text}</Button>
  );
};
