import React, { useState, useRef } from 'react';
import { TextfieldSelect } from 'components';
import { MenuItem, TextField, Box } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import { GetPublicTreatmentsQuery, HomePageConfig } from 'types.d';
import CardComponent from './CardComponent';
import { useCustomPermission, useUpdateMeClient } from 'hooks';
import { DataLoadTreatment } from 'modules/home/components/FormSearchTreatment';
import { HOME_PAGE_CONFIG } from 'CONST';
import { GroupButtonFilter } from 'components';

type Props = {
  items?: GetPublicTreatmentsQuery['getPublicTreatments']['nodes'];
  hasMore: boolean;
  setHasMore: React.Dispatch<React.SetStateAction<boolean>>;
  getMoreData: (
    dataFilter: { name: string; type?: HomePageConfig },
    page: number,
    reset?: boolean,
    type?: string,
  ) => void;
  title: string;
  handleAssignTreatment?: () => void;
  isReset: boolean;
};

export const TreatmentsComponent: React.FC<Props> = ({
  items,
  hasMore,
  setHasMore,
  getMoreData,
  isReset,
}) => {
  let page = useRef(1);

  const { meClient } = useUpdateMeClient();

  const { isAdmin, isNavigator } = useCustomPermission();

  const [dataLoadTreatment, setDataLoadTreatment] = useState<DataLoadTreatment>(
    {
      name: '',
      type:
        meClient?.setting?.homePage?.treatmentType ||
        HomePageConfig.AllTreatment,
    },
  );

  const setDataFilter = (dataFilter: DataLoadTreatment) => {
    setDataLoadTreatment({ ...dataFilter });
  };

  const loadMore = () => {
    if (hasMore) {
      page.current++;
      setHasMore(false);
      getMoreData(dataLoadTreatment, page.current);
    }
  };

  const handleChangeType = (e: any) => {
    setDataLoadTreatment({
      name: dataLoadTreatment?.name,
      type: e.target.value,
    });
  };
  const resetFilter = () => {
    page.current = 1;
    setHasMore(false);
    const filter = {
      name: '',
      type:
        meClient?.setting?.homePage?.treatmentType ||
        HomePageConfig.AllTreatment,
    };
    setDataLoadTreatment(filter);
    getMoreData(filter, page.current, true);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    page.current = 1;
    setHasMore(false);
    getMoreData(dataLoadTreatment, page.current, true);
  };

  return (
    <>
      {(isAdmin || isNavigator) && (
        <form noValidate onSubmit={onSubmit}>
          <Box
            display="grid"
            gridTemplateColumns="200px 200px 100px 100px"
            gridGap={8}
            justifyContent="end"
          >
            <TextField
              placeholder="Search"
              size="small"
              fullWidth
              variant="outlined"
              value={dataLoadTreatment.name}
              onChange={event => {
                setDataFilter({
                  name: event.target.value,
                  type: dataLoadTreatment.type,
                });
              }}
            />
            <TextfieldSelect
              label="Filter"
              name="type"
              small
              callbackChangeValue={e => handleChangeType(e)}
              value={dataLoadTreatment?.type}
            >
              {[
                ...HOME_PAGE_CONFIG,
                { value: 'public', title: 'Public Treatments' },
                { value: 'private', title: 'Private Treatments' },
              ]?.map(item => (
                <MenuItem key={item?.value} value={item?.value}>
                  {item?.title}
                </MenuItem>
              ))}
            </TextfieldSelect>
            <GroupButtonFilter
              loading={false}
              resetFilter={resetFilter}
              callBackClick={onSubmit}
            />
          </Box>
        </form>
      )}
      {!isReset && (
        <InfiniteScroll
          pageStart={page.current}
          loadMore={loadMore}
          hasMore={hasMore}
        >
          {items?.map((item, index) => (
            <div key={item._id} className="card">
              <CardComponent index={index} data={item} />
            </div>
          ))}
        </InfiniteScroll>
      )}
    </>
  );
};
export default TreatmentsComponent;
