import React from 'react';
import { Grid, Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { MediaStyled } from 'share/component_css';
import { styled } from '@material-ui/core/styles';
type Props = {};

export const GridStyled = styled(Grid)(({ theme }) => ({
  cursor: 'pointer',
  position: 'relative',
  margin: theme.spacing(0.5),
  borderRadius: '10px',
}));

export const LoadingDialogMedia: React.FC<Props> = () => {
  return (
    <Grid container spacing={2}>
      {[0, 1, 2, 3, 4].map(item => {
        return (
          <Grid key={item} container item xs={12} sm={6}>
            <GridStyled item container spacing={2}>
              <Grid item xs={12} sm={6}>
                <MediaStyled>
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                  >
                    <Skeleton variant="rect" height="100%" />
                  </Box>
                </MediaStyled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Skeleton width="50%" />
                <Skeleton width="70%" />
                <Skeleton width="50%" />
                <Skeleton width="30%" />
              </Grid>
            </GridStyled>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default LoadingDialogMedia;
