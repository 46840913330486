import gql from 'graphql-tag';
import {
  CalendarConfigFragment,
  MetaFragment,
  ResponseShareTreatmentResFragment,
} from './fragments';

export const GET_CALENDAR_CONFIG_INFO = gql`
  query GetCalendarConfigInfo($params: GetCalendarConfigInput!) {
    getCalendarConfigInfo(params: $params) {
      nodes {
        ...CalendarConfig
      }
      meta {
        ...Meta
      }
    }
  }
  ${CalendarConfigFragment}
  ${MetaFragment}
`;

export const CREATE_CALENDAR_CONFIG = gql`
  mutation CreateCalendarConfig($params: CreateCalendarConfigInput!) {
    createCalendarConfig(params: $params) {
      ...ResponseShareTreatmentRes
    }
  }
  ${ResponseShareTreatmentResFragment}
`;

export const DELETE_CALENDAR_CONFIG = gql`
  mutation DeleteCalendarConfig($params: DeleteCalendarConfigInput!) {
    deleteCalendarConfig(params: $params)
  }
`;

export const GET_CONFIG_BY_GROUP = gql`
  query getConfigByGroup($params: IdInputType!) {
    getConfigByGroup(params: $params) {
      ...CalendarConfig
    }
  }
  ${CalendarConfigFragment}
`;
