import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  TableRow,
  TableCell,
  Grid,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableContainer,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  GetPatientTimeRecordQuery,
  GetPatientTimeRecordQueryVariables,
  PatientTimeRecordFragment,
} from 'types.d';
import { GET_PATIENT_TIME_RECORD } from 'gql';
import { PatientContext, PatientDetailContext } from 'share/context';
import {
  useToogleDialog,
  usePagination,
  InfoPagination,
  useUpdateMeClient,
  useQueryCustom,
  useTimeRecord,
} from 'hooks';
import {
  ButtonLoading,
  NoDataTable,
  LoadingTable,
  DeleteConfirm,
} from 'components';
import Report from './Report';
import DialogAddTimeRecord from './DialogAddTimeRecord';
import { useServices } from '../../services';

type Props = {
  tabIndexDialog?: number;
};

export const TimeRecord: React.FC<Props> = ({ tabIndexDialog }) => {
  const [openDialogTimeReport, setOpenDialogTimeReport] = useToogleDialog();

  const { meClient } = useUpdateMeClient();

  const patientDetailContext = useContext(PatientDetailContext);

  const patientContext = useContext(PatientContext);

  const idRecord = useRef('');

  const recordSelected = useRef<PatientTimeRecordFragment | undefined>(
    undefined,
  );

  const { getLastRecord } = useTimeRecord();

  const [isOpenDelete, toogleDialogDelete] = useToogleDialog();

  const {
    handleDeleteRecord,
    loadingDeleteRecord,
    handleCreateRecord,
  } = useServices();

  const callbackFetchData = (infoPagination: InfoPagination) => {
    fetchMoreTimeReport(infoPagination);
  };

  const {
    handleSetInfoPagination,
    infoPagination,
    DefaultPagination,
    defaultPaginationProps,
  } = usePagination(callbackFetchData);

  const {
    data: dataListTimeRecord,
    loading: loadingListTimeRecord,
    fetchMore,
  } = useQueryCustom<
    GetPatientTimeRecordQuery,
    GetPatientTimeRecordQueryVariables
  >({
    api: GET_PATIENT_TIME_RECORD,
    variables: {
      params: {
        page: 1,
        limit: meClient.setting?.perPage || 5,
        patient: patientDetailContext?.patient.owner?.idOwner,
        sortByOrder: { startedAt: -1 },
      },
    },
  });

  const handleDelete = () => {
    handleDeleteRecord(idRecord.current, () => {
      callbackDelete();
      idRecord.current = '';
      toogleDialogDelete(false);
      patientDetailContext?.handleSetDialogOpen('');
      recordSelected.current = undefined;
    });
  };

  const fetchMoreTimeReport = useCallback(
    (infoPagination: InfoPagination) => {
      const { page, limit } = infoPagination;
      fetchMore({
        variables: {
          params: {
            page,
            limit,
            patient: patientDetailContext?.patient.owner?.idOwner,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            getPatientTimeRecord: {
              ...prev.getPatientTimeRecord,
              nodes: fetchMoreResult!.getPatientTimeRecord!.nodes!,
              meta: fetchMoreResult!.getPatientTimeRecord!.meta!,
            },
          };
        },
      });
    },
    [fetchMore, patientDetailContext],
  );

  const addTimeReport = useCallback(() => {
    setOpenDialogTimeReport(true);
    recordSelected.current = undefined;
  }, [setOpenDialogTimeReport]);

  const openDialogDelete = (id: string) => {
    idRecord.current = id;
    toogleDialogDelete(true);
  };

  //open dialog from outside (button + on task )
  useEffect(() => {
    //open adding dialog
    if (patientDetailContext?.dialogOpen === 'add-record') {
      addTimeReport();
      return;
    }
    if (patientDetailContext?.dialogOpen.includes('startNow')) {
      handleCreateRecord(patientDetailContext?.dialogOpen.split('-')[1], () => {
        callback();
      });
      return;
    }
    //open delete dialog
    if (patientDetailContext?.dialogOpen.includes('deleteRecord')) {
      openDialogDelete(patientDetailContext?.dialogOpen.split('-')[1]);
      return;
    }
    //open stop dialog
    if (patientDetailContext?.dialogOpen.includes('stop-record')) {
      setOpenDialogTimeReport(true);
      recordSelected.current = patientDetailContext?.dataForOpenDialog;
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTimeReport, patientDetailContext?.dialogOpen]);

  const isInactive = useMemo(() => {
    return patientDetailContext?.isInactive;
  }, [patientDetailContext]);

  const callback = useCallback(() => {
    handleSetInfoPagination({ ...infoPagination, page: 1 }, true);
  }, [handleSetInfoPagination, infoPagination]);

  const callbackDelete = () => {
    if ((dataListTimeRecord?.getPatientTimeRecord?.nodes?.length || 0) === 1) {
      handleSetInfoPagination({ page: 1, limit: infoPagination.limit }, true);
    } else {
      callbackFetchData(infoPagination);
    }
  };
  return (
    <>
      {openDialogTimeReport && (
        <DialogAddTimeRecord
          openDialogAddRecord={openDialogTimeReport}
          setOpenDialogAddRecord={setOpenDialogTimeReport}
          recordSelected={getLastRecord(recordSelected.current)}
          idCptCode={recordSelected.current?._id}
          callback={callback}
        />
      )}
      <DeleteConfirm
        loading={loadingDeleteRecord}
        handleConfirm={handleDelete}
        open={isOpenDelete}
        toogle={toogleDialogDelete}
      />
      {!patientDetailContext?.isAnonymous && !isInactive && (
        <ButtonLoading
          Icon={<AddIcon />}
          className="mb-8 text-tranform-none"
          text="Add Time Record"
          callbackClick={addTimeReport}
          disabled={patientContext?.timer?.seconds !== 0}
        />
      )}
      <Grid xs={12} item>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Module</TableCell>
                <TableCell>Time Spent</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loadingListTimeRecord &&
                dataListTimeRecord?.getPatientTimeRecord.nodes?.length ===
                  0 && <NoDataTable colSpan={3} />}
              {loadingListTimeRecord && <LoadingTable colSpan={3} />}
              {!loadingListTimeRecord &&
                dataListTimeRecord &&
                dataListTimeRecord?.getPatientTimeRecord?.nodes.map(
                  (report, index) => {
                    return (
                      <Report
                        key={index}
                        report={report}
                        index={index}
                        callbackDelete={callbackDelete}
                      />
                    );
                  },
                )}
            </TableBody>
          </Table>
        </TableContainer>
        {dataListTimeRecord?.getPatientTimeRecord?.meta?.total !== 0 && (
          <DefaultPagination
            count={dataListTimeRecord?.getPatientTimeRecord?.meta?.total || 0}
            {...defaultPaginationProps}
          />
        )}
      </Grid>
    </>
  );
};

export default React.memo(TimeRecord);
