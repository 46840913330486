import React, { useContext, useMemo } from 'react';
import { useDrag } from 'react-dnd';
import { PatientDetailContext } from 'share/context';
import { BoxPatientDragStyled } from '../../styles';

type Props = {
  label: string;
  tabIndex: number;
  icon?: React.ReactElement;
  splitScreen: (index: number) => void;
  numberSplitScreen: number | null;
};

export const TabPatientDrag: React.FC<Props> = ({
  label,
  tabIndex,
  icon,
  splitScreen,
  numberSplitScreen,
}) => {
  const patientDetailContext = useContext(PatientDetailContext);

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', label, tabIndex },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult && item) {
        splitScreen((item as { tabIndex: number })?.tabIndex);
      }
    },
  });

  const isNotAbleToDrag = useMemo(() => {
    return [patientDetailContext?.indexTab, numberSplitScreen].includes(
      tabIndex,
    );
  }, [numberSplitScreen, patientDetailContext, tabIndex]);

  const opacity = isDragging ? 0.4 : 1;

  return (
    <BoxPatientDragStyled
      ref={isNotAbleToDrag ? null : drag}
      style={{
        opacity,
        cursor: isNotAbleToDrag ? 'pointer' : 'move',
      }}
    >
      {label}
      {icon}
    </BoxPatientDragStyled>
  );
};

export default React.memo(TabPatientDrag);
