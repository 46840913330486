import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Card, CardContent, Grid } from '@material-ui/core';
type Props = {};

export const Loading: React.FC<Props> = () => {
    return (
        <>
            <Grid container spacing={2}>
                {Array.from(Array(6).keys()).map((item) => (
                    <Grid item xs={12} sm={6} md={3} key={item}>
                        <Card className="position-relative mt-16 p-16">
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Skeleton variant="rect" width="100%" height={80} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} className="mt-16">
                                    <Grid item xs={12}>
                                        <Skeleton variant="rect" width="100%" height={40} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} className="mt-16">
                                    <Grid item xs={12}>
                                        <Skeleton variant="rect" width="100%" height={20} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} className="mt-16">
                                    <Grid item xs={12} sm={5}>
                                        <Skeleton variant="rect" width="100%" height={20} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};
export default React.memo(Loading);
