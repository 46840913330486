import React, { useMemo } from 'react';
import { AutoCompleteLoadData, AutoCompleteSelect } from 'components';
import {
  GetTreatmentAddSurveyQueryVariables,
  TreatmentType,
  TreatmentsForAddSurveyFragment,
} from 'types.d';
import { FormData } from '../DialogSendSurvey';
import { FormContextValues } from 'react-hook-form';
import { QueryLazyOptions } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';
import { EventClickArg } from '@fullcalendar/react';
import { sortObject } from 'share/utils';

type Props = {
  methods: FormContextValues<FormData>;
  loadingTreatments: boolean;
  dataTreatments: TreatmentsForAddSurveyFragment[];
  renderTreatment: any;
  loadTreatments: (
    options?: QueryLazyOptions<GetTreatmentAddSurveyQueryVariables> | undefined,
  ) => void;
  sendBy: string;
  currentEvent?: EventClickArg;
};

export const AutocompleteLoadSurvey: React.FC<Props> = ({
  methods,
  loadingTreatments,
  dataTreatments,
  renderTreatment,
  loadTreatments,
  sendBy,
  currentEvent,
}) => {
  const watchTreatment = methods!.watch('treatment');

  const handleTextInput = (value: string) => {
    if (value) {
      loadTreatments({
        variables: {
          params: {
            name: value,
            hasSurvey: sendBy === 'survey' ? true : false,
            type: [TreatmentType.Treatment],
            sortByOrder: { name: 1 },
          },
        },
      });
    }
  };

  const listSurveyByTreatment = useMemo(() => {
    return watchTreatment?.survey || [];
  }, [watchTreatment]);

  return (
    <>
      <Grid item xs={12} sm={4}>
        <AutoCompleteLoadData
          hasOptionsTextNull
          label="Treatment"
          nameOption="name"
          required
          loadList={loadingTreatments}
          options={dataTreatments || []}
          small
          defaultValue={renderTreatment ? renderTreatment : undefined}
          name="treatment"
          idOption="idTreatment"
          control={methods!.control}
          methods={methods}
          error={!!methods!.errors.treatment}
          callbackHandleText={value => handleTextInput(value)}
          disabled={!!currentEvent}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <AutoCompleteSelect
          disablePortal
          small
          required
          error={!!methods!.errors.survey}
          name="survey"
          label="Survey"
          control={methods!.control}
          nameOption="name"
          options={sortObject(listSurveyByTreatment) || []}
          disabled={!!currentEvent}
        />
      </Grid>
    </>
  );
};

export default React.memo(AutocompleteLoadSurvey);
