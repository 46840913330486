import gql from 'graphql-tag';
import {
  MetaFragment,
  PatientFollowUpFragment,
  PatientNoteFragment,
  PatientFragment,
  SurveyPatientDashboardFragment,
  StageChangeLogFragment,
  TreatmentStagesFragment,
  CurrentStageFragment,
  CommonTreatmentFragment,
  ResponseShareTreatmentResFragment,
  PatientTagFragment,
  TagFragment,
  PatientSurveyFragment,
  SurveyForNoteFragment,
  TreatmentsForAddSurveyFragment,
  PatientChecklistFragment,
  SurveyResultEmbeddedFragment,
  FollowUpTypeShortFragment,
  PatientChecklistPdfFragment,
  PatientCondStageFragment,
  FolderForTaskTypeFragment,
  PatientOwnerFragment,
  SameNewPatientFragment,
  PatientDashboardInfoFragment,
  PatientDashboardInfoQuickViewFragment,
  DuplicateUserFragment,
  PatientTimeRecordFragment,
  PatientAssignFragment,
} from './fragments';

export const GET_PATIENT_DASHBOARDS = gql`
  query GetPatientDashboards($params: GetPatientDashboardInput!) {
    getPatientDashboards(params: $params) {
      ...PatientDashboardInfo
    }
  }
  ${PatientDashboardInfoFragment}
`;

export const GET_COMMON_TREATMENT = gql`
  query GetCommonTreatment($params: GetTreatmentsInput!) {
    getTreatments(params: $params) {
      nodes {
        ...CommonTreatment
      }
    }
  }
  ${CommonTreatmentFragment}
`;

export const GET_COMMON_TREATMENT_FAVORITES = gql`
  query GetCommonTreatmentFavorites($params: GetTreatmentsInput!) {
    getTreatments(params: $params) {
      nodes {
        ...CommonTreatment
      }
    }
  }
  ${CommonTreatmentFragment}
`;

export const GET_ASSIGN_SURVEYS = gql`
  query GetAssignSurveys(
    $params: GetTreatmentsInput! = { type: SURVEY, sortByOrder: { name: 1 } }
  ) {
    getAssignSurveys(params: $params) {
      nodes {
        ...CommonTreatment
      }
    }
  }
  ${CommonTreatmentFragment}
`;

export const GET_TREATMENT_ADD_SURVEY = gql`
  query GetTreatmentAddSurvey($params: GetTreatmentsInput!) {
    getTreatments(params: $params) {
      nodes {
        ...TreatmentsForAddSurvey
      }
    }
  }
  ${TreatmentsForAddSurveyFragment}
`;

export const GET_TREATMENT_ADD_PATIENT = gql`
  query GetTreatmentAddPatient($params: GetTreatmentsInput!) {
    getTreatments(params: $params) {
      nodes {
        ...CommonTreatment
      }
    }
  }
  ${CommonTreatmentFragment}
`;

export const CREATE_PATIENT_DASHBOARD = gql`
  mutation CreatePatientDashboard($params: CreatePatientDashboardInput!) {
    createPatientDashboard(params: $params) {
      _id
      name
    }
  }
`;

export const UPDATE_PATIENT_DASHBOARD = gql`
  mutation UpdatePatientDashboard($params: UpdatePatientDashboardInput!) {
    updatePatientDashboard(params: $params) {
      ...PatientDashboardInfo
    }
  }
  ${PatientDashboardInfoFragment}
`;

export const DELETE_PATIENT_DASHBOARD = gql`
  mutation DeletePatientDashboard($params: IdInputType!) {
    deletePatientDashboard(params: $params)
  }
`;

export const GET_PATIENTS = gql`
  query GetPatients($params: GetPatientsInput!) {
    getPatients(params: $params) {
      nodes {
        ...Patient
      }
      meta {
        ...Meta
      }
    }
  }
  ${MetaFragment}
  ${PatientFragment}
`;

export const GET_PATIENTS_ASSIGN = gql`
  query GetPatientsAssign($params: GetPatientsInput!) {
    getPatients(params: $params) {
      nodes {
        ...PatientAssign
      }
    }
  }
  ${PatientAssignFragment}
`;

export const CREATE_PATIENT_NOTE = gql`
  mutation CreatePatientNote($params: CreatePatientNoteInput!) {
    createPatientNote(params: $params) {
      ...PatientNote
    }
  }
  ${PatientNoteFragment}
`;

export const ADD_MORE_PATIENT_NOTE = gql`
  mutation AddMorePatientNote($params: AddMorePatientNoteInput!) {
    addMorePatientNote(params: $params) {
      ...PatientNote
    }
  }
  ${PatientNoteFragment}
`;

export const UPDATE_PATIENT_NOTE = gql`
  mutation UpdatePatientNote($params: UpdatePatientNoteInput!) {
    updatePatientNote(params: $params) {
      ...PatientNote
    }
  }
  ${PatientNoteFragment}
`;

export const CREATE_PATIENT_FOLLOW_UP = gql`
  mutation CreatePatientFollowUp($params: CreatePatientFollowUpInput!) {
    createPatientFollowUp(params: $params) {
      ...PatientFollowUp
    }
  }
  ${PatientFollowUpFragment}
`;

export const UPDATE_PATIENT_FOLLOW_UP = gql`
  mutation UpdatePatientFollowUp($params: UpdatePatientFollowUpInput!) {
    updatePatientFollowUp(params: $params) {
      ...PatientFollowUp
    }
  }
  ${PatientFollowUpFragment}
`;

export const UPDATE_PATIENT_INFO = gql`
  mutation UpdatePatientInfo($params: UpdatePatientInfoInput!) {
    updatePatientInfo(params: $params) {
      ...PatientOwner
    }
  }
  ${PatientOwnerFragment}
`;

export const DELETE_PATIENT_FOLLOW_UP = gql`
  mutation deletePatientFollowUp($params: DeletePatientFollowInput!) {
    deletePatientFollowUp(params: $params)
  }
`;

export const GET_SURVEY_DATA_BY_PATIENT = gql`
  query GetSurveyDataByPatient($params: GetSurveyDataByPatientInput!) {
    getSurveyDataByPatient(params: $params) {
      nodes {
        ...SurveyResultEmbedded
      }
    }
  }
  ${SurveyResultEmbeddedFragment}
`;

export const GET_DETAIL_SURVEY_DATA = gql`
  query GetDetailSurveyData($params: GetTreatmentInput!) {
    getTreatment(params: $params) {
      _id
      surveyData
    }
  }
`;

export const UPDATE_SURVEY_RESULT = gql`
  mutation UpdateSurveyResult($params: UpdateSurveyResultInput!) {
    updateSurveyResult(params: $params) {
      updatedAt
      updatedBy {
        firstName
        lastName
      }
      workingStatus
    }
  }
`;

export const UPDATE_STAGE_CHANGEG_LOG = gql`
  mutation UpdateStageChangedLog($params: UpdateStageChangedLogInput!) {
    updateStageChangedLog(params: $params) {
      ...StageChangeLogF
    }
  }
  ${StageChangeLogFragment}
`;

export const UPDATE_STAGE_CHANGED_LOG_TIME = gql`
  mutation UpdateStageChangedLogTime($params: UpdateStageChangedLogTimeInput!) {
    updateStageChangedLogTime(params: $params) {
      ...StageChangeLogF
    }
  }
  ${StageChangeLogFragment}
`;

// export const DELETE_STAGE_CHANGE_LOG = gql`
//   mutation DeleteStageChangedLog($params: DeleteStageChangedLogInput!) {
//     deleteStageChangedLog(params: $params)
//   }
// `;

export const GET_ROOT_TREATMENT_FROM_PATIENT = gql`
  query GetRootTreatmentFromPatient(
    $params: GetRootTreatmentInputFromPatient!
  ) {
    getRootTreatmentFromPatient(params: $params) {
      _id
      name
      treatmentStages {
        ...TreatmentStages
      }
    }
  }
  ${TreatmentStagesFragment}
`;

export const GET_CURRENT_STAGE = gql`
  query getCurrentStage($params: GetCurrentStageInput!) {
    getCurrentStage(params: $params) {
      ...CurrentStage
    }
  }
  ${CurrentStageFragment}
`;

export const GET_TREATMENTS_NOT_YET_ASSIGNED = gql`
  query GetTreatmentsNotYetAssigned(
    $params: GetTreatmentsNotYetAssignedInput!
  ) {
    getTreatmentsNotYetAssigned(params: $params) {
      ...CommonTreatment
    }
  }
  ${CommonTreatmentFragment}
`;

export const SHARE_MULTI_TREATMENT_TO_PATIENT = gql`
  mutation ShareMultiTreatmentToPatient($params: SharedMultiTreatmentInput!) {
    shareMultiTreatmentToPatient(params: $params) {
      ...ResponseShareTreatmentRes
    }
  }
  ${ResponseShareTreatmentResFragment}
`;

export const CREATE_NEW_PATIENT = gql`
  mutation CreateNewPatient($params: CreateNewPatientInput!) {
    createNewPatient(params: $params) {
      result
      patient {
        _id
        email
        phones
      }
      data {
        ...SameNewPatient
      }
      userExists {
        ...DuplicateUser
      }
    }
  }
  ${SameNewPatientFragment}
  ${DuplicateUserFragment}
`;

export const SIGNED_PATIENT_NOTE = gql`
  mutation SignedPatientNote($params: UpdateStatusPatientNoteInput!) {
    signedPatientNote(params: $params) {
      _id
    }
  }
`;

export const PUBLISHED_PATIENT_NOTE = gql`
  mutation PublishedPatientNote($params: UpdateStatusPatientNoteInput!) {
    publishedPatientNote(params: $params) {
      _id
    }
  }
`;

export const DELETE_PATIENT_NOTE = gql`
  mutation DeletePatientNote($params: DeletePatientNoteInput!) {
    deletePatientNote(params: $params)
  }
`;

export const REMOVE_PATIENT_NOTE = gql`
  mutation RemovePatientNote($params: DeletePatientNoteInput!) {
    removePatientNote(params: $params) {
      ...PatientNote
    }
  }
  ${PatientNoteFragment}
`;

export const CHANGE_SURVEY_RESULT_OF_NOTE = gql`
  mutation ChangeSurveyResultOfNote($params: ChangeSurveyResultInput!) {
    changeSurveyResultOfNote(params: $params) {
      ...PatientNote
    }
  }
  ${PatientNoteFragment}
`;

export const SEARCH_PATIENTS = gql`
  query SearchPatients($params: AssistantInputType!) {
    searchPatients_v2(params: $params)
  }
`;

export const GET_PATIENT_TAGS = gql`
  query GetPatientTags($params: GetPatientTagsInput!) {
    getPatientTags(params: $params) {
      nodes {
        ...Tag
      }
    }
  }
  ${TagFragment}
`;

export const CREATE_PATIENT_TAG = gql`
  mutation CreatePatientTag($params: CreateTagForPatientInput!) {
    createPatientTag(params: $params) {
      ...PatientTag
    }
  }
  ${PatientTagFragment}
`;

export const UN_TAG_ON_PATIENT = gql`
  mutation UnTagOnPatient($params: UnTagOnPatientInput!) {
    unTagOnPatient(params: $params)
  }
`;

export const GET_PATIENT_TAGS_BY_IDS = gql`
  query GetPatientTagsByIds($params: GetTagsByPatientInput!) {
    getPatientTagsByIds(params: $params) {
      ...Tag
    }
  }
  ${TagFragment}
`;

export const IMPORT_PATIENT = gql`
  mutation ImportPatient($file: Upload!, $params: ImportPatientInput!) {
    importPatient(file: $file, params: $params)
  }
`;

export const GET_PATIENT_NOTES = gql`
  query GetPatientNotes($params: GetPatientNotesInput!) {
    getPatientNotes(params: $params) {
      nodes {
        ...PatientNote
      }
      meta {
        ...Meta
      }
    }
  }
  ${PatientNoteFragment}
  ${MetaFragment}
`;

export const GET_PATIENT_FOLLOW_UPS = gql`
  query GetPatientFollowUps($params: GetPatientFollowUpsInput!) {
    getPatientFollowUps(params: $params) {
      nodes {
        ...PatientFollowUp
      }
      meta {
        ...Meta
      }
    }
  }
  ${PatientFollowUpFragment}
  ${MetaFragment}
`;

export const GET_STAGE_CHANGELOGS = gql`
  query GetStageChangedLogs($params: GetStageChangedLogsInput!) {
    getStageChangedLogs(params: $params) {
      nodes {
        ...StageChangeLogF
      }
      meta {
        ...Meta
      }
    }
  }
  ${StageChangeLogFragment}
  ${MetaFragment}
`;

export const GET_PATIENT_SURVEYS = gql`
  query GetSurveyResultGroup($params: GetSurveyResultGroupInput!) {
    getSurveyResultGroup(params: $params) {
      nodes {
        ...PatientSurvey
      }
      meta {
        ...Meta
      }
    }
  }
  ${PatientSurveyFragment}
  ${MetaFragment}
`;

export const GET_DATA_FOR_NOTE = gql`
  query GetDataForNote(
    $paramsNote: GetSurveyResultGroupInput!
    $paramsType: GetPatientNoteTypesInput!
  ) {
    getSurveyResultGroup(params: $paramsNote) {
      nodes {
        ...SurveyForNote
      }
    }
    getPatientNoteTypes(params: $paramsType) {
      nodes {
        idType: _id
        name
        description
      }
    }
  }
  ${SurveyForNoteFragment}
`;

export const CREATE_SURVEY_RESULT_BY_NAVIGATOR = gql`
  mutation CreateSurveyResultByNavigator(
    $params: NavigatorCreateSurveyResultInput!
  ) {
    createSurveyResultByNavigator(params: $params) {
      surveyResult: _id
    }
  }
`;

export const UNREFERENCE_TREATMENT = gql`
  mutation UnReferenceTreatment($params: UnReferenceTreatmentInput!) {
    unReferenceTreatment(params: $params)
  }
`;

export const SEND_TREATMENT_REMINDER = gql`
  mutation SendTreatmentReminder($params: TreatmentReminderInput!) {
    sendTreatmentReminder(params: $params)
  }
`;

export const GET_GROUP_DETAIL_PATIENT = gql`
  query GetGroupDetailPatient(
    $paramsFollowUp: GetPatientFollowUpsInput!
    $paramsNote: GetPatientNotesInput!
    $paramsSurvey: GetSurveyDataByPatientInput!
    $paramsChecklist: GetPatientChecklistInput!
    $paramsCondition: GetPatientStageInput!
    $paramsTimeRecord: GetPatientTimeRecordInput!
  ) {
    getPatientFollowUps(params: $paramsFollowUp) {
      nodes {
        ...PatientFollowUp
      }
      meta {
        ...Meta
      }
    }
    getPatientNotes(params: $paramsNote) {
      nodes {
        ...PatientNote
      }
      meta {
        ...Meta
      }
    }
    getSurveyDataByPatient(params: $paramsSurvey) {
      nodes {
        ...SurveyResultEmbedded
      }
    }
    getPatientChecklists(params: $paramsChecklist) {
      nodes {
        ...PatientChecklist
      }
    }
    getListPatientStages(params: $paramsCondition) {
      nodes {
        ...PatientCondStage
      }
    }
    getPatientTimeRecord(params: $paramsTimeRecord) {
      nodes {
        ...PatientTimeRecord
      }
    }
  }
  ${PatientFollowUpFragment}
  ${PatientNoteFragment}
  ${PatientChecklistFragment}
  ${MetaFragment}
  ${SurveyResultEmbeddedFragment}
  ${PatientCondStageFragment}
  ${PatientTimeRecordFragment}
`;

export const GET_NOTE_TAG = gql`
  query GetNoteTag($params: GetPatientNotesInput!) {
    getPatientNotes(params: $params) {
      nodes {
        ...PatientNote
      }
      meta {
        ...Meta
      }
    }
  }
  ${PatientNoteFragment}
  ${MetaFragment}
`;

export const GET_DATA_FOR_FILTER_PATIENT = gql`
  query GetDataForFilterPatient(
    $paramsTreatment: GetTreatmentsInput! = {
      type: TREATMENT
      sortByOrder: { name: 1 }
    }
    $paramsSurvey: GetTreatmentsInput! = {
      type: SURVEY
      sortByOrder: { name: 1 }
    }
    $paramsChecklist: GetTreatmentsInput! = {
      type: CHECKLIST
      sortByOrder: { name: 1 }
    }
    $paramsFollowUpType: GetFollowUpTypesInput! = {
      page: 1
      isFilterList: true
      sortByOrder: { name: 1 }
    }
    $paramsNavigator: GetUsersInput! = { sortByOrder: { name: 1 } }
    $paramsModule: GetCPTCodeInput! = { sortByOrder: { name: 1 } }
  ) {
    getTreatments(params: $paramsTreatment) {
      nodes {
        _id
        name
        treatmentStages {
          name
          treatmentStage {
            stageId: _id
          }
        }
      }
    }
    getSurveys: getTreatments(params: $paramsSurvey) {
      nodes {
        ...SurveyPatientDashboard
      }
    }
    getChecklists: getTreatments(params: $paramsChecklist) {
      nodes {
        ...SurveyPatientDashboard
      }
    }
    getFollowUpTypes(params: $paramsFollowUpType) {
      nodes {
        ...FollowUpTypeShort
      }
    }
    getNavigators(params: $paramsNavigator) {
      nodes {
        _id
        firstName
        lastName
        middleName
      }
    }
    getCPTCode(params: $paramsModule) {
      nodes {
        _id
        code
        name
      }
    }
  }
  ${SurveyPatientDashboardFragment}
  ${FollowUpTypeShortFragment}
`;

export const GET_COMMON_CHECKLIST = gql`
  query GetCommonChecklist(
    $params: GetTreatmentsInput! = { type: CHECKLIST, sortByOrder: { name: 1 } }
  ) {
    getTreatments(params: $params) {
      nodes {
        ...CommonTreatment
      }
    }
  }
  ${CommonTreatmentFragment}
`;

export const GET_GROUP_SETTING_TASK_TYPE = gql`
  query GetGroupSettingTaskType(
    $paramsGroupSetting: GetTreatmentRegimenInput!
    $paramsTaskType: GetFollowUpTypesInput!
  ) {
    getTreatmentRegimen(params: $paramsGroupSetting) {
      nodes {
        _id
        name: regimenName
      }
    }
    getFollowUpTypes(params: $paramsTaskType) {
      nodes {
        _id
        name
        folder {
          _id
        }
      }
    }
  }
`;

export const GET_COMMON_DATA = gql`
  query GetCommonData($params: GetTreatmentsInput!) {
    getTreatments(params: $params) {
      nodes {
        ...CommonTreatment
      }
    }
  }
  ${CommonTreatmentFragment}
`;

export const GET_DATA_TASK_TYPE = gql`
  query GetDataTaskType(
    $paramsTreatment: GetTreatmentsInput!
    $paramsFolder: GetTreatmentsInput!
  ) {
    getTreatments(params: $paramsTreatment) {
      nodes {
        ...CommonTreatment
      }
    }
    getFolder: getTreatments(params: $paramsFolder) {
      nodes {
        ...FolderForTaskType
      }
    }
  }
  ${CommonTreatmentFragment}
  ${FolderForTaskTypeFragment}
`;

export const GET_FOLDER_TASK_TYPE = gql`
  query GetFolderTaskType($params: GetTreatmentsInput! = { type: FOLDER }) {
    getFolder: getTreatments(params: $params) {
      nodes {
        ...FolderForTaskType
      }
    }
  }
  ${FolderForTaskTypeFragment}
`;

export const GET_SURVEY_RESULT_TASK = gql`
  query GetSurveyResultTask(
    $paramsSurveyVersion: GetSurveyVersionInput!
    $paramsTask: GetPatientFollowUpsInput!
    $paramsChecklist: GetPatientChecklistInput!
    $paramsPatientStage: GetPatientStageInput!
    $paramsTimeRecord: GetPatientTimeRecordInput!
  ) {
    getSurveyVersion(params: $paramsSurveyVersion) {
      nodes {
        name
        surveyData
      }
    }
    getPatientFollowUps(params: $paramsTask) {
      nodes {
        _id
        note
        followUpDate
      }
    }
    getPatientChecklists(params: $paramsChecklist) {
      nodes {
        ...PatientChecklistPdf
      }
    }
    getListPatientStages(params: $paramsPatientStage) {
      nodes {
        ...PatientCondStage
      }
    }
    getPatientTimeRecord(params: $paramsTimeRecord) {
      nodes {
        ...PatientTimeRecord
      }
    }
  }
  ${PatientChecklistPdfFragment}
  ${PatientCondStageFragment}
  ${PatientTimeRecordFragment}
`;

export const ARCHIVE_SURVEY_RESULT = gql`
  mutation ArchiveSurveyResult($params: HideSurveyResultInput!) {
    archiveSurveyResult(params: $params)
  }
`;

export const IMPORT_PATIENT_DASHBOARD = gql`
  mutation importPatientDashboard($params: IdInputType!) {
    importPatientDashboard(params: $params) {
      _id
    }
  }
`;

export const GET_PD4_QUICK_VIEW = gql`
  query GetPD4QuickView {
    getPD4QuickView {
      ...PatientDashboardInfoQuickView
    }
  }
  ${PatientDashboardInfoQuickViewFragment}
`;

export const GET_LINK_DOWNLOAD_FILE_SAMPLE = gql`
  query GetLinkDownloadFileSample {
    getLinkDownloadFileSample
  }
`;
export const DELETE_PATIENT = gql`
  mutation DeletePatient($params: DeletePatientInput!) {
    deletePatient(params: $params)
  }
`;

export const DELETE_SURVEY_RESULT = gql`
  mutation DeleteSurveyResult($params: DeleteSurveyResultInput!) {
    deleteSurveyResult(params: $params)
  }
`;

export const GET_DATA_FOR_FILTER_NAVIGATOR = gql`
  query GetDataFilterByNavigator(
    $paramsTreatment: GetTreatmentsInput!
    $paramsSurvey: GetTreatmentsInput!
  ) {
    getTreatments(params: $paramsTreatment) {
      nodes {
        _id
        name
      }
    }
    getSurvey: getTreatments(params: $paramsSurvey) {
      nodes {
        _id
        name
      }
    }
  }
`;

export const CREATE_EXPORT = gql`
  mutation createExport($params: CreateExportInput!) {
    createExport(params: $params) {
      dashboardId
    }
  }
`;

export const GET_EXPORT = gql`
  query getExport($params: GetExportInput!) {
    getExport(params: $params) {
      dashboardId
      selectedFields
      selectedSurveys
      aliases
    }
  }
`;

export const GET_ALL_PATIENT_CHECKLISTS = gql`
  query ExportPatientChecklists($params: ExportAllPatientInput!) {
    exportPatientChecklists(params: $params) {
      nodes {
        ...PatientChecklist
      }
    }
  }
  ${PatientChecklistFragment}
`;
