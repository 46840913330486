import React from 'react';
import { DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { styled, Theme } from '@material-ui/core/styles';
interface Props {
  title?: string | React.ReactNode;
  onClose: () => void;
}

const IconButtonStyled = styled(IconButton)(
  ({ hastext, theme }: { hastext: string; theme: Theme }) => ({
    color: theme.palette.grey[500],
    marginLeft: hastext === 'false' ? 'auto' : 'none',
  }),
);

const DialogTitleStyled = styled(DialogTitle)(
  ({ hastext, theme }: { hastext: string; theme: Theme }) => ({
    paddingBottom: hastext === 'false' ? 0 : theme.spacing(0),
  }),
);

export const DialogTitleClose: React.FC<Props> = ({ title, onClose }) => {
  return (
    <>
      <DialogTitleStyled hastext={title ? 'true' : 'false'} disableTypography>
        {typeof title === 'string' ? (
          <Typography variant="h6" className="text-tranform-none">
            {title}
          </Typography>
        ) : (
          title
        )}
        <IconButtonStyled
          hastext={title ? 'true' : 'false'}
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButtonStyled>
      </DialogTitleStyled>
    </>
  );
};

export default DialogTitleClose;
