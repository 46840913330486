import React, {
  useCallback,
  useRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  TableRow,
  TableCell,
  IconButton,
  Grid,
  Table,
  TableHead,
  TableBody,
  Tooltip,
} from '@material-ui/core';
import { checkFilterFollowUp, formatDate, sortTaskType } from 'share/utils';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DialogFollowUp from './DialogFollowUp';
import {
  PatientFollowUpFragment,
  GetPatientFollowUpsQuery,
  GetPatientFollowUpsQueryVariables,
} from 'types.d';
import { GET_PATIENT_FOLLOW_UPS } from 'gql';
import { FOLLOW_UP_STATUS } from 'CONST';
import { convertArrayToObject } from 'share/utils';
import { ContentPatientContext, PatientDetailContext } from 'share/context';
import { useToogleDialog, usePagination, InfoPagination } from 'hooks';
import {
  ButtonLoading,
  DeleteConfirm,
  NoDataTable,
  LoadingTable,
} from 'components';
import {
  TableContainerStyled,
  TableCellMaxWidthStyled,
  TypoThreeDotsLine,
} from 'share/component_css';
import DeleteIcon from '@material-ui/icons/Delete';
import { useServices } from '../../services';
import { useLazyQuery } from '@apollo/react-hooks';
import { DataFilterDate } from 'modules/dashboard/interfaces';
import { Rule } from 'modules/patients/interfaces';
import { PaperBorder } from 'share/component_css';
type Props = {
  tabIndexDialog?: number;
};

const objFollowUp = convertArrayToObject(FOLLOW_UP_STATUS, 'value');

export const FollowUp: React.FC<Props> = ({ tabIndexDialog }) => {
  const [isOpen, toogleDialog] = useToogleDialog();

  const [isOpenDelete, toogleDialogDelete] = useToogleDialog();

  const patientDetailContext = useContext(PatientDetailContext);

  const contentPatientContext = useContext(ContentPatientContext);

  const followUpsFilter = (contentPatientContext?.filterFromConfig as Rule[])?.find(
    item => item?.followUpDate || item?.followUpType,
  );

  const followUpSelected = useRef<PatientFollowUpFragment | undefined>(
    undefined,
  );

  let listFollowUps = [] as PatientFollowUpFragment[];

  const [dataFollowUpsShow, setDataFollowUpsShow] = useState<
    PatientFollowUpFragment[] | undefined
  >(undefined);

  const followUpsByCondition = (
    data: PatientFollowUpFragment[] | undefined,
    infoPagination: InfoPagination,
  ) => {
    data?.map(item =>
      checkFilterFollowUp(
        item,
        followUpsFilter?.followUpType,
        followUpsFilter?.followUpDate as DataFilterDate,
      )
        ? listFollowUps.unshift(item)
        : listFollowUps.push(item),
    );

    setDataFollowUpsShow(
      sortTaskType(listFollowUps, followUpsFilter)?.slice(
        (infoPagination.page - 1) * infoPagination.limit,
        (infoPagination.page - 1) * infoPagination.limit + infoPagination.limit,
      ),
    );
  };

  const idFollowUpDelete = useRef('');

  const callbackFetchData = (infoPagination: InfoPagination) => {
    followUpsByCondition(
      dataFollowUp?.getPatientFollowUps?.nodes,
      infoPagination,
    );
  };

  const {
    infoPagination,
    DefaultPagination,
    defaultPaginationProps,
    handleSetInfoPagination,
  } = usePagination(callbackFetchData);

  const { handleDeleteFollowUp, loadingDeleteFollowUp } = useServices();

  const [
    getFollowUps,
    { loading: loadingFollowUp, data: dataFollowUp },
  ] = useLazyQuery<GetPatientFollowUpsQuery, GetPatientFollowUpsQueryVariables>(
    GET_PATIENT_FOLLOW_UPS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        params: {
          patientId: patientDetailContext?.patient.owner?.idOwner,
        },
      },
      onCompleted: data => {
        if (data?.getPatientFollowUps?.nodes) {
          followUpsByCondition(
            data?.getPatientFollowUps?.nodes,
            infoPagination,
          );
        }
      },
    },
  );

  useEffect(() => {
    if (patientDetailContext?.indexTab === 2 || tabIndexDialog === 1) {
      getFollowUps();
    }
  }, [getFollowUps, patientDetailContext, tabIndexDialog]);

  const editFollowUp = (followUp: PatientFollowUpFragment) => {
    followUpSelected.current = followUp;
    toogleDialog(true);
  };

  const addFollowUp = useCallback(() => {
    toogleDialog(true);
    followUpSelected.current = undefined;
  }, [toogleDialog]);

  const openDialogDelete = (id: string) => {
    idFollowUpDelete.current = id;
    toogleDialogDelete(true);
  };

  //open dialog from outside (button + on task )
  useEffect(() => {
    //open adding dialog
    if (patientDetailContext?.dialogOpen === 'add-followup') {
      addFollowUp();
      return;
    }
    //open delete dialog
    if (patientDetailContext?.dialogOpen.includes('deleteFollowup')) {
      openDialogDelete(patientDetailContext?.dialogOpen.split('-')[1]);
      return;
    }
    //open edit dialog
    if (patientDetailContext?.dialogOpen === 'editFollowup') {
      editFollowUp(patientDetailContext?.dataForOpenDialog);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addFollowUp, patientDetailContext?.dialogOpen]);

  const handleDelete = () => {
    handleDeleteFollowUp(idFollowUpDelete.current, () => {
      if ((dataFollowUp?.getPatientFollowUps?.nodes?.length || 0) === 1) {
        getFollowUps();
        handleSetInfoPagination({ ...infoPagination, page: 1 }, true);
      } else {
        getFollowUps();
        handleSetInfoPagination(
          dataFollowUp?.getPatientFollowUps?.nodes &&
            dataFollowUp?.getPatientFollowUps?.nodes?.length >
              infoPagination.limit &&
            infoPagination.page !== 1 &&
            dataFollowUp?.getPatientFollowUps?.nodes?.length %
              infoPagination.limit ===
              1
            ? { ...infoPagination, page: infoPagination.page - 1 }
            : { ...infoPagination },
        );
      }
      idFollowUpDelete.current = '';
      handleToogleDialogDelete(false);
    });

    handleToogleDialogDelete(false);
    idFollowUpDelete.current = '';
  };

  const callbackAfterAModify = useCallback(
    (isUpdate?: boolean) => {
      getFollowUps();
      handleSetInfoPagination({ ...infoPagination, page: 1 }, true);
      if (isUpdate && infoPagination.page === 1) {
        getFollowUps();
      }
    },
    [getFollowUps, handleSetInfoPagination, infoPagination],
  );

  const handleToogleDialogDelete = useCallback(
    (status: boolean) => {
      toogleDialogDelete(status);
      if (!status) {
        patientDetailContext?.handleSetDialogOpen('');
      }
    },
    [patientDetailContext, toogleDialogDelete],
  );

  const isShowAdd = useMemo(() => {
    return (
      !patientDetailContext?.isAnonymous && !patientDetailContext?.isInactive
    );
  }, [patientDetailContext]);

  const isInactive = useMemo(() => {
    return patientDetailContext?.isInactive;
  }, [patientDetailContext]);
  return (
    <>
      {isOpen && (
        <DialogFollowUp
          followUpSelected={followUpSelected.current}
          open={isOpen}
          toogleDialog={toogleDialog}
          callbackAfterAModify={callbackAfterAModify}
        />
      )}
      <DeleteConfirm
        loading={loadingDeleteFollowUp}
        handleConfirm={handleDelete}
        open={isOpenDelete}
        toogle={handleToogleDialogDelete}
      />
      {isShowAdd && (
        <ButtonLoading
          Icon={<AddIcon />}
          className="mb-8 text-tranform-none"
          text="Add Task"
          callbackClick={addFollowUp}
        />
      )}
      <Grid xs={12} item>
        <TableContainerStyled component={PaperBorder}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Task Date</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align={!isInactive ? 'inherit' : 'right'}>
                  Type
                </TableCell>
                {!isInactive && <TableCell align="right">Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loadingFollowUp &&
                dataFollowUp?.getPatientFollowUps?.nodes?.length === 0 && (
                  <NoDataTable colSpan={isInactive ? 5 : 6} />
                )}
              {loadingFollowUp && <LoadingTable colSpan={isInactive ? 5 : 6} />}
              {!loadingFollowUp &&
                dataFollowUpsShow &&
                dataFollowUpsShow.map(followUp => {
                  return (
                    <TableRow key={followUp._id}>
                      <TableCellMaxWidthStyled maxwidth="200px">
                        <TypoThreeDotsLine variant="subtitle2">
                          {followUp.note}
                        </TypoThreeDotsLine>
                      </TableCellMaxWidthStyled>
                      <TableCell>
                        {followUp.followUpDate
                          ? formatDate(followUp.followUpDate)
                          : ''}
                      </TableCell>
                      <TableCell>{formatDate(followUp.createdAt)}</TableCell>
                      <TableCell>
                        {objFollowUp[followUp.status].title || 'N/A'}
                      </TableCell>
                      <TableCellMaxWidthStyled
                        align={!isInactive ? 'inherit' : 'right'}
                        maxwidth={!isInactive ? '200px' : '500px'}
                      >
                        <TypoThreeDotsLine variant="subtitle2">
                          {followUp.type?.name || 'N/A'}
                        </TypoThreeDotsLine>
                      </TableCellMaxWidthStyled>
                      {!isInactive && (
                        <TableCell align="right">
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => editFollowUp(followUp)}
                              color="primary"
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => openDialogDelete(followUp._id)}
                              aria-label="delete"
                              color="secondary"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainerStyled>
        {dataFollowUp?.getPatientFollowUps?.nodes?.length !== 0 && (
          <DefaultPagination
            count={dataFollowUp?.getPatientFollowUps?.nodes?.length || 0}
            {...defaultPaginationProps}
          />
        )}
      </Grid>
    </>
  );
};

export default React.memo(FollowUp);
