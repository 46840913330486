import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Divider, MenuItem } from '@material-ui/core';
import {
  AutoCompleteLoadData,
  AutoCompleteSelect,
  TextfieldSelect,
} from 'components';
import { FormData } from './DialogAddPatient';
import { FormContextValues } from 'react-hook-form';
import {
  GetTreatmentAddPatientQuery,
  GetTreatmentAddPatientQueryVariables,
  GetCommonDataQuery,
  GetCommonDataQueryVariables,
  CommonTreatmentFragment,
  TreatmentType,
} from 'types.d';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_COMMON_DATA, GET_TREATMENT_ADD_PATIENT } from 'gql';
import { useEffectOnlyOnce, useUpdateMeClient } from 'hooks';
import { capitalizeFirstLetter, sortObject } from 'share/utils';
import { TypographyBold } from 'share/component_css';
import { DEFAULT_LIMIT } from 'CONST';

type Props = {
  methods: FormContextValues<FormData>;
  treatmentHomePage?: CommonTreatmentFragment;
};

const defaultParams = {
  page: 1,
  limit: DEFAULT_LIMIT,
  hasSurvey: true,
  type: [TreatmentType.Treatment],
  sortByOrder: { name: 1 },
};

export const SendTreatments: React.FC<Props> = ({
  methods,
  treatmentHomePage,
}) => {
  const { meClient } = useUpdateMeClient();

  const { watch, errors, control, setValue } = methods;

  const [sendBy, setSendBy] = useState('treatment');

  const watchTreatment = watch('treatment');

  const defaultParamsTreatment = {
    page: 1,
    type: [TreatmentType.Treatment],
    sortByOrder: { name: 1 },
    limit: DEFAULT_LIMIT,
  };

  const [loadTreatments, { data: dataTreatments }] = useLazyQuery<
    GetTreatmentAddPatientQuery,
    GetTreatmentAddPatientQueryVariables
  >(GET_TREATMENT_ADD_PATIENT, {
    fetchPolicy: 'no-cache',
  });

  useEffectOnlyOnce(() => {
    getListTreatments({
      variables: {
        params: defaultParamsTreatment,
      },
    });
  });

  useEffect(() => {
    setValue('survey', undefined);
  }, [setValue, watchTreatment]);

  const [
    getListTreatments,
    { loading: loadingListTreatments, data: dataListTreatments },
  ] = useLazyQuery<GetCommonDataQuery, GetCommonDataQueryVariables>(
    GET_COMMON_DATA,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        if (treatmentHomePage && sendBy === 'favorite') {
          const treatment = data?.getTreatments?.nodes?.find(
            item => item?._id === treatmentHomePage?._id,
          );
          setValue('treatment4Assign', { ...treatment });
        }
      },
    },
  );

  const handleTextInputTreatment = (value: string) => {
    if (value) {
      loadTreatments({
        variables: {
          params: {
            name: value,
            ...defaultParams,
          },
        },
      });
    }
  };

  const listSurveyByTreatment = useMemo(() => {
    return watchTreatment?.survey || [];
  }, [watchTreatment]);

  const handleChangeTypeBy = (event: any, value: string) => {
    setSendBy(value);
    if (['favorite', 'treatment'].includes(value)) {
      getListTreatments({
        variables: {
          params:
            value === 'favorite'
              ? { ...defaultParamsTreatment, favorites: meClient?._id }
              : defaultParamsTreatment,
        },
      });
      return;
    }
    loadTreatments({
      variables: {
        params: {
          ...defaultParams,
        },
      },
    });
  };

  const handleTextInputGetListTreatments = (value: string) => {
    const params = {
      ...defaultParamsTreatment,
      name: value,
    };
    if (value) {
      getListTreatments({
        variables: {
          params:
            sendBy === 'favorite'
              ? { ...params, favorites: meClient?._id }
              : params,
        },
      });
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TypographyBold variant="subtitle1">Assign Treatments</TypographyBold>
      </Grid>
      <Grid item xs={12} sm={sendBy === 'survey' ? 12 : 6}>
        <TextfieldSelect
          id="by"
          name="by"
          label="By"
          small
          callbackChangeValue={handleChangeTypeBy}
          value={sendBy}
        >
          {['treatment', 'survey', 'favorite'].map(item => {
            return (
              <MenuItem key={item} value={item}>
                {capitalizeFirstLetter(item)}
              </MenuItem>
            );
          })}
        </TextfieldSelect>
      </Grid>
      {['treatment', 'favorite'].includes(sendBy) && (
        <Grid item xs={6}>
          <AutoCompleteLoadData
            hasOptionsTextNull
            label="Treatment"
            nameOption="name"
            required
            loadList={loadingListTreatments}
            options={dataListTreatments?.getTreatments?.nodes || []}
            small
            defaultValue={null}
            name="treatmentAssign"
            control={control}
            error={!!errors.treatmentAssign}
            callbackHandleText={value =>
              handleTextInputGetListTreatments(value)
            }
          />
        </Grid>
      )}
      {sendBy === 'survey' && (
        <>
          <Grid item xs={12} sm={6}>
            <AutoCompleteLoadData
              hasOptionsTextNull
              label="Treatment"
              nameOption="name"
              options={dataTreatments?.getTreatments?.nodes || []}
              small
              name="treatment"
              control={methods?.control}
              error={!!methods?.errors.treatment}
              callbackHandleText={value => handleTextInputTreatment!(value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoCompleteSelect
              disablePortal
              small
              error={!!methods?.errors.surveyAssign}
              name="surveyAssign"
              label="Survey"
              control={methods?.control}
              nameOption="name"
              options={sortObject(listSurveyByTreatment) || []}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default React.memo(SendTreatments);
