import React, { useMemo, useEffect, useCallback } from 'react';
import {
  TextField,
  TableRow,
  TableCell,
  Typography,
  MenuItem,
  Grid,
  Tooltip,
  Box,
} from '@material-ui/core';
import { ButtonDelete, TextfieldSelect } from 'components';
import { ScheduleConfigEmbedded, SpecifiedType, TreatmentType } from 'types.d';
import {
  ChipStyled,
  TypographyItalic,
  TypoThreeDotsLine,
  TypoThreeDot,
} from 'share/component_css';
import { RowTreatment } from './DialogAddGroups';
import {
  COLOR_BY_TYPE,
  GET_FREQ,
  GET_REPEAT_WEEK,
  SPECIFIED_TYPE,
} from 'CONST';
import {
  capitalizeFirstLetter,
  convertDateWithoutUTC,
  formatDate,
  getOptionTypeRepeat,
  optionByNWeekday,
  optionDay,
  renderGroupSendDate,
  renderHintText,
  renderOptionByWeekDay,
} from 'share/utils';
import { addDays, addHours, addMinutes, startOfDay } from 'date-fns';
import {
  useCustomPermission,
  useCustomPopup,
  useEffectOnlyOnce,
  useToogleDialog,
  useUpdateMeClient,
} from 'hooks';
import { DataCalendar } from 'modules/patients/components/Survey/DialogSendSurvey';
import { RRule } from 'rrule';
import { useSnackbar } from 'notistack';
import { KeyboardTimePickerStyled } from 'share/component_css';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { getHours, getMinutes, set } from 'date-fns';
import { useForm } from 'react-hook-form';

type Props = {
  item: RowTreatment;
  setListTreatments?: (value: RowTreatment[]) => void;
  listTreatments?: RowTreatment[];
  isUpdate?: boolean;
  sendingDate?: string | null;
  deleteItem?: (id: string, parentId?: string) => void;
  idOwner?: string;
  isSendingGroup?: boolean;
  sendBefore?: boolean;
};

type FormData = {
  description: string;
};

export const TableListItem: React.FC<Props> = ({
  item,
  setListTreatments,
  listTreatments,
  isUpdate,
  sendingDate,
  deleteItem,
  idOwner,
  isSendingGroup,
  sendBefore
}) => {
  const onDelete = (id: string) => {
    if (deleteItem) {
      deleteItem(id, item?.parentId);
    }
  };

  const { watch, errors, register } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      description: item?.description || '',
    },
  });

  // useEffectOnlyOnce(() => {
  //   console.log("item", item)
  // })

  const watchDes = watch('description');

  const [isOpenDialog, setOpenDialogCustom] = useToogleDialog();

  const { enqueueSnackbar } = useSnackbar();

  const { meClient } = useUpdateMeClient();

  const { isNavigator, isAdmin } = useCustomPermission();

  const { dataCalendarProps, CustomTime } = useCustomPopup();

  const renderFreq = (repeat: string, dataCalendar?: DataCalendar) => {
    if (
      (dataCalendar && dataCalendar?.freq === 'day') ||
      ['notRepeat', 'daily'].includes(repeat)
    ) {
      return RRule.DAILY;
    }
    if (
      (dataCalendar && dataCalendar?.freq === 'week') ||
      ['weekly', 'allWeek'].includes(repeat)
    ) {
      return RRule.WEEKLY;
    }
    if (
      (dataCalendar && dataCalendar?.freq === 'month') ||
      repeat === 'monthly'
    ) {
      return RRule.MONTHLY;
    }
    if (repeat === 'annually') {
      return RRule.YEARLY;
    }
    return RRule.YEARLY;
  };

  const paramsCreate = useCallback(
    (repeat: string, dataCalendar?: DataCalendar) => {
      let defaultSchedule = {
        freq: renderFreq(repeat, dataCalendar),
        interval: dataCalendar?.amount ? +dataCalendar?.amount : 1,
      } as ScheduleConfigEmbedded;
      if (dataCalendar?.endDate) {
        defaultSchedule.until = new Date(
          convertDateWithoutUTC(dataCalendar?.endDate),
        );
      }
      if (dataCalendar?.after || repeat === 'notRepeat') {
        defaultSchedule.count = dataCalendar?.after ? +dataCalendar?.after : 1;
      }
      if (dataCalendar?.before || repeat === 'notRepeat') {
        defaultSchedule.count = dataCalendar?.before ? +dataCalendar?.before : 1;
      }
      if (
        repeat === 'weekly' ||
        repeat === 'allWeek' ||
        (dataCalendar && dataCalendar?.freq === 'week')
      ) {
        defaultSchedule.byweekday = renderOptionByWeekDay(repeat, dataCalendar);
      }
      if (
        repeat === 'monthly' ||
        (dataCalendar && dataCalendar?.repeatMonth === 'monthly')
      ) {
        defaultSchedule.byNweekDay = {
          weekday: optionDay(new Date().toISOString()),
          n: optionByNWeekday(new Date().toISOString()),
        };
      }
      return defaultSchedule;
    },
    [],
  );

  const changeData = (
    key: string,
    value: number | SpecifiedType | string | MaterialUiPickersDate,
  ) => {
    if (listTreatments && setListTreatments) {
      const updatedRows = [...listTreatments];
      const index = updatedRows.findIndex(row => row._id === item?._id);
      if (isUpdate && isAdmin && idOwner !== meClient?._id) {
        updatedRows[index].updatedBy = meClient?._id;
        updatedRows[index].updatedByRole = meClient?.role;
      }
      if (key === 'numberOfDays') {
        updatedRows[index].numberOfDays = value as number;
      }
      if (key === 'after') {
        updatedRows[index].after = value as SpecifiedType;
        updatedRows[index].numberOfDays = null;
        updatedRows[index].time = [
          SpecifiedType.Immediate,
          SpecifiedType.NextHours,
          'day',
        ].includes(value as SpecifiedType)
          ? undefined
          : updatedRows[index].time;
      }
      if (key === 'before') {
        updatedRows[index].before = value as SpecifiedType;
        updatedRows[index].numberOfDays = null;
        updatedRows[index].time = [
          SpecifiedType.PriorHours,
          'day',
        ].includes(value as SpecifiedType)
          ? undefined
          : updatedRows[index].time;
      }
      if (key === 'repeat') {
        updatedRows[index].repeat = value as string;
        if (value !== 'custom') {
          updatedRows[index].custom = paramsCreate(value as string);
        }
      }
      if (key === 'time') {
        updatedRows[index].time = value
          ? {
            hour: getHours(new Date(value as Date)),
            minute: getMinutes(new Date(value as Date)),
          }
          : undefined;
      }
      if (key === 'description') {
        updatedRows[index].description = value as string;
      }
      setListTreatments(updatedRows);
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (+event.target.value > 24 && (item?.after === SpecifiedType.NextHours || item?.after === SpecifiedType.PriorHours)) {
      enqueueSnackbar('No more than 24 hours', { variant: 'error' });
      return;
    }
    changeData(
      'numberOfDays',
      +event.target.value === 0 ? 1 : +event.target.value,
    );
  };

  const handleChangeAfter = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    changeData(sendBefore ? 'before' : 'after', event.target.value as SpecifiedType);
  };

  const handleChangeRepeat = (value: string) => {
    if (value === 'custom') {
      setOpenDialogCustom(true);
    }
    changeData('repeat', value);
  };

  const handleChangeDes = (value: string) => {
    changeData('description', value);
  };

  const onChangeRepeat = (e: any) => {
    e.preventDefault();
    handleChangeRepeat(e.target.value);
  };

  const handleCustomValue = (data: DataCalendar) => {
    if (listTreatments && setListTreatments) {
      const updatedRows = [...listTreatments];
      const index = updatedRows.findIndex(row => row._id === item?._id);
      updatedRows[index].custom = paramsCreate('', data);
      setListTreatments(updatedRows);
    }
  };

  const disableField = useMemo(() => {
    return isUpdate && isNavigator && idOwner !== meClient?._id;
  }, [idOwner, isNavigator, isUpdate, meClient]);

  const renderSendingDate = (item: RowTreatment) => {
    //console.log(sendingDate)
    const anchorDate = addDays(
      new Date(sendingDate || ''),
      sendBefore ? (item?.numberOfDays ? -item?.numberOfDays : 0) : item?.numberOfDays || 0,
    );
    //console.log(anchorDate.toISOString())
    if (item?.after || (item?.before && item.before !== SpecifiedType.PriorHours)) {
      return (
        renderGroupSendDate(
          item?.time ? item?.time : null,
          SPECIFIED_TYPE[item.after || item.before as SpecifiedType].value,
          sendingDate || '',
          sendBefore ? (item?.before === SpecifiedType.PriorHours
            ? (item?.numberOfDays ? item?.numberOfDays : 0) || 24 : undefined
          ) : (item?.after === SpecifiedType.NextHours
            ? item?.numberOfDays || 1
            : undefined),
        )?.toISOString() || ''
      );
    } else if (item?.before && item.before === SpecifiedType.PriorHours && sendingDate) {
      return new Date(sendingDate).toISOString() || ''
    }
    return item?.time
      ? addMinutes(
        addHours(startOfDay(anchorDate), item?.time?.hour),
        item?.time?.minute,
      ).toISOString()
      : anchorDate?.toISOString();
  };

  const renderDatacalendar = (item: ScheduleConfigEmbedded | null) => {
    return {
      endDate: item?.until,
      amount: item?.interval,
      after: item?.count,
      before: item?.count,
      freq: GET_FREQ[item?.freq || 3],
      repeatWeek:
        GET_REPEAT_WEEK[item?.byweekday ? item?.byweekday[0] : 0]?.title,
    };
  };

  const renderLabel = (item: ScheduleConfigEmbedded) => {
    if (item?.freq === 3 && item?.count === 1 && item?.interval === 1) {
      return 'Not repeat';
    }
    return `Send ${renderHintText(item)}`;
  };

  const renderSendType = (value?: number | null, isHours?: boolean) => {
    if (value) {
      return `${value} ${value > 1
        ? `${isHours ? 'hours' : 'days'}`
        : `${isHours ? 'hour' : 'day'}`
        }`;
    }
    return 'Immediate';
  };

  const specialTime = () => {
    return item?.time
      ? set(new Date(), {
        hours: item.time.hour,
        minutes: item.time.minute,
        seconds: 0,
      })
      : null;
  };

  const isDisplayChoosingTime = () => {
    if (item?.bgColor) {
      return false;
    }
    if (isUpdate) {
      return (
        (item?.after || item?.before) &&
        ((item?.after || item?.before as string) === 'day' ||
          [SpecifiedType.NextMonths, SpecifiedType.NextWeek, SpecifiedType.PriorMonths, SpecifiedType.PriorWeek].includes(
            (sendBefore ? item.before as SpecifiedType : item?.after as SpecifiedType)
          ))
      );
    }
    return (
      (item?.after || item.before) &&
      ([SpecifiedType.NextMonths, SpecifiedType.NextWeek, SpecifiedType.PriorMonths, SpecifiedType.PriorWeek].includes(
        (sendBefore ? item.before as SpecifiedType : item?.after as SpecifiedType),
      ) ||
        (item?.after || item.before as string) === 'day')
    );
  };

  const checkSizeRepeat = () => {
    if (((item?.after || item?.before) as string) === 'day') {
      return 12;
    }
    if (
      [
        SpecifiedType.NextHours,
        SpecifiedType.NextMonths,
        SpecifiedType.NextWeek,
        SpecifiedType.PriorHours,
        SpecifiedType.PriorMonths,
        SpecifiedType.PriorWeek,
      ].includes((item?.after || item?.before) as SpecifiedType) ||
      (!item?.after && item?.numberOfDays !== 0 && isUpdate) || (!item?.before && item?.numberOfDays !== 0 && isUpdate)
    ) {
      return 4;
    }
    return 6;
  };

  useEffect(() => {
    handleChangeDes(watchDes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDes]);

  return (
    <>
      <CustomTime
        isOpenDialog={isOpenDialog}
        setOpenDialogCustom={setOpenDialogCustom}
        sendOn={new Date().toISOString()}
        dataCalendar={renderDatacalendar(item?.custom)}
        setDataCalendar={handleCustomValue}
        setRecurrenceDay={handleChangeRepeat}
        {...dataCalendarProps}
      />
      <TableRow>
        <TableCell>
          <Tooltip title={item?.name}>
            <ChipStyled
              backgroundcolor={
                item?.bgColor ? item?.bgColor : COLOR_BY_TYPE[item?.type]
              }
              key={item._id}
              label={
                <Box display="flex" alignItems="center" gridGap={8}>
                  <Typography variant="subtitle2" style={{ color: '#FF9800' }}>
                    {item?.task || item?.description
                      ? 'Task'
                      : capitalizeFirstLetter(item.type.toLowerCase())}
                  </Typography>
                  <TypoThreeDot variant="subtitle2">{item.name}</TypoThreeDot>
                </Box>
              }
            />
          </Tooltip>
        </TableCell>
        {item && <TableCell>
          {sendingDate ? (
            <Box>
              <TypoThreeDotsLine
                variant="subtitle2"
                color={item?.isSent ? 'textSecondary' : 'textPrimary'}
              >
                {!sendBefore ? `${item?.after && item?.after !== SpecifiedType.NextHours
                  ? SPECIFIED_TYPE[item?.after].title
                  : renderSendType(
                    item?.numberOfDays,
                    !!(item?.after === SpecifiedType.NextHours),
                  )
                  }` : `${item?.before && item?.before !== SpecifiedType.PriorHours
                    ? SPECIFIED_TYPE[item?.before].title
                    : renderSendType(
                      item?.numberOfDays,
                      !!(item?.before === SpecifiedType.PriorHours),
                    )
                  }`}
              </TypoThreeDotsLine>
              <TypographyItalic variant="subtitle2" color="textSecondary">
                {item?.custom && item?.type === TreatmentType.Survey
                  ? renderLabel(item?.custom)
                  : ''}
              </TypographyItalic>
            </Box>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextfieldSelect
                  label="Type"
                  name="type"
                  small
                  value={
                    isUpdate
                      ? (item?.after || item?.before) || item?.numberOfDays === 0
                        ? sendBefore ? (item?.before!) : (item?.after || SpecifiedType.Immediate)
                        : 'day'
                      : sendBefore ? (item.before || SpecifiedType.PriorHours) : (item?.after || SpecifiedType.Immediate)
                  }
                  callbackChangeValue={e => handleChangeAfter(e)}
                  dense
                  disabled={disableField}
                >
                  <MenuItem disabled={sendBefore} value={SpecifiedType.NextWeek}>Next Week</MenuItem>
                  <MenuItem disabled={sendBefore} value={SpecifiedType.NextMonths}>
                    Next Month
                  </MenuItem>
                  <MenuItem disabled={sendBefore} value={SpecifiedType.Immediate}>Immediate</MenuItem>
                  <MenuItem value={sendBefore ? SpecifiedType.PriorHours : SpecifiedType.NextHours}>Hour</MenuItem>
                  <MenuItem value="day">Day</MenuItem>
                  <MenuItem disabled={!sendBefore} value={SpecifiedType.PriorWeek}>Prior Week</MenuItem>
                  <MenuItem disabled={!sendBefore} value={SpecifiedType.PriorMonths}>
                    Prior Month
                  </MenuItem>
                </TextfieldSelect>
              </Grid>
              {((item?.after as string) === 'day' || (item?.before as string) === 'day' ||
                item?.after === SpecifiedType.NextHours || item?.before === SpecifiedType.PriorHours ||
                ((!sendBefore && !item?.after && item?.numberOfDays !== 0 && isUpdate) || (sendBefore && !item?.before && item?.numberOfDays !== 0 && isUpdate))) && (
                  <Grid item xs={2}>
                    <TextField
                      margin="dense"
                      type="number"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label={sendBefore ? "Before" : "After"}
                      size="small"
                      disabled={disableField}
                      inputProps={{
                        min: 1,
                        max: ((!sendBefore && item?.after === SpecifiedType.NextHours) || (sendBefore && item?.before === SpecifiedType.PriorHours)) ? 24 : null,
                      }}
                      defaultValue={(!sendBefore && item?.after === SpecifiedType.NextHours) ? 1 : (sendBefore || item?.before === SpecifiedType.PriorHours) ? 24 : 1}
                      value={item?.numberOfDays}
                      onChange={event => handleChange(event)}
                    />
                  </Grid>
                )}
              {isDisplayChoosingTime() && (
                <Grid
                  xs={
                    item?.type === TreatmentType.Survey
                      ? ((item.after || item.before) as string) === 'day'
                        ? 5
                        : 3
                      : 5
                  }
                  item
                >
                  <KeyboardTimePickerStyled
                    margin="dense"
                    placeholder="hh:mm"
                    inputVariant="outlined"
                    label="Specific Time"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    fullWidth
                    value={specialTime()}
                    onChange={time => changeData('time', time)}
                    variant="inline"
                    keyboardIcon={<AccessTimeIcon />}
                  />
                </Grid>
              )}
              {item?.type === TreatmentType.Survey && (
                <Grid item xs={checkSizeRepeat()}>
                  <TextfieldSelect
                    dense
                    label="Repeat"
                    name="repeat"
                    value={item?.repeat}
                    disabled={disableField}
                    small
                    callbackChangeValue={e => onChangeRepeat(e)}
                    className={checkSizeRepeat() === 12 ? 'mt-0' : ''}
                  >
                    {getOptionTypeRepeat(new Date()?.toISOString()).map(
                      item => {
                        return (
                          <MenuItem key={item.value} value={item.value}>
                            {item.title}
                          </MenuItem>
                        );
                      },
                    )}
                  </TextfieldSelect>
                </Grid>
              )}
              {(item?.task || item?.bgColor) && (
                <Grid
                  item
                  xs={
                    ((item?.after || item?.before) as string) === 'day' ||
                      item?.after === SpecifiedType.NextHours || item?.before === SpecifiedType.PriorHours
                      ? 5
                      : 6
                  }
                >
                  <TextField
                    helperText={
                      !!errors.description ? errors.description.message : ''
                    }
                    InputLabelProps={{ shrink: true }}
                    margin="dense"
                    label="Description"
                    multiline
                    required
                    variant="outlined"
                    fullWidth
                    name="description"
                    error={!!errors.description}
                    inputRef={register({
                      validate: {
                        required: value =>
                          value.trim() !== '' || 'This field is required',
                      },
                    })}
                  // value={item?.description}
                  // onChange={e => handleChangeDes(e.target.value)}
                  />
                </Grid>
              )}

              {item?.repeat === 'custom' && (
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                  <TypographyItalic color="textSecondary" variant="subtitle2">
                    {item?.custom && item?.type === TreatmentType.Survey
                      ? capitalizeFirstLetter(renderHintText(item?.custom))
                      : ''}
                  </TypographyItalic>
                </Grid>
              )}
            </Grid>
          )}
        </TableCell>}
        {sendingDate && (
          <>
            <TableCell>
              <Typography
                color={item?.isSent ? 'textSecondary' : 'textPrimary'}
                variant="subtitle2"
              >
                {item?.sendingDate
                  ? formatDate(item?.sendingDate || '', true, true)
                  : formatDate(renderSendingDate(item), true, true)}
              </Typography>
            </TableCell>
          </>
        )}
        {!isSendingGroup && (
          <TableCell align="right">
            <ButtonDelete
              loadingDelete={false}
              deleteItem={onDelete}
              disabled={disableField}
              id={item._id}
              title={'Are you sure you want to delete this item?'}
              tooltipTitle="Delete"
            />
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

export default React.memo(TableListItem);
