import React, { useMemo, useState } from 'react';
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  MenuItem,
  Box,
} from '@material-ui/core';
import {
  PaperBorder,
  TableFullWidthStyled,
  TypographyItalic,
} from 'share/component_css';
import { GetSurveyResultTaskQuery } from 'types.d';
import { NoDataTable, TextfieldSelect } from 'components';
import { formatDate } from 'share/utils';

type Props = {
  checklists?: GetSurveyResultTaskQuery['getPatientChecklists']['nodes'];
  checkedAll: boolean;
  checkAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkItem: (event: React.ChangeEvent<HTMLInputElement>, item: string) => void;
  checkedList: string[];
  surveyId?: string;
  isCreatePdf?: boolean;
};

enum FilterBy {
  SURVEY = 'survey',
  ALL = 'all',
}

export const TableTask: React.FC<Props> = ({
  checklists,
  checkedAll,
  checkAll,
  checkItem,
  checkedList,
  surveyId,
  isCreatePdf,
}) => {
  const [filterBy, setFilterBy] = useState<FilterBy>(FilterBy.ALL);

  const checklistHasData = useMemo(() => {
    if (checklists) {
      return filterBy === FilterBy.SURVEY
        ? checklists?.filter(item => item.survey?._id === surveyId)
        : checklists;
    }
    return [];
  }, [checklists, filterBy, surveyId]);

  const changeFilterChecklist = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setFilterBy(event.target.value as FilterBy);
  };
  return (
    <>
      <Box
        display="flex"
        my={1}
        alignItems="end"
        justifyContent="space-between"
      >
        <TypographyItalic color="textSecondary" variant="subtitle2">
          {`*Select checklists to ${
            isCreatePdf ? 'create PDF' : 'send to EMR'
          }`}
        </TypographyItalic>
        <TextfieldSelect
          style={{ width: 160 }}
          label="Filter By"
          name="filterBy"
          small
          callbackChangeValue={changeFilterChecklist}
          value={filterBy}
        >
          <MenuItem value={FilterBy.ALL}>All</MenuItem>
          <MenuItem value={FilterBy.SURVEY}>Survey</MenuItem>
        </TextfieldSelect>
      </Box>
      <TableContainer component={PaperBorder}>
        <TableFullWidthStyled stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Checkbox
                  checked={checkedAll}
                  onChange={checkAll}
                  color="primary"
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Treatment</TableCell>
              <TableCell>Updated At</TableCell>
              <TableCell align="right">Survey</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {checklistHasData.length === 0 && <NoDataTable colSpan={4} />}
            {checklistHasData.map(item => {
              return (
                <TableRow key={item.patientChecklists?.patientChecklistId}>
                  <TableCell align="left">
                    <Checkbox
                      onChange={e =>
                        checkItem(e, item.patientChecklists?.patientChecklistId)
                      }
                      color="primary"
                      checked={checkedList.includes(
                        item.patientChecklists?.patientChecklistId,
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    {item?.patientChecklists?.checklist?.name ||
                      item?.patientChecklists?.checklistAvailable?.name ||
                      'N/A'}
                  </TableCell>
                  <TableCell>{item?.treatment?.name || 'N/A'}</TableCell>
                  <TableCell>
                    {item?.patientChecklists?.resultChecklist &&
                    item?.patientChecklists?.resultChecklist?.length !== 0
                      ? formatDate(
                          item?.patientChecklists?.resultChecklist[0]
                            ?.updatedAt,
                          true,
                        )
                      : 'N/A'}
                  </TableCell>

                  <TableCell align="right">
                    {item?.survey?.name || 'N/A'}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </TableFullWidthStyled>
      </TableContainer>
    </>
  );
};
export default React.memo(TableTask);
