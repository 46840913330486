import gql from 'graphql-tag';
import { NotificationFragment, MetaFragment } from './fragments';

export const GET_NOTIFICATION_SURVEY = gql`
  query GetNotifications($params: GetNotificationInput!) {
    getNotifications(params: $params) {
      nodes {
        ...Notification
      }
      meta {
        ...Meta
      }
    }
  }
  ${NotificationFragment}
  ${MetaFragment}
`;

export const GET_UNSEEN__SURVEY_NOTIFICATION = gql`
  query GetUnseenSurveyNotifications(
    $params: GetUnSeenSurveyNotificationInput!
  ) {
    getUnseenSurveyNotifications(params: $params) {
      nodes {
        _id
        owner {
          _id
          firstName
          lastName
        }
        surveyName
        createdAt
        isSeen
      }
      meta {
        ...Meta
      }
    }
  }
  ${MetaFragment}
`;

export const GET_NOTIFICATION_NUMBER = gql`
  query GetNumberOfNotification {
    getNumberOfNotification
  }
`;

export const GET_UNSEEN_NOTIFICATION_COUNT = gql`
  query GetUnseenNotificationsCount {
    getUnseenNotificationsCount
  }
`;

export const MARK_SEEN_NOTIFICATION = gql`
  mutation SeenNotification($params: SeenNotificationInput!) {
    seenNotification(params: $params)
  }
`;

export const MARK_SURVEY_SEEN_NOTIFICATION = gql`
  mutation MarkSurveyNotificationAsSeen($params: MarkNotificationSeenInput!) {
    markSurveyNotificationAsSeen(params: $params)
  }
`;
