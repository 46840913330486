import { useQuery } from '@apollo/react-hooks';
import { ME_CLIENT } from 'gql';
import { addHours, subHours } from 'date-fns';
import {
  PatientTimeReportFragment,
  UserRole,
  PatientCptCodeStatus,
  PatientTimeRecordFragment,
} from 'types.d';
export type PatientTimeReportFragmentExtend = PatientTimeReportFragment & {
  originalStoppedAt: any;
  originalStartedAt: any;
};
export const useTimeRecord = () => {
  const { data: dataMeClient } = useQuery(ME_CLIENT);
  const meClient = { ...dataMeClient.meClient };

  const compareTimezoneOrgLocal = () => {
    const currentDate = new Date();
    const localTimezone = currentDate.getTimezoneOffset() / 60;
    const timezoneOrg: number = meClient.timezone?.offset || 0;
    return {
      isSame: localTimezone === timezoneOrg,
      timezoneOrg:
        timezoneOrg >= 0
          ? `(+${timezoneOrg} GMT)`
          : `(${timezoneOrg.toString()} GMT)`,
    };
  };

  const displayTimezone = () => {
    return `(GMT ${dataMeClient.meClient.timezone.offset})`;
  };
  const isHavingPermissionTimeRecord = (idOwner: string, noAdmin?: boolean) => {
    return noAdmin
      ? idOwner === meClient?._id
      : idOwner === meClient?._id ||
          dataMeClient.meClient?.role === UserRole.Admin;
  };

  const convertDateTimeByTimezone = (date?: string) => {
    const currentDate = date ? new Date(date) : new Date();
    const localTimezone = -(currentDate.getTimezoneOffset() / 60);
    const timezoneOrg: number = meClient.timezone?.offset || 0;
    if (localTimezone === timezoneOrg) {
      return currentDate.toISOString();
    }
    const dateWithoutTimezone =
      localTimezone >= 0
        ? subHours(currentDate, localTimezone)
        : addHours(currentDate, -localTimezone);
    const dateTimezone =
      timezoneOrg >= 0
        ? addHours(new Date(dateWithoutTimezone), timezoneOrg)
        : subHours(new Date(dateWithoutTimezone), -timezoneOrg);
    return dateTimezone.toISOString();
  };

  const convertDateTimeByTimezonecallback = (
    functionFormat: (date: string) => string,
    date?: string,
  ) => {
    const convertedDate = convertDateTimeByTimezone(date);
    return functionFormat(convertedDate);
  };

  const renderDateByTimezone = (date: string) => {
    const { isSame, timezoneOrg } = compareTimezoneOrgLocal();
    return `${date} ${isSame ? '' : timezoneOrg}`;
  };

  const dataByMonth = (data: PatientTimeReportFragment[]) => {
    const listByMonth: {
      [key: string]: PatientTimeReportFragmentExtend[];
    } = data.reduce(
      (report: { [key: string]: PatientTimeReportFragmentExtend[] }, item) => {
        const month = convertDateTimeByTimezone(item.startedAt).substring(0, 7);
        if (!report[month]) {
          report[month] = [];
        }
        report[month].push({
          ...item,
          startedAt: convertDateTimeByTimezone(item.startedAt),
          stoppedAt: convertDateTimeByTimezone(item.stoppedAt),
          originalStoppedAt: item.stoppedAt,
          originalStartedAt: item.startedAt,
        });
        return report;
      },
      {},
    );
    return Object.values(listByMonth);
  };

  const isAbleToDeleteModule = (timeReport: PatientTimeReportFragment[]) => {
    if (timeReport && timeReport.length !== 0) {
      return !timeReport.some(item => {
        return !isHavingPermissionTimeRecord(item.owner.idOwner);
      });
    }
    return true;
  };

  const getRecordOfLoggedUser = (record: PatientTimeRecordFragment[]) => {
    return record.filter(item => {
      return item.timeReport?.some(item => {
        return isHavingPermissionTimeRecord(item.owner.idOwner, true);
      });
    });
  };

  const getLastRecord = (record?: PatientTimeRecordFragment) => {
    const recordOfLoggedUser = record?.timeReport?.find(
      report => report.owner.idOwner === meClient?._id,
    );
    if (recordOfLoggedUser) {
      return recordOfLoggedUser;
    }
    return record?.timeReport?.find(report => {
      return report.state === PatientCptCodeStatus.Stopped;
    });
  };

  return {
    dataByMonth,
    convertDateTimeByTimezone,
    convertDateTimeByTimezonecallback,
    renderDateByTimezone,
    isHavingPermissionTimeRecord,
    isAbleToDeleteModule,
    getLastRecord,
    getRecordOfLoggedUser,
    displayTimezone,
  };
};
