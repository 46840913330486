import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { styled } from '@material-ui/core/styles';
type Props = {
  colSpan?: number;
  numberRow?: number;
};

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
}));

export const LoadingTable: React.FC<Props> = ({ colSpan, numberRow }) => {
  const renderSkeleton = () => {
    const arrElement: any = [];
    for (let i = 0; i < colSpan!; i++) {
      arrElement.push(
        <TableCellStyled key={i}>
          <Skeleton />
        </TableCellStyled>,
      );
    }
    return arrElement;
  };
  return (
    <>
      {Array.from(Array(numberRow ? numberRow : 4).keys()).map(item => {
        return <TableRow key={item}>{renderSkeleton()}</TableRow>;
      })}
    </>
  );
};
export default LoadingTable;
