import gql from 'graphql-tag';
import {
  CommentEdgeFragment,
  GroupCommentsFragment,
  GroupCommentByPatientFragment,
  CommentFragment,
} from './fragments';

export const CREATE_COMMENT = gql`
  mutation CreateComment($params: CreateCommentInput!) {
    createComment(params: $params) {
      ...Comment
    }
  }
  ${CommentFragment}
`;

export const GET_COMMENTS = gql`
  query GetComments($params: GetCommentInput!) {
    getComments(params: $params) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        ...CommentEdge
      }
    }
  }
  ${CommentEdgeFragment}
`;

export const GET_GROUP_COMMENTS = gql`
  query GetGroupComments($params: CursorGetGroupCommentInput!) {
    getGroupComments(params: $params) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...GroupComments
        }
      }
    }
  }
  ${GroupCommentsFragment}
`;

export const GET_GROUP_COMMENTS_BY_PATIENT = gql`
  query GetGroupCommentsByPatient($params: IdInputType!) {
    getGroupCommentsByPatient(params: $params) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...GroupComments
        }
      }
    }
  }
  ${GroupCommentsFragment}
`;

export const GET_UNREAD_ALL_COMMENT = gql`
  query GetUnreadAllComment {
    getUnreadAllComment {
      _id
      unreadCount
    }
  }
`;

export const SEEN_COMMENT = gql`
  mutation SeenComment($params: SeenCommentInput!) {
    seenComment(params: $params)
  }
`;

export const GET_PATIENT_COMMENT = gql`
  query GetPatientComment($params: GetGroupCommentInput!) {
    getUnreadCommentsPatientList(params: $params) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...GroupCommentByPatient
        }
      }
    }
  }
  ${GroupCommentByPatientFragment}
`;

export const GET_ALL_UNREAD_PATIENT_COMMENT = gql`
  query GetAllUnreadPatientComment(
    $params: GetGroupCommentInput! = { isGroupAllInOne: true }
  ) {
    getUnreadCommentsPatientList(params: $params) {
      edges {
        node {
          _id
          unreadCount
          lastCommentAt
        }
      }
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment($params: DeleteCommentInput!) {
    deleteComment(params: $params)
  }
`;
