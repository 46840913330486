import { useState, useEffect } from 'react';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;

  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;

  prompt(): Promise<void>;
}

export const useWebInstallPrompt = (): [any, () => void] => {
  const [
    installPromptEvent,
    setInstallPromptEvent,
  ] = useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const beforeInstallPromptHandler = (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      setInstallPromptEvent(event);
    };
    window.addEventListener('beforeinstallprompt', e =>
      beforeInstallPromptHandler(e as BeforeInstallPromptEvent),
    );

    return () =>
      window.addEventListener('beforeinstallprompt', e =>
        beforeInstallPromptHandler(e as BeforeInstallPromptEvent),
      );
  });

  const handleInstallAccepted = () => {
    // show native prompt
    installPromptEvent?.prompt();

    // decide what to do after the user chooses
    installPromptEvent?.userChoice?.then(choice => {
      // if the user declined, we don't want to show the prompt again
      if (choice.outcome === 'accepted') {
        setInstallPromptEvent(null);
      }
    });
  };
  return [installPromptEvent, handleInstallAccepted];
};
