import React, { useState, useMemo, useContext } from 'react';
import {
  Controller,
  useFieldArray,
  FormContextValues,
  ArrayField,
  Message,
  NestDataObject,
  FieldError,
} from 'react-hook-form';
import {
  Box,
  IconButton,
  Tooltip,
  Grid,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { BookmarkCheck } from 'mdi-material-ui';
import PhoneInput from 'react-phone-input-2';
import AddIcon from '@material-ui/icons/Add';
import { TypographyRedStyled, BoxStyled } from 'share/component_css';
import { ButtonDelete, ButtonLoading, DialogButton } from 'components';
import { useToogleDialog, useMutationCustom } from 'hooks';
import {
  UPDATE_PATIENT_INFO,
  PatientFragment as PatientFragmentGql,
} from 'gql';
import {
  UpdatePatientInfoMutation,
  UpdatePatientInfoMutationVariables,
} from 'types.d';
import { PatientDetailContext } from 'share/context';
import { countryCode } from 'share/utils';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

type FormExtend = {
  phones: Phone[];
};

type FormPhoneComponentProps<T> = {
  methods: any;
  patientPhones?: Phone[];
  fields: Partial<ArrayField<Record<string, any>, 'id'>>[];
  checkValidPhone: (key: string) => Message | string | undefined;
  indexDefault?: number;
  setIndexDefault: React.Dispatch<React.SetStateAction<number | undefined>>;
  removePhones: (index: number) => void;
  addPhone: () => void;
  setIsDisplayPhone: (status: boolean) => void;
};

type PropsOutSide = {
  disabledActions?: boolean;
  isTreatmentDesign?: boolean;
};

export type Phone = {
  phone: string;
  typePhone: string;
  ext: string;
  default: boolean;
  [key: string]: string | boolean;
};

type ButtonUpdatePhoneType = {};

export const useFormPhone = <T extends FormExtend>(
  methods?: FormContextValues<T>,
  patientPhones?: Phone[],
): {
  formPhoneComponentProps: FormPhoneComponentProps<T>;
  FormPhoneComponent: any;
  remove: (index?: number | number[] | undefined) => void;
  isDisplayPhone: boolean;
  setIsDisplayPhone: (status: boolean) => void;
  ButtonUpdatePhone: any;
} => {
  const patientDetailContext = useContext(PatientDetailContext);

  const phones = useMemo(() => {
    if (patientPhones && patientPhones?.length !== 0) {
      return patientPhones;
    }
    if (
      patientDetailContext?.patient.owner?.phones &&
      patientDetailContext?.patient.owner?.phones?.length !== 0
    ) {
      return patientDetailContext?.patient.owner?.phones;
    }
    return [{ phone: '', typePhone: 'mobile', ext: '' }];
  }, [patientDetailContext, patientPhones]);

  const hookMethods = useForm<FormExtend>({
    mode: 'onBlur',
    defaultValues: {
      phones: phones,
    },
  });
  const allMethods = useMemo(() => {
    if (methods) {
      return methods;
    }
    return hookMethods;
  }, [hookMethods, methods]);

  const { control, errors } = allMethods;

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'phones',
  });

  const [indexDefault, setIndexDefault] = useState<number | undefined>(
    patientPhones
      ? patientPhones?.findIndex(item => item.default)
      : patientDetailContext?.patient.owner?.phones?.findIndex(
          item => item.default,
        ) || 0,
  );

  const [isDisplayPhone, setIsDisplayPhone] = useToogleDialog();

  const checkValidPhone = (key: string) => {
    const errPhones = errors?.phones as
      | NestDataObject<Phone, FieldError>[]
      | undefined;
    if (errPhones) {
      const errPhone = errPhones?.find(item => {
        return item && item.phone ? item?.phone?.ref?.name === key : false;
      });
      return errPhone ? errPhone.phone?.message : '';
    }
    return '';
  };

  const addPhone = () => {
    append({ phone: '', typePhone: 'mobile', ext: '' });
  };

  const removePhones = (index: number) => {
    remove(index);
    if (indexDefault !== undefined) {
      if (index < indexDefault) {
        setIndexDefault(indexDefault - 1);
      }
      if (index - indexDefault === 0) {
        setIndexDefault(0);
      }
    }
  };

  const formPhoneComponentProps = {
    methods: allMethods,
    fields,
    addPhone,
    removePhones,
    checkValidPhone,
    indexDefault,
    setIndexDefault,
    setIsDisplayPhone,
  };

  return {
    remove,
    isDisplayPhone,
    setIsDisplayPhone,
    formPhoneComponentProps,
    FormPhoneComponent,
    ButtonUpdatePhone,
  };
};

export const ButtonUpdatePhone: React.FC<FormPhoneComponentProps<any> &
  ButtonUpdatePhoneType> = ({
  methods,
  indexDefault,
  setIsDisplayPhone,
  setIndexDefault,
}) => {
  const patientDetailContext = useContext(PatientDetailContext);

  const { enqueueSnackbar } = useSnackbar();

  const patientDetail = useMemo(() => {
    return patientDetailContext?.patient;
  }, [patientDetailContext]);

  const [updatePatientInfo, { loading, client }] = useMutationCustom<
    UpdatePatientInfoMutation,
    UpdatePatientInfoMutationVariables
  >({
    api: UPDATE_PATIENT_INFO,
    textSuccess: 'Update phones of patient successfully',
    callbackSuccess: data => {
      if (client) {
        client.writeFragment({
          id: `PatientRes:${patientDetail?._id}`,
          fragment: PatientFragmentGql,
          fragmentName: 'Patient',
          data: {
            ...patientDetail,
            owner: {
              ...data.updatePatientInfo,
            },
          },
        });
      }
      setIsDisplayPhone(false);
    },
  });
  const updatePatientPhone = methods.handleSubmit((data: any) => {
    if (indexDefault === -1 || indexDefault === undefined) {
      enqueueSnackbar('Please provide default phone number to receive SMS', {
        variant: 'error',
      });
      return;
    }
    if (indexDefault !== undefined && data.phones) {
      data.phones[indexDefault].default = true;
    }
    updatePatientInfo({
      variables: {
        params: {
          _id: patientDetail?._id,
          phones: data.phones?.filter(Boolean) || [],
        },
      },
    });
  });

  const backToForm = () => {
    setIsDisplayPhone(false);
  };

  return (
    <>
      <ButtonLoading
        callbackClick={backToForm}
        color="default"
        text="Back"
        Icon={<ArrowBackIcon />}
      />
      <DialogButton loading={loading} onClickButton={updatePatientPhone} />
    </>
  );
};

export const FormPhoneComponent: React.FC<FormPhoneComponentProps<any> &
  PropsOutSide> = ({
  methods,
  fields,
  checkValidPhone,
  indexDefault,
  setIndexDefault,
  removePhones,
  addPhone,
  disabledActions,
  isTreatmentDesign,
}) => {
  const watchPhones = methods.watch('phones');

  const { control, register, errors } = methods;

  const optionCountryCode = countryCode();

  const renderPhoneSize = (isTypeWork: boolean) => {
    if (isTreatmentDesign) {
      return 10;
    }
    return isTypeWork ? 4 : 8;
  };

  return (
    <>
      {fields.length !== 0 &&
        fields.map((field, index) => (
          <Grid key={field.id} item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Controller
                  name={`phones[${index}].typePhone`}
                  control={control}
                  defaultValue={field.typePhone}
                  as={
                    <TextField
                      inputRef={register}
                      disabled={disabledActions}
                      defaultValue="mobile"
                      fullWidth
                      label="Type"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      select
                      variant="outlined"
                      SelectProps={{
                        displayEmpty: true,
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        },
                      }}
                    >
                      <MenuItem value="home">Home</MenuItem>
                      <MenuItem value="work">Work</MenuItem>
                      <MenuItem value="mobile">Mobile</MenuItem>
                    </TextField>
                  }
                />
              </Grid>
              <Grid
                item
                xs={8}
                sm={renderPhoneSize(watchPhones[index]?.typePhone === 'work')}
              >
                <BoxStyled
                  error={
                    checkValidPhone(`phones[${index}].phone`) ? 'true' : 'false'
                  }
                >
                  <Controller
                    defaultValue={field.phone}
                    name={`phones[${index}].phone`}
                    control={control}
                    rules={{
                      validate: {
                        required: value =>
                          value !== '' || 'This field is required',
                      },
                      pattern: optionCountryCode.pattern,
                      min: {
                        value: 1,
                        message: 'this field is required',
                      },
                    }}
                    as={
                      <PhoneInput
                        disabled={disabledActions}
                        inputStyle={{
                          color: disabledActions ? '#aaaaaa' : 'black',
                        }}
                        country={'us'}
                        onlyCountries={optionCountryCode.countryCodes}
                        enableAreaCodeStretch
                        containerClass="container-phone"
                        autoFormat={true}
                        specialLabel="Phone "
                        disableDropdown={
                          !(process.env.REACT_APP_HAS_VN_CODE === 'true')
                        }
                        countryCodeEditable={true}
                      />
                    }
                  />
                  {checkValidPhone(`phones[${index}].phone`) && (
                    <TypographyRedStyled variant="caption" display="block">
                      {checkValidPhone(`phones[${index}].phone`)}
                    </TypographyRedStyled>
                  )}
                </BoxStyled>
              </Grid>
              {watchPhones[index]?.typePhone &&
                watchPhones[index]?.typePhone === 'work' && (
                  <Grid item xs={8} sm={4}>
                    <Controller
                      defaultValue={field.ext}
                      name={`phones[${index}].ext`}
                      control={control}
                      rules={{
                        pattern: {
                          value: /^[0-9]*$/,
                          message: 'This field must be a number',
                        },
                      }}
                      as={
                        <TextField
                          fullWidth
                          label="Extension"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          disabled={disabledActions}
                          error={
                            errors?.phones
                              ? !!errors?.phones[index]?.ext
                              : false
                          }
                          helperText={
                            errors?.phones
                              ? errors?.phones[index]?.ext
                                ? errors.phones[index].ext?.message
                                : ''
                              : ''
                          }
                          inputProps={{ maxLength: 4 }}
                        />
                      }
                    />
                  </Grid>
                )}
              {!disabledActions && (
                <Grid item xs={4} sm={2}>
                  <Box display="flex" justifyContent="center">
                    <Tooltip
                      arrow
                      title={
                        index === indexDefault
                          ? 'Default phone'
                          : 'Set as default phone'
                      }
                    >
                      <IconButton
                        aria-label=""
                        onClick={() => setIndexDefault(index)}
                        color="primary"
                      >
                        <BookmarkCheck
                          color={
                            index === indexDefault ? 'primary' : 'disabled'
                          }
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                    <ButtonDelete
                      id={index.toString()}
                      deleteItem={() => removePhones(index)}
                      loadingDelete={false}
                      idSelected=""
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
      {!disabledActions && (
        <Grid item xs={12}>
          <ButtonLoading
            callbackClick={addPhone}
            text="Phone"
            Icon={<AddIcon />}
          />
        </Grid>
      )}
    </>
  );
};
