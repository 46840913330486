import { useState, useCallback } from 'react';

export const useToogleDialog = (): [boolean, (status: boolean) => void] => {
  const [isOpen, setIsOpen] = useState(false);

  const toogleDialog = useCallback((status: boolean) => {
    setIsOpen(status);
  }, []);

  return [isOpen, toogleDialog];
};
