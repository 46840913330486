import React, { useContext, useCallback, useMemo } from 'react';
import {
  GetCurrentStageQuery,
  GetCurrentStageQueryVariables,
  GetStageChangedLogsQuery,
  GetStageChangedLogsQueryVariables,
} from 'types.d';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { TableContainerForStageStyled } from '../../styles';
import { GET_STAGE_CHANGELOGS, GET_CURRENT_STAGE } from 'gql';
import { PatientDetailContext, PatientStageContext } from 'share/context';
import { NoDataTable, LoadingTable } from 'components';
import { PaperBorder, TableContainerStyled } from 'share/component_css';
import CurrentStage from './CurrentStage';
import StageChangeLog from './StageChangeLog';
import {
  usePagination,
  InfoPagination,
  useQueryCustom,
  useUpdateMeClient,
} from 'hooks';

type Props = {};

export const Stages: React.FC<Props> = () => {
  const patientDetailContext = useContext(PatientDetailContext);

  const callbackFetchData = (infoPagination: InfoPagination) => {
    fetchMoreStageChangeLog(infoPagination);
  };

  const { meClient } = useUpdateMeClient();

  const {
    infoPagination,
    DefaultPagination,
    defaultPaginationProps,
    handleSetInfoPagination,
  } = usePagination(callbackFetchData);

  const {
    data: dataCurrentStage,
    loading: loadingCurrentStage,
  } = useQueryCustom<GetCurrentStageQuery, GetCurrentStageQueryVariables>({
    api: GET_CURRENT_STAGE,
    variables: {
      params: {
        patientId: patientDetailContext?.patient.owner?.idOwner,
      },
    },
  });

  const {
    data: dataChangeLog,
    loading: loadingChangeLog,
    fetchMore,
  } = useQueryCustom<
    GetStageChangedLogsQuery,
    GetStageChangedLogsQueryVariables
  >({
    api: GET_STAGE_CHANGELOGS,
    variables: {
      params: {
        page: 1,
        limit: meClient.setting?.perPage || 5,
        patientId: patientDetailContext?.patient.owner?.idOwner,
      },
    },
  });

  const fetchMoreStageChangeLog = useCallback(
    ({ page, limit }: InfoPagination) => {
      fetchMore({
        variables: {
          params: {
            page,
            limit,
            patientId: patientDetailContext?.patient.owner?.idOwner,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            getStageChangedLogs: {
              ...prev.getStageChangedLogs,
              nodes: fetchMoreResult!.getStageChangedLogs!.nodes!,
              meta: fetchMoreResult!.getStageChangedLogs!.meta!,
            },
          };
        },
      });
    },
    [fetchMore, patientDetailContext],
  );

  const refreshStageChangeLog = () => {
    handleSetInfoPagination({ page: 1, limit: infoPagination.limit }, true);
  };

  const isInactive = useMemo(() => {
    return patientDetailContext?.isInactive;
  }, [patientDetailContext]);
  return (
    <>
      <PatientStageContext.Provider value={{ refreshStageChangeLog }}>
        <PaperBorder className="position-relative p-16">
          <Typography
            className="mt-8"
            style={{ marginBottom: 12 }}
            variant="h6"
          >
            Current Stage
          </Typography>
          <TableContainerForStageStyled
            className="mt-4"
            component={PaperBorder}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Root Treatment</TableCell>
                  <TableCell>Assigned Treatment</TableCell>
                  <TableCell>Stage</TableCell>
                  <TableCell align={!isInactive ? 'inherit' : 'right'}>
                    Status
                  </TableCell>
                  {!isInactive && <TableCell align="right">Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {!loadingCurrentStage &&
                  dataCurrentStage?.getCurrentStage?.length === 0 && (
                    <NoDataTable colSpan={isInactive ? 4 : 5} />
                  )}
                {loadingCurrentStage && (
                  <LoadingTable colSpan={isInactive ? 4 : 5} />
                )}
                {!loadingCurrentStage &&
                  dataCurrentStage?.getCurrentStage.map((item, index) => {
                    return <CurrentStage key={item._id} item={item} />;
                  })}
              </TableBody>
            </Table>
          </TableContainerForStageStyled>
        </PaperBorder>

        <PaperBorder className="position-relative mt-16 mb-16 p-16">
          <Typography className="mt-8 mb-8" variant="h6">
            Stage Change Log
          </Typography>
          <TableContainerStyled component={PaperBorder}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Root Treatment</TableCell>
                  <TableCell>Subtreatment</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Stage</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell align={!isInactive ? 'inherit' : 'right'}>
                    End Date
                  </TableCell>
                  {!isInactive && <TableCell align="right">Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {!loadingChangeLog &&
                  dataChangeLog?.getStageChangedLogs?.nodes.length === 0 && (
                    <NoDataTable colSpan={isInactive ? 6 : 7} />
                  )}
                {loadingChangeLog && (
                  <LoadingTable colSpan={isInactive ? 6 : 7} />
                )}
                {!loadingChangeLog &&
                  dataChangeLog?.getStageChangedLogs.nodes.map(stage => {
                    return <StageChangeLog key={stage._id} stage={stage} />;
                  })}
              </TableBody>
            </Table>
          </TableContainerStyled>
          {dataChangeLog?.getStageChangedLogs?.nodes?.length !== 0 && (
            <DefaultPagination
              loading={loadingChangeLog}
              {...defaultPaginationProps}
              count={dataChangeLog?.getStageChangedLogs?.meta?.total || 0}
            />
          )}
        </PaperBorder>
      </PatientStageContext.Provider>
    </>
  );
};

export default React.memo(Stages);
