import React, { useContext } from 'react';
import CommentIcon from '@material-ui/icons/Comment';
import {
  GET_PATIENT_COMMENT,
  UNREAD_COMMENTS_PATIENT_LIST_SUBCRIPTION,
} from 'gql';
import {
  GetPatientCommentQuery,
  GetPatientCommentQueryVariables,
  OnUnreadCommentsPatientListSubscription,
  OnUnreadCommentsPatientListSubscriptionVariables,
} from 'types.d';
import { useQueryCustom, useCheckLogin } from 'hooks';
import { PatientDetailContext } from 'share/context';
import Badge from '@material-ui/core/Badge';
import { useSubscription } from '@apollo/react-hooks';
type Props = {};

export const IconUnReadQuestion: React.FC<Props> = () => {
  const patientDetailContext = useContext(PatientDetailContext);

  const isLogin = useCheckLogin();

  //get unread from each patient
  const { data: dataUnreadFromPatient } = useQueryCustom<
    GetPatientCommentQuery,
    GetPatientCommentQueryVariables
  >({
    api: GET_PATIENT_COMMENT,
    variables: {
      params: {
        patientId: patientDetailContext?.patient.owner?.user?.idUser,
      },
    },
  });

  //subcription for each patient when logging user seen comment
  useSubscription<
    OnUnreadCommentsPatientListSubscription,
    OnUnreadCommentsPatientListSubscriptionVariables
  >(UNREAD_COMMENTS_PATIENT_LIST_SUBCRIPTION, {
    variables: {
      patientId: patientDetailContext?.patient.owner?.user?.idUser,
    },
    skip: !isLogin || !patientDetailContext?.patient.owner?.user?.idUser,
  });

  return (
    <>
      {!!dataUnreadFromPatient?.getUnreadCommentsPatientList?.edges[0]?.node
        ?.unreadCount ? (
        <Badge
          badgeContent={
            dataUnreadFromPatient?.getUnreadCommentsPatientList?.edges[0]?.node
              ?.unreadCount || 0
          }
          color="secondary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <CommentIcon color="action" />
        </Badge>
      ) : (
        ''
      )}
    </>
  );
};

export default React.memo(IconUnReadQuestion);
