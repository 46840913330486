import gql from 'graphql-tag';
import {
  CommentFragment,
  GroupCommentsFragment,
  GroupCommentByPatientFragment,
  MetaFragment,
} from './fragments';
export const TREATMENT_COMMENT_SUBCRIPTION = gql`
  subscription onTreatmentComment($topic: String!, $patientId: ObjectId) {
    treatmentCommentSubscription(topic: $topic, patientId: $patientId) {
      ...Comment
    }
  }
  ${CommentFragment}
`;

export const NEW_SURVEY_RESULT_NOTIFICATION_SUBCRIPTION = gql`
  subscription onNewSurveyResultNotification {
    newSurveyResultNotification {
      _id
      owner {
        _id
        firstName
        lastName
      }
      surveyName
      createdAt
      isSeen
    }
  }
`;

export const UNREAD_COMMENTS_SUBCRIPTION = gql`
  subscription onUnreadComments($patientId: ObjectId) {
    unreadCommentsSubscription(patientId: $patientId) {
      edges {
        node {
          ...GroupComments
        }
      }
    }
  }
  ${GroupCommentsFragment}
`;

export const UNREAD_ALL_COMMENTS_SUBCRIPTION = gql`
  subscription onUnreadAllComments {
    unreadAllCommentsSubscription {
      _id
      unreadCount
    }
  }
`;

export const UNREAD_COMMENTS_PATIENT_LIST_SUBCRIPTION = gql`
  subscription onUnreadCommentsPatientList($patientId: ObjectId) {
    unreadCommentsPatientListSubscription(patientId: $patientId) {
      edges {
        node {
          ...GroupCommentByPatient
        }
      }
    }
  }
  ${GroupCommentByPatientFragment}
`;

export const UNREAD_ALL_COMMENTS_BY_PATIENTS = gql`
  subscription onUnreadAllCommentsByPatients {
    unreadAllCommentsByPatientsSubscription {
      edges {
        node {
          _id
          unreadCount
          lastCommentAt
        }
      }
    }
  }
`;

export const LOGIN_OTHER_DEVICE = gql`
  subscription loginOtherDevice {
    loginOtherDevice
  }
`;
