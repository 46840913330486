import { Tooltip, Typography, Box } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import {
  BoxWrapFilterTable,
  TypoThreeDotsLine,
} from 'share/component_css';
import {
  InfoPagination,
  Phone,
  useUpdateMeClient,
} from 'hooks';
import {
  GetPatientsQuery,
  GetPatientsQueryVariables,
  Gender,
} from 'types.d';
import { GET_PATIENTS } from 'gql';
import { usePagination } from 'hooks';
import { DefaultTable } from 'components';
import { LIST_FILTER_PATIENT, LIST_GENDER } from 'CONST';
import ActionPatientProcedures from './components/ActionPatientProcedures';
import { useLazyQuery } from '@apollo/react-hooks';

type Props = {};

export const PatientProcedures: React.FC<Props> = () => {

  const { meClient } = useUpdateMeClient();

  const getNameOrder = useCallback((sort: string): {
    [key: string]: number;
  } => {
    const keySort = [
      'surveyCompleteDate',
      'createdAt',
      'surveyCompleteDate',
      'followUpDate',
      'dob',
    ];
    return {
      [LIST_FILTER_PATIENT[sort].key]: keySort.includes(sort) ? -1 : 1,
    };
  }, []);

  useEffect(() => {
    getListPatient();
  }, []);


  const [getListPatient, { loading: loadingPatients, data: listPatient, fetchMore }] =
    useLazyQuery<GetPatientsQuery, GetPatientsQueryVariables>(GET_PATIENTS,
      {
        fetchPolicy: 'cache-and-network',
        variables: {
          params: {
            page: 1,
            limit: meClient.setting?.perPage || 5,
            order: meClient.setting?.patientDashboard?.sort
              ? getNameOrder(meClient.setting?.patientDashboard?.sort)
              : getNameOrder('createdAt'),
          },
        },
        onCompleted: data => {

        },
      });

  const getMorePatients = useCallback(
    ({ page, limit }: InfoPagination, sortBy?: string) => {
      fetchMore({
        variables: {
          params: {
            order: meClient.setting?.patientDashboard?.sort
              ? getNameOrder(meClient.setting?.patientDashboard?.sort)
              : getNameOrder('createdAt'),
            page,
            limit,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            getPatients: {
              ...prev.getPatients,
              nodes: [...fetchMoreResult!.getPatients!.nodes!],
              meta: {
                ...fetchMoreResult!.getPatients!.meta!,
              },
            },
          };
        },
      });
    },
    [
      fetchMore,
      getNameOrder,
    ],
  );


  const callbackFetchData = useCallback(
    ({ page, limit }: InfoPagination) => {
      getMorePatients({ page, limit });
    },
    [getMorePatients],
  );

  const {
    DefaultPagination,
    defaultPaginationProps,
  } = usePagination(callbackFetchData);

  return (
    <>
      <BoxWrapFilterTable>
        <Box display="flex" alignItems="center" gridGap={8}>
          <Typography className="font-weight-bold" variant="h5">
            Patient Procedures Dashboard
          </Typography>
          {/* <Tooltip title={
            <Box display="flex" flexDirection="column">
              <Typography variant="subtitle2">
                This dashboard handles for following procedures:
              </Typography>
              {
                generateInformation(PROCEDURES)
              }
            </Box>
          }>
            <InfoIcon color="disabled" />
          </Tooltip> */}
        </Box>
      </BoxWrapFilterTable>
      <DefaultTable
        heads={[
          { text: 'Name', width: '20%' },
          { text: 'Email', width: '20%' },
          { text: 'Phone', width: '20%' },
          { text: 'Gender', width: '15%' },
          { text: 'Status', width: '15%' },
          { text: 'Actions' },
        ]}
        loading={loadingPatients}
        content={
          listPatient?.getPatients.nodes.map(row => {
            return {
              key: row._id,
              cells: [
                <TypoThreeDotsLine variant="subtitle2">
                  {`${row?.owner?.firstName || ''} ${row?.owner?.middleName ||
                    ''} ${row?.owner?.lastName || ''}`}
                </TypoThreeDotsLine>,
                <TypoThreeDotsLine variant="subtitle2">
                  {row?.owner?.email ? row?.owner?.email : 'N/A'}
                </TypoThreeDotsLine>,
                <TypoThreeDotsLine variant="subtitle2">
                  {row?.owner?.phones?.length == 0 ? 'N/A' : (row?.owner?.phones as Phone[])?.find(
                    item => item.default,
                  )?.phone}
                </TypoThreeDotsLine>,
                <TypoThreeDotsLine variant="subtitle2">
                  {LIST_GENDER[row?.owner?.gender as Gender] || 'N/A'}
                </TypoThreeDotsLine>,
                <TypoThreeDotsLine variant="subtitle2">
                  {row?.owner?.user?.status}
                </TypoThreeDotsLine>,
                <ActionPatientProcedures
                  patient={row}
                />,
              ],
            };
          }) || []
        }
      />

      {listPatient && listPatient.getPatients.nodes?.length !== 0 && (
        <DefaultPagination
          loading={loadingPatients}
          {...defaultPaginationProps}
          count={listPatient.getPatients.meta?.total || 0}
        />
      )}
    </>
  );
};
export default PatientProcedures;

