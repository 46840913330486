/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState } from 'react';
import { styled } from '@material-ui/core/styles';
import {
  Avatar,
  Badge,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
  GET_UNSEEN__SURVEY_NOTIFICATION,
  NEW_SURVEY_RESULT_NOTIFICATION_SUBCRIPTION,
  MARK_SURVEY_SEEN_NOTIFICATION,
  GET_UNSEEN_NOTIFICATION_COUNT,
} from 'gql';
import {
  useMutationCustom,
  useQueryCustom,
  usePopover,
  useToogleDialog,
} from 'hooks';
import {
  GetUnseenSurveyNotifications,
  GetUnseenSurveyNotificationsQueryVariables,
  onNewSurveyResultNotificationSubscription,
  NavigatorNotificationFragment,
  SurveyNotificationMutation,
  SurveyNotificationMutationVariables,
  GetUnseenNotificationsCountQuery,
  GetUnseenNotificationsCountQueryVariables,
} from 'types.d';
import LoadingNotification from './LoadingNotification';
import { useLazyQuery, useQuery, useSubscription } from '@apollo/react-hooks';
import NavigatorNotificationItem from './NavigatorNotificationItem';
import { DialogInfoPatient } from 'modules/comments/components/DialogInfomationPatient';
import { useSnackbar } from 'notistack';

type Props = {};

const ListStyled = styled(List)(({ theme }) => ({
  '@media screen and (min-width:500px)': {
    width: theme.spacing(50),
  },
}));

const NotificationWrapper = styled('div')(({ theme }) => ({
  maxHeight: theme.spacing(50),
  overflowY: 'scroll',
  '@media screen and (min-width:500px)': {
    width: theme.spacing(50),
    '&::-webkit-scrollbar': {
      width: theme.spacing(1),
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  color: theme.palette.primary.dark,
  background: 'none',
  overflow: 'visible',
  cursor: 'pointer',
  margin: theme.spacing(0, 1),
}));

export const NavigatorNotificationDropdown: React.FC<Props> = () => {
  const patientOpenDialog = useRef('');

  const { enqueueSnackbar } = useSnackbar();

  const [open, close, DefaultPopover, defaultPopoverProps] = usePopover();

  const [isOpenDialogPatient, setIsOpenDialogPatient] = useToogleDialog();

  const [unreadNotifications, setUnreadNotifications] = useState<number | null>(
    null,
  );

  const [notifications, setNotifications] = useState<
    NavigatorNotificationFragment[]
  >([]);

  useQuery<
    GetUnseenNotificationsCountQuery,
    GetUnseenNotificationsCountQueryVariables
  >(GET_UNSEEN_NOTIFICATION_COUNT, {
    onCompleted: data =>
      setUnreadNotifications(data?.getUnseenNotificationsCount),
  });

  const [loadNotifications, { data, loading }] = useLazyQuery<
    GetUnseenSurveyNotifications,
    GetUnseenSurveyNotificationsQueryVariables
  >(GET_UNSEEN__SURVEY_NOTIFICATION, {
    variables: {
      params: {
        limit: 40,
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: data =>
      setNotifications(data?.getUnseenSurveyNotifications?.nodes),
  });

  const [seenNotification] = useMutationCustom<
    SurveyNotificationMutation,
    SurveyNotificationMutationVariables
  >({
    api: MARK_SURVEY_SEEN_NOTIFICATION,
  });

  useSubscription<onNewSurveyResultNotificationSubscription>(
    NEW_SURVEY_RESULT_NOTIFICATION_SUBCRIPTION,
    {
      onSubscriptionData: data => {
        const notificationData =
          data?.subscriptionData?.data?.newSurveyResultNotification;
        const owner = notificationData?.owner;

        enqueueSnackbar(
          `${owner?.firstName} ${owner?.lastName} completed ${notificationData?.surveyName}`,
          {
            variant: 'success',
          },
        );

        if (notificationData) {
          unreadNotifications
            ? setUnreadNotifications(unreadNotifications + 1)
            : setUnreadNotifications(1);

          setNotifications([notificationData, ...notifications]);
        }
      },
    },
  );

  const markAsSeen = (unseenId: string) => {
    const updatedNotifications = [...notifications];

    const index = notifications.findIndex(
      notification => notification._id === unseenId,
    );

    if (!updatedNotifications[index].isSeen) {
      seenNotification({
        variables: {
          params: {
            notificationId: unseenId,
          },
        },
      }).then(() => {
        updatedNotifications[index]['isSeen'] = true;

        if (unreadNotifications && unreadNotifications > 0)
          setUnreadNotifications(unreadNotifications - 1);

        setNotifications(updatedNotifications);
      });
    }
  };

  const openInfomationPatient = (
    e: any,
    patientId: string,
    notificationId: string,
  ) => {
    e.stopPropagation();

    markAsSeen(notificationId);

    patientOpenDialog.current = patientId;
    setIsOpenDialogPatient(true);

    close();
  };

  return (
    <>
      <DialogInfoPatient
        patientSelected={patientOpenDialog.current}
        open={isOpenDialogPatient}
        toogleDialog={setIsOpenDialogPatient}
      />
      <DefaultPopover {...defaultPopoverProps}>
        <Box boxShadow={3} borderRadius={5} padding={1}>
          <ListStyled
            subheader={
              <ListSubheader
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                }}
              >
                Notifications
              </ListSubheader>
            }
          >
            <NotificationWrapper>
              {loading ? (
                <LoadingNotification />
              ) : notifications && notifications.length ? (
                notifications.map(notification => (
                  <NavigatorNotificationItem
                    notification={notification}
                    openInfomationPatient={openInfomationPatient}
                  />
                ))
              ) : (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <NotificationsIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText secondary="You have no notification." />
                </ListItem>
              )}
            </NotificationWrapper>
          </ListStyled>
        </Box>
      </DefaultPopover>

      <AvatarStyled
        onClick={e => {
          open(e);
          if (!data) {
            loadNotifications();
          }
        }}
        aria-haspopup="true"
        aria-controls="dropdown-notification"
      >
        <Badge badgeContent={unreadNotifications || null} color="primary">
          <NotificationsIcon />
        </Badge>
      </AvatarStyled>
    </>
  );
};

export default React.memo(NavigatorNotificationDropdown);
