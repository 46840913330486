import React, { useState, useCallback, useContext } from 'react';
import { Typography, Box, Grid, TextField, MenuItem } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { styled, Theme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import ReactPlayer from 'react-player';
import { useMutation } from '@apollo/react-hooks';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import grey from '@material-ui/core/colors/grey';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { MediaContext } from 'share/context';
import { ButtonLoading } from 'components';
import {
  ImageType,
  UploadFileMutation,
  UploadFileMutationVariables,
} from 'types.d';
import { handleError } from 'share/utils';
import { UPLOAD_FILE } from 'gql';
import {
  LIST_MEDIA_TYPE,
  MAX_FILE_SIZE_IMAGE,
  MAX_FILE_SIZE_VIDEO,
} from 'CONST';
import Cropper from 'react-easy-crop';

const BoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: grey[200],
  border: `1px dotted ${grey[300]}`,
}));
const BoxWrapMediaStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
}));

const BoxMediaStyled = styled(Box)(
  ({ theme, src }: { theme: Theme; src: string | null }) => ({
    backgroundImage: `url(${src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor: theme.palette.common.black,
    backgroundSize: 'contain',
  }),
);

interface Props {
  closeDialog?: (isRefetch?: boolean) => void;
  ratio?: string;
  setValueTab?: (value: number) => void;
}

const IMAGE_TYPE = [
  { value: 'free', label: 'Free Image' },
  { value: 'main', label: 'Main Image' },
  { value: 'banner', label: 'Banner Image' },
];

export const BoxUploadMedia: React.FC<Props> = ({
  closeDialog,
  ratio,
  setValueTab,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const mediaContext = useContext(MediaContext);

  const [zoom, setZoom] = useState<number>(1);

  const [imageType, setImageType] = useState<string>('free');

  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  const [infoFileUpload, setInfoFileUpload] = useState<{
    src: string;
    type: string;
    file: UploadFileMutationVariables['file'];
    name?: string;
  } | null>(null);

  const [uploadFile, { loading }] = useMutation<UploadFileMutation>(
    UPLOAD_FILE,
    {
      onCompleted(data) {
        enqueueSnackbar('Upload file successfully', { variant: 'success' });
        if (closeDialog) {
          closeDialog(true);
        }
        if (setValueTab) {
          setValueTab(0);
        }
        setInfoFileUpload(null);
      },
      onError(error) {
        const arrError = handleError(error.graphQLErrors[0]!);
        enqueueSnackbar(arrError.join(', '), { variant: 'error' });
      },
    },
  );

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length !== 0) {
        const typeFile = rejectedFiles[0].type.split('/').shift();
        const errorText = mediaContext?.type
          ? `File type must be ${
              typeFile === 'image' ? 'mp4' : 'png, jpeg, jpg'
            }`
          : ' File type must be mp4, png, jpeg, jpg';
        enqueueSnackbar(errorText, {
          variant: 'error',
        });
        return;
      } else {
        const typeFile = acceptedFiles[0].type.split('/').shift();
        if (
          typeFile === 'image' &&
          acceptedFiles[0].size > MAX_FILE_SIZE_IMAGE
        ) {
          enqueueSnackbar('Image size must be less than 5mb', {
            variant: 'error',
          });
          return;
        }
        if (
          typeFile === 'video' &&
          acceptedFiles[0].size > MAX_FILE_SIZE_VIDEO
        ) {
          enqueueSnackbar('Video size must be less than 100mb', {
            variant: 'error',
          });
          return;
        }
      }
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = function(e) {
        setInfoFileUpload({
          src: reader.result as string,
          type: acceptedFiles[0].type.split('/').shift(),
          file: acceptedFiles[0],
        });
      };
    },
    [enqueueSnackbar, mediaContext],
  );

  const setFileAccepted = () => {
    if (mediaContext) {
      return Object.is(mediaContext!.type, 'image')
        ? ['.png', '.jpeg', '.jpg']
        : '.mp4';
    }
    return ['.png', '.jpeg', '.jpg', '.mp4'];
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: setFileAccepted(),
  });

  // const handleUploadFile = () => {
  //   if (!infoFileUpload!.name) {
  //     enqueueSnackbar('You must fill name of file', {
  //       variant: 'error',
  //     });
  //     return;
  //   } else {
  //     uploadFile({
  //       variables: {
  //         file: infoFileUpload!.file,
  //         params: { name: infoFileUpload!.name },
  //       },
  //     });
  //   }
  // };

  const handleUploadFile = () => {
    if (!infoFileUpload || !croppedImage) {
      enqueueSnackbar('No image selected or cropped image data missing', {
        variant: 'error',
      });
      return;
    }

    if (!infoFileUpload.name) {
      enqueueSnackbar('You must fill in the name of the file', {
        variant: 'error',
      });
      return;
    }

    uploadFile({
      variables: {
        file: getImageFile(),
        params: { name: infoFileUpload.name },
      },
    });
  };

  const getImageFile = () => {
    if (infoFileUpload && croppedImage) {
      // Convert base64 to Blob
      const base64Data = croppedImage.split(',')[1]; // Remove base64 header
      const contentType = 'image/jpeg'; // Assuming the cropped image is jpeg
      const byteCharacters = atob(base64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, {
        type: contentType,
      });

      if (infoFileUpload.name) {
        const croppedFile = new File([blob], infoFileUpload.name, {
          type: contentType,
        });

        return croppedFile;
      }
    }
  };

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    getCroppedImg(croppedAreaPixels);
  };

  const getCroppedImg = async (pixelCrop: any) => {
    if (infoFileUpload) {
      const image = new Image();
      image.src = infoFileUpload?.src;
      await new Promise(resolve => {
        image.onload = resolve;
      });

      const canvas = document.createElement('canvas');
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height,
        );

        setCroppedImage(canvas.toDataURL('image/jpeg'));
      }
    }
  };

  return (
    <>
      <BoxStyled width="100%" paddingTop={ratio ?? '75%'} position="relative">
        {!infoFileUpload && (
          <div className="outline-none" {...getRootProps()}>
            <input {...getInputProps()} />
            <Box
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography>Drop files here</Typography>
              <Typography>or</Typography>
              <ButtonLoading
                Icon={<InsertDriveFileIcon />}
                text="Select files"
                color="default"
              />
            </Box>
          </div>
        )}
        {infoFileUpload && (
          <BoxWrapMediaStyled
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
          >
            {infoFileUpload.type === 'image' && (
              <>
                {imageType === 'free' ? (
                  <BoxMediaStyled
                    width="100%"
                    height="100%"
                    src={infoFileUpload.src}
                  />
                ) : (
                  <Cropper
                    image={infoFileUpload.src}
                    crop={crop}
                    zoom={zoom}
                    aspect={imageType === 'banner' ? 88 / 25 : 16 / 9}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                )}
              </>
            )}
            {infoFileUpload.type === 'video' && (
              <ReactPlayer
                controls
                url={infoFileUpload.src as string}
                width="100%"
                height="100%"
                muted
                config={{
                  file: {
                    attributes: {
                      muted: true,
                    },
                  },
                }}
              />
            )}
          </BoxWrapMediaStyled>
        )}
      </BoxStyled>
      {infoFileUpload && (
        <Box width="100%" mt={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                onChange={e =>
                  setInfoFileUpload({
                    ...infoFileUpload,
                    name: e.target.value.trim(),
                  })
                }
                className="mr-8"
                label="File Name"
                variant="outlined"
                disabled={loading}
                fullWidth
                inputProps={{ maxLength: 255 }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                size="small"
                fullWidth
                label="Image Type"
                InputLabelProps={{ shrink: true }}
                select
                variant="outlined"
                SelectProps={{
                  displayEmpty: true,
                }}
                disabled={infoFileUpload.type !== 'image'}
                defaultValue="all"
                value={imageType}
                onChange={e => setImageType(e.target.value)}
              >
                {IMAGE_TYPE.map(item => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={5} sm={3} md={3}>
              <ButtonLoading
                text="Upload"
                callbackClick={handleUploadFile}
                Icon={<CloudUploadIcon />}
                loading={loading}
                className="mr-8 h-100"
                fullWidth
              />
            </Grid>
            <Grid item xs={5} sm={3} md={3}>
              <ButtonLoading
                fullWidth
                text="Remove"
                callbackClick={() => setInfoFileUpload(null)}
                Icon={<DeleteIcon />}
                color="secondary"
                disabled={loading}
                className="mr-8 h-100"
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default BoxUploadMedia;
