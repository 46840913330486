import React, { useContext } from 'react';
import { Dialog, DialogActions, Box, DialogContent } from '@material-ui/core';
import { styled, Theme } from '@material-ui/core/styles';
import { ButtonCopyLink, ButtonLoading } from 'components';
import { PopoverContext } from 'share/context';
import DialogShareAssignTreatment from './DialogShareAssignTreatment';
import { useToogleDialog } from 'hooks';
import PrintIcon from '@material-ui/icons/Print';
import { TypoThreeDot } from 'share/component_css';
import ButtonSendEmail from './ButtonSendEmail';
import ButtonSendSMS from './ButtonSendSMS';

type Props = {
  open: string;
  setOpenModal: React.Dispatch<React.SetStateAction<string>>;
  link: string;
  urlSharing?: string | null | undefined;
  idTreatment?: string | null;
  smsMessage?: string;
  close?: () => void;
  nameTreatment?: string | null;
};

const ViewQrCodeStyled = styled('div')(
  ({ src, theme }: { src: string; theme: Theme }) => ({
    backgroundImage: `url(${src})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.common.black,
  }),
);

const DialogStyled = styled(Dialog)({
  '& .MuiPaper-root': {
    maxWidth: '500px',
  },
});

const DialogContentStyled = styled(DialogContent)({
  padding: '10px 100px',
});

const DialogActionStyled = styled(DialogActions)({
  padding: '24px',
  gap: '8px',
});

const WrapImageQrCode = styled(Box)({
  position: 'relative',
  width: '100%',
  paddingTop: '100%',
});

export const ViewQrCode: React.FC<Props> = ({
  open,
  setOpenModal,
  link,
  urlSharing,
  idTreatment,
  close,
  smsMessage,
  nameTreatment,
}) => {
  const [isOpen, toogleDialog] = useToogleDialog();

  const popoverContext = useContext(PopoverContext);

  const handleClose = () => {
    setOpenModal('');
    if (close) {
      close();
    }
  };

  return (
    <>
      {isOpen && (
        <DialogShareAssignTreatment
          open={isOpen}
          setOpenModal={toogleDialog}
          idTreatment={idTreatment}
          smsMessage={smsMessage}
        />
      )}
      <DialogStyled
        open={open === 'qrCode'}
        onClose={handleClose}
        fullWidth={true}
        onExited={() => {
          popoverContext?.closePopover();
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gridGap={8}
          p="16px 16px 0px 16px"
        >
          <TypoThreeDot align="center" variant="h6">
            {nameTreatment}
          </TypoThreeDot>
          <div id="append-view-qrcode">
            <ButtonCopyLink
              hasElemAppend="append-view-qrcode"
              link={urlSharing as string}
            />
          </div>
        </Box>
        <DialogContentStyled style={{ padding: '10px 100px' }}>
          <WrapImageQrCode>
            <ViewQrCodeStyled src={link} />
          </WrapImageQrCode>
        </DialogContentStyled>
        <DialogActionStyled>
          <ButtonSendSMS idTreatment={idTreatment} smsMessage={smsMessage} />
          <ButtonSendEmail idTreatment={idTreatment} smsMessage={smsMessage} />
          <a href={link} style={{ width: '100%' }} aria-label='print'>
            <ButtonLoading
              fullWidth
              Icon={<PrintIcon />}
              text="Print"
              variant="contained"
            />
          </a>
        </DialogActionStyled>
      </DialogStyled>
    </>
  );
};
export default ViewQrCode;
