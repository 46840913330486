import React, { useMemo, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  IconButton,
  TextField,
  Grid,
  InputAdornment,
  Box,
  Divider,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { SIGN_UP, SIGN_UP_PHONE } from 'gql';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { handleError } from 'share/utils';
import { TypographyCursor, ButtonSizeIcon } from 'share/component_css';
import {
  RegisterMutation,
  RegisterMutationVariables,
  RegisterPhoneUserMutation,
  RegisterPhoneUserMutationVariables,
} from 'types.d';
import { EMAIL_REGEX, PASSWORD_REGEX } from 'CONST';
import { UserInfo } from './ModalLogin';
import { Phone } from 'hooks';
import EmailPhoneInput from './EmailPhoneInput';
import PopperRulePassword from './PopperRulePassword';

type Props = {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  setUserInfo: (value: UserInfo) => void;
  handleCloseModal?: (status: boolean) => void;
};

type FormData = {
  email: string;
  showEmail: boolean;
  showPhone: boolean;
  password: string;
  lastName: string;
  firstName: string;
  middleName?: string;
  phoneNumber: string;
  confirmPassword: string;
};

const SignupSchema = {
  firstName: yup
    .string()
    .trim()
    .required('First name is required'),
  lastName: yup
    .string()
    .trim()
    .required('Last name is required'),
  phoneNumber: yup.string().trim(),
  email: yup.string().trim(),
  password: yup
    .string()
    .required('Password is required')
    .matches(PASSWORD_REGEX, 'Your password should be valid'),
  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
};

export const RegisterForm: React.FC<Props> = ({
  setTab,
  handleCloseModal,
  setUserInfo,
}) => {
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const [anchorEl, setAnchorEl] = useState<
    HTMLInputElement | HTMLTextAreaElement | null
  >(null);

  const [isShowEmail, setIsShowEmail] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const signupSchema = useMemo(() => {
    let schema = { ...SignupSchema };
    schema = isShowEmail
      ? {
          ...schema,
          email: yup
            .string()
            .required('Email is required')
            .matches(EMAIL_REGEX, 'Invalid email address'),
        }
      : {
          ...schema,
          phoneNumber: yup
            .string()
            .trim()
            .required('Phone number is required'),
        };
    return yup.object().shape(schema);
  }, [isShowEmail]);

  const { register, handleSubmit, errors, control, watch, setValue } = useForm<
    FormData
  >({
    validationSchema: signupSchema,
  });

  const watchPassword = watch('password');

  const handleOpenPopper = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [doSignUp] = useMutation<RegisterMutation, RegisterMutationVariables>(
    SIGN_UP,
    {
      onCompleted(data) {
        setTab('confirmEmail');
        setUserInfo({
          email: data?.register?.email,
          userId: data?.register?._id,
        });
      },
      onError(error) {
        const arrError = handleError(error.graphQLErrors[0]!);
        enqueueSnackbar(arrError.join(', '), { variant: 'error' });
      },
    },
  );

  const [doSignUpPhone] = useMutation<
    RegisterPhoneUserMutation,
    RegisterPhoneUserMutationVariables
  >(SIGN_UP_PHONE, {
    onCompleted(data) {
      const registerPhone = (data?.registerPhoneUser?.phones as Phone[])[0]
        ?.phone;
      setTab('confirmPhone');
      if (data?.registerPhoneUser?.phones) {
        setUserInfo({
          phone: registerPhone,
          userId: data?.registerPhoneUser?._id,
        });
      }
    },
    onError(error) {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
  });

  const onSubmit = handleSubmit(
    ({ email, password, firstName, lastName, phoneNumber, middleName }) => {
      if (phoneNumber) {
        doSignUpPhone({
          variables: {
            params: {
              phone: `+${phoneNumber}`,
              password,
              firstName,
              lastName,
              middleName: middleName ? middleName : null,
            },
          },
        });
        return;
      }
      doSignUp({
        variables: {
          params: {
            email,
            password,
            firstName,
            lastName,
            middleName: middleName ? middleName : null,
          },
        },
      });
    },
  );

  const handleSetValue = (value: string, isEmail?: boolean) => {
    if (isEmail) {
      setValue('email', value);
      return;
    }
    setValue('phoneNumber', value);
  };

  return (
    <>
      <form onSubmit={onSubmit} noValidate>
        <PopperRulePassword anchorEl={anchorEl} watchPassword={watchPassword} />
        <Grid spacing={1} container>
          <Grid item xs={6}>
            <TextField
              required
              tabIndex={1}
              autoFocus
              inputProps={{ maxLength: 255 }}
              size="small"
              InputLabelProps={{ shrink: true }}
              id="firstName"
              label="First Name"
              error={!!errors.firstName}
              inputRef={register}
              helperText={!!errors.firstName ? errors.firstName.message : ''}
              fullWidth
              type="text"
              name="firstName"
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              tabIndex={2}
              inputProps={{ maxLength: 255 }}
              size="small"
              InputLabelProps={{ shrink: true }}
              id="lastName"
              label="Last Name"
              error={!!errors.lastName}
              inputRef={register}
              helperText={!!errors.lastName ? errors.lastName.message : ''}
              fullWidth
              type="text"
              name="lastName"
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              tabIndex={3}
              inputProps={{ maxLength: 255 }}
              size="small"
              InputLabelProps={{ shrink: true }}
              id="middleName"
              label="Middle Name"
              inputRef={register}
              fullWidth
              type="text"
              name="middleName"
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <Box mt={1}>
              <EmailPhoneInput
                watch={watch}
                handleSetValue={handleSetValue}
                register={register}
                errorsEmail={errors.email}
                errorsPhone={errors.phoneNumber}
                control={control}
                setIsShowEmail={setIsShowEmail}
                isRegisterForm
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <TextField
              tabIndex={5}
              inputProps={{ maxLength: 255 }}
              size="small"
              id="password"
              label="Password"
              type={showPassword.password ? 'text' : 'password'}
              name="password"
              margin="dense"
              required
              fullWidth
              error={!!errors.password}
              inputRef={register}
              helperText={!!errors.password ? errors.password.message : ''}
              variant="outlined"
              autoComplete="new-password"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="Toggle password visibility  "
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          password: !showPassword.password,
                        })
                      }
                    >
                      {showPassword.password ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onFocus={handleOpenPopper}
              onBlur={handleOpenPopper}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              tabIndex={6}
              inputProps={{ maxLength: 255 }}
              size="small"
              id="confirmPassword"
              label="Confirm"
              type={showPassword.confirmPassword ? 'text' : 'password'}
              name="confirmPassword"
              margin="dense"
              required
              fullWidth
              error={!!errors.confirmPassword}
              inputRef={register}
              helperText={
                !!errors.confirmPassword ? errors.confirmPassword.message : ''
              }
              variant="outlined"
              autoComplete="new-password"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="Toggle password visibility  "
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          confirmPassword: !showPassword.confirmPassword,
                        })
                      }
                    >
                      {showPassword.confirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <Grid spacing={2} container alignItems="center">
            <Grid direction="row-reverse" container item xs={12}>
              <ButtonSizeIcon
                fullWidth
                tabIndex={6}
                type="submit"
                variant="outlined"
                color="primary"
                onClick={onSubmit}
              >
                Sign Up
              </ButtonSizeIcon>
            </Grid>
          </Grid>
          <Box textAlign="center" py={2}>
            <Divider />
          </Box>
          <Box textAlign="center">
            <TypographyCursor
              onClick={() => setTab('login')}
              variant="subtitle2"
              color="primary"
            >
              You have an account? Sign In
            </TypographyCursor>
          </Box>
        </Box>
      </form>
    </>
  );
};
export default RegisterForm;
