import React, { useContext, useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import {
  CheckCircleOutlineOutlined,
  Close,
  InsertComment,
  InsertCommentOutlined,
  Save,
} from '@material-ui/icons';
import { ButtonLoading, DialogTitleClose, StepWrapper } from 'components';
import { Mention, MentionsInput } from 'react-mentions';
import { useMutation } from '@apollo/react-hooks';
import { ChatBotFeedbackArgs, ChatbotFeedbackMutation } from 'types.d';
import { Chatbot_Feedback } from 'gql/server/chatbot';
import { useSnackbar } from 'notistack';
import { ChatbotDrawerContext } from 'share/context';

type Props = {
  msg: any;
  messages: any;
  setMessages: Function;
  setResponseAdded: Function;
};

const SaveButton = styled(ButtonLoading)(({ theme }) => ({
  marginTop: '5px',
}));

const CloseButton = styled(ButtonLoading)(({ theme }) => ({
  marginTop: '5px',
  marginRight: '2px',
}));

const BoxStyled = styled(Box)(({ theme }) => ({
  padding: '5px',
  background: 'white',
  borderRadius: '3px',
  border: '1px solid lightgrey',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start', // Align items to the start (left) of the container
  '& > *:first-child': {
    minWidth: '100%',
    alignSelf: 'center', // First child takes up remaining space
  },
  // '& > *:nth-child(2)': {
  //     alignSelf: 'flex-start', // Align the second child to the end (right) of its line
  // },
  // '& > *:nth-child(3)': {
  //     alignSelf: 'flex-end', // Align the third child to the center of its line
  // },
}));

export const CommentModal: React.FC<Props> = (props: Props) => {
  const [msgComment, setComment] = useState(
    props.msg.comment ? props.msg.comment : '',
  );

  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [addLikeOrDislike] = useMutation<
    ChatbotFeedbackMutation,
    ChatBotFeedbackArgs
  >(Chatbot_Feedback, {
    onCompleted(data) {
      if (data) {
        props.msg.comment = msgComment;
        props.setResponseAdded(false);
        props?.setMessages(
          props.messages.map((ele: any) => {
            if (ele.responseId == props.msg.responseId) {
              ele.comment = msgComment;
              if (ele.feedback == true) {
                props.msg.feedback = false;
                ele.feedback = false;
              }
            }
            return { ...ele };
          }),
        );
        enqueueSnackbar('Thanks for your Feedback', {
          variant: 'success',
        });
        setLoading(false);
      }
    },
    onError(error) {},
  });

  function addComment(comment: string) {
    setComment(comment);
    props.msg.comment = comment;
  }

  function saveComment() {
    setLoading(true);
    addLikeOrDislike({
      variables: {
        params: {
          messageId: props.msg.responseId,
          like: props.msg.like,
          comment: msgComment,
        },
      },
    });
  }

  function closeModal() {
    props.setResponseAdded(false);
    props?.setMessages(
      props.messages.map((ele: any) => {
        if (ele.responseId == props.msg.responseId) {
          if (ele.feedback == true) {
            props.msg.feedback = false;
            ele.feedback = false;
          }
        }
        return { ...ele };
      }),
    );
  }
  return (
    <>
      <BoxStyled>
        <StepWrapper isfocus={''} disable={''}>
          <div className="direction-input-wrapper">
            <div className="input-wrapper">
              <MentionsInput
                //disabled={props.msg.history}
                value={msgComment}
                onChange={e => {
                  addComment(e.target.value);
                }}
                allowSpaceInQuery
                placeholder={`Type here`}
                className="direction-input"
              >
                <Mention
                  trigger="{"
                  data={[]}
                  markup="{{__id__}}"
                  displayTransform={id => '{{' + id + '}}'}
                />
              </MentionsInput>
            </div>
          </div>
        </StepWrapper>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          width={'100%'}
        >
          <CloseButton
            text="Close"
            Icon={<Close />}
            loading={undefined}
            callbackClick={closeModal}
          />
          <SaveButton
            text="Save"
            Icon={<Save />}
            loading={loading}
            callbackClick={e => {
              e.preventDefault();
              saveComment();
            }}
            // disabled={props.msg.history}
          />
        </Box>
      </BoxStyled>
    </>
  );
};

export default React.memo(CommentModal);
