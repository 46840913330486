import React, { useState, useCallback, useContext } from 'react';
import {
  PatientNoteFragment,
  SignedPatientNoteMutation,
  SignedPatientNoteMutationVariables,
  NoteStatus,
} from 'types.d';
import { useMutationCustom, useToogleDialog } from 'hooks';
import { SIGNED_PATIENT_NOTE } from 'gql';
import { PatientDetailContext } from 'share/context';
import { ButtonLoading } from 'components';
import NoteIcon from '@material-ui/icons/Note';

export const useDialogNote = (): [
  () => React.ReactElement,
  string[],
  PatientNoteFragment | null,
  boolean,
  (data: string[]) => void,
  (data: PatientNoteFragment | null) => void,
  (status: boolean) => void,
] => {
  const [listCheckedSign, setListCheckedSign] = useState<string[]>([]);

  const patientDetailContext = useContext(PatientDetailContext);

  const [
    patientNoteDetail,
    setPatientNoteDetail,
  ] = useState<PatientNoteFragment | null>(null);

  const [checkedAll, setCheckedAll] = useToogleDialog();

  const handleSetListCheckedSign = useCallback((data: string[]) => {
    setListCheckedSign(data);
  }, []);

  const handleSetPatientNoteDetail = useCallback(
    (data: PatientNoteFragment | null) => {
      setPatientNoteDetail(data);
    },
    [],
  );

  const [signedPatientNote, { loading: loadingSignedNote }] = useMutationCustom<
    SignedPatientNoteMutation,
    SignedPatientNoteMutationVariables
  >({
    api: SIGNED_PATIENT_NOTE,
    textSuccess: 'Sign notes successfully',
    callbackSuccess: data => {
      if (patientNoteDetail) {
        const noteDetail = {
          ...patientNoteDetail,
          notes:
            patientNoteDetail?.notes?.map(item => {
              return {
                ...item,
                status: listCheckedSign.includes(item.idNote)
                  ? NoteStatus.Signed
                  : item.status,
              };
            }) || null,
        };

        setPatientNoteDetail(noteDetail);
        setListCheckedSign([]);
        setCheckedAll(false);
      }
    },
  });

  const signNotes = () => {
    signedPatientNote({
      variables: {
        params: {
          noteId: patientNoteDetail?._id,
          patientId: patientDetailContext?.patient.owner?.idOwner,
          subNoteIds: listCheckedSign,
        },
      },
    });
  };

  const renderSignedNotesButton = () => {
    return (
      <ButtonLoading
        Icon={<NoteIcon />}
        text="Sign Notes"
        callbackClick={signNotes}
        loading={loadingSignedNote}
        disabled={!Boolean(listCheckedSign.length)}
      />
    );
  };

  return [
    renderSignedNotesButton,
    listCheckedSign,
    patientNoteDetail,
    checkedAll,
    handleSetListCheckedSign,
    handleSetPatientNoteDetail,
    setCheckedAll,
  ];
};
