import React from 'react';
import { Box } from '@material-ui/core';
import PickColorFollowUpType from './PopoverPickColor';
import { DEFAULT_COLOR_TAG } from 'CONST';

type Props = {
  color: string;
  setDisplayColor: (color: string) => void;
};

export const ColorDisplay: React.FC<Props> = ({ color, setDisplayColor }) => {
  return (
    <Box display="flex" alignItems="center">
      <Box
        borderRadius={4}
        height={40}
        width="100%"
        bgcolor={color || DEFAULT_COLOR_TAG}
      />
      <PickColorFollowUpType
        colorDefault={color}
        setDisplayColor={setDisplayColor}
      />
    </Box>
  );
};

export default React.memo(ColorDisplay);
