import { PatientFragment } from 'types.d';
import { RowInput } from 'jspdf-autotable';
import { ExtendedPatientTimeReportFragment } from 'modules/patients/components/TimeRecord/DialogBilling';
import { useCommonPdf } from 'hooks';

export const usePrintBilling = (patientDetail?: PatientFragment) => {
  const {
    doc,
    drawLabel,
    drawLine,
    addSpace,
    setHeight,

    getHeight,
    drawInfomationPatient,
    drawAutoTable,

    renderBody,
  } = useCommonPdf();

  const addRecordTable = (
    records?: ExtendedPatientTimeReportFragment[] | null,
  ) => {
    if (records && records?.length !== 0) {
      addSpace(5);
      drawLine();
      addSpace(8);
      drawLabel('Billing');
      setHeight(getHeight() - 4);
      const body = renderBody(records);
      drawAutoTable({
        head: [
          'Date',
          'Start Time',
          'End Time',
          'Time Spent',
          'Navigator',
          {
            content: 'Note Types',
            colSpan: 2,
          },
        ],
        body: [...(body.flat() as RowInput[])],
        columnStyles: {
          0: { cellWidth: 25 },
          1: { cellWidth: 25 },
          2: { cellWidth: 25 },
          3: { cellWidth: 25 },
          4: { cellWidth: 40 },
        },
      });
    }
  };

  const createBillingPdf = ({
    records,
  }: {
    records?: ExtendedPatientTimeReportFragment[];
  }) => {
    drawInfomationPatient({ patientDetail, gender: '', mrn: undefined });
    addRecordTable(records);
    return doc;
  };
  return {
    createBillingPdf,
  };
};
