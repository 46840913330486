import gql from 'graphql-tag';
import { MetaFragment, OrganizationFragment, TreatmentDetailFragment, UserFragment } from './fragments';

export const GET_ALL_FLOWS = gql`
  query GetAllFlows($params: GetPaginatedFlowInput!) {
    getAllFlows(params: $params) {
      nodes {
        _id
        name
        references {
          _id
          name
          type
          ownerId
          parentId
        }
        user {
          ...User
        }
        encryptionToken
        organization {
          ...Organization
        }
        slug
        urlSharing
        qrCode
        favorites {
          _id
        }
      }
      meta {
        ...Meta
      }
    }
  }
  ${MetaFragment}
  ${UserFragment}
  ${OrganizationFragment}
`;

export const CREATE_FLOW = gql`
  mutation CreateFlow($params: FlowInput!) {
    createFlow(params: $params) {
      _id
      name
      references {
        _id
        name
        type
        ownerId
        parentId
      }
      user {
        ...User
      }
      encryptionToken
      organization {
        ...Organization
      }
      slug
      urlSharing
      qrCode
    }
  }
  ${UserFragment}
  ${OrganizationFragment}
`;

export const UPDATE_FLOW = gql`
  mutation UpdateFlow($params: UpdateFlowInput!) {
    updateFlow(params: $params) {
      _id
      name
      references {
        _id
        name
        type
        ownerId
        parentId
      }
      user {
        ...User
      }
      encryptionToken
      organization {
        ...Organization
      }
      slug
      urlSharing
      qrCode
    }
  }
  ${UserFragment}
  ${OrganizationFragment}
`;

export const CREATE_QR_CODE_FLOW = gql`
  mutation CreateQRCodeFlow($params: IdInputType!) {
    createQRCodeFlow(params: $params) {
      _id
      name
      references {
        _id
        name
        type
        ownerId
        parentId
      }
      user {
        ...User
      }
      encryptionToken
      organization {
        ...Organization
      }
      slug
      urlSharing
      qrCode
    }
  }
  ${UserFragment}
  ${OrganizationFragment}
`;

export const ASSIGN_FLOW = gql`
  mutation AssignFlow($params: AssignInput!){
    assignFlow(params: $params){
      flowId
      organization
      orgName 
      flowName
      token
      slug
      references
    }
  }
`

export const DELETE_FLOW = gql`
  mutation DeleteFlow($params: DeleteFlowInput!) {
    deleteFlow(params: $params)
    }
`;

export const GET_FLOW_DETAIL = gql`
  query GetFlowDetail($params: GetAssignFlowInput!) {
    getFlowDetail(params: $params) {
      _id
      name
      references {
        type
        surveyData
        ...TreatmentDetail
      }
      user {
        ...User
      }
      encryptionToken
      organization {
        ...Organization
      }
      slug
      urlSharing
      qrCode
    }
  }
  ${TreatmentDetailFragment}
  ${UserFragment}
  ${OrganizationFragment}
`;

export const CHECK_USER_IN_SYSYEM = gql`
  query CheckUserInSystem($params: CheckEmailOrPhoneNumber!) {
    checkUserInSystem(params: $params){
      isValid
      userLoggedIn
    }
    }
`;

export const GENERATE_AND_SEND_OTP_FOR_USER = gql`
  mutation GenerateAndSendOTPForUser($params: GenerateOTPInput!) {
    generateAndSendOTPForUser(params: $params){
      success
    }
  }
`;

export const VERIFY_OTP_FOR_USER = gql`
  mutation VerifyOtpForUser($params: ActiveUserOTPInputFlow!) {
    verifyOtpForUser(params: $params){
      success
      patientCreated
    }
  }
`;

export const GENERATE_AND_SEND_OTP_FOR_NON_USER = gql`
  mutation generateAndSendOTPForNonUser($params: GenerateOTPInput!) {
    generateAndSendOTPForNonUser(params: $params){
      success
    }
  }
`;

export const VERIFY_OTP_FOR_NON_USER = gql`
  mutation VerifyOtpForNonUser($params: OTPForNonUserFlow!) {
    verifyOtpForNonUser(params: $params){
      success
      patientCreated
    }
  }
`;

export const SUBMIT_FLOW_DATA = gql`
  mutation SubmitFlowData($params: SubmitFlowData!){
    submitFlowData(params: $params)
  }
`;

export const ADD_TO_FAVORITE_FLOW = gql`
  mutation AddToFavoriteFlow($params: IdInputType!) {
    addToFavoriteFlow(params: $params)
    }
`;

export const REMOVE_FROM_FAVORITE_FLOW = gql`
  mutation RemoveFromFavoriteFlow($params: IdInputType!) {
    removeFromFavoriteFlow(params: $params)
    }
`;