import React, { useEffect, useMemo, useContext } from 'react';
import { Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core';
import {
  DialogTitleClose,
  DialogButton,
  AutoCompleteLoadData,
  AutoCompleteSelect,
} from 'components';
import { useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  GET_TREATMENT_ADD_SURVEY,
  CREATE_SURVEY_RESULT_BY_NAVIGATOR,
  GET_SURVEY_DATA_BY_PATIENT,
  GET_PATIENT_CHECKLISTS,
} from 'gql';
import {
  GetTreatmentAddSurveyQuery,
  GetTreatmentAddSurveyQueryVariables,
  TreatmentsForAddSurveyFragment,
  SurveyForAddSurveyFragment,
  CreateSurveyResultByNavigatorMutation,
  CreateSurveyResultByNavigatorMutationVariables,
  TreatmentType,
} from 'types.d';
import { trimObjectValue, getStatusByAnswer, sortObject } from 'share/utils';
import SurveyDisplay from '../SurveyDisplay';
import * as Survey from 'survey-react';
import { useMutationCustom } from 'hooks';
import { PatientDetailContext } from 'share/context';
import { useServices } from '../../services';
import { useSnackbar } from 'notistack';
import { DEFAULT_LIMIT } from 'CONST';
import { customEvent } from 'modules/google_tag_manager';

type Props = {
  toogleDialog: (status: boolean) => void;
  open: boolean;
  callbackAdd?: () => void;
};

type FormData = {
  treatment: TreatmentsForAddSurveyFragment;
  survey: SurveyForAddSurveyFragment | null;
};

const defaultParams = {
  page: 1,
  limit: DEFAULT_LIMIT,
  hasSurvey: true,
  type: [TreatmentType.Treatment],
  sortByOrder: { name: 1 },
};

export const DialogAddSurvey: React.FC<Props> = ({
  toogleDialog,
  open,
  callbackAdd,
}) => {
  const { variablesChecklistTag } = useServices();

  const methods = useForm<FormData>({
    mode: 'onBlur',
  });

  const { handleSubmit, control, watch, setValue, errors } = methods;

  const { enqueueSnackbar } = useSnackbar();

  const watchTreatment = watch('treatment');

  const watchSurvey = watch('survey');

  const patientDetailContext = useContext(PatientDetailContext);

  const { variablesSurveyTags } = useServices();

  //check checklistresult tab is opened
  const isOnTabChecklist = useMemo(() => {
    return (
      patientDetailContext?.isExtend && patientDetailContext?.indexTab === 6
    );
  }, [patientDetailContext]);

  const closeDialog = () => {
    toogleDialog(false);
    patientDetailContext?.handleSetDialogOpen('');
  };

  const [
    createSurveyResultByNavigator,
    { loading: loadingCreate },
  ] = useMutationCustom<
    CreateSurveyResultByNavigatorMutation,
    CreateSurveyResultByNavigatorMutationVariables
  >({
    refetchQueries: [
      ...patientDetailContext?.refetchQueriesGetPatient,
      {
        query: GET_SURVEY_DATA_BY_PATIENT,
        variables: variablesSurveyTags,
      },
      {
        query: GET_PATIENT_CHECKLISTS,
        variables: variablesChecklistTag,
      },
    ],
    api: CREATE_SURVEY_RESULT_BY_NAVIGATOR,
    textSuccess: 'Add survey to patient successfully',
    callbackSuccess: () => {
      if (callbackAdd) {
        callbackAdd();
      }
      customEvent('CreateSurveyResultsByNav', 'NAVIGATOR');
      if (isOnTabChecklist) {
        patientDetailContext?.setIsRefreshChecklist(true);
      }
      closeDialog();
    },
  });

  const [
    loadTreatments,
    { loading: loadingTreatments, data: dataTreatments },
  ] = useLazyQuery<
    GetTreatmentAddSurveyQuery,
    GetTreatmentAddSurveyQueryVariables
  >(GET_TREATMENT_ADD_SURVEY, {
    fetchPolicy: 'cache-and-network',
  });

  const surveyConfig = useMemo(() => {
    if (watchSurvey) {
      const survey = new Survey.Model(watchSurvey.surveyData);
      survey.showNavigationButtons = false;
      survey.showCompletedPage = false;
      return survey;
    }
    return null;
  }, [watchSurvey]);

  const handleTextInput = (value: string) => {
    if (value) {
      loadTreatments({
        variables: {
          params: {
            name: value,
            ...defaultParams,
          },
        },
      });
    }
  };

  const handleSave = handleSubmit(data => {
    if (Object.keys(surveyConfig?.data).length === 0) {
      enqueueSnackbar('You need to answer at least one question', {
        variant: 'error',
      });
      return;
    }
    if (surveyConfig) {
      const dataTrim = trimObjectValue(data);
      createSurveyResultByNavigator({
        variables: {
          params: {
            survey: dataTrim.survey?.idSurvey,
            data: surveyConfig?.data || null,
            patientId: patientDetailContext?.patient.owner?.idOwner,
            completedStatus: getStatusByAnswer(surveyConfig),
          },
        },
      });
    }
  });

  const enterDialog = () => {
    loadTreatments({
      variables: {
        params: {
          ...defaultParams,
        },
      },
    });
  };

  useEffect(() => {
    setValue('survey', null);
  }, [setValue, watchTreatment]);
  const listSurveyByTreatment = useMemo(() => {
    return watchTreatment?.survey || [];
  }, [watchTreatment]);

  return (
    <>
      <Dialog
        open={open}
        onEnter={enterDialog}
        onClose={closeDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitleClose title="Add Survey" onClose={closeDialog} />
        <DialogContent>
          <Grid container spacing={2}>
            <>
              <Grid item xs={12} sm={6} md={3}>
                <AutoCompleteLoadData
                  hasOptionsTextNull
                  label="Treatment"
                  nameOption="name"
                  idOption="idTreatment"
                  required
                  loadList={loadingTreatments}
                  options={dataTreatments?.getTreatments.nodes || []}
                  small
                  defaultValue={null}
                  name="treatment"
                  methods={methods}
                  control={control}
                  error={!!errors.treatment}
                  callbackHandleText={value => handleTextInput(value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <AutoCompleteSelect
                  disablePortal
                  small
                  required
                  error={!!errors.survey}
                  name="survey"
                  label="Survey"
                  control={control}
                  nameOption="name"
                  options={sortObject(listSurveyByTreatment) || []}
                />
              </Grid>
              {surveyConfig && (
                <Grid item xs={12}>
                  <SurveyDisplay survey={surveyConfig} />
                </Grid>
              )}
            </>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton isCancel onClickButton={closeDialog} />
          <DialogButton loading={loadingCreate} onClickButton={handleSave} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(DialogAddSurvey);
