import React, { useContext, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { ME_CLIENT, CREATE_COMMENT } from 'gql';
import { CreateCommentMutation, CreateCommentMutationVariables } from 'types.d';
import { useQuery } from '@apollo/react-hooks';
import { DeleteConfirm } from 'components';
import SendIcon from '@material-ui/icons/Send';
import { TypographyBold } from 'share/component_css';
import { useMutationCustom, useCustomPermission, useToogleDialog } from 'hooks';
import { CommentsContext } from 'share/context';
import { TextFieldCommentStyled } from '../styles';
import CancelIcon from '@material-ui/icons/Cancel';
type Props = {
  toogleComment?: (status: boolean) => void;
  hasCancel?: boolean;
  parent?: string;
  callbackReply?: () => void;
  isTreatmentPage?: boolean;
  sendTo?: string;
  isOnPatientDashboard?: boolean;
};

export const FormAddComment = React.forwardRef(
  (
    {
      toogleComment,
      hasCancel,
      parent,
      callbackReply,
      isTreatmentPage,
      sendTo,
      isOnPatientDashboard,
    }: Props,
    ref?: React.Ref<HTMLTextAreaElement>,
  ) => {
    const { data: dataMeClient } = useQuery(ME_CLIENT);

    const [isOpenConfirm, setOpenConfirm] = useToogleDialog();

    const { isNavigator, isSuperAdmin, isUser } = useCustomPermission();

    const [textMessage, setTextMessage] = useState('');

    const commentsContext = useContext(CommentsContext);

    const [
      createComment,
      { loading: loadingCreateComment },
    ] = useMutationCustom<
      CreateCommentMutation,
      CreateCommentMutationVariables
    >({
      api: CREATE_COMMENT,
      callbackSuccess: data => {
        setTextMessage('');
        setOpenConfirm(false);
        // if (callbackReply) {
        //   callbackReply();
        // }
        // commentsContext?.callbackAddComment(data.createComment, !!isFirstComment);
      },
    });

    const handleComment = (e: any) => {
      e.preventDefault();
      if (isNavigator && !isOnPatientDashboard && !hasCancel) {
        setOpenConfirm(true);
        return;
      }
      if (!loadingCreateComment) {
        createComment({
          variables: {
            params: {
              treatment: commentsContext?.treatmentId,
              message: textMessage,
              parent: parent || null,
              sendTo,
            },
          },
        });
      }
    };

    const handleEditComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setTextMessage(e.target.value);
    };

    const confirmComment = () => {
      createComment({
        variables: {
          params: {
            treatment: commentsContext?.treatmentId,
            message: textMessage,
            parent: parent || null,
            sendTo,
            broadcastQuesFromTreatments: true,
          },
        },
      });
    };

    const hinText = () => {
      if (isUser) {
        return 'Type your question';
      }
      return isOnPatientDashboard
        ? 'Type here to send a direct message to the patient'
        : 'Type here to make a broadcast statement';
    };

    return (
      <>
        <DeleteConfirm
          title="Confirmation"
          action="update"
          content={
            <Typography color="textSecondary">
              Please confirm your action : Sending this message will distribute
              it to all users
            </Typography>
          }
          handleConfirm={confirmComment}
          open={isOpenConfirm}
          toogle={setOpenConfirm}
          loading={loadingCreateComment}
          buttonText="Send"
          icon={<SendIcon />}
        />
        {!isSuperAdmin && (
          <Box mt={1.5} display="flex" flexDirection="column">
            <Box>
              <Typography
                className="d-inline mr-8"
                variant="subtitle2"
                color="textSecondary"
              >
                {hinText()}
              </Typography>
              <TypographyBold
                className="d-inline"
                color="primary"
                variant="subtitle2"
              >
                {`${dataMeClient.meClient.firstName} ${dataMeClient.meClient
                  .middleName || ''} ${dataMeClient.meClient.lastName}`}
              </TypographyBold>
            </Box>
            <form noValidate onSubmit={handleComment}>
              <Box
                display="flex"
                alignItems="center"
                border={1}
                borderRadius={8}
                borderColor="grey.300"
              >
                <TextFieldCommentStyled
                  inputProps={{ maxLength: 255 }}
                  inputRef={ref}
                  fullWidth
                  onChange={handleEditComment}
                  value={textMessage}
                  variant="outlined"
                />

                {/* {isNavigator && (
              <TypographyItalic color="textSecondary" variant="subtitle2">
                * This message will be sent to everyone. If you want to send
                private messages to your patients, please use the [Reply]
                feature or mention the patient's email that you want to send
              </TypographyItalic>
            )} */}
                {hasCancel && (
                  <IconButton onClick={() => toogleComment!(false)}>
                    <CancelIcon />
                  </IconButton>
                )}
                <IconButton
                  onClick={handleComment}
                  disabled={textMessage.trim() === ''}
                  color="primary"
                >
                  {loadingCreateComment ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <SendIcon />
                  )}
                </IconButton>
              </Box>
            </form>
          </Box>
        )}
      </>
    );
  },
);

export default React.memo(FormAddComment);
