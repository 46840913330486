import React, { useMemo } from 'react';
import { Tooltip, Box, IconButton, Typography } from '@material-ui/core';
import {
  SubTreatmentsSideBar,
  GetSubTreatmentSideBarQuery,
  GetSubTreatmentSideBarQueryVariables,
  AddShowingTreatmentSidebarMutation,
  AddShowingTreatmentSidebarMutationVariables,
  ShowingTreatmentsSidebarQuery,
} from 'types.d';
import {
  GET_SUB_TREATMENT_SIDE_BAR,
  ADD_SUB_TREATMENTS_SIDE_BAR,
  SHOWING_TREATMENTS_SIDEBAR,
  ADD_SHOWING_TREATMENT_SIDEBAR,
} from 'gql';
import { useLazyQuery } from '@apollo/react-hooks';
import { useMutationCustom } from 'hooks';
import { AvatarStyled } from './styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { LinkRouterStyled } from 'share/component_css';
import Skeleton from '@material-ui/lab/Skeleton';
import { useQuery, useMutation } from '@apollo/react-hooks';

type Props = {
  item?: SubTreatmentsSideBar | null;
  parent?: string;
};

export const SubTreatment: React.FC<Props> = ({ item, parent }) => {
  const { data: dataTreatmentSidebar } = useQuery<
    ShowingTreatmentsSidebarQuery
  >(SHOWING_TREATMENTS_SIDEBAR);

  const [addTreatmentSideBarShow] = useMutation<
    AddShowingTreatmentSidebarMutation,
    AddShowingTreatmentSidebarMutationVariables
  >(ADD_SHOWING_TREATMENT_SIDEBAR);

  const [addSubTreatmentsSideBar] = useMutationCustom({
    api: ADD_SUB_TREATMENTS_SIDE_BAR,
    callbackSuccess: () => {
      addTreatmentSideBarShow({
        variables: {
          input: `${parent ? `${parent}-` : ''}${item?._id || ''}`,
        },
      });
    },
  });

  const [getSubTreatment, { loading: loadingSub }] = useLazyQuery<
    GetSubTreatmentSideBarQuery,
    GetSubTreatmentSideBarQueryVariables
  >(GET_SUB_TREATMENT_SIDE_BAR, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      addSubTreatmentsSideBar({
        variables: {
          subTreatments: data.getTreatment?.subSections?.map(sub => {
            return sub;
          }),
        },
      });
    },
  });

  const isExtend = useMemo(() => {
    return dataTreatmentSidebar?.showingTreatmentsSidebar?.includes(
      `${parent ? `${parent}-` : ''}${item?._id || ''}`,
    );
  }, [dataTreatmentSidebar, item, parent]);

  const getListSub = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!isExtend && item?.children?.length === 0) {
      getSubTreatment({
        variables: { params: { _id: item?._id } },
      });
    } else {
      addTreatmentSideBarShow({
        variables: {
          input: `${parent ? `${parent}-` : ''}${item?._id || ''}`,
        },
      });
    }
  };

  const checkCanExtend = useMemo(() => {
    return parent ? parent.split(',').length === 1 : true;
  }, [parent]);

  return (
    <>
      {item && (
        <>
          <Tooltip key={item._id} title={item.label || item.name}>
            <Box
              justifyContent="space-between"
              className="cursor-pointer"
              display="flex"
              alignItems="center"
              py={1}
            >
              <Box display="flex" alignItems="center">
                <AvatarStyled>
                  <Typography color="primary" variant="subtitle2">
                    {item.label
                      ? item.label[0].toUpperCase()
                      : (item.name as string)[0].toUpperCase()}
                  </Typography>
                </AvatarStyled>
                <LinkRouterStyled
                  to={
                    item?.slug
                      ? `/treatment/${item.slug}`
                      : `/assigned-treatments/treatment/${item._id}/`
                  }
                >
                  {item.label || item.name}
                </LinkRouterStyled>
              </Box>

              {item?.subSectionIds?.length !== 0 && checkCanExtend && (
                <IconButton onClick={getListSub}>
                  {isExtend ? (
                    <ExpandMoreIcon fontSize="small" />
                  ) : (
                    <ChevronRightIcon fontSize="small" />
                  )}
                </IconButton>
              )}
            </Box>
          </Tooltip>
          <Box ml={2}>
            {loadingSub && (
              <>
                <Skeleton />
                <Skeleton />
              </>
            )}

            {isExtend &&
              item.children?.map(subItem => {
                return (
                  <SubTreatment
                    parent={`${parent ? `${parent},` : ''}${item._id}`}
                    key={subItem?._id}
                    item={subItem}
                  />
                );
              })}
          </Box>
        </>
      )}
    </>
  );
};

export default SubTreatment;
