import React, { useState, useMemo, useContext } from 'react';
import { Dialog, DialogActions, Box, DialogContent } from '@material-ui/core';
import { DialogTitleClose, ButtonLoading } from 'components';
import {
  GetSurveyResultTaskQuery,
  PatientCondStageFragment,
  Gender,
  PatientNoteFragment,
  PatientTimeRecordFragment,
  PatientTimeReportFragment,
} from 'types.d';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useChecked } from 'hooks';
import TableTask from './TableTask';
import TableChecklist from './TableChecklist';
import TableCondition from './TableCondition';
import TableNote from './TableNote';
import { useAddMrn } from 'hooks';
import { PatientDetailContext } from 'share/context';
import { PictureAsPdf } from '@material-ui/icons';
import { SendEmrType } from '../DialogAllQuestions';
import { TypographyItalic } from 'share/component_css';
import TableRecord from './TableRecord';

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
  tasks?: GetSurveyResultTaskQuery['getPatientFollowUps'];
  checklists?: GetSurveyResultTaskQuery['getPatientChecklists']['nodes'];
  records?: PatientTimeRecordFragment[];
  notes?: PatientNoteFragment['notes'];
  sendEmr: (dataEmr: {
    gender: Gender | string;
    mrn: string;
    tasks: string[];
    checklists: string[];
    conditions: string[];
    notes: string[];
    records: string[];
  }) => void;
  surveyId?: string;
  conditions?: PatientCondStageFragment[];
  surveyName: string;
  loadingEmr: boolean;
  isCreatePdf?: boolean;
  downloadPdf: (dataPdf: SendEmrType) => void;
};
enum DisplayTab {
  Task = 'task',
  Checklist = 'checklist',
  Condition = 'condition',
  Note = 'note',
  Report = 'report',
}

type TextContent = {
  title: string;
  note: string;
};

const title: { [key: string]: TextContent } = {
  [DisplayTab.Checklist]: { title: 'Checklists', note: 'checklists' },
  [DisplayTab.Condition]: { title: 'Conditions', note: 'conditions' },
  [DisplayTab.Task]: { title: 'Tasks', note: 'tasks' },
  [DisplayTab.Note]: { title: 'Notes', note: 'notes' },
  [DisplayTab.Report]: { title: 'Time Reports', note: 'reports' },
};

export const DialogFilterSendEmr: React.FC<Props> = ({
  open,
  toogleDialog,
  tasks,
  sendEmr,
  checklists,
  surveyId,
  conditions,
  loadingEmr,
  notes,
  isCreatePdf,
  downloadPdf,
  records,
}) => {
  const {
    openForm,
    setOpenForm,
    MrnComponent,
    mrnComponentProps,
  } = useAddMrn();

  const listRecordHasBilled = useMemo(() => {
    return records?.filter(item =>
      item?.timeReport?.find(report => report.status),
    );
  }, [records]);

  const patientDetailContext = useContext(PatientDetailContext);

  const {
    checkItem: checkItemNote,
    checkedAll: checkedAllNote,
    checkAll: checkAllNote,
    checkedList: checkedNote,
    resetCheckbox: resetCheckboxNote,
  } = useChecked(
    notes?.map(item => {
      return { ...item, _id: item.idNote };
    }) || [],
  );

  const {
    checkItem: checkItemTask,
    checkedAll: checkedAllTask,
    checkAll: checkAllTask,
    checkedList: checkedTask,
    resetCheckbox: resetCheckboxTask,
  } = useChecked(tasks?.nodes || []);

  const {
    checkItem: checkItemRecord,
    checkedList: checkedRecord,
    setCheckedList: setCheckedListRecord,
    resetCheckbox: resetCheckboxRecord,
  } = useChecked(
    (listRecordHasBilled
      ?.flatMap(item => item?.timeReport)
      ?.filter(item => item?.status) as PatientTimeReportFragment[]) || [],
  );

  const {
    checkItem: checkItemCondition,
    checkedAll: checkedAllCondition,
    checkAll: checkAllCondition,
    checkedList: checkedCondition,
    resetCheckbox: resetCheckboxCondition,
  } = useChecked(conditions || []);

  const convertChecklist = useMemo(() => {
    return (
      checklists?.map(item => {
        return { ...item, _id: item.patientChecklists?.patientChecklistId };
      }) || []
    );
  }, [checklists]);

  const {
    checkItem: checkItemChecklist,
    checkedAll: checkedAllChecklist,
    checkAll: checkAllChecklist,
    checkedList: checkedChecklist,
    resetCheckbox: resetCheckboxChecklist,
  } = useChecked(convertChecklist);

  const [displayTab, setDisplayTab] = useState<DisplayTab>(DisplayTab.Task);

  const onEnter = () => {
    resetCheckboxNote();
    resetCheckboxTask();
    resetCheckboxChecklist();
    resetCheckboxCondition();
    resetCheckboxRecord();
    setDisplayTab(DisplayTab.Task);
  };

  const checkSendEmr = () => {
    if (
      patientDetailContext?.patient?.owner?.mrn &&
      patientDetailContext?.patient?.owner?.gender
    ) {
      handleSendEmr({
        mrn: patientDetailContext?.patient?.owner?.mrn,
        gender: patientDetailContext?.patient?.owner?.gender,
      });
      return;
    }
    setOpenForm(true);
  };

  const handleSendEmr = (params: { gender: Gender | string; mrn: string }) => {
    const { gender, mrn } = params;
    sendEmr({
      gender,
      mrn,
      checklists: checkedChecklist,
      conditions: checkedCondition,
      tasks: checkedTask,
      notes: checkedNote,
      records: checkedRecord,
    });
  };

  return (
    <Dialog onEnter={onEnter} open={open} maxWidth="md" fullWidth>
      <DialogTitleClose
        title={title[displayTab].title}
        onClose={() => toogleDialog(false)}
      />
      <DialogContent className="pt-0">
        <TypographyItalic
          gutterBottom
          color="textSecondary"
          variant="subtitle2"
        >
          {displayTab !== DisplayTab.Checklist &&
            `* Select ${title[displayTab].note} to ${
              isCreatePdf ? 'create PDF' : 'send to EMR'
            }`}
        </TypographyItalic>
        {displayTab === DisplayTab.Task && (
          <TableTask
            tasks={tasks}
            checkAll={checkAllTask}
            checkedAll={checkedAllTask}
            checkItem={checkItemTask}
            checkedList={checkedTask}
          />
        )}
        {displayTab === DisplayTab.Checklist && (
          <TableChecklist
            checklists={checklists}
            checkAll={checkAllChecklist}
            checkedAll={checkedAllChecklist}
            checkItem={checkItemChecklist}
            checkedList={checkedChecklist}
            surveyId={surveyId}
            isCreatePdf={isCreatePdf}
          />
        )}
        {displayTab === DisplayTab.Condition && (
          <TableCondition
            conditions={conditions}
            checkAll={checkAllCondition}
            checkedAll={checkedAllCondition}
            checkItem={checkItemCondition}
            checkedList={checkedCondition}
          />
        )}
        {displayTab === DisplayTab.Note && (
          <TableNote
            notes={notes}
            checkAll={checkAllNote}
            checkedAll={checkedAllNote}
            checkItem={checkItemNote}
            checkedList={checkedNote}
          />
        )}
        {displayTab === DisplayTab.Report && (
          <TableRecord
            records={listRecordHasBilled}
            checkItem={checkItemRecord}
            checkedList={checkedRecord}
            setCheckedList={setCheckedListRecord}
          />
        )}
      </DialogContent>
      <DialogActions>
        {displayTab === DisplayTab.Task && (
          <ButtonLoading
            className="ml-8"
            text="Next"
            Icon={<ArrowForwardIcon />}
            callbackClick={() => {
              setDisplayTab(DisplayTab.Checklist);
            }}
          />
        )}
        {displayTab === DisplayTab.Checklist && (
          <>
            <ButtonLoading
              color="default"
              className="ml-8"
              text="Back"
              Icon={<ArrowBackIcon />}
              callbackClick={() => {
                setDisplayTab(DisplayTab.Task);
              }}
            />
            <ButtonLoading
              text="Next"
              Icon={<ArrowForwardIcon />}
              callbackClick={() => {
                setDisplayTab(DisplayTab.Condition);
              }}
            />
          </>
        )}
        {displayTab === DisplayTab.Condition && (
          <>
            <ButtonLoading
              color="default"
              className="ml-8"
              text="Back"
              Icon={<ArrowBackIcon />}
              callbackClick={() => {
                setDisplayTab(DisplayTab.Checklist);
              }}
            />
            <ButtonLoading
              text="Next"
              Icon={<ArrowForwardIcon />}
              callbackClick={() => {
                setDisplayTab(DisplayTab.Note);
              }}
            />
          </>
        )}
        {displayTab === DisplayTab.Note && (
          <>
            <ButtonLoading
              color="default"
              className="ml-8"
              text="Back"
              Icon={<ArrowBackIcon />}
              callbackClick={() => {
                setDisplayTab(DisplayTab.Condition);
              }}
            />
            <ButtonLoading
              text="Next"
              Icon={<ArrowForwardIcon />}
              callbackClick={() => {
                setDisplayTab(DisplayTab.Report);
              }}
            />
          </>
        )}
        {displayTab === DisplayTab.Report && (
          <>
            {openForm ? (
              <MrnComponent
                loading={loadingEmr}
                handleCallback={handleSendEmr}
                {...mrnComponentProps}
              />
            ) : (
              <>
                <Box mr="auto">
                  <ButtonLoading
                    color="default"
                    text="Back"
                    Icon={<ArrowBackIcon />}
                    callbackClick={() => {
                      setDisplayTab(DisplayTab.Note);
                    }}
                  />
                </Box>
                <ButtonLoading
                  text="Create PDF"
                  Icon={<PictureAsPdf />}
                  callbackClick={() => {
                    downloadPdf({
                      gender: '',
                      mrn: undefined,
                      checklists: checkedChecklist,
                      conditions: checkedCondition,
                      tasks: checkedTask,
                      notes: checkedNote,
                      records: checkedRecord,
                    });
                  }}
                />
                {!isCreatePdf && (
                  <ButtonLoading
                    text="Send to EMR"
                    Icon={<CloudUploadIcon />}
                    callbackClick={checkSendEmr}
                  />
                )}
              </>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(DialogFilterSendEmr);
