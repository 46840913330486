import React, { useEffect, useMemo, useState, useRef } from 'react';
import {
  TableRow,
  TableCell,
  Typography,
  Collapse,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Checkbox,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {
  PatientTimeRecordFragment,
  PatientTimeReportFragment,
  PatientCptCodeStatus,
} from 'types.d';
import { format } from 'date-fns';
import {
  chipNoteTypes,
  formatDate,
  getDuration,
  renderTime,
  renderDataTotal,
} from 'share/utils';
import { ButtonDelete } from 'components';
import {
  TableCellMaxWidthStyled,
  TypographyItalic,
  TypographyBold,
} from 'share/component_css';
import {
  PatientTimeReportFragmentExtend,
  useTimeRecord,
  useToogleDialog,
} from 'hooks';
import { useServices } from '../../services';
import EditIcon from '@material-ui/icons/Edit';
import DialogUpdateRecord from './DialogUpdateRecord';
type Props = {
  report: PatientTimeRecordFragment;
  isExpand: boolean;
  isCreatePdf?: boolean;
  checkItem?: (
    event: React.ChangeEvent<HTMLInputElement>,
    item: string,
  ) => void;
  checkedList?: string[];
  setCheckedList?: (items: string[]) => void;
};

export type TRecordTotal = {
  _id: string;
  totalLabel: string;
  timeSpend: string;
};

export const TableDetailReport: React.FC<Props> = ({
  report,
  isExpand,
  isCreatePdf,
  checkItem,
  checkedList,
  setCheckedList,
}) => {
  const timeRecordSelected = useRef<PatientTimeReportFragmentExtend | null>(
    null,
  );
  const [checkAll, setCheckedAll] = useState(false);

  const [openModifyNoteType, toogleModifyNoteType] = useToogleDialog();

  const { dataByMonth, isHavingPermissionTimeRecord } = useTimeRecord();

  const { handleDeleteRecord, loadingDeleteRecord } = useServices();

  const handleDelete = (key: string) => {
    handleDeleteRecord(key, () => {}, true);
  };

  const formatDateTime = (date: string) => {
    return format(new Date(date), 'HH:mm:ss');
  };

  const timeReports = useMemo(() => {
    if (isCreatePdf) {
      return report?.timeReport?.filter(item => item?.status);
    }
    return report?.timeReport;
  }, [isCreatePdf, report]);

  const checkAllReport = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (checkedList && setCheckedList) {
      setCheckedAll(event.target.checked);
      setCheckedList(
        event.target.checked
          ? Array.from(
              new Set([
                ...checkedList,
                ...(timeReports?.map(item => item?._id) || []),
              ]),
            )
          : checkedList.filter(
              item => !timeReports?.map(item => item._id).includes(item),
            ),
      );
    }
  };

  const handleOpenModifyNoteType = (item: PatientTimeReportFragmentExtend) => {
    timeRecordSelected.current = item;
    toogleModifyNoteType(true);
  };
  // ${format(new Date(data[0].startedAt), 'MM/yy')}
  const allRecords = useMemo(() => {
    return dataByMonth(timeReports || [])
      .map(item => {
        return [
          ...item,
          renderDataTotal(
            item,
            format(new Date(item[0].startedAt), 'MM/yy'),
          ) as TRecordTotal,
        ];
      })
      .flat();
  }, [timeReports, dataByMonth]);
  const isTotalRecord = (
    record: PatientTimeReportFragment | TRecordTotal,
  ): record is TRecordTotal => {
    return (record as TRecordTotal).totalLabel !== undefined;
  };

  useEffect(() => {
    if (checkedList) {
      const listChecked = timeReports?.filter(item =>
        checkedList?.includes(item?._id),
      );
      setCheckedAll(listChecked?.length === timeReports?.length);
    }
  }, [checkedList, timeReports]);

  return (
    <>
      <DialogUpdateRecord
        open={openModifyNoteType}
        toogle={toogleModifyNoteType}
        timeRecordSelected={timeRecordSelected.current}
        idCode={report._id}
        codeName={report.code || ''}
      />
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={3}>
          <Collapse in={isExpand} timeout="auto" unmountOnExit>
            <Box display="flex" flexWrap="wrap">
              <TableContainer style={{ maxHeight: 300 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {checkItem && checkedList && setCheckedList && (
                        <TableCell>
                          <Checkbox
                            onChange={e => checkAllReport(e)}
                            color="primary"
                            checked={checkAll}
                          />
                        </TableCell>
                      )}
                      <TableCell>Date</TableCell>
                      <TableCell>Start Time</TableCell>
                      <TableCell>Stop Time</TableCell>
                      <TableCell>Time Spent</TableCell>
                      <TableCell>Navigator</TableCell>
                      <TableCellMaxWidthStyled maxwidth="300px">
                        Note Types
                      </TableCellMaxWidthStyled>
                      {!isCreatePdf && (
                        <>
                          <TableCell>Status</TableCell>
                          <TableCell align="right">Actions</TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allRecords.map(item => {
                      return isTotalRecord(item) ? (
                        <TableRow
                          style={{ backgroundColor: '#fafafa' }}
                          key={item._id}
                        >
                          <TableCell></TableCell>
                          <TableCell colSpan={2}>
                            <TypographyBold variant="subtitle2">
                              {item.totalLabel}
                            </TypographyBold>
                          </TableCell>
                          <TableCell colSpan={5} align="left">
                            <TypographyBold variant="subtitle2">
                              {item.timeSpend}
                            </TypographyBold>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow key={item?._id}>
                          {checkItem && checkedList && (
                            <TableCell align="left">
                              <Checkbox
                                onChange={e => checkItem(e, item._id)}
                                color="primary"
                                checked={checkedList.includes(item._id)}
                              />
                            </TableCell>
                          )}
                          <TableCell>
                            {item.startedAt
                              ? formatDate(item.startedAt)
                              : 'N/A'}
                          </TableCell>
                          <TableCell>
                            {item.startedAt
                              ? formatDateTime(item.startedAt)
                              : 'N/A'}
                          </TableCell>
                          <TableCell>
                            {item.stoppedAt
                              ? formatDateTime(item.stoppedAt)
                              : 'N/A'}
                          </TableCell>
                          <TableCell>
                            {item.stoppedAt ? (
                              <>
                                {renderTime(
                                  getDuration(item?.stoppedAt, item?.startedAt),
                                )}
                              </>
                            ) : (
                              'N/A'
                            )}
                          </TableCell>
                          <TableCell>{`${item.owner?.firstName || ''} ${item
                            .owner?.middleName || ''} ${item.owner?.lastName ||
                            ''}`}</TableCell>
                          <TableCellMaxWidthStyled maxwidth="400px">
                            <Typography variant="subtitle2">
                              {chipNoteTypes(item)}
                            </Typography>
                          </TableCellMaxWidthStyled>
                          {!isCreatePdf && (
                            <>
                              <TableCell>
                                {item?.status ? 'Billed' : 'Unbill'}
                              </TableCell>

                              <TableCell align="right">
                                {item.state ===
                                  PatientCptCodeStatus.Stopped && (
                                  <Tooltip arrow title="Update Record">
                                    <IconButton
                                      onClick={() =>
                                        handleOpenModifyNoteType(item)
                                      }
                                      color="primary"
                                      disabled={
                                        !isHavingPermissionTimeRecord(
                                          item?.owner?.idOwner,
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <ButtonDelete
                                  loadingDelete={loadingDeleteRecord}
                                  deleteItem={handleDelete}
                                  id={item?._id}
                                  disabled={
                                    !isHavingPermissionTimeRecord(
                                      item?.owner?.idOwner,
                                    )
                                  }
                                />
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {report?.timeReport?.length === 0 && (
              <TypographyItalic
                align="center"
                variant="subtitle1"
                color="textSecondary"
              >
                There are no reports
              </TypographyItalic>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default React.memo(TableDetailReport);
