import React, { useContext, useMemo } from 'react';
import { useQueryCustom, useCheckLogin } from 'hooks';
import {
  GET_GROUP_COMMENTS_BY_PATIENT,
  UNREAD_COMMENTS_SUBCRIPTION,
} from 'gql';
import {
  GroupCommentsFragment,
  GetGroupCommentsByPatientQuery,
  GetGroupCommentsByPatientQueryVariables,
  OnUnreadCommentsSubscription,
  OnUnreadCommentsSubscriptionVariables,
  PageInfo,
} from 'types.d';
import { Box } from '@material-ui/core';
import { BoxCenterComment } from 'components';
import { PatientDetailContext } from 'share/context';
import { useSubscription, useApolloClient } from '@apollo/react-hooks';
import WrapItem from './WrapItem';

type Props = {
  setTreatmentSelected: (treatment: GroupCommentsFragment) => void;
  treatmentSelected?: GroupCommentsFragment | null;
};

export const ListCommentByPatients: React.FC<Props> = ({
  setTreatmentSelected,
  treatmentSelected,
}) => {
  // const pageTreatment = useRef(1);

  const patientDetailContext = useContext(PatientDetailContext);

  const client = useApolloClient();

  const isLogin = useCheckLogin();

  const patientDetail = useMemo(() => {
    return patientDetailContext?.patient;
  }, [patientDetailContext]);

  const { data: dataGroup } = useQueryCustom<
    GetGroupCommentsByPatientQuery,
    GetGroupCommentsByPatientQueryVariables
  >({
    api: GET_GROUP_COMMENTS_BY_PATIENT,
    variables: {
      params: {
        _id: patientDetail?.owner?.user?.idUser,
      },
    },
  });
  //subcription when patient on list has comment and navigator see comment
  useSubscription<
    OnUnreadCommentsSubscription,
    OnUnreadCommentsSubscriptionVariables
  >(UNREAD_COMMENTS_SUBCRIPTION, {
    skip: !isLogin || !patientDetail?.owner?.user?.idUser,
    variables: { patientId: patientDetail?.owner?.user?.idUser },
    onSubscriptionData: data => {
      const dataGroupComments = client.readQuery<
        GetGroupCommentsByPatientQuery
      >({
        query: GET_GROUP_COMMENTS_BY_PATIENT,
        variables: {
          params: {
            _id: patientDetail?.owner?.user?.idUser,
          },
        },
      });
      const dataGroup = [
        ...(dataGroupComments?.getGroupCommentsByPatient?.edges || []),
      ];

      const dataSubcription =
        data.subscriptionData.data?.unreadCommentsSubscription?.edges[0]?.node;
      //get index of the changed group in list group
      const indexGroupChange = dataGroupComments?.getGroupCommentsByPatient?.edges.findIndex(
        item => item.node.treatment?._id === dataSubcription?.treatment?._id,
      );
      //if a changed group in list group
      if (
        indexGroupChange !== undefined &&
        indexGroupChange >= 0 &&
        dataSubcription
      ) {
        //update a changed group
        dataGroup[indexGroupChange].node = dataSubcription;
        //write cache with a sorted list group
        client.writeQuery<GetGroupCommentsByPatientQuery>({
          query: GET_GROUP_COMMENTS_BY_PATIENT,
          variables: {
            params: {
              _id: patientDetail?.owner?.user?.idUser,
            },
          },
          data: {
            ...dataGroupComments,
            getGroupCommentsByPatient: {
              ...dataGroupComments?.getGroupCommentsByPatient,
              edges: dataGroup.sort((a, b) => {
                return (
                  new Date(b?.node?.lastCommentAt).getTime() -
                  new Date(a?.node?.lastCommentAt).getTime()
                );
              }),
              pageInfo: dataGroupComments?.getGroupCommentsByPatient
                .pageInfo as PageInfo,
            },
          },
        });
      } else {
        //add new group in list group
        client.writeQuery<GetGroupCommentsByPatientQuery>({
          query: GET_GROUP_COMMENTS_BY_PATIENT,
          variables: {
            params: {
              _id: patientDetail?.owner?.user?.idUser,
            },
          },
          data: {
            ...dataGroupComments,
            getGroupCommentsByPatient: {
              ...dataGroupComments?.getGroupCommentsByPatient,
              edges: [
                { node: dataSubcription as GroupCommentsFragment },
                ...dataGroup,
              ],
              pageInfo: dataGroupComments?.getGroupCommentsByPatient
                .pageInfo as PageInfo,
            },
          },
        });
      }
    },
  });

  return (
    <>
      <Box
        width="100%"
        borderColor="grey.300"
        borderRadius={8}
        border={1}
        height={500}
        overflow="auto"
        position="relative"
      >
        <Box position="relative" height="100%">
          {dataGroup?.getGroupCommentsByPatient.edges.length === 0 && (
            <BoxCenterComment text="There are no treatments" />
          )}
          {dataGroup?.getGroupCommentsByPatient.edges.map(item => {
            return (
              <WrapItem
                key={item.node._id}
                name={item.node.treatment?.name || ''}
                isSelected={item.node._id === treatmentSelected?._id}
                unreadCount={item.node.unreadCount}
                callbackOnClick={() => setTreatmentSelected(item.node)}
              />
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default React.memo(ListCommentByPatients);
