import React from 'react';
import { useWebInstallPrompt } from 'hooks';
import { PromptToInstallContext } from 'share/context';
type Props = {};

export const PromtInstallProvider: React.FC<Props> = ({ children }) => {
  const [webInstallPrompt, handleWebInstallAccepted] = useWebInstallPrompt();

  return (
    <PromptToInstallContext.Provider
      value={{ webInstallPrompt, handleWebInstallAccepted }}
    >
      {children}
    </PromptToInstallContext.Provider>
  );
};

export default React.memo(PromtInstallProvider);
