import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { routes } from './config';
import PublicRoute from './PublicRoute';
import { ME_CLIENT } from 'gql';
import Layout from './Layout';
import { LinearProgress } from '@material-ui/core';
import ErrorBoundary from './components/ErrorBoundary';
import { useCheckRole } from 'hooks';
import Home from 'modules/home';
const LandingPage = lazy(() => import('../modules/landing_page'));
const VirtualTour = lazy(() => import('../modules/virtual_tour'));

const RouterComponent: React.FC = () => {
  const { data: dataMeClient } = useQuery(ME_CLIENT);

  const { checkLogin } = useCheckRole();

  return (
    <ErrorBoundary>
      <Suspense fallback={<LinearProgress />}>
        <Switch>
          {routes
            ?.map(item => {
              if (item?.path === '/assigned-treatments/') {
                return {
                  ...item,
                  keyPermission: checkLogin ? 'viewAssignedTreatment' : '',
                };
              }
              return item;
            })
            .map((route, i) => {
              return (
                <PublicRoute
                  dataMeClient={dataMeClient}
                  key={route.path}
                  {...route}
                />
              );
            })}
          <Route path="/landing-page/" render={() => <LandingPage />} />
          <Route
            path="/redefining-patient-experience/"
            render={() => <VirtualTour />}
          />

          <Route path="/provide-navigator/" render={() => <VirtualTour />} />
          <Route path="/" exact>
            <Redirect to="/home/" />
          </Route>
          <Route
            path="*"
            render={path => (
              <Layout>
                <Home url={path} />
              </Layout>
            )}
          />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};
export default RouterComponent;
