import gql from 'graphql-tag';
import { OrganizationFragment, MetaFragment } from './fragments';

export const GET_ORGANIZATIONS = gql`
  query GetOrganizations($params: GetOrganizationsInput!) {
    getOrganizations(params: $params) {
      nodes {
        ...Organization
      }
      meta {
        ...Meta
      }
    }
  }
  ${OrganizationFragment}
  ${MetaFragment}
`;

export const GET_BASIC_ORGANIZATIONS = gql`
  query GetBasicOrganizations($params: GetOrganizationsInput!) {
    getOrganizations(params: $params) {
      nodes {
        _id
        name
        default
      }
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query GetOrganization($params: IdInputType!) {
    getOrganization(params: $params) {
      ...Organization
    }
  }
  ${OrganizationFragment}
`;

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($params: CreateOrganizationInput!) {
    createOrganization(params: $params) {
      name
      orgAdmin {
        email
      }
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganizationInfo($params: UpdateOrganizationInput!) {
    updateOrganizationInfo(params: $params) {
      ...Organization
    }
  }
  ${OrganizationFragment}
`;

export const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($params: DeleteOrganizationInput!) {
    deleteOrganization(params: $params)
  }
`;

export const HANDLE_REPRESENTATIVE_INFO = gql`
  mutation HandleRepresentativeInfo($params: RepresentativeInput!) {
    handleRepresentativeInfo(params: $params)
  }
`;

export const UPDATE_DEFAULT_ORGANIZATION = gql`
  mutation UpdateDefaultOrganization(
    $params: GetHomePageConfigByOrganizationInput!
  ) {
    setDefaultHomePageConfig(params: $params)
  }
`;
