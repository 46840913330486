import React, { useMemo } from 'react';
import { Tooltip, Box, Typography, Chip, ListItem } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { AvatarStyled } from './styles';
import { LinkRouterStyled, TypoThreeDot } from 'share/component_css';
import { PatientDashboardInfoQuickViewFragment } from 'types.d';
import { useLocation } from 'react-router-dom';

type Props = {
  item?: PatientDashboardInfoQuickViewFragment;
};
const ListItemStyled = styled(ListItem)({
  '&&.Mui-selected': {
    backgroundColor: 'transparent',
  },
});

export const SubPatientDashboard: React.FC<Props> = ({ item }) => {
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const chipLabel = useMemo(() => {
    if (item?.numberOfPatient) {
      if (item?.numberOfPatient < 10) return `0${item?.numberOfPatient}`;
      else if (item?.numberOfPatient > 99) return '99+';
      return `${item?.numberOfPatient}`;
    }
    return 0;
  }, [item]);
  const checkSelected = (path: string) => {
    return `/patients/?id=${query.get('id')}` === path;
  };

  return (
    <>
      {item && (
        <>
          <LinkRouterStyled to={`/patients/?id=${item._id}`}>
            <ListItemStyled
              button
              selected={checkSelected(`/patients/?id=${item._id}`)}
            >
              <Box
                justifyContent="space-between"
                className="cursor-pointer ml-4 hover-color"
                display="flex"
                alignItems="center"
                width="100%"
              >
                <Box display="flex" alignItems="center" overflow="hidden">
                  <AvatarStyled>
                    <Typography variant="subtitle2">
                      {item.name ? item.name[0].toUpperCase() : 'N/A'}
                    </Typography>
                  </AvatarStyled>
                  <Tooltip title={item.name}>
                    <TypoThreeDot className="ml-4" variant="subtitle2">
                      {item.name}
                    </TypoThreeDot>
                  </Tooltip>
                </Box>
                <Tooltip title={item.numberOfPatient || ''}>
                  <Chip color="primary" variant="outlined" label={chipLabel} />
                </Tooltip>
              </Box>
            </ListItemStyled>
          </LinkRouterStyled>
        </>
      )}
    </>
  );
};

export default SubPatientDashboard;
