import React, { useContext } from 'react';
import { AutoCompleteSelect } from 'components';
import { useMutationCustom } from 'hooks';
import {
  GetChecklistForAttachQuery,
  GetChecklistForAttachQueryVariables,
  AttachPatientChecklistMutation,
  AttachPatientChecklistMutationVariables,
  Treatment,
} from 'types.d';
import {
  GET_CHECKLIST_FOR_ATTACH,
  ATTACH_PATIENT_CHECKLIST,
  GET_PATIENT_CHECKLISTS,
} from 'gql';
import Skeleton from '@material-ui/lab/Skeleton';
import { DialogTitleClose, DialogButton } from 'components';
import { DialogActions, Dialog } from '@material-ui/core';
import { useLazyQuery } from '@apollo/react-hooks';
import { PatientDetailContext } from 'share/context';
import { useServices } from '../../services';
import { useForm } from 'react-hook-form';
import { TypographyItalic } from 'share/component_css';
import { DialogContentStyled } from '../../styles';
import { customEvent } from 'modules/google_tag_manager';
type Props = {
  isOpen: boolean;
  toogleOpen: (status: boolean) => void;
  checklistAttach?: string | null;
  checklistId?: string;
  patientChecklistId?: string;
  isAdding?: boolean;
  callbackAfterAttach?: () => void;
};
type FormData = {
  checklist: Treatment;
};

export const DialogChecklist: React.FC<Props> = ({
  isOpen,
  toogleOpen,
  patientChecklistId,
  checklistAttach,
  checklistId,
  isAdding,
  callbackAfterAttach,
}) => {
  const { variablesChecklistTag } = useServices();

  const patientDetailContext = useContext(PatientDetailContext);
  const { handleSubmit, errors, control } = useForm<FormData>({
    mode: 'onBlur',
  });

  const [
    getChecklist,
    { data: checklists, loading: loadingChecklist },
  ] = useLazyQuery<
    GetChecklistForAttachQuery,
    GetChecklistForAttachQueryVariables
  >(GET_CHECKLIST_FOR_ATTACH, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const [attachPatientChecklist, { loading }] = useMutationCustom<
    AttachPatientChecklistMutation,
    AttachPatientChecklistMutationVariables
  >({
    api: ATTACH_PATIENT_CHECKLIST,
    refetchQueries: [
      {
        query: GET_PATIENT_CHECKLISTS,
        variables: variablesChecklistTag,
      },
    ],
    textSuccess: `${isAdding ? 'Add' : 'Update'
      } checklist to patient successfully`,
    callbackSuccess: () => {
      customEvent((isAdding ? "Add" : "Update") + "CheckListToPatientPD", "NAVIGATOR")
      handleClose();
      if (callbackAfterAttach) {
        callbackAfterAttach();
      }
    },
  });

  const handleClose = () => {
    toogleOpen(false);
    patientDetailContext?.handleSetDialogOpen('');
  };

  const enterDialog = () => {
    getChecklist({
      variables: {
        params: {
          patientId: patientDetailContext?.patient.owner?.idOwner,
          patientChecklistId: checklistId,
          checklistResultId: patientChecklistId,
          sortByOrder: { name: 1 },
        },
      },
    });
  };

  const handleSave = handleSubmit(data => {
    const params: AttachPatientChecklistMutationVariables['params'] = {
      patientId: patientDetailContext?.patient.owner?.idOwner,
      checklistId: data?.checklist?._id || null,
    };
    if (patientChecklistId) {
      params.checklistResultId = patientChecklistId;
    }
    if (checklistId) {
      params._id = checklistId;
    }
    attachPatientChecklist({
      variables: {
        params,
      },
    });
  });

  return (
    <>
      {isOpen && (
        <Dialog open={isOpen} onEnter={enterDialog} maxWidth="sm" fullWidth>
          <DialogTitleClose
            title={isAdding ? 'Add Checklist' : 'Replace Checklist'}
            onClose={handleClose}
          />
          <DialogContentStyled
            isadding={isAdding ? 'true' : 'false'}
            className="pt-0"
          >
            {loadingChecklist ? (
              <Skeleton variant="rect" width="100%" height={100} />
            ) : (
              <>
                {!isAdding && (
                  <TypographyItalic
                    className="mb-8"
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    * This action will replace your current checklist with the
                    selected checklist.
                  </TypographyItalic>
                )}
                <AutoCompleteSelect
                  small
                  disablePortal
                  required
                  name="checklist"
                  label="Checklist"
                  nameOption="name"
                  error={!!errors.checklist}
                  control={control}
                  options={checklists?.getChecklistForPatient.nodes || []}
                />
              </>
            )}
          </DialogContentStyled>
          <DialogActions>
            <DialogButton isCancel onClickButton={handleClose} />
            <DialogButton loading={loading} onClickButton={handleSave} />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default React.memo(DialogChecklist);
