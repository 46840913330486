import { TConfigRouter } from './interfaces';
import Layout from './Layout';
import ResetPassword from 'components/ResetPassword';
import CustomTreatments from 'modules/custom_menu_treatment';
import PatientProcedures from 'modules/patients_procedures';
import Profile from 'modules/profile';
import { SurveyDashboard } from 'modules/survey-dashboard';
import { lazy } from 'react';
// pages
const Treatments = lazy(() => import('../modules/treatments/index'));
const Dashboard = lazy(() => import('../modules/dashboard/index'));
const MediaManagement = lazy(() => import('../modules/media_management/index'));
const Treatment = lazy(() =>
  import('../modules/treatments/components/Treatment'),
);
const ChatbotView = lazy(() => import('../modules/chatbot'));
const SectionDisplay = lazy(() =>
  import('../modules/home/components/SectionDisplay'),
);
const User = lazy(() => import('../modules/user/index'));
const Home = lazy(() => import('../modules/home/index'));
const QrCode = lazy(() => import('../modules/qr_code/index'));
const TreatmentTree = lazy(() => import('../modules/treatment_tree/index'));
const Patients = lazy(() => import('../modules/patients/index'));
const CommentTreatment = lazy(() =>
  import('../modules/comments/CommentTreatment'),
);
const CommentPatient = lazy(() => import('../modules/comments/CommentPatient'));
const ConfirmEmail = lazy(() => import('../modules/confirm_email'));
const FollowUpType = lazy(() => import('../modules/follow_up_types'));
const NoteType = lazy(() => import('../modules/note_types'));
const Condition = lazy(() => import('../modules/condition'));
const PDFConversion = lazy(() => import('../modules/pdf-conversion'));
const ChatAnalysis = lazy(() => import('../modules/chat-analysis'));
const Provider = lazy(() => import('../modules/provider'));
const Organization = lazy(() => import('../modules/organization'));
const EmrLogs = lazy(() => import('../modules/emr_log'));
const TwilioLogs = lazy(() => import('../modules/Twilio_Logs'));
const OrgInformation = lazy(() => import('../modules/org_infor'));
const SurveyDetail = lazy(() => import('../modules/survey_detail'));
const ShortLinkSms = lazy(() => import('../modules/short_link_sms'));
const CommonConfigs = lazy(() => import('../modules/common-configs'));
const ReleaseNotes = lazy(() => import('../modules/release-notes'));
const SettingGroups = lazy(() => import('../modules/setting_groups'));
const TreatmentPathways = lazy(() => import('../modules/treatment_flows'));
const Tag = lazy(() => import('../modules/tag_management'));
const Modules = lazy(() => import('../modules/CPTCodes'));
const AssignFlow = lazy(() => import('../modules/treatment_flows/components/AssignFlow'));
const TreatmentFlow = lazy(() => import('../modules/treatment_flows/components/TreatmentFlow'));

export const routes: TConfigRouter[] = [
  {
    path: '/home/',
    component: Home,
    layout: Layout,
    exact: true,
    label: 'Home',
  },
  {
    path: '/section/:id',
    component: SectionDisplay,
    layout: Layout,
    exact: true,
    label: 'Home',
  },
  {
    path: '/home/treatment/:id/',
    component: Treatment,
    layout: Layout,
    exact: true,
    label: 'View Treatment',
    showCustomLabel: true,
  },
  {
    path: '/dashboard/',
    component: Dashboard,
    layout: Layout,
    exact: true,
    keyPermission: 'viewDashboard',
    label: 'Analytics Dashboard',
  },
  {
    path: '/assigned-treatments/',
    component: Treatments,
    layout: Layout,
    exact: true,
    label: 'My Treatment Modules',
    keyPermission: 'viewAssignedTreatment',
  },
  {
    path: '/media-management/',
    component: MediaManagement,
    layout: Layout,
    exact: true,
    keyPermission: 'viewMedia',
    label: 'Media',
  },

  {
    path: '/assigned-treatments/treatment/:id/',
    component: Treatment,
    layout: Layout,
    label: 'View Treatment',
    exact: true,
  },
  {
    path: '/users/',
    component: User,
    layout: Layout,
    keyPermission: 'viewUser',
    label: 'Users',
    exact: true,
  },
  {
    path: '/group-settings/',
    component: SettingGroups,
    layout: Layout,
    keyPermission: 'viewSettingGroup',
    label: 'Group Settings',
    exact: true,
  },
  {
    path: '/chat-analysis/',
    component: ChatAnalysis,
    layout: Layout,
    label: 'Chat Analysis',
    exact: true,
    keyPermission: 'viewChatAnalysis',
  },
  {
    path: '/treatment-flows/',
    component: TreatmentPathways,
    layout: Layout,
    keyPermission: 'viewSettingPathway',
    label: 'Treatment Flows',
    exact: true,
  },
  {
    path: '/pdf-conversion/',
    component: PDFConversion,
    layout: Layout,
    label: 'PDF Conversion',
    exact: true,
    keyPermission: 'viewPDFConversion',
  },
  {
    path: '/confirm-email/',
    component: ConfirmEmail,
    layout: Layout,
    exact: true,
    label: 'Confirm email',
  },
  {
    path: '/share/',
    component: QrCode,
    layout: Layout,
    exact: true,
    label: 'Share code',
  },
  {
    path: '/flow/',
    component: AssignFlow,
    layout: Layout,
    exact: true,
    label: 'Flow',
  },
  {
    path: '/assigned-flow/:flowSlug',
    component: TreatmentFlow,
    layout: Layout,
    exact: true,
    label: 'Flow',
  },
  {
    path: '/forgot-password/',
    component: ResetPassword,
    layout: Layout,
    exact: true,
    label: 'Reset Password',
  },
  {
    path: '/profile/',
    component: Profile,
    layout: Layout,
    exact: true,
    label: 'Profile',
  },
  {
    path: '/treatment-designer/',
    component: TreatmentTree,
    layout: Layout,
    exact: true,
    keyPermission: 'viewTreatments',
    label: 'Treatment Designer',
  },
  {
    path: '/patients/',
    component: Patients,
    layout: Layout,
    exact: true,
    label: 'Patient Dashboard',
    keyPermission: 'viewPatient',
  },
  {
    path: '/patients-procedures/',
    component: PatientProcedures,
    layout: Layout,
    exact: true,
    label: 'Patient Procedures Dashboard',
    keyPermission: 'viewPatient',
  },
  {
    path: '/public-surveys/',
    component: SurveyDashboard,
    layout: Layout,
    exact: true,
    label: 'Public Surveys',
    keyPermission: 'viewPatient',
  },
  {
    path: '/question-treatment/',
    component: CommentTreatment,
    layout: Layout,
    exact: true,
    label: 'Question from Treatments',
    keyPermission: 'viewComments',
  },
  {
    path: '/question-patient/',
    component: CommentPatient,
    layout: Layout,
    exact: true,
    label: 'Question from Patients',
    keyPermission: 'viewComments',
  },
  {
    path: '/assigned-treatment/treatment/:id/',
    component: Treatment,
    layout: Layout,
    label: 'View Treatment',
    exact: true,
  },
  {
    path: '/task-type/',
    component: FollowUpType,
    layout: Layout,
    label: 'Task Type',
    exact: true,
    keyPermission: 'viewFollowUpType',
  },
  {
    path: '/tag/',
    component: Tag,
    layout: Layout,
    label: 'Tags',
    exact: true,
    keyPermission: 'viewTag',
  },
  {
    path: '/setting/task-type/',
    component: FollowUpType,
    layout: Layout,
    label: 'Task Type',
    exact: true,
    keyPermission: 'viewFollowUpTypeSetting',
  },
  {
    path: '/setting/group-settings/',
    component: SettingGroups,
    layout: Layout,
    label: 'Group Settings',
    exact: true,
    keyPermission: 'viewSettingGroupSetting',
  },
  {
    path: '/setting/chat-analysis/',
    component: ChatAnalysis,
    layout: Layout,
    label: 'Chat Analysis',
    exact: true,
    keyPermission: 'viewSettingChatAnalysis',
  },
  {
    path: '/setting/treatment-flows/',
    component: TreatmentPathways,
    layout: Layout,
    label: 'Treatment Flows',
    exact: true,
    keyPermission: 'viewSettingTreatmentPathway',
  },
  {
    path: '/setting/pdf-conversion/',
    component: PDFConversion,
    layout: Layout,
    label: 'PDF Conversion',
    exact: true,
    keyPermission: 'viewSettingPDFConversion',
  },
  {
    path: '/setting/tag/',
    component: Tag,
    layout: Layout,
    label: 'Tags',
    exact: true,
    keyPermission: 'viewTagSetting',
  },
  {
    path: '/note-type/',
    component: NoteType,
    layout: Layout,
    label: 'Note Type',
    exact: true,
    keyPermission: 'viewNoteType',
  },
  {
    path: '/condition/',
    component: Condition,
    layout: Layout,
    label: 'Conditions',
    exact: true,
    keyPermission: 'viewCondition',
  },
  {
    path: '/treatment/:id/',
    component: Treatment,
    layout: Layout,
    exact: true,
    label: 'View Treatment',
    showCustomLabel: true,
  },
  {
    path: '/organizations/',
    component: Organization,
    layout: Layout,
    exact: true,
    label: 'Organizations',
    keyPermission: 'viewOrganizations',
  },
  {
    path: '/emr-logs/',
    component: EmrLogs,
    layout: Layout,
    exact: true,
    label: 'EMR Logs',
    keyPermission: 'viewEmrLog',
  },
  {
    path: '/administration/emr-logs/',
    component: EmrLogs,
    layout: Layout,
    exact: true,
    label: 'EMR Logs',
    keyPermission: 'viewEmrLogAdministration',
  },
  {
    path: '/administration/release-notes/',
    component: ReleaseNotes,
    layout: Layout,
    exact: true,
    label: 'Release notes',
    keyPermission: 'viewReleaseNotesAdministration',
  },
  {
    path: '/twilio-logs/',
    component: TwilioLogs,
    layout: Layout,
    exact: true,
    label: 'Twilio Logs',
    keyPermission: 'viewTwilioLog',
  },
  {
    path: '/organization-information/',
    component: OrgInformation,
    layout: Layout,
    exact: true,
    label: 'Organization Setup',
    keyPermission: 'viewOrgInfo',
  },
  {
    path: '/s/:id',
    component: ShortLinkSms,
    layout: Layout,
    exact: true,
    label: 'Short Link SMS',
  },
  {
    path: '/survey/',
    component: SurveyDetail,
    layout: Layout,
    exact: true,
    label: 'Survey Detail',
  },
  {
    path: '/providers/',
    component: Provider,
    layout: Layout,
    label: 'Providers',
    exact: true,
    keyPermission: 'viewProvider',
  },
  {
    path: '/setting/providers/',
    component: Provider,
    layout: Layout,
    label: 'Providers',
    exact: true,
    keyPermission: 'viewProviderSetting',
  },
  {
    path: '/common-configs/',
    component: CommonConfigs,
    layout: Layout,
    exact: true,
    label: 'Common Configs',
    keyPermission: 'viewCommonConfigs',
  },
  {
    path: '/release-notes/',
    component: ReleaseNotes,
    layout: Layout,
    exact: true,
    keyPermission: 'viewReleaseNotes',
    label: 'Release notes',
  },
  {
    path: '/release-note/',
    component: ReleaseNotes,
    layout: Layout,
    exact: true,
    keyPermission: 'viewReleaseNotesUser',
    label: 'Release notes',
  },
  {
    path: '/module/',
    component: Modules,
    layout: Layout,
    exact: true,
    keyPermission: 'viewModules',
    label: 'Modules',
  },
  {
    path: '/chatbotsearch',
    component: ChatbotView,
    layout: Layout,
    exact: true,
    label: '',
  },
  {
    path: '/my-treatments/',
    component: CustomTreatments,
    layout: Layout,
    exact: true,
    label: 'My Treatments',
    keyPermission: 'viewMyTreatments'
  },
  {
    path: '/fav-treatments/',
    component: CustomTreatments,
    layout: Layout,
    exact: true,
    label: 'My Favorite Treatments',
    keyPermission: 'viewFavoriteTreatments'
  },
  {
    path: '/all-treatments/',
    component: CustomTreatments,
    layout: Layout,
    exact: true,
    label: 'All Treatments',
    keyPermission: 'viewAllTreatments'
  },
  {
    path: '/pub-treatments/',
    component: CustomTreatments,
    layout: Layout,
    exact: true,
    label: 'Public Treatments',
  },
  {
    path: '/pvt-treatments/',
    component: CustomTreatments,
    layout: Layout,
    exact: true,
    label: 'Private Treatments',
    keyPermission: 'viewPrivateTreatments'
  },
];
