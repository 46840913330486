import React, { useState, useContext } from 'react';
import {
  DialogActions,
  DialogContent,
  TableHead,
  TableContainer,
  Dialog,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Box,
  MenuItem,
  Select,
} from '@material-ui/core';
import { DialogButton, LoadingTable } from 'components';
import {
  GetSubTreatmentForDeleteQuery,
  GetTreatmentParentQuery,
  BulkUpdateTreatmentMutation,
  BulkUpdateTreatmentMutationVariables,
  ChangingBehavior,
  GetTreatmentParentQueryVariables,
  DeleteTreatmentMutation,
  GetSubTreatmentForDeleteQueryVariables,
  DeleteTreatmentMutationVariables,
  TreatmentType,
} from 'types.d';
import { PaperBorder, TypographyBold } from 'share/component_css';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { handleError } from 'share/utils';
import {
  BULK_UPDATE_TREATMENT,
  DELETE_TREATMENT,
  GET_TREATMENT_PARENT,
  GET_SUB_TREATMENT_FOR_DELETE,
} from 'gql';
import { PopoverContext } from 'share/context';

type Props = {
  open: string;
  setOpenModal: React.Dispatch<React.SetStateAction<string>>;
  selectedTreatment: string;
  handleSuccessDelete: (idNewParent?: string) => void;
  type?: string;
};

export const ModalSelectParent: React.FC<Props> = ({
  open,
  setOpenModal,
  selectedTreatment,
  handleSuccessDelete,
  type,
}) => {
  const popoverContext = useContext(PopoverContext);

  const { enqueueSnackbar } = useSnackbar();
  const [getParentTreatment, { data: treatmentsParent }] = useLazyQuery<
    GetTreatmentParentQuery,
    GetTreatmentParentQueryVariables
  >(GET_TREATMENT_PARENT, {
    fetchPolicy: 'cache-and-network',
  });

  const [
    loadSubTreatment,
    { loading: loadingSubTreatment, data: subTreatment },
  ] = useLazyQuery<
    GetSubTreatmentForDeleteQuery,
    GetSubTreatmentForDeleteQueryVariables
  >(GET_SUB_TREATMENT_FOR_DELETE, {
    fetchPolicy: 'cache-and-network',
  });

  const [deleteTreatment] = useMutation<
    DeleteTreatmentMutation,
    DeleteTreatmentMutationVariables
  >(DELETE_TREATMENT, {
    onCompleted(data) {
      enqueueSnackbar(`Delete ${(type as string).toLowerCase()} successfully`, {
        variant: 'success',
      });
      handleSuccessDelete(parent);
    },
    onError(error) {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
  });

  const [bulkUpdateTreatment] = useMutation<
    BulkUpdateTreatmentMutation,
    BulkUpdateTreatmentMutationVariables
  >(BULK_UPDATE_TREATMENT, {
    onCompleted(data) {
      deleteTreatment({ variables: { params: { _id: selectedTreatment } } });
    },
    onError(error) {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
  });

  const [parent, setParent] = useState('');

  const handleClose = () => {
    setOpenModal('');
  };

  const handleChangeParent = (event: React.ChangeEvent<{ value: unknown }>) => {
    setParent(event.target.value as string);
  };

  const handleDelete = () => {
    if (subTreatment) {
      const subSections = subTreatment?.getTreatment?.subSections
        ? subTreatment?.getTreatment?.subSections.map(item => {
            return {
              _id: item._id,
              parent: parent === '' ? null : parent,
              changingBehavior: ChangingBehavior.Move,
            };
          })
        : [];
      const survey = subTreatment?.getTreatment?.survey
        ? subTreatment?.getTreatment?.survey.map(item => {
            return {
              _id: item.idSurvey,
              parent: parent === '' ? null : parent,
              changingBehavior: ChangingBehavior.Move,
            };
          })
        : [];
      bulkUpdateTreatment({
        variables: {
          params: [...subSections, ...survey],
        },
      });
    }
  };
  const onEnter = () => {
    getParentTreatment({
      variables: {
        params: {
          treatmentId: selectedTreatment,
          type: Object.is(type as string, TreatmentType.Folder)
            ? [TreatmentType.Folder]
            : [TreatmentType.Folder, TreatmentType.Treatment],
          sortByOrder: { type: 1, name: 1 },
        },
      },
    });
    loadSubTreatment({
      variables: {
        params: {
          _id: selectedTreatment,
        },
      },
    });
    setParent('');
  };

  const getSub = () => {
    return subTreatment ? subTreatment?.getTreatment?.subSections ?? [] : [];
  };
  const getSurvey = () => {
    return subTreatment ? subTreatment?.getTreatment?.survey ?? [] : [];
  };

  return (
    <>
      <Dialog
        open={open === 'selectParent'}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        onEnter={onEnter}
        disableBackdropClick
        disableEscapeKeyDown
        onExited={() => popoverContext!.closePopover()}
      >
        <DialogContent id="alert-dialog-content">
          <Box
            display="flex"
            justifyContent="center"
            mb={2}
            alignItems="center"
          >
            <Box paddingRight={1}>
              <TypographyBold variant="body1">
                You need to select other item
              </TypographyBold>
            </Box>
            <Box width="100px">
              <Select
                value={parent}
                fullWidth
                onChange={handleChangeParent}
                displayEmpty
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value="">None</MenuItem>
                {treatmentsParent &&
                  treatmentsParent.getTreatmentsForUpdate.nodes.map(item => {
                    return (
                      <MenuItem key={item._id} value={item._id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Box>
          </Box>

          <TableContainer component={PaperBorder}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="right">Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingSubTreatment && <LoadingTable colSpan={3} />}
                {getSub().length !== 0 &&
                  getSub().map(row => (
                    <TableRow key={row._id}>
                      <TableCell>{row._id}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="right">{row.type}</TableCell>
                    </TableRow>
                  ))}
                {getSurvey().length !== 0 &&
                  getSurvey().map(row => (
                    <TableRow key={row.idSurvey}>
                      <TableCell>{row.idSurvey}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="right">{row.type}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <DialogButton isCancel onClickButton={handleClose} />
          <DialogButton onClickButton={handleDelete} />
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ModalSelectParent;
