import React, { useMemo, useState } from 'react';
import { DataFilterDate } from '../../interfaces';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Divider,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Rule } from 'modules/patients/interfaces';
import { WrapDate, PopoverStyled, GridCustomBorder } from '../../styles';
import CheckIcon from '@material-ui/icons/Check';
import { DatePicker } from '@material-ui/pickers';
import { compareAsc } from 'date-fns';
import { useTheme } from '@material-ui/core/styles';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { ButtonLoading } from 'components';
import isSameDay from 'date-fns/isSameDay';
import { convertDate } from 'share/utils';
import { OPTIONS_DATE_RANGE } from 'CONST';
import { MenuItemStyled } from 'share/component_css';

type Props = {
  filterData: (date?: DataFilterDate | string) => void;
  dateSelected?: DataFilterDate | string;
  title?: string;
  acceptNull?: boolean;
  dataFilter?: Rule;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
  isFlexibleValue?: boolean;
};

export const DialogFilterDate: React.FC<Props> = ({
  filterData,
  dateSelected,
  acceptNull,
  handleClose,
  anchorEl,
  isFlexibleValue,
}) => {
  const [dataFilterDate, setdataFilterDate] = useState<DataFilterDate>({
    startDate: null,
    endDate: null,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [dateRange, setDateRange] = useState('');

  const [switchToggle, setSwitchToggle] = useState(false);
  const switchToggleChanges = () => {
    setSwitchToggle(!switchToggle);
  };

  const open = Boolean(anchorEl);

  const filterDate = (isReset?: boolean) => {
    if (isReset) {
      setdataFilterDate({ startDate: null, endDate: null });
      filterData('allDates');
      setDateRange('allDates');
      return;
    }
    if (dateRange) {
      isFlexibleValue
        ? filterData(dateRange)
        : filterData(convertDate(dateRange) as DataFilterDate);
      return;
    }

    const { startDate, endDate } = dataFilterDate;
    if (acceptNull || (dataFilterDate.endDate && dataFilterDate.startDate)) {
      const start = startDate
        ? new Date(new Date(startDate).setHours(0, 0, 0))
        : null;
      const end = endDate
        ? new Date(new Date(endDate).setHours(23, 59, 59))
        : null;
      filterData({
        startDate: start ? start.toISOString() : start,
        endDate: end ? end.toISOString() : end,
      });
    }
  };

  const handleDateChange = (date: Date | null, type: string) => {
    setDateRange('');
    const { endDate } = dataFilterDate;
    if (
      type === 'startDate' &&
      endDate &&
      date &&
      compareAsc(new Date(date), new Date(endDate))
    ) {
      setdataFilterDate({
        ...dataFilterDate,
        [type]: new Date(date).toISOString(),
        endDate:
          new Date(endDate) < date
            ? new Date(date).toISOString()
            : new Date(endDate).toISOString(),
      });
      return;
    } else {
      setdataFilterDate({
        ...dataFilterDate,
        endDate: null,
      });
    }
    setdataFilterDate({
      ...dataFilterDate,
      [type]: date,
    });
  };

  const onEnterDialog = () => {
    const dateConverted =
      typeof dateSelected === 'string'
        ? convertDate(dateSelected)
        : dateSelected;
    setdataFilterDate(dateConverted || { startDate: null, endDate: null });
    if (typeof dateSelected === 'string') {
      setDateRange(dateSelected);
    }
  };
  const selectDateRange = (dateRangeSelected: string) => {
    setDateRange(dateRangeSelected);
    setdataFilterDate(
      dateRangeSelected === 'allDates'
        ? { startDate: null, endDate: null }
        : (convertDate(dateRangeSelected) as DataFilterDate),
    );
  };

  const renderDateRange = useMemo(() => {
    return OPTIONS_DATE_RANGE?.find(
      item =>
        convertDate(item.value).endDate ===
          (convertDate(dateRange).endDate ||
            (dataFilterDate as DataFilterDate).endDate) &&
        convertDate(item.value).startDate ===
          (convertDate(dateRange).startDate ||
            (dataFilterDate as DataFilterDate).startDate),
    );
  }, [dateRange, dataFilterDate]);

  return (
    <>
      <PopoverStyled
        open={open}
        onEnter={onEnterDialog}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container justify="center">
          {!isMobile ? (
            <>
              <GridCustomBorder item sm={2}>
                <Box display="flex" flexDirection="column" mt={2}>
                  {OPTIONS_DATE_RANGE.map(item => {
                    return (
                      <MenuItemStyled
                        onClick={() => selectDateRange(item.value)}
                        key={item.value}
                        value={item.value}
                        selected={renderDateRange?.value === item.value}
                      >
                        {item.title}
                      </MenuItemStyled>
                    );
                  })}
                </Box>
              </GridCustomBorder>
              <Grid item sm={5}>
                <Typography variant="h6" align="center" className="pt-8">
                  Start date
                </Typography>
                <WrapDate>
                  <DatePicker
                    autoOk
                    disableToolbar
                    variant="static"
                    openTo="date"
                    value={dataFilterDate.startDate}
                    onChange={date => handleDateChange(date, 'startDate')}
                    renderDay={(
                      day,
                      selectedDate,
                      isInCurrentMonth,
                      dayComponent,
                    ) => {
                      return (
                        <Box
                          className={
                            day && dataFilterDate.startDate
                              ? isSameDay(
                                  day,
                                  new Date(dataFilterDate.startDate),
                                )
                                ? 'selected'
                                : ''
                              : ''
                          }
                        >
                          {dayComponent}
                        </Box>
                      );
                    }}
                  />
                </WrapDate>
              </Grid>
              <Grid item sm={5}>
                <Typography variant="h6" align="center" className="pt-8">
                  End date
                </Typography>
                <WrapDate>
                  <DatePicker
                    disableToolbar
                    autoOk
                    variant="static"
                    openTo="date"
                    value={dataFilterDate.endDate}
                    minDate={dataFilterDate.startDate}
                    renderDay={(
                      day,
                      selectedDate,
                      isInCurrentMonth,
                      dayComponent,
                    ) => {
                      return (
                        <Box
                          className={
                            day && dataFilterDate.endDate
                              ? isSameDay(day, new Date(dataFilterDate.endDate))
                                ? 'selected'
                                : ''
                              : ''
                          }
                        >
                          {dayComponent}
                        </Box>
                      );
                    }}
                    onChange={date => handleDateChange(date, 'endDate')}
                  />
                </WrapDate>
              </Grid>
            </>
          ) : !switchToggle ? (
            <>
              <Grid item>
                <Typography variant="h6" align="center" className="pt-8">
                  Start date
                </Typography>
                <WrapDate>
                  <DatePicker
                    autoOk
                    variant="static"
                    openTo="date"
                    disableToolbar
                    value={dataFilterDate.startDate}
                    onChange={date => handleDateChange(date, 'startDate')}
                  />
                </WrapDate>
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                <Typography variant="h6" align="center" className="pt-8">
                  End date
                </Typography>
                <WrapDate>
                  <DatePicker
                    autoOk
                    variant="static"
                    openTo="date"
                    disableToolbar
                    value={dataFilterDate.endDate}
                    minDate={dataFilterDate.startDate}
                    maxDateMessage
                    onChange={date => handleDateChange(date, 'endDate')}
                  />
                </WrapDate>
              </Grid>
            </>
          )}
        </Grid>
        <Divider />
        <Grid container justify="flex-end" spacing={2} alignItems="center">
          {!isMobile ? (
            <>
              <Grid item className="mr-16 mt-8">
                <ButtonLoading
                  text="Reset"
                  className="mr-8"
                  callbackClick={() => filterDate(true)}
                  Icon={<RotateLeftIcon />}
                  color="default"
                />
                <ButtonLoading
                  text="Save"
                  callbackClick={() => filterDate()}
                  Icon={<CheckIcon />}
                />
              </Grid>
            </>
          ) : (
            <Grid item>
              <Tooltip title="Reset">
                <IconButton aria-label="reset" onClick={() => filterDate(true)}>
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Save">
                <IconButton
                  aria-label="save"
                  color="primary"
                  onClick={() => filterDate()}
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          alignItems="center"
          justify="center"
          spacing={2}
          className="pb-16"
        >
          {isMobile && (
            <>
              <Grid item>
                <Typography variant="overline">Start</Typography>
              </Grid>
              <ToggleSwitch
                name="start date"
                checked={switchToggle}
                onChange={switchToggleChanges}
                color="primary"
              />
              <Grid item>
                {' '}
                <Typography variant="overline">End</Typography>
              </Grid>
            </>
          )}
        </Grid>
      </PopoverStyled>
    </>
  );
};

export default React.memo(DialogFilterDate);
