import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { commentToTree } from 'share/utils';
import Comment from './Comment';
import FormAddComment from './FormAddComment';
import { Box } from '@material-ui/core';
import {
  useQueryCustom,
  useCustomPermission,
  useCheckLogin,
  useMutationCustom,
} from 'hooks';
import { GET_COMMENTS, SEEN_COMMENT } from 'gql';
import {
  GetCommentsQuery,
  GetCommentsQueryVariables,
  CommentEdgeFragment,
  OnTreatmentCommentSubscriptionVariables,
  OnTreatmentCommentSubscription,
  SeenCommentMutation,
  SeenCommentMutationVariables,
} from 'types.d';
import { CommentsContext } from 'share/context';
import { TypographyItalic } from 'share/component_css';
import LoadingComment from './LoadingComment';
import { BoxCenterComment } from 'components';
import { TREATMENT_COMMENT_SUBCRIPTION } from 'gql';
import { useSubscription } from '@apollo/react-hooks';

type Props = {
  treatmentId: string;
  contributors?: string[] | null;
  treatmentOwner: string;
  hasBoxNoComments?: boolean;
  fromPatient?: string;
  isTreatmentPage?: boolean;
  fixedHeight?: boolean;
};

export type CommentTreeInterface = CommentEdgeFragment & {
  children: CommentEdgeFragment[];
};

export const Messages: React.FC<Props> = ({
  treatmentId,
  contributors,
  treatmentOwner,
  hasBoxNoComments,
  fromPatient,
  isTreatmentPage,
  fixedHeight,
}) => {
  const currentTime = useRef(new Date());

  const firstLoading = useRef(true);

  const infoComment = useRef<{
    root: { cursor: string | null; hasNextPage: boolean } | null;
    child: { [key: string]: { cursor: string | null; hasNextPage: boolean } };
  }>({
    root: null,
    child: {},
  });

  const loading = useRef('root');

  const [comments, setComments] = useState<CommentEdgeFragment[]>([]);

  const {
    isAdmin,
    isNavigator,
    isOwnerAndContributors,
  } = useCustomPermission();

  const isLogin = useCheckLogin();

  const [seenComment] = useMutationCustom<
    SeenCommentMutation,
    SeenCommentMutationVariables
  >({
    api: SEEN_COMMENT,
  });

  //subcription when get new comment
  useSubscription<
    OnTreatmentCommentSubscription,
    OnTreatmentCommentSubscriptionVariables
  >(TREATMENT_COMMENT_SUBCRIPTION, {
    variables: {
      topic: treatmentId,
      patientId: fromPatient || null,
    },
    onSubscriptionData: data => {
      if (data.subscriptionData.data) {
        setComments([
          {
            node: data.subscriptionData.data.treatmentCommentSubscription,
            __typename: 'CommentEdge',
          } as CommentEdgeFragment,
          ...comments,
        ]);
        seenComment({
          variables: {
            params: {
              ids: [
                data.subscriptionData.data.treatmentCommentSubscription._id,
              ],
              treatmentId,
            },
          },
        });
      }
    },
    skip: !isLogin,
  });

  const {
    error,
    fetchMore: fetchMoreComment,
    loading: loadingComments,
  } = useQueryCustom<GetCommentsQuery, GetCommentsQueryVariables>({
    api: GET_COMMENTS,
    variables: {
      params: {
        limit: 10,
        treatment: treatmentId,
        fromPatient: fromPatient || null,
        broadcastQuesFromTreatments: true,
      },
    },
    callbackSuccess: data => {
      if (!infoComment.current.root) {
        infoComment.current.root = {
          cursor: data.getComments.pageInfo.endCursor || null,
          hasNextPage: data.getComments.pageInfo.hasNextPage,
        };
      }

      seenComment({
        variables: {
          params: {
            ids: data.getComments.edges.map(item => item.node._id),
            treatmentId,
          },
        },
      });

      setComments(data.getComments.edges);
    },
    callbackError: () => {
      setComments([]);
    },
  });
  useEffect(() => {
    if (error) {
      setComments([]);
      infoComment.current = {
        root: null,
        child: {},
      };
    }
  }, [error]);

  useEffect(() => {
    if (treatmentId) {
      infoComment.current = {
        root: null,
        child: {},
      };
      loading.current = 'root';
      currentTime.current = new Date();
    }
  }, [treatmentId]);

  //anonymous,admin, navigator is not owner and contributor of treatment are not able to comment
  const isAbleToComment = useMemo(() => {
    if (isNavigator) {
      return (
        isOwnerAndContributors([treatmentOwner, ...(contributors || [])]) &&
        !isTreatmentPage
      );
    }
    return !isAdmin && isLogin;
  }, [
    isNavigator,
    isAdmin,
    isLogin,
    isOwnerAndContributors,
    treatmentOwner,
    contributors,
    isTreatmentPage,
  ]);

  const getReplies = useCallback(
    ({ isRoot, parent }: { isRoot: boolean; parent?: string }) => {
      loading.current = isRoot ? 'root' : (parent as string);
      firstLoading.current = false;
      fetchMoreComment({
        variables: {
          params: {
            limit: 5,
            treatment: treatmentId,
            cursor: isRoot
              ? infoComment.current.root?.cursor
              : infoComment.current.child[parent || '']?.cursor || null,
            parent: parent || null,
            fromTime:
              isRoot || infoComment.current.child[parent || '']?.cursor
                ? null
                : currentTime.current,
            fromPatient: fromPatient || null,
            broadcastQuesFromTreatments: true,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          if (isRoot) {
            infoComment.current = {
              ...infoComment.current,
              root: {
                cursor: fetchMoreResult?.getComments.pageInfo.endCursor || null,
                hasNextPage: fetchMoreResult.getComments.pageInfo.hasNextPage,
              },
            };
          } else {
            infoComment.current = {
              ...infoComment.current,
              child: infoComment.current.child
                ? {
                    ...infoComment.current.child,
                    [parent as string]: {
                      cursor:
                        fetchMoreResult?.getComments.pageInfo.endCursor || null,
                      hasNextPage:
                        fetchMoreResult.getComments.pageInfo.hasNextPage,
                    },
                  }
                : {
                    [parent as string]: {
                      cursor:
                        fetchMoreResult?.getComments.pageInfo.endCursor || null,
                      hasNextPage:
                        fetchMoreResult.getComments.pageInfo.hasNextPage,
                    },
                  },
            };
          }
          return {
            getComments: {
              ...prev.getComments,
              pageInfo: fetchMoreResult.getComments.pageInfo,
              edges: [...comments, ...fetchMoreResult!.getComments!.edges!],
            },
          };
        },
      });
    },
    [comments, fetchMoreComment, fromPatient, treatmentId],
  );

  const getMoreReplies = useCallback(
    (parent: string) => {
      getReplies({ isRoot: false, parent });
    },
    [getReplies],
  );

  const treeComment = useMemo(() => {
    if (comments) {
      return commentToTree(comments);
    }
    return [];
  }, [comments]);

  const isFirstLoading = useMemo(() => {
    if (firstLoading.current) {
      return !loadingComments;
    }
    return true;
  }, [loadingComments]);

  const removeComments = (idComments: string[]) => {
    setComments(comments.filter(item => !idComments.includes(item.node._id)));
  };

  return (
    <CommentsContext.Provider
      value={{
        idOwner: treatmentOwner,
        contributors,
        treatmentId,
        isAbleToComment,
      }}
    >
      <Box
        width="100%"
        height={fixedHeight ? 500 : 'calc(100vh - 208px)'}
        display="flex"
        flexDirection="column"
      >
        <Box
          borderColor="grey.300"
          borderRadius={8}
          border={1}
          px={2}
          position="relative"
          flexGrow={1}
          style={{ overflowY: 'hidden' }}
        >
          <Box
            height={isTreatmentPage ? 'auto' : 'calc(100% - 28px)'}
            overflow="auto"
          >
            {!loadingComments && hasBoxNoComments && comments.length === 0 && (
              <BoxCenterComment text="There are no comments" />
            )}
            {isFirstLoading &&
              (treeComment as CommentTreeInterface[]).map(item => {
                return (
                  <Comment
                    loadingReplies={
                      loading.current === item.node._id && loadingComments
                    }
                    key={item.node._id}
                    getMoreReplies={getMoreReplies}
                    message={item}
                    hasNextPage={
                      infoComment.current.child[item.node._id]?.hasNextPage ||
                      false
                    }
                    removeComments={removeComments}
                    sendTo={fromPatient}
                  />
                );
              })}
            {loadingComments && loading.current === 'root' && (
              <LoadingComment />
            )}
          </Box>

          {!loadingComments && infoComment.current.root?.hasNextPage && (
            <>
              {isTreatmentPage ? (
                <TypographyItalic
                  color="primary"
                  variant="subtitle2"
                  className="has-underline cursor-pointer"
                  onClick={() => getReplies({ isRoot: true })}
                >
                  Show more comments...
                </TypographyItalic>
              ) : (
                <Box
                  width="100%"
                  position="absolute"
                  textAlign="center"
                  bottom={0}
                  left={0}
                  height={28}
                  bgcolor="primary.main"
                  color="common.white"
                  lineHeight="28px"
                  borderRadius="0 0 8px 8px"
                  className="cursor-pointer"
                  onClick={() => getReplies({ isRoot: true })}
                >
                  Show more comments...
                </Box>
              )}
            </>
          )}
        </Box>
        {/* anonymous and admin,navigator is not owner and contributors of treatment do not have permission to comment */}
        {isAbleToComment && (
          <FormAddComment
            isTreatmentPage={isTreatmentPage}
            sendTo={fromPatient}
            isOnPatientDashboard={fixedHeight}
          />
        )}
      </Box>
    </CommentsContext.Provider>
  );
};

export default React.memo(Messages);
