import gql from 'graphql-tag';
import {
  MetaFragment,
  GroupTreatmentSettingFragment,
  GroupTreatmentSettingAssignFragment,
} from './fragments';

export const GET_GROUP_TREATMENTS = gql`
  query GetGroupTreatments($params: GetGroupTreatmentInput!) {
    getGroupTreatments(params: $params) {
      nodes {
        ...GroupTreatmentSetting
      }
      meta {
        ...Meta
      }
    }
  }
  ${MetaFragment}
  ${GroupTreatmentSettingFragment}
`;

export const CREATE_GROUP_TREATMENT = gql`
  mutation CreateGroupTreatment($params: CreateGroupTreatmentInput!) {
    createGroupTreatment(params: $params) {
      idFollowUp: _id
    }
  }
`;

export const UPDATE_GROUP_TREATMENT = gql`
  mutation UpdateGroupTreatment($params: UpdateGroupTreatmentInput!) {
    updateGroupTreatment(params: $params) {
      ...GroupTreatmentSetting
    }
  }
  ${GroupTreatmentSettingFragment}
`;

export const DELETE_GROUP_TREATMENT = gql`
  mutation DeleteGroupTreatment($params: DeleteGroupTreatmentInput!) {
    deleteGroupTreatment(params: $params)
  }
`;

export const GET_GROUP_TREATMENT_ASSIGN = gql`
  query GetGroupTreatmentAssign($params: PaginateInputType!) {
    getGroupTreatments4Assign(params: $params) {
      nodes {
        ...GroupTreatmentSettingAssign
      }
      meta {
        ...Meta
      }
    }
  }
  ${MetaFragment}
  ${GroupTreatmentSettingAssignFragment}
`;
