import React, { useState } from 'react';
import { usePopover } from 'hooks';
import { Box, Tooltip, IconButton } from '@material-ui/core';
import { ColorBox, Color, createColor } from 'material-ui-color';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import { DialogButton } from 'components';
import { DEFAULT_COLOR_TAG } from 'CONST';

type Props = {
  colorDefault?: string;
  setDisplayColor: (color: string) => void;
};

export const PopoverPickColor: React.FC<Props> = ({
  colorDefault,
  setDisplayColor,
}) => {
  const [color, setColor] = useState(colorDefault || DEFAULT_COLOR_TAG);
  const [open, close, DefaultPopover, defaultPopoverProps] = usePopover();

  const handleChange = (newValue: Color) => {
    setColor(newValue.css.backgroundColor as string);
  };

  const handleSetColor = () => {
    setDisplayColor(color);
    close();
  };

  return (
    <>
      <DefaultPopover {...defaultPopoverProps}>
        <Box p={1}>
          <ColorBox value={createColor(color)} onChange={handleChange} />
          <Box display="flex" flexDirection="row-reverse">
            <DialogButton className="ml-8" onClickButton={handleSetColor} />
            <DialogButton isCancel onClickButton={close} />
          </Box>
        </Box>
      </DefaultPopover>
      <Tooltip title="Pick color">
        <IconButton
          aria-label="pick color"
          onClick={e => {
            open(e);
            setColor(colorDefault || DEFAULT_COLOR_TAG);
          }}
          color="default"
        >
          <FormatColorFillIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default React.memo(PopoverPickColor);
