import { useRef, useState, useEffect } from 'react';
import { HEIGHT_A4, WIDTH_A4 } from 'CONST';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import {
  PatientFollowUp,
  PatientChecklistPdfFragment,
  PatientCondStageFragment,
  Gender,
  PatientFragment,
  PatientNoteFragment,
  PatientTimeRecordFragment,
} from 'types.d';
import { formatDate, renderTime, totalBilledTime } from 'share/utils';
import { Styles, RowInput } from 'jspdf-autotable';
import * as Survey from 'survey-react';
import { useCommonPdf } from 'hooks';
type Tasks = Pick<PatientFollowUp, '_id' | 'note' | 'followUpDate'>;

export const useCapturePdf = (
  surveyName: string,
  patientDetail?: PatientFragment,
) => {
  const [isLoading, setIsLoading] = useState<
    'createPdf' | 'sendEmr' | undefined
  >(undefined);

  const [checklistModels, setChecklistModels] = useState<{
    isChanged: boolean;
    checklists: Survey.ReactSurveyModel[];
  }>({ isChanged: false, checklists: [] });

  const dataForCreatePdf = useRef<
    | {
        callbackCreatePdf: (doc: jsPDF) => void;
        gender: Gender | string;
        mrn?: string;
        tasks?: Tasks[];
        checklists?: PatientChecklistPdfFragment[];
        conditions?: PatientCondStageFragment[];
        records?: PatientTimeRecordFragment[];
        notes?: PatientNoteFragment['notes'];
      }
    | undefined
  >(undefined);
  const elementByPage = useRef<{ element: Element; height: number }[][]>([[]]);
  const allPromiseCanvas = useRef<Promise<HTMLCanvasElement>[]>([]);
  const heightPageReal = useRef(0);
  const pageTable = useRef(1);
  const isGettingHeight = useRef(false);

  const {
    doc,
    drawLabel,
    drawLine,
    addSpace,
    setHeight,
    lMargin,
    rMargin,
    tMargin,
    getHeight,
    drawInfomationPatient,
    heightA4Page,
    drawAutoTable,
    colorHeadTextTable,
    colorTotal,
    renderBody,
  } = useCommonPdf();

  const setCssForLabel = () => {
    doc.setFont('WorkSans', 'bold');
    doc.setFontSize(13);
    doc.setTextColor(89, 89, 89);
  };

  const getSvRow = (element: HTMLElement, svRowItems?: Element[]) => {
    const allItems = svRowItems || [];
    Array.from(element.children).forEach(item => {
      if (item?.className === 'sv_row') {
        allItems.push(item);
        return;
      }
      if (item.childNodes.length === 0) {
        return;
      } else {
        getSvRow(item as HTMLElement, allItems);
      }
    });
    return allItems;
  };

  const getElement = (element: HTMLElement, startHeight?: number) => {
    const svRowItems = getSvRow(element);
    const heightOfPage = startHeight || getHeight();
    //get rest of height by px
    heightPageReal.current = Number(
      (
        ((element?.offsetWidth as number) *
          (HEIGHT_A4 - tMargin - heightOfPage)) /
        (WIDTH_A4 - lMargin - rMargin)
      ).toFixed(2),
    );
    svRowItems.forEach(item => {
      const heightElem =
        elementByPage.current[elementByPage.current.length - 1].length === 0
          ? Number(item.getBoundingClientRect().height.toFixed(2))
          : Number(
              (
                elementByPage.current[elementByPage.current.length - 1][
                  elementByPage.current[elementByPage.current.length - 1]
                    .length - 1
                ].height +
                Number(item.getBoundingClientRect().height.toFixed(2))
              ).toFixed(2),
            );
      if (heightElem <= heightPageReal.current) {
        elementByPage.current[elementByPage.current.length - 1].push({
          element: item,
          height: heightElem,
        });
      } else {
        heightPageReal.current = heightPageReal.current + 100;
        // heightPageReal.current = heightA4Page;
        // setHeight(heightA4Page);
        elementByPage.current.push([
          {
            element: item,
            height: Number(item.getBoundingClientRect().height.toFixed(2)),
          },
        ]);
      }
    });
  };

  const isAddingPageTable = () => {
    if (getHeight() + 40 > HEIGHT_A4 - tMargin) {
      return true;
    }
    return false;
  };

  const handleCheckAddPage = () => {
    if (isAddingPageTable()) {
      doc.addPage();
      setHeight(tMargin);
    } else {
      addSpace(5);
      drawLine();
      addSpace(8);
    }
  };

  const drawTable = (params: {
    label: string;
    head: RowInput;
    body: RowInput[];
    columnStyles?: {
      [key: string]: Partial<Styles>;
    };
  }) => {
    const { label, head, body, columnStyles } = params;
    pageTable.current = 1;
    setHeight(
      isGettingHeight.current
        ? (doc as any).lastAutoTable.finalY || getHeight()
        : getHeight(),
    );
    handleCheckAddPage();
    drawLabel(label);
    setHeight(getHeight() - 4);
    drawAutoTable({
      head,
      body,
      columnStyles,
      willDrawCell: data => {
        if (data.pageNumber !== pageTable.current) {
          pageTable.current = data.pageNumber;
          doc.setTextColor(110, 110, 110);
        }
      },
    });
  };

  const addTreatmentTable = (
    conditions?: PatientCondStageFragment[] | null,
  ) => {
    if (conditions && conditions?.length !== 0) {
      drawTable({
        label: 'Treatment Log',
        head: ['Condition', 'Stage', 'Start Date', 'End Date'],
        body:
          conditions.map(item => [
            item.condition?.name || '',
            item?.condition?.stages?.find(
              stage => stage.stageId === item.stageId,
            )?.name || 'N/A',
            item.startDate ? formatDate(item.startDate, true) : 'N/A',
            item.endDate ? formatDate(item.endDate, true) : 'N/A',
          ]) || [],
      });
      setHeight((doc as any).lastAutoTable.finalY || getHeight());
    }
  };

  const addNoteTable = (notes?: PatientNoteFragment['notes']) => {
    if (notes && notes?.length !== 0) {
      drawTable({
        label: 'Notes',
        head: ['Description', 'Time'],
        body:
          notes?.map(item => [
            item.description,
            formatDate(item.notedAt, true),
          ]) || [],
      });
      isGettingHeight.current = true;
      return;
    }
    isGettingHeight.current = false;
  };

  const addTaskTable = (tasks?: Tasks[] | null) => {
    if (tasks && tasks?.length !== 0) {
      drawTable({
        label: 'Tasks',
        head: ['Description', 'Task Time'],
        body:
          tasks?.map(item => [
            item.note,
            formatDate(item.followUpDate) || '',
          ]) || [],
      });
    }
  };

  const addRecordTable = (records?: PatientTimeRecordFragment[] | null) => {
    if (records && records?.length !== 0) {
      records?.map(item => {
        const body = renderBody(item?.timeReport);

        drawTable({
          label: `Module - ${item?.code}` || 'N/A',
          head: [
            'Date',
            'Start Time',
            'End Time',
            'Time Spent',
            'Navigator',
            {
              content: 'Note Types',
              colSpan: 2,
            },
          ],
          body:
            [
              ...(body.flat() as RowInput[]),
              [
                {
                  colSpan: 3,
                  content: 'Total',
                  styles: {
                    fillColor: colorTotal,
                    textColor: colorHeadTextTable,
                  },
                },
                {
                  colSpan: 7,
                  content: renderTime(totalBilledTime(item?.timeReport) || 0),
                  styles: {
                    fillColor: colorTotal,
                    textColor: colorHeadTextTable,
                  },
                },
              ],
            ] || [],
          columnStyles: {
            0: { cellWidth: 25 },
            1: { cellWidth: 25 },
            2: { cellWidth: 25 },
            3: { cellWidth: 25 },
            4: { cellWidth: 40 },
          },
        });
        isGettingHeight.current = true;
        return;
      });
      isGettingHeight.current = true;
      return;
    }
    isGettingHeight.current = false;
  };

  const drawSurvey = async (element: HTMLElement | null, name?: string) => {
    if (element) {
      window.scrollTo(0, 0);
      handleCheckAddPage();
      setCssForLabel();
      const textSurveyName = doc.splitTextToSize(
        surveyName,
        WIDTH_A4 - lMargin - rMargin,
      );
      doc.text(
        doc.splitTextToSize(
          name || textSurveyName,
          WIDTH_A4 - lMargin - rMargin,
        ),
        lMargin,
        getHeight(),
      );
      setHeight(getHeight() + doc.getTextDimensions(textSurveyName).h + 2);
      const heightBeforeGetElement = getHeight();
      //break page a4
      const imgTag = Array.from(
        (element as HTMLElement).querySelectorAll('img'),
      );
      imgTag.forEach(elem => {
        elem.setAttribute('crossOrigin', 'anonymous');
        elem.style.maxWidth = '100%';
      });
      getElement(element as HTMLElement);
      setHeight(heightBeforeGetElement);
      const elementGenerate = document.getElementById('generate-question');
      if (elementGenerate) {
        elementByPage.current.forEach(item => {
          item.forEach(question => {
            elementGenerate.appendChild(question.element);
          });
          const promiseCanvas = html2canvas(elementGenerate, {
            useCORS: true,
            allowTaint: true,
          });
          allPromiseCanvas.current.push(promiseCanvas);
          elementGenerate.innerHTML = '';
        });
        await Promise.all(allPromiseCanvas.current).then(items => {
          items.forEach((item, index) => {
            if (index === 0) {
              doc.addImage(
                item,
                'JPEG',
                lMargin,
                getHeight(),
                WIDTH_A4 - lMargin - rMargin,
                (item.height * (WIDTH_A4 - lMargin - rMargin)) / item.width,
              );
            } else {
              setHeight(tMargin);
              doc.addPage();
              doc.addImage(
                item,
                'JPEG',
                lMargin,
                tMargin,
                WIDTH_A4 - lMargin - rMargin,
                (item.height * (WIDTH_A4 - lMargin - rMargin)) / item.width,
              );
            }
            if (index === items.length - 1) {
              setHeight(
                getHeight() +
                  (item.height * (WIDTH_A4 - lMargin - rMargin)) / item.width,
              );
            }
          });
        });
        elementByPage.current = [[]];
        allPromiseCanvas.current = [];
      }
    }
  };

  const drawChecklists = async (
    allChecklist?: PatientChecklistPdfFragment[],
  ) => {
    const checklists = Array.from(
      document.getElementsByClassName('checklist-items'),
    );
    const arrNameChecklist =
      allChecklist?.map(item => {
        return (
          item.patientChecklists?.checklist?.name ||
          item.patientChecklists?.checklistAvailable?.name ||
          ''
        );
      }) || [];

    for (let i = 0; i <= checklists.length; i++) {
      await drawSurvey(checklists[i] as HTMLElement, arrNameChecklist[i]);
    }
  };

  const handleGeneratePdf = (data: {
    typePdf: 'createPdf' | 'sendEmr';
    callbackCreatePdf: (doc: jsPDF) => void;
    gender: Gender | string;
    mrn?: string;
    tasks?: Tasks[];
    checklists?: PatientChecklistPdfFragment[];
    conditions?: PatientCondStageFragment[];
    notes?: PatientNoteFragment['notes'];
    records?: PatientTimeRecordFragment[];
  }) => {
    setIsLoading(data.typePdf);
    dataForCreatePdf.current = data;
    const initChecklists: Survey.ReactSurveyModel[] = [];
    const checklistsModel = data.checklists?.reduce((init, item) => {
      const surveyData =
        item.patientChecklists?.resultChecklist &&
        item.patientChecklists?.resultChecklist?.length !== 0
          ? item.patientChecklists?.resultChecklist[0]?.checklistResult
          : null;
      const survey = new Survey.Model(
        (item.patientChecklists?.checklist?.surveyData ||
          item.patientChecklists?.checklistAvailable?.surveyData) as string,
      );
      survey.data = surveyData;
      survey.showNavigationButtons = false;
      survey.showCompletedPage = false;
      init.push(survey);
      return init;
    }, initChecklists);
    setChecklistModels({ isChanged: true, checklists: checklistsModel || [] });
  };

  useEffect(() => {
    if (checklistModels.isChanged && dataForCreatePdf.current) {
      const {
        gender,
        mrn,
        tasks,
        checklists,
        conditions,
        records,
        callbackCreatePdf,
        notes,
      } = dataForCreatePdf.current;
      createPdf({
        gender,
        mrn,
        tasks,
        checklists,
        conditions,
        notes,
        records,
      }).then(doc => {
        setIsLoading(undefined);
        callbackCreatePdf(doc);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistModels.isChanged]);

  const createPdf = async ({
    gender,
    mrn,
    tasks,
    checklists,
    conditions,
    notes,
    records,
  }: {
    gender: Gender | string;
    mrn?: string;
    tasks?: Tasks[];
    checklists?: PatientChecklistPdfFragment[];
    conditions?: PatientCondStageFragment[];
    records?: PatientTimeRecordFragment[];
    notes?: PatientNoteFragment['notes'];
  }) => {
    //draw patient information
    drawInfomationPatient({ patientDetail, gender, mrn });
    //draw condition stage
    addTreatmentTable(conditions);
    //draw survey result
    await drawSurvey(document.getElementById('main-survey'));
    //draw checklists
    await drawChecklists(checklists);
    //insert note table
    addNoteTable(notes);
    //insert records table
    addRecordTable(records);
    //insert tasks table
    addTaskTable(tasks);
    return doc;
  };

  return {
    createPdf,
    isLoading,
    handleGeneratePdf,
    checklistModels: checklistModels.checklists,
  };
};
