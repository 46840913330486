import React from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { ButtonLoading, DialogTitleClose } from 'components';
import CancelIcon from '@material-ui/icons/Cancel';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_PATIENTS } from 'gql';
import { GetPatientsQuery, GetPatientsQueryVariables } from 'types.d';
import PatientDetail from 'modules/patients/components/PatientDetail';
import Skeleton from '@material-ui/lab/Skeleton';

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
  patientSelected: string;
};

export const DialogInfoPatient: React.FC<Props> = ({
  open,
  toogleDialog,
  patientSelected,
}) => {
  const [
    getPatient,
    { loading: loadingPatients, data: listPatient },
  ] = useLazyQuery<GetPatientsQuery, GetPatientsQueryVariables>(GET_PATIENTS, {
    fetchPolicy: 'cache-and-network',
  });

  const onEnterDialog = () => {
    getPatient({
      variables: {
        params: {
          order: {
            createdAt: -1,
          },
          condition: {
            'owner._id': patientSelected,
          },
          page: 1,
          limit: 1,
        },
      },
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onEnter={onEnterDialog}
        onClose={() => toogleDialog(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitleClose
          title="Patient Information"
          onClose={() => toogleDialog(false)}
        />
        <DialogContent>
          {!loadingPatients && listPatient?.getPatients.nodes[0] && (
            <PatientDetail
              isOnDialog
              ignoredItems={[
                'followUp',
                'note',
                'surveyResult',
                'checklist',
                'condition',
              ]}
              item={listPatient?.getPatients.nodes[0]}
              index={0}
            />
          )}
          {loadingPatients && (
            <Skeleton variant="rect" width="100%" height={400} />
          )}
        </DialogContent>
        <DialogActions>
          <ButtonLoading
            callbackClick={() => toogleDialog(false)}
            Icon={<CancelIcon />}
            text="Close"
            color="default"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(DialogInfoPatient);
