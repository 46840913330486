import { useRef, useState, useEffect } from 'react';
export const useScroll = (stringId: string) => {
  let timer = useRef<number | null>(null);

  const [isSticky, setIsSticky] = useState(false);

  const initPos = useRef(0);
  useEffect(() => {
    const handleScroll = () => {
      const elementPos = document.getElementById(stringId)?.offsetTop || 0;

      if (timer.current !== null) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(function() {
        setIsSticky(elementPos > initPos.current);
      }, 150);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (document.getElementById(stringId)) {
      initPos.current = document.getElementById(stringId)?.offsetTop || 0;
    }
  }, [stringId]);

  return isSticky;
};
