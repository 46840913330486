import React, { useContext, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useMutationCustom, useToogleDialog } from 'hooks';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import NoteIcon from '@material-ui/icons/Note';
import DeleteIcon from '@material-ui/icons/Delete';
import ResendIcon from '../icons/ResendIcon';
import ArchiveIcon from '@material-ui/icons/Archive';
import gql from 'graphql-tag';
import {
  ArchiveSurveyResultMutation,
  ArchiveSurveyResultMutationVariables,
  ResendSurveyMutation,
  ResendSurveyMutationVariables,
  SurveyResultEmbeddedFragment,
} from 'types.d';
import {
  ARCHIVE_SURVEY_RESULT,
  GET_SURVEY_DATA_BY_PATIENT,
  RESEND_SURVEY,
} from 'gql';
import { PatientTagsContext } from 'share/context';
import { DeleteConfirm } from 'components';
import DialogNote from '../Note/DialogNote';
import { useServices } from 'modules/patients/services';
import { useApolloClient } from '@apollo/react-hooks';

type Props = {
  item?: SurveyResultEmbeddedFragment;
  close: () => void;
  openDialogSurvey: (status: boolean) => void;
};

export const PopoverSurveyResult: React.FC<Props> = ({
  item,
  close,
  openDialogSurvey,
}) => {
  const [isOpenDialogNote, toogleDialogNote] = useToogleDialog();

  const [
    isOpenDeleteSurveyResult,
    toogleDialogDeleteSurveyResult,
  ] = useToogleDialog();

  const client = useApolloClient();

  const {
    variablesSurveyTags,
    handleDeleteSurveyResult,
    loadingDeleteSurveyResult,
  } = useServices();

  const patientTagsContext = useContext(PatientTagsContext);

  //check survey tab is opened
  const isOnTab = useMemo(() => {
    return patientTagsContext?.isExtend && patientTagsContext?.indexTab === 3;
  }, [patientTagsContext]);

  const [isOpenArchive, toogleDialogArchive] = useToogleDialog();

  const [resendSurvey, { loading }] = useMutationCustom<
    ResendSurveyMutation,
    ResendSurveyMutationVariables
  >({
    api: RESEND_SURVEY,
    textSuccess: 'Resend successfully',
    callbackSuccess: () => {
      close();
    },
  });

  const [archiveSurveyResult, { loading: loadingArchive }] = useMutationCustom<
    ArchiveSurveyResultMutation,
    ArchiveSurveyResultMutationVariables
  >({
    api: ARCHIVE_SURVEY_RESULT,
    refetchQueries: [
      {
        query: GET_SURVEY_DATA_BY_PATIENT,
        variables: variablesSurveyTags,
      },
    ],
    textSuccess: 'Archive successfully',
    callbackSuccess: () => {
      close();
      //update survey result embedded
      client?.writeFragment({
        id: `SurveyResultEmbedded:${item?._id}`,
        fragment: gql`
          fragment SurveyResultEmbeddedUpdate on SurveyResultEmbedded {
            isArchive
          }
        `,
        data: {
          isArchive: true,
          __typename: 'SurveyResultEmbedded',
        },
      });
    },
  });

  const handleResendSurvey = () => {
    resendSurvey({
      variables: {
        params: {
          surveyId: item?.surveyDetail?.originSurvey,
          patientInfo: [
            {
              id: patientTagsContext?.patient.owner?.idOwner,
              sendSMS: false,
              sendEmail: true,
            },
          ],
        },
      },
    });
  };

  const handleArchive = () => {
    archiveSurveyResult({
      variables: {
        params: {
          surveyResultId: item?._id,
          isArchive: true,
          patientId: patientTagsContext?.patient?._id,
        },
      },
    });
  };

  const handleDeleteResult = () => {
    handleDeleteSurveyResult(item?._id, () => {
      toogleDialogDeleteSurveyResult(false);
    });
  };

  const openDialogDelete = () => {
    //open dialog from task tab
    if (isOnTab) {
      patientTagsContext?.handleSetDialogOpen(
        `deleteSurveyResult-${item?._id}`,
      );
      close();
      return;
    }
    //open dialog from task tag
    toogleDialogDeleteSurveyResult(true);
  };

  return (
    <>
      <DeleteConfirm
        action="update"
        title="Confirmation"
        content={
          <Typography color="textSecondary">
            This survey result will be disappeared in patient card. You can see
            it in the table. Do you still want to archive this item?
          </Typography>
        }
        icon={<ArchiveIcon />}
        buttonText="Archive"
        loading={loadingArchive}
        handleConfirm={handleArchive}
        open={isOpenArchive}
        toogle={e => {
          toogleDialogArchive(false);
          close();
        }}
      />
      <DeleteConfirm
        title="Are you sure you want to delete this survey result"
        loading={loadingDeleteSurveyResult}
        handleConfirm={handleDeleteResult}
        open={isOpenDeleteSurveyResult}
        toogle={e => {
          toogleDialogDeleteSurveyResult(false);
          close();
        }}
      />

      {isOpenDialogNote && (
        <DialogNote
          noteSelected={item?.patientNote || undefined}
          open={isOpenDialogNote}
          toogleDialog={e => {
            toogleDialogNote(false);
            close();
          }}
          infoSurveyWhenCreate={
            item?.patientNote
              ? undefined
              : { result: item?._id, survey: item?.surveyDetail?.surveyId }
          }
          notAbleToUpdateSurvey
        />
      )}
      <Box p={0.5} display="flex" flexDirection="column">
        <Tooltip title="View PDF">
          <IconButton color="default" onClick={() => openDialogSurvey(false)}>
            <PictureAsPdfIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={item?.patientNote ? 'Update Note' : 'Add Note'}>
          <IconButton
            color="primary"
            onClick={() => {
              toogleDialogNote(true);
            }}
          >
            <NoteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <IconButton onClick={handleResendSurvey} color="primary">
          {loading ? (
            <CircularProgress size={21} color="inherit" />
          ) : (
            <ResendIcon isResend fontSize="small" />
          )}
        </IconButton>
        <Tooltip title="Archive">
          <IconButton onClick={() => toogleDialogArchive(true)} color="primary">
            <ArchiveIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={openDialogDelete} color="secondary">
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
};

export default React.memo(PopoverSurveyResult);
