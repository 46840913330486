import React from 'react';
import { Typography, Container } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { LinkRouterStyled, TypographyErrorStyled } from 'share/component_css';
import { ButtonLoading } from 'components';
import CachedIcon from '@material-ui/icons/Cached';

const ContainerStyled = styled(Container)({
  marginTop: '150px',
});

type Props = {
  error?: string;
};

const objError: { [key: string]: { label: string; text: string } } = {
  '404': {
    label: '404',
    text: 'SORRY BUT THE PAGE YOU ARE LOOKING FOR COULD NOT BE FOUND',
  },
  '401': {
    label: '401',
    text: 'SORRY, YOU ARE NOT AUTHORIZED TO ACCESS THIS PAGE',
  },
  notFoundTreatment: {
    label: '404',
    text: 'SORRRY, THIS CONTENT IS NO LONGER AVAILABLE',
  },
  notAccessTreatment: {
    label: '403',
    text: 'SORRY, YOU ARE NOT AUTHORIZED TO ACCESS THIS TREATMENT',
  },
  completedSurvey: {
    label: 'Completed',
    text:
      'YOU HAVE ALREADY COMPLETED YOUR CHECK-IN. IF YOU HAVE QUESTIONS PLEASE LOGIN AND TYPE YOUR QUESTION USING THE LEFT HAND COLUMN COMMUNICATION TAB.',
  },
  somethingWentWrong: {
    label: 'Opps!',
    text: 'SORRY, SOMETHING WENT WRONG HERE, PLEASE REFRESH A PAGE',
  },
};

export const ErrorPage: React.FC<Props> = ({ error }) => {
  return (
    <>
      <ContainerStyled maxWidth="md" fixed>
        <TypographyErrorStyled variant="h4" gutterBottom>
          {objError[error || '401'].label}
        </TypographyErrorStyled>
        <Typography variant="subtitle1" gutterBottom>
          {objError[error || '401'].text}
        </Typography>
        {error === 'somethingWentWrong' ? (
          <ButtonLoading
            callbackClick={() => {
              window.location.reload();
            }}
            text="Refresh Page"
            Icon={<CachedIcon />}
          />
        ) : (
          <LinkRouterStyled to={'/'}>
            <ButtonLoading
              text="Back To Home"
              Icon={<KeyboardBackspaceIcon />}
            />
          </LinkRouterStyled>
        )}
      </ContainerStyled>
    </>
  );
};
export default ErrorPage;
