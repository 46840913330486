import { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  GET_ASSIGN_TREATMENTS_SIDE_BAR,
  ADD_SUB_TREATMENTS_SIDE_BAR,
  ADD_SHOWING_TREATMENT_SIDEBAR,
} from 'gql';
import { useMutationCustom } from 'hooks';
import {
  GetAssignTreatmentsSideBarQuery,
  GetAssignTreatmentsSideBarQueryVariables,
  AddShowingTreatmentSidebarMutation,
  AddShowingTreatmentSidebarMutationVariables,
} from 'types.d';
import { useLazyQuery } from '@apollo/react-hooks';
import { LayoutContext } from 'share/context';
import { KEY_OPEN_SYNC_TREATMENT } from 'CONST';
import { removeLocalStorage, redirectAfterLogin } from 'share/utils';
import { useHistory } from 'react-router-dom';

export const useAdingTreatmentSidebars = (
  isMoreFunction?: boolean,
  callback?: Function,
) => {
  const [addSubTreatmentsSideBar] = useMutationCustom({
    api: ADD_SUB_TREATMENTS_SIDE_BAR,
  });

  const [addTreatmentSideBarShow] = useMutation<
    AddShowingTreatmentSidebarMutation,
    AddShowingTreatmentSidebarMutationVariables
  >(ADD_SHOWING_TREATMENT_SIDEBAR);

  const layoutContext = useContext(LayoutContext);

  const history = useHistory();

  const handleAfterGettingAssignTreatments = (
    data: GetAssignTreatmentsSideBarQuery,
  ) => {
    const subTreatment = data.getAssignTreatments.nodes
      ?.filter(item => item.subSections && item?.subSections?.length !== 0)
      .flatMap(subItem => {
        return subItem.subSections;
      });
    addSubTreatmentsSideBar({
      variables: {
        subTreatments: [...data.getAssignTreatments.nodes, ...subTreatment],
      },
    });
    data.getAssignTreatments.nodes.forEach(item => {
      if (item.subSectionIds && item.subSectionIds.length !== 0) {
        addTreatmentSideBarShow({
          variables: {
            input: item._id,
          },
        });
      }
    });
    if (callback) {
      callback();
    }
  };

  const [getSubTreaments, { loading }] = useLazyQuery<
    GetAssignTreatmentsSideBarQuery,
    GetAssignTreatmentsSideBarQueryVariables
  >(GET_ASSIGN_TREATMENTS_SIDE_BAR, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      handleAfterGettingAssignTreatments(data);
      if (isMoreFunction) {
        if (layoutContext?.isRedirectingToHome) {
          history.push(redirectAfterLogin());
        }
        layoutContext?.toogleDialogLogin(false);
        removeLocalStorage(KEY_OPEN_SYNC_TREATMENT);
      }
    },
  });

  return { getSubTreaments, loadingSubTreatments: loading };
};
