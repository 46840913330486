import gql from 'graphql-tag';
import { DashboardFragment, DashboardResFragment } from './fragments';
export const GET_DASHBOARDS = gql`
  query GetDashboards {
    getDashboards {
      ...DashboardRes
    }
  }
  ${DashboardResFragment}
`;

export const GET_DASHBOARD = gql`
  query GetDashboard($params: IdInputType!) {
    getDashboard(params: $params) {
      ...DashboardRes
    }
  }
  ${DashboardResFragment}
`;

export const CREATE_DASHBOARD = gql`
  mutation CreateDashboard($params: CreateDashboardInput!) {
    createDashboard(params: $params) {
      ...Dashboard
    }
  }
  ${DashboardFragment}
`;

export const UPDATE_DASHBOARD = gql`
  mutation UpdateDashboard($params: UpdateDashboardInput!) {
    updateDashboard(params: $params) {
      ...Dashboard
    }
  }
  ${DashboardFragment}
`;

export const DELETE_DASHBOARD = gql`
  mutation DeleteDashboard($params: IdInputType!) {
    deleteDashboard(params: $params)
  }
`;

export const UPDATE_ORDER_CHART = gql`
  mutation UpdateOrderChart($params: UpdateOrderDashboardInput!) {
    updateOrderDashboard(params: $params)
  }
`;

export const GET_NAVIGATOR_TO_SHARE = gql`
  query GetNavigatorsToShare($params: GetUsersInput!) {
    getNavigators(params: $params) {
      nodes {
        _id
        email
        firstName
        middleName
        lastName
        status
        role
      }
    }
  }
`;

export const SHARE_DASHBOARD = gql`
  mutation ShareDashboard($params: ShareDashboardInput!) {
    shareDashboard(params: $params)
  }
`;

export const SET_SHOW_DASHBOARDS = gql`
  mutation SetShowDashboards($params: [UpdateDashboardInput!]!) {
    setShowDashboards(params: $params) {
      _id
      name
      isShow
    }
  }
`;

export const GET_LIST_FOLDER = gql`
  query GetListFolder(
    $params: GetTreatmentsInput! = { type: FOLDER, sortByOrder: { name: 1 } }
  ) {
    getTreatments(params: $params) {
      nodes {
        _id
        name
      }
    }
  }
`;

export const GET_TREE_NODES = gql`
  query GetTreeNode($params: GetNodeTreeInput!) {
    getTreeNodes(params: $params) {
      nodes {
        _id
        name
        type
        parent {
          type
        }
      }
    }
  }
`;
