import React from 'react';
import {
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Chip,
} from '@material-ui/core';
import { ButtonDelete, ButtonConfirm } from 'components';
import { UserListFragment } from 'types.d';
import { EmailSync } from 'mdi-material-ui';
import {
  TableCellMaxWidthStyled,
  TypoThreeDotsLine,
  TableFullWidthStyled,
  PaperBorder,
} from 'share/component_css';
type SecondaryEmail = UserListFragment['secondaryEmail'];

type Props = {
  listEmailAlias: SecondaryEmail | null;
  loadingSetPrimary: boolean;
  handleSetPrimaryEmail: (secondaryEmail: string) => void;
  loadingDelete: boolean;
  handleRemoveEmailAlias: (secondaryEmail: string) => void;
};

const TableEmailAlias: React.FC<Props> = ({
  listEmailAlias,
  loadingSetPrimary,
  handleSetPrimaryEmail,
  loadingDelete,
  handleRemoveEmailAlias,
}) => {
  return (
    <TableContainer className="mt-16" component={PaperBorder}>
      <TableFullWidthStyled>
        <TableHead>
          <TableRow>
            <TableCell>Email alias</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listEmailAlias?.map(item => {
            return (
              <TableRow key={item.email}>
                <TableCellMaxWidthStyled maxwidth="250px">
                  <TypoThreeDotsLine variant="subtitle2">
                    {item?.email}
                  </TypoThreeDotsLine>
                </TableCellMaxWidthStyled>
                <TableCell align="center">
                  <Chip
                    label={item?.isVerified ? 'Active' : 'Pending'}
                    variant="outlined"
                    color={item?.isVerified ? 'primary' : 'default'}
                  />
                </TableCell>
                <TableCell align="right">
                  {item.isVerified && (
                    <ButtonConfirm
                      loadingConfirm={loadingSetPrimary}
                      handleConfirm={() => handleSetPrimaryEmail(item.email)}
                      title="Set email alias to primary email?"
                      content={`Would you like to set ${item.email} to primary email
                  `}
                      tooltipTitle="Set primary email"
                      icon={<EmailSync fontSize="small" />}
                    />
                  )}
                  <ButtonDelete
                    loadingDelete={loadingDelete}
                    deleteItem={() => handleRemoveEmailAlias(item.email)}
                    content={`User will not be able to use ${item.email} to log into system after you delete this email alias`}
                    id={item.email}
                    idSelected={item.email}
                    title={'Are you sure you want to delete this email alias?'}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableFullWidthStyled>
    </TableContainer>
  );
};
export default TableEmailAlias;
