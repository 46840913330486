import React from 'react';
import {
  UNREAD_ALL_COMMENTS_SUBCRIPTION,
  GET_UNREAD_ALL_COMMENT,
  UNREAD_ALL_COMMENTS_BY_PATIENTS,
  GET_ALL_UNREAD_PATIENT_COMMENT,
} from 'gql';
import MenuInDrawer from './MenuInDrawer';
import { TConfigMenu } from '../config_menu';
import { useCheckLogin, useQueryCustom, useCustomPermission } from 'hooks';
import {
  OnUnreadAllCommentsByPatientsSubscription,
  OnUnreadAllCommentsByPatientsSubscriptionVariables,
  OnUnreadAllCommentsSubscription,
  OnUnreadAllCommentsSubscriptionVariables,
  GetUnreadAllCommentQuery,
  GetUnreadAllCommentQueryVariables,
  GetAllUnreadPatientCommentQuery,
  GetAllUnreadPatientCommentQueryVariables,
  CommentGroupRes,
} from 'types.d';
import { useSubscription, useApolloClient } from '@apollo/react-hooks';

type Props = {
  item: TConfigMenu;
};
export const QuestionMenu: React.FC<Props> = ({ item }) => {
  const client = useApolloClient();

  const isLogin = useCheckLogin();

  const { isUser } = useCustomPermission();

  //get all unread from treatment of logged user
  const { data: unreadAllComment } = useQueryCustom<
    GetUnreadAllCommentQuery,
    GetUnreadAllCommentQueryVariables
  >({
    api: GET_UNREAD_ALL_COMMENT,
    skip: !isLogin,
  });

  //get all unread from patient(apply for navigator)
  const { data: unreadAllPatient } = useQueryCustom<
    GetAllUnreadPatientCommentQuery,
    GetAllUnreadPatientCommentQueryVariables
  >({
    api: GET_ALL_UNREAD_PATIENT_COMMENT,
    skip: !isLogin || isUser,
  });

  //subcription unread comment on comment-patient
  useSubscription<
    OnUnreadAllCommentsByPatientsSubscription,
    OnUnreadAllCommentsByPatientsSubscriptionVariables
  >(UNREAD_ALL_COMMENTS_BY_PATIENTS, {
    skip: !isLogin || isUser,
    onSubscriptionData: data => {
      const cacheAllUnreadComment = client.readQuery<
        GetAllUnreadPatientCommentQuery,
        GetAllUnreadPatientCommentQueryVariables
      >({
        query: GET_ALL_UNREAD_PATIENT_COMMENT,
        variables: {
          params: {
            isGroupAllInOne: true,
          },
        },
      });
      const cache =
        cacheAllUnreadComment?.getUnreadCommentsPatientList.edges[0].node;
      client.writeQuery<
        GetAllUnreadPatientCommentQuery,
        GetAllUnreadPatientCommentQueryVariables
      >({
        query: GET_ALL_UNREAD_PATIENT_COMMENT,
        variables: {
          params: {
            isGroupAllInOne: true,
          },
        },
        data: {
          ...cacheAllUnreadComment,
          getUnreadCommentsPatientList: {
            ...cacheAllUnreadComment?.getUnreadCommentsPatientList,
            edges: [
              {
                ...cacheAllUnreadComment?.getUnreadCommentsPatientList.edges[0],
                node: {
                  ...(cache as Pick<
                    CommentGroupRes,
                    '_id' | 'unreadCount' | 'lastCommentAt'
                  >),
                  unreadCount:
                    data.subscriptionData.data
                      ?.unreadAllCommentsByPatientsSubscription?.edges[0].node
                      ?.unreadCount || 0,
                },
              },
            ],
          },
        },
      });
    },
  });

  //subcription unread comment on comment-treatment
  useSubscription<
    OnUnreadAllCommentsSubscription,
    OnUnreadAllCommentsSubscriptionVariables
  >(UNREAD_ALL_COMMENTS_SUBCRIPTION, {
    skip: !isLogin,
    onSubscriptionData: data => {
      const cacheAllUnreadComment = client.readQuery<GetUnreadAllCommentQuery>({
        query: GET_UNREAD_ALL_COMMENT,
      });

      client.writeQuery({
        query: GET_UNREAD_ALL_COMMENT,
        data: {
          getUnreadAllComment: [
            {
              ...cacheAllUnreadComment?.getUnreadAllComment[0],
              unreadCount:
                data.subscriptionData.data?.unreadAllCommentsSubscription[0]
                  ?.unreadCount || 0,
            },
          ],
        },
      });
    },
  });
  return (
    <MenuInDrawer
      unreadComment={{
        treatment: unreadAllComment?.getUnreadAllComment[0]?.unreadCount || 0,
        patient:
          unreadAllPatient?.getUnreadCommentsPatientList.edges[0]?.node
            ?.unreadCount || 0,
      }}
      detail={item}
    />
  );
};
export default React.memo(QuestionMenu);
