import React, { useEffect } from 'react';
import { Box, Fab, IconButton, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { usePopover } from 'hooks';
import { ICON_FONT_SIZE } from 'modules/treatments/components/SocialShare';
import RedditIcon from '@material-ui/icons/Reddit';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
type Props = {
  data: any
};

export const PopoverSocialShare: React.FC<Props> = ({
  data
}) => {
  const encodedTitle = encodeURIComponent(data!.query! + ":-");

  const encodedAns = encodeURIComponent(data!.resp!);

  const [open, close, DefaultPopover, defaultPopoverProps] = usePopover();

  const socialShares = [
    {
      name: 'Email',
      url: `mailto:?subject=${encodedTitle}&body=${encodedAns}`,
      icon: (
        <EmailIcon
          style={{ color: '#8B8585', fontSize: `${ICON_FONT_SIZE}px ` }}
        />
      ),
    },
    {
      name: 'WhatsApp',
      url: `https://api.whatsapp.com/send?text=${encodedTitle} ${encodedAns}`,
      icon: (
        <WhatsAppIcon
          style={{ color: '#25D366', fontSize: `${ICON_FONT_SIZE}px` }}
        />
      ),
    },
    // {
    //   name: 'Facebook',
    //   url: `https://www.facebook.com/dialog/feed?app_id=${process.env.REACT_APP_FACEBOOK_ID}&text=${encodedTitle} ${encodedAns}`,
    //   icon: (
    //     <FacebookIcon
    //       style={{ color: '#1877F2', fontSize: `${ICON_FONT_SIZE}px ` }}
    //     />
    //   ),
    // },
    {
      name: 'Twitter',
      url: `https://twitter.com/intent/tweet?text=${encodedTitle} ${encodedAns}`,
      icon: (
        <TwitterIcon
          style={{ color: '#1DA1F2', fontSize: `${ICON_FONT_SIZE}px ` }}
        />
      ),
    },
    {
      name: 'Reddit',
      url: `http://www.reddit.com/submit?text=${encodedTitle} ${encodedAns}`,
      icon: (
        <RedditIcon
          style={{ color: '#FF4500', fontSize: `${ICON_FONT_SIZE}px ` }}
        />
      ),
    },
  ];

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_ID_FACEBOOK!,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v14.0'
      });
    };

    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = "https://connect.facebook.net/en_US/sdk.js";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleShare = () => {
    window.FB.ui({
      method: 'share', // You must still specify a URL to satisfy Facebook's requirements
      quote: encodedAns,
      url: `https://www.facebook.com/dialog/feed?app_id=${process.env.REACT_APP_FACEBOOK_ID}&text=${encodedTitle} ${encodedAns}`,
    }, function (response: any) {
      if (response && !response.error_message) {
        console.log("Pass")
        console.log(response)
      } else {
        console.log("Fail")
        console.log(response.error_message)
      }
    });
  };

  return (
    <>
      <DefaultPopover {...defaultPopoverProps}>
        <Box display="grid" gridRowGap={4} m={1}>
          {socialShares.map((social, index) => (
            <Tooltip title={`Share on ${social.name}`} key={index}>
              <IconButton
                component="a"
                href={social.url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`Share on ${social.name}`}
                onClick={() => {
                  if (social.name == "Facebook") {
                    handleShare()
                  }
                }
                }>
                {social.icon}
              </IconButton>
            </Tooltip>
          ))}
          {/* <Tooltip title={`Share on Facebook`} >
            <IconButton
              aria-label={`Share on Facebook`}
              onClick={() => {
                handleShare()
              }
              }>
              <FacebookIcon
                style={{ color: '#1877F2', fontSize: `${ICON_FONT_SIZE}px ` }}
              />
            </IconButton>
          </Tooltip> */}
        </Box>
      </DefaultPopover>
      <Tooltip title="Share">
        <IconButton
          aria-label='share'
          onClick={e => open(e)}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default React.memo(PopoverSocialShare);
