import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { formatDate } from 'share/utils';
import { styled } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

type Props = {
  date: string;
  name?: string;
};

const BoxStyled = styled(Box)({
  '& >span': {
    marginRight: '3px',
  },
  '& svg': {
    marginRight: '3px',
    fontSize: '5px',
  },
});

export const InforTreatment: React.FC<Props> = ({ date, name }) => {
  return (
    <>
      <BoxStyled flexWrap="wrap" display="flex" alignItems="center">
        <Typography variant="caption" color="textSecondary">
          {name}
        </Typography>
        <FiberManualRecordIcon color="disabled" />
        <Typography variant="caption" color="textSecondary">
          {formatDate(date)}
        </Typography>
      </BoxStyled>
    </>
  );
};
export default InforTreatment;
