import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import { ButtonLoading } from 'components';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { AccountSearch } from 'mdi-material-ui';
import { usePopover, useToogleDialog } from 'hooks';
import DialogAddPatient from '../../patients/components/DialogAddPatient';
import ButtonQrCode from '../../treatment_tree/components/ButtonQrCode';
import DialogSearchPatient from './DialogSearchPatient';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DialogSendSurvey from 'modules/patients/components/Survey/DialogSendSurvey';
import { SubTreatmentFragment, TreatmentFragment } from 'types.d';

type Props = {
  treatmentSelected?: TreatmentFragment | SubTreatmentFragment | null;
  qrCode: string;
  urlSharing: string;
};

export const PopoverComponent: React.FC<Props> = ({
  treatmentSelected,
  qrCode,
  urlSharing,
}) => {
  const [
    isOpenDialogAddPatient,
    toogleOpenDialogAddPatient,
  ] = useToogleDialog();

  const [
    isOpenDialogSearchPatient,
    toogleOpenDialogSearchPatient,
  ] = useToogleDialog();

  const [
    isOpenDialogSendSurvey,
    toogleOpenDialogSendSurvey,
  ] = useToogleDialog();

  const [open, close, DefaultPopover, defaultPopoverProps] = usePopover();

  return (
    <>
      {isOpenDialogAddPatient && (
        <DialogAddPatient
          open={isOpenDialogAddPatient}
          toogleDialog={toogleOpenDialogAddPatient}
          handleClose={close}
          treatmentSelected={treatmentSelected}
        />
      )}
      {isOpenDialogSearchPatient && (
        <DialogSearchPatient
          open={isOpenDialogSearchPatient}
          toogleDialog={toogleOpenDialogSearchPatient}
          treatmentSelected={treatmentSelected?._id}
          handleClose={close}
        />
      )}
      {isOpenDialogSendSurvey && (
        <DialogSendSurvey
          open={isOpenDialogSendSurvey}
          toogleDialog={toogleOpenDialogSendSurvey}
          treatmentHomepage={treatmentSelected}
          close={close}
        />
      )}
      <DefaultPopover {...defaultPopoverProps}>
        <Box display="grid" gridRowGap={4} m={1}>
          <ButtonLoading
            flexStart
            callbackClick={() => toogleOpenDialogAddPatient(true)}
            color="primary"
            text="Add Patient"
            Icon={<PersonAddIcon />}
            variant="text"
          />
          <ButtonQrCode
            qrCode={qrCode}
            urlSharing={urlSharing}
            idTreatment={treatmentSelected?._id}
            smsMessage={treatmentSelected?.smsMessage || ''}
            handleClose={close}
            nameTreatment={treatmentSelected?.name}
          />
          <ButtonLoading
            flexStart
            callbackClick={() => toogleOpenDialogSearchPatient(true)}
            color="primary"
            text="Search Patient"
            Icon={<AccountSearch />}
            variant="text"
          />
          <ButtonLoading
            flexStart
            callbackClick={() => toogleOpenDialogSendSurvey(true)}
            color="primary"
            text="Send"
            Icon={<AssignmentTurnedInIcon />}
            variant="text"
          />
        </Box>
      </DefaultPopover>
      <IconButton className="ml-4 pt-0 pb-0" onClick={open} color="primary">
        <MoreHorizIcon color="primary" />
      </IconButton>
    </>
  );
};
export default PopoverComponent;
