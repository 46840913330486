import React, { useContext } from 'react';
import { TagFragment } from 'types.d';
import { TypoThreeDot, ChipStyled } from 'share/component_css';
import PickColorTag from './PickColorTag';
import DeleteIcon from '@material-ui/icons/Delete';
import { useToogleDialog, usePopover } from 'hooks';
import { DeleteConfirm } from 'components';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { TagForImport } from '../../interfaces';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import { AccountArrowRight, AccountRemove } from 'mdi-material-ui';
import { CreateUpdateTreatmentContext } from 'share/context';
import { useSnackbar } from 'notistack';
import { Link, LinkOff } from 'mdi-material-ui';
import { customEvent } from 'modules/google_tag_manager';

type Props = {
  tag: TagFragment | TagForImport;
  removeTag: (tag: TagFragment | TagForImport) => void;
  updateTagColor: (
    inforTag: { tagName: string; color: string },
    callback: Function,
  ) => void;
  loading?: boolean;
  isInactive?: boolean;
  isFolder?: boolean;
  updateTagWhenUpdate?: (tag: TagFragment) => void;
};

export const Tag: React.FC<Props> = ({
  tag,
  removeTag,
  updateTagColor,
  loading,
  isInactive,
  isFolder,
  updateTagWhenUpdate,
}) => {
  const [isOpenDialogDelete, toogleOpenDialogDelete] = useToogleDialog();

  const [isOpenDialogAssign, toogleOpenDialogAssign] = useToogleDialog();

  const createUpdateTreatmentContext = useContext(CreateUpdateTreatmentContext);

  const tags = createUpdateTreatmentContext?.tags;

  const setTags = createUpdateTreatmentContext?.setTags;

  const [isOpenPickColor, setIsOpenPickColor] = useToogleDialog();

  const { enqueueSnackbar } = useSnackbar();

  const deleteTag = (tag: TagFragment | TagForImport | null) => {
    if (tag) {
      toogleOpenDialogDelete(true);
    }
  };

  const closePickColor = () => {
    setIsOpenPickColor(false);
    close();
  };

  const [open, close, DefaultPopover, defaultPopoverProps] = usePopover();

  const handleAssign = (status: boolean, id: string) => {
    if (createUpdateTreatmentContext?.treatmentForUpdate?._id) {
      toogleOpenDialogAssign(true);
      return;
    }
    close();
    if (tags && setTags) {
      const updatedRows = [...tags];
      const index = updatedRows.findIndex(row => row.tag?._id === id);
      updatedRows[index].isAssignTag = status;
      setTags(updatedRows);
    }
  };

  const toogleAssignTag = (tag: TagFragment) => {
    const tagSelected = tags?.find(item => item?.tag?._id === tag?._id);
    if (updateTagWhenUpdate) {
      updateTagWhenUpdate(tag);
      customEvent("UpdateTagPD", "NAVIGATOR")
      enqueueSnackbar(`Update tag successfully`, {
        variant: 'success',
      });
      toogleOpenDialogAssign(false);
    }
    close();
    if (tags && setTags) {
      const updatedRows = [...tags];
      const index = updatedRows.findIndex(row => row.tag?._id === tag?._id);
      updatedRows[index].isAssignTag = !tagSelected?.isAssignTag;
      setTags(updatedRows);
    }
  };

  const handleChangColor = (
    inforTag: { tagName: string; color: string },
    callback: Function,
    id: string,
  ) => {
    if (tags && setTags && isFolder) {
      const updatedRows = [...tags];
      const index = updatedRows.findIndex(row => row.tag?._id === id);
      updatedRows[index].tag.color = inforTag.color;
      setTags(updatedRows);
      callback();
    }
    updateTagColor(inforTag, callback);
  };

  const tagSelected = tags?.find(
    item => item?.tag?._id === (tag as TagFragment)?._id,
  );

  return (
    <>
      <DefaultPopover {...defaultPopoverProps}>
        {isOpenPickColor ? (
          <Box p={1}>
            <PickColorTag
              updateTagColor={(infoTag, callback) =>
                handleChangColor(infoTag, callback, (tag as TagFragment)?._id)
              }
              close={closePickColor}
              item={tag}
              loading={loading}
              isFolder={isFolder}
            />
          </Box>
        ) : (
          <Box p={0.5} display="flex" flexDirection="column">
            <Tooltip title="Pick color">
              <IconButton onClick={() => setIsOpenPickColor(true)}>
                <FormatColorFillIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={() => deleteTag(tag)} color="secondary">
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {isFolder && (
              <Tooltip
                title={
                  tagSelected?.isAssignTag
                    ? 'Remove from patient'
                    : 'Attach to patient'
                }
              >
                {tagSelected?.isAssignTag ? (
                  <IconButton
                    onClick={() =>
                      handleAssign(false, (tag as TagFragment)?._id)
                    }
                  >
                    <AccountRemove fontSize="small" color="secondary" />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() =>
                      handleAssign(true, (tag as TagFragment)?._id)
                    }
                  >
                    <AccountArrowRight color="primary" fontSize="small" />
                  </IconButton>
                )}
              </Tooltip>
            )}
          </Box>
        )}
      </DefaultPopover>
      <DeleteConfirm
        handleConfirm={() => removeTag(tag)}
        open={isOpenDialogDelete}
        toogle={toogleOpenDialogDelete}
      />
      <DeleteConfirm
        action={!tagSelected?.isAssignTag ? 'update' : ''}
        icon={tagSelected?.isAssignTag ? <LinkOff /> : <Link />}
        handleConfirm={() => toogleAssignTag(tag as TagFragment)}
        open={isOpenDialogAssign}
        buttonText={tagSelected?.isAssignTag ? 'Detach' : 'Save'}
        toogle={toogleOpenDialogAssign}
        title={
          tagSelected?.isAssignTag
            ? `Would you like to remove the tag ${tagSelected?.name} from patient?`
            : `Would you like to attach the tag ${tagSelected?.name} to patient?`
        }
      />
      <ChipStyled
        backgroundcolor={tag.color || ''}
        label={
          <Box display="flex" alignItems="center">
            <Tooltip title={tag?.name || ''}>
              <TypoThreeDot variant="subtitle2">{tag?.name}</TypoThreeDot>
            </Tooltip>
            {tagSelected?.isAssignTag && (
              <Tooltip title="Attached">
                <Link aria-label='' className="ml-4" style={{ color: 'white' }} />
              </Tooltip>
            )}
          </Box>
        }
        onDelete={e => {
          setIsOpenPickColor(false);
          open(e);
        }}
        deleteIcon={!isInactive ? <MoreHorizIcon /> : <></>}
      />
    </>
  );
};
export default React.memo(Tag);
