import React from 'react';
import {
  CircularProgress,
  Avatar,
  Typography,
  TableCell,
  TextField,
  Button,
  Grid,
  TableContainer,
  MenuItem,
  Chip,
  Table,
  IconButton,
  Box,
  BoxProps,
  DialogContent,
  Dialog,
  TableContainerProps,
  Paper,
  Slider,
} from '@material-ui/core';
import { Link as LinkRouter } from 'react-router-dom';
import { createMuiTheme, styled, Theme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import StarIcon from '@material-ui/icons/Star';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ErrorIcon from '@material-ui/icons/Error';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import {
  CONFIRM_BACKGROUND_COLOR,
  CONFIRM_COLOR,
  MIN_WIDTH_TABLE,
} from 'CONST';

export const BoxStyled = styled(Box)(
  ({ error, required }: { error: string; required?: string }) => ({
    '& .special-label': {
      color: error === 'true' ? '#f44336' : '#0000008a',
      '&:after': {
        content: required === 'false' ? '' : "'*'",
        color: '#f44336',
      },
    },
    '&:hover': {
      color: error === 'true' ? '#f44336' : '#0000008a',
    },
    '& input.form-control': {
      borderColor: error === 'true' ? '#f44336' : '#cccccc',
    },
  }),
);

export const WrapButtonProgress = styled('div')({
  position: 'relative',
  display: 'inline-block',
});
export const ProgressButtonStyled = styled(CircularProgress)(
  ({ sizearound }: { sizearound?: string }) => ({
    position: 'absolute',
    top: sizearound === 'small' ? '0px' : '-3px',
    left: sizearound === 'small' ? '0px' : '-3px',
    zIndex: 1,
  }),
);

export const AvatarStyled = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
}));

export const LinkRouterStyled = styled(LinkRouter)(
  ({ hasunderline }: { hasunderline?: boolean }) => ({
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: hasunderline ? 'underline' : 'none',
    },
  }),
);

export const IconButtonLargeStyled = styled(IconButton)(
  ({ nopadding }: { nopadding?: string }) => ({
    '& .MuiSvgIcon-root': {
      fontSize: '2rem',
    },
    paddingLeft: nopadding === 'true' ? 0 : '8px',
  }),
);

export const IconButtonDeleteStyled = styled(IconButton)(
  ({ typeaction }: { typeaction: string }) => ({
    '& .MuiSvgIcon-root': {
      fontSize: '2rem',
    },
    '&:hover': {
      backgroundColor: CONFIRM_BACKGROUND_COLOR[typeaction],
    },
    backgroundColor: CONFIRM_BACKGROUND_COLOR[typeaction],
    padding: '16px',
    pointerEvents: 'none',
    color: CONFIRM_COLOR[typeaction],
  }),
);

export const IconButtonSuccess = styled(IconButton)(({ theme }) => ({
  color: theme.palette.success.main,
  '&:hover': {
    backgroundColor: 'rgba(25, 103, 210, 0.04)',
  },
}));

export const TypographyCursor = styled(Typography)({
  cursor: 'pointer',
  display: 'inline',
});

export const TypographyItalic = styled(Typography)({
  fontStyle: 'italic',
});

export const TypographyCapitalize = styled(Typography)({
  textTransform: 'capitalize',
});

export const TypographyUnderline = styled(Typography)({
  '&:hover': {
    textDecoration: 'underline',
  },
});

export const TypoTreedotUnderline = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden !important',
  textOverflow: 'ellipsis',
  '&:hover': {
    textDecoration: 'underline',
  },
});

export const TypographyBold = styled(Typography)({
  fontWeight: 600,
});

export const MediaStyled = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  paddingTop: '56.25%',
});

interface WrapSearchProps {
  hasbanner: 'true' | 'false';
  isLogin?: 'true' | 'false';
  theme?: Theme;
}

export const FieldRadiusStyled = styled(TextField)(
  ({ theme }: { theme: Theme }) => ({
    '&.MuiOutlinedInput-root': {
      borderRadius: '8px',
    },
    backgroundColor: theme.palette.common.white,
  }),
);

export const WrapFormSearchTreatment = styled(
  ({
    hasbanner,
    theme = defaultTheme,
    ...other
  }: WrapSearchProps & Omit<BoxProps, keyof WrapSearchProps>) => (
    <Box {...other} />
  ),
)({
  backgroundColor: '#fff',
  position: 'fixed',
  top: (props: WrapSearchProps) =>
    Object.is(props.hasbanner, 'true') ? '64px' : '0px',
  width: 'calc(100vw - 300px)',
  right: 0,
  height: '64px',
  boxShadow: 'rgb(145 158 171 / 16%) 0px 8px 16px 8px',
  borderRadius: '8px',
  zIndex: 1202,

  '@media screen and (max-width:768px)': {
    width: '100vw',
  },
  '@media screen and (max-width:600px)': {
    height: 'auto',
    padding: '8px 8px',
  },
});

export const WrapSearchTreatment = styled(
  ({
    hasbanner,
    isLogin,
    theme = defaultTheme,
    ...other
  }: WrapSearchProps & Omit<BoxProps, keyof WrapSearchProps>) => (
    <Box {...other} />
  ),
)({
  top: (props: WrapSearchProps) =>
    Object.is(props.hasbanner, 'true') ? '64px' : '0px',
  display: 'flex',
  alignItems: 'center',
  position: 'fixed',
  zIndex: 1202,
  height: '64px',
  right: (props: WrapSearchProps) =>
    Object.is(props.isLogin, 'true') ? '20px' : '110px',
  '@media screen and (max-width:599px)': {
    height: '56px',
  },
  '@media screen and (max-width:437px)': {
    height: '60px',
  },
  minHeight: '56px',
  // '@media screen and (min-width:464px)': {
  //   minHeight: '48px',
  // },
  // '@media screen and (min-width:600px)': {
  //   minHeight: '64px',
  // },
  // '@media screen and (max-width:500px)': {
  //   width: 'calc(100vw - 190px)',
  //   top: (props: WrapSearchProps) =>
  //     Object.is(props.hasbanner, 'true') ? '108px' : '0px',
  // },
  // top: (props: WrapSearchProps) =>
  //   Object.is(props.hasbanner, 'true') ? '63px' : '0px',
  // '@media screen and (max-width:1279px)': {
  //   left: '245px',
  // },
  // '@media screen and (max-width:600px)': {
  //   left: '130px',
  // },
  // '@media screen and (max-width:499px)': {
  //   left: '37px',
  //   width: 'calc(100vw - 230px)',
  // },
  // '@media screen and (max-width:400px)': {
  //   left: '37px',
  //   width: 'calc(100vw - 200px)',
  // },
  // transition: (props: WrapSearchProps) =>
  //   Object.is(props.hasbanner, 'true') ? '' : 'top 300ms',
});

export const TitleTypographyStyled = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    visibility: 'hidden',
  },
}));

export const ImageStyled = styled('div')(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundPosition: 'center center',
  borderRadius: '10px',
  backgroundColor: theme.palette.common.white,
}));

export const TableCellMinWidthStyled = styled(TableCell)(
  ({ minwidth }: { minwidth: string }) => ({
    minWidth: minwidth,
  }),
);

export const TableCellMaxWidthStyled = styled(TableCell)(
  ({ maxwidth }: { maxwidth: string }) => ({
    '& :first-child': {
      maxWidth: maxwidth,
      wordBreak: 'break-word',
    },
  }),
);
export const TableCellEmailMaxWidthStyled = styled(TableCell)(
  ({ maxwidth }: { maxwidth: string }) => ({
    '& :first-child': {
      maxWidth: maxwidth,
      wordBreak: 'break-word',
    },
    '& :last-child': {
      maxWidth: maxwidth,
      wordBreak: 'break-word',
    },
  }),
);

export const TextContentStyled = styled('div')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': 3,
  '-webkit-box-orient': 'vertical',
});

export const TypoThreeDot = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden !important',
  textOverflow: 'ellipsis',
});

export const TextFieldDisabledBlack = styled(TextField)(
  ({ theme, disabledtext }: { theme: Theme; disabledtext?: string }) => ({
    '& .MuiInputBase-root.Mui-disabled': {
      color: disabledtext
        ? theme.palette.text.disabled
        : theme.palette.common.black,
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336',
    },
  }),
);

export const ButtonSizeIcon = styled(Button)(({ theme }) => ({
  paddingTop: '6.75px',
  paddingBottom: '6.75px',
}));

export const StarIconStyled = styled(StarIcon)({
  color: yellow[500],
});
export const ReactPlayerStyled = styled(ReactPlayer)(
  ({ isdisplay, theme }: { isdisplay: string; theme: Theme }) => ({
    visibility: Object.is(isdisplay, 'true') ? 'visible' : 'hidden',
    backgroundColor: theme.palette.grey['400'],
    borderRadius: '10px',
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    '& div': {
      '& iframe': {
        maxWidth: '100%',
      },
    },
  }),
);

export const BoxSubTreatmentStyled = styled(Box)(
  ({ ishightlight, theme }: { ishightlight: string; theme: Theme }) => ({
    backgroundColor: Object.is(ishightlight, 'true')
      ? blue[100]
      : theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      flexBasis: '33.3333333%',
      maxWidth: '33.3333333%',
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: '25%',
      maxWidth: '25%',
    },
  }),
);

export const WrapSurvey = styled('div')(({ theme }) => ({
  fontFamily: 'Helvetica',
  '& .sv_body': {
    borderTop: '1px solid',
  },

  '& .sv_nav': {
    marginBottom: theme.spacing(1),
    '& input': {
      border: '1px solid rgba(25, 118, 210, 0.5)',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      fontSize: '0.8125rem',
      minWidth: theme.spacing(8),
      boxSizing: 'border-box',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontWeight: 500,
      lineHeight: 1.75,
      borderRadius: theme.spacing(0.5),
      letterSpacing: '0.028578em',
      color: `${theme.palette.primary.main}!important`,
      backgroundColor: 'transparent!important',
      '&:hover': {
        borderColor: theme.palette.primary.dark,
        backgroundColor: `rgba(25, 118, 210, 0.04)!important`,
      },
    },
  },

  '& .sv_q_file_preview > img': {
    maxWidth: '100%',
  },
}));

export const GridSurveyStyled = styled(Grid)(({ theme }) => ({
  fontFamily: 'Helvetica',
  '& .sv_body': {
    borderTop: '1px solid',
  },

  '& .sv_nav': {
    marginBottom: theme.spacing(1),
    '& input': {
      padding: '6px 16px',
      fontSize: '0.875rem',
      minWidth: theme.spacing(8),
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      fontWeight: '500',
      lineHeight: '1.75',
      borderRadius: theme.spacing(0.5),
      letterSpacing: '0.02857em',
      '& :hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}));

export const TypographyRedStyled = styled(Typography)(({ theme }) => ({
  color: '#f44336',
  marginTop: '4px',
  marginLeft: '14px',
  marginRight: '14px',
}));

export const TypographyGreenStyled = styled(Typography)(({ theme }) => ({
  color: 'rgb(76, 175, 80)',
  background: '#D7F7D3',
  padding: '4px',
  borderRadius: '6px',
}));

export const TypographyErrorStyled = styled(Typography)(
  ({ theme, size }: { theme: Theme; size?: string }) => ({
    fontSize: `${size || 100}px`,
    fontWeight: 900,
    textTransform: 'uppercase',
    color: '#d8d8d8',
    position: 'relative',
    '&:after': {
      content: "''",
      position: 'absolute',
      left: 0,
      bottom: 0,
      height: '4px',
      width: '100px',
      backgroundColor: theme.palette.primary.dark,
    },
  }),
);

interface WrapTableContainerProps {
  theme?: Theme;
  component: any;
}
export const TableContainerStyled = styled(
  ({
    component,
    theme = defaultTheme,
    ...other
  }: WrapTableContainerProps &
    Omit<TableContainerProps, keyof WrapTableContainerProps>) => (
    <TableContainer {...other} component={component} />
  ),
)({
  maxHeight: (props: WrapTableContainerProps) => props?.theme?.spacing(50),
  overflow: 'auto',
  width: '100%',
});

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  '& .MuiListItemIcon-root': {
    color: theme.palette.primary.main,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    '& .MuiListItemIcon-root': {
      color: theme.palette.common.white,
    },
    '& .MuiListItemText-root': {
      color: theme.palette.common.white,
    },
  },
  '&&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const ScrollItemStyled = styled(Box)(({ theme }) => ({
  overflowX: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
}));

export const ScrollingItemStyled = styled(Box)({
  overflow: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '& .react-horizontal-scrolling-menu--arrow-left': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .react-horizontal-scrolling-menu--arrow-right': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .react-horizontal-scrolling-menu--wrapper': {
    width: '100%',
  },
  '& .react-horizontal-scrolling-menu--inner-wrapper': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .react-horizontal-scrolling-menu--scroll-container': {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'scroll',
    gap: '4px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export const ChipStyled = styled(Chip)(
  ({
    theme,
    backgroundcolor,
    colortext,
    opacity,
  }: {
    theme: Theme;
    backgroundcolor?: string;
    colortext?: string;
    opacity?: string;
  }) => ({
    gridRow: 1,
    maxWidth: `360px!important`,
    backgroundColor: `${backgroundcolor || theme.palette.text.disabled}`,
    opacity: opacity === 'true' ? 0.35 : 1,
    color:
      colortext === 'true'
        ? theme.palette.common.black
        : theme.palette.common.white,
    '&:focus': {
      backgroundColor: `${backgroundcolor || theme.palette.text.disabled}`,
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
);

export const SignUpWithPhone = styled(TypographyCursor)({
  float: 'right',
});

export const ChipTaskTypeStyled = styled(ChipStyled)({
  '& .MuiChip-deleteIcon': {
    color: red[700],
  },
});

export const BoxCenterStyled = styled(Box)({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
});

export const TableFullWidthStyled = styled(Table)({
  minWidth: '400px',
  width: '100%',
});

export const KeyboardTimePickerStyled = styled(KeyboardTimePicker)({
  '& .MuiIconButton-root': {
    padding: 0,
  },
});

export const KeyboardDatePickerStyled = styled(KeyboardDatePicker)({
  '& .MuiIconButton-root': {
    padding: 0,
  },
});

export const BoxWrapFilterTable = styled(Box)(
  ({ theme }: { theme: Theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gridGap: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  }),
);

export const FormStyled = styled('form')({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  flexGrow: 1,
});

export const SliderStyled = styled(Slider)(({ theme }: { theme: Theme }) => ({
  width: '99%',
  '& .MuiSlider-thumb': {
    width: '14px',
    height: '14px',
  },
  '& .MuiSlider-track': {
    height: '4px',
  },
  '& .MuiSlider-rail': {
    height: '4px',
  },
  '& .MuiSlider-mark': {
    width: '3px',
    height: '4px',
  },
}));
//global class
export const globalClass = makeStyles({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '8px',
    },
    '.styles_react-code-input__CRulA > input': {
      border: '1px solid #a8adb7',
      marginRight: '6px',
      marginLeft: '6px',
      borderRadius: '6px',
      width: '46px !important',
      fontFamily: 'Helvetica',
    },
    '.styles_react-code-input__CRulA > input:focus + input': {
      borderLeft: '1px solid #a8adb7',
    },
    '*::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'rgba(0,0,0,.4)',
    },
    '.font-size-12': {
      fontSize: '12px',
    },
    '.font-weight-bold': {
      fontWeight: 700,
    },
    '.position-relative': {
      position: 'relative',
    },
    '.position-absolute': {
      position: 'absolute',
    },
    '.d-block': {
      display: 'block',
    },
    '.d-inline': {
      display: 'inline',
    },
    '.items-center': {
      alignItems: 'center',
    },
    '.pt-0': {
      paddingTop: 0,
    },
    '.pb-0': {
      paddingBottom: 0,
    },
    '.pl-8': {
      paddingLeft: 8,
    },
    '.mr-6': {
      marginRight: 6,
    },
    '.p-6': {
      padding: 6,
    },
    '.mr-8': {
      marginRight: 8,
    },
    '.mr-16': {
      marginRight: 16,
    },
    '.mr-24': {
      marginRight: 24,
    },
    '.outline-none': {
      outline: 'none',
    },
    '.h-100': {
      height: '100%',
    },
    '.h-auto': {
      height: 'auto',
    },
    '.flexgrow-1': {
      flexGrow: 1,
    },
    '.ml-8': {
      marginLeft: 8,
    },
    '.ml-16': {
      marginLeft: 16,
    },
    '.fontsize-40': {
      fontSize: 40,
    },
    '.w-100': {
      width: '100%',
    },
    '.cursor-pointer': {
      cursor: 'pointer',
    },
    '.mr-auto': {
      marginRight: 'auto',
    },
    '.ml-auto': {
      marginLeft: 'auto',
    },
    '.d-flex': {
      display: 'flex',
    },
    '.float-right': {
      float: 'right',
    },
    '.float-left': {
      float: 'left',
    },
    '.m-8': {
      margin: 8,
    },
    '.mt-0': {
      marginTop: 0,
    },
    '.mt-4': {
      marginTop: 4,
    },
    '.mt-8': {
      marginTop: 8,
    },
    '.mt-16': {
      marginTop: 16,
    },
    '.mt-24': {
      marginTop: 24,
    },
    '.mb-4': {
      marginBottom: 4,
    },
    '.border-purple': {
      border: '2px solid purple',
    },
    '.border-red': {
      border: '2px solid #d11919',
    },
    '.mb-8': {
      marginBottom: 8,
    },
    '.mb-16': {
      marginBottom: 16,
    },
    '.pl-16': {
      paddingLeft: 16,
    },
    '.pr-16': {
      paddingRight: 16,
    },
    '.pb-8': {
      paddingBottom: 8,
    },
    '.pb-16': {
      paddingBottom: 16,
    },
    '.pt-8': {
      paddingTop: 8,
    },
    '.pt-16': {
      paddingTop: 16,
    },
    '.p-16': {
      padding: 16,
    },
    '.p-8': {
      padding: 8,
    },
    '.p-4': {
      padding: 4,
    },
    '.m-4': {
      margin: 4,
    },
    '.mr-4': {
      marginRight: 4,
    },
    '.ml-4': {
      marginLeft: 4,
    },
    '.blurred': {
      filter: 'blur(5px)',
    },
    '.boxshadow-none': {
      boxShadow: 'none!important',
    },
    '.text-center': {
      textAlign: 'center',
    },
    '.text-tranform-none': {
      textTransform: 'none!important',
    },
    '.error-phone': {
      '& .special-label': {
        color: '#f44336',
      },
      '&:hover': {
        color: '#f44336',
      },
      '& input.form-control': {
        borderColor: '#f44336',
      },
    },
    '.special-label-phone': {
      '& .special-label': {
        color: '#0000008a',
      },
    },
    '.react-tel-input': {
      '& input.form-control': {
        width: '100%',
        paddingTop: '10px',
        paddingBottom: '10px',
      },
    },
    '.button-add-root': {
      paddingTop: '6.75px',
      paddingBottom: '6.75px',
    },
    '.button-fit-large': {
      padding: '14px 21px',
    },
    '.has-underline': {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '.hover-color': {
      '&:hover': {
        color: '#1967d2',
      },
    },
    '.bg-white': {
      backgroundColor: '#fff',
    },
    '.pointer-events-none': {
      pointerEvents: 'none',
    },
    '.no-scroll': {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
    },
    '.button-fit-input': {
      height: '40px',
    },
    '.bg-main': {
      backgroundColor: '#e3edfd',
    },
    '.radius-6': {
      borderRadius: '6px',
    },
    '.word-break-all': {
      wordBreak: 'break-all',
    },
  },
});
export const ButtonFlexStart = styled(Button)({
  '& .MuiButton-label': {
    display: 'flex',
    justifyContent: 'flex-start',
  },
});
export const TypoThreeDotsLine = styled(Typography)(
  ({ linenumber }: { linenumber?: number }) => ({
    display: '-webkit-box',
    alignItems: 'center',
    '-webkit-line-clamp': linenumber || 4,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
  }),
);
export const TypoThreeDotsEmail = styled(Typography)({
  display: 'flex',
  maxWidth: '200px',
});

export const DialogNoScroll = styled(Dialog)({
  '& .MuiDialogContent-root': {
    overflowY: 'hidden',
  },
});

export const SuccessIcon = styled(CheckCircleIcon)(({ theme }) => ({
  fontSize: theme.spacing(8.5),
  color: green[700],
}));

export const VerifyIcon = styled(VerifiedUserIcon)(({ theme }) => ({
  fontSize: theme.spacing(8.5),
  color: blue[700],
}));

export const FailedIcon = styled(ErrorIcon)(({ theme }) => ({
  fontSize: theme.spacing(8.5),
  color: red[700],
}));

export const DialogContentPt0 = styled(DialogContent)({
  paddingTop: 0,
});

export const WrapContent = styled(Box)(({ theme }) => ({
  '@media (min-width: 1440px)': {
    maxWidth: theme.spacing(180),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const ImgFull = styled('img')({
  width: '100%',
  height: 'auto',
});

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    landingPage1: string;
    landingPage2: string;
    landingPage3: string;
  }
  interface PaletteOptions {
    landingPage1: string;
    landingPage2: string;
    landingPage3: string;
  }
}
export const PaperBorder = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  border: '1px solid #dadce0',
}));

//default theme
export const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: blue[700],
    },
    landingPage1: '#1A3A5D',
    landingPage2: '#3374BA',
    landingPage3: '#40B7AD',
  },
  overrides: {
    MuiCard: {
      root: {
        boxShadow: 'none',
        border: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
    MuiPaper: {
      elevation1: {
        // boxShadow: 'none',
        // border: '1px solid #dadce0',
      },
      rounded: {
        borderRadius: '8px',
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: '0.875rem',
      },
      root: {
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '36px',
      },
    },
    MuiIconButton: {
      root: {
        padding: '8px',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        minWidth: '76px',
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiTableCell: {
      root: {
        '&$head': {
          borderBottom: `2px solid ${grey[300]}`,
          backgroundColor: grey[50],
        },
      },
      body: {
        padding: '12px',
      },
      head: {
        padding: '12px',
        paddingTop: '16px',
        paddingBottom: '16px',
        fontWeight: 600,
      },
    },
    MuiListItem: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
          '& .MuiListItemIcon-root': {
            color: '#1967d2',
          },
          '& .MuiListItemText-root': {
            color: '#1967d2',
          },
        },
        '&.Mui-selected': {
          backgroundColor: '#e3edfd',
          '&:hover': {
            backgroundColor: '#e3edfd',
          },
          '& .MuiListItemIcon-root': {
            color: '#1967d2',
          },
          '& .MuiListItemText-root': {
            color: '#1967d2',
            'MuiTypography-body1': {
              fontWeight: 500,
            },
          },
        },
      },
    },
    MuiTable: {
      root: {
        minWidth: `${MIN_WIDTH_TABLE}px`,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '16px',
        paddingRight: '8px',
        paddingTop: '8px',
        textTransform: 'capitalize',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '16px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '16px',
      },
      spacing: {
        gap: '8px',
      },
    },
    MuiDialog: {
      paperFullWidth: {
        width: '100%',
      },
      paper: {
        margin: '8px',
      },
    },
    MuiBreadcrumbs: {
      root: {
        '& li:not(:last-child)': {
          maxWidth: '150px',
        },
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '0.875rem',
      },
      root: {
        minHeight: '40px',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '14px',
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: red[700],
      },
    },
    MuiTypography: {
      subtitle2: {
        fontWeight: 400,
      },
    },
  },
});
