import React, { useMemo } from 'react';
import { Tooltip, Box, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { AccountCancel } from 'mdi-material-ui';
import { DeleteConfirm } from 'components';
import { useToogleDialog, useCustomPermission, useMutationCustom } from 'hooks';
import {
  PatientFragment,
  UserStatus,
  InactiveUserMutation,
  InactiveUserMutationVariables,
  DeletePatientMutation,
  DeletePatientMutationVariables,
} from 'types.d';
import { INACTIVE_USER, DELETE_PATIENT } from 'gql';
import DialogUpdateInfo from '../Dialogs/DialogUpdateInfo';
import EventIcon from '@material-ui/icons/Event';
import DialogCalendar from '../Dialogs/DialogCalendar';
import { customEvent } from 'modules/google_tag_manager';

type Props = {
  patient: PatientFragment;
  isExtend: boolean;
  handleCollapse: (status: boolean) => void;
  callbackDelete?: () => void;
  isCollaspeCard?: boolean;
  isOnDialog?: boolean;
};
export const GroupActions: React.FC<Props> = ({
  isExtend,
  handleCollapse,
  patient,
  callbackDelete,
  isCollaspeCard,
  isOnDialog,
}) => {
  const [isOpenInactive, toggleDialogInactive] = useToogleDialog();

  const [isOpen, toogleDialog] = useToogleDialog();

  const [isOpenDelete, toogleDialogDelete] = useToogleDialog();

  const [isOpenCalendar, toogleDialogCalendar] = useToogleDialog();

  const { isAdmin } = useCustomPermission();
  const isShowInacticeUser = useMemo(() => {
    return (
      patient.owner && !(patient?.owner?.user?.status === UserStatus.Inactive)
    );
  }, [patient]);

  const isShowDeletedUser = useMemo(() => {
    return patient?.owner && isAdmin && !isOnDialog;
  }, [isAdmin, isOnDialog, patient]);

  const [inactiveUser, { loading: loadingInactive }] = useMutationCustom<
    InactiveUserMutation,
    InactiveUserMutationVariables
  >({
    api: INACTIVE_USER,
    textSuccess: 'Inactivate patient successfully',
    callbackSuccess: data => {
      customEvent("inactivatePatient", "NAVIGATOR")
      toggleDialogInactive(false);
      if (callbackDelete) {
        callbackDelete();
      }
    },
  });

  const [deletePatient, { loading: loadingDelete }] = useMutationCustom<
    DeletePatientMutation,
    DeletePatientMutationVariables
  >({
    api: DELETE_PATIENT,
    textSuccess: 'Delete patient successfully',
    callbackSuccess: data => {
      toogleDialogDelete(false);
      if (callbackDelete) {
        callbackDelete();
      }
    },
  });

  const handleInactivePatient = () => {
    inactiveUser({
      variables: { params: { userId: patient?.owner?.user?.idUser } },
    });
  };

  const handleDeletePatient = () => {
    deletePatient({
      variables: { params: { patientId: patient?._id } },
    });
  };

  return (
    <>
      {isOpen && (
        <DialogUpdateInfo
          open={isOpen}
          infoPatient={patient}
          toogleDialog={toogleDialog}
          idPatient={patient?._id}
        />
      )}
      {isOpenCalendar && (
        <DialogCalendar
          openDialog={isOpenCalendar}
          toogleDialog={toogleDialogCalendar}
          patientDetail={patient}
        />
      )}
      <DeleteConfirm
        title="Are you sure you want to inactivate this patient?"
        handleConfirm={handleInactivePatient}
        icon={<AccountCancel />}
        buttonText="Inactivate"
        open={isOpenInactive}
        toogle={toggleDialogInactive}
        loading={loadingInactive}
      />
      <DeleteConfirm
        title="Are you sure you want to delete this patient?"
        handleConfirm={handleDeletePatient}
        open={isOpenDelete}
        toogle={toogleDialogDelete}
        loading={loadingDelete}
      />
      <Box
        position="absolute"
        right={8}
        top={12}
        display="flex"
        alignItems="center"
      >
        {patient.owner && (
          <>
            <Tooltip arrow title="View survey submission time">
              <IconButton
                size="small"
                color="primary"
                onClick={() => toogleDialogCalendar(true)}
              >
                <EventIcon />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Edit information">
              <IconButton
                size="small"
                color="primary"
                onClick={() => toogleDialog(true)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        {isShowInacticeUser && (
          <Tooltip arrow title="Inactivate">
            <IconButton size="small" onClick={() => toggleDialogInactive(true)}>
              <AccountCancel />
            </IconButton>
          </Tooltip>
        )}
        {isShowDeletedUser && (
          <Tooltip arrow title="Delete">
            <IconButton
              size="small"
              color="secondary"
              onClick={() => toogleDialogDelete(true)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {isCollaspeCard && (
          <Tooltip
            arrow
            title={isExtend ? 'Click to collapse' : 'Click to expand'}
          >
            <IconButton
              size="small"
              onClick={() => handleCollapse(!isExtend)}
              color="primary"
            >
              {isExtend ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </>
  );
};

export default React.memo(GroupActions);
