import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { NoData, NoDataPage } from 'components';
import { styled } from '@material-ui/core/styles';

type Props = {
  colSpan?: number;
  isPage?: boolean;
  marginTop?: number;
};

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  paddingTop: theme.spacing(4),
}));

export const NoDataTable: React.FC<Props> = ({
  colSpan,
  isPage,
  marginTop,
}) => {
  return (
    <TableRow>
      <TableCellStyled colSpan={colSpan}>
        {isPage ? <NoDataPage marginTop={marginTop || 0} /> : <NoData />}
      </TableCellStyled>
    </TableRow>
  );
};
export default NoDataTable;
