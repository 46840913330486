import React, { useState } from 'react';
import { InputAdornment, IconButton, TextField } from '@material-ui/core';
import { ButtonLoading, PopperRulePassword } from 'components';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import {
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
} from 'types.d';
import { useMutation } from '@apollo/react-hooks';
import { CHANGE_PASSWORD } from 'gql';
import { handleError } from 'share/utils';
import CheckIcon from '@material-ui/icons/Check';
import { PASSWORD_REGEX, NO_SPACING_REGEX } from 'CONST';

type FormData = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const ChangePasswordSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .trim()
    .required('Current password is required')
    .matches(NO_SPACING_REGEX, 'Your password should be valid'),
  newPassword: yup
    .string()
    .required('New password is required')
    .matches(PASSWORD_REGEX, 'Your password should be valid'),
  confirmNewPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<
    HTMLInputElement | HTMLTextAreaElement | null
  >(null);

  const { register, handleSubmit, watch, errors } = useForm<FormData>({
    mode: 'onBlur',
    validationSchema: ChangePasswordSchema,
  });

  const watchPassword = watch('newPassword');

  const handleOpenPopper = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [changePassword, { loading }] = useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(CHANGE_PASSWORD, {
    onCompleted(data) {
      enqueueSnackbar('Change password successfully', { variant: 'success' });
    },
    onError(error) {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
  });

  const onSubmit = handleSubmit(({ currentPassword, newPassword }) => {
    changePassword({
      variables: { params: { password: currentPassword, newPassword } },
    });
  });

  return (
    <>
      <PopperRulePassword anchorEl={anchorEl} watchPassword={watchPassword} />
      <TextField
        fullWidth
        required
        size="small"
        inputProps={{ maxLength: 255 }}
        InputLabelProps={{ shrink: true }}
        id="currentPassword"
        name="currentPassword"
        label="Current Password"
        type={showPassword.currentPassword ? 'text' : 'password'}
        autoComplete="current-password"
        variant="outlined"
        margin="dense"
        error={!!errors.currentPassword}
        inputRef={register}
        helperText={
          !!errors.currentPassword ? errors.currentPassword.message : ''
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="Toggle password visibility"
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    currentPassword: !showPassword.currentPassword,
                  })
                }
              >
                {showPassword.currentPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          ),
          inputProps: { tabIndex: 1 },
        }}
      />
      <TextField
        fullWidth
        required
        size="small"
        inputProps={{ maxLength: 255 }}
        InputLabelProps={{ shrink: true }}
        error={!!errors.newPassword}
        id="newPassword"
        name="newPassword"
        label="New Password"
        inputRef={register}
        type={showPassword.newPassword ? 'text' : 'password'}
        autoComplete="current-password"
        variant="outlined"
        margin="dense"
        helperText={!!errors.newPassword ? errors.newPassword.message : ''}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="Toggle password visibility"
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    newPassword: !showPassword.newPassword,
                  })
                }
              >
                {showPassword.newPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          inputProps: { tabIndex: 2 },
        }}
        onFocus={handleOpenPopper}
        onBlur={handleOpenPopper}
      />
      <TextField
        fullWidth
        required
        size="small"
        inputProps={{ maxLength: 255 }}
        InputLabelProps={{ shrink: true }}
        type={showPassword.confirmNewPassword ? 'text' : 'password'}
        error={!!errors.confirmNewPassword}
        id="confirmNewPassword"
        name="confirmNewPassword"
        inputRef={register}
        label="Confirm New Password"
        autoComplete="current-password"
        variant="outlined"
        margin="dense"
        helperText={
          !!errors.confirmNewPassword ? errors.confirmNewPassword.message : ''
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="Toggle password visibility  "
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    confirmNewPassword: !showPassword.confirmNewPassword,
                  })
                }
              >
                {showPassword.confirmNewPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          ),
          inputProps: { tabIndex: 3 },
        }}
      />
      <ButtonLoading
        Icon={<CheckIcon />}
        tabIndex={6}
        text="Save"
        size="medium"
        loading={loading}
        callbackClick={onSubmit}
        className="mt-8"
      />
    </>
  );
};

export default ChangePassword;
