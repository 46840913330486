import React from 'react';
import { styled } from '@material-ui/core/styles';
import { DEFAULT_IMAGE } from 'CONST';
import { renderMedia } from 'share/utils';
import { ImageStyled, ReactPlayerStyled } from 'share/component_css';

type Props = {
  mainImage?: string | null;
  mainVideo?: string | null;
  thumbnail?: string | null;
  id?: string;
  index: number;
};

const ImgStyled = styled(ImageStyled)(
  ({
    src,
    isdisplay,
    opacity,
  }: {
    src: string;
    isdisplay?: string;
    opacity?: number;
  }) => ({
    backgroundImage: `url(${src || DEFAULT_IMAGE})`,
    visibility: Object.is(isdisplay, 'true') ? 'visible' : 'hidden',
    opacity: opacity ?? 1,
  }),
);

export const Thumbnail: React.FC<Props> = ({
  mainVideo,
  thumbnail,
  mainImage,
  id,
  index,
}) => {
  return (
    <>
      {mainVideo ? (
        <ReactPlayerStyled
          style={{
            pointerEvents: 'none',
          }}
          playsinline
          controls
          id={`player${id}`}
          isdisplay="true"
          url={renderMedia(mainVideo)}
          width="100%"
          height="100%"
          muted
          light={renderMedia(thumbnail) || false}
          config={{
            file: {
              attributes: {
                muted: true,
              },
            },
            youtube: {
              playerVars: { showinfo: 1 },
            },
          }}
        />
      ) : (
        <ImgStyled
          isdisplay={'true'}
          src={
            renderMedia(thumbnail) || renderMedia(mainImage) || DEFAULT_IMAGE
          }
        />
      )}
    </>
  );
};
export default React.memo(Thumbnail);
