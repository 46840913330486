import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useCopyToClipBoard } from 'hooks';
import { WrapButtonProgress, ProgressButtonStyled } from 'share/component_css';
import { ButtonLoading } from 'components';
type Props = {
  isButton?: boolean;
  link: string;
  hasElemAppend?: string;
  callBackFunction?: Function;
  text?: string;
};

export const ButtonCopyLink: React.FC<Props> = ({
  isButton,
  link,
  hasElemAppend,
  callBackFunction,
  text,
}) => {
  const { loadingCopy, handleCopyToClipBoard } = useCopyToClipBoard();
  return (
    <>
      {isButton ? (
        <ButtonLoading
          flexStart
          variant="text"
          disabled={loadingCopy}
          callbackClick={() =>
            handleCopyToClipBoard(link, hasElemAppend, callBackFunction)
          }
          Icon={<FileCopyIcon />}
          text={text || 'Copy Link'}
          fullWidth={true}
          loading={loadingCopy}
        />
      ) : (
        <WrapButtonProgress>
          <Tooltip arrow title={text || 'Copy Link'}>
            <IconButton
              disabled={loadingCopy}
              aria-label={text || 'copy link'}
              color="primary"
              onClick={() =>
                handleCopyToClipBoard(link, hasElemAppend, callBackFunction)
              }
            >
              <FileCopyIcon />
            </IconButton>
          </Tooltip>
          {loadingCopy && <ProgressButtonStyled size={45} />}
        </WrapButtonProgress>
      )}
    </>
  );
};
export default ButtonCopyLink;
