import React, { useState } from 'react';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {
  InputAdornment,
  TextField,
  Container,
  Box,
  IconButton,
  Fab,
} from '@material-ui/core';
import { ButtonLoading, PopperRulePassword } from 'components';
import { useLocation, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';
import { RESET_PASSWORD, TOOGLE_BANNER_STATUS } from 'gql';
import { ResetPasswordMutation, ResetPasswordMutationVariables } from 'types.d';
import { handleError } from 'share/utils';
import { useForm } from 'react-hook-form';
import CheckIcon from '@material-ui/icons/Check';
import * as yup from 'yup';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { PASSWORD_REGEX } from 'CONST';

type FormData = {
  password: string;
  confirmPassword: string;
};

const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .matches(PASSWORD_REGEX, 'Your password should be valid'),
  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmNewPassword: false,
  });

  const history = useHistory();

  const queryUrl = new URLSearchParams(useLocation().search);

  const accessToken = queryUrl.get('token');

  const [anchorEl, setAnchorEl] = useState<
    HTMLInputElement | HTMLTextAreaElement | null
  >(null);

  const [toogleBannerStatus] = useMutation(TOOGLE_BANNER_STATUS);

  const { enqueueSnackbar } = useSnackbar();
  // const [isOpenLogin, toogleDialogLogin] = useToogleDialog();
  const [doResetPassword, { loading }] = useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(RESET_PASSWORD, {
    onCompleted(data) {
      if (data.resetPassword) {
        enqueueSnackbar(
          'You have successfully changed your password! You may now log in with your new credentials',
          {
            variant: 'success',
            autoHideDuration: 5000,
          },
        );
        history.push('/');
        toogleBannerStatus({
          variables: { status: false },
        });
      }
    },
    onError(error) {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
  });

  const { handleSubmit, watch, errors, register } = useForm<FormData>({
    mode: 'onBlur',
    validationSchema: ResetPasswordSchema,
  });

  const onSubmit = handleSubmit(({ password }) => {
    if (accessToken) {
      doResetPassword({
        variables: { params: { accessToken, password } },
      });
    }
  });

  const watchPassword = watch('password');

  const handleOpenPopper = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <Container maxWidth="sm">
      <Box mt={12} display="flex" justifyContent="center">
        <Fab className="boxshadow-none" color="primary" aria-label="add">
          <LockOpenIcon />
        </Fab>
      </Box>
      <PopperRulePassword anchorEl={anchorEl} watchPassword={watchPassword} />

      <TextField
        inputProps={{ maxLength: 255 }}
        label="Password"
        name="password"
        margin="dense"
        required
        fullWidth
        size="small"
        type={showPassword.newPassword ? 'text' : 'password'}
        error={!!errors.password}
        inputRef={register}
        helperText={!!errors.password ? errors.password.message : ''}
        variant="outlined"
        autoComplete="new-password"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="Toggle password visibility  "
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    newPassword: !showPassword.newPassword,
                  })
                }
              >
                {showPassword.newPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          inputProps: { tabIndex: 1 },
        }}
        onFocus={handleOpenPopper}
        onBlur={handleOpenPopper}
      />

      <TextField
        size="small"
        inputProps={{ maxLength: 255 }}
        label="Confirm password"
        type={showPassword.confirmNewPassword ? 'text' : 'password'}
        name="confirmPassword"
        margin="dense"
        required
        fullWidth
        error={!!errors.confirmPassword}
        inputRef={register}
        helperText={
          !!errors.confirmPassword ? errors.confirmPassword.message : ''
        }
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="Toggle password visibility  "
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    confirmNewPassword: !showPassword.confirmNewPassword,
                  })
                }
              >
                {showPassword.confirmNewPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          ),
          inputProps: { tabIndex: 1 },
        }}
        InputLabelProps={{ shrink: true }}
      />
      <Box display="flex" flexDirection="row-reverse">
        <ButtonLoading
          tabIndex={6}
          className="mt-8"
          isSubmit
          text="Save"
          Icon={<CheckIcon />}
          callbackClick={onSubmit}
          loading={loading}
        />
      </Box>
    </Container>
  );
};

export default ResetPassword;
