import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Tooltip } from '@material-ui/core';

type Props = {
  props?: SvgIconProps;
  isHightLight?: boolean;
};

export const PhoneOfficeIcon: React.FC<Props> = ({ props, isHightLight }) => {
  return (
    <Tooltip arrow title="Work">
      <SvgIcon
        color={isHightLight ? 'primary' : 'action'}
        fontSize="small"
        viewBox="0 0 512 512"
        {...props}
      >
        <g>
          <path d="m481.5 179.07v-110.261c0-15.164-12.337-27.5-27.5-27.5h-396c-15.164 0-27.5 12.336-27.5 27.5v110.261c-16.992 1.038-30.5 15.184-30.5 32.432v226.689c0 17.92 14.58 32.5 32.5 32.5h447c17.921 0 32.5-14.58 32.5-32.5v-226.689c0-17.248-13.508-31.394-30.5-32.432zm-466.5 259.121v-226.689c0-8.972 6.79-16.38 15.5-17.377v261.443c-8.71-.997-15.5-8.404-15.5-17.377zm482 0c0 8.972-6.79 16.38-15.5 17.377v-74.27c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v74.394h-235.298v-279.84c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v279.84h-170.702v-386.883c0-6.893 5.607-12.5 12.5-12.5h158.202v84.564c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-84.564h222.798c6.893 0 12.5 5.607 12.5 12.5v277.425c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-152.108c8.71.997 15.5 8.405 15.5 17.377z" />
          <path d="m421.995 88.423h-16.314c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h16.314c1.379 0 2.5 1.122 2.5 2.5v20c0 1.378-1.121 2.5-2.5 2.5h-146.515c-1.379 0-2.5-1.122-2.5-2.5v-20c0-1.378 1.121-2.5 2.5-2.5h95.222c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-95.222c-9.649 0-17.5 7.851-17.5 17.5v20c0 9.649 7.851 17.5 17.5 17.5h146.515c9.649 0 17.5-7.851 17.5-17.5v-20c0-9.649-7.85-17.5-17.5-17.5z" />
          <path d="m160.891 78.075h-66.974c-17.92 0-32.5 14.58-32.5 32.5v186.553c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-186.553c0-9.649 7.851-17.5 17.5-17.5h66.974c9.649 0 17.5 7.851 17.5 17.5v290.851c0 9.649-7.851 17.5-17.5 17.5h-66.974c-9.649 0-17.5-7.851-17.5-17.5v-69.191c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v69.191c0 17.92 14.58 32.5 32.5 32.5h66.974c17.92 0 32.5-14.58 32.5-32.5v-290.851c0-17.921-14.579-32.5-32.5-32.5z" />
          <path d="m271.437 224.002h24.946c6.893 0 12.5-5.607 12.5-12.5v-20c0-6.893-5.607-12.5-12.5-12.5h-24.946c-6.893 0-12.5 5.607-12.5 12.5v20c0 6.893 5.607 12.5 12.5 12.5zm2.5-30h19.946v15h-19.946z" />
          <path d="m361.378 179.002h-24.947c-6.893 0-12.5 5.607-12.5 12.5v20c0 6.893 5.607 12.5 12.5 12.5h24.947c6.893 0 12.5-5.607 12.5-12.5v-20c0-6.892-5.607-12.5-12.5-12.5zm-2.5 30h-19.947v-15h19.947z" />
          <path d="m426.995 179.002h-24.947c-6.893 0-12.5 5.607-12.5 12.5v20c0 6.893 5.607 12.5 12.5 12.5h24.947c6.893 0 12.5-5.607 12.5-12.5v-20c0-6.892-5.607-12.5-12.5-12.5zm-2.5 30h-19.947v-15h19.947z" />
          <path d="m258.937 271.502c0 6.893 5.607 12.5 12.5 12.5h24.946c6.893 0 12.5-5.607 12.5-12.5v-20c0-6.893-5.607-12.5-12.5-12.5h-24.946c-6.893 0-12.5 5.607-12.5 12.5zm15-17.5h19.946v15h-19.946z" />
          <path d="m361.378 239.002h-24.947c-6.893 0-12.5 5.607-12.5 12.5v20c0 6.893 5.607 12.5 12.5 12.5h24.947c6.893 0 12.5-5.607 12.5-12.5v-20c0-6.892-5.607-12.5-12.5-12.5zm-2.5 30h-19.947v-15h19.947z" />
          <path d="m426.995 239.002h-24.947c-6.893 0-12.5 5.607-12.5 12.5v20c0 6.893 5.607 12.5 12.5 12.5h24.947c6.893 0 12.5-5.607 12.5-12.5v-20c0-6.892-5.607-12.5-12.5-12.5zm-2.5 30h-19.947v-15h19.947z" />
          <path d="m258.937 331.502c0 6.893 5.607 12.5 12.5 12.5h24.946c6.893 0 12.5-5.607 12.5-12.5v-20c0-6.893-5.607-12.5-12.5-12.5h-24.946c-6.893 0-12.5 5.607-12.5 12.5zm15-17.5h19.946v15h-19.946z" />
          <path d="m361.378 299.002h-24.947c-6.893 0-12.5 5.607-12.5 12.5v20c0 6.893 5.607 12.5 12.5 12.5h24.947c6.893 0 12.5-5.607 12.5-12.5v-20c0-6.892-5.607-12.5-12.5-12.5zm-2.5 30h-19.947v-15h19.947z" />
          <path d="m426.995 299.002h-24.947c-6.893 0-12.5 5.607-12.5 12.5v20c0 6.893 5.607 12.5 12.5 12.5h24.947c6.893 0 12.5-5.607 12.5-12.5v-20c0-6.892-5.607-12.5-12.5-12.5zm-2.5 30h-19.947v-15h19.947z" />
          <path d="m410.284 371.748c-16.106 0-29.21 13.104-29.21 29.21s13.104 29.21 29.21 29.21c16.107 0 29.211-13.104 29.211-29.21s-13.103-29.21-29.211-29.21zm0 43.419c-7.835 0-14.21-6.375-14.21-14.21s6.375-14.21 14.21-14.21c7.836 0 14.211 6.375 14.211 14.21s-6.375 14.21-14.211 14.21z" />
          <path d="m348.904 408.458h-82.468c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h82.468c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5z" />
          <path d="m348.904 378.458h-82.468c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h82.468c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5z" />
        </g>
      </SvgIcon>
    </Tooltip>
  );
};
export default PhoneOfficeIcon;
