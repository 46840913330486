import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  DialogTitle,
} from '@material-ui/core';
import { DialogButton, IosShareIcon } from 'components';

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
};

export const DialogIosPwaInstall: React.FC<Props> = ({
  open,
  toogleDialog,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => toogleDialog(false)}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className="text-tranform-none">
        You need follow 2 steps bellow
      </DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center">
          <Typography>1. Tap the share button</Typography>
          <IosShareIcon />
        </Box>
        <Typography>2. Find and tap 'Add to Homescreen'</Typography>
      </DialogContent>
      <DialogActions>
        <DialogButton isCancel onClickButton={() => toogleDialog(false)} />
      </DialogActions>
    </Dialog>
  );
};
export default React.memo(DialogIosPwaInstall);
