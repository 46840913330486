import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

type Props = {
  props?: SvgIconProps;
  fillColor?: string;
};

export const QrCodeIcon: React.FC<Props> = ({ props, fillColor }) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M3 11h2v2H3v-2m8-6h2v4h-2V5m-2 6h4v4h-2v-2H9v-2m6 0h2v2h2v-2h2v2h-2v2h2v4h-2v2h-2v-2h-4v2h-2v-4h4v-2h2v-2h-2v-2m4 8v-4h-2v4h2M15 3h6v6h-6V3m2 2v2h2V5h-2M3 3h6v6H3V3m2 2v2h2V5H5M3 15h6v6H3v-6m2 2v2h2v-2H5z"
        fill={fillColor ?? '#626262'}
      />
    </SvgIcon>
  );
};
export default QrCodeIcon;
