import React, { useCallback, useContext } from 'react';
import {
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Chip,
  Box,
} from '@material-ui/core';
import { TableCellMaxWidthStyled } from 'share/component_css';
import EditIcon from '@material-ui/icons/Edit';
import {
  StageChangeLogFFragment,
  ShareStatus,
  UpdateStageChangedLogTimeMutation,
  UpdateStageChangedLogTimeMutationVariables,
} from 'types.d';
import { formatDate } from 'share/utils';
import DialogFilterDate from 'modules/dashboard/components/Dialogs/DialogFilterDate';
import { DataFilterDate } from 'modules/dashboard/interfaces';
import { UPDATE_STAGE_CHANGED_LOG_TIME } from 'gql';
import { useMutationCustom } from 'hooks';
import { PatientDetailContext } from 'share/context';

type Props = {
  stage: StageChangeLogFFragment;
};

export const StageChangeLog: React.FC<Props> = ({ stage }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const patientDetailContext = useContext(PatientDetailContext);

  // const [deleteStageChangeLog] = useMutationCustom<
  //   DeleteStageChangedLogMutation,
  //   DeleteStageChangedLogMutationVariables
  // >({
  //   api: DELETE_STAGE_CHANGE_LOG,
  //   textSuccess: 'Delete stage successfully',
  //   callbackSuccess: data => {},
  // });

  const [updateStageChangeLogTime] = useMutationCustom<
    UpdateStageChangedLogTimeMutation,
    UpdateStageChangedLogTimeMutationVariables
  >({
    api: UPDATE_STAGE_CHANGED_LOG_TIME,
    textSuccess: 'Update date of stage successfully',
    callbackSuccess: data => {
      handleClose();
    },
  });

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const renderStatus = (status?: string | null) => {
    if (status) {
      return status === ShareStatus.Sending ? (
        <Chip label="Sent" />
      ) : (
        <Chip label="Open" color="primary" />
      );
    }
    return <Chip label="N/A" disabled />;
  };

  const filterDate = (date?: DataFilterDate | string) => {
    updateStageChangeLogTime({
      variables: {
        params: {
          startDate: (date as DataFilterDate)?.startDate,
          endDate: (date as DataFilterDate)?.endDate,
          _id: stage._id,
          patientId: patientDetailContext?.patient?._id,
        },
      },
    });
  };

  // const handleDeleteStageLog = (key: string) => {
  //   deleteStageChangeLog({ variables: { params: { _id: key } } });
  // };

  return (
    <>
      <DialogFilterDate
        anchorEl={anchorEl}
        handleClose={handleClose}
        filterData={filterDate}
        dateSelected={{
          startDate: stage.startDate,
          endDate: stage.endDate,
        }}
        acceptNull
      />
      <TableRow>
        <TableCellMaxWidthStyled maxwidth="200px">
          {stage.root?.name}
        </TableCellMaxWidthStyled>
        <TableCellMaxWidthStyled maxwidth="200px">
          {stage.treatment?.name}
        </TableCellMaxWidthStyled>
        <TableCell>{renderStatus(stage.status)}</TableCell>
        <TableCell>{stage?.treatmentStageEmbed?.name || 'N/A'}</TableCell>
        <TableCell>
          <Box display="flex" alignItems="center">
            {stage.startDate ? formatDate(stage.startDate) : 'N/A'}
          </Box>
        </TableCell>
        <TableCell
          align={!patientDetailContext?.isInactive ? 'inherit' : 'right'}
        >
          {stage.endDate ? formatDate(stage.endDate) : 'N/A'}
        </TableCell>
        {!patientDetailContext?.isInactive && (
          <TableCell align="right">
            <Tooltip arrow title="Edit date of stage log">
              <IconButton
                onClick={e => setAnchorEl(e.currentTarget as any)}
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}

        {/* <ButtonDelete
            loadingDelete={loadingDeleteStageChangeLog}
            deleteItem={handleDeleteStageLog}
            id={stage._id}
            idSelected={idStageLogDeleted.current}
          /> */}
      </TableRow>
    </>
  );
};

export default React.memo(StageChangeLog);
