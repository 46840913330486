import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import { useZoomDetection } from 'hooks/useZoomDetection';

const GTM_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID!; // Replace with your GTM ID

export const initializeTagManager = () => {
  const tagManagerArgs = {
    gtmId: GTM_ID,
    dataLayerName: 'dataLayer',
    events: {
      send_to: GTM_ID,
    },
  };
  TagManager.initialize(tagManagerArgs);
};

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageView',
        pagePath: location.pathname,
      },
    });
  }, [location]);
};

// For a button click
export const trackButtonClickEvent = (btnLabel: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'btnClick',
      btnLabel: btnLabel,
    },
  });
};

export const ChatbotRan = (userType?: string) => {
  var obj;
  if (userType) {
    obj = {
      event: 'chatbotHit',
      userType: userType,
    };
  } else {
    obj = {
      event: 'contentAssistant',
    };
  }
  TagManager.dataLayer({
    dataLayer: obj,
  });
};

export const SurveyFilledFromChatbot = (surveyType: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'surveyFromChatbot',
      surveyType: surveyType,
    },
  });
};

export const useZoomLevelTracking = () => {
  const zoomLevel = useZoomDetection();
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'zoomLevel',
        zoomLevel: zoomLevel,
      },
    });
  }, [zoomLevel]);
};

export const loginEvent = (role: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'login',
      userRole: role,
    },
  });
};

export const organizationEvent = (org: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'orgData',
      orgId: org.id,
      orgName: org.name,
      userId: org.userId,
      userName: org.userName,
    },
  });
};

export const customEvent = (event: string, role: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event: event,
      userRole: role,
    },
  });
};
