import React from 'react';
import {
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { TableFullWidthStyled } from 'share/component_css';
import { PatientTimeRecordFragment } from 'types.d';
import { NoDataTable } from 'components';
import Report from '../TimeRecord/Report';

type Props = {
  records?: PatientTimeRecordFragment[];
  checkItem: (event: React.ChangeEvent<HTMLInputElement>, item: string) => void;
  checkedList: string[];
  setCheckedList: (items: string[]) => void;
};

export const TableRecord: React.FC<Props> = ({
  records,
  checkItem,
  checkedList,
  setCheckedList,
}) => {
  return (
    <>
      <TableContainer component={Paper}>
        <TableFullWidthStyled stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">Module</TableCell>
              <TableCell>Time spent</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records?.length === 0 && <NoDataTable colSpan={2} />}
            {records?.map((item, index) => {
              return (
                <Report
                  key={index}
                  report={item}
                  index={index}
                  checkedList={checkedList}
                  checkItem={checkItem}
                  setCheckedList={setCheckedList}
                />
              );
            })}
          </TableBody>
        </TableFullWidthStyled>
      </TableContainer>
    </>
  );
};
export default React.memo(TableRecord);
