import gql from 'graphql-tag';
import { ResponseLoginFragment } from './fragments';

export const LOG_OUT = gql`
  mutation Logout {
    logout
  }
`;

export const LOGIN = gql`
  mutation Login($params: LoginInput!) {
    login(params: $params) {
      ...ResponseLogin
    }
  }
  ${ResponseLoginFragment}
`;

export const LOGIN_PHONE = gql`
  mutation LoginPhone($params: LoginPhoneInput!) {
    loginPhone(params: $params) {
      ...ResponseLogin
    }
  }
  ${ResponseLoginFragment}
`;

export const LOGIN_GOOGLE = gql`
  mutation GoogleAuth($idToken: String!) {
    googleAuth(idToken: $idToken) {
      ...ResponseLogin
    }
  }
  ${ResponseLoginFragment}
`;

export const LOGIN_FACEBOOK = gql`
  mutation FacebookAuth($idToken: String!) {
    facebookAuth(idToken: $idToken) {
      ...ResponseLogin
    }
  }
  ${ResponseLoginFragment}
`;

export const ACTIVE_USER = gql`
  mutation ActiveUser($params: ActiveUserInput!) {
    activeUser(params: $params) {
      ...ResponseLogin
    }
  }
  ${ResponseLoginFragment}
`;

export const VERIFY_USER_PHONE = gql`
  mutation VerifyPhoneUser($params: ActiveUserOTPInput!) {
    verifyPhoneUser(params: $params) {
      ...ResponseLogin
    }
  }
  ${ResponseLoginFragment}
`;

export const ME = gql`
  query Me {
    me {
      ...ResponseLogin
    }
  }
  ${ResponseLoginFragment}
`;

export const VERIFY_EMAIL_FORGOT_PASSWORD = gql`
  mutation VerifyEmailForgotPassword($params: VerifyEmailForgotPasswordInput!) {
    verifyEmailForgotPassword(params: $params)
  }
`;

export const VERIFY_PHONE_FORGOT_PASSWORD = gql`
  mutation VerifyPhoneNumberForgotPassword(
    $params: VerifyPhoneNumberForgotPasswordInput!
  ) {
    verifyPhoneNumberForgotPassword(params: $params)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($params: ResetPasswordInput!) {
    resetPassword(params: $params)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($params: ChangePasswordInput!) {
    changePassword(params: $params) {
      email
    }
  }
`;

export const SIGN_UP = gql`
  mutation Register($params: RegisterInput!) {
    register(params: $params) {
      email
      firstName
      lastName
      _id
    }
  }
`;

export const SIGN_UP_PHONE = gql`
  mutation RegisterPhoneUser($params: RegisterPhoneUser!) {
    registerPhoneUser(params: $params) {
      phones
      firstName
      lastName
      _id
    }
  }
`;

export const RESEND_EMAIL_VERIFY = gql`
  mutation ResendEmailVerify($params: ResendAccountEmailVerify!) {
    resendEmailVerify(params: $params)
  }
`;

export const RESEND_OTP = gql`
  mutation ResendOTP($params: resendOTPInput!) {
    resendOTP(params: $params)
  }
`;
