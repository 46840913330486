import gql from 'graphql-tag';
import { SurveyVersionFragment } from './fragments';

export const GET_SHORT_LINK = gql`
  query GetShortLink($params: GetShortLinkInput!) {
    getShortLink(params: $params) {
      _id
      link
      key
      expiredDate
    }
  }
`;

export const GET_SURVEY_WITH_TOKEN = gql`
  query GetSurveyWithToken($params: GetSurveyInput!) {
    getSurveyWithToken(params: $params) {
      treatment {
        ...SurveyVersion
      }
      firstName
    }
  }
  ${SurveyVersionFragment}
`;
