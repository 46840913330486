import React, { useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { styled } from '@material-ui/core/styles';
import { Box, Chip, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

type Props = {
  valueCustom: string;
  setValueCustom: (value: string) => void;
  disable?: boolean;
};

export const data = [
  { id: 'link', value: '{{link}}', display: 'Link' },
  { id: 'orgName', value: '{{orgName}}', display: 'Org Name' },
  { id: 'firstName', value: '{{firstName}}', display: 'First Name' },
];

export const StepWrapper = styled('fieldset')(
  ({ isfocus, disable }: { isfocus: string; disable: string }) => ({
    border: Object.is(isfocus, 'true')
      ? '2px solid rgb(25, 118, 210)'
      : '1px solid #cccccc',
    borderRadius: '4px',
    minWidth: 0,
    '& .direction-input-wrapper': {
      position: 'relative',
      '& .input-wrapper': {
        width: '100%',
        height: '108px',
      },
    },
    '& .direction-input': {
      height: '80px',
      '& .direction-input__input': {
        border: 'none',
        color: Object.is(disable, 'true') ? '#ccc' : 'black',
        '&:focus-visible': {
          outline: 'none',
          boxShadow: 'none',
        },
      },
      '& .direction-input__suggestions': {
        position: 'absolute',
        borderRadius: '4px',
        boxShadow:
          '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        '& .direction-input__suggestions__list': {
          padding: '10px',
        },
        '& .direction-input__suggestions__item': {
          padding: '7px',
        },
        '& .direction-input__suggestions__item--focused': {
          backgroundColor: '#e3edfd',
        },
      },
    },
  }),
);

export const InputTypeMentions: React.FC<Props> = ({
  valueCustom,
  setValueCustom,
  disable,
}) => {
  const [isfocus, setFocus] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  return (
    <StepWrapper
      isfocus={isfocus ? 'true' : 'false'}
      disable={disable ? 'true' : 'false'}
    >
      <legend>
        <Typography
          className="m-4"
          color={isfocus ? 'primary' : 'textSecondary'}
          variant="caption"
        >
          SMS Message
        </Typography>
      </legend>
      <div className="direction-input-wrapper">
        <div className="input-wrapper">
          <MentionsInput
            value={valueCustom}
            onChange={e => {
              setValueCustom(e.target.value.slice(0, 120));
            }}
            allowSpaceInQuery
            placeholder={`Type here`}
            className="direction-input"
            maxLength={120}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            disabled={disable}
          >
            <Mention
              trigger="{"
              data={data}
              markup="{{__id__}}"
              displayTransform={id => '{{' + id + '}}'}
            />
          </MentionsInput>
          <Box
            className="mr-8"
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            {!disable &&
              data.map(item => {
                return (
                  <Chip
                    key={item.value}
                    className="mr-8"
                    onClick={e => {
                      setFocus(true);
                      if (valueCustom.length + item.value.length > 120) {
                        enqueueSnackbar(
                          'Message must be less than 120 characters',
                          {
                            variant: 'error',
                          },
                        );
                        return;
                      }
                      setValueCustom(valueCustom.concat(item.value));
                    }}
                    variant="default"
                    label={item.display}
                    color="default"
                  />
                );
              })}
            <Typography color="textSecondary" variant="subtitle2">
              {`${
                valueCustom.length < 10
                  ? `0${valueCustom.length}`
                  : `${valueCustom.length}`
              }/120`}
            </Typography>
          </Box>
        </div>
      </div>
    </StepWrapper>
  );
};
export default InputTypeMentions;
