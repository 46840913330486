import React from 'react';
import { Dialog, Box } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { styled, Theme } from '@material-ui/core/styles';
import { ImageStyled } from 'share/component_css';
import { DEFAULT_IMAGE } from 'CONST';
import { useToogleDialog } from 'hooks';

export const ImgStyled = styled(ImageStyled)(
  ({ theme, src }: { theme: Theme; src: string }) => ({
    backgroundImage: `url(${src || DEFAULT_IMAGE})`,
    cursor: 'pointer',
  }),
);

const ImgDialogStyled = styled(ImageStyled)(
  ({ theme, src }: { theme: Theme; src: string }) => ({
    backgroundImage: `url(${src || DEFAULT_IMAGE})`,
    borderRadius: '0px',
    border: 0,
    backgroundSize: 'contain',
  }),
);

const WrapVideo = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
}));

type Props = {
  type: string | undefined;
  srcUrl: string;
  thumbNailsUrls?: string[] | null;
  preventOpenDialog?: boolean;
};

const VideoPlayer = ({ srcUrl }: { srcUrl: string }) => {
  return (
    <ReactPlayer
      controls
      url={`${process.env.REACT_APP_LINK_S3}${process.env.REACT_APP_MEDIA_BUCKET}${srcUrl}`}
      width="100%"
      height="100%"
    />
  );
};

export const MediaComponent: React.FC<Props> = ({
  type,
  srcUrl,
  preventOpenDialog,
  thumbNailsUrls,
}) => {
  const [isOpen, toogleDialog] = useToogleDialog();

  const handleOpen = () => {
    if (!preventOpenDialog!) {
      toogleDialog(true);
    }
    return;
  };

  return (
    <>
      <Dialog
        aria-describedby="alert-dialog-content"
        open={isOpen}
        onClose={() => toogleDialog(false)}
        fullWidth
      >
        <Box position="relative" width="100%" pt="75%">
          {type === 'image' ? (
            <ImgDialogStyled
              src={`${process.env.REACT_APP_LINK_S3}${process.env.REACT_APP_MEDIA_BUCKET}${srcUrl}`}
            />
          ) : (
            <WrapVideo
              width="100%"
              height="100%"
              top={0}
              left={0}
              position="absolute"
            >
              <VideoPlayer srcUrl={srcUrl} />
            </WrapVideo>
          )}
        </Box>
      </Dialog>
      {type === 'image' ? (
        <ImgStyled
          onClick={handleOpen}
          src={`${process.env.REACT_APP_LINK_S3}${
            process.env.REACT_APP_MEDIA_BUCKET
          }${thumbNailsUrls![0]}`}
        />
      ) : (
        <Box
          position="absolute"
          top={0}
          left={0}
          borderRadius={10}
          width="100%"
          height="100%"
          overflow="hidden"
          onClick={handleOpen}
          className="cursor-pointer"
        >
          <Box className="pointer-events-none" width="100%" height="100%">
            <VideoPlayer srcUrl={srcUrl} />
          </Box>
        </Box>
        // <ImgStyled onClick={handleOpen} src={DEFAULT_THUMBNAIL_VIDEO} />
      )}
    </>
  );
};
export default React.memo(MediaComponent);
