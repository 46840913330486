import React, { useMemo } from 'react';
import { KeyboardDateOption, TextfieldSelect } from 'components';
import { DataCalendar, FormData } from '../DialogSendSurvey';
import { Controller, FormContextValues } from 'react-hook-form';
import { Grid, MenuItem } from '@material-ui/core';
import { EventClickArg } from '@fullcalendar/react';
import {
  capitalizeFirstLetter,
  getByNWeekDay,
  getOptionTypeRepeat,
  getRRuleToText,
  optionByNWeekday,
  optionDay,
  optionWeekly,
} from 'share/utils';
import {
  KeyboardTimePickerStyled,
  TypographyItalic,
} from 'share/component_css';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Frequency } from 'rrule';

type Props = {
  methods: FormContextValues<FormData>;
  currentEvent?: EventClickArg;
  sendBy: string;
  isDisableUpdate: boolean;
  handleChangeType: (value: string) => void;
  recurrenceDay: string;
  dataCalendar: DataCalendar | null;
  optionFreq:
  | Frequency.YEARLY
  | Frequency.MONTHLY
  | Frequency.WEEKLY
  | Frequency.DAILY;
};

export const CustomizeSendingTime: React.FC<Props> = ({
  methods,
  currentEvent,
  sendBy,
  isDisableUpdate,
  handleChangeType,
  recurrenceDay,
  dataCalendar,
  optionFreq,
}) => {
  const watchSendingDate = methods!.watch('sendingDate');

  const optionByWeekDayForHint = useMemo(() => {
    if (dataCalendar?.freq === 'week') {
      return [optionWeekly(dataCalendar.repeatWeek)];
    }
    if (
      dataCalendar?.freq === 'month' &&
      dataCalendar.repeatMonth === 'monthly'
    ) {
      return [
        getByNWeekDay(optionDay(watchSendingDate)).nth(
          optionByNWeekday(watchSendingDate),
        ),
      ];
    }
  }, [dataCalendar, watchSendingDate]);

  const hintText = useMemo(() => {
    if (dataCalendar) {
      return getRRuleToText(
        dataCalendar,
        optionFreq,
        watchSendingDate,
        optionByWeekDayForHint as number[],
      );
    }
  }, [dataCalendar, optionByWeekDayForHint, optionFreq, watchSendingDate]);

  return (
    <>
      <Grid item xs={4}>
        <KeyboardDateOption
          methods={methods}
          name="sendingDate"
          label={
            currentEvent?.event?.extendedProps?.treatmentRegimenId ||
              sendBy === 'group'
              ? 'Anchor Date'
              : 'Sending Date'
          }
          disable={isDisableUpdate}
          moreRules={
            currentEvent?.event?.extendedProps?.apptTime
              ? {
                compare: value => {
                  const selectedDate = new Date(value);
                  const apptDate = new Date(currentEvent.event.extendedProps.apptTime);
                  // Check if selectedDate or apptDate are invalid
                  if (isNaN(selectedDate.getTime()) || isNaN(apptDate.getTime())) {
                    return 'Invalid date format.';
                  }

                  // Compare dates
                  if (selectedDate >= apptDate) {
                    return 'Selected date should not be later than the appointment date: ' + new Date(apptDate);
                  }
                  return true;
                }
              }
              : {}
          }
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          as={
            <KeyboardTimePickerStyled
              required
              placeholder="hh:mm"
              inputVariant="outlined"
              label={
                currentEvent?.event?.extendedProps?.treatmentRegimenId ||
                  sendBy === 'group'
                  ? 'Anchor Time'
                  : 'Sending Time'
              }
              InputLabelProps={{ shrink: true }}
              size="small"
              fullWidth
              value={() => { }}
              onChange={() => { }}
              variant="inline"
              error={!!methods!.errors.sendingTime}
              helperText={methods!.errors.sendingTime?.message || ''}
              disabled={isDisableUpdate}
              keyboardIcon={<AccessTimeIcon />}
            />
          }
          name="sendingTime"
          control={methods!.control}
          rules={currentEvent?.event?.extendedProps?.apptTime ? {
            validate: {
              required: value => value !== null || 'This field is required',
              pattern: value => {
                return (
                  !isNaN(new Date(value).getTime()) || 'Invalid date format'
                );
              },
              compare: value => {
                const selectedDate = new Date(value);
                const apptDate = new Date(currentEvent.event.extendedProps.apptTime);
                // Check if selectedDate or apptDate are invalid
                if (isNaN(selectedDate.getTime()) || isNaN(apptDate.getTime())) {
                  return 'Invalid date format.';
                }

                // Compare dates
                if (selectedDate.getTime() >= apptDate.getTime()) {
                  return 'Selected date should not be later than the appointment date: ' + new Date(apptDate);
                }
                return true;
              }
            },
          } : {
            validate: {
              required: value => value !== null || 'This field is required',
              pattern: value => {
                return (
                  !isNaN(new Date(value).getTime()) || 'Invalid date format'
                );
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextfieldSelect
          label="Repeat"
          name="repeat"
          small
          value={recurrenceDay}
          disabled={isDisableUpdate || sendBy === 'group'}
        >
          {getOptionTypeRepeat(watchSendingDate).map(item => {
            return (
              <MenuItem
                onClick={() => handleChangeType(item.value)}
                key={item.value}
                value={item.value}
              >
                {item.title}
              </MenuItem>
            );
          })}
        </TextfieldSelect>
      </Grid>
      <Grid item xs={12}>
        {dataCalendar && recurrenceDay === 'custom' && sendBy !== 'group' && (
          <TypographyItalic color="textSecondary" variant="subtitle2">
            {hintText ||
              capitalizeFirstLetter(currentEvent?.event.textColor || '')}
          </TypographyItalic>
        )}
      </Grid>
    </>
  );
};

export default React.memo(CustomizeSendingTime);
