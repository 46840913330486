import { styled } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';

export const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  marginRight: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
}));
