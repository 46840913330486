import React, { useMemo, useContext, forwardRef } from 'react';
import Autocomplete, {
  createFilterOptions,
  AutocompleteProps,
  AutocompleteGetTagProps,
} from '@material-ui/lab/Autocomplete';
import { Controller, Control } from 'react-hook-form';
import { Box, Typography, TextField, Chip, Tooltip } from '@material-ui/core';
import { FixedSizeList } from 'react-window';
import { OuterElementContext } from 'share/context';
type OptionValue = {
  _id?: string | null;
  name?: string | null;
  label?: string | null;
  description?: string | null;
};

type OptionExtend<TOption> = TOption & OptionValue;

type Props<TOption> = {
  options: OptionExtend<TOption>[];
  control?: Control;
  nameOption: 'name' | 'label' | 'description';
  name: string;
  label: string;
  multiple?: boolean;
  small?: boolean;
  numberOfValue?: number;
  defaultValue?: string | string[];
  callbackChangeValue?: (event: any, newValue: any) => void;
  callbackRenderOption?: (
    option: OptionExtend<TOption>,
    selected: boolean,
  ) => JSX.Element;
  error?: boolean;
  required?: boolean;
  disablePortal?: boolean;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  disabledOptions?: string[];
  hasMargin?: boolean;
  hasAllValue?: boolean;
  sortTagsByOptions?: boolean;
  isApplyReactWindow?: boolean;
  isNotFilterOption?: boolean;
};

const renderRow = (props: any) => {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top,
    },
  });
};

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref as any} {...props} {...outerProps} />;
});

const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;

  return (
    <div ref={ref as any}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          height={250}
          width="100%"
          innerElementType="ul"
          itemCount={itemCount}
          outerElementType={OuterElementType}
          itemSize={35}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export const AutoCompleteSelect = <TOption extends object>({
  options,
  control,
  nameOption,
  name,
  label,
  multiple,
  small,
  defaultValue,
  callbackChangeValue,
  callbackRenderOption,
  error,
  required,
  disablePortal,
  placeholder,
  disabled,
  className,
  disabledOptions,
  numberOfValue,
  hasMargin,
  hasAllValue,
  sortTagsByOptions,
  isApplyReactWindow,
  isNotFilterOption,
}: Props<TOption>) => {
  const autocompleteOptions = useMemo(() => {
    return hasAllValue
      ? [{ _id: 'all', name: 'All' } as OptionExtend<TOption>, ...options]
      : options;
  }, [hasAllValue, options]);

  const autoCompleteOptions = useMemo(() => {
    let allOptions: AutocompleteProps<any, any, any, any> = {
      className: className || '',
      disabled: !!disabled,
      disablePortal: !Boolean(disablePortal),
      autoHighlight: true,
      options: autocompleteOptions,
      getOptionLabel: (option: OptionExtend<TOption>) =>
        option[nameOption] || '',
      renderTags: (tagValue: any[], getTagProps: AutocompleteGetTagProps) => {
        const valueSort = options?.filter(item =>
          tagValue?.map(tag => tag?._id).includes(item._id),
        );
        return (
          <Box display="flex" flexWrap="wrap" alignItems="center">
            {(!sortTagsByOptions ? tagValue : valueSort)
              .slice(0, numberOfValue ? numberOfValue : 2)
              .map((option, index) => (
                <Chip
                  label={option[nameOption]}
                  {...getTagProps({ index })}
                  disabled={!!disabledOptions?.includes(option?._id as string)}
                />
              ))}
            {(sortTagsByOptions ? valueSort.length : tagValue.length) >
              (numberOfValue ? numberOfValue : 2) && (
              <Typography variant="subtitle2">{`+${(sortTagsByOptions
                ? valueSort.length
                : tagValue.length) -
                (numberOfValue ? numberOfValue : 2)}`}</Typography>
            )}
          </Box>
        );
      },
      renderInput: (params: any) => (
        <TextField
          required={required}
          InputLabelProps={{ shrink: true }}
          {...{
            InputProps: params.InputProps,
            id: params.id,
            disabled: params.disabled,
            inputProps: params.inputProps,
          }}
          margin={hasMargin ? 'dense' : 'none'}
          size={small ? 'small' : 'medium'}
          label={label}
          variant="outlined"
          fullWidth
          disabled={!!disabled}
          placeholder={placeholder || label}
        />
      ),
      filterOptions: isNotFilterOption
        ? (options: any) => options
        : createFilterOptions({
            matchFrom: 'any',
            ignoreCase: true,
            ignoreAccents: true,
            stringify: (option: OptionExtend<TOption>) =>
              option[nameOption] || '',
          }),
    };
    if (isApplyReactWindow) {
      allOptions.ListboxComponent = ListboxComponent as React.ComponentType<
        React.HTMLAttributes<HTMLElement>
      >;
    }
    return allOptions;
  }, [
    autocompleteOptions,
    className,
    disablePortal,
    disabled,
    disabledOptions,
    hasMargin,
    isApplyReactWindow,
    label,
    nameOption,
    numberOfValue,
    options,
    placeholder,
    required,
    small,
    sortTagsByOptions,
    isNotFilterOption,
  ]);

  const defaultValueAutocomplete = useMemo(() => {
    if (multiple) {
      return defaultValue
        ? autocompleteOptions.filter(item =>
            (defaultValue as string[]).includes(item._id as string),
          )
        : [];
    } else {
      return defaultValue
        ? autocompleteOptions.find(item =>
            Object.is(item._id, defaultValue as string),
          )
        : null;
    }
  }, [autocompleteOptions, defaultValue, multiple]);

  const changeValueAutoComplete = (event: any, newValue: any) => {
    if (callbackChangeValue) {
      callbackChangeValue(event, newValue);
    }
  };

  const renderAutoComplete = () => {
    return multiple ? (
      <Autocomplete
        {...autoCompleteOptions}
        onChange={(event: any, newValue: any) =>
          changeValueAutoComplete(event, newValue)
        }
        renderOption={(option, optionState) => {
          return renderOption(option, optionState.selected);
        }}
        disableCloseOnSelect
        size={small ? 'small' : 'medium'}
        multiple
        value={defaultValueAutocomplete as OptionExtend<TOption>[]}
      />
    ) : (
      <Autocomplete
        {...autoCompleteOptions}
        onChange={(event: any, newValue: any) =>
          changeValueAutoComplete(event, newValue)
        }
        renderOption={(option, optionState) => {
          return renderOption(option, optionState.selected);
        }}
        size={small ? 'small' : 'medium'}
        value={defaultValueAutocomplete as OptionExtend<TOption>}
      />
    );
  };

  const renderOption = (option: OptionExtend<TOption>, selected: boolean) => {
    if (callbackRenderOption) {
      return callbackRenderOption(option, selected);
    } else {
      return (
        <Tooltip title={option[nameOption] || ''}>
          <Typography variant="subtitle2" noWrap>
            {option[nameOption]}
          </Typography>
        </Tooltip>
      );
    }
  };

  return (
    <>
      {control ? (
        <Controller
          as={
            <Autocomplete
              {...autoCompleteOptions}
              renderOption={(option, optionState) => {
                return renderOption(option, optionState.selected);
              }}
              getOptionDisabled={options => {
                return !!disabledOptions?.includes(options?._id as string);
              }}
              size={small ? 'small' : 'medium'}
              multiple={!!multiple}
              disableCloseOnSelect={!!multiple}
              renderInput={params => (
                <TextField
                  required={required}
                  InputLabelProps={{ shrink: true }}
                  {...{
                    InputProps: params.InputProps,
                    id: params.id,
                    disabled: params.disabled,
                    inputProps: params.inputProps,
                  }}
                  margin={hasMargin ? 'dense' : 'none'}
                  label={label}
                  size={small ? 'small' : 'medium'}
                  variant="outlined"
                  fullWidth
                  error={error}
                  helperText={error ? 'This field is required' : ''}
                />
              )}
            />
          }
          name={name}
          onChange={([, data]) => data}
          control={control}
          rules={{
            validate: {
              required: value => {
                if (required) {
                  return multiple
                    ? value.length !== 0 || 'This field is required'
                    : Boolean(value) || 'This field is required';
                }
                return true;
              },
            },
          }}
          defaultValue={defaultValueAutocomplete}
        />
      ) : (
        renderAutoComplete()
      )}
    </>
  );
};

export default React.memo(AutoCompleteSelect);
