import React from 'react';
import { Switch, SwitchProps } from '@material-ui/core';

export const ToggleSwitch: React.FC<SwitchProps> = ({
  checked,
  onChange,
  name,
  color,
}) => {
  return (
    <>
      <Switch
        checked={checked}
        onChange={onChange}
        name={name}
        color={color || 'primary'}
      />
    </>
  );
};
