import gql from 'graphql-tag';
import { MetaFragment, FollowUpTypeSettingFragment } from './fragments';

export const GET_FOLLOW_UP_TYPES = gql`
  query GetFollowUpTypes($params: GetFollowUpTypesInput!) {
    getFollowUpTypes(params: $params) {
      nodes {
        ...FollowUpTypeSetting
      }
      meta {
        ...Meta
      }
    }
  }
  ${MetaFragment}
  ${FollowUpTypeSettingFragment}
`;

export const CREATE_FOLLOW_UP_TYPE = gql`
  mutation CreateFollowUpType($params: CreateFollowUpTypeInput!) {
    createFollowUpType(params: $params) {
      idFollowUp: _id
    }
  }
`;

export const UPDATE_FOLLOW_UP_TYPE = gql`
  mutation UpdateFollowUpType($params: UpdateFollowUpTypeInput!) {
    updateFollowUpType(params: $params) {
      ...FollowUpTypeSetting
    }
  }
  ${FollowUpTypeSettingFragment}
`;

export const DELETE_FOLLOW_UP_TYPE = gql`
  mutation DeleteFollowUpType($params: DeleteFollowUpTypeInput!) {
    deleteFollowUpType(params: $params)
  }
`;

export const GET_ASSOCIATED_FOLLOW_UP_TYPES = gql`
  query GetAssociatedFollowUpTypes($params: GetAssociatedFollowUpTypesInput!) {
    getAssociatedFollowUpTypes(params: $params) {
      nodes {
        _id
        description
        name
        color
      }
    }
  }
`;

export const HANDLE_FAVORITE_FOLLOW_UP_TYPE = gql`
  mutation HandleFavoriteFollowUpType(
    $params: UpdateFavoriteFollowUpTypeInput!
  ) {
    handleFavoriteFollowUpType(params: $params) {
      ...FollowUpTypeSetting
    }
  }
  ${FollowUpTypeSettingFragment}
`;

export const REMOVE_ONE_TREATMENT = gql`
  mutation RemoveOneTreatment($params: RemoveOneTreatmentInput!) {
    removeOneTreatment(params: $params) {
      _id
      treatment {
        _id
        name
      }
      folder {
        _id
        name
      }
    }
  }
`;

export const ADD_MORE_TREATMENT = gql`
  mutation AddMoreTreatment($params: AddMoreTreatmentInput!) {
    addMoreTreatment(params: $params) {
      _id
      treatment {
        _id
        name
      }
      folder {
        _id
        name
      }
    }
  }
`;
