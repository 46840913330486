import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Divider, MenuItem } from '@material-ui/core';
import {
  AutoCompleteLoadData,
  AutoCompleteSelect,
  KeyboardDateOption,
  TextfieldSelect,
} from 'components';
import { FormData } from './DialogAddPatient';
import { Controller, FormContextValues } from 'react-hook-form';
import {
  GetTreatmentAddPatientQuery,
  GetTreatmentAddPatientQueryVariables,
  ScheduleConfigEmbedded,
  TreatmentType,
  GetCommonDataQuery,
  GetCommonDataQueryVariables,
  GetTreatmentRegimenAddPatientQuery,
  GetTreatmentRegimenAddPatientQueryVariables,
  CommonTreatmentFragment,
} from 'types.d';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  GET_COMMON_DATA,
  GET_TREATMENT_ADD_PATIENT,
  GET_TREATMENT_REGIMEN_ADD_PATIENT,
} from 'gql';
import {
  useCustomPopup,
  useEffectOnlyOnce,
  useToogleDialog,
  useUpdateMeClient,
} from 'hooks';
import {
  capitalizeFirstLetter,
  convertDateWithoutUTC,
  getByNWeekDay,
  getOptionTypeRepeat,
  optionByNWeekday,
  optionDay,
  optionWeekly,
  sortObject,
} from 'share/utils';
import {
  KeyboardTimePickerStyled,
  TypographyBold,
  TypographyItalic,
} from 'share/component_css';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useSnackbar } from 'notistack';
import { DataCalendar } from './Survey/DialogSendSurvey';
import { RRule } from 'rrule';
import { DEFAULT_LIMIT } from 'CONST';
import TableListGroup from './Survey/Components/TableListGroup';
type Props = {
  methods: FormContextValues<FormData>;
  dataCalendar?: DataCalendar | null;
  setDataCalendar?: (value: DataCalendar | null) => void;
  setRecurrenceDay: (value: string) => void;
  recurrenceDay: string;
  OnlyChecked: any;
  defaulCheckedProps: {
    checked: string[];
    handleSetChecked: (
      event: React.ChangeEvent<HTMLInputElement>,
      value: string,
    ) => void;
  };
  treatmentHomePage?: CommonTreatmentFragment;
};

const defaultParams = {
  page: 1,
  limit: DEFAULT_LIMIT,
  hasSurvey: true,
  type: [TreatmentType.Treatment],
  sortByOrder: { name: 1 },
};

export const SendTreatments: React.FC<Props> = ({
  methods,
  dataCalendar,
  setDataCalendar,
  setRecurrenceDay,
  recurrenceDay,
  OnlyChecked,
  defaulCheckedProps,
  treatmentHomePage,
}) => {
  const [isOpenDialog, setOpenDialogCustom] = useToogleDialog();

  const { meClient } = useUpdateMeClient();

  const { watch, errors, control, setValue } = methods;

  const watchGroup = watch('group');

  const watchSendingTime = watch('sendingTime');

  const watchSendingDate = watch('sendingDate');

  const [sendBy, setSendBy] = useState('treatment');

  const watchTreatment = watch('treatment');

  const { enqueueSnackbar } = useSnackbar();

  const { dataCalendarProps, CustomTime } = useCustomPopup();

  const defaultParamsTreatment = {
    page: 1,
    type: [TreatmentType.Treatment],
    sortByOrder: { name: 1 },
    limit: DEFAULT_LIMIT,
  };

  const [loadTreatments, { data: dataTreatments }] = useLazyQuery<
    GetTreatmentAddPatientQuery,
    GetTreatmentAddPatientQueryVariables
  >(GET_TREATMENT_ADD_PATIENT, {
    fetchPolicy: 'cache-and-network',
  });

  useEffectOnlyOnce(() => {
    getListTreatments({
      variables: {
        params: defaultParamsTreatment,
      },
    });
  });

  const [
    loadGroupTreatments,
    { data: dataGroup, loading: loadRegimen },
  ] = useLazyQuery<
    GetTreatmentRegimenAddPatientQuery,
    GetTreatmentRegimenAddPatientQueryVariables
  >(GET_TREATMENT_REGIMEN_ADD_PATIENT, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setValue('survey', undefined);
  }, [setValue, watchTreatment]);

  const [
    getListTreatments,
    { loading: loadingListTreatments, data: dataListTreatments },
  ] = useLazyQuery<GetCommonDataQuery, GetCommonDataQueryVariables>(
    GET_COMMON_DATA,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        if (treatmentHomePage && sendBy === 'favorite') {
          const treatment = data?.getTreatments?.nodes?.find(
            item => item?._id === treatmentHomePage?._id,
          );
          setValue('treatment4Assign', { ...treatment });
        }
      },
    },
  );

  const handleTextInputTreatment = (value: string) => {
    if (value) {
      loadTreatments({
        variables: {
          params: {
            name: value,
            ...defaultParams,
          },
        },
      });
    }
  };

  const listSurveyByTreatment = useMemo(() => {
    return watchTreatment?.survey || [];
  }, [watchTreatment]);

  const handleChangeType = (value: string) => {
    if (methods?.errors.sendingDate) {
      enqueueSnackbar('Invalid submission time', { variant: 'error' });
      return;
    }
    setRecurrenceDay(value);
    if (value !== 'custom' && setDataCalendar) {
      setDataCalendar(null);
    }
    if (value === 'custom') {
      setOpenDialogCustom(true);
    }
  };

  const optionFreq = useMemo(() => {
    if (
      (dataCalendar && dataCalendar.freq === 'day') ||
      ['notRepeat', 'daily'].includes(recurrenceDay)
    ) {
      return RRule.DAILY;
    }
    if (
      (dataCalendar && dataCalendar.freq === 'week') ||
      ['weekly', 'allWeek'].includes(recurrenceDay)
    ) {
      return RRule.WEEKLY;
    }
    if (
      (dataCalendar && dataCalendar.freq === 'month') ||
      recurrenceDay === 'monthly'
    ) {
      return RRule.MONTHLY;
    }
    if (recurrenceDay === 'annually') {
      return RRule.YEARLY;
    }
    return RRule.YEARLY;
  }, [dataCalendar, recurrenceDay]);

  const optionByWeekDayForHint = useMemo(() => {
    if (dataCalendar?.freq === 'week') {
      return [optionWeekly(dataCalendar.repeatWeek)];
    }
    if (
      dataCalendar?.freq === 'month' &&
      dataCalendar.repeatMonth === 'monthly'
    ) {
      return [
        getByNWeekDay(optionDay(watchSendingDate || '')).nth(
          optionByNWeekday(watchSendingDate || ''),
        ),
      ];
    }
  }, [dataCalendar, watchSendingDate]);

  const hintText = useMemo(() => {
    if (!dataCalendar) {
      return '';
    }
    let defultRrule = {
      freq: optionFreq,
      interval: dataCalendar?.amount || 1,
      dtstart:
        watchSendingDate && !isNaN(new Date(watchSendingDate).getTime())
          ? new Date(watchSendingDate)
          : new Date(),
    } as ScheduleConfigEmbedded;
    if (
      dataCalendar?.endDate &&
      !isNaN(new Date(dataCalendar?.endDate).getTime()) &&
      !dataCalendar?.endDate.includes('_')
    ) {
      defultRrule.until = new Date(convertDateWithoutUTC(dataCalendar.endDate));
    }
    if (dataCalendar.freq === 'week' || dataCalendar.freq === 'month') {
      defultRrule.byweekday = optionByWeekDayForHint as number[];
    }
    if (dataCalendar?.after) {
      defultRrule.count = dataCalendar.after;
    }
    if (dataCalendar?.before) {
      defultRrule.count = dataCalendar.before;
    }
    return capitalizeFirstLetter(new RRule(defultRrule).toText());
  }, [dataCalendar, optionByWeekDayForHint, optionFreq, watchSendingDate]);

  const handleChangeTypeBy = (event: any, value: string) => {
    setSendBy(value);
    if (['favorite', 'treatment'].includes(value)) {
      getListTreatments({
        variables: {
          params:
            value === 'favorite'
              ? { ...defaultParamsTreatment, favorites: meClient?._id }
              : defaultParamsTreatment,
        },
      });
      return;
    }
    if (value === 'survey') {
      loadTreatments({
        variables: {
          params: {
            ...defaultParams,
          },
        },
      });
      return;
    }
    if (value === 'group') {
      loadGroupTreatments({
        variables: {
          params: { page: 1, limit: DEFAULT_LIMIT },
        },
      });
    }
  };

  const handleTextInputGetListTreatments = (value: string) => {
    const params = {
      ...defaultParamsTreatment,
      name: value,
    };
    if (value) {
      getListTreatments({
        variables: {
          params:
            sendBy === 'favorite'
              ? { ...params, favorites: meClient?._id }
              : params,
        },
      });
    }
  };

  const handleTextInputGetListGroups = (value: string) => {
    if (value) {
      loadGroupTreatments({
        variables: {
          params: {
            search: value,
          },
        },
      });
    }
  };

  return (
    <>
      <CustomTime
        isOpenDialog={isOpenDialog}
        setOpenDialogCustom={setOpenDialogCustom}
        sendOn={watch('sendingDate')}
        setDataCalendar={setDataCalendar}
        dataCalendar={dataCalendar}
        setRecurrenceDay={setRecurrenceDay}
        {...dataCalendarProps}
      />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TypographyBold variant="subtitle1">Send</TypographyBold>
      </Grid>
      <Grid item xs={12} sm={sendBy === 'survey' ? 12 : 6}>
        <TextfieldSelect
          id="by"
          name="by"
          label="By"
          small
          callbackChangeValue={handleChangeTypeBy}
          value={sendBy}
        >
          {['treatment', 'survey', 'favorite', 'group'].map(item => {
            return (
              <MenuItem key={item} value={item}>
                {capitalizeFirstLetter(item)}
              </MenuItem>
            );
          })}
        </TextfieldSelect>
      </Grid>
      {['treatment', 'favorite'].includes(sendBy) && (
        <Grid item xs={6}>
          <AutoCompleteLoadData
            hasOptionsTextNull
            label="Treatment"
            nameOption="name"
            loadList={loadingListTreatments}
            options={dataListTreatments?.getTreatments?.nodes || []}
            small
            defaultValue={null}
            name="treatmentSend"
            control={control}
            callbackHandleText={value =>
              handleTextInputGetListTreatments(value)
            }
          />
        </Grid>
      )}
      {sendBy === 'survey' && (
        <>
          <Grid item xs={12} sm={6}>
            <AutoCompleteLoadData
              hasOptionsTextNull
              label="Treatment"
              nameOption="name"
              options={dataTreatments?.getTreatments?.nodes || []}
              small
              name="treatment"
              control={methods?.control}
              callbackHandleText={value => handleTextInputTreatment!(value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoCompleteSelect
              disablePortal
              small
              name="surveySend"
              label="Survey"
              control={methods?.control}
              nameOption="name"
              options={sortObject(listSurveyByTreatment) || []}
            />
          </Grid>
        </>
      )}
      {sendBy === 'group' && (
        <Grid item xs={12} sm={6}>
          <AutoCompleteLoadData
            hasOptionsTextNull
            label="Group"
            nameOption="name"
            loadList={loadRegimen}
            options={
              dataGroup?.getTreatmentRegimen?.nodes?.map(item => {
                return {
                  ...item,
                  name: item?.regimenName,
                  disabled: item?.forProcedures === true,
                };
              }) || []
            }
            small
            defaultValue={null}
            name="group"
            control={methods!.control}
            methods={methods}
            callbackHandleText={value => handleTextInputGetListGroups(value)}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <KeyboardDateOption
          methods={methods}
          name="sendingDate"
          label="Sending Date"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          as={
            <KeyboardTimePickerStyled
              required
              placeholder="hh:mm"
              inputVariant="outlined"
              label="Sending Time"
              InputLabelProps={{ shrink: true }}
              size="small"
              fullWidth
              value={() => { }}
              onChange={() => { }}
              variant="inline"
              error={!!errors.sendingTime}
              helperText={errors.sendingTime?.message || ''}
              keyboardIcon={<AccessTimeIcon />}
            />
          }
          name="sendingTime"
          control={control}
          rules={{
            validate: {
              pattern: value => {
                return (
                  !isNaN(new Date(value).getTime()) || 'Invalid date format'
                );
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextfieldSelect
          label="Repeat"
          name="repeat"
          small
          callbackChangeValue={e => handleChangeType(e.target.value)}
          value={recurrenceDay}
          disabled={sendBy === 'group'}
        >
          {getOptionTypeRepeat(watchSendingDate || '').map(item => {
            return (
              <MenuItem key={item.value} value={item.value}>
                {item.title}
              </MenuItem>
            );
          })}
        </TextfieldSelect>
      </Grid>
      <Grid item xs={12} sm={6}>
        <OnlyChecked
          value={[{ value: 'remind', label: 'Resend after 48 hours' }]}
          disabled
          {...defaulCheckedProps}
        />
      </Grid>
      {sendBy === 'group' && (
        <Grid item xs={12}>
          <TableListGroup
            loadingGetGroup={false}
            watchSendingDate={watchSendingDate}
            watchSendingTime={watchSendingTime}
            watchGroup={watchGroup}
          />
        </Grid>
      )}
      {dataCalendar && recurrenceDay === 'custom' && (
        <Grid item xs={12}>
          <TypographyItalic color="textSecondary" variant="subtitle2">
            {hintText}
          </TypographyItalic>
        </Grid>
      )}
    </>
  );
};

export default React.memo(SendTreatments);
