import React, { useRef } from 'react';
import {
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  Typography,
} from '@material-ui/core';
import { NoDataTable } from 'components';
import { Gender, UserFragment } from 'types.d';
import { ObjectData, useToogleDialog } from 'hooks';
import { PatientInfo } from './DialogShareAssignTreatment';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DialogAddPatient, {
  FormData,
} from '../../patients/components/DialogAddPatient';
import {
  PaperBorder,
  TableCellMaxWidthStyled,
  TableCellMinWidthStyled,
} from 'share/component_css';
import { formatPhoneNumber, renderCheckedItem } from 'share/utils';

type Props = {
  checkIsCreateNew: ObjectData;
  listEmail: Array<UserFragment & PatientInfo>;
  setListEmail?: (data: Array<UserFragment & PatientInfo>) => void;
  isSendSMS?: boolean;
};

export const TableUserList: React.FC<Props> = ({
  listEmail,
  checkIsCreateNew,
  setListEmail,
  isSendSMS,
}) => {
  const patientInfo = useRef<(UserFragment & PatientInfo) | undefined>(
    undefined,
  );
  const {
    checkItem: checkItemCreate,
    checkedAll: checkedAllCreate,
    checkAll: checkAllCreate,
    checkedList: checkedListCreate,
  } = checkIsCreateNew;

  const [
    isOpenDialogAddPatient,
    toogleOpenDialogAddPatient,
  ] = useToogleDialog();

  const handleAddInfor = (data: FormData, email: string) => {
    if (listEmail && setListEmail) {
      const updatedRows = [...listEmail];
      const index = updatedRows.findIndex(row => row.email === email);
      updatedRows[index].firstName = data.firstName || '';
      updatedRows[index].lastName = data.lastName || '';
      updatedRows[index].middleName = data.middleName || '';
      updatedRows[index].dob = data.dob;
      updatedRows[index].email = email;
      updatedRows[index].gender = data.gender as Gender;
      updatedRows[index].mrn = data.mrn;
      updatedRows[index].provider = data.provider;
      updatedRows[index].emrProvider = data.emrProvider;
      updatedRows[index].address = data.address;
      updatedRows[index].race = data.race;
      setListEmail(updatedRows);
    }
  };

  return (
    <>
      {isOpenDialogAddPatient && (
        <DialogAddPatient
          open={isOpenDialogAddPatient}
          toogleDialog={toogleOpenDialogAddPatient}
          handleAddInfor={handleAddInfor}
          patientInfo={patientInfo.current}
        />
      )}
      <TableContainer component={PaperBorder}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {!isSendSMS ? (
                <TableCellMinWidthStyled minwidth="175px">
                  Email
                </TableCellMinWidthStyled>
              ) : (
                <TableCellMinWidthStyled minwidth="175px">
                  Moblie Number
                </TableCellMinWidthStyled>
              )}

              <TableCell align="left">
                <Checkbox
                  checked={checkedAllCreate}
                  onChange={checkAllCreate}
                  color="primary"
                />
                Create account
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listEmail.length === 0 && <NoDataTable colSpan={5} />}
            {listEmail.length !== 0 &&
              listEmail.map((item, index) => {
                return (
                  <TableRow key={index}>
                    {!isSendSMS ? (
                      <TableCellMaxWidthStyled maxwidth="300px">
                        <Typography variant="subtitle2">
                          {item?.email ? item?.email : '--'}
                        </Typography>
                      </TableCellMaxWidthStyled>
                    ) : (
                      <TableCellMaxWidthStyled maxwidth="300px">
                        <Typography variant="subtitle2">
                          {item?.phones && item?.phones?.length > 0
                            ? formatPhoneNumber(item.phones[0]?.phone)
                            : '--'}
                        </Typography>
                      </TableCellMaxWidthStyled>
                    )}

                    <TableCell align="left">
                      <Checkbox
                        onChange={e =>
                          checkItemCreate(e, renderCheckedItem(item))
                        }
                        color="primary"
                        checked={checkedListCreate.includes(
                          renderCheckedItem(item),
                        )}
                        disabled={!!item?.authenticationInfo}
                      />
                      {checkedListCreate?.includes(renderCheckedItem(item)) && (
                        <Tooltip title="Add information">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              toogleOpenDialogAddPatient(true);
                              patientInfo.current = item;
                            }}
                          >
                            <PersonAddIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default TableUserList;
