import { configMenu } from 'routers/config_menu';
import { routes } from 'routers/config';
import { useLocation } from 'react-router-dom';

export const useHandlePathExists = () => {
  const location = useLocation();
  const currentPath = location.pathname.split('/')[1];

  const existsConfigMenu = configMenu.some(
    item => item.url.split('/')[1] === currentPath,
  );
  const existRoutes = routes.some(
    item => item.path?.split('/')[1] === currentPath,
  );

  return existsConfigMenu || existRoutes;
};
