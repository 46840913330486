import React, {
  useEffect,
  useCallback,
  useRef,
  useContext,
  useState,
} from 'react';
import { styled, useTheme } from '@material-ui/core/styles';
import {
  Typography,
  AppBar,
  Toolbar,
  CssBaseline,
  IconButton,
  ListItemText,
  ListItemIcon,
  Menu,
  Avatar,
  Box,
  useMediaQuery,
  Grid,
} from '@material-ui/core';
import { Cog } from 'mdi-material-ui';
import SignOutIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import {
  TREATMENT_ASSIGN_SYNC,
  MENU_STATUS,
  TOOGLE_MENU_STATUS,
  ME_CLIENT,
  LOG_OUT,
  ADD_SUB_TREATMENTS_SIDE_BAR,
  TOOGLE_BANNER_STATUS,
  ADD_SHOWING_TREATMENT_SIDEBAR,
  BANNER_STATUS,
  TOOGLE_SHOW_SUB_SIDE_BAR,
  TOOGLE_SHOW_COMMENT_FROM,
  HANDLE_REPRESENTATIVE_INFO,
  LOGIN_OTHER_DEVICE,
  TOOGLE_STATUS_HASING_RECORD,
  SHOW_SAVE_RECORDING,
} from 'gql';
import { useMutation, useQuery, useSubscription } from '@apollo/react-hooks';
import {
  useUpdateMeClient,
  useCheckRole,
  useCustomPermission,
  useToogleDialog,
  useMutationCustom,
  useCheckLogin,
} from 'hooks';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ModalLogin,
  BreadCrumbComponent,
  ModalSyncTreatment,
  GpsIcon,
  DialogNoTreatmentDefault,
  IconSearchAndProfile,
  DeleteConfirm,
} from 'components';
import {
  getSessionStorage,
  getLocalAssignTreatment,
  checkHasSessionStorage,
  getLocalStorage,
  removeLocalAssignTreatment,
  isBotMail,
  removeLocalStorage,
  removeSessionStorage,
} from 'share/utils';
import grey from '@material-ui/core/colors/grey';
import {
  CONTEXT_TREATMENTS,
  KEY_LOCAL_ASSIGNED_SURVEYS,
  KEY_LOCAL_SKIP_BANNER,
  KEY_OPEN_SYNC_TREATMENT,
  KEY_SESSION_ORGANIZATION_ID,
  ORG_LOGO,
} from 'CONST';
import DrawerComponent from './components/DrawerComponent';
import {
  LinkRouterStyled,
  globalClass,
  TitleTypographyStyled,
  MenuItemStyled,
} from 'share/component_css';
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { Link, Collapse, CollapseProps } from '@material-ui/core';
import {
  AddShowingTreatmentSidebarMutation,
  AddShowingTreatmentSidebarMutationVariables,
  ToogleShowSubTreatmentsSideBarMutation,
  ToogleShowSubTreatmentsSideBarMutationVariables,
  ToogleShowCommentFromMutation,
  ToogleShowCommentFromMutationVariables,
  TreatmentAssignSyncMutation,
  TreatmentAssignSyncMutationVariables,
  HandleRepresentativeInfoMutation,
  HandleRepresentativeInfoMutationVariables,
  ResponseRemoteLoginFragment,
  LoginOtherDeviceSubscription,
  LoginOtherDeviceSubscriptionVariables,
  ToogleStatusHasingRecordMutationVariables,
  ToogleStatusHasingRecordMutation,
  StatusRecordStop,
  ShowSaveRecordingQuery,
  ShowSaveRecordingQueryVariables,
} from 'types.d';
import {
  SubcriptionClientContext,
  LayoutContext,
  ChatbotDrawerContext,
} from 'share/context';
import { ButtonSizeIcon } from 'share/component_css';
import WarningIcon from '@material-ui/icons/Warning';
import { Facebook, LinkedIn, Twitter } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import './index.css';
import { useUpdateHomepageClient } from 'hooks/useUpdateHomepage';
import ChatbotDrawer from './components/ChatbotDrawer';
import Searchbar from 'components/Searchbar';

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: theme.palette.common.white,
}));

const AvatarMenuStyled = styled(Avatar)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.dark,
  textTransform: 'uppercase',
}));

const MenuStyled = styled(Menu)(({ theme }) => ({
  '& .MuiList-padding': {
    padding: 0,
  },
}));

const BoxContentStyled = styled(Box)(({ theme }) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: theme.spacing(2),
  // '@media (min-width: 1440px)': {
  //   maxWidth: theme.spacing(180),
  // },
  paddingLeft: 0,
  paddingRight: 0,
}));

const MainContentBoxStyled = styled(Box)(({ theme }) => ({
  paddingLeft: '16px',
  paddingRight: '16px',
  marginLeft: 'auto',
  marginRight: 'auto',
  '@media (min-width: 1440px)': {
    maxWidth: theme.spacing(180),
  },
}));

// SearchIconButton
export const CollapseStyled = styled(({ ...other }: CollapseProps) => (
  <Collapse {...other} />
))({
  '@media screen and (min-width:500px)': {
    height: '64px !important',
  },
  height: '108px !important',
  '& .MuiCollapse-wrapper': {
    height: '100%',
    '& .MuiCollapse-wrapperInner': {
      height: '100%',
    },
  },
});

const AlertStyled = styled(MuiAlert)(({ theme }) => ({
  position: 'relative',
  zIndex: 1205,
  width: '100%',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  '& .MuiAlert-action ': {
    marginLeft: '1px',
  },
  '& .MuiAlert-message': {
    padding: '8px 0',
    display: 'inline',
  },
  alignItems: 'center',
}));

const CloseAlertIconButton = styled(IconButton)({
  marginLeft: 'none',
});

const LinkStyled = styled(Link)({
  cursor: 'pointer',
});

const IconButtonStyled = styled(IconButton)({
  color: grey[500],
});

const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  backgroundColor: grey[50],
}));

const FooterBoxStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  marginTop: '100px',
  marginBottom: '-20px',
  backgroundColor: grey[50],
  borderTop: '1px solid rgb(218, 220, 224)',
}));

const FooterGridStyled = styled(Grid)(({ theme }) => ({
  margin: '0 auto',
  '@media (min-width: 1440px)': {
    maxWidth: theme.spacing(180),
  },
}));

const Layout: React.FC = ({ children }) => {
  const theme = useTheme();

  // check redirect user to home page
  const isRedirectingToHome = useRef(true);

  const targetRef = useRef<HTMLDivElement>(null);

  const isLogin = useCheckLogin();

  const [isOpenLogin, toogleDialogLogin] = useToogleDialog();

  const [isOpenConfirmLogout, toogleDialogConfirmLogout] = useToogleDialog();

  const [firstName, setFirstName] = useState('');

  const [openNoTreatmentDefault, setOpenNoTreatmentDefault] = useToogleDialog();

  const { isUser, isAdmin, isPatient, isSuperAdmin } = useCustomPermission();

  const { pathname } = useLocation();

  const [addTreatmentSideBarShow] = useMutation<
    AddShowingTreatmentSidebarMutation,
    AddShowingTreatmentSidebarMutationVariables
  >(ADD_SHOWING_TREATMENT_SIDEBAR);

  const [toogleShowCommentFrom] = useMutation<
    ToogleShowCommentFromMutation,
    ToogleShowCommentFromMutationVariables
  >(TOOGLE_SHOW_COMMENT_FROM);

  const [toogleShowSubSideBar] = useMutation<
    ToogleShowSubTreatmentsSideBarMutation,
    ToogleShowSubTreatmentsSideBarMutationVariables
  >(TOOGLE_SHOW_SUB_SIDE_BAR);

  const [toogleStatusHasingRecord] = useMutation<
    ToogleStatusHasingRecordMutation,
    ToogleStatusHasingRecordMutationVariables
  >(TOOGLE_STATUS_HASING_RECORD);

  const { data: dataShowSaveRecord } = useQuery<
    ShowSaveRecordingQuery,
    ShowSaveRecordingQueryVariables
  >(SHOW_SAVE_RECORDING);

  const isOpenSignup = useRef(false);

  const [isOpenSyncTreatment, toogleSyncTreatment] = useToogleDialog();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isToogleMenu = useMediaQuery(theme.breakpoints.down('md'));

  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [chatbotDrawer, setChatbotDrawer] = useState(
    window.location.pathname.includes('/treatment/') ? true : false,
  );

  const [quickViewCards, setQuickViewCards] = useState([]);

  const [openedTreatment, setOpenedTreatment] = useState({
    _id: '',
    slug: '',
    markdown: '',
  });

  const chatbotDrawerContext = useContext(ChatbotDrawerContext);

  const [logout, { loading }] = useMutation(LOG_OUT, {
    onCompleted(data) {
      chatbotDrawerContext?.setMessages([]);
      removeLocalStorage('remoteUser');
      removeLocalStorage(KEY_LOCAL_ASSIGNED_SURVEYS);
      removeLocalStorage(ORG_LOGO);
      removeSessionStorage(KEY_SESSION_ORGANIZATION_ID);
      removeLocalStorage(CONTEXT_TREATMENTS);
      chatbotDrawerContext?.setMessages([
        {
          text:
            "Welcome to TGPS Assistant, your go-to guide for instant answers! 🌟 I'm here to help. Dive in and ask away",
          type: 'user',
        },
      ]);
      history.push('/');
      //close connect ws
      if (subcriptionClientContext?.subcriptionClient) {
        subcriptionClientContext?.subcriptionClient.unsubscribeAll();
        subcriptionClientContext?.subcriptionClient.close();
      }
      //close list comment
      toogleShowCommentFrom({
        variables: { input: false },
      });
      //close list assign treatment on sidebar
      toogleShowSubSideBar({
        variables: {
          input: { status: false },
        },
      });
      //remove list treatment show on sidebar
      addTreatmentSideBarShow({
        variables: { input: null },
      });
      //update me is anonymous
      handleUpdateMeClient(null);
      // remove assign treatment on sidebar
      addSubTreatmentsSideBar({
        variables: {
          subTreatments: null,
        },
      });
    },
  });

  const { handleUpdateMeClient } = useUpdateMeClient();

  const { homepageClient } = useUpdateHomepageClient();

  const { checkLogin } = useCheckRole();

  const { data: dataMenuStatus } = useQuery(MENU_STATUS);

  const [toogleMenuStatus] = useMutation(TOOGLE_MENU_STATUS);

  const { data: dataBannerStatus } = useQuery(BANNER_STATUS);

  const [toogleBannerStatus] = useMutation(TOOGLE_BANNER_STATUS);

  const { data: dataMeClient } = useQuery(ME_CLIENT);

  const subcriptionClientContext = useContext(SubcriptionClientContext);

  const [addSubTreatmentsSideBar] = useMutationCustom({
    api: ADD_SUB_TREATMENTS_SIDE_BAR,
  });

  const [handleRepresentativeInfo] = useMutationCustom<
    HandleRepresentativeInfoMutation,
    HandleRepresentativeInfoMutationVariables
  >({
    api: HANDLE_REPRESENTATIVE_INFO,
    callbackSuccess: data => {
      history.push('/');
      window.location.reload();
    },
  });

  useSubscription<
    LoginOtherDeviceSubscription,
    LoginOtherDeviceSubscriptionVariables
  >(LOGIN_OTHER_DEVICE, {
    skip: !isLogin,
    onSubscriptionData: data => {
      toogleDialogConfirmLogout(true);
    },
  });

  const cancelRemote = () => {
    handleRepresentativeInfo({
      variables: { params: {} },
    });
  };

  const [treatmentAssignSync] = useMutationCustom<
    TreatmentAssignSyncMutation,
    TreatmentAssignSyncMutationVariables
  >({
    api: TREATMENT_ASSIGN_SYNC,

    callbackSuccess: () => {
      removeLocalAssignTreatment();
    },
    callbackError: () => {
      removeLocalAssignTreatment();
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToogle = useCallback(() => {
    if (chatbotDrawer) {
      setChatbotDrawer(false);
    }

    toogleMenuStatus({
      variables: {
        status: !dataMenuStatus.menuStatus,
      },
    });
  }, [chatbotDrawer, dataMenuStatus.menuStatus, toogleMenuStatus]);

  const doLogout = () => {
    handleClose();
    //if there is a current record, stop logingout and opening popup to confirm whether user want to stop record or not
    if (dataShowSaveRecord?.showSaveRecording) {
      toogleStatusHasingRecord({
        variables: {
          input: {
            status: StatusRecordStop.Logout,
          },
        },
      });
      return;
    }
    setTimeout(() => {
      logout();
    }, 1000);
  };

  const openModalLogin = () => {
    isOpenSignup.current = false;
    handleClose();
    toogleDialogLogin(true);
  };
  const handleOpenSignUp = () => {
    isOpenSignup.current = true;
    toogleDialogLogin(true);
  };

  const handleSkip = () => {
    toogleBannerStatus({
      variables: { status: false },
    });
  };

  const handleOpenProfile = () => {
    handleClose();
    history.push('/profile/');
  };

  const handleOpenConfig = () => {
    handleClose();
    history.push('/common-configs/');
  };

  useEffect(() => {
    //sync treatment from local storage
    if (getLocalAssignTreatment().length !== 0 && dataMeClient.meClient.email) {
      //logged user is patient
      if (isPatient) {
        const treatmentAutoSync = getLocalAssignTreatment().filter(
          item => item.organization === dataMeClient.meClient.organizationId,
        );
        //sync treatments are belongs to organization that user is in automatically
        if (treatmentAutoSync.length !== 0) {
          treatmentAssignSync({
            variables: {
              params: treatmentAutoSync.map(item => {
                return {
                  _id: item._id,
                  token: item.token,
                };
              }),
            },
          });
          return;
        }
        //remove all treatments in local storage that is not belongs to organization of current patient
        removeLocalAssignTreatment();
        return;
      }
      //logged user is normal user
      if (isUser) {
        const treatmentAutoSync = Array.from(
          new Set(
            getLocalAssignTreatment().map((item: any) => item.organization),
          ),
        );
        //all treatments in local storage are belongs to only one organization. sync it to current user
        if (treatmentAutoSync.length === 1) {
          treatmentAssignSync({
            variables: {
              params: getLocalAssignTreatment().map(item => {
                return {
                  _id: item._id,
                  token: item.token,
                };
              }),
            },
          });
          return;
        }
        //all treatments in local storage are belongs to few organizations. open popup to support current user select treatments
        if (
          !getLocalStorage(KEY_OPEN_SYNC_TREATMENT) &&
          treatmentAutoSync.length !== 0
        ) {
          toogleSyncTreatment(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMeClient.meClient.email, isUser, checkLogin, toogleSyncTreatment]);

  useEffect(() => {
    const isDisplayBanner =
      !checkLogin &&
      ((checkHasSessionStorage(KEY_LOCAL_SKIP_BANNER) &&
        getSessionStorage(KEY_LOCAL_SKIP_BANNER) === 'true') ||
        !checkHasSessionStorage(KEY_LOCAL_SKIP_BANNER));
    toogleBannerStatus({
      variables: {
        status: isDisplayBanner,
      },
    });
  }, [checkLogin, toogleBannerStatus]);

  useEffect(() => {
    if (
      dataMeClient.meClient.email &&
      !dataMeClient.meClient.hasDefaultTreatment &&
      pathname !== '/treatment-designer/' &&
      isAdmin
    ) {
      setOpenNoTreatmentDefault(true);
      return;
    }
    setOpenNoTreatmentDefault(false);
  }, [dataMeClient.meClient, isAdmin, pathname, setOpenNoTreatmentDefault]);

  const setIsRedirectingToHome = () => {
    isRedirectingToHome.current = false;
  };

  globalClass();

  const remoteUser = JSON.parse(getLocalStorage('remoteUser')!);

  const scrollToElement = (inputString: string) => {
    if (targetRef.current) {
      let elementFound = false;

      const children = targetRef.current.childNodes;

      for (let i = 0; i < children.length; i++) {
        const element = children[i];

        if (element.nodeType === Node.ELEMENT_NODE) {
          const htmlElement = element as HTMLElement;

          const originalClass = htmlElement.getAttribute('class');

          htmlElement.removeAttribute('class');

          const outerHTMLWithoutClass = htmlElement.outerHTML;

          if (originalClass) htmlElement.setAttribute('class', originalClass);

          if (inputString.includes(outerHTMLWithoutClass)) {
            htmlElement.classList.add('highlight');

            if (!elementFound)
              htmlElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });

            elementFound = true;
          } else {
            htmlElement.classList.remove('highlight');
          }
        }
      }
    }
  };

  const handleOpenedTreatment = (
    _id: string,
    slug: string,
    markdown: string,
  ) => {
    setOpenedTreatment({
      _id,
      slug,
      markdown,
    });
  };

  return (
    <LayoutContext.Provider
      value={{
        firstName,
        setFirstName,
        toogleDialogLogin,
        setIsRedirectingToHome,
        isRedirectingToHome: isRedirectingToHome.current,
        handleClick,
        chatbotDrawer,
        quickViewCards,
        setQuickViewCards,
        targetRef,
        scrollToElement,
        openedTreatment,
        handleOpenedTreatment,
      }}
    >
      <DeleteConfirm
        loading={loading}
        handleConfirm={() => logout()}
        open={isOpenConfirmLogout}
        toogle={toogleDialogConfirmLogout}
        icon={<WarningIcon />}
        action="warning"
        buttonText="Logout"
        title="Account has been logged in on another device"
        content="This account will be signed out of your device because it has just been logged in from another device."
      />
      {isOpenLogin && (
        <ModalLogin
          activeTab={isOpenSignup.current ? 'register' : 'login'}
          handleCloseModal={toogleDialogLogin}
          open={isOpenLogin}
        />
      )}
      <DialogNoTreatmentDefault open={openNoTreatmentDefault} />
      {isOpenSyncTreatment && (
        <ModalSyncTreatment
          handleCloseModal={toogleSyncTreatment}
          open={isOpenSyncTreatment}
        />
      )}
      <CssBaseline />
      <Box display="flex" width="100%">
        <AppBarStyled>
          {!checkLogin && dataBannerStatus.bannerStatus && (
            <CollapseStyled in={dataBannerStatus.bannerStatus}>
              <AlertStyled
                severity="info"
                action={
                  <CloseAlertIconButton
                    color="inherit"
                    size="small"
                    onClick={() => {
                      toogleBannerStatus({
                        variables: {
                          status: !dataBannerStatus.bannerStatus,
                        },
                      });
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </CloseAlertIconButton>
                }
              >
                {Object.is(window.location.pathname, '/survey/') ? (
                  <>
                    Hi
                    {firstName ? ` ${firstName}` : ''}, let's start with your
                    health check in. You can{' '}
                    <LinkStyled onClick={openModalLogin}>
                      <strong> login </strong>
                    </LinkStyled>{' '}
                    to see your full account or you can{' '}
                    <LinkStyled onClick={handleSkip}>
                      <strong> skip </strong>
                    </LinkStyled>{' '}
                    login.
                  </>
                ) : (
                  <>
                    You are currently not logged in. To ensure your work is
                    saved, please{' '}
                    <LinkStyled onClick={openModalLogin}>
                      <strong> sign in </strong>
                    </LinkStyled>
                    or
                    <LinkStyled onClick={handleOpenSignUp}>
                      <strong> sign up</strong>
                    </LinkStyled>
                    . You can{' '}
                    <LinkStyled onClick={handleSkip}>
                      <strong> skip </strong>
                    </LinkStyled>
                    to continue your work.
                  </>
                )}
              </AlertStyled>
            </CollapseStyled>
          )}
          {remoteUser && (
            <CollapseStyled in={!!remoteUser}>
              <AlertStyled severity="info">
                <>
                  You are using{' '}
                  {(remoteUser as ResponseRemoteLoginFragment)?.firstName || ''}{' '}
                  {(remoteUser as ResponseRemoteLoginFragment)?.middleName ||
                    ''}{' '}
                  {(remoteUser as ResponseRemoteLoginFragment)?.lastName || ''}
                  's account to interact with{' '}
                  {(remoteUser as ResponseRemoteLoginFragment)?.organization
                    ?.name || ''}
                  , click
                  <LinkStyled onClick={cancelRemote}>
                    <strong> exit </strong>
                  </LinkStyled>{' '}
                  to back to super admin's screen{' '}
                </>
              </AlertStyled>
            </CollapseStyled>
          )}
          <ToolbarStyled>
            <IconButtonStyled
              aria-label="Open drawer"
              onClick={handleDrawerToogle}
              edge="start"
            >
              <MenuIcon />
            </IconButtonStyled>
            <LinkRouterStyled to="/home/">
              <Box display="flex" justifyContent="center" alignItems="center">
                <GpsIcon hasMargin />
                <TitleTypographyStyled color="primary" variant="subtitle1">
                  TreatmentGPS
                </TitleTypographyStyled>
              </Box>
            </LinkRouterStyled>

            {!isMobile && <Searchbar />}

            <Box display="flex" marginLeft="auto" alignItems="center">
              <IconSearchAndProfile homepageData={homepageClient} />

              {!checkLogin && (
                <ButtonSizeIcon
                  variant="outlined"
                  color="primary"
                  onClick={openModalLogin}
                >
                  Login
                </ButtonSizeIcon>
              )}
            </Box>

            <MenuStyled
              id="simple-MenuStyled"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  marginTop: 4,
                },
              }}
            >
              {checkLogin && (
                <Box p={2} display="flex" alignItems="center">
                  <AvatarMenuStyled>{`${dataMeClient.meClient.firstName[0]}${
                    dataMeClient.meClient.lastName
                      ? dataMeClient.meClient.lastName[0]
                      : ''
                  }`}</AvatarMenuStyled>
                  <Box ml={2} minWidth={170}>
                    <Typography variant="subtitle1" color="primary">{`${
                      dataMeClient.meClient.firstName
                    } ${dataMeClient.meClient.middleName || ''} ${dataMeClient
                      .meClient.lastName || ''}`}</Typography>
                    <Typography color="textSecondary" variant="subtitle2">
                      {isBotMail(dataMeClient.meClient.email)
                        ? ''
                        : dataMeClient.meClient.email}
                    </Typography>
                  </Box>
                </Box>
              )}

              {checkLogin && (
                <MenuItemStyled onClick={handleOpenProfile}>
                  <ListItemIcon>
                    <PersonIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Profile" />
                </MenuItemStyled>
              )}

              {checkLogin && !isUser && !isSuperAdmin && (
                <MenuItemStyled onClick={handleOpenConfig}>
                  <ListItemIcon>
                    <Cog fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Common configs" />
                </MenuItemStyled>
              )}
              {checkLogin && (
                <MenuItemStyled onClick={doLogout}>
                  <ListItemIcon>
                    <SignOutIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </MenuItemStyled>
              )}
            </MenuStyled>
          </ToolbarStyled>
          <div id="back-to-top-anchor" />
        </AppBarStyled>
        <DrawerComponent handleDrawerToogle={handleDrawerToogle} />
        <BoxContentStyled
          width={
            isToogleMenu
              ? '100%'
              : dataMenuStatus.menuStatus
              ? 'calc(100% - 300px)'
              : '96%'
          }
          height="100%"
          pt={
            dataBannerStatus.bannerStatus ||
            JSON.parse(getLocalStorage('remoteUser')!)
              ? 20
              : 10
          }
          pr={isMobile ? 0.5 : 2}
          pl={isMobile ? 0.5 : 2}
        >
          {!Object.is(window.location.pathname, '/survey/') &&
            !Object.is(window.location.pathname, '/home/') && (
              <MainContentBoxStyled>
                <BreadCrumbComponent />
              </MainContentBoxStyled>
            )}
          {isMobile && (
            <Box p={2}>
              <Searchbar />
            </Box>
          )}
          <MainContentBoxStyled minHeight={window.innerHeight}>
            {children}
          </MainContentBoxStyled>
          <FooterBoxStyled>
            <FooterGridStyled container spacing={4}>
              <Grid item xs={12} sm={5} md={6}>
                <Grid container spacing={1}>
                  {homepageClient?.footerConfigurations.map(
                    (treatment: any, index: number) => (
                      <Grid key={index} item xs={12} md={6}>
                        {treatment?.children &&
                        treatment?.children.length > 0 &&
                        treatment.children[0].title !== treatment.title ? (
                          <LinkRouterStyled
                            to={
                              treatment?.children &&
                              treatment?.children.length > 0
                                ? `/treatment/${treatment.children[0].treatmentRef.slug}`
                                : '#'
                            }
                          >
                            <Typography variant="button">
                              {treatment.title}
                            </Typography>
                          </LinkRouterStyled>
                        ) : (
                          <LinkRouterStyled
                            to={
                              treatment?.children &&
                              treatment?.children.length > 0
                                ? `/treatment/${treatment.children[0].treatmentRef.slug}`
                                : '#'
                            }
                          >
                            <Typography variant="button">
                              {treatment.title}
                            </Typography>
                          </LinkRouterStyled>
                        )}
                        <Box display={'flex'} flexDirection={'column'}>
                          {treatment?.children &&
                            treatment?.children.length > 0 &&
                            treatment.children[0].treatmentRef.name !==
                              treatment.title &&
                            treatment?.children.map(
                              (item: any, index: number) => (
                                <LinkRouterStyled
                                  key={index}
                                  to={`/treatment/${item.treatmentRef.slug}`}
                                >
                                  <Typography variant="caption">
                                    {item.treatmentRef.name}
                                  </Typography>
                                </LinkRouterStyled>
                              ),
                            )}
                        </Box>
                      </Grid>
                    ),
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={homepageClient?.footerConfigurations?.length > 0 ? 7 : 12}
                md={homepageClient?.footerConfigurations?.length > 0 ? 6 : 12}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={
                      homepageClient?.footerConfigurations?.length > 0 ? 12 : 6
                    }
                    sm={
                      homepageClient?.footerConfigurations?.length > 0 ? 6 : 4
                    }
                    md={
                      homepageClient?.footerConfigurations?.length > 0 ? 6 : 4
                    }
                    lg={4}
                  >
                    <Typography variant="button">Follow Us</Typography>
                    <Box display={'flex'}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/treatment-gps"
                      >
                        <LinkedIn
                          style={{
                            fontSize: '30px',
                            fill: '#0077b5',
                            marginRight: '5px',
                          }}
                        />
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/people/Treatment-GPS/61551357161208/"
                      >
                        <Facebook
                          style={{
                            fontSize: '30px',
                            fill: '#1877F2',
                            marginRight: '5px',
                          }}
                        />
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://twitter.com/AbdullahKTGPS"
                      >
                        <Twitter
                          style={{
                            fontSize: '30px',
                            fill: '#1DA1F2',
                            marginRight: '5px',
                          }}
                        />
                      </a>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={
                      homepageClient?.footerConfigurations?.length > 0 ? 12 : 6
                    }
                    sm={
                      homepageClient?.footerConfigurations?.length > 0 ? 6 : 4
                    }
                    md={
                      homepageClient?.footerConfigurations?.length > 0 ? 6 : 4
                    }
                    lg={4}
                  >
                    <Typography variant="button">Contact Us</Typography>
                    <Box>
                      <Typography variant="caption">
                        info@treatmentgps.com
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption">
                        sales@treatmentgps.com
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption">+1 541-321-8377</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={
                      homepageClient?.footerConfigurations?.length > 0 ? 12 : 6
                    }
                    sm={
                      homepageClient?.footerConfigurations?.length > 0 ? 6 : 4
                    }
                    md={
                      homepageClient?.footerConfigurations?.length > 0 ? 6 : 4
                    }
                    lg={4}
                  >
                    <Typography variant="button">Treatment GPS</Typography>
                    <Box>
                      <Typography variant="caption">
                        2400 Hartman Lane, Springfield, OR 97477, STE 200
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </FooterGridStyled>
          </FooterBoxStyled>
        </BoxContentStyled>
        {
          <ChatbotDrawer
            chatbotDrawer={chatbotDrawer}
            setChatbotDrawer={setChatbotDrawer}
          />
        }
      </Box>
    </LayoutContext.Provider>
  );
};

export default Layout;
