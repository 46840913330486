import React, { useContext, useState } from 'react';
import {
  IconButton,
  Avatar,
  Button,
  Typography,
  Box,
  List,
  Drawer,
  Grid,
  ListItem,
  Collapse,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import SearchIcon from '@material-ui/icons/Search';
import { BANNER_STATUS, ME_CLIENT } from 'gql';
import {
  LinkRouterStyled,
  MenuItemStyled,
  WrapSearchTreatment,
} from 'share/component_css';
import { useCheckRole, useCustomPermission } from 'hooks';
import { NotificationDropdown } from 'components';
import { styled, useTheme } from '@material-ui/core/styles';
import { LayoutContext } from 'share/context';
import { getLocalStorage, renderMedia } from 'share/utils';
import { ViewAgendaOutlined } from '@material-ui/icons';
import NavigatorNotificationDropdown from './NavigatorNotificationDropdown';

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.dark,
  textTransform: 'uppercase',
  cursor: 'pointer',
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    padding: theme.spacing(1),
  },
  '&.MuiButton-contained': {
    boxShadow: 'none',
    height: '50px',
    borderRadius: '4px',
  },
}));

const HeaderButtonStyled = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'rgb(0 0 0 / 15%)',
    textDecoration: 'none',
  },
}));

const HeaderDrawerStyled = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '65%',
  },
}));

export const HeaderButtonIconStyled = styled(IconButton)({
  '& .MuiSvgIcon-root': {
    fontSize: '1.5rem',
    marginRight: '5px',
  },
});

type Props = {
  open?: boolean;
  setOpen?: (status: boolean) => void;
  isHomePage?: boolean;
  homepageData: any;
};

export const IconSearchAndProfile: React.FC<Props> = ({
  open,
  setOpen,
  isHomePage,
  homepageData,
}) => {
  const theme = useTheme();

  const { data: dataBannerStatus } = useQuery(BANNER_STATUS);

  const { data } = useQuery(ME_CLIENT);

  const { checkLogin } = useCheckRole();

  const { isUser, isNavigator } = useCustomPermission();

  const layoutContext = useContext(LayoutContext);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [menuDrawerToggle, setMenuDrawerToggle] = useState(false);

  const [menuOpenIndex, setMenuOpenIndex] = useState(null);

  const [displaySubmenu, setDisplaySubmenu] = useState(false);

  const handleMenuDrawerToggle = () => {
    setMenuDrawerToggle(!menuDrawerToggle);
  };

  return (
    <WrapSearchTreatment
      hasbanner={
        dataBannerStatus.bannerStatus ||
        JSON.parse(getLocalStorage('remoteUser')!)
          ? 'true'
          : 'false'
      }
      isLogin={data.meClient?._id ? 'true' : 'false'}
    >
      {!isMobile ? (
        <Box display={'flex'} marginRight={'auto'} alignItems={'end'}>
          <ul className="menu">
            {homepageData &&
              homepageData?.buttonConfigurations &&
              homepageData?.buttonConfigurations.map(
                (button: any, index: any) => (
                  <List
                    key={index}
                    className={displaySubmenu ? 'menu-item' : ''}
                    onMouseEnter={() => setDisplaySubmenu(true)}
                    onMouseLeave={() => setDisplaySubmenu(false)}
                  >
                    <LinkRouterStyled
                      style={{ margin: 0, padding: 0 }}
                      to={`/section/${index}`}
                      onClick={() => setDisplaySubmenu(false)}
                    >
                      <HeaderButtonStyled>{button.title}</HeaderButtonStyled>
                    </LinkRouterStyled>
                    <div
                      className="submenu"
                      style={{
                        marginTop:
                          !checkLogin && dataBannerStatus.bannerStatus
                            ? '118px'
                            : '55px',
                      }}
                    >
                      <div
                        style={{
                          width: '80%',
                          color: 'black',
                          margin: '20px auto',
                        }}
                      >
                        <Grid container spacing={4}>
                          {button.children.map(
                            (subsection: any, index: number) => (
                              <Grid key={index} item xs={12} sm={6} md={3}>
                                <LinkRouterStyled
                                  to={
                                    subsection?.treatmentRef?.slug
                                      ? `/treatment/${subsection?.treatmentRef?.slug}`
                                      : '#'
                                  }
                                  style={{ padding: '0px' }}
                                >
                                  <Typography
                                    variant="h6"
                                    className="treatment-name"
                                  >
                                    {subsection?.treatments &&
                                    subsection?.treatments?.length > 0
                                      ? subsection?.title
                                      : subsection?.treatmentRef.name}
                                  </Typography>
                                </LinkRouterStyled>
                                <Typography
                                  variant="caption"
                                  display="block"
                                  gutterBottom
                                >
                                  {subsection?.treatmentRef?.shortDescription
                                    .length > 100 ? (
                                    <>
                                      {subsection?.treatmentRef?.shortDescription.substring(
                                        0,
                                        100,
                                      )}
                                      ...
                                    </>
                                  ) : (
                                    subsection?.treatmentRef?.shortDescription
                                  )}
                                </Typography>
                                {!(
                                  subsection?.treatments &&
                                  subsection?.treatments?.length > 0
                                ) && (
                                  <img
                                    height={100}
                                    src={renderMedia(
                                      `${subsection?.treatmentRef?.mainImage}`,
                                    )}
                                  />
                                )}
                                {subsection?.treatments && (
                                  <List>
                                    {subsection.treatments.map(
                                      (treatment: any, index: number) => (
                                        <ListItem
                                          key={index}
                                          disableGutters
                                          style={{
                                            padding: '0px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            margin: '5px 0px',
                                          }}
                                        >
                                          <LinkRouterStyled
                                            style={{ padding: '0px' }}
                                            to={`/treatment/${treatment.slug}`}
                                          >
                                            <Typography
                                              variant="caption"
                                              style={{ fontWeight: 500 }}
                                              className="treatment-name"
                                            >
                                              {treatment?.name}
                                            </Typography>
                                          </LinkRouterStyled>
                                          <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                          >
                                            {treatment?.shortDescription &&
                                            treatment?.shortDescription.length >
                                              100 ? (
                                              <>
                                                {treatment?.shortDescription.substring(
                                                  0,
                                                  100,
                                                )}
                                                ...
                                              </>
                                            ) : (
                                              treatment?.shortDescription
                                            )}
                                          </Typography>
                                        </ListItem>
                                      ),
                                    )}
                                  </List>
                                )}
                              </Grid>
                            ),
                          )}
                        </Grid>
                      </div>
                    </div>
                  </List>
                ),
              )}
          </ul>
        </Box>
      ) : (
        <>
          <HeaderButtonIconStyled
            color="primary"
            onClick={handleMenuDrawerToggle}
            edge="end"
          >
            <ViewAgendaOutlined />
          </HeaderButtonIconStyled>
          <HeaderDrawerStyled
            anchor="right"
            open={menuDrawerToggle}
            onClose={handleMenuDrawerToggle}
          >
            <List style={{ listStyle: 'none' }}>
              {homepageData &&
                homepageData?.buttonConfigurations.map(
                  (button: any, index: any) => (
                    <>
                      <MenuItemStyled onClick={() => setMenuOpenIndex(index)}>
                        {button.title}
                      </MenuItemStyled>
                      {button.children &&
                        button.children.map((item: any) => (
                          <Collapse
                            in={menuOpenIndex === index}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List
                              style={{ marginLeft: '20px' }}
                              component="div"
                              disablePadding
                            >
                              <LinkRouterStyled
                                to={`/treatment/${
                                  item.title != ''
                                    ? '#'
                                    : item?.treatmentRef?.slug
                                }`}
                              >
                                <ListItem>
                                  <ListItemText
                                    primary={
                                      item.title != ''
                                        ? item.title
                                        : item?.treatmentRef?.name
                                    }
                                  />
                                </ListItem>
                              </LinkRouterStyled>
                            </List>
                            {item.treatments &&
                              item.treatments.map((subtreatment: any) => (
                                <Collapse
                                  in={true}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List
                                    style={{ marginLeft: '40px' }}
                                    component="div"
                                    disablePadding
                                  >
                                    <LinkRouterStyled
                                      to={`/treatment/${subtreatment.slug}`}
                                    >
                                      <ListItem>
                                        <ListItemText
                                          primary={subtreatment.name}
                                        />
                                      </ListItem>
                                    </LinkRouterStyled>
                                  </List>
                                </Collapse>
                              ))}
                          </Collapse>
                        ))}
                    </>
                  ),
                )}
            </List>
          </HeaderDrawerStyled>
        </>
      )}

      {isHomePage && (
        <IconButton
          aria-label=""
          onClick={() => setOpen!(!open)}
          color="primary"
        >
          <SearchIcon fontSize="large" />
        </IconButton>
      )}
      {checkLogin && (
        <>
          {isUser && <NotificationDropdown />}
          {isNavigator && <NavigatorNotificationDropdown />}
          <ButtonStyled
            onClick={e => layoutContext?.handleClick(e)}
            startIcon={
              <AvatarStyled
                aria-haspopup="true"
                aria-controls="simple-menu"
              >{`${data.meClient.firstName ? data.meClient.firstName[0] : ''}${
                data.meClient.lastName ? data.meClient.lastName[0] : ''
              }`}</AvatarStyled>
            }
          >
            <Typography align="left" variant="subtitle2">
              {`${data.meClient.firstName || ''} ${data.meClient.middleName ||
                ''} ${data.meClient.lastName || ''}`}
            </Typography>
          </ButtonStyled>
        </>
      )}
    </WrapSearchTreatment>
  );
};
export default React.memo(IconSearchAndProfile);
