import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { PaperBorder } from 'share/component_css';
type Props = {};

export const LoadingComment: React.FC<Props> = () => {
  return (
    <>
      <PaperBorder className="p-8 mb-16 mt-16">
        <Skeleton width={80} variant="text" />
        <Skeleton variant="text" width="100%" />
      </PaperBorder>
      <PaperBorder className="p-8">
        <Skeleton width={80} variant="text" />
        <Skeleton variant="text" width="100%" />
      </PaperBorder>
    </>
  );
};

export default React.memo(LoadingComment);
