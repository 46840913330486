import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import {
  DialogTitleClose,
  AutoCompleteSelect,
  TextfieldSelect,
  KeyboardDateOption,
} from 'components';
import { useMutationCustom } from 'hooks';
import {
  CREATE_PATIENT_FOLLOW_UP,
  UPDATE_PATIENT_FOLLOW_UP,
  GET_ASSOCIATED_FOLLOW_UP_TYPES,
  GET_PATIENT_FOLLOW_UPS,
} from 'gql';
import {
  CreatePatientFollowUpMutation,
  CreatePatientFollowUpMutationVariables,
  UpdatePatientFollowUpMutation,
  UpdatePatientFollowUpMutationVariables,
  FollowUpStatus,
  PatientFollowUpFragment,
  GetAssociatedFollowUpTypesQuery,
  GetAssociatedFollowUpTypesQueryVariables,
} from 'types.d';
import { FOLLOW_UP_STATUS, LIST_FILTER_TASK_TYPE, TaskTypeFilter } from 'CONST';
import DialogButton from 'components/DialogButton';
import { PatientDetailContext } from 'share/context';
import { useLazyQuery } from '@apollo/react-hooks';
import { useServices } from '../../services';
import { useForm, FormContext } from 'react-hook-form';
import { trimObjectValue, formatDate } from 'share/utils';
import Skeleton from '@material-ui/lab/Skeleton';
import { customEvent } from 'modules/google_tag_manager';

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
  followUpSelected?: PatientFollowUpFragment;
  callbackAfterAModify?: (isUpdate?: boolean) => void;
};

type FormData = {
  note: string;
  followUpDate: string;
  type: any;
  status: string;
};

export const DialogFollowUp: React.FC<Props> = ({
  open,
  toogleDialog,
  followUpSelected,
  callbackAfterAModify,
}) => {
  const patientDetailContext = useContext(PatientDetailContext);

  const isEnter = useRef(false);

  const [taskType, setTaskType] = useState<TaskTypeFilter>(TaskTypeFilter.all);

  const firstUpdate = useRef(false);

  const {
    variablesFollowUpTag,
    handleDeleteFollowUp,
    loadingDeleteFollowUp,
  } = useServices();

  const methods = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      note: '',
      followUpDate: '',
      type: undefined,
      status: 'OPEN',
    },
  });

  const { register, control, setValue, handleSubmit, errors, watch } = methods;

  const watchType = watch('type');

  const [createPatientFollowUp, { loading: loadingCreate }] = useMutationCustom<
    CreatePatientFollowUpMutation,
    CreatePatientFollowUpMutationVariables
  >({
    api: CREATE_PATIENT_FOLLOW_UP,
    refetchQueries: [
      {
        query: GET_PATIENT_FOLLOW_UPS,
        variables: variablesFollowUpTag,
      },
    ],
    textSuccess: 'Add task successfully',
    callbackSuccess: () => {
      customEvent("addTaskPD", "NAVIGATOR")
      handleCancel();
      if (callbackAfterAModify) {
        callbackAfterAModify();
      }
    },
  });

  const [updatePatientFollowUp, { loading: loadingUpdate }] = useMutationCustom<
    UpdatePatientFollowUpMutation,
    UpdatePatientFollowUpMutationVariables
  >({
    api: UPDATE_PATIENT_FOLLOW_UP,
    refetchQueries: [
      {
        query: GET_PATIENT_FOLLOW_UPS,
        variables: variablesFollowUpTag,
      },
    ],
    textSuccess: 'Update task successfully',
    callbackSuccess: () => {
      customEvent("updateTaskPD", "NAVIGATOR")
      handleCancel();
      if (callbackAfterAModify) {
        callbackAfterAModify(true);
      }
    },
  });

  const [getFollowUpTypes, { data: dataFollowUpTypes, loading }] = useLazyQuery<
    GetAssociatedFollowUpTypesQuery,
    GetAssociatedFollowUpTypesQueryVariables
  >(GET_ASSOCIATED_FOLLOW_UP_TYPES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (isEnter.current) {
        if (followUpSelected) {
          const typeSelected = data.getAssociatedFollowUpTypes?.nodes.find(
            item => item._id === followUpSelected.type?._id,
          );
          firstUpdate.current = true;
          setValue('note', followUpSelected.note);
          setValue('status', followUpSelected.status);
          setValue('type', typeSelected || null);
          setValue(
            'followUpDate',
            formatDate(new Date(followUpSelected.followUpDate).toISOString()) ||
            '',
          );
          return;
        }
        setValue('note', '');
        setValue('type', null);
        setValue('followUpDate', '');
      }
    },
  });

  const handleDelteTask = () => {
    handleDeleteFollowUp(
      followUpSelected?._id,
      () => {
        toogleDialog(false);
        patientDetailContext?.handleSetDialogOpen('');
        if (callbackAfterAModify) {
          callbackAfterAModify();
        }
      },
      true,
    );
  };

  const handleSave = handleSubmit(data => {
    const dataTrim = { ...trimObjectValue(data) };

    const { note, type, status, followUpDate } = dataTrim;

    const params = {
      note,
      followUpDate: followUpDate ? new Date(followUpDate) : null,
      type: type?._id || null,
    };
    if (followUpSelected) {
      if (status === FollowUpStatus.Cancelled) {
        handleDelteTask();
        return;
      }
      updatePatientFollowUp({
        variables: {
          params: {
            ...params,
            _id: followUpSelected._id,
            status: status as FollowUpStatus,
            patientId: patientDetailContext?.patient?._id,
          },
        },
      });
    } else {
      createPatientFollowUp({
        variables: {
          params: {
            ...params,
            userId: patientDetailContext?.patient.owner?.idOwner ?? '',
          },
        },
      });
    }
  });

  const enterDialog = () => {
    isEnter.current = true;
    getFollowUpTypes({
      variables: {
        params: {
          page: 1,
          patientId: patientDetailContext?.patient.owner?.idOwner,
          sortByOrder: { name: 1 },
        },
      },
    });
  };

  const handleCancel = () => {
    toogleDialog(false);
    patientDetailContext?.handleSetDialogOpen('');
  };

  const getTaskType = (type: unknown) => {
    const paramsByType: {
      [key: string]: GetAssociatedFollowUpTypesQueryVariables;
    } = {
      [TaskTypeFilter.all]: {
        params: {
          page: 1,
          patientId: patientDetailContext?.patient.owner?.idOwner,
        },
      },
      [TaskTypeFilter.favorite]: {
        params: {
          patientId: patientDetailContext?.patient.owner?.idOwner,
          page: 1,
          favorites: true,
          sortByOrder: { name: 1 },
        },
      },
    };
    isEnter.current = false;
    setTaskType(type as TaskTypeFilter);
    setValue('type', null);
    getFollowUpTypes({
      variables: {
        ...paramsByType[type as string],
      },
    });
  };

  useEffect(() => {
    if (watchType) {
      if (firstUpdate.current === true) {
        firstUpdate.current = false;
      } else {
        setValue('note', watchType.description);
      }
    }
  }, [setValue, watchType]);

  return (
    <FormContext {...methods}>
      <Dialog onEntering={enterDialog} open={open} maxWidth="xs" fullWidth>
        <DialogTitleClose
          title={followUpSelected ? 'Update Task' : 'Add Task'}
          onClose={handleCancel}
        />
        <DialogContent>
          {loading && isEnter.current ? (
            <Skeleton width="100%" height={300} variant="rect" />
          ) : (
            <>
              <KeyboardDateOption
                methods={methods}
                name="followUpDate"
                label="Task Date"
              />
              {followUpSelected && (
                <TextfieldSelect
                  name="status"
                  label="Status"
                  control={control}
                  dense
                >
                  {FOLLOW_UP_STATUS.map(item => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </TextfieldSelect>
              )}
              <RadioGroup
                aria-label="position"
                name="position"
                value={taskType}
                onChange={(e, value) => getTaskType(value as unknown)}
                row
              >
                {LIST_FILTER_TASK_TYPE.map(item => {
                  return (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      control={<Radio color="primary" />}
                      label={item.label}
                    />
                  );
                })}
              </RadioGroup>
              <AutoCompleteSelect
                className="mt-8"
                small
                disablePortal
                name="type"
                label="Type"
                control={control}
                nameOption="name"
                options={
                  dataFollowUpTypes?.getAssociatedFollowUpTypes?.nodes || []
                }
              />
              <TextField
                required
                helperText={!!errors.note ? errors.note.message : ''}
                error={!!errors.note}
                InputLabelProps={{ shrink: true }}
                margin="dense"
                label="Description"
                multiline
                rows={8}
                inputRef={register({
                  validate: {
                    required: value =>
                      value.trim() !== '' || 'This field is required',
                  },
                })}
                variant="outlined"
                fullWidth
                name="note"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <DialogButton isCancel onClickButton={handleCancel} />
          {!patientDetailContext?.isInactive && (
            <DialogButton
              loading={loadingCreate || loadingUpdate || loadingDeleteFollowUp}
              onClickButton={handleSave}
            />
          )}
        </DialogActions>
      </Dialog>
    </FormContext>
  );
};

export default React.memo(DialogFollowUp);
