import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
  Typography,
} from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import {
  CheckCircleOutlineOutlined,
  Close,
  InsertComment,
  InsertCommentOutlined,
  Save,
  VoiceChatSharp,
} from '@material-ui/icons';
import {
  ButtonLoading,
  DialogButton,
  DialogTitleClose,
  StepWrapper,
} from 'components';
import {
  speechSynthesis,
  SpeechSynthesisUtterance,
  SubscriptionKey,
} from 'web-speech-cognitive-services';
import { Composer } from 'react-say';
import VoicesComponent from 'routers/components/VoicesComponent';
import { SliderStyled } from 'share/component_css';
import { getLocalStorage, setDataLocalStorage } from 'share/utils';
import { DEFAULT_VOICE_SETTINGS } from 'CONST';
type Props = {
  msg: any;
  messages: any;
  setMessages: Function;
  setResponseAdded: Function;
};

const BoxStyled = styled(Box)(({ theme }) => ({
  padding: '5px',
  background: 'white',
  border: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start', // Align items to the start (left) of the container
  '& > *:first-child': {
    minWidth: '100%',
    alignSelf: 'center', // First child takes up remaining space
  },
}));

export const SettingsModal: React.FC<Props> = (props: Props) => {
  const voices = window.speechSynthesis.getVoices();

  const [rate, setRate] = useState(props.msg.rate);

  const [pitch, setPitch] = useState(props.msg.pitch);

  const [vol, setVolume] = useState(props.msg.vol);

  const [voice, setVoice] = useState<any>(voices[props.msg.voice].name);

  var default_voice_settings = JSON.parse(
    getLocalStorage(DEFAULT_VOICE_SETTINGS)!,
  );

  const marks = [
    {
      value: 0,
    },
    {
      value: 0.5,
    },
    {
      value: 1,
    },
    {
      value: 1.5,
    },
    {
      value: 2,
    },
  ];

  useEffect(() => {
    const fetchVoices = () => {
      const voices = window.speechSynthesis.getVoices();
      setVoice(voices[props.msg.voice].name);
    };
    // Ensure voices are loaded before setting the default voice
    if (window.speechSynthesis.onvoiceschanged !== undefined) {
      window.speechSynthesis.onvoiceschanged = fetchVoices;
    } else {
      fetchVoices();
    }
    if (props.msg.rate != default_voice_settings.rate) {
      saveRate('', default_voice_settings.rate);
    }
    if (props.msg.pitch != default_voice_settings.pitch) {
      savePitch('', default_voice_settings.pitch);
    }
    if (props.msg.voice != default_voice_settings.voice) {
      saveVoice(default_voice_settings.voice);
    }
  }, [
    default_voice_settings.pitch,
    default_voice_settings.rate,
    default_voice_settings.voice,
    props.msg.pitch,
    props.msg.rate,
    props.msg.voice,
    savePitch,
    saveRate,
    saveVoice,
  ]);

  function saveRate(event: any, rate: any) {
    props.setResponseAdded(false);
    props?.setMessages(
      props.messages.map((ele: any) => {
        if (ele.type == 'bot') {
          if (rate > -1 && rate <= 2) {
            setRate(rate);
            ele.rate = rate;
            ele.played = undefined;
          }
          if (ele.responseId == props.msg.responseId) {
            props.msg.rate = rate;
            if (default_voice_settings.rate !== rate) {
              default_voice_settings.rate = rate;
              setDataLocalStorage(
                DEFAULT_VOICE_SETTINGS,
                JSON.stringify(default_voice_settings),
              );
            }
          }
        }
        return { ...ele };
      }),
    );
  }

  function savePitch(event: any, pitch: any) {
    props.setResponseAdded(false);
    props?.setMessages(
      props.messages.map((ele: any) => {
        if (ele.type == 'bot') {
          if (pitch > -1 && pitch <= 2) {
            setPitch(pitch);
            ele.pitch = pitch;
            ele.played = undefined;
          }
          if (ele.responseId == props.msg.responseId) {
            props.msg.pitch = pitch;
            if (default_voice_settings.pitch !== pitch) {
              default_voice_settings.pitch = pitch;
              setDataLocalStorage(
                DEFAULT_VOICE_SETTINGS,
                JSON.stringify(default_voice_settings),
              );
            }
          }
        }
        return { ...ele };
      }),
    );
  }

  function saveVolume(event: any, vol: any) {
    props.setResponseAdded(false);
    props?.setMessages(
      props.messages.map((ele: any) => {
        if (ele.responseId == props.msg.responseId) {
          if (vol > -1 && vol <= 2) {
            setVolume(vol);
            props.msg.vol = vol;
            ele.vol = vol;
            ele.played = undefined;
          }
        }
        return { ...ele };
      }),
    );
  }

  function closeModal() {
    props.setResponseAdded(false);
    props?.setMessages(
      props.messages.map((ele: any) => {
        if (ele.responseId == props.msg.responseId) {
          if (ele.settings == true) {
            props.msg.settings = false;
            ele.settings = false;
          }
        }
        return { ...ele };
      }),
    );
  }

  function handleSelectedVoiceChange(
    event: React.ChangeEvent<{ value: unknown }>,
  ) {
    saveVoice(event.target.value);
  }

  function saveVoice(voice: any) {
    props.setResponseAdded(false);
    props?.setMessages(
      props.messages.map((ele: any) => {
        if (ele.type == 'bot') {
          var index = voices.findIndex(voc => voc.name === voice);
          ele.voice = index;
          ele.played = undefined;
          if (ele.responseId == props.msg.responseId) {
            props.msg.voice = index;
            setVoice(voices[index].name);
            if (default_voice_settings.voice !== index) {
              default_voice_settings.voice = index;
              setDataLocalStorage(
                DEFAULT_VOICE_SETTINGS,
                JSON.stringify(default_voice_settings),
              );
            }
          }
        }
        return { ...ele };
      }),
    );
  }

  return (
    <>
      <Dialog open={props.msg.settings} fullWidth maxWidth="xs">
        <DialogTitleClose title="Voice Settings" onClose={closeModal} />
        <DialogContent>
          <BoxStyled>
            <Typography variant="body2" style={{ fontWeight: 500 }}>
              Rate
            </Typography>
            <SliderStyled
              defaultValue={rate}
              max={2}
              min={0}
              step={0.1}
              value={rate}
              valueLabelDisplay="auto"
              marks={marks}
              onChange={saveRate}
            />
            <Typography variant="body2" style={{ fontWeight: 500 }}>
              Pitch
            </Typography>
            <SliderStyled
              defaultValue={pitch}
              max={2}
              min={0}
              step={0.1}
              valueLabelDisplay="auto"
              marks={marks}
              value={pitch}
              onChange={savePitch}
            />
            {/* <Typography
                            variant="body2"
                            style={{ fontWeight: 500 }}
                        >Volume</Typography>
                        <Slider
                            defaultValue={vol}
                            max={2}
                            min={0}
                            step={0.1}
                            valueLabelDisplay="auto"
                            marks={marks}
                            value={vol}
                            onChange={saveVolume}
                        /> */}
            {voices && voices.length > 0 && (
              <VoicesComponent
                voices={voices}
                voice={voice}
                handleSelectedVoiceChange={handleSelectedVoiceChange}
              />
            )}
          </BoxStyled>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default React.memo(SettingsModal);
