import React, { useContext } from 'react';
import { PatientDetailContext } from 'share/context';
import { Box, Tooltip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ChipStyled } from 'share/component_css';

type Props = {
  index: number;
};

export const BoxShowMore: React.FC<Props> = ({ index }) => {
  const patientDetailContext = useContext(PatientDetailContext);

  return (
    <Tooltip title="Show More">
      <ChipStyled
        colortext="true"
        className="mr-8"
        onClick={() => patientDetailContext?.enableTab(index)}
        label={
          <Box display="flex">
            <ArrowDropDownIcon fontSize="small" />
          </Box>
        }
      />
    </Tooltip>
  );
};
export default React.memo(BoxShowMore);
