import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { useToogleDialog } from 'hooks';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import DialogSurveyView from './DialogSurveyView';
import { SurveyResultFragment } from 'types.d';

type Props = {
  item: SurveyResultFragment;
};

export const RowSurvey: React.FC<Props> = ({ item }) => {
  const [openDialogSurveyView, setOpenDialogSurveyView] = useToogleDialog();

  return (
    <>
      {openDialogSurveyView && (
        <DialogSurveyView
          open={openDialogSurveyView}
          toogleDialog={setOpenDialogSurveyView}
          item={item}
        />
      )}
      <Tooltip title="View" arrow>
        <IconButton
          onClick={() => setOpenDialogSurveyView(true)}
          color="primary"
        >
          <RemoveRedEyeIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default React.memo(RowSurvey);
