import React, { useCallback, useRef, useContext, useMemo } from 'react';
import AddIcon from '@material-ui/icons/Add';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Tooltip, Box, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTheme } from '@material-ui/core/styles';
import LoadingTags from './LoadingTags';
import {
  PatientCondStageFragment,
  DeletePatientStageMutation,
  DeletePatientStageMutationVariables,
} from 'types.d';
import { TypoThreeDot, ChipStyled } from 'share/component_css';
import { useToogleDialog, usePopover, useMutationCustom } from 'hooks';
import { DeleteConfirm, ScrollStyled } from 'components';
import { useServices } from '../../services';
import BoxShowMore from './BoxShowMore';
import { formatDate } from 'share/utils';
import DialogConditionStage from '../ConditionStage/DialogConditionStage';
import { DELETE_PATIENT_STAGE } from 'gql';
import { PatientTagsContext } from 'share/context';

type Props = {
  loadingDataGroup: boolean;
  conditions?: PatientCondStageFragment[];
};

export const ConditionTag: React.FC<Props> = ({
  loadingDataGroup,
  conditions,
}) => {
  const conditionSelected = useRef<PatientCondStageFragment | undefined>(
    undefined,
  );
  const theme = useTheme();

  const [isOpenDelete, handleToogleDialogDelete] = useToogleDialog();

  const patientTagsContext = useContext(PatientTagsContext);

  const { refetchQueriesCondition } = useServices();

  const [
    deletePatientStage,
    { loading: loadingDeletePatientStage },
  ] = useMutationCustom<
    DeletePatientStageMutation,
    DeletePatientStageMutationVariables
  >({
    api: DELETE_PATIENT_STAGE,
    refetchQueries: refetchQueriesCondition(),
    textSuccess: 'Remove condition successfully',
    callbackSuccess: () => {
      close();
      handleToogleDialogDelete(false);
    },
  });

  const [
    openDialogConditionStage,
    setOpenDialogConditionStage,
  ] = useToogleDialog();

  const [open, close, DefaultPopover, defaultPopoverProps] = usePopover();

  //check survey tab is opened
  const isOnTab = useMemo(() => {
    return patientTagsContext?.isExtend && patientTagsContext?.indexTab === 8;
  }, [patientTagsContext]);

  const openAddDialog = useCallback(() => {
    //open dialog from tab
    if (isOnTab) {
      patientTagsContext?.handleSetDialogOpen('add-condition');
      return;
    }
    //open dialog from tag
    setOpenDialogConditionStage(true);
    conditionSelected.current = undefined;
  }, [isOnTab, patientTagsContext, setOpenDialogConditionStage]);

  const getNameStage = (item: PatientCondStageFragment) => {
    return (
      item?.condition?.stages?.find(stage => stage.stageId === item.stageId)
        ?.name || 'N/A'
    );
  };

  const deleteConditionType = () => {
    deletePatientStage({
      variables: {
        params: {
          condStageId: conditionSelected.current?._id,
          patientId: patientTagsContext?.patient.owner?.idOwner,
          conditionId: conditionSelected.current?.condition?.conditionId,
        },
      },
    });
  };

  const editCondition = () => {
    //open dialog from task tab
    if (isOnTab) {
      patientTagsContext?.handleSetDialogOpen(
        `edit-condition`,
        conditionSelected.current,
      );
      return;
    }
    //open dialog from tag
    setOpenDialogConditionStage(true);
  };

  const deleteCondition = () => {
    if (isOnTab) {
      patientTagsContext?.handleSetDialogOpen(
        `delete-condition`,
        conditionSelected.current,
      );
      return;
    }
    //open dialog from tag
    handleToogleDialogDelete(true);
  };

  return (
    <>
      <DeleteConfirm
        loading={loadingDeletePatientStage}
        handleConfirm={deleteConditionType}
        open={isOpenDelete}
        toogle={handleToogleDialogDelete}
      />
      {openDialogConditionStage && (
        <DialogConditionStage
          conditionStageSelected={conditionSelected.current}
          open={openDialogConditionStage}
          closeDialog={() => {
            setOpenDialogConditionStage(false);
            close();
          }}
        />
      )}
      <DefaultPopover {...defaultPopoverProps}>
        <Box p={0.5} display="flex" flexDirection="column">
          {!conditionSelected?.current?.endDate && (
            <Tooltip title="Update">
              <IconButton onClick={editCondition} color="primary">
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Delete">
            <IconButton
              onClick={deleteCondition}
              aria-label="delete"
              color="secondary"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </DefaultPopover>
      {!patientTagsContext?.isInactive && (
        <ChipStyled
          colortext="true"
          className="mr-8"
          onClick={openAddDialog}
          label={
            <Box display="flex">
              <AddIcon fontSize="small" />
            </Box>
          }
        />
      )}
      {conditions?.length !== 0 && <BoxShowMore index={8} />}
      {loadingDataGroup && <LoadingTags />}
      {conditions && conditions?.length > 0 && !loadingDataGroup && (
        <ScrollStyled
          loading={loadingDataGroup}
          listLength={conditions?.length}
        >
          {conditions.map(item => {
            return (
              <ChipStyled
                className="cursor-pointer"
                key={item._id}
                backgroundcolor={item.color || theme.palette.primary.main}
                label={
                  <Tooltip
                    title={
                      <Box display="flex" flexDirection="column">
                        {patientTagsContext?.generateInformation([
                          {
                            name: 'Condition',
                            info: item.condition?.name || 'N/A',
                          },
                          {
                            name: 'Stage',
                            info: getNameStage(item),
                          },
                          {
                            name: 'Start Date',
                            info: formatDate(item.createdAt, true),
                          },
                        ])}
                      </Box>
                    }
                  >
                    <TypoThreeDot variant="subtitle2">
                      {`${item.condition?.name || 'N/A'} - ${getNameStage(item)}
                      `}
                    </TypoThreeDot>
                  </Tooltip>
                }
                onDelete={e => {
                  open(e);
                  conditionSelected.current = item;
                }}
                deleteIcon={
                  !patientTagsContext?.isInactive ? <MoreHorizIcon /> : <></>
                }
              />
            );
          })}
        </ScrollStyled>
      )}
    </>
  );
};

export default React.memo(ConditionTag);
