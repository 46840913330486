import React, { useState, useEffect } from 'react';
import { AutoCompleteSelect } from 'components';
import { GET_BASIC_ORGANIZATIONS } from 'gql';
import {
  GetBasicOrganizationsQuery,
  GetBasicOrganizationsQueryVariables,
} from 'types.d';
import { Box } from '@material-ui/core';
import { useLazyQuery } from '@apollo/react-hooks';
import { useCustomPermission } from 'hooks';
import { sortObject } from 'share/utils';

type DefaultPopoverProps = {
  orgSelected?: string | undefined;
  dataOrganization?: GetBasicOrganizationsQuery;
  changeOrg: (_event: any, newValue: any) => void;
};
export const useFilterByOrg = (
  callbackGetOrgs: (orgId: string) => void,
  callbackChangeOrg: (orgId: string) => void,
) => {
  const [orgSelected, setOrgSelected] = useState<string | undefined>(undefined);

  const { isSuperAdmin } = useCustomPermission();

  const [getOrg, { data: dataOrganization }] = useLazyQuery<
    GetBasicOrganizationsQuery,
    GetBasicOrganizationsQueryVariables
  >(GET_BASIC_ORGANIZATIONS, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const orgDefault = data?.getOrganizations?.nodes.find(
        item => item.default,
      );
      setOrgSelected(orgDefault?._id);
      callbackGetOrgs(orgDefault?._id);
    },
    variables: {
      params: {
        page: 1,
      },
    },
  });

  useEffect(() => {
    if (isSuperAdmin) {
      getOrg();
    }
  }, [getOrg, isSuperAdmin]);

  const changeOrg = (_event: any, newValue: any) => {
    setOrgSelected(newValue?._id);
    callbackChangeOrg(newValue?._id as string);
  };

  const defaultSelectOrgProps = {
    orgSelected,
    dataOrganization,
    changeOrg,
  };

  return { orgSelected, DefaultSelectOrg, defaultSelectOrgProps };
};

export const DefaultSelectOrg: React.FC<DefaultPopoverProps> = ({
  orgSelected,
  dataOrganization,
  changeOrg,
}) => {
  return (
    <Box width={200} mr={1}>
      <AutoCompleteSelect
        small
        disablePortal
        name="organization"
        label="Organization"
        nameOption="name"
        defaultValue={orgSelected}
        options={sortObject(dataOrganization?.getOrganizations?.nodes) || []}
        callbackChangeValue={changeOrg}
      />
    </Box>
  );
};
