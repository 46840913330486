import React from 'react';
import { ButtonLoading } from 'components';
import DialogShareAssignTreatment from './DialogShareAssignTreatment';
import { useToogleDialog } from 'hooks';
import EmailIcon from '@material-ui/icons/Email';

type Props = {
  idTreatment?: string | null;
  smsMessage?: string;
};

export const ButtonSendEmail: React.FC<Props> = ({
  idTreatment,
  smsMessage,
}) => {
  const [isOpen, toogleDialog] = useToogleDialog();

  return (
    <>
      {isOpen && (
        <DialogShareAssignTreatment
          open={isOpen}
          setOpenModal={toogleDialog}
          idTreatment={idTreatment}
          smsMessage={smsMessage}
        />
      )}
      <ButtonLoading
        fullWidth
        Icon={<EmailIcon />}
        text="Email"
        callbackClick={() => toogleDialog(true)}
        variant="contained"
      />
    </>
  );
};
export default ButtonSendEmail;
