import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { useMutation } from '@apollo/react-hooks';
import ReactCodeInput from 'react-verification-code-input';
import { TypographyBold, VerifyIcon } from 'share/component_css';
import {
  VerifyPhoneUserMutation,
  VerifyPhoneUserMutationVariables,
} from 'types.d';
import { TOOGLE_BANNER_STATUS, VERIFY_USER_PHONE } from 'gql';
import { useUpdateMeClient } from 'hooks';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import BackdropComponent from './BackdropComponent';
import { handleError } from 'share/utils';
import { ButtonLoading } from './ButtonLoading';
import RefreshIcon from '@material-ui/icons/Refresh';

type Props = {
  phone?: string;
  resendOTP?: () => void;
  loadingSendOTP: boolean;
  time: number;
  setTime: Function;
  isUnverifyAccount: boolean;
};

export const BoxStyled = styled(Box)({
  '&.MuiBox-root': {
    padding: 16,
    paddingTop: 0,
  },
});

export const SuccessPhoneLayout: React.FC<Props> = ({
  phone,
  resendOTP,
  loadingSendOTP,
  time,
  setTime,
  isUnverifyAccount,
}) => {
  const [values, setValues] = useState('');
  const handleChangeCode = (value: string) => {
    setValues(value);
  };
  const { handleUpdateMeClient } = useUpdateMeClient();

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [toogleBannerStatus] = useMutation(TOOGLE_BANNER_STATUS);

  useEffect(() => {
    if (time === 30) {
      let resendTime = setInterval(() => {
        setTime((time: any) => {
          if (time === 0) {
            clearInterval(resendTime);
            return 0;
          } else return time - 1;
        });
      }, 1000);
    }
  }, [setTime, time]);

  const [verifyUserPhone, { loading }] = useMutation<
    VerifyPhoneUserMutation,
    VerifyPhoneUserMutationVariables
  >(VERIFY_USER_PHONE, {
    onError(error) {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
    onCompleted(data) {
      enqueueSnackbar('Verify successfully', { variant: 'success' });
      //update me client
      handleUpdateMeClient(data.verifyPhoneUser);
      //redirect to home
      history.push('/');
      //skip banner when login
      toogleBannerStatus({
        variables: { status: false },
      });
    },
  });

  useEffect(() => {
    if (values?.length === 6) {
      verifyUserPhone({
        variables: {
          params: {
            phone: phone as string,
            otp: values,
          },
        },
      });
    }
  }, [phone, values, verifyUserPhone]);

  return (
    <BoxStyled alignItems="center" display="flex" flexDirection="column">
      <VerifyIcon />
      <TypographyBold align="center" variant="h6">
        Enter Your Verification Code
      </TypographyBold>
      <BackdropComponent loading={loading} />
      <Typography className="mb-16 mt-16" align="center" variant="subtitle2">
        {isUnverifyAccount
          ? `Look like your account has not been verified. Please check your phone
            number to get verification code and enter the correct numbers here.`
          : `We sent you a verification code. Please enter the correct numbers
            here.`}
      </Typography>
      <Box
        display="flex"
        className="mb-16"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <ReactCodeInput
          onChange={handleChangeCode}
          type="text"
          fields={6}
          className="Tes"
        />
        <ButtonLoading
          disabled={!!(time !== 0)}
          loading={loadingSendOTP}
          className="float-right mt-24"
          Icon={<RefreshIcon />}
          text={
            time === 0
              ? 'Resend OTP'
              : `Resend OTP in 00:${time > 9 ? time : `0${time}`}`
          }
          callbackClick={resendOTP}
        />
      </Box>
    </BoxStyled>
  );
};
