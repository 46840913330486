import React, { useState, useMemo, useContext } from 'react';
import { Box, Chip, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { GetNavigatorsToInviteQuery } from 'types.d';
import { ButtonLoading, AutoCompleteLoadData, CheckboxList } from 'components';
import { PopoverContext } from 'share/context';
type Props = {
  loadGetNavigator: boolean;
  loadingInvite: boolean;
  getListNavigator: (search: string) => void;
  dataNavigators?: GetNavigatorsToInviteQuery['getNavigatorsToInvite']['nodes'];
  handleSave: (
    users: GetNavigatorsToInviteQuery['getNavigatorsToInvite']['nodes'],
  ) => void;
};

export const AutoCompleteNavigator: React.FC<Props> = ({
  loadingInvite,
  getListNavigator,
  dataNavigators,
  loadGetNavigator,
  handleSave,
}) => {
  const [selectedUser, setSelectedUser] = useState<
    GetNavigatorsToInviteQuery['getNavigatorsToInvite']['nodes']
  >([]);

  const popoverContext = useContext(PopoverContext);

  const handleAutocomplete = (
    value: GetNavigatorsToInviteQuery['getNavigatorsToInvite']['nodes'],
  ) => {
    setSelectedUser(value);
  };

  const handleTextInput = (text: string) => {
    if (text !== '') {
      getListNavigator(text);
    }
  };

  const listOptions = useMemo(() => {
    if (dataNavigators) {
      const selectedUserId = selectedUser.map(item => {
        return item._id;
      });
      return [...dataNavigators].filter(item => {
        return !selectedUserId.includes(item._id);
      });
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataNavigators]);

  return (
    <>
      <AutoCompleteLoadData
        label="Users"
        nameOption="name"
        loadList={loadGetNavigator}
        multiple
        small
        defaultValue={selectedUser}
        options={listOptions}
        callbackChangeValue={(_, newValue) => {
          handleAutocomplete(newValue);
        }}
        disableCloseOnSelect
        limitTags={2}
        callbackHandleText={value => handleTextInput(value)}
        callbackRenderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip label={option.email} {...getTagProps({ index })} />
          ))
        }
        getOptionDisabled={
          dataNavigators?.filter(item => {
            return (
              item._id === popoverContext?.inforTreatment?.nodeData?.ownerId
            );
          }) || []
        }
        callbackRenderOption={(option, selected) => (
          <CheckboxList
            checked={selected}
            option={
              <Box display="flex" flexDirection="column">
                <Typography variant="h6" gutterBottom>
                  {option.firstName +
                    (option.middleName || '') +
                    ' ' +
                    option.lastName}
                  {option._id ===
                    popoverContext?.inforTreatment?.nodeData?.ownerId && (
                    <Chip className="ml-8" label="Owner" size="small" />
                  )}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {option.email}
                </Typography>
              </Box>
            }
          />
        )}
      />
      <Box pt={2}>
        <ButtonLoading
          text="Invite"
          Icon={<PersonIcon />}
          loading={loadingInvite}
          callbackClick={() => handleSave(selectedUser)}
        />
      </Box>
    </>
  );
};

export default React.memo(AutoCompleteNavigator);
