import React from 'react';
import { InforTreatment } from 'modules/treatment_tree/interfaces';
import {
  CreateTreatmentMutation,
  UpdateTreatmentInfoMutation,
  GetDashboardsQuery,
  GetDataForFilterPatientQuery,
  CreateTreatmentStageInput,
  TreatmentStagesFragment,
  PatientFragment,
  GetTreatmentsTreeQuery,
  TreatmentForAddUpdateFragment,
  GetDataForUpdateTreeQuery,
  FollowUpTypeShortFragment,
  CommonTreatmentFragment,
  GetDashboardQuery,
  PatientDashboardInfoFragment,
  UpdateTagTreatmentTreeEmbeddedFragment,
  GetQuestionsQueryVariables,
  GetQuestionsQuery,
  GetTreatmentQueryVariables,
  GetTreatmentQuery,
  GetGroupSettingTaskTypeQuery,
  FollowUpTypeDescpShortFragment,
} from 'types.d';
import { ConfigChart } from 'modules/dashboard/interfaces';
import {
  Group,
  Rule,
  Timer,
  ConditionSurvey,
} from 'modules/patients/interfaces';
import { DataFilterDate } from 'modules/dashboard/interfaces';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { QueryLazyOptions } from '@apollo/react-hooks';
import { FilterByAnswer } from 'modules/dashboard/components/Content';

interface PlayVideoContextInterface {
  toogleStatusVideo: (index: number | null) => void;
}
interface TMediaContext {
  handleSetOpenDialog: (status: boolean) => void;
  open: boolean;
  type: 'image' | 'video';
  setSelectedMedia: (
    media: string,
    typeMedia: string,
    thumbnail?: string,
  ) => void;
  mediaSelected: { media: string; typeMedia: string };
  removeSelectedMedia: () => void;
}

export type TypeForm =
  | 'add-treatment'
  | 'update-treatment'
  | 'add-folder'
  | 'update-folder'
  | 'add-survey'
  | 'update-survey'
  | 'add-checklist'
  | 'update-checklist'
  | '';

export interface TTreeContext {
  dataTree?: GetTreatmentsTreeQuery;
  handleSetDisplayForm: ({
    inforTreatment,
    typeForm,
  }: {
    inforTreatment: InforTreatment | undefined;
    typeForm: TypeForm;
  }) => void;
  inforTreatmentSelected?: InforTreatment;
  refetchTree: Function;
  typeOfForm: string;
  loadingUpdate: boolean;
  setIdParenSelected: (id: string) => void;
  handleDeleteOnTree: (id?: string) => void;
  refetch: Function;
  handleSetExpandForm: (status: boolean) => void;
  isExpandForm: boolean;
  addOnTree: (paramsCreate: CreateTreatmentMutation['createTreatment']) => void;
  updateOnTree: (
    paramsUpdate: UpdateTreatmentInfoMutation['updateTreatmentInfo'],
  ) => void;
  updateTreeQrCode: () => void;
  setCheckListToSurvey: () => void;
  handleSetDataTree: () => void;
  searchInfomation: {
    valueSearch: string;
    searchFocusIndex: null | number;
    searchFoundCount: number;
  };
  handleSetSearchInfomation: (params: {
    valueSearch: string;
    searchFocusIndex: null | number;
    searchFoundCount: number;
  }) => void;
}

export interface OTTreeContext {
  dataTree?: GetTreatmentsTreeQuery;
  handleSetDataTree: () => void;
  searchInfomation: {
    valueSearch: string;
    searchFocusIndex: null | number;
    searchFoundCount: number;
  };
  handleSetSearchInfomation: (params: {
    valueSearch: string;
    searchFocusIndex: null | number;
    searchFoundCount: number;
  }) => void;
}

export interface DataAggregateInterface {
  [key: string]: any;
  name: string;
  value: number | string;
}
interface ChartContextInterface {
  dataAggregate?: DataAggregateInterface[];
  dataChart: ConfigChart;
  switchDrillDown?: (name?: string) => void;
  isDrillDown?: boolean;
}

interface ExportAnalyticsPdf {
  ignoreOnPdf: boolean;
  handleDiff: () => void;
  isOnPdf: boolean;
  dateSelected?: DataFilterDate | string;
  dataTable: any;
  getDataTable: (data: {
    isTableChart: boolean;
    name: string;
    id: string;
    dataAggreate?: DataAggregateInterface[];
  }) => void;
  valueFilter?: FilterByAnswer;
  setValueFilter: (value?: FilterByAnswer) => void;
  setInitLoadingChart: (isLoadingOne?: boolean) => void;
}

export interface ContentPatient {
  dataFilterForm: (Group | Rule)[];
  handleSetDataFilterForm: (data: (Group | Rule)[]) => void;
}

interface DashboardEditContextInterface {
  openDialogEdit: (dataForm: ConfigChart) => void;
  closeDialogEdit: Function;
  dashboardId?: string;
  configChartProps?: ConfigChart;
}

interface PopoverContextInterface {
  closePopover: Function;
  inforTreatment: InforTreatment;
}

interface PatientContextInterface {
  treaments?: GetDataForFilterPatientQuery['getTreatments']['nodes'];
  surveys?: GetDataForFilterPatientQuery['getSurveys']['nodes'];
  checklists?: GetDataForFilterPatientQuery['getChecklists']['nodes'];
  followUpType?: FollowUpTypeShortFragment[];
  followUpTypeDescp?: FollowUpTypeDescpShortFragment[];
  navigators?: GetDataForFilterPatientQuery['getNavigators']['nodes'];
  modules?: GetDataForFilterPatientQuery['getCPTCode']['nodes'];
  isGroup: (data: Group | Rule) => boolean;
  hidePatientInfo: boolean;
  refetchAllDashboard: Function;
  dashboardList: PatientDashboardInfoFragment[] | undefined;
  handleStop: () => void;
  timer: Timer;
  dialogConfirmStop: () => void;
  handleContinue: (seconds: number) => void;
  removeCountdown: () => void;
  continueToCountdown: () => void;
}

export interface DashboardsContextInterface {
  refetch: Function;
  dashboardsShow: GetDashboardsQuery['getDashboards'];
  allDashboard?: GetDashboardsQuery['getDashboards'];
  loadingDashboards: boolean;
  indexTab: number;
  handleSetIndexTab: (index: number) => void;
  handleSetIsEditMode: (status: boolean) => void;
}
interface FilterPatientContextInterface {
  dataFilterForm: (Group | Rule)[];
  handleSetDataFilterForm: (data: (Group | Rule)[]) => void;
  setFilter: (data: {
    arrValue: {
      value: Date | string | DataFilterDate | null | boolean;
      key: string;
    }[];
    idDataFilter: string;
  }) => void;
  deleteFilter: (filter?: Group | Rule) => void;
  toogleIsExtend: (status: boolean) => void;
}

interface ContentAnalyticDashboardInterface {
  isEditMode?: boolean;
  dashboard: GetDashboardQuery['getDashboard'];
  dateSelected?: DataFilterDate | string;
  valueFilter?: FilterByAnswer;
  setValueFilter: (value?: FilterByAnswer) => void;
}

interface DashboardShowModeInterface {
  isHidingNameUser?: boolean;
  handleToggleInfo: () => void;
}

interface DialogEditReportInterface {
  getQuestionsSurvey: (
    options?: QueryLazyOptions<GetQuestionsQueryVariables> | undefined,
  ) => void;
  listQuestionSurvey?: GetQuestionsQuery;
  getQuestionsChecklist: (
    options?: QueryLazyOptions<GetTreatmentQueryVariables> | undefined,
  ) => void;
  listQuestionChecklist?: GetTreatmentQuery;
}

interface TCheckHightLightContext {
  stageHightLight?: string[];
}

interface TTreatmentDetailContext {
  treatmentId: string;
}

interface TreatmentFlowInterface {}

interface FormTreatmentContextInterface {
  handleSetListStage: (
    data: CreateTreatmentStageInput[] | TreatmentStagesFragment[],
  ) => void;
  handleCreateStage: (data: CreateTreatmentStageInput) => void;
  editStage: (text: string, index: number, id?: string) => void;
  deleteStage: (index: number, stageId?: string) => void;
  setStageUpdate: () => void;
  loadingDeleteTreatmentStage: boolean;
}

interface PatientDetailContextInterface {
  patient: PatientFragment;
  isAnonymous: boolean;
  indexTab: number;
  enableTab: (index: number) => void;
  handleSetDialogOpen: (nameDialog: string, data?: any) => void;
  dialogOpen: string;
  isExtend?: boolean;
  dataForOpenDialog: any;
  isRefreshingChecklist: boolean;
  setIsRefreshChecklist: (status: boolean) => void;
  isInactive: boolean;
  refetchQueriesGetPatient?: any;
  currentDate: string | null;
  setCurrentDate: (date: string | null) => void;
}

interface CommentsContextInterface {
  idOwner: string;
  contributors?: string[] | null;
  treatmentId: string;
  isAbleToComment: boolean;
}

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;

  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;

  prompt(): Promise<void>;
}

interface SubcriptionClientContextInterface {
  subcriptionClient: SubscriptionClient | null;
}

interface PromptToInstallContextInterface {
  webInstallPrompt: BeforeInstallPromptEvent | null;
  handleWebInstallAccepted: () => void;
}

interface CreateUpdateTreatmentInterface {
  listParent?: TreatmentForAddUpdateFragment[];
  isUpdateValue: boolean;
  treatmentForUpdate?: GetDataForUpdateTreeQuery['getTreatment'];
  checklist?: CommonTreatmentFragment[];
  groupUpdate?: GetDataForUpdateTreeQuery['getTreatmentRegimen'];
  taskTypeUpdate?: GetDataForUpdateTreeQuery['getFollowUpTypes'];
  dataGroupSettingTaskType?: GetGroupSettingTaskTypeQuery;
  tags?: UpdateTagTreatmentTreeEmbeddedFragment[];
  setTags?: (tags: UpdateTagTreatmentTreeEmbeddedFragment[]) => void;
  getMoreParent: (value: string) => void;
}

interface RuleFilterPatientInterface {
  dataFilter?: Rule;
  changeDataFilter: (value: string | boolean, key: string) => void;
  filterDate: (key: string, date?: DataFilterDate | string) => void;
}

interface PatientStageInterface {
  refreshStageChangeLog: () => void;
}

interface UserInterface {
  handleSetNavigator: (userId: string, organization: string) => void;
  loadingSetNavigator: boolean;
}

interface LayoutInteface {
  toogleDialogLogin: (status: boolean) => void;
  setIsRedirectingToHome: () => void;
  isRedirectingToHome: boolean;
  firstName: string;
  setFirstName: (value: string) => void;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  chatbotDrawer: boolean;
  quickViewCards: any;
  setQuickViewCards: any;
  targetRef: React.RefObject<HTMLDivElement>;
  scrollToElement: (inputString: string) => void;
  openedTreatment: { _id: string; slug: string; markdown: string };
  handleOpenedTreatment: (_id: string, slug: string, markdown: string) => void;
}

interface ChatbotInteface {
  messages: any;
  setMessages: (value: any) => void;
}

interface ChatbotDrawerInteface {
  messages: any;
  setMessages: (value: any) => void;
}

interface ContentPatientContextInterface {
  dataFilterForm: (Rule | Group)[];
  filterFromConfig: any;
  dataFilterSurvey?: {
    [key: string]: ConditionSurvey[];
  };
}

interface TabsDashboardContexteInterface {
  checkedList: PatientFragment[];
  checkedListAll: string[];
  resetCheckbox: () => void;
  checkedDataList: any[];
}

interface PatientTagsContextInterface {
  indexTab: number;
  handleSetDialogOpen: (nameDialog: string, data?: any) => void;
  isExtend: boolean;
  isInactive: boolean;
  patient: PatientFragment;
  generateInformation: (
    information: {
      name: string;
      info: string;
    }[],
  ) => JSX.Element[];
}

interface OuterElementContextInterface {
  [key: string]: any;
}

export const TreatmentDetailContext = React.createContext<
  TTreatmentDetailContext | undefined
>(undefined);

export const CheckHightLightContext = React.createContext<
  TCheckHightLightContext | undefined
>(undefined);

export const MediaContext = React.createContext<TMediaContext | undefined>(
  undefined,
);

export const TreatmentTreeContext = React.createContext<
  TTreeContext | undefined
>(undefined);

export const OrganizationTreatmentTreeContext = React.createContext<
  OTTreeContext | undefined
>(undefined);

export const PopoverContext = React.createContext<
  PopoverContextInterface | undefined
>(undefined);

export const ChartContext = React.createContext<
  ChartContextInterface | undefined
>(undefined);

export const DashboardEditContext = React.createContext<
  DashboardEditContextInterface | undefined
>(undefined);

export const DashboardsContext = React.createContext<
  DashboardsContextInterface | undefined
>(undefined);

export const FilterPatientContext = React.createContext<
  FilterPatientContextInterface | undefined
>(undefined);

export const FormTreatmentContext = React.createContext<
  FormTreatmentContextInterface | undefined
>(undefined);

export const PatientContext = React.createContext<
  PatientContextInterface | undefined
>(undefined);

export const PatientDetailContext = React.createContext<
  PatientDetailContextInterface | undefined
>(undefined);

export const PlayVideoContext = React.createContext<
  PlayVideoContextInterface | undefined
>(undefined);

export const CommentsContext = React.createContext<
  CommentsContextInterface | undefined
>(undefined);

export const PromptToInstallContext = React.createContext<
  PromptToInstallContextInterface | undefined
>(undefined);

export const SubcriptionClientContext = React.createContext<
  SubcriptionClientContextInterface | undefined
>(undefined);

export const CreateUpdateTreatmentContext = React.createContext<
  CreateUpdateTreatmentInterface | undefined
>(undefined);

export const FormFilterPatientContext = React.createContext<
  RuleFilterPatientInterface | undefined
>(undefined);

export const PatientStageContext = React.createContext<
  PatientStageInterface | undefined
>(undefined);

export const UserContext = React.createContext<UserInterface | undefined>(
  undefined,
);

export const LayoutContext = React.createContext<LayoutInteface | undefined>(
  undefined,
);

export const ChatbotDrawerContext = React.createContext<
  ChatbotDrawerInteface | ChatbotInteface | undefined
>(undefined);

export const ContentAnalyticDashboardContext = React.createContext<
  ContentAnalyticDashboardInterface | undefined
>(undefined);

export const DashboardShowModeContext = React.createContext<
  DashboardShowModeInterface | undefined
>(undefined);

export const DialogEditReportContext = React.createContext<
  DialogEditReportInterface | undefined
>(undefined);

export const ExportAnalyticsPdfContext = React.createContext<
  ExportAnalyticsPdf | undefined
>(undefined);

export const ContentPatientContext = React.createContext<
  ContentPatientContextInterface | undefined
>(undefined);

export const PatientTagsContext = React.createContext<
  PatientTagsContextInterface | undefined
>(undefined);

export const TabsDashboardContext = React.createContext<
  TabsDashboardContexteInterface | undefined
>(undefined);

export const OuterElementContext = React.createContext<
  OuterElementContextInterface | undefined
>(undefined);

export const ChatbotContext = React.createContext<
  ChatbotDrawerInteface | undefined
>(undefined);

export const TreatmentFlowContext = React.createContext<
  TreatmentFlowInterface | undefined
>(undefined);
