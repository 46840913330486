import { styled, Theme } from '@material-ui/core/styles';
import { Box, TextField, Grid, Chip } from '@material-ui/core';
import { PaperBorder } from 'share/component_css';

export const PaperStyled = styled(PaperBorder)(
  ({ theme, ishightlight }: { theme: Theme; ishightlight?: string }) => ({
    borderColor: Object.is(ishightlight, 'true')
      ? theme.palette.primary.light
      : theme.palette.grey[200],
    borderWidth: Object.is(ishightlight, 'true') ? '3px' : '1px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
  }),
);

export const WrapBoxSearch = styled(Box)({
  // '& input': {
  //   padding: '18px',
  // },
  // '& .MuiSelect-root': {
  //   paddingTop: '17px',
  //   paddingBottom: '17px',
  // },
});

export const GridMarkDownStyled = styled(Grid)({
  '& h1': {
    fontSize: '1.625rem',
  },

  '& h2': {
    fontSize: '1.5rem',
  },
  '& h3': {
    fontSize: '1.25rem',
  },
  '& h4': {
    fontSize: '1rem',
  },
  '& p, & li': {
    fontSize: '1rem',
    wordBreak: 'break-word',
  },
  '& img': {
    display: 'block',
    textAlign: 'center',
    margin: '0 auto',
    maxWidth: '100%',
  },
  '& table': {
    borderCollapse: 'collapse',
    width: '100%',
  },
  '& th, & td': {
    border: '1px solid black',
    padding: '8px',
    textAlign: 'left',
  },
  '& th': {
    backgroundColor: '#f2f2f2',
  },
  '& tr:nth-child(even)': {
    backgroundColor: '#f2f2f2',
  },
  '& pre': {
    whiteSpace: 'pre-wrap',       // Allows the text to wrap within the pre tag
    wordWrap: 'break-word',       // Prevents long words from overflowing
    overflowX: 'auto',            // Adds a horizontal scrollbar if the content overflows
    maxWidth: '100%',             // Ensures the pre tag does not exceed the width of the container
    padding: '10px',              // Adds some padding for better readability
    backgroundColor: '#f5f5f5',   // Light gray background color
    border: '1px solid #ddd',     // Light border
    borderRadius: '4px',          // Rounded corners
    boxSizing: 'border-box'
  }
});
export const ChipStyled = styled(Chip)(({ theme }) => ({
  width: theme.spacing(8),
}));

export const TextFieldCommentStyled = styled(TextField)(({ theme }) => ({
  '& input': {
    fontSize: '14px',
    '&:focus-visible': {
      outline: 'none',
    },
  },
  '& fieldset': {
    borderWidth: 0,
  },
  '&:focus-visible': {
    outline: 'none',
  },
  '& .MuiInputBase-root': {
    '&:focus-visible': {
      outline: 'none',
    },
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
    },
  },
}));
export const BoxCommentStyled = styled(Box)(
  ({ theme, isdeleted }: { theme: Theme; isdeleted?: string }) => ({
    '& .elem-absolute': {
      display: 'none',
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translate(0%, -50%)',
    },
    '&:hover': {
      backgroundColor: Object.is(isdeleted, 'true')
        ? theme.palette.grey[300]
        : 'transparent',
    },
    '&:hover .elem-absolute': {
      display: Object.is(isdeleted, 'true') ? 'block' : 'none',
    },
  }),
);