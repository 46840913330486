import React, { useContext, useRef } from 'react';
import { Box, Grid } from '@material-ui/core';
import { SectionHeadingStyled } from '..';
import TreatmentCard from './TreatmentCard';
import { LayoutContext } from 'share/context';
import InfiniteScroll from 'react-infinite-scroller';
import { HomePageConfig } from 'types.d';

type Props = {
  items?: any;
  isReset: boolean;
  hasMore: boolean;
  setHasMore: React.Dispatch<React.SetStateAction<boolean>>;
  getMoreData: (
    dataFilter: { name: string; type?: HomePageConfig },
    page: number,
    reset?: boolean,
    type?: string,
  ) => void;
  dataLoadType?: any;
};

export const TreatmentsComponent: React.FC<Props> = (props: Props) => {
  const layoutContext = useContext(LayoutContext);
  let page = useRef(1);

  const loadMore = () => {
    if (props.hasMore) {
      page.current++;
      props.setHasMore(false);
      props.getMoreData({ name: "" }, page.current, props.isReset, props.dataLoadType);
    }
  };

  return (
    <>
      {!props.isReset && (
        <InfiniteScroll
          pageStart={page.current}
          loadMore={loadMore}
          hasMore={props.hasMore}
        >
          {props.items?.map((item: any, index: React.Key | undefined) => (
            <Box key={index} mb={3}>
              <SectionHeadingStyled variant="h5">
                {item?.name}
              </SectionHeadingStyled>
              <Grid container spacing={2}>
                {item?.children.map((card: any) => (
                  <Grid
                    item
                    xs={12}
                    sm={item.children.length < 2 ? 12 : 6}
                    md={
                      item.children.length === 1
                        ? 12
                        : item.children.length === 2
                          ? 6
                          : layoutContext?.chatbotDrawer
                            ? 6
                            : 4
                    }
                    lg={
                      item.children.length === 1
                        ? 12
                        : item.children.length === 2
                          ? 6
                          : item.children.length === 3
                            ? 4
                            : layoutContext?.chatbotDrawer
                              ? 4
                              : 3
                    }
                  >
                    <TreatmentCard
                      slug={card?.slug}
                      mainImage={card?.mainImage}
                      mainVideo={card?.mainVideo}
                      name={card?.name}
                      shortDescription={
                        card?.shortDescription
                      }
                      description={card?.description}
                      content={card?.content}
                      subsections={card?.subSections}
                      bannerImage={card?.bannerImage}
                      childrenLength={item?.children.length}
                      isSingleImage={item?.children?.length === 1}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </InfiniteScroll>
      )}
    </>
  );
};
export default TreatmentsComponent;
