import React, { useMemo } from 'react';
import { Breadcrumbs, Box, Typography, Tooltip } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import { PathToTreatmentFragment } from 'types.d';
import { routes } from 'routers/config';
import { LinkRouterStyled, TypoTreedotUnderline } from 'share/component_css';

type Props = {
  custom?: PathToTreatmentFragment[];
};

export const BreadCrumbComponent: React.FC<Props> = ({ custom }) => {
  const params: { [key: string]: any } = useParams();

  let location = useLocation();

  const urlPath = useMemo(() => {
    const path = location.pathname
      .slice(0, location.pathname.lastIndexOf('/'))
      .replace(params.id, '');
    return path[path.length - 1] === '/' ? path : `${path}/`;
  }, [location.pathname, params.id]);

  const urlForCustom = useMemo(() => {
    let urls: { url: string; label: string }[] = [];
    if (custom) {
      urls = custom.map(item => {
        return {
          url: item.slug
            ? `/treatment/${item.slug}`
            : urlPath === '/treatment/'
            ? `/home/treatment/${item.idPath}`
            : `${urlPath}${item.idPath}`,
          label: item.name,
        };
      });
      if (urlPath === '/assigned-treatments/treatment/') {
        urls = [
          { url: `/assigned-treatments/`, label: 'My Treatment Modules' },
          ...urls,
        ];
        return urls;
      }
      urls = [{ url: `/home/`, label: 'Home' }, ...urls];
    }
    return urls;
  }, [custom, urlPath]);

  //get label by path url
  const getLabel = useMemo(() => {
    const route = routes.find(item => {
      return `${urlPath}` === item.path;
    });

    return route ? route.label : '';
  }, [urlPath]);

  return (
    <>
      {(Object.keys(params).length === 0 || custom!) && (
        <Box display="flex" py={2} alignItems="center">
          {urlForCustom.length === 0 && (
            <Breadcrumbs aria-label="breadcrumb">
              {!urlPath.includes('home') && (
                <Tooltip arrow title={'Home'}>
                  <TypoTreedotUnderline
                    variant="subtitle2"
                    color="textSecondary"
                  >
                    <LinkRouterStyled to={'/home/'}>Home</LinkRouterStyled>
                  </TypoTreedotUnderline>
                </Tooltip>
              )}
              <Typography
                className="font-weight-bold"
                variant="subtitle2"
                color="textPrimary"
              >
                {getLabel}
              </Typography>
            </Breadcrumbs>
          )}
          {urlForCustom.length !== 0 && (
            <Breadcrumbs aria-label="breadcrumb">
              {urlForCustom?.map((item, index) => {
                return index !== urlForCustom.length - 1 ? (
                  <Tooltip key={item.url} arrow title={item.label}>
                    <TypoTreedotUnderline
                      variant="subtitle2"
                      color="textSecondary"
                    >
                      <LinkRouterStyled to={item.url}>
                        {item.label}
                      </LinkRouterStyled>
                    </TypoTreedotUnderline>
                  </Tooltip>
                ) : (
                  <Typography
                    className="font-weight-bold"
                    key={item.url}
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    {item.label}
                  </Typography>
                );
              })}
            </Breadcrumbs>
          )}
        </Box>
      )}
    </>
  );
};
export default BreadCrumbComponent;
