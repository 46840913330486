import React from 'react';
import { SelectProps, TextField } from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';

type Props = {
  className?: string;
  id?: string;
  name: string;
  label?: string;
  small?: boolean;
  value?: string;
  callbackChangeValue?: (event: any, value: string) => void;
  style?: any;
  dense?: boolean;
  disabled?: boolean;
  control?: Control;
  selectProps?: SelectProps;
  error?: boolean;
  required?: boolean;
  defaultValue?: string;
  defaultValueField?: string;
  inputRef?: any;
  tabIndex?: number;
};
export const TextfieldSelect: React.FC<Props> = ({
  id,
  name,
  label,
  small,
  value,
  style,
  callbackChangeValue,
  children,
  dense,
  disabled,
  control,
  defaultValueField,
  selectProps,
  error,
  required,
  defaultValue,
  inputRef,
  tabIndex,
  className,
}) => {
  const handleChangeValue = (event: any, value: string) => {
    if (callbackChangeValue) {
      callbackChangeValue(event, value);
    }
  };

  const renderTextfield = () => {
    return (
      <TextField
        className={className || ''}
        required={required}
        id={id}
        margin={dense ? 'dense' : 'none'}
        name={name}
        style={style}
        label={label}
        disabled={disabled}
        size={small ? 'small' : 'medium'}
        InputLabelProps={{ shrink: true }}
        select
        variant="outlined"
        fullWidth
        value={value}
        defaultValue={defaultValueField}
        onChange={e => handleChangeValue(e, e.target.value)}
        SelectProps={{
          displayEmpty: true,
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          },
        }}
      >
        {children}
      </TextField>
    );
  };
  return (
    <>
      {control ? (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={{
            validate: {
              required: value => {
                if (required && value.length === 0) {
                  return 'This field is required';
                }
                return true;
              },
            },
          }}
          as={
            <TextField
              required={required}
              tabIndex={tabIndex}
              inputRef={inputRef}
              margin={dense ? 'dense' : 'none'}
              size="small"
              fullWidth
              label={label}
              InputLabelProps={{ shrink: true }}
              select
              variant="outlined"
              SelectProps={{
                ...selectProps,
                displayEmpty: true,
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                },
              }}
              error={error}
              helperText={error ? 'This field is required' : ''}
            >
              {children}
            </TextField>
          }
        />
      ) : (
        renderTextfield()
      )}
    </>
  );
};

export default React.memo(TextfieldSelect);
