import React from 'react';
import { useMutationCustom, useUpdateMeClient } from 'hooks';
import { SecondaryEmails } from '../../../gql/local/auth';
import FormAddEmailAlias from '../../user/components/FormAddEmailAlias';

import {
  AddSecondaryEmailMutation,
  AddSecondaryEmailMutationVariables,
  RemoveSecondaryEmailMutation,
  RemoveSecondaryEmailMutationVariables,
  SetPrimaryEmailMutation,
  SetPrimaryEmailMutationVariables,
} from 'types.d';
import {
  ADD_SECONDARY_EMAIL,
  REMOVE_SECONDARY_EMAIL,
  SET_PRIMARY_EMAIL,
} from 'gql';

type Props = {
  listEmailAlias: SecondaryEmails;
};

type Alias = {
  secondaryEmail: string;
};
const EmailAlias: React.FC<Props> = ({ listEmailAlias }) => {
  const { handleUpdateMeClient } = useUpdateMeClient();
  const [secondaryEmailAdd, { loading: loadingAddAlias }] = useMutationCustom<
    AddSecondaryEmailMutation,
    AddSecondaryEmailMutationVariables
  >({
    api: ADD_SECONDARY_EMAIL,
    textSuccess: 'Add email alias successfully',
    callbackSuccess: data => {
      handleUpdateMeClient({
        ...data.addSecondaryEmail,
      });
    },
  });
  const [
    secondaryEmailRemove,
    { loading: loadingRemoveAlias },
  ] = useMutationCustom<
    RemoveSecondaryEmailMutation,
    RemoveSecondaryEmailMutationVariables
  >({
    api: REMOVE_SECONDARY_EMAIL,
    textSuccess: 'Remove email alias successfully',
    callbackSuccess: data => {
      handleUpdateMeClient({
        ...data.removeSecondaryEmail,
      });
    },
  });
  const [
    setPrimaryEmail,
    { loading: loadingSetPrimaryEmail },
  ] = useMutationCustom<
    SetPrimaryEmailMutation,
    SetPrimaryEmailMutationVariables
  >({
    api: SET_PRIMARY_EMAIL,
    textSuccess: 'Set primary email successfully',
    callbackSuccess: data => {
      handleUpdateMeClient({
        ...data.setPrimaryEmail,
      });
    },
  });
  const handleSetPrimaryEmail = (secondaryEmail: string) => {
    setPrimaryEmail({
      variables: {
        params: {
          secondaryEmail: secondaryEmail,
        },
      },
    });
  };
  const handleDeleteEmailAlias = (secondaryEmail: string) => {
    secondaryEmailRemove({
      variables: {
        params: {
          secondaryEmail: secondaryEmail,
        },
      },
    });
  };
  const addEmailAlias = (dataTrim: Alias) => {
    secondaryEmailAdd({
      variables: {
        params: {
          ...dataTrim,
        },
      },
    });
  };

  return (
    <FormAddEmailAlias
      listEmailAlias={listEmailAlias}
      loadingAdd={loadingAddAlias}
      loadingSetPrimary={loadingSetPrimaryEmail}
      loadingDelete={loadingRemoveAlias}
      addEmailAlias={addEmailAlias}
      handleRemoveEmailAlias={handleDeleteEmailAlias}
      handleSetPrimaryEmail={handleSetPrimaryEmail}
    />
  );
};
export default EmailAlias;
