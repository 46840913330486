import React from 'react';
import { Typography, Grid, MenuItem } from '@material-ui/core';
import { TypographyBold } from 'share/component_css';
import {
  AutoCompleteSelect,
  CheckboxList,
  TableTreatmentGroup,
  TextfieldSelect,
} from 'components';
import { AssignBy } from './DialogAddPatient';
import { FormContextValues } from 'react-hook-form';
import {
  GetAssignSurveysQuery,
  GetAssignSurveysQueryVariables,
  GetCommonTreatmentFavoritesQuery,
  GetCommonTreatmentFavoritesQueryVariables,
  GetCommonTreatmentQuery,
  GetCommonTreatmentQueryVariables,
  GetGroupTreatmentAssignQuery,
  GetGroupTreatmentAssignQueryVariables,
  TreatmentType,
} from 'types.d';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  GET_ASSIGN_SURVEYS,
  GET_COMMON_TREATMENT,
  GET_COMMON_TREATMENT_FAVORITES,
  GET_GROUP_TREATMENT_ASSIGN,
} from 'gql';
import { useCustomPermission, useQueryCustom } from 'hooks';
import { FormData } from './Dialogs/DialogUpdateInfo';

type Props = {
  assignBy: AssignBy;
  setAssignBy: React.Dispatch<React.SetStateAction<AssignBy>>;
  methods: FormContextValues<FormData>;
  treatmentSelected: string | undefined;
  isUpdateInfo?: boolean;
};

export const AssignTreatmentUpdate: React.FC<Props> = ({
  methods,
  assignBy,
  setAssignBy,
  treatmentSelected,
  isUpdateInfo,
}) => {
  const { idMe } = useCustomPermission();

  const paramsTreatment = {
    type: [TreatmentType.Treatment],
    sortByOrder: { name: 1 },
  };

  const watchGroup = methods!.watch('group');

  const { data: dataListTreatment } = useQueryCustom<
    GetCommonTreatmentQuery,
    GetCommonTreatmentQueryVariables
  >({
    api: GET_COMMON_TREATMENT,
    fetchPolicy: 'no-cache',
    variables: {
      params: paramsTreatment,
    },
    callbackSuccess: data => {
      methods!.setValue(
        'treatmentIds',
        data?.getTreatments?.nodes?.filter(
          item => item._id === treatmentSelected,
        ),
      );
    },
  });

  const [
    getListTreatmentFavorites,
    { data: dataListTreatmentFavorites },
  ] = useLazyQuery<
    GetCommonTreatmentFavoritesQuery,
    GetCommonTreatmentFavoritesQueryVariables
  >(GET_COMMON_TREATMENT_FAVORITES, {
    fetchPolicy: 'cache-and-network',
  });

  const [getListSurvey, { data: dataListSurvey }] = useLazyQuery<
    GetAssignSurveysQuery,
    GetAssignSurveysQueryVariables
  >(GET_ASSIGN_SURVEYS, {
    fetchPolicy: 'cache-and-network',
  });

  const [loadGroupTreatments, { data: dataGroup }] = useLazyQuery<
    GetGroupTreatmentAssignQuery,
    GetGroupTreatmentAssignQueryVariables
  >(GET_GROUP_TREATMENT_ASSIGN, {
    fetchPolicy: 'cache-and-network',
  });

  const changeTypeAssign = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAssignBy((event.target as HTMLInputElement).value as AssignBy);
    methods!.clearError();
    methods!.setValue('treatmentIds', []);
    if (
      (event.target as HTMLInputElement).value === AssignBy.GROUP &&
      !dataGroup?.getGroupTreatments4Assign.nodes
    ) {
      loadGroupTreatments({
        variables: {
          params: { page: 1, sortByOrder: { name: 1 } },
        },
      });
      return;
    }
    if ((event.target as HTMLInputElement).value === AssignBy.SURVEY) {
      getListSurvey();
      return;
    }
    getListTreatmentFavorites({
      variables: {
        params: { ...paramsTreatment, favorites: idMe },
      },
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <TypographyBold variant="h6">Assign Treatments</TypographyBold>
      </Grid>
      <Grid item xs={3}>
        <TextfieldSelect
          label="By"
          name="by"
          small
          value={assignBy}
          callbackChangeValue={changeTypeAssign}
        >
          <MenuItem value={AssignBy.TREATMENT}>Treatment</MenuItem>
          <MenuItem value={AssignBy.GROUP}>Group</MenuItem>
          {!isUpdateInfo && <MenuItem value={AssignBy.SURVEY}>Survey</MenuItem>}
          <MenuItem value={AssignBy.FAVORITES}>Favorites</MenuItem>
        </TextfieldSelect>
      </Grid>
      <Grid item xs={9}>
        {[AssignBy.TREATMENT, AssignBy.FAVORITES].includes(assignBy) && (
          <AutoCompleteSelect
            callbackRenderOption={(option, selected) => (
              <CheckboxList
                checked={selected}
                option={
                  <Typography variant="subtitle2">{option?.name}</Typography>
                }
              />
            )}
            small
            disablePortal
            required
            control={methods!.control}
            error={!!methods!.errors.treatmentIds}
            multiple
            name="treatmentIds"
            label="Treatments"
            nameOption="name"
            options={
              assignBy === AssignBy.TREATMENT
                ? dataListTreatment?.getTreatments?.nodes || []
                : dataListTreatmentFavorites?.getTreatments?.nodes || []
            }
          />
        )}
        {!isUpdateInfo && assignBy === AssignBy.SURVEY && (
          <AutoCompleteSelect
            callbackRenderOption={(option, selected) => (
              <CheckboxList
                checked={selected}
                option={
                  <Typography variant="subtitle2">{option?.name}</Typography>
                }
              />
            )}
            small
            disablePortal
            required
            control={methods!.control}
            error={!!methods!.errors.surveyIds}
            multiple
            name="surveyIds"
            label="Surveys"
            nameOption="name"
            options={dataListSurvey?.getAssignSurveys?.nodes || []}
          />
        )}
        {assignBy === AssignBy.GROUP && (
          <AutoCompleteSelect
            small
            disablePortal
            required
            control={methods!.control}
            error={!!methods!.errors.group}
            name="group"
            label="Group"
            nameOption="name"
            options={dataGroup?.getGroupTreatments4Assign.nodes || []}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {assignBy === AssignBy.GROUP && (
          <Grid item xs={12}>
            <TableTreatmentGroup group={watchGroup} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default React.memo(AssignTreatmentUpdate);
