import React, {
  useRef,
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core';
import { DialogTitleClose, AutoCompleteSelect } from 'components';
import { useMutationCustom } from 'hooks';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {
  CREATE_PATIENT_STAGE,
  UPDATE_PATIENT_STAGE,
  GET_CONDITIONS_FOR_MODIFY,
  GET_CURRENT_PATIENT_STAGE,
} from 'gql';
import {
  StageEmbeddedFragment,
  CreatePatientStageMutation,
  CreatePatientStageMutationVariables,
  UpdatePatientStageMutation,
  UpdatePatientStageMutationVariables,
  GetConditionsForModifyQuery,
  GetConditionsForModifyQueryVariables,
  ConditionSettingForModifyFragment,
  PatientCondStageFragment,
  GetCurrentPatientStageQuery,
  GetCurrentPatientStageQueryVariables,
} from 'types.d';
import DialogButton from 'components/DialogButton';
import { useLazyQuery } from '@apollo/react-hooks';
import Skeleton from '@material-ui/lab/Skeleton';
import { useForm, Controller } from 'react-hook-form';
import { PatientDetailContext } from 'share/context';
import { useServices } from '../../services';
import ColorDisplay from 'modules/follow_up_types/components/ColorDisplay';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { TypographyItalic } from 'share/component_css';
import { customEvent } from 'modules/google_tag_manager';

type Props = {
  open: boolean;
  closeDialog: () => void;
  callback?: () => void;
  conditionStageSelected?: PatientCondStageFragment;
};

type FormData = {
  stage?: StageEmbeddedFragment;
  conditionType?: ConditionSettingForModifyFragment;
  startDate: string | null;
  endDate: string | null;
};

export const DialogConditionStage: React.FC<Props> = ({
  open,
  closeDialog,
  callback,
  conditionStageSelected,
}) => {
  const patientDetailContext = useContext(PatientDetailContext);

  const [color, setColor] = useState(conditionStageSelected?.color);

  const stageSelected = useRef('');

  const { refetchQueriesCondition } = useServices();
  const {
    control,
    watch,
    errors,
    handleSubmit,
    setValue,
    getValues,
    clearError,
  } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      stage: undefined,
      conditionType: undefined,
      startDate: null,
      endDate: null,
    },
  });

  const watchConditionType = watch('conditionType');

  const watchStage = watch('stage');

  const watchStartDate = watch('startDate');

  const [
    createPatientStage,
    { loading: loadingCreatePatientStage },
  ] = useMutationCustom<
    CreatePatientStageMutation,
    CreatePatientStageMutationVariables
  >({
    refetchQueries: refetchQueriesCondition(),
    api: CREATE_PATIENT_STAGE,
    textSuccess: 'Add condition successfully',
    callbackSuccess: () => {
      customEvent("AddConditionPD", "NAVIGATOR")
      if (callback) {
        callback();
      }
      handleCloseDialog();
    },
  });

  const [
    updatePatientStage,
    { loading: loadingUpdatePatientStage },
  ] = useMutationCustom<
    UpdatePatientStageMutation,
    UpdatePatientStageMutationVariables
  >({
    api: UPDATE_PATIENT_STAGE,
    refetchQueries: refetchQueriesCondition(),
    textSuccess: 'Change stage successfully',
    callbackSuccess: () => {
      customEvent("changeConditionStagePD", "NAVIGATOR")
      if (callback) {
        callback();
      }
      handleCloseDialog();
    },
  });

  const [getConditionType, { data: dataConditionType, loading }] = useLazyQuery<
    GetConditionsForModifyQuery,
    GetConditionsForModifyQueryVariables
  >(GET_CONDITIONS_FOR_MODIFY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      if (conditionStageSelected) {
        const conditionType = dataConditionType?.getListConditions.nodes.find(
          item => item._id === conditionStageSelected.condition?.conditionId,
        );
        setValue('conditionType', conditionType);
        stageSelected.current = conditionStageSelected.stageId;
      }
    },
  });

  const [getStages, { data: dataStages }] = useLazyQuery<
    GetCurrentPatientStageQuery,
    GetCurrentPatientStageQueryVariables
  >(GET_CURRENT_PATIENT_STAGE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });
  const handleSave = handleSubmit(
    ({ stage, conditionType, startDate, endDate }) => {
      const params = {
        patientId: patientDetailContext?.patient.owner?.idOwner,
        conditionId: conditionType?._id,
        color,
        startDate,
        endDate,
      };

      if (conditionStageSelected) {
        let paramsUpdate: UpdatePatientStageMutationVariables['params'] = {
          ...params,
          condStageId: conditionStageSelected._id,
          currentStageId: stageSelected.current,
          startDate: startDate,
        };
        if (stage) {
          paramsUpdate.stageId = stage?._id;
        }
        if (endDate) {
          paramsUpdate.endDate = endDate;
        }
        updatePatientStage({
          variables: {
            params: {
              ...paramsUpdate,
            },
          },
        });
        return;
      }
      createPatientStage({
        variables: {
          params: { ...params, stageId: stage?._id },
        },
      });
    },
  );

  const createCondition = () => {
    if (
      dataStages?.getCurrentPatientStage?.stages?.length !== 0 ||
      conditionStageSelected
    ) {
      handleSave();
      return;
    }
    return false;
  };

  const enterDialog = () => {
    getConditionType({
      variables: {
        params: {
          page: 1,
          sortByOrder: { name: 1 },
        },
      },
    });
  };

  useEffect(() => {
    if (watchConditionType) {
      getStages({
        variables: {
          params: {
            sortByOrder: { name: 1 },
            patientId: patientDetailContext?.patient.owner?.idOwner,
            conditionId: watchConditionType._id,
          },
        },
      });
      setValue('stage', undefined);
      clearError();
    }
    if (conditionStageSelected?.startDate) {
      setValue('startDate', conditionStageSelected?.startDate);
      setValue('endDate', conditionStageSelected?.endDate);
    }
    if (conditionStageSelected) {
      const stageSelecte = conditionStageSelected.condition?.stages?.find(
        item => item.stageId === stageSelected?.current,
      );

      setValue('stage', stageSelecte);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchConditionType?._id]);

  const handleSetColor = useCallback((color: string) => {
    setColor(color);
  }, []);

  const handleCloseDialog = () => {
    patientDetailContext?.handleSetDialogOpen('');
    closeDialog();
  };

  const disableStage = useMemo(() => {
    if (!conditionStageSelected) {
      if (dataStages?.getCurrentPatientStage?.stages?.length === 0) {
        return true;
      }
      return false;
    }
    return true;
  }, [conditionStageSelected, dataStages]);

  return (
    <Dialog onEntering={enterDialog} open={open} maxWidth="sm" fullWidth>
      <DialogTitleClose
        title={conditionStageSelected ? 'Change Stage' : 'Add Condition'}
        onClose={handleCloseDialog}
      />
      <DialogContent style={{ paddingTop: conditionStageSelected ? 0 : 16 }}>
        {loading ? (
          <Skeleton width="100%" height={200} variant="rect" />
        ) : (
          <>
            {conditionStageSelected && (
              <TypographyItalic
                className="mb-16"
                color="textSecondary"
                variant="subtitle2"
              >
                * You need to select another stage to change a stage for this
                condition
              </TypographyItalic>
            )}

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AutoCompleteSelect
                  error={!!errors.conditionType}
                  required
                  disabled={!!conditionStageSelected}
                  options={dataConditionType?.getListConditions.nodes || []}
                  placeholder="Condition"
                  name="conditionType"
                  small
                  control={control}
                  label="Condition"
                  nameOption="name"
                  disablePortal
                />
              </Grid>
              {dataStages?.getCurrentPatientStage?.stages?.length === 0 &&
                !conditionStageSelected && (
                  <TypographyItalic
                    variant="subtitle2"
                    color="textSecondary"
                    className="ml-16"
                  >
                    *All stages of the condition have been used
                  </TypographyItalic>
                )}
              <Grid item xs={12}>
                <AutoCompleteSelect
                  error={!!errors.stage}
                  required
                  disabled={disableStage}
                  options={dataStages?.getCurrentPatientStage?.stages || []}
                  placeholder="Stage"
                  name="stage"
                  small
                  control={control}
                  label="Stage"
                  nameOption="name"
                  disablePortal
                />
              </Grid>
              <>
                <Grid item xs={12} sm={6}>
                  <Controller
                    as={
                      <KeyboardDatePicker
                        placeholder="mm/dd/yyyy"
                        inputVariant="outlined"
                        label="Start Date"
                        format="MM/dd/yyyy"
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        fullWidth
                        value={() => { }}
                        onChange={() => { }}
                        variant="inline"
                        autoOk
                        error={!!errors?.startDate}
                        helperText={
                          errors?.startDate
                            ? errors?.startDate?.types?.message ||
                            errors?.startDate.message
                            : ''
                        }
                        keyboardIcon={<DateRangeIcon />}
                      />
                    }
                    name="startDate"
                    control={control}
                    rules={{
                      validate: {
                        pattern: value => {
                          return (
                            !isNaN(new Date(value).getTime()) ||
                            'Invalid date format'
                          );
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    as={
                      <KeyboardDatePicker
                        placeholder="mm/dd/yyyy"
                        inputVariant="outlined"
                        label="End Date"
                        format="MM/dd/yyyy"
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        fullWidth
                        value={() => { }}
                        onChange={() => { }}
                        variant="inline"
                        autoOk
                        error={!!errors?.endDate}
                        minDate={watchStartDate || Date.now()}
                        helperText={
                          errors?.endDate
                            ? errors?.endDate?.types?.message ||
                            errors?.endDate.message
                            : ''
                        }
                        keyboardIcon={<DateRangeIcon />}
                      />
                    }
                    name="endDate"
                    control={control}
                    rules={{
                      validate: {
                        pattern: value => {
                          return (
                            !isNaN(new Date(value).getTime()) ||
                            'Invalid date format'
                          );
                        },
                        compare: value => {
                          const { startDate } = getValues();
                          if (startDate && value) {
                            return (
                              new Date(value).getTime() >=
                              new Date(startDate as Date).getTime() ||
                              'The end date must be >= start date'
                            );
                          }
                          return true;
                        },
                      },
                    }}
                  />
                </Grid>
              </>
              <Grid item xs={12}>
                <ColorDisplay
                  color={color || ''}
                  setDisplayColor={handleSetColor}
                />
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <DialogButton isCancel onClickButton={handleCloseDialog} />
        <DialogButton
          disabled={
            stageSelected.current === watchStage?._id &&
            !!conditionStageSelected
          }
          loading={loadingCreatePatientStage || loadingUpdatePatientStage}
          onClickButton={createCondition}
        />
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(DialogConditionStage);
