import React, { useContext, useCallback, useEffect, useMemo } from 'react';
import { PaperBorder, TableContainerStyled } from 'share/component_css';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from '@material-ui/core';
import ButtonAttachChecklist from './ButtonAttachChecklist';
import { PatientDetailContext } from 'share/context';
import {
  InfoPagination,
  usePagination,
  useQueryCustom,
  useUpdateMeClient,
} from 'hooks';
import {
  GetPatientChecklistsQuery,
  GetPatientChecklistsQueryVariables,
} from 'types.d';
import { GET_PATIENT_CHECKLISTS } from 'gql';
import { NoDataTable, LoadingTable } from 'components';
import Checklist from './Checklist';

type Props = {};

export const Checklists: React.FC<Props> = () => {
  const patientDetailContext = useContext(PatientDetailContext);

  const { meClient } = useUpdateMeClient();

  const callbackFetchData = (infoPagination: InfoPagination) => {
    fetchMoreChecklist(infoPagination);
  };

  const {
    infoPagination,
    DefaultPagination,
    handleSetInfoPagination,
    defaultPaginationProps,
  } = usePagination(callbackFetchData);

  const {
    data: dataChecklist,
    loading: loadingChecklist,
    fetchMore,
  } = useQueryCustom<
    GetPatientChecklistsQuery,
    GetPatientChecklistsQueryVariables
  >({
    api: GET_PATIENT_CHECKLISTS,
    variables: {
      params: {
        page: 1,
        limit: meClient.setting?.perPage || 5,
        patientId: patientDetailContext?.patient.owner?.idOwner,
      },
    },
  });

  const fetchMoreChecklist = useCallback(
    (infoPagination: InfoPagination) => {
      const { page, limit } = infoPagination;
      fetchMore({
        variables: {
          params: {
            patientId: patientDetailContext?.patient.owner?.idOwner,
            page,
            limit,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            getPatientChecklists: {
              ...prev.getPatientChecklists,
              nodes: fetchMoreResult!.getPatientChecklists!.nodes!,
              meta: fetchMoreResult!.getPatientChecklists!.meta!,
            },
          };
        },
      });
    },
    [fetchMore, patientDetailContext],
  );

  const callbackAfterAttach = useCallback(
    (isDetach?: boolean) => {
      if (isDetach) {
        handleSetInfoPagination(infoPagination, true);
        return;
      }
      handleSetInfoPagination({ page: 1, limit: infoPagination.limit }, true);
    },
    [handleSetInfoPagination, infoPagination],
  );

  //refresh list when adding survey
  useEffect(() => {
    if (patientDetailContext?.isRefreshingChecklist) {
      handleSetInfoPagination({ page: 1, limit: infoPagination.limit }, true);
      patientDetailContext?.setIsRefreshChecklist(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetailContext?.isRefreshingChecklist]);

  const isInactive = useMemo(() => {
    return patientDetailContext?.isInactive;
  }, [patientDetailContext]);

  return (
    <>
      {!isInactive && (
        <ButtonAttachChecklist callbackAfterAttach={callbackAfterAttach} />
      )}
      <Grid item xs={12}>
        <TableContainerStyled component={PaperBorder}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Treatment</TableCell>
                <TableCell>Survey</TableCell>
                <TableCell align={!isInactive ? 'inherit' : 'right'}>
                  Type
                </TableCell>
                {!isInactive && <TableCell align="right">Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loadingChecklist &&
                dataChecklist?.getPatientChecklists?.nodes.length === 0 && (
                  <NoDataTable colSpan={isInactive ? 4 : 5} />
                )}
              {loadingChecklist && (
                <LoadingTable colSpan={isInactive ? 4 : 5} />
              )}
              {!loadingChecklist &&
                dataChecklist?.getPatientChecklists?.nodes?.map(item => {
                  return (
                    <Checklist
                      callbackAfterAttach={callbackAfterAttach}
                      key={item.patientChecklists?.patientChecklistId}
                      item={item}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainerStyled>
        {dataChecklist?.getPatientChecklists?.meta?.total !== 0 && (
          <DefaultPagination
            count={dataChecklist?.getPatientChecklists?.meta?.total || 0}
            {...defaultPaginationProps}
          />
        )}
      </Grid>
    </>
  );
};

export default React.memo(Checklists);
