import React, { useCallback } from 'react';
import { useCheckLogin, useCheckRole, useCustomPermission } from 'hooks';

type Props = {
  roles?: string | string[];
  custom?: boolean;
  displayWhenNotLogin?: boolean;
  type?: string
};

export const CheckRole: React.FC<Props> = ({
  roles,
  children,
  displayWhenNotLogin,
  custom = true,
  type
}) => {
  const { checkRole } = useCheckRole(roles!);
  const { checkUserForMenu } = useCustomPermission();
  const isLogin = useCheckLogin();
  const renderChildren = useCallback(() => {
    if (roles && (roles == 'viewFavoriteTreatments' || roles == 'viewAllTreatments'
      || roles == 'viewPublicTreatments' || roles == 'viewPrivateTreatments'
      || roles == 'viewMyTreatments'
    )) {
      if (checkUserForMenu(type!)) {
        return children;
      }
    }
    if ((displayWhenNotLogin && !isLogin) || (checkRole && custom)) {
      return children;
    }
    return null;
  }, [checkRole, children, custom, displayWhenNotLogin, isLogin]);
  return <>{renderChildren()}</>;
};
export default CheckRole;
