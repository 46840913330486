import React, { useState, useMemo, useCallback } from 'react';
import {
  Tooltip,
  Grid,
  IconButton,
  Fab,
  Box,
  Tabs,
  useMediaQuery,
} from '@material-ui/core';
import { useDrop } from 'react-dnd';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useTheme } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
  TabQuestionStyled,
  TabPatientStyled,
  GridWrapTabStyled,
} from '../../styles';
import IconUnReadQuestion from '../IconUnReadQuestion';
import TabPatientDrag from './TabPatientDrag';
import GridDrop from './GridDrop';
import Stages from '../Stage';
import FollowUp from '../FollowUp';
import Surveys from '../Survey';
import QuestionOnFilter from '../SurveyResponse';
import { Note } from '../Note';
import CommentByPatient from 'modules/comments/components/CommentByPatient';
import Checklists from '../Checklist';
import ConditionStage from '../ConditionStage';
import { PatientFragment } from 'types.d';
import TimeRecord from '../TimeRecord';
import { PaperBorder } from 'share/component_css';
type Props = {
  indexTab: number;
  isOnDialog?: boolean;
  patient: PatientFragment;
  handleCollapse: (isExtend: boolean) => void;
  setIndexTab: (index: number) => void;
};

export const TabsTable: React.FC<Props> = ({
  indexTab,
  isOnDialog,
  patient,
  handleCollapse,
  setIndexTab,
}) => {
  const theme = useTheme();

  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));

  const [numberSplitScreen, setNumberSplitScreen] = useState<number | null>(
    null,
  );

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'card',
    drop: () => ({ name: 'Parent' }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const componentByIndex = useMemo(() => {
    return [
      <Box pt={5.5}>
        <Stages />
      </Box>,
      <Note />,
      <FollowUp />,
      <Surveys />,
      <TimeRecord />,
      <Box pt={5.5}>
        <QuestionOnFilter />
      </Box>,
      <Box pt={5.5}>
        <CommentByPatient isSplitScreen={numberSplitScreen !== null} />
      </Box>,
      <Checklists />,
      <ConditionStage />,
    ];
  }, [numberSplitScreen]);
  const componentSplitByIndex = useMemo(() => {
    return numberSplitScreen === null
      ? null
      : componentByIndex[numberSplitScreen];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberSplitScreen]);

  const isActiveDrop = useMemo(() => {
    return canDrop && isOver;
  }, [canDrop, isOver]);
  const tabByIndex = useMemo(() => {
    let tabInDialog = [
      <Stages />,
      <Box pt={numberSplitScreen !== null ? 3 : 0}>
        <Note />
      </Box>,
      <Box pt={numberSplitScreen !== null ? 3 : 0}>
        <FollowUp />
      </Box>,
      <Box pt={numberSplitScreen !== null ? 3 : 0}>
        <Surveys />
      </Box>,
      <Box pt={numberSplitScreen !== null ? 3 : 0}>
        <TimeRecord />
      </Box>,
      <QuestionOnFilter />,
      <CommentByPatient isSplitScreen={numberSplitScreen !== null} />,
      <Box pt={numberSplitScreen !== null ? 3 : 0}>
        <Checklists />
      </Box>,
      <Box pt={numberSplitScreen !== null ? 3 : 0}>
        <ConditionStage />
      </Box>,
    ];
    if (isOnDialog) {
      tabInDialog.splice(4, 3);
    }
    return tabInDialog;
  }, [isOnDialog, numberSplitScreen]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === numberSplitScreen || newValue === 5) {
      setNumberSplitScreen(null);
    }
    setIndexTab(newValue);
  };

  const splitScreen = useCallback((tabIndex: number) => {
    setNumberSplitScreen(tabIndex);
  }, []);

  return (
    <>
      <Grid item xs={12} container alignItems="center">
        <PaperBorder square style={{ width: isTablet ? '93%' : '96%' }}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={indexTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
          >
            <TabPatientStyled
              label={
                <TabPatientDrag
                  numberSplitScreen={numberSplitScreen}
                  splitScreen={splitScreen}
                  tabIndex={0}
                  label="Stages"
                />
              }
            />
            <TabPatientStyled
              label={
                <TabPatientDrag
                  numberSplitScreen={numberSplitScreen}
                  splitScreen={splitScreen}
                  tabIndex={1}
                  label="Notes"
                />
              }
            />
            <TabPatientStyled
              tabIndex={2}
              label={
                <TabPatientDrag
                  numberSplitScreen={numberSplitScreen}
                  splitScreen={splitScreen}
                  tabIndex={2}
                  label="Tasks"
                />
              }
            />
            <TabPatientStyled
              tabIndex={3}
              label={
                <TabPatientDrag
                  numberSplitScreen={numberSplitScreen}
                  splitScreen={splitScreen}
                  tabIndex={3}
                  label="Surveys"
                />
              }
            />
            {!isOnDialog && (
              <TabPatientStyled
                tabIndex={4}
                label={
                  <TabPatientDrag
                    numberSplitScreen={numberSplitScreen}
                    splitScreen={splitScreen}
                    tabIndex={4}
                    label="Time Reports"
                  />
                }
              />
            )}
            {!isOnDialog && (
              <TabPatientStyled
                tabIndex={5}
                label={
                  <TabPatientDrag
                    numberSplitScreen={numberSplitScreen}
                    splitScreen={splitScreen}
                    tabIndex={5}
                    label="Survey Responses"
                  />
                }
              />
            )}
            {!isOnDialog && patient.owner && (
              <TabQuestionStyled
                label={
                  <TabPatientDrag
                    numberSplitScreen={numberSplitScreen}
                    splitScreen={splitScreen}
                    icon={<IconUnReadQuestion />}
                    tabIndex={6}
                    label="Communication"
                  />
                }
              />
            )}
            <TabPatientStyled
              label={
                <TabPatientDrag
                  numberSplitScreen={numberSplitScreen}
                  splitScreen={splitScreen}
                  tabIndex={7}
                  label="Checklists"
                />
              }
            />
            <TabPatientStyled
              label={
                <TabPatientDrag
                  numberSplitScreen={numberSplitScreen}
                  splitScreen={splitScreen}
                  tabIndex={8}
                  label="Conditions"
                />
              }
            />
          </Tabs>
        </PaperBorder>
        <Tooltip title="Collapse">
          <Fab
            className="ml-auto boxshadow-none"
            size="small"
            color="primary"
            onClick={() => handleCollapse(false)}
          >
            <ExpandLessIcon />
          </Fab>
        </Tooltip>
      </Grid>
      <GridWrapTabStyled item xs={12} ref={drop}>
        {isActiveDrop && <GridDrop />}
        <Grid container spacing={2}>
          <Grid item xs={numberSplitScreen !== null ? 6 : 12}>
            {tabByIndex[indexTab]}
          </Grid>
          {numberSplitScreen !== null && (
            <Grid item xs={6}>
              <PaperBorder>
                <Box position="relative" pt={3} p={2}>
                  <Box position="absolute" top={0} right={0}>
                    <IconButton
                      color="primary"
                      onClick={() => setNumberSplitScreen(null)}
                    >
                      <HighlightOffIcon fontSize="large" />
                    </IconButton>
                  </Box>
                  {componentSplitByIndex}
                </Box>
              </PaperBorder>
            </Grid>
          )}
        </Grid>
      </GridWrapTabStyled>
    </>
  );
};

export default React.memo(TabsTable);
