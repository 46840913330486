import React, { useEffect, useContext } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useToogleDialog } from 'hooks';
import { ButtonLoading } from 'components';
import DialogChecklist from './DialogChecklist';
import { PatientDetailContext } from 'share/context';

type Props = {
  callbackAfterAttach: () => void;
};

export const ButtonDetachChecklist: React.FC<Props> = ({
  callbackAfterAttach,
}) => {
  const [isOpenDialogChecklist, toogleDialogChecklist] = useToogleDialog();

  const patientDetailContext = useContext(PatientDetailContext);

  //open adding checklist dialog from outside (button "+" on checklist tag )
  useEffect(() => {
    //open adding dialog
    if (patientDetailContext?.dialogOpen === 'addChecklistResult') {
      toogleDialogChecklist(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetailContext?.dialogOpen]);

  return (
    <>
      <DialogChecklist
        isAdding
        isOpen={isOpenDialogChecklist}
        toogleOpen={toogleDialogChecklist}
        callbackAfterAttach={callbackAfterAttach}
      />

      <ButtonLoading
        callbackClick={() => toogleDialogChecklist(true)}
        Icon={<AddIcon />}
        className="mb-8"
        text="Add Checklist"
      />
    </>
  );
};

export default React.memo(ButtonDetachChecklist);
