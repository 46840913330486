import React, { useState, useContext } from 'react';
import { TableRow, TableCell, Box, IconButton } from '@material-ui/core';
import { renderTime, totalBilledTime } from 'share/utils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { PatientCptCodeStatus, PatientTimeRecordFragment } from 'types.d';
import TableDetailReport from './TableDetailReport';
import { ButtonDelete } from 'components';
import { useServices } from '../../services';
import { PatientContext } from 'share/context';
import { useTimeRecord } from 'hooks';

type Props = {
  report: PatientTimeRecordFragment;
  index: number;
  callbackDelete?: () => void;
  checkItem?: (
    event: React.ChangeEvent<HTMLInputElement>,
    item: string,
  ) => void;
  checkedList?: string[];
  setCheckedList?: (items: string[]) => void;
};

export const Report: React.FC<Props> = ({
  report,
  index,
  callbackDelete,
  checkItem,
  checkedList,
  setCheckedList,
}) => {
  const [isExpand, setIsExpand] = useState(false);
  const {
    handleDeleteRecord,
    loadingDeleteRecord,
    toogleSaveRecording,
  } = useServices();

  const patientContext = useContext(PatientContext);

  const { isAbleToDeleteModule } = useTimeRecord();

  const handleDelete = (report: PatientTimeRecordFragment, key: string) => {
    if (callbackDelete) {
      handleDeleteRecord(key, () => {
        if (
          report.timeReport &&
          report.timeReport[0].state === PatientCptCodeStatus.Running
        ) {
          patientContext?.handleStop();
          toogleSaveRecording({
            variables: {
              input: null,
            },
          });
        }

        callbackDelete();
      });
    }
  };

  return (
    <>
      <React.Fragment>
        <TableRow key={index}>
          <TableCell>
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={() => setIsExpand(!isExpand)}
                color="primary"
              >
                {isExpand ? <ExpandMoreIcon /> : <ChevronRightIcon />}
              </IconButton>
              {report?.code}
            </Box>
          </TableCell>
          <TableCell>
            {report?.totalSecond
              ? renderTime(
                  checkItem
                    ? totalBilledTime(report?.timeReport) || 0
                    : report?.totalSecond,
                )
              : '0'}
          </TableCell>
          <TableCell align="right">
            <ButtonDelete
              loadingDelete={loadingDeleteRecord}
              deleteItem={key => handleDelete(report, key)}
              id={report?._id}
              disabled={
                !(!checkItem && isAbleToDeleteModule(report.timeReport || []))
              }
            />
          </TableCell>
        </TableRow>
        <TableDetailReport
          report={report}
          isExpand={isExpand}
          isCreatePdf={!!checkItem}
          checkedList={checkedList}
          checkItem={checkItem}
          setCheckedList={setCheckedList}
        />
      </React.Fragment>
    </>
  );
};

export default React.memo(Report);
