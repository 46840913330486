import React, {
  useRef,
  useMemo,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { CommentTreeInterface } from './Comments';
import { Typography, Box, Chip } from '@material-ui/core';
import formatDistance from 'date-fns/formatDistance';
import { TypographyBold } from 'share/component_css';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import {
  useCheckLogin,
  useToogleDialog,
  useCustomPermission,
  useMutationCustom,
} from 'hooks';
import FormAddComment from './FormAddComment';
import { CommentEdgeFragment, UserStatus } from 'types.d';
import { CommentsContext } from 'share/context';
import LoadingComment from './LoadingComment';
import { ButtonDelete } from 'components';
import { DELETE_COMMENT } from 'gql';
import { DeleteCommentMutation, DeleteCommentMutationVariables } from 'types.d';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  message: CommentTreeInterface;
  getMoreReplies: (parent: string) => void;
  hasNextPage: boolean;
  loadingReplies: boolean;
  removeComments: (idComments: string[]) => void;
  sendTo?: string;
};

const useStyles = makeStyles(theme => ({
  unreadComment: {
    animation: '$changeColor 1s linear',
    color: theme.palette.secondary.main,
    fontSize: '1rem',
  },
  '@keyframes changeColor': {
    '0%': {
      color: theme.palette.secondary.dark,
      fontSize: '1.2rem',
    },
    '100%': {
      color: theme.palette.secondary.main,
      fontSize: '1rem',
    },
  },
}));

export const Comment: React.FC<Props> = ({
  message,
  getMoreReplies,
  hasNextPage,
  loadingReplies,
  removeComments,
  sendTo,
}) => {
  const classes = useStyles();

  const [isShowAddComment, setShowAddComment] = useToogleDialog();

  const { isAdmin, idMe } = useCustomPermission();

  const isLogin = useCheckLogin();

  const commentsContext = useContext(CommentsContext);

  const isFirstShowing = useRef(true);

  const boxCommentRef = useRef<HTMLTextAreaElement>(null);

  const [deleteComment, { loading: loadingDelete }] = useMutationCustom<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >({
    api: DELETE_COMMENT,
    textSuccess: 'Delete comment successfully',
  });

  const showAddComment = useCallback(() => {
    setShowAddComment(true);
    if (boxCommentRef.current) {
      boxCommentRef.current.focus();
    }
  }, [setShowAddComment]);
  useEffect(() => {
    if (isShowAddComment && boxCommentRef.current) {
      boxCommentRef.current.focus();
    }
  }, [isShowAddComment]);

  const childrenSort: CommentEdgeFragment[] = useMemo(() => {
    if (message.children) {
      return message.children.sort((a, b) => {
        return (
          new Date(b?.node.createdAt).getTime() -
          new Date(a?.node.createdAt).getTime()
        );
      });
    }
    return [];
  }, [message.children]);
  const checkAbleToReply = useCallback(
    idOwner => {
      if (commentsContext?.contributors) {
        return ![
          commentsContext?.idOwner,
          ...commentsContext?.contributors,
        ].includes(idOwner);
      }
      return commentsContext?.idOwner !== idOwner;
    },
    [commentsContext],
  );

  const handleGetMoreReplies = () => {
    isFirstShowing.current = false;
    getMoreReplies(message.node._id);
  };

  const handleDeleteComment = (key: string) => {
    deleteComment({
      variables: {
        params: {
          commentId: key,
        },
      },
    }).then(() => {
      removeComments(
        Object.is(key, message.node._id)
          ? [key, ...message.children.map(item => item.node._id)]
          : [key],
      );
    });
  };

  const isUnRead = useCallback(
    (comment: CommentEdgeFragment) => {
      if (comment && comment.node) {
        return ![
          ...(comment.node.readBy?.map(item => item.readById) || []),
          comment.node.owner?.idOwner,
        ].includes(idMe);
      }
      return false;
    },
    [idMe],
  );

  const renderStatus = (status: UserStatus | undefined) => {
    if (status && status === UserStatus.Inactive) {
      return (
        <Chip
          className="ml-8"
          size="small"
          variant="default"
          label="Inactive"
        />
      );
    }
  };

  return (
    <Box display="flex" flexDirection="column" my={2}>
      <Box
        borderRadius={8}
        border={1}
        borderColor="grey.300"
        p={1}
        position="relative"
      >
        <Box display="flex" alignItems="center">
          <TypographyBold variant="subtitle2" color="primary">
            {`${message?.node.owner?.firstName} ${message?.node.owner
              ?.middleName || ''} ${message?.node.owner?.lastName}`}
          </TypographyBold>
          {renderStatus(message?.node?.owner?.status)}
          <FiberManualRecordIcon
            className="ml-4 mr-4"
            style={{ fontSize: '0.2rem' }}
          />
          <Typography color="textSecondary" variant="caption">
            {formatDistance(new Date(message.node.createdAt), new Date(), {
              includeSeconds: true,
            })}
          </Typography>
          {isUnRead(message) && (
            <FiberManualRecordIcon
              color="secondary"
              className={`ml-4 mr-4 ${classes.unreadComment}`}
            />
          )}
        </Box>
        <Typography className="mt-4" variant="subtitle2">
          {message.node.message}
        </Typography>
        {isAdmin && (
          <Box position="absolute" top={0} right={0}>
            <ButtonDelete
              loadingDelete={loadingDelete}
              deleteItem={handleDeleteComment}
              id={message.node._id}
              idSelected={message.node._id}
              title="Are you sure you want to delete this item (Include all of the replies inside)"
            />
          </Box>
        )}
      </Box>
      <Box pl={2} display="flex" alignItems="center">
        {checkAbleToReply(message.node.owner?.idOwner) && !isAdmin && isLogin && (
          <>
            <FiberManualRecordIcon
              className="mr-4"
              style={{ fontSize: '0.2rem' }}
            />
            <Typography
              color="textSecondary"
              className="cursor-pointer mr-4 has-underline"
              variant="caption"
              onClick={() => showAddComment()}
            >
              Reply
            </Typography>
          </>
        )}
      </Box>
      {isFirstShowing.current && message.node.numberOfAnswer !== 0 && (
        <Box
          className="cursor-pointer"
          pl={3}
          pt={1}
          display="flex"
          alignItems="center"
        >
          <ModeCommentIcon fontSize="small" className="mr-4" color="disabled" />
          <Typography
            className="has-underline"
            color="textSecondary"
            variant="caption"
            onClick={handleGetMoreReplies}
          >
            View more {message.node.numberOfAnswer} replies
          </Typography>
          {!!message.node.numberOfUnreadAnswer && (
            <FiberManualRecordIcon
              color="secondary"
              style={{ fontSize: '1rem' }}
              className="ml-4 mr-4"
            />
          )}
        </Box>
      )}
      <Box pl={3}>
        {childrenSort.length !== 0 && (
          <>
            {childrenSort.map(sub => {
              return (
                <Box
                  display="flex"
                  key={sub.node._id}
                  flexDirection="column"
                  my={2}
                >
                  <Box
                    borderRadius={8}
                    border={1}
                    borderColor="grey.300"
                    p={1}
                    position="relative"
                  >
                    <Box display="flex" alignItems="center">
                      <TypographyBold variant="subtitle2" color="primary">
                        {`${sub?.node.owner?.firstName} ${sub?.node.owner
                          ?.middleName || ''} ${sub?.node.owner?.lastName}`}
                      </TypographyBold>
                      {renderStatus(message?.node?.owner?.status)}
                      <FiberManualRecordIcon
                        className="ml-4 mr-4"
                        style={{ fontSize: '0.2rem' }}
                      />
                      <Typography color="textSecondary" variant="caption">
                        {formatDistance(
                          new Date(sub.node.createdAt),
                          new Date(),
                          {
                            includeSeconds: true,
                          },
                        )}
                      </Typography>
                      {isUnRead(sub) && (
                        <FiberManualRecordIcon
                          color="secondary"
                          className={`ml-4 mr-4 ${classes.unreadComment}`}
                        />
                      )}
                    </Box>
                    <Typography className="mt-4" variant="subtitle2">
                      {sub.node.message}
                    </Typography>
                    {isAdmin && (
                      <Box position="absolute" top={0} right={0}>
                        <ButtonDelete
                          loadingDelete={loadingDelete}
                          deleteItem={handleDeleteComment}
                          id={sub.node._id}
                          idSelected={sub.node._id}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box pl={2} display="flex" alignItems="center">
                    {commentsContext?.isAbleToComment &&
                      checkAbleToReply(message.node.owner?.idOwner) && (
                        <>
                          <FiberManualRecordIcon
                            className="mr-4"
                            style={{ fontSize: '0.2rem' }}
                          />
                          <Typography
                            color="textSecondary"
                            className="cursor-pointer mr-4 has-underline"
                            variant="caption"
                            onClick={() => showAddComment()}
                          >
                            Reply
                          </Typography>
                        </>
                      )}
                  </Box>
                </Box>
              );
            })}
            {hasNextPage && (
              <Box
                className="cursor-pointer"
                display="flex"
                alignItems="center"
                mt={-1}
              >
                <ModeCommentIcon
                  fontSize="small"
                  className="mr-4"
                  color="disabled"
                />
                <Typography
                  className="has-underline"
                  color="textSecondary"
                  variant="caption"
                  onClick={() => getMoreReplies(message.node._id)}
                >
                  View more replies
                </Typography>
              </Box>
            )}
          </>
        )}
        {loadingReplies && <LoadingComment />}
      </Box>
      {isShowAddComment && (
        <Box pl={3} pt={1}>
          <FormAddComment
            ref={boxCommentRef}
            parent={message.node._id}
            hasCancel
            toogleComment={setShowAddComment}
            sendTo={sendTo}
          />
        </Box>
      )}
    </Box>
  );
};

export default React.memo(Comment);
