import React from 'react';
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from '@material-ui/core';
import {
  TableFullWidthStyled,
  TableCellMaxWidthStyled,
  TypoThreeDotsLine,
  PaperBorder,
} from 'share/component_css';
import { PatientNoteFragment } from 'types.d';
import { formatDate } from 'share/utils';
import { NoDataTable } from 'components';

type Props = {
  notes?: PatientNoteFragment['notes'];
  checkedAll: boolean;
  checkAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkItem: (event: React.ChangeEvent<HTMLInputElement>, item: string) => void;
  checkedList: string[];
};

export const TableNote: React.FC<Props> = ({
  notes,
  checkedAll,
  checkAll,
  checkItem,
  checkedList,
}) => {
  return (
    <>
      <TableContainer component={PaperBorder}>
        <TableFullWidthStyled stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Checkbox
                  checked={checkedAll}
                  onChange={checkAll}
                  color="primary"
                />
              </TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="right">Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!notes || notes?.length === 0) && <NoDataTable colSpan={3} />}
            {notes?.map(item => {
              return (
                <TableRow key={item.idNote}>
                  <TableCell align="left">
                    <Checkbox
                      onChange={e => checkItem(e, item.idNote)}
                      color="primary"
                      checked={checkedList.includes(item.idNote)}
                    />
                  </TableCell>
                  <TableCellMaxWidthStyled maxwidth="200px">
                    <TypoThreeDotsLine variant="subtitle2">
                      {item.description}
                    </TypoThreeDotsLine>
                  </TableCellMaxWidthStyled>
                  <TableCell align="right">
                    {formatDate(item.notedAt, true)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </TableFullWidthStyled>
      </TableContainer>
    </>
  );
};
export default React.memo(TableNote);
