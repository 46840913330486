import React, { useContext } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid, Box } from '@material-ui/core';
import { TreatmentTreeContext } from 'share/context';

type Props = {};

export const LoadingForm: React.FC<Props> = () => {
  const treatmentContext = useContext(TreatmentTreeContext);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Skeleton variant="rect" width="20%" height={50} />
            <Skeleton variant="rect" width="30%" height={50} />
          </Box>
        </Grid>
        {treatmentContext?.typeOfForm.includes('survey') ||
        treatmentContext?.typeOfForm.includes('checklist') ? (
          <>
            <Grid item xs={3}>
              <Skeleton variant="rect" width="100%" height={50} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton variant="rect" width="100%" height={50} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton variant="rect" width="100%" height={50} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" width="100%" height={200} />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Skeleton
              variant="rect"
              width="100%"
              height={50}
              className="mb-16"
            />
            <Skeleton variant="rect" width="100%" height={50} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default React.memo(LoadingForm);
