import React, { useContext, useCallback } from 'react';
import { SubTreatmentDetailFragment, SubTreatmentFragment } from 'types.d';
import {
  MediaStyled,
  TypoThreeDot,
  BoxSubTreatmentStyled,
  LinkRouterStyled,
} from 'share/component_css';
import { Thumbnail } from 'components';
import { CheckHightLightContext } from 'share/context';
import { Box, Tooltip } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { ChipStyled } from '../styles';
import { withStyles } from '@material-ui/core/styles';
import { sortSubTreatment } from 'share/utils';
import PopoverComponent from './PopoverComponent';
import { useCustomPermission } from 'hooks';

type Props = {
  subTreatments?: SubTreatmentDetailFragment[];
};

const TreatmentTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: theme.palette.common.black,
    fontSize: '14px',
    maxHeight: '72px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
}))(Tooltip);

export const CarouselSubTreatment: React.FC<Props> = ({ subTreatments }) => {
  const checkHightLightContext = useContext(CheckHightLightContext);

  const { isAdmin, isNavigator } = useCustomPermission();

  const location = useLocation();

  const checkHightLight = useCallback(
    (treatmentId: string) => {
      if (checkHightLightContext?.stageHightLight) {
        return checkHightLightContext?.stageHightLight.includes(treatmentId);
      }
      return false;
    },
    [checkHightLightContext],
  );

  const linkDetail = (slug: string, id?: string) => {
    if (slug) {
      return `/treatment/${slug}/`;
    }
    const path = location.pathname.split('/').filter(Boolean);
    return `/${path[0]}/treatment/${id}/`;
  };

  return (
    <>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        {sortSubTreatment(subTreatments)?.map((item, index) => {
          return (
            <BoxSubTreatmentStyled
              key={item.subId}
              ishightlight={checkHightLight(item.subId) ? 'true' : 'false'}
              p={checkHightLight(item.subId) ? 0.5 : 2}
              flexGrow={0}
              flexShrink={0}
              borderRadius={8}
              border={checkHightLight(item.subId) ? 2 : 0}
              borderColor="primary.dark"
            >
              <Box>
                <TreatmentTooltip title={item.description || ''}>
                  <LinkRouterStyled
                    to={`${linkDetail(item.slug || '', item.subId)}`}
                  >
                    <MediaStyled>
                      <Thumbnail
                        index={index}
                        mainVideo={item.mainVideo}
                        mainImage={item.mainImage}
                        id={item.subId}
                        thumbnail={item.thumbnail}
                      />
                    </MediaStyled>
                  </LinkRouterStyled>
                </TreatmentTooltip>

                <Box display="flex" alignItems="center" my={1}>
                  <TreatmentTooltip title={item.description || ''}>
                    <TypoThreeDot
                      className={`has-underline font-weight-bold`}
                      variant={checkHightLight(item.subId) ? 'h6' : 'subtitle1'}
                      color={
                        checkHightLight(item.subId) ? 'primary' : 'inherit'
                      }
                    >
                      <LinkRouterStyled
                        to={`${linkDetail(item.slug || '', item.subId)}`}
                      >
                        {item.name}
                      </LinkRouterStyled>
                    </TypoThreeDot>
                  </TreatmentTooltip>
                  {(isAdmin || isNavigator) && (
                    <PopoverComponent
                      treatmentSelected={
                        {
                          ...item,
                          _id: item?.subId,
                          name: item?.name,
                          smsMessage: item?.smsMessage,
                          survey: item?.survey,
                        } as SubTreatmentFragment
                      }
                      qrCode={item?.qrCode as string}
                      urlSharing={item?.urlSharing as string}
                    />
                  )}

                  {item.stageLinkTo && (
                    <ChipStyled
                      color={
                        checkHightLight(item.subId) ? 'primary' : 'default'
                      }
                      size="small"
                      label={item.stageLinkTo.name}
                    />
                  )}
                </Box>
              </Box>
            </BoxSubTreatmentStyled>
          );
        })}
      </Box>
    </>
  );
};

export default React.memo(CarouselSubTreatment);
