import React, { useRef } from 'react';
import { TextField, Box, MenuItem } from '@material-ui/core';
import { GroupButtonFilter } from 'components';
import { useForm, FormContextValues } from 'react-hook-form';
import { FormStyled } from 'share/component_css';
import {
  LIST_CONVERSION_STATUS,
  LIST_CONVERSION_TYPE,
  LIST_FORM_STATUS,
} from 'CONST';
import { TextfieldSelect } from 'components';
import { ConversionStatus, ConversionType, FormStatus } from 'types.d';

type FilterComponentProps = {
  methods: FormContextValues<FormData>;
  setFilledForm: (data: FormData) => void;
};

export type FormData = {
  [key: string]: any;
  status: FormStatus | string;
};

type ExtendFilterComponentProps = FilterComponentProps & {
  callbackClickFilter: (data: FormData) => void;
  callbackReset: (data: FormData) => void;
  loading: boolean;
};

export const useFilterDynamicSurvey = () => {
  const filledForm = useRef<FormData>({
    status: '',
  });

  const methods = useForm<FormData>({
    mode: 'onBlur',
  });

  const setFilledForm = (data: FormData) => {
    filledForm.current = data;
  };

  const filterComponentProps = {
    methods,
    setFilledForm,
  };

  return {
    allValues: filledForm.current,
    filterComponentProps,
    FilterComponent,
  };
};

export const FilterComponent: React.FC<ExtendFilterComponentProps> = ({
  methods,
  callbackClickFilter,
  loading,
  setFilledForm,
}) => {
  const { register, control, handleSubmit, setValue } = methods;

  const handleClick = handleSubmit(data => {
    const filters = {
      ...data,
    };
    callbackClickFilter(filters);
    setFilledForm(filters);
  });

  const resetFilter = () => {
    setValue('status', '');

    const filters = {
      status: '',
    };
    callbackClickFilter(filters);
    setFilledForm(filters);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleClick();
  };

  return (
    <FormStyled onSubmit={e => onSubmit(e)} noValidate>
      <Box
        display="grid"
        gridGap={8}
        gridTemplateColumns="repeat(1, 250px) 100px 100px"
      >
        <TextfieldSelect
          name="status"
          control={control}
          defaultValue=""
          label="Status"
        >
          <MenuItem value="">All</MenuItem>
          {Object.keys(LIST_FORM_STATUS).map(item => {
            return (
              <MenuItem key={item} value={item}>
                {LIST_FORM_STATUS[item]}
              </MenuItem>
            );
          })}
        </TextfieldSelect>

        <GroupButtonFilter
          loading={loading}
          resetFilter={resetFilter}
          callBackClick={onSubmit}
        />
      </Box>
    </FormStyled>
  );
};
