import gql from 'graphql-tag';

export const CREATE_CHART_CONFIG = gql`
  mutation CreateChartConfig($params: CreateChartConfigInput!) {
    createChartConfig(params: $params) {
      _id
    }
  }
`;

export const CREATE_CHART_CONFIGS = gql`
  mutation CreateChartConfigs($params: CreateChartConfigsInput!) {
    createChartConfigs(params: $params) {
      _id
    }
  }
`;

export const UPDATE_CHART_CONFIG = gql`
  mutation UpdateChartConfig($params: UpdateChartConfigInput!) {
    updateChartConfig(params: $params) {
      _id
    }
  }
`;

export const DELETE_CHART_CONFIG = gql`
  mutation DeleteChartConfig($params: IdInputType!) {
    deleteChartConfig(params: $params)
  }
`;
