import { useSnackbar } from 'notistack';
import { useState } from 'react';

export const useCopyToClipBoard = () => {
  const [loadingCopy, setLoadingCopy] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyToClipBoard = (
    text: string,
    hasElemAppend?: string,
    callBackFunction?: Function,
    callBackFunctionParam?: any,
  ) => {
    setLoadingCopy(true);
    setTimeout(() => {
      const el = document.createElement('textarea');
      el.value = text;
      const elem = hasElemAppend
        ? document.getElementById(hasElemAppend)
        : document.body;
      elem!.appendChild(el);
      el.select();
      document.execCommand('copy');
      elem!.removeChild(el);
      setLoadingCopy(false);
      if (callBackFunction) {
        if (callBackFunctionParam) {
          callBackFunction(callBackFunctionParam);
        } else {
          callBackFunction();
        }
      }
      enqueueSnackbar('Copied', { variant: 'success' });
    }, 200);
  };
  return { loadingCopy, handleCopyToClipBoard };
};
