import gql from 'graphql-tag';
import {
  ImageOptionsFragment,
  DotsOptionsFragment,
  QROptionsFragment,
  BackgroundOptionsFragment,
  CornersSquareOptionsFragment,
  CornersDotOptionsFragment,
} from './fragments';

export const GET_HOMEPAGE = gql`
  query GetHomePageConfigByOrganizationInput(
    $params: GetHomePageConfigByOrganizationInput!
  ) {
    getHomePageConfig(params: $params) {
      _id
      title
      isVisible
      organizationHomepageSlug
      logoURL
      oscConfirmation
      organization {
        _id
        name
      }
      dynamicTreatments {
        title
        children {
          title
          treatmentRef {
            _id
            label
            shortDescription
            description
            content
            name
            slug
            type
            mainImage
            bannerImage
            mainVideo
            subSections {
              _id
              shortDescription
              description
              content
              name
              slug
              type
              mainImage
              bannerImage
              mainVideo
            }
          }
          treatments {
            _id
            shortDescription
            description
            content
            name
            slug
            type
            mainImage
            bannerImage
            mainVideo
          }
        }
        dynamicSection
      }
      buttonConfigurations {
        title
        children {
          title
          treatmentRef {
            _id
            label
            shortDescription
            description
            content
            name
            slug
            type
            mainImage
            bannerImage
            mainVideo
          }
          treatments {
            _id
            shortDescription
            description
            content
            name
            slug
            type
            mainImage
            bannerImage
            mainVideo
          }
        }
        dynamicSection
      }
      footerConfigurations {
        title
        children {
          title
          treatmentRef {
            _id
            label
            shortDescription
            description
            content
            name
            slug
            type
            mainImage
            bannerImage
            mainVideo
          }
          treatments {
            _id
            description
            shortDescription
            content
            name
            slug
            type
            mainImage
            bannerImage
            mainVideo
          }
        }
        dynamicSection
      }
      favoriteFlows {
        _id
        name
        encryptionToken
        slug
        urlSharing
        qrCode
      }
    }
  }
`;

export const CREATE_HOMEPAGE = gql`
  # Write your query or mutation here
  mutation CreateHomepageConfigInput($params: CreateHomepageConfigInput!) {
    createHomePageConfig(params: $params) {
      _id
    }
  }
`;

export const CREATE_QR_CODE_SETTINGS = gql`
  mutation CreateQrCodeSettings($params: QRCodeSettingsInput!) {
    createQrCodeSettings(params: $params) {
      _id
    }
  }
`;

export const GET_QR_CODE_SETTINGS = gql`
  query GetQrCodeSettings {
    getQrCodeSettings {
      width
      height
      image
      margin
      qrOptions {
        ...QROptions
      }
      imageOptions {
        ...ImageOptions
      }
      dotsOptions {
        ...DotsOptions
      }
      backgroundOptions {
        ...BackgroundOptions
      }
      cornersSquareOptions {
        ...CornersSquareOptions
      }
      cornersDotOptions {
        ...CornersDotOptions
      }
    }
  }

  ${QROptionsFragment}
  ${ImageOptionsFragment}
  ${DotsOptionsFragment}
  ${BackgroundOptionsFragment}
  ${CornersSquareOptionsFragment}
  ${CornersDotOptionsFragment}
`;
