import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { CHECK_HIGHLIGHT, GET_TREATMENTS_HOME_PAGE } from 'gql';
import { CheckHightLightQuery, CheckHightLightQueryVariables, GetTreatmentsHomePageQuery, GetTreatmentsHomePageQueryVariables, HomePageConfig } from 'types.d';
import { NoDataPage } from 'components';
import { useCustomPermission } from 'hooks';
import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import TreatmentsComponent from 'modules/home/components/Treatments';
import { useLocation } from 'react-router-dom';
import { Loading } from 'modules/home/components/Loading';

type Props = {
  url?: any;
};

export const SectionHeadingStyled = styled(Typography)(({ theme }) => ({
  margin: '10px 0px',
  fontWeight: 500,
}));

export const defaultVariables = {
  limit: 5,
  page: 1,
  isRoot: true,
  isHideOnHomePage: false,
  sortByOrder: { orderSetting: 1 },
};

export const CustomTreatments: React.FC<Props> = ({ url }) => {
  const { idMe } = useCustomPermission();

  const location = useLocation();

  var type = location.pathname.includes('fav') ? HomePageConfig.MyFavoriteTreatments :
    location.pathname.includes('all') ? HomePageConfig.AllTreatment :
      location.pathname.includes('my') ? HomePageConfig.MyTreatment :
        location.pathname.includes('pub') ? HomePageConfig.PublicTreatments :
          HomePageConfig.PrivateTreatments;

  const [hasMore, setHasMore] = useState(false);

  const renderParamByType = useCallback(
    (type: string) => {
      switch (type) {
        case HomePageConfig.PrivateTreatments:
          return { isPrivate: true };
        case HomePageConfig.PublicTreatments:
          return {
            isPrivate: false,
          };
        case HomePageConfig.MyTreatment:
          return { isOwner: true };
        case HomePageConfig.MyFavoriteTreatments:
          return {
            favorites: idMe,
          };
        default:
          return {};
      }
    },
    [idMe],
  );

  const paramsBySetting = useMemo(() => {
    const params: { [key: string]: any } = {
      [HomePageConfig.PrivateTreatments]:
        { isPrivate: true },
      [HomePageConfig.PublicTreatments]:
      {
        isPrivate: false,
      },
      [HomePageConfig.MyTreatment]: {
        isOwner: true,
      },
      [HomePageConfig.MyFavoriteTreatments]: {
        favorites: idMe,
      },
      [HomePageConfig.AllTreatment]: {},
    };
    return params;
  }, [idMe]);

  const { data: dataCheckHighLight } = useQuery<
    CheckHightLightQuery,
    CheckHightLightQueryVariables
  >(CHECK_HIGHLIGHT, {
    fetchPolicy: 'cache-and-network',
  });

  const [treatments, setTreatments] = useState<any>([]);

  const [isReset, setIsReset] = useState(false);

  const [isSearch, setIsSearch] = useState(false);

  const {
    loading: loadingHomePage,
    fetchMore,
    data: dataHomePageTreatment,
  } = useQuery<GetTreatmentsHomePageQuery, GetTreatmentsHomePageQueryVariables>(
    GET_TREATMENTS_HOME_PAGE,
    {
      variables: {
        params: {
          ...defaultVariables,
          ...paramsBySetting[type],
          name: null,
        }
      },
      fetchPolicy: 'cache-and-network',
      onCompleted(data) {
        if (data.getTreatments.nodes.length > 0) {
          if (treatments.length != 0)
            setTreatments([])

          setIsReset(false);
          var item;
          if (treatments.length > 0) {
            const filteredNodes = data.getTreatments.nodes.filter(node => {
              // Check if the node exists in treatments
              const nodeExists = treatments.some((treatment: { children: any[]; }) =>
                treatment.children.some(child => child._id === node._id)
              );
              // Return true if the node does not exist in treatments
              return !nodeExists;
            });
            treatments[0].children.push(...filteredNodes)
          } else {
            item = {
              name: "",
              children: data.getTreatments.nodes
            }
            treatments.push(item)
          }
          setTreatments(treatments)
          setHasMore(
            data!.getTreatments!.meta!.total >
            [...data!.getTreatments!.nodes].length,
          );
        }
        setIsSearch(true);
      },
      onError(error) {
        console.log(error);
      },
    }
  );

  const getMoreData = useCallback(
    (
      dataFilter: { name: string; type?: HomePageConfig },
      page: number,
      reset?: boolean,
    ) => {
      if (reset) {
        setIsReset(true);
      }

      let params: GetTreatmentsHomePageQueryVariables['params'] = {
        ...defaultVariables,
        ...renderParamByType(
          type || HomePageConfig.AllTreatment,
        ),
        name: null,
        page,
      };
      fetchMore({
        variables: {
          params,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            getTreatments: {
              ...prev.getTreatments,
              nodes: reset
                ? [...fetchMoreResult!.getTreatments!.nodes!]
                : [
                  ...prev!.getTreatments!.nodes!,
                  ...fetchMoreResult!.getTreatments!.nodes!,
                ],
              meta: {
                ...prev!.getTreatments!.meta!,
                ...fetchMoreResult!.getTreatments!.meta!,
              },
            },
          };
        },
      });
    },
    [fetchMore, renderParamByType],
  );

  return (
    <>
      {dataHomePageTreatment && (
        <>
          <TreatmentsComponent
            setHasMore={setHasMore}
            getMoreData={getMoreData}
            hasMore={hasMore}
            isReset={isReset}
            items={treatments}
          />
          {!loadingHomePage &&
            dataHomePageTreatment?.getTreatments?.nodes?.length === 0 && (
              <NoDataPage marginTop={18} />
            )}
        </>
      )}
      {loadingHomePage && <Loading />}
    </>
  );
};
export default CustomTreatments;
