import React, { useEffect } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteConfirm from './DeleteConfirm';
import { useToogleDialog } from 'hooks';
import { ButtonLoading } from 'components';

type Props = {
  id: string;
  text?: string;
  deleteItem: (key: string) => void;
  loadingDelete: boolean;
  idSelected?: string;
  isButton?: boolean;
  title?: string;
  className?: string;
  disabled?: boolean;
  content?: string;
  variant?: 'text' | 'outlined' | 'contained';
  notFullWidth?: boolean;
  icon?: React.ReactNode;
  tooltipTitle?: string;
  isInactiveUser?: boolean;
};

export const ButtonDelete: React.FC<Props> = ({
  id,
  deleteItem,
  loadingDelete,
  idSelected,
  isButton,
  title,
  text,
  content,
  disabled,
  variant,
  notFullWidth,
  icon,
  tooltipTitle,
  isInactiveUser,
}) => {
  const [isOpen, toogleDialog] = useToogleDialog();

  useEffect(() => {
    if (!loadingDelete) {
      toogleDialog(false);
    }
  }, [loadingDelete, toogleDialog]);
  return (
    <>
      <DeleteConfirm
        loading={loadingDelete}
        title={title}
        handleConfirm={() => deleteItem(id)}
        open={isOpen}
        toogle={toogleDialog}
        content={content}
        icon={icon}
        buttonText={text ? text : 'Delete'}
      />
      {isButton ? (
        <ButtonLoading
          flexStart
          variant={variant || 'text'}
          Icon={icon || <DeleteIcon />}
          className="mr-8"
          text={text ? text : 'Delete'}
          color="secondary"
          callbackClick={() => toogleDialog(true)}
          fullWidth={!Boolean(notFullWidth)}
          disabled={!!disabled}
        />
      ) : (
        <Tooltip arrow title={tooltipTitle || 'Delete'}>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              toogleDialog(true);
            }}
            aria-label="delete"
            color={isInactiveUser ? 'default' : 'secondary'}
            disabled={!!disabled}
          >
            {icon || <DeleteIcon fontSize="small" />}
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};
export default ButtonDelete;
