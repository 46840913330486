import React from 'react';
import { Fab, Zoom, useScrollTrigger } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { styled } from '@material-ui/core/styles';
type Props = {
  windowElem?: () => Window;
};

const FabStyled = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

export const ScrollToTop: React.FC<Props> = ({ windowElem }) => {
  const trigger = useScrollTrigger({
    target: windowElem ? windowElem() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <>
      <Zoom in={trigger}>
        <FabStyled
          onClick={handleClick}
          color="secondary"
          size="small"
          aria-label="scroll back to top"
        >
          <KeyboardArrowUpIcon />
        </FabStyled>
      </Zoom>
    </>
  );
};
export default React.memo(ScrollToTop);
