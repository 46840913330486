import React, { useEffect } from 'react';
import {
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { useToogleDialog } from 'hooks';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import { ButtonLoading, DialogTitleClose } from 'components';

type Props = {
  handleConfirm: () => void;
  loadingConfirm: boolean;
  title?: string;
  disabled?: boolean;
  content?: string | React.ReactNode;
  tooltipTitle?: string;
  icon: React.ReactNode;
};

export const ButtonConfirm: React.FC<Props> = ({
  handleConfirm,
  loadingConfirm,
  title,
  disabled,
  content,
  tooltipTitle,
  icon,
}) => {
  const [isOpen, toogleDialog] = useToogleDialog();

  useEffect(() => {
    if (!loadingConfirm) {
      toogleDialog(false);
    }
  }, [loadingConfirm, toogleDialog]);
  const handleClose = () => {
    toogleDialog(false);
  };
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} fullWidth={true}>
        <DialogTitleClose onClose={handleClose} title={title} />
        {content && <DialogContent className="pt-0">{content}</DialogContent>}
        <DialogActions className={content ? 'pt-0' : ''}>
          <ButtonLoading
            callbackClick={handleClose}
            text="Cancel"
            Icon={<CancelIcon />}
            color="default"
          />
          <ButtonLoading
            callbackClick={handleConfirm}
            text="OK"
            loading={!!loadingConfirm}
            Icon={<CheckIcon />}
          />
        </DialogActions>
      </Dialog>
      <Tooltip arrow title={tooltipTitle || ''}>
        <IconButton
          onClick={e => {
            e.stopPropagation();
            toogleDialog(true);
          }}
          aria-label="delete"
          color="primary"
        >
          {icon}
        </IconButton>
      </Tooltip>
    </>
  );
};
export default ButtonConfirm;
