import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  Avatar,
  Typography,
} from '@material-ui/core';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import { styled } from '@material-ui/core/styles';
import {
  GpsIcon,
  DialogTitleClose,
  ForgotPasswordForm,
  SuccessLayout,
  FailedLayout,
} from 'components';
import { Link } from '@material-ui/core';
import { useMutationCustom } from 'hooks';
import {
  ResendEmailVerifyMutation,
  ResendEmailVerifyMutationVariables,
  ResendOtpMutation,
  ResendOtpMutationVariables,
} from 'types.d';
import { RESEND_EMAIL_VERIFY, RESEND_OTP } from 'gql';
import { SuccessPhoneLayout } from './SuccessPhoneLayout';

type Props = {
  open?: boolean;
  handleCloseModal?: (status: boolean) => void;
  activeTab: string;
};

export type UserInfo = {
  userId: string;
  phone?: string;
  email?: string;
};

export const AvatarStyled = styled(Avatar)(({ theme }) => ({
  margin: '0 auto',
  backgroundColor: theme.palette.primary.main,
}));

export const DialogContentStyled = styled(DialogContent)(
  ({ isalert }: { isalert: string }) => ({
    '&.MuiDialogContent-root': {
      paddingTop: isalert ? 0 : 16,
    },
  }),
);

export const LinkStyled = styled(Link)({
  cursor: 'pointer',
});

export const ModalLogin: React.FC<Props> = ({
  open,
  handleCloseModal,
  activeTab,
}) => {
  const [tab, setTab] = useState(activeTab);

  const [time, setTime] = useState(30);

  const [isUnverifyAccount, setIsUnverifyAccount] = useState(false);

  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);

  const handleClose = () => {
    handleCloseModal!(false);
  };

  let tabContent = '';
  let tabBody = '';

  switch (tab) {
    case 'login':
      tabContent = 'Please sign in to continue';
      break;
    case 'register':
      tabContent = 'Create an account to join';
      break;
    case 'forgotPassword':
      tabContent = 'Forgot your password?';
      tabBody = `Please enter your Email or Mobile number and we'll send you instructions to reset your password`;
      break;
    case 'confirmEmail':
      break;
    case 'confirmPhone':
      break;
    case 'loginFailed':
      break;
    case 'confirmResetPasswordByEmail':
      break;
    case 'confirmResetPasswordByPhone':
      break;
  }
  const [resendEmailVerify, { loading }] = useMutationCustom<
    ResendEmailVerifyMutation,
    ResendEmailVerifyMutationVariables
  >({
    api: RESEND_EMAIL_VERIFY,
    textSuccess: 'Resend email successfully',
    callbackSuccess: data => {
      if (handleCloseModal) {
        handleCloseModal(false);
      }
    },
  });

  const [resendOTP, { loading: loadingSendOTP }] = useMutationCustom<
    ResendOtpMutation,
    ResendOtpMutationVariables
  >({
    api: RESEND_OTP,
    textSuccess: 'Resend OTP successfully',
    callbackSuccess: data => {
      setTime(30);
      setTab('confirmPhone');
    },
  });

  const resendEmail = () => {
    if (!loading) {
      resendEmailVerify({
        variables: { params: { userId: userInfo?.userId } },
      });
    }
  };

  const handleResendOTP = () => {
    if (!loadingSendOTP) {
      resendOTP({
        variables: { params: { phone: userInfo?.phone as string } },
      });
    }
  };
  return (
    <>
      <Dialog
        open={open!}
        onClose={handleClose}
        onExited={() => setTab('login')}
        maxWidth={tab === 'confirmEmail' || tab === 'register' ? 'sm' : 'xs'}
        fullWidth
      >
        <DialogTitleClose onClose={handleClose} />
        <DialogContentStyled
          isalert={String(!!['confirmEmail', 'loginFailed'].includes(tab))}
        >
          {![
            'confirmEmail',
            'loginFailed',
            'confirmPhone',
            'confirmResetPasswordByEmail',
            'confirmResetPasswordByPhone',
          ].includes(tab) && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <GpsIcon />
              <Typography gutterBottom variant="subtitle2" color="primary">
                TREATMENTS
              </Typography>
              <Typography align="center" variant="h6">
                Welcome to TreatmentGPS
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                color="textSecondary"
              >
                {tabContent}
              </Typography>
              <Typography
                gutterBottom
                variant="caption"
                color="textSecondary"
                align="center"
              >
                {tabBody}
              </Typography>
            </Box>
          )}

          {tab === 'register' && (
            <RegisterForm
              setUserInfo={setUserInfo}
              handleCloseModal={handleCloseModal}
              setTab={setTab}
            />
          )}
          {tab === 'login' && (
            <LoginForm
              setUserInfo={setUserInfo}
              setTab={setTab}
              handleCloseModal={handleCloseModal}
              setIsUnverifyAccount={setIsUnverifyAccount}
            />
          )}
          {tab === 'forgotPassword' && (
            <ForgotPasswordForm
              setTab={setTab}
              handleCloseModal={handleCloseModal}
            />
          )}
          {tab === 'confirmEmail' && (
            <SuccessLayout
              text={
                <>
                  We have sent an email to <strong>{userInfo?.email}</strong>{' '}
                  with a link to verify your account.
                  <br />
                  <br /> If you have not received the verification email, please
                  check your "Spam" folder or{' '}
                  {
                    <LinkStyled onClick={resendEmail}>
                      Click here to resend the email.
                    </LinkStyled>
                  }
                </>
              }
            />
          )}
          {tab === 'confirmPhone' && (
            <SuccessPhoneLayout
              phone={userInfo?.phone}
              resendOTP={handleResendOTP}
              loadingSendOTP={loadingSendOTP}
              time={time}
              setTime={setTime}
              isUnverifyAccount={isUnverifyAccount}
            />
          )}
          {tab === 'loginFailed' && (
            <FailedLayout
              text={
                <>
                  Look like your account has not been verified. Please check
                  your email or{' '}
                  {
                    <LinkStyled onClick={resendEmail}>
                      Click here to resend the email.
                    </LinkStyled>
                  }
                </>
              }
            />
          )}
          {tab === 'confirmResetPasswordByEmail' && (
            <SuccessLayout
              title="Check in your mail!"
              text="We have just emailed you with the instructions to reset your password."
            />
          )}
          {tab === 'confirmResetPasswordByPhone' && (
            <SuccessLayout
              title="Check in your message!"
              text="We have just sent you a message with the instructions to reset your password."
            />
          )}
        </DialogContentStyled>
      </Dialog>
    </>
  );
};
export default ModalLogin;
