import gql from 'graphql-tag';
import { MetaFragment, ProviderSettingFragment } from './fragments';

export const GET_PROVIDER = gql`
  query GetProvider($params: GetProviderInput!) {
    getProvider(params: $params) {
      nodes {
        ...ProviderSetting
      }
      meta {
        ...Meta
      }
    }
  }
  ${MetaFragment}
  ${ProviderSettingFragment}
`;

export const CREATE_PROVIDER = gql`
  mutation CreateProvider($params: CreateProviderInput!) {
    createProvider(params: $params) {
      _id
    }
  }
`;

export const UPDATE_PROVIDER = gql`
  mutation UpdateProvider($params: UpdateProviderInput!) {
    updateProvider(params: $params) {
      ...ProviderSetting
    }
  }
  ${ProviderSettingFragment}
`;

export const DELETE_PROVIDER = gql`
  mutation DeleteProvider($params: DeleteProviderInput!) {
    deleteProvider(params: $params)
  }
`;
