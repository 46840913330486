import React, { useMemo } from 'react';
import { AutoCompleteSelect } from 'components';
import {
  allTimezones,
  useTimezoneSelect,
  ITimezoneOption,
} from 'react-timezone-select';
import { FormContextValues } from 'react-hook-form';

export type TTimezoneOptionExtend = ITimezoneOption & {
  _id: string;
  name: string;
};

type Props = {
  control: any;
  disabled?: boolean;
  optionTimeZoneSelect: TTimezoneOptionExtend[];
  methods: FormContextValues<any>;
};

export const useAutocompleteTimezone = (methods: FormContextValues<any>) => {
  const { options } = useTimezoneSelect({
    labelStyle: 'original',
    timezones: allTimezones,
  });

  const optionTimeZoneSelect = useMemo(() => {
    return (
      options?.map(item => {
        return { ...item, _id: item?.abbrev || '', name: item?.label || '' };
      }) || []
    );
  }, [options]);

  const setDefaultTimezone = (timezoneValue: string) => {
    const orgTime =
      optionTimeZoneSelect.findIndex(item => item.value === timezoneValue) ||
      optionTimeZoneSelect.findIndex(item => item.value === 'Etc/GMT');

    methods.setValue(
      'timezone',
      optionTimeZoneSelect[orgTime] as TTimezoneOptionExtend,
    );
  };

  const defaultPropsComponent = {
    optionTimeZoneSelect,
    methods,
  };
  return {
    defaultPropsComponent,
    optionTimeZoneSelect,
    AutocompleteTimezone,
    setDefaultTimezone,
  };
};

export const AutocompleteTimezone = ({
  control,
  disabled,
  optionTimeZoneSelect,
  methods,
}: Props) => {
  return (
    <AutoCompleteSelect
      disabled={disabled}
      small
      hasMargin
      disablePortal
      name="timezone"
      label="Time Zone"
      control={control}
      nameOption="name"
      required
      error={!!methods.errors['timezone']}
      options={optionTimeZoneSelect}
    />
  );
};

export default React.memo(AutocompleteTimezone);
