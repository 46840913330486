import React from 'react';
import { WrapSurvey } from 'share/component_css';
import * as Survey from 'survey-react';
import { useTheme } from '@material-ui/core/styles';
import { useEffectOnlyOnce } from 'hooks';
type Props = {
  survey: Survey.ReactSurveyModel;
};

export const SurveyDisplay: React.FC<Props> = ({ survey }) => {
  const theme = useTheme();
  useEffectOnlyOnce(() => {
    const mainColor = theme.palette.primary.main;
    //set main color for creator survey
    const defaultThemeColorsSurvey =
      Survey.StylesManager.ThemeColors['default'];
    defaultThemeColorsSurvey['$main-color'] = mainColor;
    Survey.StylesManager.applyTheme();
  });
  return (
    <WrapSurvey>
      <Survey.Survey model={survey} />
    </WrapSurvey>
  );
};

export default React.memo(SurveyDisplay);
