import React, { useEffect, useMemo, useContext, useState, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Divider,
  MenuItem,
  Box,
  Chip,
} from '@material-ui/core';
import { FormContext, useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/react-hooks';
import { RRule } from 'rrule';
import { EventClickArg } from '@fullcalendar/react';
import { DateClickArg } from '@fullcalendar/interaction';
import { useSnackbar } from 'notistack';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import set from 'date-fns/set';
import format from 'date-fns/format';
import {
  DialogTitleClose,
  DialogButton,
  AutoCompleteLoadData,
  TextfieldSelect,
  ToogleExpand,
  ButtonDelete,
  ButtonLoading,
  TableListSuccess,
} from 'components';
import {
  GET_TREATMENT_ADD_SURVEY,
  RESEND_SURVEY,
  GET_TREATMENT_REGIMEN,
  CREATE_CALENDAR_CONFIG_BY_REGIMEN,
  GET_COMMON_DATA,
  GET_PATIENT_FOLLOW_UPS,
  GET_PATIENTS_ASSIGN,
} from 'gql';
import {
  GetTreatmentAddSurveyQuery,
  GetTreatmentAddSurveyQueryVariables,
  TreatmentsForAddSurveyFragment,
  SurveyForAddSurveyFragment,
  ResendSurveyMutation,
  ResendSurveyMutationVariables,
  TreatmentType,
  TreatmentSendStatus,
  CreateCalendarConfigMutation,
  CreateCalendarConfigMutationVariables,
  ScheduleConfigEmbedded,
  DeleteCalendarConfigMutation,
  DeleteCalendarConfigMutationVariables,
  ConfigType,
  ContactMethod,
  TreatmentFragment,
  SubTreatmentFragment,
  GetPatientsAssignQuery,
  GetPatientsAssignQueryVariables,
  PatientOwnerFragment,
  UserStatus,
  GetTreatmentRegimenQuery,
  GetTreatmentRegimenQueryVariables,
  CreateCalendarConfigByRegimenMutation,
  CreateCalendarConfigByRegimenMutationVariables,
  CommonTreatmentFragment,
  GetConfigByGroupQuery,
  GetConfigByGroupQueryVariables,
  TreatmentRegimenFragment,
  GetCommonDataQuery,
  GetCommonDataQueryVariables,
  UserFragment,
  SharedUser,
  ResponseShareTreatmentResFragment,
  PatientAssignFragment,
} from 'types.d';
import {
  defaultChecked,
  capitalizeFirstLetter,
  optionDay,
  optionByNWeekday,
  convertDateWithoutUTC,
  convertFilterPatient,
  renderSearchItem,
  isBotMail,
  renderOptionByWeekDay,
} from 'share/utils';
import {
  Phone,
  useChecked,
  useCustomPopup,
  useEffectOnlyOnce,
  useFormPhone,
  useInputMentions,
  useMutationCustom,
  useOnlyChecked,
  useToogleDialog,
  useUpdateMeClient,
} from 'hooks';
import { PatientDetailContext, TabsDashboardContext } from 'share/context';
import { TypographyBold, TypographyItalic } from 'share/component_css';
import { useServices } from 'modules/patients/services';
import {
  CREATE_CALENDAR_CONFIG,
  DELETE_CALENDAR_CONFIG,
  GET_CONFIG_BY_GROUP,
} from 'gql/server/calendar';
import { OPTIONS_DAY, OPTIONS_RECURRENCE } from 'CONST';
import { addDays, addSeconds } from 'date-fns';
import CheckIcon from '@material-ui/icons/Check';
import TableListGroup from './Components/TableListGroup';
import TableListUser from 'modules/treatment_tree/components/TableListUser';
import AutocompleteLoadGroup from './Components/AutocompleteLoadGroup';
import AutocompleteLoadSurvey from './Components/AutocompleteLoadSurvey';
import { CustomizeSendingTime } from './Components/CustomizeSendingTime';
import { customEvent } from 'modules/google_tag_manager';

type Props = {
  toogleDialog: (status: boolean) => void;
  open: boolean;
  isShareMulti?: boolean;
  close?: () => void;
  callBack?: () => void;
  currentEvent?: EventClickArg;
  dateSelect?: DateClickArg;
  getCalendarConfigs?: () => void;
  treatmentHomepage?: TreatmentFragment | SubTreatmentFragment | null;
  surveySelected?: TreatmentsForAddSurveyFragment & {
    parent: TreatmentsForAddSurveyFragment | null;
    smsMessage?: string | null;
    _id: string;
  };
};

export type DataCalendar = {
  endDate: string | null;
  amount: number;
  after?: number;
  before?: number;
  freq: string;
  repeatWeek: string;
  repeatMonth: string;
};

type TreatmentRegimenType = TreatmentRegimenFragment & {
  name: string;
};

export type FormData = {
  treatment: TreatmentsForAddSurveyFragment;
  treatment4Assign: CommonTreatmentFragment | null;
  patients?: PatientAssignFragment[];
  survey: SurveyForAddSurveyFragment | null;
  sendingDate: string | null;
  endDate: string | null;
  amount: number;
  after?: number;
  before?: number;
  freq: string;
  repeatWeek: string;
  repeatMonth: string;
  sendingTime: string | null;
  group: TreatmentRegimenType;
  treatmentFavorite: CommonTreatmentFragment;
  apptTime: string | null;
};

export const DialogSendSurvey: React.FC<Props> = ({
  toogleDialog,
  open,
  isShareMulti,
  close,
  callBack,
  currentEvent,
  dateSelect,
  getCalendarConfigs,
  treatmentHomepage,
  surveySelected,
}) => {
  const { meClient } = useUpdateMeClient();

  const dateSelected =
    currentEvent && currentEvent.event.start
      ? new Date(currentEvent.event.start)
      : dateSelect?.date || new Date();

  const renderTreatment = useMemo(() => {
    if (currentEvent) {
      return currentEvent?.event.extendedProps.survey?.type ===
        TreatmentType.Survey
        ? currentEvent?.event.extendedProps.survey.parent
        : currentEvent?.event.extendedProps.survey;
    }
    if (treatmentHomepage) {
      return treatmentHomepage;
    }
    if (surveySelected) {
      return surveySelected?.type === TreatmentType.Treatment
        ? surveySelected
        : surveySelected?.parent;
    }
    return undefined;
  }, [currentEvent, surveySelected, treatmentHomepage]);

  const renderSurvey = useMemo(() => {
    if (currentEvent) {
      return currentEvent?.event.extendedProps.survey;
    }
    if (surveySelected && surveySelected?.type === TreatmentType.Survey) {
      return {
        idSurvey: surveySelected?.idTreatment,
        name: surveySelected?.name,
        smsMessage: surveySelected?.smsMessage,
      };
    }
    return undefined;
  }, [currentEvent, surveySelected]);

  const methods = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      amount: 1,
      survey: renderSurvey ? renderSurvey : undefined,
      sendingDate: format(dateSelected, 'MM/dd/yyyy'),
      endDate: null,
      after: undefined,
      before: undefined,
      treatment4Assign: renderTreatment ? renderTreatment : undefined,
      group: undefined,
      sendingTime:
        currentEvent || dateSelect
          ? dateSelected.toISOString()
          : new Date(0, 0, 0, 9, 0).toISOString(),
    },
  });

  const { errors, control, handleSubmit, setValue, watch } = methods;

  const tabsDashboardContext = useContext(TabsDashboardContext);

  const listSuccess = useRef<ResponseShareTreatmentResFragment[] | null>(null);

  const [isOpenDialogSuccess, toogleDialogSuccess] = useToogleDialog();

  const watchTreatment = watch('treatment');

  const watchSurvey = watch('survey');

  const watchGroup = watch('group');

  const watchSendingDate = watch('sendingDate');

  const watchSendingTime = watch('sendingTime');

  const watchPatients = watch('patients');
  const watchTreatment4Assign = watch('treatment4Assign');

  const [isShowExpand, setShowExpand] = useState(
    !!(currentEvent || dateSelect),
  );

  const [optionPatients, setOptionPatients] = useState<
    PatientAssignFragment[] | undefined
  >(undefined);

  const { enqueueSnackbar } = useSnackbar();

  const [isOpenDialog, setOpenDialogCustom] = useToogleDialog();

  const [dataCalendar, setDataCalendar] = useState<DataCalendar | null>(null);

  const [
    getListPatient,
    { loading: loadingPatients, data: dataListPatients },
  ] = useLazyQuery<GetPatientsAssignQuery, GetPatientsAssignQueryVariables>(
    GET_PATIENTS_ASSIGN,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        setOptionPatients(data?.getPatients?.nodes || []);
      },
    },
  );

  const listUserByChecked = useMemo(() => {
    return tabsDashboardContext?.checkedDataList;
  }, [tabsDashboardContext]);

  const checkEmail = useChecked(
    (watchPatients || listUserByChecked)?.filter(
      item => item?.owner?.email && !isBotMail(item?.owner?.email),
    ) || [],
  );

  const checkSMS = useChecked(
    (watchPatients || listUserByChecked)?.filter(
      item => item?.owner?.phones && item?.owner?.phones?.length !== 0,
    ) || [],
  );

  const checkIsCreateNew = useChecked([]);

  const renderDefaultAssign = useMemo(() => {
    if (!currentEvent) {
      return 'survey';
    }
    if (currentEvent?.event?.extendedProps?.treatmentRegimenId) {
      return 'group';
    }
    if (
      currentEvent?.event?.extendedProps?.survey.type ===
      TreatmentType.Treatment
    ) {
      return 'treatment';
    }
    return 'survey';
  }, [currentEvent]);

  const [sendBy, setSendBy] = useState(renderDefaultAssign);

  const { dataCalendarProps, CustomTime } = useCustomPopup();

  const isDisableUpdate = useMemo(() => {
    return !!(currentEvent
      ? (currentEvent?.event.extendedProps.configType === ConfigType.Auto ||
        (currentEvent?.event.extendedProps.treatmentRegimenId
          && !currentEvent?.event.extendedProps.procedure
        )
      ) : isShareMulti && listUserByChecked?.length === 0);
  }, [currentEvent, isShareMulti, listUserByChecked]);

  const messageByGroup = useMemo(() => {
    return watchGroup?.regimenConfig
      ?.filter(item => !item?.task)
      .map(item => {
        return {
          _id: item?.treatment?._id,
          name: item?.treatment?.name,
          smsMessage: item?.treatment?.smsMessage,
          type: item?.treatment?.type || TreatmentType.Treatment,
        } as CommonTreatmentFragment;
      });
  }, [watchGroup]);

  const {
    valueCustom,
    defaulInputMentionsProps,
    InputMentions,
    setValueCustom,
  } = useInputMentions(
    sendBy === 'treatment',
    watchSurvey?.smsMessage || watchTreatment4Assign?.smsMessage || '',
    messageByGroup,
  );

  const patientDetailContext = useContext(PatientDetailContext);

  const {
    checked,
    setChecked,
    defaulCheckedProps,
    OnlyChecked,
  } = useOnlyChecked();

  const { refetchQueryPatientData, variablesFollowUpTag } = useServices();

  const {
    FormPhoneComponent,
    formPhoneComponentProps,
    isDisplayPhone,
    setIsDisplayPhone,
    ButtonUpdatePhone,
  } = useFormPhone();

  const closeDialog = () => {
    toogleDialog(false);
    close && close();
    patientDetailContext?.handleSetDialogOpen('');
  };

  const optionRepeatWhenUpdate = useMemo(() => {
    if (!currentEvent) {
      return;
    }
    const config = currentEvent.event.extendedProps.scheduleConfig;
    switch (true) {
      case !!config.until ||
        (!!config.count && config.freq !== 3) ||
        config.interval > 1:
        return 'custom';
      case config.freq === 3 && !config.count:
        return 'daily';
      case config.freq === 2 && config.byweekday?.length === 1:
        return 'weekly';
      case config.freq === 2 && config.byweekday?.length > 1:
        return 'allWeek';
      case config.freq === 1 && !!config.byNweekDay:
        return 'monthly';
      case config.freq === 0:
        return 'annually';
      default:
        return 'notRepeat';
    }
  }, [currentEvent]);

  const [recurrenceDay, setRecurrenceDay] = useState(
    optionRepeatWhenUpdate || 'notRepeat',
  );

  const closeDialogSetDefault = () => {
    setIsDisplayPhone(false);
  };

  const getDefaultOptionPatient = () => {
    getListPatient({
      variables: {
        params: {
          page: 1,
          limit: isShareMulti ? null : 10,
          order: { createdAt: -1 },
        },
      },
    });
  };

  const getDefaultOptionTreatment = () => {
    loadTreatments({
      variables: {
        params: {
          page: 1,
          limit: 10,
          hasSurvey: sendBy === 'survey' ? true : false,
          type: [TreatmentType.Treatment],
          sortByOrder: { name: 1 },
        },
      },
    });
  };

  useEffectOnlyOnce(() => {
    if (treatmentHomepage || surveySelected) {
      getDefaultOptionPatient();
    }
    if (!currentEvent?.event?.extendedProps?.treatmentRegimenId) {
      getDefaultOptionTreatment();
    }
    if (currentEvent?.event?.extendedProps?.treatmentRegimenId) {
      getTreatmentRegimen({
        variables: { params: currentEvent ? {} : { page: 1, limit: 10 } },
      });
    }
  });

  const backToForm = () => {
    getDefaultOptionPatient();
    toogleDialogSuccess(false);
    checkEmail.resetCheckbox();
    checkSMS.resetCheckbox();
  };

  const [
    getTreatmentRegimen,
    { loading: loadingGetRegimen, data: dataRegimen },
  ] = useLazyQuery<GetTreatmentRegimenQuery, GetTreatmentRegimenQueryVariables>(
    GET_TREATMENT_REGIMEN,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (currentEvent) {
          const regimenSelected = data?.getTreatmentRegimen?.nodes?.find(
            item =>
              item._id ===
              currentEvent?.event?.extendedProps?.treatmentRegimenId,
          );
          if (regimenSelected) {
            setValue('group', {
              ...regimenSelected,
              name: regimenSelected?.regimenName,
            } as TreatmentRegimenType);
            getCalendarConfigGroup({
              variables: {
                params: {
                  _id: currentEvent?.event?.extendedProps?._id,
                },
              },
            });
          }
        }
      },
    },
  );

  const [
    getListTreatments,
    { loading: loadingListTreatments, data: dataListTreatments },
  ] = useLazyQuery<GetCommonDataQuery, GetCommonDataQueryVariables>(
    GET_COMMON_DATA,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        if (currentEvent) {
          const treatmentSelected = data?.getTreatments?.nodes?.find(
            item =>
              item?._id === currentEvent?.event?.extendedProps?.survey?._id,
          );
          if (treatmentSelected) {
            setValue('treatment4Assign', treatmentSelected);
          }
        }
      },
    },
  );

  const [
    loadTreatments,
    { loading: loadingTreatments, data: dataTreatments },
  ] = useLazyQuery<
    GetTreatmentAddSurveyQuery,
    GetTreatmentAddSurveyQueryVariables
  >(GET_TREATMENT_ADD_SURVEY, {
    fetchPolicy: 'no-cache',
  });

  const [getCalendarConfigGroup, { loading: loadingGetGroup }] = useLazyQuery<
    GetConfigByGroupQuery,
    GetConfigByGroupQueryVariables
  >(GET_CONFIG_BY_GROUP, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data?.getConfigByGroup && data?.getConfigByGroup?.length !== 0) {
        setValue(
          'sendingDate',
          format(
            new Date(data?.getConfigByGroup[0]?.regimenStart || ''),
            'MM/dd/yyyy',
          ),
        );
        setValue(
          'sendingTime',
          new Date(data?.getConfigByGroup[0]?.regimenStart || '').toISOString(),
        );
      }
    },
  });

  const optionFreq = useMemo(() => {
    if (
      (dataCalendar && dataCalendar.freq === 'day') ||
      ['notRepeat', 'daily'].includes(recurrenceDay)
    ) {
      return RRule.DAILY;
    }
    if (
      (dataCalendar && dataCalendar.freq === 'week') ||
      ['weekly', 'allWeek'].includes(recurrenceDay)
    ) {
      return RRule.WEEKLY;
    }
    if (
      (dataCalendar && dataCalendar.freq === 'month') ||
      recurrenceDay === 'monthly'
    ) {
      return RRule.MONTHLY;
    }
    if (recurrenceDay === 'annually') {
      return RRule.YEARLY;
    }
    return RRule.YEARLY;
  }, [dataCalendar, recurrenceDay]);

  const paramsCreateEvent = (data: FormData) => {
    const { sendingDate, sendingTime } = data;
    const dtstart = set(new Date(sendingDate as string), {
      hours: getHours(new Date(sendingTime as string)),
      minutes: getMinutes(new Date(sendingTime as string)),
    });

    let defaultParams = {
      dtstart,
      freq: optionFreq,
      interval: dataCalendar?.amount ? +dataCalendar?.amount : 1,
    } as ScheduleConfigEmbedded;
    if (dataCalendar?.endDate) {
      defaultParams.until = new Date(
        convertDateWithoutUTC(dataCalendar?.endDate),
      );
    }
    if (dataCalendar?.after || recurrenceDay === 'notRepeat') {
      defaultParams.count = dataCalendar?.after ? +dataCalendar?.after : 1;
    }
    if (
      recurrenceDay === 'weekly' ||
      recurrenceDay === 'allWeek' ||
      (dataCalendar && dataCalendar.freq === 'week')
    ) {
      defaultParams.byweekday = renderOptionByWeekDay(
        recurrenceDay,
        dataCalendar,
        watchSendingDate,
      );
    }
    if (
      recurrenceDay === 'monthly' ||
      (dataCalendar && dataCalendar.repeatMonth === 'monthly')
    ) {
      defaultParams.byNweekDay = {
        weekday: optionDay(watchSendingDate),
        n: optionByNWeekday(watchSendingDate),
      };
    }
    return defaultParams;
  };

  const refetchQuery =
    !isShareMulti && !treatmentHomepage && !surveySelected
      ? refetchQueryPatientData
      : [];

  const callBackCreate = (
    textSuccess: string,
    data: ResponseShareTreatmentResFragment[],
  ) => {
    if (
      data[0]?.userError &&
      data[0]?.userError?.length > 0 &&
      patientDetailContext
    ) {
      enqueueSnackbar(data[0]?.userError[0], {
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar(textSuccess, { variant: 'success' });
    listSuccess.current = data;
    if (getCalendarConfigs) {
      getCalendarConfigs();
    }
    if (isShareMulti && callBack) {
      callBack();
    }
    if (tabsDashboardContext?.resetCheckbox) {
      tabsDashboardContext?.resetCheckbox();
    }
    if (treatmentHomepage || isShareMulti || surveySelected) {
      toogleDialogSuccess(true);
    }
    close && !surveySelected && close();
    if (!treatmentHomepage && !isShareMulti && !surveySelected) {
      closeDialog();
    }
  };

  const [
    createCalendarConfig,
    { loading, data: dataCreateCalendar },
  ] = useMutationCustom<
    CreateCalendarConfigMutation,
    CreateCalendarConfigMutationVariables
  >({
    api: CREATE_CALENDAR_CONFIG,
    refetchQueries: refetchQuery,
    callbackSuccess: data => {

      customEvent(currentEvent
        ? "updateCalendarConfigPD" : "createCalendarConfigPD", "NAVIGATOR")
      callBackCreate(
        currentEvent
          ? 'Update calendar config successfully'
          : 'Sent successfully',
        data?.createCalendarConfig,
      );
    },
  });

  const [
    createCalendarConfigByRegimen,
    { loading: loadingRegimen, data: dataCalendarGroup },
  ] = useMutationCustom<
    CreateCalendarConfigByRegimenMutation,
    CreateCalendarConfigByRegimenMutationVariables
  >({
    api: CREATE_CALENDAR_CONFIG_BY_REGIMEN,
    refetchQueries: [
      {
        query: GET_PATIENT_FOLLOW_UPS,
        variables: variablesFollowUpTag,
      },
    ],
    callbackSuccess: data => {
      customEvent("CreateCalendarConfigByRegimenPD", "NAVIGATOR")
      callBackCreate(
        'Create calendar config by regimen successfully',
        data?.createCalendarConfigByRegimen,
      );
    },
  });

  const [deleteCalendarConfig, { loading: loadingDelete }] = useMutationCustom<
    DeleteCalendarConfigMutation,
    DeleteCalendarConfigMutationVariables
  >({
    api: DELETE_CALENDAR_CONFIG,
    textSuccess: 'Delete calendar config successfully',
    callbackSuccess: data => {
      customEvent("DeleteCalendarConfigByRegimenPD", "NAVIGATOR")
      if (getCalendarConfigs) {
        getCalendarConfigs();
      }
      closeDialog();
    },
  });

  const [
    resendSMS,
    { loading: loadingSendSMS, data: dataResend },
  ] = useMutationCustom<ResendSurveyMutation, ResendSurveyMutationVariables>({
    refetchQueries: refetchQuery,
    api: RESEND_SURVEY,
    callbackSuccess: data => {
      if (
        data?.resendSurvey[0]?.userError &&
        data?.resendSurvey[0]?.userError?.length !== 0 &&
        !isShareMulti &&
        checked.includes('sendEmail') &&
        (!treatmentHomepage || !surveySelected)
      ) {
        enqueueSnackbar(data?.resendSurvey[0]?.userError, { variant: 'error' });
        return;
      }
      if (
        data?.resendSurvey[0]?.userError &&
        data?.resendSurvey[0]?.userError?.length !== 0 &&
        !isShareMulti &&
        !checked.includes('sendEmail') &&
        (!treatmentHomepage || !surveySelected)
      ) {
        setIsDisplayPhone(true);
        return;
      }
      callBackCreate('Sent Successfully', data?.resendSurvey);
    },
  });

  const handleTextInputTreatment = (value: string) => {
    if (value) {
      const objRule = [
        { 'owner.email': convertFilterPatient(value, 'contain') },
        {
          'owner.phones': {
            $elemMatch: { phone: convertFilterPatient(value, 'contain') },
          },
        },
      ];
      getListPatient({
        variables: {
          params: {
            order: { createdAt: -1 },
            condition: { $or: objRule },
            page: 1,
            limit: 25,
          },
        },
      });
    }
  };

  const handleTextInputGetListTreatments = (value: string) => {
    const params = {
      name: value,
      type: [TreatmentType.Treatment],
      sortByOrder: { name: 1 },
      page: 1,
      limit: 25,
    };
    if (value) {
      getListTreatments({
        variables: {
          params:
            sendBy === 'favorite'
              ? { ...params, favorites: meClient?._id }
              : params,
        },
      });
    }
  };

  const renderDefaultChecked = useMemo(() => {
    let valueCheckedUpdate = [] as string[];
    if (currentEvent && currentEvent.event.extendedProps.sendEmail) {
      valueCheckedUpdate = [...valueCheckedUpdate, ContactMethod.Email];
    }
    if (currentEvent && currentEvent.event.extendedProps.sendSMS) {
      valueCheckedUpdate = [...valueCheckedUpdate, ContactMethod.Sms];
    }
    let defaultValue = defaultChecked(
      currentEvent
        ? valueCheckedUpdate
        : patientDetailContext?.patient?.owner?.patientConfig?.contactConfig
          ?.method || [],
    );
    defaultValue = [...defaultValue, ...checked, 'remind'];
    if (
      (['notRepeat', 'daily', 'weekly', 'allWeek'].includes(recurrenceDay) ||
        //repeat every with day less than 7  freq=3(RRule.WEEKLY)
        (dataCalendar?.freq === 'day' && dataCalendar?.amount <= 7) ||
        //every week: freq=2(RRule.WEEKLY)
        (dataCalendar?.freq === 'week' && dataCalendar?.amount === 1) ||
        //if end date <= one week from send on.
        new Date(dataCalendar?.endDate || '').getTime() <=
        new Date(addDays(new Date(watchSendingDate || ''), 7)).getTime()) &&
      isShowExpand
    ) {
      defaultValue = defaultValue.filter(item => item !== 'remind');
      return defaultValue;
    }
    return defaultValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    recurrenceDay,
    isShowExpand,
    patientDetailContext,
    watchSendingDate,
    dataCalendar,
  ]);

  const onSubmit = (data: FormData) => {
    const scheduleConfig = paramsCreateEvent(data);
    const listUser = (listUserByChecked && listUserByChecked?.length > 0
      ? listUserByChecked
      : data?.patients
    )
      ?.filter(
        item =>
          (item?.owner?.email && !isBotMail(item?.owner?.email)) ||
          (item?.owner?.phones?.length && item?.owner?.phones?.length > 0),
      )
      ?.map(item => {
        return {
          email: item?.owner?.email,
          id: item._id,
          phone:
            item?.owner?.phones && item?.owner?.phones?.length !== 0
              ? item?.owner?.phones[0]?.phone
              : null,
          sendSMS: checkSMS.checkedList?.includes(item?._id),
          sendEmail: checkEmail.checkedList?.includes(item?._id),
        };
      }) as SharedUser[];

    if (
      (treatmentHomepage || isShareMulti || surveySelected) &&
      listUser?.filter(item => !item.sendEmail && !item.sendSMS)?.length !== 0
    ) {
      enqueueSnackbar(
        'Each patient need at least one method to assign treatment',
        { variant: 'error' },
      );
      return;
    }
    const defaultParams = {
      treatmentId:
        data?.survey?.idSurvey ||
        data?.treatment4Assign?._id ||
        currentEvent?.event?.id,
      patientInfo: listUser || [
        {
          id: patientDetailContext?.patient.owner?.idOwner,
          email: patientDetailContext?.patient.owner?.email,
          phone: (patientDetailContext?.patient?.owner
            ?.phones as Phone[])?.find(item => item?.default)?.phone,
          sendSMS: checked.includes('sendSMS'),
          sendEmail: checked.includes('sendEmail'),
        } as SharedUser,
      ],
      smsMessage: valueCustom,
    };
    if (defaultParams?.patientInfo?.length === 0) {
      enqueueSnackbar('Invalid patient list', { variant: 'error' });
      return;
    }
    if (sendBy === 'group') {
      console.log(data)
      createCalendarConfigByRegimen({
        variables: {
          params: {
            apptTime: currentEvent?.event?.extendedProps?.apptTime || null,
            procedure: currentEvent?.event?.extendedProps?.procedure || null,
            patientInfo: defaultParams.patientInfo,
            treatmentRegimenId: data?.group?._id,
            smsMessage: valueCustom,
            startDate: isShowExpand
              ? new Date(scheduleConfig?.dtstart).toISOString()
              : addSeconds(new Date(), 50).toISOString(),
            localTimeZone: -(new Date().getTimezoneOffset() / 60),
          },
        },
      });
      return;
    }
    if (
      ['favorite', 'treatment'].includes(sendBy) &&
      data?.treatment4Assign &&
      !isShowExpand
    ) {
      createCalendarConfig({
        variables: {
          params: {
            ...defaultParams,
            scheduleConfig: !isShowExpand
              ? {
                dtstart: addSeconds(new Date(), 50).toISOString(),
                freq: 3,
                count: 1,
                interval: 1,
              }
              : scheduleConfig,
            resend48h: checked?.includes('remind'),
          },
        },
      });
      return;
    }
    if (currentEvent) {
      createCalendarConfig({
        variables: {
          params: {
            ...defaultParams,
            scheduleConfig,
            resend48h: checked?.includes('remind'),
          },
        },
      });
      return;
    }
    if (isShowExpand) {
      createCalendarConfig({
        variables: {
          params: {
            ...defaultParams,
            scheduleConfig,
            resend48h: checked?.includes('remind'),
          },
        },
      });
      return;
    }
    resendSMS({
      variables: {
        params: {
          surveyId: watchSurvey?.idSurvey,
          patientInfo: defaultParams.patientInfo,
          smsMessage: valueCustom,
          treatmentSendStatus: TreatmentSendStatus.Send,
        },
      },
    });
  };

  const handleSave = handleSubmit(data => {
    if (
      !checked.includes('sendSMS') &&
      !checked.includes('sendEmail') &&
      !currentEvent &&
      !treatmentHomepage &&
      !surveySelected &&
      !isShareMulti
    ) {
      enqueueSnackbar('Need a method to contact user', {
        variant: 'error',
      });
      return;
    }
    if (
      !patientDetailContext?.patient?.owner?.phones?.find(
        item => item.default,
      ) &&
      checked.includes('sendSMS') &&
      !isShareMulti &&
      !treatmentHomepage &&
      !surveySelected
    ) {
      setIsDisplayPhone(true);
      return;
    }

    onSubmit(data);
  });

  const enterDialog = () => {
    if (currentEvent) {
      const config = currentEvent.event.extendedProps.scheduleConfig;
      setValueCustom(currentEvent.event.extendedProps.smsMessage || '');
      if (recurrenceDay === 'custom') {
        setDataCalendar({
          amount: config.interval,
          endDate: config.until,
          after: config.count,
          freq: OPTIONS_RECURRENCE[config.freq].value,
          repeatWeek:
            config.byweekday && config.byweekday?.length !== 0
              ? OPTIONS_DAY[config.byweekday[0]].value
              : '',
          repeatMonth: config.byNweekDay ? 'monthly' : 'daily',
        });
      }
    }
    if (currentEvent || dateSelect) {
      setValue('repeat', optionRepeatWhenUpdate);
    }
  };

  useEffect(() => {
    setChecked([...renderDefaultChecked]);
  }, [renderDefaultChecked, setChecked]);

  useEffect(() => {
    setValue('survey', null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, watchTreatment]);

  const handleChangeTypeBy = (event: any, value: string) => {
    setSendBy(value);
    if (['favorite', 'treatment'].includes(value) && !currentEvent) {
      setValue('treatment4Assign', null);
      const params = {
        page: 1,
        type: [TreatmentType.Treatment],
        sortByOrder: { name: 1 },
        limit: 25,
      };
      getListTreatments({
        variables: {
          params:
            value === 'favorite'
              ? { ...params, favorites: meClient?._id }
              : params,
        },
      });
    }
    if (value === 'group' && !currentEvent) {
      getTreatmentRegimen({
        variables: { params: currentEvent ? {} : { page: 1, limit: 10 } },
      });
    }
  };

  const AdvanceBox = () => {
    return (
      <Box
        className="cursor-pointer"
        display="flex"
        alignItems="center"
        onClick={() => setShowExpand(!isShowExpand)}
      >
        <TypographyBold variant="subtitle1">
          Customize sending time
        </TypographyBold>
        <ToogleExpand isExpand={isShowExpand} fontSize="small" />
      </Box>
    );
  };

  const handleChangeType = (value: string) => {
    if (errors.sendingDate) {
      enqueueSnackbar('Invalid submission time', { variant: 'error' });
      return;
    }
    if (value !== 'custom') {
      setDataCalendar(null);
    }
    setRecurrenceDay(value);
    if (value === 'custom') {
      setOpenDialogCustom(true);
    }
  };

  const deleteItem = (_id: string) => {
    deleteCalendarConfig({
      variables: {
        params: { _id },
      },
    });
  };

  return (
    <>
      {isDisplayPhone && (
        <Dialog open={isDisplayPhone} onClose={closeDialogSetDefault} fullWidth>
          <DialogTitleClose
            title="Set Default phone"
            onClose={closeDialogSetDefault}
          />
          <DialogContent>
            <Grid container spacing={2}>
              <FormPhoneComponent {...formPhoneComponentProps} />
            </Grid>
          </DialogContent>
          <DialogActions>
            <ButtonUpdatePhone {...formPhoneComponentProps} />
          </DialogActions>
        </Dialog>
      )}
      <CustomTime
        isOpenDialog={isOpenDialog}
        setOpenDialogCustom={setOpenDialogCustom}
        sendOn={methods.watch('sendingDate')}
        setDataCalendar={setDataCalendar}
        dataCalendar={dataCalendar}
        setRecurrenceDay={setRecurrenceDay}
        isUpdate={!!currentEvent}
        {...dataCalendarProps}
      />
      <FormContext {...methods}>
        <Dialog
          open={open}
          onEntering={enterDialog}
          onClose={closeDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitleClose
            title={currentEvent ? 'Update Sending Schedule' : 'Send'}
            onClose={closeDialog}
          />
          {isShareMulti && listUserByChecked?.length === 0 && (
            <TypographyItalic
              variant="subtitle2"
              color="textSecondary"
              className="ml-16"
            >
              * You should select at least one patient to send survey.{' '}
            </TypographyItalic>
          )}

          <DialogContent
            style={{ paddingBottom: checked.includes('sendSMS') ? 16 : 0 }}
          >
            {isOpenDialogSuccess &&
              (dataCreateCalendar?.createCalendarConfig ||
                dataCalendarGroup?.createCalendarConfigByRegimen ||
                dataResend?.resendSurvey) && (
                <Grid item xs={12}>
                  <TableListSuccess list={listSuccess.current || []} />
                </Grid>
              )}
            {!isOpenDialogSuccess && (
              <Grid container spacing={2}>
                <>
                  {(treatmentHomepage || surveySelected) && (
                    <Grid item xs={12}>
                      <AutoCompleteLoadData
                        label="Search Patient"
                        placeholder="Email or Mobile"
                        nameOption="name"
                        loadList={loadingPatients}
                        required
                        multiple
                        small
                        name="patients"
                        options={optionPatients || []}
                        disableCloseOnSelect
                        limitTags={3}
                        control={methods!.control}
                        methods={methods}
                        error={!!errors.patients}
                        callbackHandleText={value =>
                          handleTextInputTreatment(value)
                        }
                        getOptionDisabled={dataListPatients?.getPatients?.nodes?.filter(
                          item => item.owner?.status === UserStatus.Inactive,
                        )}
                        callbackRenderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              key={option._id}
                              className="m-4"
                              label={`${option?.owner?.firstName} ${option?.owner?.lastName}`}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        callbackRenderOption={(option, selected) => {
                          return renderSearchItem(
                            option?.owner as PatientOwnerFragment,
                            selected,
                          );
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={4}>
                    <TextfieldSelect
                      id="by"
                      name="by"
                      label="By"
                      small
                      callbackChangeValue={handleChangeTypeBy}
                      value={sendBy}
                      disabled={!!currentEvent}
                    >
                      {(isShareMulti
                        ? ['survey', 'group']
                        : ['survey', 'treatment', 'favorite', 'group']
                      ).map(item => {
                        return (
                          <MenuItem key={item} value={item}>
                            {capitalizeFirstLetter(item)}
                          </MenuItem>
                        );
                      })}
                    </TextfieldSelect>
                  </Grid>

                  {(!currentEvent
                    ? sendBy === 'survey'
                    : !currentEvent?.event?.extendedProps?.treatmentRegimenId &&
                    currentEvent?.event?.extendedProps?.survey.type ===
                    TreatmentType.Survey) && (
                      <AutocompleteLoadSurvey
                        methods={methods}
                        loadingTreatments={loadingTreatments}
                        renderTreatment={renderTreatment}
                        dataTreatments={
                          dataTreatments?.getTreatments?.nodes || []
                        }
                        loadTreatments={loadTreatments}
                        sendBy={sendBy}
                        currentEvent={currentEvent}
                      />
                    )}
                  {(!currentEvent
                    ? ['treatment', 'favorite'].includes(sendBy)
                    : !currentEvent?.event?.extendedProps?.treatmentRegimenId &&
                    currentEvent?.event?.extendedProps?.survey.type ===
                    TreatmentType.Treatment) && (
                      <Grid item xs={12} sm={4}>
                        <AutoCompleteLoadData
                          hasOptionsTextNull
                          label="Treatment"
                          nameOption="name"
                          required
                          loadList={loadingListTreatments}
                          options={dataListTreatments?.getTreatments?.nodes || []}
                          small
                          defaultValue={null}
                          name="treatment4Assign"
                          control={control}
                          methods={methods}
                          error={!!errors.treatment4Assign}
                          callbackHandleText={value => {
                            handleTextInputGetListTreatments(value);
                          }}
                          disabled={!!currentEvent}
                        />
                      </Grid>
                    )}
                  {(sendBy === 'group' ||
                    currentEvent?.event?.extendedProps?.treatmentRegimenId) && (
                      <Grid item xs={12} sm={4}>
                        <AutocompleteLoadGroup
                          methods={methods}
                          loadingGetRegimen={loadingGetRegimen}
                          dataRegimen={
                            dataRegimen?.getTreatmentRegimen?.nodes || []
                          }
                          getTreatmentRegimen={getTreatmentRegimen}
                          isDisableUpdate={isDisableUpdate}
                        />
                      </Grid>
                    )}
                  {!treatmentHomepage && !isShareMulti && !surveySelected && (
                    <Grid item xs={12} style={{ paddingLeft: 0 }}>
                      <OnlyChecked
                        value={[
                          { value: 'sendEmail', label: 'Send Email' },
                          { value: 'sendSMS', label: 'Send SMS' },
                        ]}
                        disabled={isDisableUpdate}
                        {...defaulCheckedProps}
                      />
                      {isShowExpand && sendBy !== 'group' && !watchGroup && (
                        <OnlyChecked
                          value={[
                            { value: 'remind', label: 'Resend after 48 hours' },
                          ]}
                          disabled
                          {...defaulCheckedProps}
                        />
                      )}
                    </Grid>
                  )}
                  {(treatmentHomepage || isShareMulti || surveySelected) && (
                    <Grid item xs={12}>
                      <TableListUser
                        checkEmail={checkEmail}
                        checkSMS={checkSMS}
                        checkIsCreateNew={checkIsCreateNew}
                        listEmail={
                          (watchPatients
                            ? watchPatients
                            : listUserByChecked
                          )?.map(item => {
                            return {
                              _id: item?._id,
                              email: item?.owner?.email,
                              phones: item?.owner?.phones,
                            } as UserFragment;
                          }) || []
                        }
                      />
                    </Grid>
                  )}
                  {(checked.includes('sendSMS') ||
                    checkSMS?.checkedList?.length > 0) && (
                      <InputMentions {...defaulInputMentionsProps} />
                    )}
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <AdvanceBox />
                  </Grid>
                </>
                {isShowExpand && (
                  <CustomizeSendingTime
                    methods={methods}
                    currentEvent={currentEvent}
                    sendBy={sendBy}
                    isDisableUpdate={isDisableUpdate}
                    handleChangeType={handleChangeType}
                    recurrenceDay={recurrenceDay}
                    dataCalendar={dataCalendar}
                    optionFreq={optionFreq}
                  />
                )}
                {watchGroup &&
                  (sendBy === 'group' ||
                    currentEvent?.event?.extendedProps?.treatmentRegimenId) && (
                    <Grid item xs={12}>
                      <TableListGroup
                        sendBefore={currentEvent?.event?.extendedProps?.apptTime ? true : false}
                        loadingGetGroup={loadingGetGroup}
                        currentEvent={currentEvent}
                        // methods={methods}
                        isShowExpand={isShowExpand}
                        watchGroup={watchGroup}
                        watchSendingDate={watchSendingDate}
                        watchSendingTime={watchSendingTime}
                      />
                    </Grid>
                  )}
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            {isOpenDialogSuccess ? (
              <>
                <ButtonLoading
                  callbackClick={backToForm}
                  color="default"
                  text="Back"
                  Icon={<ArrowBackIcon />}
                />
                <ButtonLoading
                  callbackClick={closeDialog}
                  color="primary"
                  text="OK"
                  Icon={<CheckIcon />}
                />
              </>
            ) : (
              <>
                {currentEvent && (
                  <Box mr="auto">
                    <ButtonDelete
                      loadingDelete={loadingDelete}
                      deleteItem={deleteItem}
                      id={currentEvent?.event.extendedProps._id}
                      isButton
                      variant="outlined"
                    />
                  </Box>
                )}
                <DialogButton isCancel onClickButton={closeDialog} />
                <ButtonLoading
                  text="Save or Send now"
                  disabled={isDisableUpdate}
                  loading={loadingSendSMS || loading || loadingRegimen}
                  callbackClick={handleSave}
                  Icon={<CheckIcon />}
                />
              </>
            )}
          </DialogActions>
        </Dialog>
      </FormContext>
    </>
  );
};

export default React.memo(DialogSendSurvey);
