import React from 'react';
import { Grid, Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { PaperStyled } from 'modules/treatments/styles';
import { MediaStyled } from 'share/component_css';

type Props = {
  marginTop?: any;
};

export const LoadingTreatment: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <PaperStyled style={{ marginTop: props.marginTop }}>
        <Grid container spacing={2}>
          <Grid xs={12} sm={7} md={8} item>
            <Skeleton height={matches ? '20%' : '50%'} width="80%" />
            <Box>
              <Skeleton height={matches ? '5%' : '15%'} width="70%" />
            </Box>
            <Box marginBottom={2}>
              <Skeleton height={matches ? '20%' : '55%'} />
            </Box>
          </Grid>
          <Grid xs={12} sm={5} md={4} item>
            <MediaStyled>
              <Box
                position="absolute"
                width="100%"
                height="100%"
                borderRadius={10}
                overflow="hidden"
                top={0}
                left={0}
              >
                <Skeleton height="100%" variant="rect" />
              </Box>
            </MediaStyled>
          </Grid>
        </Grid>
      </PaperStyled>
    </>
  );
};

export default LoadingTreatment;
