import React, { useState, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_PUBLIC_TREATMENTS } from 'gql';
import {
  GetPublicTreatmentsQuery,
  GetPublicTreatmentsQueryVariables,
  HomePageConfig,
} from 'types.d';
import { TreatmentsComponent } from 'modules/treatments/components/Treatments';
import { LoadingTreatment, NoDataPage } from 'components';

type Props = {};

const defaultVariables = {
  limit: 5,
  page: 1,
  isHideOnHomePage: false,
};

export const HomeUser: React.FC<Props> = () => {
  const [hasMore, setHasMore] = useState(false);

  const [isReset, setIsReset] = useState(false);

  const {
    loading: loadingData,
    fetchMore,
    data: dataPublicTreatment,
  } = useQuery<GetPublicTreatmentsQuery, GetPublicTreatmentsQueryVariables>(
    GET_PUBLIC_TREATMENTS,
    {
      variables: {
        params: {
          ...defaultVariables,
          isRoot: true,
          name: null,
        },
      },
      fetchPolicy: 'cache-and-network',
      onCompleted(data) {
        setIsReset(false);
        setHasMore(
          data!.getPublicTreatments!.meta!.total >
            [...data!.getPublicTreatments!.nodes].length,
        );
      },
    },
  );

  const getMoreData = useCallback(
    (
      dataFilter: { name: string; type?: HomePageConfig },
      page: number,
      reset?: boolean,
    ) => {
      if (reset) {
        setIsReset(true);
      }
      let params: GetPublicTreatmentsQueryVariables['params'] = {
        ...defaultVariables,
        name: dataFilter.name || null,
        page,
      };
      if (!dataFilter.name) {
        params = { ...params, isRoot: true };
      }

      fetchMore({
        variables: {
          params,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            getPublicTreatments: {
              ...prev.getPublicTreatments,
              nodes: reset
                ? [...fetchMoreResult!.getPublicTreatments!.nodes!]
                : [
                    ...prev!.getPublicTreatments!.nodes!,
                    ...fetchMoreResult!.getPublicTreatments!.nodes!,
                  ],
              meta: {
                ...prev!.getPublicTreatments!.meta!,
                ...fetchMoreResult!.getPublicTreatments!.meta!,
              },
            },
          };
        },
      });
    },
    [fetchMore],
  );

  return (
    <>
      {dataPublicTreatment && (
        <>
          <TreatmentsComponent
            setHasMore={setHasMore}
            items={dataPublicTreatment?.getPublicTreatments?.nodes}
            getMoreData={getMoreData}
            hasMore={hasMore}
            title="Home"
            isReset={isReset}
          />
          {!loadingData &&
            dataPublicTreatment?.getPublicTreatments?.nodes?.length === 0 && (
              <NoDataPage marginTop={18} />
            )}
        </>
      )}
      {loadingData && <LoadingTreatment />}
    </>
  );
};
export default HomeUser;
