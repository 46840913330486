import gql from 'graphql-tag';

export const ChatBot_Query = gql`
  query Chatbot($params: ChatBotQueryInput!) {
    chatbot(params: $params) {
      relatedtreatments {
        name
        slug
        shortDescription
        mainImage
        mainVideo
        survey {
          _id
          name
          slug
          surveyData
        }
      }
      answer
      responseId
      contexts
      isForm
      formName
    }
  }
`;

export const ChatBot_History = gql`
  query History($params: QueryRetrieveInput!) {
    history(params: $params) {
      role
      content
      responseId
      like
      comment
      relatedArticles {
        name
        slug
        shortDescription
        mainImage
        mainVideo
        survey {
          _id
          name
          slug
          surveyData
        }
      }
    }
  }
`;

export const ChatBot_LogKey = gql`
  query ChatBotHistoryKey {
    getHistoryKey
  }
`;

export const Rawgpt_Query = gql`
  query RawGPT($params: ChatBotQueryInput!) {
    rawgpt(params: $params)
  }
`;

export const Chatbot_Feedback = gql`
  mutation addLikeOrDislike($params: ChatbotLikeDislikeInput!) {
    addLikeOrDislike(params: $params)
  }
`;

export const Chatbot_Update_Prompt = gql`
  mutation updatePrompt($params: CreateChatbotConfigInput!) {
    updatePrompt(params: $params) {
      prompt
    }
  }
`;

export const Chatbot_Prompt = gql`
  query chatbotPrompt($params: GetChatbotConfigInput!) {
    chatbotPrompt(params: $params) {
      _id
      prompt
    }
  }
`;

export const GET_CHATBOT_PDF = gql`
  query GetChatbotPDF($params: SharePDFInput!) {
    getChatbotPDF(params: $params)
  }
`;

export const GET_CHATBOT_FORM = gql`
  query ChatbotForm($params: ChatBotQueryInput!) {
    chatbotform(params: $params)
  }
`;
