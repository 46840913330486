import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Tooltip } from '@material-ui/core';

type Props = {
  props?: SvgIconProps;
  isHightLight?: boolean;
};

export const TelephoneIcon: React.FC<Props> = ({ props, isHightLight }) => {
  return (
    <Tooltip arrow title="Home">
      <SvgIcon
        color={isHightLight ? 'primary' : 'action'}
        fontSize="small"
        viewBox="0 0 512 512"
        {...props}
      >
        <g>
          <g>
            <g>
              <path
                d="M394.792,220.234c-9.292-17.417-27.333-28.229-47.063-28.229H164.271c-19.729,0-37.771,10.813-47.063,28.229
				l-68.271,128.01c-4.104,7.708-6.271,16.385-6.271,25.094v74.667c0,23.531,19.146,42.667,42.667,42.667h341.333
				c23.521,0,42.667-19.135,42.667-42.667v-74.667c0-8.708-2.167-17.385-6.271-25.104L394.792,220.234z M448,448.004
				c0,11.76-9.563,21.333-21.333,21.333H85.333c-11.771,0-21.333-9.573-21.333-21.333v-74.667c0-5.219,1.313-10.438,3.771-15.063
				l68.271-128c5.563-10.448,16.396-16.938,28.229-16.938h183.458c11.833,0,22.667,6.49,28.229,16.938l68.271,127.99
				c2.458,4.635,3.771,9.854,3.771,15.073V448.004z"
              />
              <circle cx="191.983" cy="277.338" r="21.333" />
              <circle cx="191.983" cy="341.338" r="21.333" />
              <circle cx="191.983" cy="405.338" r="21.333" />
              <circle cx="256.004" cy="405.338" r="21.333" />
              <circle cx="319.983" cy="405.338" r="21.333" />
              <circle cx="256.004" cy="341.338" r="21.333" />
              <circle cx="319.983" cy="341.338" r="21.333" />
              <circle cx="256.004" cy="277.338" r="21.333" />
              <circle cx="319.983" cy="277.338" r="21.333" />
              <path
                d="M503.25,129.025C437.229,59.588,349.417,21.338,255.99,21.338c-93.417,0-181.229,38.25-247.24,107.688
				C2.938,135.14,0,143.168,0,151.196c0,8.027,2.937,16.053,8.75,22.163l49.167,51.74c11.563,12.146,32.083,12.146,43.646,0
				c16.448-17.302,35.146-31.563,55.479-42.333c10.188-5.25,16.771-16.302,16.677-26.75l7.438-56.063
				c53.583-17.146,96.615-17.135,149.698-0.01l7.333,54.667c0,11.823,6.271,22.615,16.521,28.25
				c20.438,10.823,39.135,25.083,55.594,42.375c5.781,6.094,13.531,9.438,21.823,9.438c8.281,0,16.031-3.344,21.813-9.427
				l49.313-51.875c5.625-5.917,8.569-13.628,8.75-21.396C512.193,143.688,509.25,135.337,503.25,129.025z M487.792,158.661
				l-49.313,51.885c-3.448,3.615-9.292,3.604-12.719-0.01c-18.052-18.969-38.594-34.625-60.927-46.448
				c-3.323-1.833-5.313-5.375-5.406-10.885l-8.313-62.688c-0.531-4.01-3.292-7.375-7.115-8.688
				c-31.115-10.656-59.406-15.979-87.781-15.979c-28.365,0-56.802,5.323-88.187,15.969c-3.844,1.302-6.615,4.677-7.146,8.698
				l-8.406,64.094c0,3.896-2.094,7.594-5.313,9.25c-22.479,11.906-43.021,27.563-61.063,46.531
				c-3.438,3.604-9.292,3.594-12.729,0.01l-49.167-51.74c-3.854-4.042-3.854-10.885,0-14.927
				C86.156,78.557,168.469,42.671,255.99,42.671s169.844,35.885,231.802,101.063C491.646,147.775,491.646,154.619,487.792,158.661z"
              />
            </g>
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </SvgIcon>
    </Tooltip>
  );
};
export default TelephoneIcon;
