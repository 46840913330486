import React from 'react';
import {
  Checkbox,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { NoDataTable } from 'components';
import { UserFragment } from 'types.d';
import { formatPhoneNumber, isBotMail } from 'share/utils';
import {
  PaperBorder,
  TableCellMaxWidthStyled,
  TableCellMinWidthStyled,
} from 'share/component_css';
import { ObjectData } from 'hooks';

type Props = {
  OnlyChecked?: any;
  checkEmail: ObjectData;
  checkSMS: ObjectData;
  checkIsCreateNew: ObjectData;
  listEmail: UserFragment[];
  defaulCheckedProps?: {
    checked: string[];
    handleSetChecked: (
      event: React.ChangeEvent<HTMLInputElement>,
      value: string,
    ) => void;
  };
  isShareTreatment?: boolean;
  isSendSMS?: boolean;
};

export const TableListUser: React.FC<Props> = ({
  OnlyChecked,
  checkEmail,
  checkSMS,
  listEmail,
  defaulCheckedProps,
  checkIsCreateNew,
  isShareTreatment,
  isSendSMS,
}) => {
  const {
    checkItem: checkItemEmail,
    checkedAll: checkedAllEmail,
    checkAll: checkAllEmail,
    checkedList: checkedListEmail,
  } = checkEmail;

  const {
    checkItem: checkItemSMS,
    checkedAll: checkedAllSMS,
    checkAll: checkAllSMS,
    checkedList: checkedListSMS,
  } = checkSMS;

  const {
    checkItem: checkItemCreate,
    checkedAll: checkedAllCreate,
    checkAll: checkAllCreate,
    checkedList: checkedListCreate,
  } = checkIsCreateNew;

  const isObjectUser = (item: UserFragment | string): item is UserFragment => {
    return (item as UserFragment).email !== undefined;
  };

  const renderEmail = (user: string | UserFragment) => {
    if (isObjectUser(user)) {
      return isBotMail(user?.email) ? '--' : user?.email;
    }
    return +user ? '--' : user;
  };

  const renderPhoneNumber = (user: string | UserFragment) => {
    if (isObjectUser(user)) {
      return user?.phones && user?.phones?.length > 0
        ? formatPhoneNumber(user.phones[0]?.phone)
        : '--';
    }
    return +user ? formatPhoneNumber(user) : '--';
  };

  return (
    <TableContainer component={PaperBorder}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {!isSendSMS && (
              <TableCellMinWidthStyled minwidth="175px">
                Email
              </TableCellMinWidthStyled>
            )}
            {(isSendSMS || !isShareTreatment) && (
              <TableCellMinWidthStyled minwidth="175px">
                Moblie Number
              </TableCellMinWidthStyled>
            )}
            {!isShareTreatment && (
              <>
                <TableCell>
                  <Checkbox
                    checked={checkedAllEmail}
                    onChange={checkAllEmail}
                    color="primary"
                  />
                  Send Email
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={checkedAllSMS}
                    onChange={checkAllSMS}
                    color="primary"
                  />
                  Send SMS
                </TableCell>
              </>
            )}
            {isShareTreatment && (
              <TableCell align="left">
                <Checkbox
                  checked={checkedAllCreate}
                  onChange={checkAllCreate}
                  color="primary"
                />
                Create account
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {listEmail.length === 0 && <NoDataTable colSpan={5} />}
          {listEmail.length !== 0 &&
            listEmail.map(item => (
              <TableRow key={isObjectUser(item) ? item?._id : item}>
                {!isSendSMS && (
                  <TableCellMaxWidthStyled maxwidth="300px">
                    <Typography variant="subtitle2">
                      {renderEmail(item)}
                    </Typography>
                  </TableCellMaxWidthStyled>
                )}
                {(isSendSMS || !isShareTreatment) && (
                  <TableCellMaxWidthStyled maxwidth="300px">
                    <Typography variant="subtitle2">
                      {renderPhoneNumber(item)}
                    </Typography>
                  </TableCellMaxWidthStyled>
                )}
                {!isShareTreatment && (
                  <>
                    <TableCell>
                      {isObjectUser(item) ? (
                        <Checkbox
                          onChange={e => checkItemEmail(e, item._id)}
                          color="primary"
                          checked={checkedListEmail.includes(item._id)}
                          disabled={!item?.email || isBotMail(item?.email)}
                        />
                      ) : (
                        <OnlyChecked
                          {...defaulCheckedProps}
                          isMediumSize
                          disabled
                          value={[{ value: !+item ? item : '' }]}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {isObjectUser(item) ? (
                        <Checkbox
                          onChange={e => checkItemSMS(e, item._id)}
                          color="primary"
                          checked={checkedListSMS.includes(item._id)}
                          disabled={!item?.phones || item?.phones?.length === 0}
                        />
                      ) : (
                        <OnlyChecked
                          {...defaulCheckedProps}
                          isMediumSize
                          disabled
                          value={[{ value: +item ? item : '' }]}
                        />
                      )}
                    </TableCell>
                  </>
                )}

                {isShareTreatment && (
                  <TableCell align="left">
                    <Checkbox
                      onChange={e => checkItemCreate(e, item)}
                      color="primary"
                      checked={checkedListCreate.includes(item)}
                      disabled={isObjectUser(item)}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TableListUser;
