import gql from 'graphql-tag';
import {
  MetaFragment,
  ConditionSettingFragment,
  PatientCondStageFragment,
  ConditionSettingForModifyFragment,
} from './fragments';

export const GET_CONDITIONS = gql`
  query GetListConditions($params: GetConditionSettingInput!) {
    getListConditions(params: $params) {
      nodes {
        ...ConditionSetting
      }
      meta {
        ...Meta
      }
    }
  }
  ${MetaFragment}
  ${ConditionSettingFragment}
`;

export const CRETE_CONDITION_SETTING = gql`
  mutation CreateConditionSetting($params: CreateConditionSettingInput!) {
    createConditionSetting(params: $params) {
      idCondition: _id
    }
  }
`;

export const UPDATE_CONDITION_SETTING = gql`
  mutation UpdateConditionSetting($params: UpdateConditionSettingInput!) {
    updateConditionSetting(params: $params) {
      ...ConditionSetting
    }
  }
  ${ConditionSettingFragment}
`;

export const DELETE_CONDITION_SETTING = gql`
  mutation DeleteConditionSetting($params: DeleteConditionSettingInput!) {
    deleteConditionSetting(params: $params)
  }
`;

export const GET_LIST_PATIENT_STAGE = gql`
  query GetListPatientStages($params: GetPatientStageInput!) {
    getListPatientStages(params: $params) {
      nodes {
        ...PatientCondStage
      }
      meta {
        ...Meta
      }
    }
  }
  ${MetaFragment}
  ${PatientCondStageFragment}
`;

export const CREATE_PATIENT_STAGE = gql`
  mutation CreatePatientStage($params: CreatePatientStageInput!) {
    createPatientStage(params: $params) {
      _id
    }
  }
`;

export const UPDATE_PATIENT_STAGE = gql`
  mutation UpdatePatientStage($params: UpdatePatientStageInput!) {
    updatePatientStage(params: $params) {
      _id
    }
  }
`;

export const DELETE_PATIENT_STAGE = gql`
  mutation DeletePatientStage($params: DeletePatientStageInput!) {
    deletePatientStage(params: $params)
  }
`;

export const GET_CONDITIONS_FOR_MODIFY = gql`
  query GetConditionsForModify($params: GetConditionSettingInput!) {
    getListConditions(params: $params) {
      nodes {
        ...ConditionSettingForModify
      }
    }
  }
  ${ConditionSettingForModifyFragment}
`;

export const GET_CURRENT_PATIENT_STAGE = gql`
  query GetCurrentPatientStage($params: GetCurrentPatientStageInput!) {
    getCurrentPatientStage(params: $params) {
      _id
      stages {
        _id
        name
      }
    }
  }
`;
