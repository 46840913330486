import React, { useMemo } from 'react';
import {
  SubTreatmentsSideBar,
  ShowSubTreatmentsSideBarQuery,
  ToogleShowSubTreatmentsSideBarMutation,
  ToogleShowSubTreatmentsSideBarMutationVariables,
} from 'types.d';
import SubTreatment from './SubTreatment';
import { listToTree } from 'share/utils';
import { sortTreatment } from 'share/utils';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  SUB_TREATMENTS_SIDE_BAR,
  SHOW_SUB_TREATMENTS_SIDE_BAR,
  TOOGLE_SHOW_SUB_SIDE_BAR,
} from 'gql';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box } from '@material-ui/core';

type Props = {};

export const SubTreatments: React.FC<Props> = () => {
  const { data: dataShowSubSideBar } = useQuery<ShowSubTreatmentsSideBarQuery>(
    SHOW_SUB_TREATMENTS_SIDE_BAR,
  );
  const { data } = useQuery<{
    subTreatmentsSideBar: SubTreatmentsSideBar[];
  }>(SUB_TREATMENTS_SIDE_BAR);

  const [toogleShowSubSideBar] = useMutation<
    ToogleShowSubTreatmentsSideBarMutation,
    ToogleShowSubTreatmentsSideBarMutationVariables
  >(TOOGLE_SHOW_SUB_SIDE_BAR);

  const listSubTreatments = useMemo(() => {
    if (data?.subTreatmentsSideBar) {
      return listToTree(
        sortTreatment([...data.subTreatmentsSideBar]),
        '_id',
        'parentId',
      );
    }
    return [];
  }, [data]);

  const toogleSubTreatment = () => {
    toogleShowSubSideBar({
      variables: {
        input: { status: !dataShowSubSideBar?.showSubTreatmentsSideBar },
      },
    });
  };

  return (
    <>
      {data?.subTreatmentsSideBar?.length !== 0 && (
        <Box display="flex" position="relative" flexDirection="column" px={2}>
          <Box
            className="cursor-pointer"
            position="absolute"
            top="-34px"
            right="3px"
            onClick={toogleSubTreatment}
          >
            {dataShowSubSideBar?.showSubTreatmentsSideBar ? (
              <ExpandMoreIcon color="action" />
            ) : (
              <ChevronRightIcon color="action" />
            )}
          </Box>
          {dataShowSubSideBar?.showSubTreatmentsSideBar &&
            (listSubTreatments as SubTreatmentsSideBar[]).map(item => {
              return <SubTreatment key={item._id} item={item} />;
            })}
        </Box>
      )}
    </>
  );
};

export default React.memo(SubTreatments);
