import { useMutation } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import { MutationTuple } from '@apollo/react-hooks';
import { handleError } from 'share/utils';
import { useSnackbar } from 'notistack';
import { MutationUpdaterFn, PureQueryOptions } from 'apollo-client';

export const useMutationCustom = <TypeMuTation, TypeVariables>(arg: {
  api: DocumentNode;
  variables?: TypeVariables;
  textSuccess?: string;
  callbackSuccess?: (data: TypeMuTation) => void;
  refetchQueries?: Array<string | PureQueryOptions>;
  callbackUpdate?: MutationUpdaterFn<TypeMuTation>;
  callbackError?: () => void;
}): MutationTuple<TypeMuTation, TypeVariables> => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    api,
    textSuccess,
    callbackSuccess,
    variables,
    callbackUpdate,
    callbackError,
    refetchQueries,
  } = arg;
  const options: { [key: string]: any } = {};
  if (variables) {
    options.variables = variables;
  }
  const dataMutation = useMutation<TypeMuTation, TypeVariables>(api, {
    ...options,
    notifyOnNetworkStatusChange: true,
    onError(error) {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
      if (callbackError) {
        callbackError();
      }
    },
    onCompleted(data) {
      if (textSuccess) {
        enqueueSnackbar(textSuccess, {
          variant: 'success',
        });
      }
      if (callbackSuccess) {
        callbackSuccess(data);
      }
    },
    update(cache, mutationResult) {
      if (callbackUpdate) {
        callbackUpdate(cache, mutationResult);
      }
    },
    refetchQueries: refetchQueries,
  });
  return [...dataMutation];
};
