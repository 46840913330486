import gql from 'graphql-tag';
import {
  CPTCodeFragment,
  MetaFragment,
  PatientCPTCodeFragment,
  PatientTimeRecordFragment,
  PatientNoteTypeSettingFragment,
  PatientTimeReportFragment,
} from './fragments';

export const GET_CPT_CODE = gql`
  query GetCPTCode($params: GetCPTCodeInput!) {
    getCPTCode(params: $params) {
      nodes {
        ...CPTCode
      }
      meta {
        ...Meta
      }
    }
  }
  ${CPTCodeFragment}
  ${MetaFragment}
`;
export const GET_CPT_CODE_DETAIL = gql`
  query GetCPTCodeDetails($params: GetCPTDetailsInput!) {
    getCPTCodeDetails(params: $params) {
      _id
      code
      patientNoteTypes {
        ...PatientNoteTypeSetting
      }
    }
  }
  ${PatientNoteTypeSettingFragment}
`;

export const CREATE_CPT_CODE = gql`
  mutation CreateCPTCode($params: CreateCPTCodeInput!) {
    createCPTCode(params: $params) {
      _id
    }
  }
`;

export const UPDATE_CPT_CODE = gql`
  mutation UpdateCPTCode($params: UpdateCPTCodeInput!) {
    updateCPTCode(params: $params) {
      ...CPTCode
    }
  }
  ${CPTCodeFragment}
`;

export const DELETE_CPT_CODE = gql`
  mutation DeleteCPTCode($params: DeleteCPTCodeInput!) {
    deleteCPTCode(params: $params)
  }
`;

export const GET_PATIENT_TIME_RECORD = gql`
  query GetPatientTimeRecord($params: GetPatientTimeRecordInput!) {
    getPatientTimeRecord(params: $params) {
      nodes {
        ...PatientTimeRecord
      }
      meta {
        ...Meta
      }
    }
  }
  ${PatientTimeRecordFragment}
  ${MetaFragment}
`;

export const ADD_PATIENT_TIME_RECORD = gql`
  mutation AddPatientTimeRecord($params: AddTimeRecordInput!) {
    addPatientTimeRecord(params: $params) {
      ...PatientCPTCode
    }
  }
  ${PatientCPTCodeFragment}
`;

export const UPDATE_PATIENT_TIME_RECORD = gql`
  mutation UpdatePatientTimeRecord($params: UpdateRecordsInput!) {
    updatePatientTimeRecord(params: $params)
  }
`;

export const STOP_PATIENT_TIME_RECORD = gql`
  mutation StopPatientTimeRecord($params: StopRecordInput!) {
    stopPatientTimeRecord(params: $params)
  }
`;

export const DELETE_PATIENT_TIME_RECORD = gql`
  mutation DeletePatientTimeRecord($params: DeleteRecordInput!) {
    deletePatientTimeRecord(params: $params)
  }
`;

export const UPDATE_PATIENT_TIME_RECORD_STATUS = gql`
  mutation UpdatePatientTimeRecordStatus(
    $params: UpdatePatientTimeRecordStatusInput!
  ) {
    updatePatientTimeRecordStatus(params: $params) {
      ...PatientTimeReport
    }
  }
  ${PatientTimeReportFragment}
`;
