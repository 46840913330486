import React, { useState, useCallback } from 'react';
import { IconButton, TablePagination } from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { useUpdateMeClient } from './useUpdateMeClient';

export type InfoPagination = {
  page: number;
  limit: number;
};

type DefaultPaginationProps = {
  infoPagination: InfoPagination;
  handleChangePage: (event: any, value: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type PaginationProps = {
  count: number;
  loading?: boolean;
  isPatient?: boolean;
} & DefaultPaginationProps;

interface PaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}
export const usePagination = (
  callbackFetchData: (infoPagination: InfoPagination) => void,
) => {
  const { meClient } = useUpdateMeClient();

  const [infoPagination, setInfoPagination] = useState({
    page: 1,
    limit: meClient.setting?.perPage || 5,
  });
  const handleChangePage = useCallback(
    (event: any, value: number) => {
      setInfoPagination({ ...infoPagination, page: value + 1 });
      callbackFetchData({ ...infoPagination, page: value + 1 });
    },
    [callbackFetchData, infoPagination],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInfoPagination({
        ...infoPagination,
        page: 1,
        limit: parseInt(event.target.value, 10),
      });
      callbackFetchData({
        ...infoPagination,
        page: 1,
        limit: parseInt(event.target.value, 10),
      });
    },
    [callbackFetchData, infoPagination],
  );

  const handleSetInfoPagination = useCallback(
    (infoPagination: InfoPagination, isFetchingData?: boolean) => {
      setInfoPagination({ ...infoPagination });
      if (isFetchingData) {
        callbackFetchData({
          ...infoPagination,
        });
      }
    },
    [callbackFetchData],
  );

  const defaultPaginationProps = {
    infoPagination,
    handleChangePage,
    handleChangeRowsPerPage,
  };

  return {
    infoPagination,
    DefaultPagination,
    defaultPaginationProps,
    handleSetInfoPagination,
  };
};

export const DefaultPagination: React.FC<PaginationProps> = ({
  count,
  loading,
  isPatient,
  infoPagination,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  return (
    <>
      {count !== 0 && (
        <TablePagination
          style={{ opacity: loading ? 0 : 1 }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={infoPagination.limit}
          page={infoPagination.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={PaginationActions}
          labelRowsPerPage={isPatient ? 'Patients per page' : 'Rows per page'}
        />
      )}
    </>
  );
};

const PaginationActions = (props: PaginationActionsProps) => {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
};
