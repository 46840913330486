import React, { useContext } from 'react';
import { ChipStyled, TypographyBold } from 'share/component_css';
import { Box, Typography } from '@material-ui/core';
import { formatDate, surveyColorTag, renderOutline } from 'share/utils';
import { SurveyResultEmbeddedFragment } from 'types.d';
import { PatientDetailContext } from 'share/context';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

type Props = {
  result: SurveyResultEmbeddedFragment;
  editSurvey: (isUpdateSurvey?: boolean) => void;
  detailSurveyResult: React.MutableRefObject<
    SurveyResultEmbeddedFragment | undefined
  >;
  open: (
    event: React.MouseEvent<HTMLElement | SVGSVGElement, MouseEvent>,
  ) => void;
};

export const SurveyResult: React.FC<Props> = ({
  result,
  editSurvey,
  detailSurveyResult,
  open,
}) => {
  const patientDetailContext = useContext(PatientDetailContext);
  return (
    <ChipStyled
      opacity={result.isArchive ? 'true' : ''}
      className={`cursor-pointer ${renderOutline(result)}`}
      label={
        <Box display="flex" alignItems="center">
          <TypographyBold className="mr-8" variant="subtitle2">
            {formatDate(result.createdAt, true)}
          </TypographyBold>
          <Typography className="ml-8" variant="subtitle2">
            {`Updated ${formatDate(result.updatedAt, true)} ${
              result.updatedBy
                ? `, ${result.updatedBy.firstName} ${result.updatedBy
                    .middleName || ''} ${result.updatedBy.lastName}`
                : ''
            }`}
          </Typography>
        </Box>
      }
      onClick={e => {
        detailSurveyResult.current = result;
        editSurvey(true);
      }}
      onDelete={e => {
        open(e);
        detailSurveyResult.current = result;
      }}
      deleteIcon={
        !patientDetailContext?.isInactive ? (
          <MoreHorizIcon className="m-8" />
        ) : (
          <></>
        )
      }
      backgroundcolor={surveyColorTag(result)}
    />
  );
};

export default React.memo(SurveyResult);
