import React from 'react';
import { PatientFragment, UserStatus } from 'types.d';
import { IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useToogleDialog } from 'hooks';
import DialogUpdateInfo from 'modules/patients/components/Dialogs/DialogUpdateInfo';
import { DialogInfoPatient } from 'modules/comments/components/DialogInfomationPatient';
import InfoIcon from '@material-ui/icons/Info';

type Props = {
  item: PatientFragment;
  treatmentSelected?: string;
};

export const RowPatientInfo: React.FC<Props> = ({
  item,
  treatmentSelected,
}) => {
  const [isOpen, toogleDialog] = useToogleDialog();

  const [isOpenDialogPatient, setIsOpenDialogPatient] = useToogleDialog();

  return (
    <>
      {isOpen && (
        <DialogUpdateInfo
          open={isOpen}
          infoPatient={item}
          toogleDialog={toogleDialog}
          idPatient={item?._id}
          treatmentSelected={treatmentSelected}
          isFromHome
        />
      )}
      {isOpenDialogPatient && (
        <DialogInfoPatient
          patientSelected={item?._id}
          open={isOpenDialogPatient}
          toogleDialog={setIsOpenDialogPatient}
        />
      )}

      {item?.owner?.status !== UserStatus.Inactive && (
        <>
          <Tooltip title="Edit information" arrow>
            <IconButton onClick={() => toogleDialog(true)} color="primary">
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Patient information" arrow>
            <IconButton
              onClick={() => setIsOpenDialogPatient(true)}
              color="primary"
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
};

export default React.memo(RowPatientInfo);
