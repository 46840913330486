import React, { useMemo, useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { useLazyQuery, QueryLazyOptions } from '@apollo/react-hooks';
import { FormContextValues } from 'react-hook-form';
import { AutoCompleteLoadData, AutoCompleteSelect } from 'components';
import {
  GetProviderQuery,
  GetProviderQueryVariables,
  PatientFragment,
} from 'types.d';
import { GET_PROVIDER } from 'gql';
import { DEFAULT_LIMIT } from 'CONST';
import { PatientDetailContext } from 'share/context';

type ProviderComponentProps = {
  optionEmrProviders: OptionEmrProvider[];
  optionProviders: OptionEmrProvider[];
  control: any;
  disable?: boolean;
  getProviders: (options?: QueryLazyOptions<GetProviderQueryVariables>) => void;
  loadingGetProvider: boolean;
};

export type OptionEmrProvider = {
  _id: string;
  name: string;
  emrProvider: string;
};

type ExtendProviderComponentProps = ProviderComponentProps & {
  methods: FormContextValues<any>;
};

type FormExtend = {
  provider: OptionEmrProvider;
  emrProvider?: OptionEmrProvider;
};

export const useProvider = (
  infoPatient?: PatientFragment,
  methods?: FormContextValues<any>,
): {
  optionEmrProviders: OptionEmrProvider[];
  optionProviders: OptionEmrProvider[];
  providerComponentProps: ProviderComponentProps;
  ProviderComponent: any;
  getProviders: (options?: QueryLazyOptions<GetProviderQueryVariables>) => void;
} => {
  const {
    watch,
    setValue,
    formState: { touched },
    control,
  } = methods as FormContextValues<FormExtend>;

  const watchProviders = watch('provider');

  const [optionEmrProviders, setOptionEmrProviders] = useState<
    OptionEmrProvider[]
  >([]);

  const [
    getProviders,
    { data: dataProviders, loading: loadingGetProvider },
  ] = useLazyQuery<GetProviderQuery, GetProviderQueryVariables>(GET_PROVIDER, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (
        infoPatient &&
        data?.getProvider?.nodes &&
        data?.getProvider?.nodes.length !== 0
      ) {
        const isExistEmr = !!data?.getProvider?.nodes?.find(
          item => item?._id === methods?.watch('provider')?._id,
        );
        setOptionEmrProviders(
          (!isExistEmr && methods?.watch('provider')
            ? [...data.getProvider.nodes, methods?.watch('provider')]
            : data.getProvider.nodes
          )
            .filter(item => {
              return (
                infoPatient?.owner?.provider
                  ?.map(item => item._id)
                  .includes(item._id) && item.emrProvider
              );
            })
            .map(item => {
              return {
                _id: item?._id as string,
                name: item.emrProvider as string,
                emrProvider: item?.emrProvider as string,
              };
            }) || [],
        );
      }
    },
  });

  const optionProviders = useMemo(() => {
    if (
      dataProviders?.getProvider?.nodes &&
      dataProviders?.getProvider?.nodes.length !== 0
    ) {
      return dataProviders.getProvider.nodes.map(item => {
        return {
          _id: item._id as string,
          name: item.provider as string,
          emrProvider: item?.emrProvider || '',
        };
      });
    }
    return [];
  }, [dataProviders]);

  useEffect(() => {
    if (touched.provider) {
      const providerSelected = dataProviders?.getProvider?.nodes?.find(
        item => item._id === watchProviders?._id,
      );
      setValue('emrProvider', {
        _id: providerSelected?._id,
        name: providerSelected?.emrProvider,
      } as OptionEmrProvider);
    }
  }, [dataProviders, setValue, touched.provider, watchProviders]);

  useEffect(() => {
    if (touched.provider) {
      setOptionEmrProviders(
        dataProviders?.getProvider?.nodes
          ?.filter(item => {
            return item.emrProvider && watchProviders?._id === item._id;
          })
          ?.map(item => {
            return {
              _id: item._id as string,
              name: item.emrProvider as string,
              emrProvider: item.emrProvider as string,
            };
          }) || [],
      );
    }
  }, [dataProviders, touched.provider, watchProviders]);

  const providerComponentProps = {
    optionEmrProviders,
    optionProviders,
    control,
    getProviders,
    loadingGetProvider,
    methods,
  };

  return {
    optionEmrProviders,
    optionProviders,
    getProviders,
    providerComponentProps,
    ProviderComponent,
  };
};

export const ProviderComponent: React.FC<ExtendProviderComponentProps> = ({
  optionEmrProviders,
  optionProviders,
  control,
  disable,
  getProviders,
  loadingGetProvider,
  methods,
}) => {
  const patientDetailContext = useContext(PatientDetailContext);

  const owner = patientDetailContext?.patient?.owner;

  const handleTextInput = (value: string) => {
    if (value) {
      getProviders({
        variables: {
          params: {
            page: 1,
            provider: value,
            sortByOrder: { provider: 1 },
            limit: DEFAULT_LIMIT,
          },
        },
      });
    }
  };

  return (
    <>
      <Grid item xs={6}>
        <AutoCompleteLoadData
          name="provider"
          label="Provider"
          nameOption="name"
          control={control}
          loadList={loadingGetProvider}
          small
          options={optionProviders || []}
          disabled={disable}
          hasOptionsTextNull
          methods={methods}
          callbackHandleText={value => handleTextInput(value)}
          defaultValue={
            owner?.provider
              ? {
                  ...owner?.provider[0],
                  emrProvider: owner?.provider[0]?.emrProvider || '',
                  name: owner?.provider[0]?.provider || '',
                }
              : undefined
          }
        />
      </Grid>
      <Grid item xs={6}>
        <AutoCompleteSelect
          small
          disabled={disable}
          disablePortal
          control={control}
          name="emrProvider"
          label="EMR Provider"
          nameOption="name"
          options={optionEmrProviders || []}
          defaultValue={methods?.watch('provider')?._id}
        />
      </Grid>
    </>
  );
};
