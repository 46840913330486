import gql from 'graphql-tag';

export const MENU_STATUS = gql`
  {
    menuStatus @client
  }
`;

export const TOOGLE_MENU_STATUS = gql`
  mutation ToogleMenuStatus($status: Boolean) {
    toogleMenuStatus(status: $status) @client
  }
`;

//Banner status when logout
export const BANNER_STATUS = gql`
  {
    bannerStatus @client
  }
`;
export const TOOGLE_BANNER_STATUS = gql`
  mutation ToogleBannerStatus($status: Boolean) {
    toogleBannerStatus(status: $status) @client
  }
`;

export const SUB_TREATMENTS_SIDE_BAR = gql`
  {
    subTreatmentsSideBar @client
  }
`;

export interface VariablesSubTreatment {
  _id: string;
  name: string;
  subSectionIds: string[];
}

export const ADD_SUB_TREATMENTS_SIDE_BAR = gql`
  mutation AddSubTreatmentsSideBar($subTreatments: [VariablesSubTreatment]) {
    addSubTreatmentsSideBar(subTreatments: $subTreatments) @client
  }
`;

// query GetDashboard($params: IdInputType!) {
//   getDashboard(params: $params) {
//     ...DashboardRes
//   }
// }
// export const SHOW_SUB_TREATMENTS_SIDE_BAR = gql`
//   query ShowSubTreatmentsSideBar {
//     showSubTreatmentsSideBar @client
//   }
// `;
// export const TOOGLE_SHOW_SUB_TREATMENT_SIDE_BAR = gql`
//   mutation ToogleShowSubTreatmentsSideBar($status: Boolean) {
//     toogleShowSubTreatmentsSideBar(status: $status) @client
//   }
// `;

export const HOMPEPAGE_CLIENT = gql`
  {
    homepageClient @client {
      _id
      buttonConfigurations
      dynamicTreatments
      footerConfigurations
      organization
      organizationHomepageSlug
      favoriteFlows
      __typename
    }
  }
`;

export const UPDATE_HOMEPAGE_CLIENT = gql`
  mutation UpdateHomepageClient(
    $_id: any
    $buttonConfigurations: any
    $dynamicTreatments: any
    $footerConfigurations: any
    $organization: any
    $organizationHomepageSlug: any
    $favoriteFlows: any
    $__typename: any
  ) {
    updateHomepageClient(
      _id: $_id
      buttonConfigurations: $buttonConfigurations
      dynamicTreatments: $dynamicTreatments
      footerConfigurations: $footerConfigurations
      organization: $organization
      organizationHomepageSlug: $organizationHomepageSlug
      favoriteFlows: $favoriteFlows
      __typename: $__typename
    ) @client
  }
`;
