import { useContext, useCallback, useMemo } from 'react';
import { PatientDetailContext } from 'share/context';
import {
  AddPatientTimeRecordMutation,
  AddPatientTimeRecordMutationVariables,
  DeletePatientFollowUpMutation,
  DeletePatientFollowUpMutationVariables,
  DeletePatientTimeRecordMutation,
  DeletePatientTimeRecordMutationVariables,
  DeleteSurveyResultMutation,
  DeleteSurveyResultMutationVariables,
  PatientChecklistFragment,
  PatientCptCodeStatus,
  PatientNoteTypeSettingFragment,
  SurveyResultStatus,
  TSaveRecording,
  ToogleSaveRecordingMutation,
  ToogleSaveRecordingMutationVariables,
  UpdatePatientTimeRecordStatusInput,
  UpdatePatientTimeRecordStatusMutation,
  UpdatePatientTimeRecordStatusMutationVariables,
} from 'types.d';
import {
  DELETE_PATIENT_FOLLOW_UP,
  GET_PATIENT_FOLLOW_UPS,
  PatientNoteFragment as PatientNoteFragmentGql,
  GET_LIST_PATIENT_STAGE,
  DELETE_SURVEY_RESULT,
  GET_SURVEY_DATA_BY_PATIENT,
  GET_PATIENT_CHECKLISTS,
  GET_PATIENT_SURVEYS,
  GET_PATIENT_TIME_RECORD,
  DELETE_PATIENT_TIME_RECORD,
  ADD_PATIENT_TIME_RECORD,
  TOOGLE_SAVE_RECORDING,
  UPDATE_PATIENT_TIME_RECORD_STATUS,
} from 'gql';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { handleError } from 'share/utils';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import { useMutationCustom } from 'hooks';
import { customEvent } from 'modules/google_tag_manager';

export const useServices = () => {
  const patientDetailContext = useContext(PatientDetailContext);

  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const [toogleSaveRecording] = useMutation<
    ToogleSaveRecordingMutation,
    ToogleSaveRecordingMutationVariables
  >(TOOGLE_SAVE_RECORDING);

  const refetchQueriesTimeReport = () => {
    return [
      {
        query: GET_PATIENT_TIME_RECORD,
        variables: {
          params: {
            patient: patientDetailContext?.patient.owner?.idOwner,
            page: 1,
            limit: 5,
          },
        },
      },
    ];
  };

  const [toggleStatusReport, { loading: loadingToggleStatus }] = useMutation<
    UpdatePatientTimeRecordStatusMutation,
    UpdatePatientTimeRecordStatusMutationVariables
  >(UPDATE_PATIENT_TIME_RECORD_STATUS, {
    refetchQueries: refetchQueriesTimeReport(),
  });

  const isOnTabChecklist = useMemo(() => {
    return (
      patientDetailContext?.isExtend && patientDetailContext?.indexTab === 6
    );
  }, [patientDetailContext]);

  const variablesFollowUpTag = {
    params: {
      patientId: patientDetailContext?.patient.owner?.idOwner,
      status: { $nin: ['CLOSED', 'CANCELLED'] },
    },
  };

  const variablesChecklistTag = {
    params: {
      patientId: patientDetailContext?.patient.owner?.idOwner,
      checklistExists: true,
    },
  };

  const variablesSurveyTags = {
    params: {
      page: 1,
      limit: 5,
      patientId: patientDetailContext?.patient.owner?.idOwner,
      order: { updatedAt: -1 },
      isArchive: false,
      workingStatus: SurveyResultStatus.New,
    },
  };

  const refetchQueryPatientData = [
    {
      query: GET_SURVEY_DATA_BY_PATIENT,
      variables: variablesSurveyTags,
    },
    {
      query: GET_PATIENT_SURVEYS,
      variables: {
        params: {
          page: 1,
          limit: 5,
          patientId: patientDetailContext?.patient.owner?.idOwner,
        },
      },
    },
  ];

  const [createRecord, { loading: loadingCreateRecord }] = useMutationCustom<
    AddPatientTimeRecordMutation,
    AddPatientTimeRecordMutationVariables
  >({
    refetchQueries: refetchQueriesTimeReport(),
    api: ADD_PATIENT_TIME_RECORD,
    textSuccess: 'Add time record successfully',
    callbackSuccess: data => {
      customEvent("AddTimeRecordPD", "NAVIGATOR")
      toogleSaveRecording({
        variables: {
          input: {
            _id: data?.addPatientTimeRecord?._id,
            startedAt: data?.addPatientTimeRecord?.startedAt,
            stoppedAt: data?.addPatientTimeRecord?.stoppedAt,
            idCptCode: data?.addPatientTimeRecord?.cptCode?._id,
            idPatient: data?.addPatientTimeRecord?.patient?._id,
            __typename: 'TSaveRecording',
          } as TSaveRecording,
        },
      });
    },
  });

  const toogleStatusRecord = (
    callback: () => void,
    _id: string,
    state: PatientCptCodeStatus,
    stoppedAt?: Date,
    patientNoteTypes?: PatientNoteTypeSettingFragment[],
  ) => {
    let params = { _id, state } as UpdatePatientTimeRecordStatusInput;
    if (stoppedAt) {
      params.stoppedAt = stoppedAt;
    }
    if (patientNoteTypes) {
      params.patientNoteTypes = patientNoteTypes?.map(item => {
        return {
          id: item?._id as string,
          description: item?.description as string,
        };
      });
    }
    toggleStatusReport({
      variables: {
        params,
      },
    })
      .then(() => {
        callback();
      })
      .catch(error => {
        const arrError = handleError(error.graphQLErrors[0]!);
        enqueueSnackbar(arrError.join(', '), { variant: 'error' });
      });
  };

  const [deleteRecord, { loading: loadingDeleteRecord }] = useMutationCustom<
    DeletePatientTimeRecordMutation,
    DeletePatientTimeRecordMutationVariables
  >({
    refetchQueries: refetchQueriesTimeReport(),
    api: DELETE_PATIENT_TIME_RECORD,
    textSuccess: 'Delete record successfully',
    callbackSuccess: data => {
      toogleSaveRecording({
        variables: {
          input: null,
        },
      });
    },
  });

  const [
    deletePatientFollowUp,
    { loading: loadingDeleteFollowUp },
  ] = useMutation<
    DeletePatientFollowUpMutation,
    DeletePatientFollowUpMutationVariables
  >(DELETE_PATIENT_FOLLOW_UP, {
    refetchQueries: [
      {
        query: GET_PATIENT_FOLLOW_UPS,
        variables: variablesFollowUpTag,
      },
    ],
  });

  const handleDeleteRecord = (
    _id: string,
    callback: () => void,
    isDeleteRecord?: boolean,
  ) => {
    deleteRecord({
      variables: {
        params: isDeleteRecord
          ? {
            recordId: _id,
            patient: patientDetailContext?.patient.owner?.idOwner,
          }
          : {
            cptCodeId: _id,
            patient: patientDetailContext?.patient.owner?.idOwner,
          },
      },
    }).then(data => {
      callback();
    });
  };

  const handleCreateRecord = (_id: string, callback: () => void) => {
    createRecord({
      variables: {
        params: {
          cptCode: _id,
          startedAt: new Date(),
          patient: patientDetailContext?.patient.owner?.idOwner,
        },
      },
    }).then(data => {
      callback();
    });
  };

  const handleDeleteFollowUp = (
    _id: string,
    callback: () => void,
    isCancelled?: boolean,
  ) => {
    deletePatientFollowUp({
      variables: {
        params: {
          _id,
          patientId: patientDetailContext?.patient.owner?.idOwner,
        },
      },
    })
      .then(data => {
        enqueueSnackbar(
          `${isCancelled ? 'Update' : 'Delete'} task successfully`,
          {
            variant: 'success',
          },
        );

        callback();
      })
      .catch(error => {
        const arrError = handleError(error.graphQLErrors[0]!);
        enqueueSnackbar(arrError.join(', '), { variant: 'error' });
      });
  };

  const [
    deleteSurveyResult,
    { loading: loadingDeleteSurveyResult },
  ] = useMutationCustom<
    DeleteSurveyResultMutation,
    DeleteSurveyResultMutationVariables
  >({
    api: DELETE_SURVEY_RESULT,
    refetchQueries: [
      {
        query: GET_SURVEY_DATA_BY_PATIENT,
        variables: variablesSurveyTags,
      },
      {
        query: GET_PATIENT_CHECKLISTS,
        variables: variablesChecklistTag,
      },
    ],
    textSuccess: 'Delete survey result successfully',
    callbackSuccess: () => {
      if (isOnTabChecklist) {
        patientDetailContext?.setIsRefreshChecklist(true);
      }
    },
  });

  const handleDeleteSurveyResult = (_id: string, callback: () => void) => {
    deleteSurveyResult({
      variables: {
        params: {
          _id,
        },
      },
    })
      .then(data => {
        callback();
      })
      .catch(error => {
        const arrError = handleError(error.graphQLErrors[0]!);
        enqueueSnackbar(arrError.join(', '), { variant: 'error' });
      });
  };

  const getPatientChecklists = useCallback((item: PatientChecklistFragment) => {
    return item?.patientChecklists;
  }, []);

  //update survey result data local
  const updateSurveyResultLocal = (idSurveyResult: string | undefined) => {
    if (idSurveyResult) {
      try {
        client.writeFragment({
          id: `SurveyResultEmbedded:${idSurveyResult}`,
          fragment: gql`
            fragment SurveyResultEmbeddedUpdate on SurveyResultEmbedded {
              patientNote {
                ...PatientNote
              }
            }
            ${PatientNoteFragmentGql}
          `,
          fragmentName: 'SurveyResultEmbeddedUpdate',
          data: {
            patientNote: null,
            __typename: 'SurveyResultEmbedded',
          },
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  //set refetch queries for condition
  const refetchQueriesCondition = () => {
    return [
      {
        query: GET_LIST_PATIENT_STAGE,
        variables: {
          params: {
            page: 1,
            limit: 5,
            patientId: patientDetailContext?.patient.owner?.idOwner,
            isCurrentStage: true,
          },
        },
      },
    ];
    // return patientDetailContext?.indexTab === 7
    //   ? [
    //       {
    //         query: GET_LIST_PATIENT_STAGE,
    //         variables: {
    //           params: {
    //             page: 1,
    //             limit: 5,
    //             patientId: patientDetailContext?.patient.owner?.idOwner,
    //             isCurrentStage: true,
    //           },
    //         },
    //       },
    //       {
    //         query: GET_LIST_PATIENT_STAGE,
    //         variables: {
    //           params: {
    //             page: 1,
    //             patientId: patientDetailContext?.patient.owner?.idOwner,
    //           },
    //         },
    //       },
    //     ]
    //   : [
    //       {
    //         query: GET_LIST_PATIENT_STAGE,
    //         variables: {
    //           params: {
    //             page: 1,
    //             limit: 5,
    //             patientId: patientDetailContext?.patient.owner?.idOwner,
    //             isCurrentStage: true,
    //           },
    //         },
    //       },
    //     ];
  };

  return {
    variablesChecklistTag,
    variablesFollowUpTag,
    handleDeleteFollowUp,
    loadingDeleteFollowUp,
    loadingDeleteSurveyResult,
    loadingToggleStatus,
    loadingCreateRecord,
    handleDeleteSurveyResult,
    loadingDeleteRecord,
    getPatientChecklists,
    updateSurveyResultLocal,
    variablesSurveyTags,
    refetchQueryPatientData,
    refetchQueriesCondition,
    refetchQueriesTimeReport,
    handleDeleteRecord,
    handleCreateRecord,
    toogleStatusRecord,
    toogleSaveRecording,
  };
};
