import React from 'react';
import { Grid, Typography, Hidden } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { styled } from '@material-ui/core/styles';

type Props = {};

const SkeletonStyled = styled(Skeleton)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
}));

const GridStyled = styled(Grid)(({ theme }) => ({
  height: theme.spacing(8),
}));

const GridBoxShadowStyled = styled(Grid)({
  boxShadow:
    '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
});
export const Loading: React.FC<Props> = () => {
  return (
    <>
      <Grid container spacing={3}>
        <GridBoxShadowStyled item xs={12}>
          <GridStyled container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Skeleton width="100%" variant="rect" height={46} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton width="50%" variant="rect" height={46} />
            </Grid>
            <Grid item xs={5}>
              <Skeleton width={'100%'} variant="rect" height={46} />
            </Grid>
          </GridStyled>
        </GridBoxShadowStyled>
        <Hidden mdDown>
          <Grid item xs={2}>
            <SkeletonStyled variant="rect" height={50} />
            <SkeletonStyled variant="rect" height={50} />
            <SkeletonStyled variant="rect" height={50} />
          </Grid>
        </Hidden>

        <Grid item xs={12} lg={10}>
          <Typography gutterBottom variant="body1">
            <Skeleton width="60%" />
          </Typography>
          <Typography gutterBottom variant="body1">
            <Skeleton width="80%" />
          </Typography>
          <Typography gutterBottom variant="body1">
            <Skeleton width="100%" />
          </Typography>
          <Skeleton variant="rect" height={500} />
        </Grid>
      </Grid>
    </>
  );
};
export default Loading;
