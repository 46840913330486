import React, { useContext, useMemo, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Chip,
  Grid,
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  ButtonLoading,
  TableListSuccess,
  DialogTitleClose,
  AutoCompleteLoadData,
  DialogButton,
  DeleteConfirm,
} from 'components';
import { useSnackbar } from 'notistack';
import {
  useChecked,
  useInputMentions,
  useMutationCustom,
  useToogleDialog,
} from 'hooks';
import { SHARE_ASSIGN_TREATMENT, GET_USERS_FOR_NAVIGATOR } from 'gql';
import {
  ShareAssignTreatmentMutation,
  ShareAssignTreatmentMutationVariables,
  GetUsersForNavigatorQuery,
  GetUsersForNavigatorQueryVariables,
  UserFragment,
  SharedUser,
  UserStatus,
  GetUsersForNavigatorInput,
  SearchType,
  Gender,
  Race,
} from 'types.d';
import { useLazyQuery } from '@apollo/react-hooks';
import { EMAIL_REGEX, DEFAULT_LIMIT } from 'CONST';
import { TreatmentTreeContext } from 'share/context';
import {
  formatPhoneNumber,
  isBotMail,
  renderCheckedItem,
  renderSearchItem,
} from 'share/utils';
import CheckIcon from '@material-ui/icons/Check';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MessageIcon from '@material-ui/icons/Message';
import { OptionEmrProvider } from '../../patients/services/useProvider';
import TableUserList from './TableUserList';

type Props = {
  open: boolean;
  setOpenModal: (status: boolean) => void;
  idTreatment?: string | null;
  smsMessage?: string;
  isSendSMS?: boolean;
};

export type PatientInfo = {
  gender?: Gender;
  provider?: OptionEmrProvider;
  emrProvider?: OptionEmrProvider;
  address?: string;
  race?: Race | string;
};

export const DialogShareAssignTreatment: React.FC<Props> = ({
  open,
  setOpenModal,
  idTreatment,
  smsMessage,
  isSendSMS,
}) => {
  const [listEmail, setListEmail] = useState<Array<UserFragment & PatientInfo>>(
    [],
  );

  const checkIsCreateNew = useChecked(
    listEmail?.filter(item => !item?.authenticationInfo) || [],
  );

  const {
    resetCheckbox: resetCheckboxCreate,
    checkedList: checkedListCreateNew,
  } = checkIsCreateNew;

  const treatmentContext = useContext(TreatmentTreeContext);

  const [getListUser, { loading: loadListUser, data: listUser }] = useLazyQuery<
    GetUsersForNavigatorQuery,
    GetUsersForNavigatorQueryVariables
  >(GET_USERS_FOR_NAVIGATOR, {
    fetchPolicy: 'cache-and-network',
  });

  const { enqueueSnackbar } = useSnackbar();

  const [isOpen, toogleDialog] = useToogleDialog();

  const [isOpenDialogConfirm, toogleDialogConfirm] = useToogleDialog();

  const {
    valueCustom,
    defaulInputMentionsProps,
    InputMentions,
  } = useInputMentions(
    true,
    treatmentContext?.dataTree?.getTreatments?.nodes?.find(
      item => item._id === idTreatment,
    )?.smsMessage ||
      smsMessage ||
      '',
  );

  const handleClose = () => {
    setOpenModal(false);
  };

  const [
    shareAssignTreatment,
    { loading: loadingShareAssignTreatment, data: dataShareAssignTreatment },
  ] = useMutationCustom<
    ShareAssignTreatmentMutation,
    ShareAssignTreatmentMutationVariables
  >({
    api: SHARE_ASSIGN_TREATMENT,
    textSuccess: 'Share treatment successfully',
    callbackSuccess: data => {
      toogleDialog(true);
      toogleDialogConfirm(false);
      setListEmail([]);
    },
  });

  const checkInputValue = (value: UserFragment & PatientInfo) => {
    const { _id, phones, ...patientInfo } = value;
    const createNew = checkedListCreateNew?.includes(renderCheckedItem(value));
    const defautParmas = {
      ...patientInfo,
      race: value?.race === '' ? null : (value?.race as Race),
      createNew,
      provider: patientInfo?.provider ? [patientInfo?.provider?._id] : null,
      emrProvider: patientInfo?.emrProvider?._id
        ? [patientInfo?.emrProvider?._id]
        : null,
    };
    const defaultParamEmail = {
      email: value?.email,
      sendSMS: false,
      sendEmail: true,
    };
    if (value?.phones && value?.phones?.length > 0) {
      const defaultParamPhone = {
        phone: value?.phones[0]?.phone,
        sendSMS: true,
        sendEmail: false,
      };
      return createNew
        ? {
            ...defautParmas,
            ...defaultParamPhone,
          }
        : defaultParamPhone;
    }
    return createNew
      ? {
          ...defautParmas,
          ...defaultParamEmail,
        }
      : defaultParamEmail;
  };

  const getUsers = (search?: string) => {
    const params: GetUsersForNavigatorInput = {
      sortByOrder: { firstName: 1 },
      page: 1,
      limit: DEFAULT_LIMIT,
    };
    if (search) {
      params.search = search;
    }
    getListUser({
      variables: {
        params: {
          sortByOrder: { firstName: 1 },
          page: 1,
          limit: isSendSMS ? 10 : 25,
          searchType: isSendSMS ? SearchType.Phone : SearchType.Email,
        },
      },
    });
  };

  const shareTreatment = () => {
    const listUser = listEmail.map(item => {
      return item?.authenticationInfo
        ? {
            email: item.email,
            id: item._id,
            phone:
              item?.phones && item?.phones?.length !== 0
                ? item?.phones[0]?.phone
                : null,
            sendSMS: !!isSendSMS,
            sendEmail: !isSendSMS,
          }
        : checkInputValue(item);
    }) as SharedUser[];
    if (
      listUser?.filter(item => !item.sendEmail && !item.sendSMS)?.length !== 0
    ) {
      enqueueSnackbar(
        'Each user need at least one method to assign treatment',
        { variant: 'error' },
      );
      return;
    }
    shareAssignTreatment({
      variables: {
        params: {
          sharedUsers: listUser,
          treatmentId: idTreatment,
          smsMessage: valueCustom,
        },
      },
    });
  };

  const handleSave = () => {
    if (checkedListCreateNew?.length > 0) {
      toogleDialogConfirm(true);
      return;
    }
    shareTreatment();
  };

  const enterDialog = () => {
    getUsers();
  };

  const handleTextInput = (text: string) => {
    if (text && text.length > 2) {
      getListUser({
        variables: {
          params: {
            search: text,
            sortByOrder: { firstName: 1 },
            page: 1,
            limit: 25,
            searchType: isSendSMS ? SearchType.Phone : SearchType.Email,
          },
        },
      });
      return;
    }
  };

  const renderLabelTag = (option: UserFragment) => {
    if (isSendSMS && option?.phones) {
      return formatPhoneNumber(option.phones[0]?.phone);
    }
    return option.email;
  };

  const backToForm = () => {
    toogleDialog(false);
    resetCheckboxCreate();
  };

  const handleConvert = (list: UserFragment[]) => {
    const newList = list.map(item => {
      if (typeof item === 'string') {
        return +item
          ? ({
              _id: `1${(item as string).replace('+', '')}` as string,
              phones: [
                {
                  phone: `1${(item as string).replace('+', '')}`,
                  type: 'mobile',
                },
              ],
            } as UserFragment)
          : ({ _id: item as string, email: item as string } as UserFragment);
      }
      return item;
    });
    return newList;
  };

  const optionUser = useMemo(() => {
    return (
      listUser?.getUsersForNavigator.nodes.filter(
        item => !(isBotMail(item.email) && item?.phones?.length === 0),
      ) || []
    );
  }, [listUser]);

  return (
    <>
      <DeleteConfirm
        loading={loadingShareAssignTreatment}
        handleConfirm={shareTreatment}
        open={isOpenDialogConfirm}
        toogle={toogleDialogConfirm}
        icon={<PersonAddIcon />}
        action="update"
        buttonText="Send"
        title="Are you sure you want to create an account for the selected user?"
      />
      <Dialog open={open} maxWidth="md" fullWidth onEnter={enterDialog}>
        <DialogTitleClose title="Share Treatment" onClose={handleClose} />
        <DialogContent>
          {!isOpen && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AutoCompleteLoadData
                  label={isSendSMS ? 'Phone number' : 'Email'}
                  nameOption="name"
                  loadList={loadListUser}
                  multiple
                  freeSolo
                  small
                  defaultValue={listEmail}
                  options={
                    isSendSMS
                      ? optionUser || []
                      : optionUser?.filter(item => !isBotMail(item.email)) || []
                  }
                  getOptionDisabled={listUser?.getUsersForNavigator.nodes?.filter(
                    item => item.status === UserStatus.Inactive,
                  )}
                  callbackChangeValue={(_, newValue) => {
                    if (newValue.length === 0) {
                      resetCheckboxCreate();
                    }
                    if (newValue.length <= 10) {
                      const listEmail = newValue as (string | UserFragment)[];
                      const lastEmail = listEmail[listEmail.length - 1];
                      if (
                        listEmail.length !== 0 &&
                        typeof lastEmail === 'string' &&
                        !isSendSMS &&
                        (!EMAIL_REGEX.test(lastEmail as string) ||
                          lastEmail.substring(0, lastEmail.indexOf('@'))
                            .length < 4)
                      ) {
                        enqueueSnackbar('You need to type email on input', {
                          variant: 'error',
                        });
                        return;
                      }
                      if (
                        listEmail.length !== 0 &&
                        typeof lastEmail === 'string' &&
                        isSendSMS &&
                        !+lastEmail
                      ) {
                        enqueueSnackbar(
                          'You need to type phone number on input',
                          {
                            variant: 'error',
                          },
                        );
                        return;
                      }

                      setListEmail(handleConvert(newValue));
                      return;
                    }
                    enqueueSnackbar('Please input no more than 10 items', {
                      variant: 'error',
                    });
                  }}
                  disableCloseOnSelect
                  limitTags={3}
                  callbackHandleText={value => handleTextInput(value)}
                  callbackRenderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        label={renderLabelTag(option)}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  callbackRenderOption={(option, selected) => {
                    return renderSearchItem(option, selected);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TableUserList
                  checkIsCreateNew={checkIsCreateNew}
                  listEmail={listEmail}
                  setListEmail={setListEmail}
                  isSendSMS={isSendSMS}
                />
              </Grid>
              {isSendSMS && <InputMentions {...defaulInputMentionsProps} />}
            </Grid>
          )}
          {isOpen && dataShareAssignTreatment?.shareAssignTreatment && (
            <Grid item xs={12}>
              <TableListSuccess
                list={dataShareAssignTreatment?.shareAssignTreatment}
                listCreateNew={checkedListCreateNew}
                isSendSMS={isSendSMS}
              />
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          {isOpen && (
            <>
              <ButtonLoading
                callbackClick={backToForm}
                color="default"
                text="Back"
                Icon={<ArrowBackIcon />}
              />
              <ButtonLoading
                callbackClick={handleClose}
                color="primary"
                text="OK"
                Icon={<CheckIcon />}
              />
            </>
          )}

          {!isOpen && (
            <>
              <DialogButton isCancel onClickButton={handleClose} />
              <ButtonLoading
                loading={loadingShareAssignTreatment}
                callbackClick={handleSave}
                text="Send"
                disabled={listEmail.length === 0}
                Icon={isSendSMS ? <MessageIcon /> : <EmailIcon />}
              />
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DialogShareAssignTreatment;
