import React, { useCallback, useRef, useMemo, useContext } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Tooltip, Box, IconButton } from '@material-ui/core';
import { PlaylistEdit } from 'mdi-material-ui';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import LoadingTags from './LoadingTags';
import {
  ChecklistStatus,
  PatientChecklistFragment,
  ResultChecklist,
} from 'types.d';
import { formatDate } from 'share/utils';
import { COLOR_STATUS_CHECKLIST } from 'CONST';
import { TypoThreeDot, ChipStyled } from 'share/component_css';
import { useToogleDialog, usePopover } from 'hooks';
import DialogChecklist from '../Checklist/DialogChecklist';
import DialogResultChecklist from '../Checklist/DialogResultChecklist';
import ButtonDetachChecklist from '../Checklist/ButtonDetachChecklist';
import { useServices } from '../../services';
import BoxShowMore from './BoxShowMore';
import { PatientTagsContext } from 'share/context';
import { ScrollStyled } from 'components';

type Props = {
  loadingDataGroup: boolean;
  patientChecklists?: PatientChecklistFragment[];
};

export const ChecklistTag: React.FC<Props> = ({
  loadingDataGroup,
  patientChecklists,
}) => {
  const checklistSelected = useRef<PatientChecklistFragment | undefined>(
    undefined,
  );

  const detailChecklistResult = useRef<ResultChecklist | undefined>(undefined);

  const { getPatientChecklists } = useServices();

  const [isOpenChecklist, setOpenChecklist] = useToogleDialog();

  const [isOpenChecklistResult, setOpenChecklistResult] = useToogleDialog();

  const [open, close, DefaultPopover, defaultPopoverProps] = usePopover();

  const patientTagsContext = useContext(PatientTagsContext);

  //check checklistresult tab is opened
  const isOnTab = useMemo(() => {
    return patientTagsContext?.isExtend && patientTagsContext?.indexTab === 7;
  }, [patientTagsContext]);

  const openAddDialog = useCallback(() => {
    if (isOnTab) {
      patientTagsContext?.handleSetDialogOpen(`addChecklistResult`);
      return;
    }
    setOpenChecklist(true);
    checklistSelected.current = undefined;
  }, [isOnTab, patientTagsContext, setOpenChecklist]);

  const updateChecklist = () => {
    if (isOnTab) {
      patientTagsContext?.handleSetDialogOpen(`addChecklistResult`);
      return;
    }
    setOpenChecklist(true);
    close();
  };

  const updateChecklistResult = (item: PatientChecklistFragment) => {
    checklistSelected.current = item;
    setOpenChecklistResult(true);
    close();
  };

  const patientChecklist = useMemo(() => {
    return checklistSelected.current
      ? getPatientChecklists(checklistSelected.current)
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientChecklists, checklistSelected.current]);

  return (
    <>
      <DefaultPopover {...defaultPopoverProps}>
        <Box p={0.5} display="flex" flexDirection="column">
          <Tooltip title="Replace Checklist">
            <IconButton onClick={updateChecklist}>
              <PlaylistEdit color="primary" />
            </IconButton>
          </Tooltip>
          {patientChecklist?.checklist && (
            <ButtonDetachChecklist
              isDetach={
                !!checklistSelected.current?.treatment ||
                !!checklistSelected.current?.survey
              }
              patientChecklistId={patientChecklist?.patientChecklistId}
              checklistId={checklistSelected.current?.patientChecklistMonoId}
            />
          )}
        </Box>
      </DefaultPopover>

      {isOpenChecklist && (
        <DialogChecklist
          isAdding={!Boolean(checklistSelected.current)}
          isOpen={isOpenChecklist}
          toogleOpen={setOpenChecklist}
          checklistAttach={
            patientChecklist?.checklist?._id ||
            patientChecklist?.checklistAvailable?._id
          }
          patientChecklistId={patientChecklist?.patientChecklistId}
          checklistId={checklistSelected.current?.patientChecklistMonoId}
        />
      )}
      {isOpenChecklistResult && (
        <DialogResultChecklist
          open={isOpenChecklistResult}
          toogleDialog={setOpenChecklistResult}
          checklistId={checklistSelected.current?.patientChecklistMonoId}
          patientChecklistId={patientChecklist?.patientChecklistId}
          result={detailChecklistResult.current}
          checklistName={
            checklistSelected?.current?.patientChecklists?.checklist?.name ||
            checklistSelected?.current?.patientChecklists?.checklistAvailable
              ?.name ||
            ''
          }
          latestChecklistId={
            checklistSelected?.current?.patientChecklists?.checklist?._id ||
            checklistSelected?.current?.patientChecklists?.checklistAvailable
              ?._id
          }
        />
      )}
      {!patientTagsContext?.isInactive && (
        <ChipStyled
          colortext="true"
          className="mr-8"
          onClick={openAddDialog}
          label={
            <Box display="flex">
              <AddIcon fontSize="small" />
            </Box>
          }
        />
      )}
      {patientChecklists?.length !== 0 && <BoxShowMore index={7} />}
      {loadingDataGroup && <LoadingTags />}
      {patientChecklists && patientChecklists?.length > 0 && !loadingDataGroup && (
        <ScrollStyled
          loading={loadingDataGroup}
          listLength={
            patientChecklists
              ?.map(item =>
                item.patientChecklists?.resultChecklist?.filter(
                  checklistStatus =>
                    checklistStatus.status !== ChecklistStatus.Completed,
                ),
              )
              ?.filter(item => item?.length !== 0)?.length
          }
        >
          {patientChecklists?.map(item =>
            item.patientChecklists?.resultChecklist
              ?.filter(
                checklistStatus =>
                  checklistStatus.status !== ChecklistStatus.Completed,
              )
              .map(result => {
                return (
                  <ChipStyled
                    className="cursor-pointer"
                    onClick={() => {
                      updateChecklistResult(item);
                      detailChecklistResult.current = result;
                    }}
                    key={result?._id}
                    backgroundcolor={
                      result && COLOR_STATUS_CHECKLIST[result.status]?.color
                    }
                    label={
                      <Tooltip
                        title={
                          <Box display="flex" flexDirection="column">
                            {patientTagsContext?.generateInformation([
                              {
                                name: 'Name',
                                info:
                                  item?.patientChecklists?.checklist?.name ||
                                  item?.patientChecklists?.checklistAvailable
                                    ?.name ||
                                  'N/A',
                              },
                              {
                                name: 'Treatment',
                                info: item?.treatment?.name || 'N/A',
                              },
                              {
                                name: 'Survey',
                                info: item?.survey?.name || 'N/A',
                              },
                              {
                                name: 'Type',
                                info: item?.patientChecklists?.checklist
                                  ? 'Manual'
                                  : 'Auto',
                              },
                              {
                                name: 'Status',
                                info: result?.status
                                  ? COLOR_STATUS_CHECKLIST[result.status]?.text
                                  : 'N/A',
                              },
                              {
                                name: 'Completed Date',
                                info: item.updatedAt
                                  ? formatDate(item.updatedAt)
                                  : 'N/A',
                              },
                            ])}
                          </Box>
                        }
                      >
                        <TypoThreeDot variant="subtitle2">
                          {`${item?.patientChecklists?.checklist?.name ||
                            item?.patientChecklists?.checklistAvailable
                              ?.name} - ${formatDate(
                            result.updatedAt,
                            true,
                          )} - ${(result &&
                            COLOR_STATUS_CHECKLIST[result.status]?.text) ||
                            'N/A'}
                      `}
                        </TypoThreeDot>
                      </Tooltip>
                    }
                    onDelete={e => {
                      open(e);
                      checklistSelected.current = item;
                    }}
                    deleteIcon={
                      !patientTagsContext?.isInactive ? (
                        <MoreHorizIcon />
                      ) : (
                        <></>
                      )
                    }
                  />
                );
              }),
          )}
        </ScrollStyled>
      )}
    </>
  );
};

export default React.memo(ChecklistTag);
