import { useQuery } from '@apollo/react-hooks';
import { ME_CLIENT, USER_UPDATE_PROFILE } from 'gql';
import { Phone, useMutationCustom, useUpdateMeClient } from 'hooks';
import React from 'react';
import { useForm } from 'react-hook-form';
import { UserUpdateMutation, UserUpdateMutationVariables } from 'types.d';
import { BoxFieldStyled } from '../styles';
import { admin, navigator, user } from 'CONST';
import { ButtonLoading, UpdatePhoneField } from 'components';
import CheckIcon from '@material-ui/icons/Check';
import { TextField } from '@material-ui/core';
import { isBotMail } from 'share/utils';

export type FormData = {
  firstName: string;
  lastName: string;
  middleName?: string;
  organization: string;
  email?: string;
  phoneNumber?: string;
};

const UpdateProfileComponent = () => {
  const { data } = useQuery(ME_CLIENT);
  const { meClient } = data;
  const { handleUpdateMeClient } = useUpdateMeClient();

  const methods = useForm<FormData>({
    mode: 'onBlur',
  });

  const { register, handleSubmit, errors } = methods;

  const registerPhone = (meClient?.phones as Phone[])?.find(
    item => item.default,
  );

  const [userUpdate, { loading }] = useMutationCustom<
    UserUpdateMutation,
    UserUpdateMutationVariables
  >({
    api: USER_UPDATE_PROFILE,
    textSuccess: "Update user's profile successfully",
    callbackSuccess: data => {
      handleUpdateMeClient({ ...data.updateUserProfile });
    },
  });

  const onSubmitForm = handleSubmit(
    ({ firstName, lastName, middleName, phoneNumber }) => {
      if (meClient.email) {
        const defaultParams = {
          firstName: firstName,
          lastName: lastName,
          middleName: middleName ? middleName : null,
        };
        userUpdate({
          variables: {
            params:
              phoneNumber && phoneNumber !== '1'
                ? {
                    ...defaultParams,
                    phone: `${
                      phoneNumber?.includes('+')
                        ? phoneNumber
                        : `+${phoneNumber}`
                    }`,
                  }
                : (defaultParams as UserUpdateMutationVariables['params']),
          },
        });
      }
    },
  );
  return (
    <>
      {meClient?.email && (
        <>
          <TextField
            required
            fullWidth
            size="small"
            inputProps={{ maxLength: 255 }}
            defaultValue={meClient?.firstName}
            inputRef={register({
              validate: {
                required: value =>
                  value.trim() !== '' || 'This field is required',
              },
            })}
            error={!!errors.firstName}
            helperText={errors.firstName ? errors.firstName.message : ''}
            InputLabelProps={{ shrink: true }}
            id="firstName"
            name="firstName"
            label="First Name"
            variant="outlined"
            margin="dense"
          />
          <TextField
            fullWidth
            size="small"
            inputProps={{ maxLength: 255 }}
            defaultValue={meClient?.middleName || ''}
            inputRef={register}
            InputLabelProps={{ shrink: true }}
            id="middleName"
            name="middleName"
            label="Middle Name"
            variant="outlined"
            margin="dense"
          />
          <TextField
            fullWidth
            required
            size="small"
            inputProps={{ maxLength: 255 }}
            defaultValue={meClient?.lastName}
            InputLabelProps={{ shrink: true }}
            id="lastName"
            name="lastName"
            inputRef={register({
              validate: {
                required: value =>
                  value.trim() !== '' || 'This field is required',
              },
            })}
            error={!!errors.lastName}
            helperText={errors.lastName ? errors.lastName.message : ''}
            label="Last Name"
            variant="outlined"
            margin="dense"
          />
          <TextField
            disabled
            size="small"
            fullWidth
            defaultValue={isBotMail(meClient!.email) ? 'N/A' : meClient!.email}
            margin="dense"
            InputLabelProps={{ shrink: true }}
            id="email"
            label="Email"
            type="text"
            name="email"
            InputProps={{
              inputProps: { tabIndex: 3 },
            }}
            variant="outlined"
            inputRef={register}
          />
          <UpdatePhoneField
            methodsProfile={methods}
            phone={registerPhone?.phone}
          />
          {(meClient?.role === navigator ||
            meClient?.role === admin ||
            meClient?.role === user) && (
            <TextField
              fullWidth
              size="small"
              disabled
              inputProps={{ maxLength: 255 }}
              defaultValue={meClient?.organization || 'N/A'}
              InputLabelProps={{ shrink: true }}
              id="organization"
              name="organization"
              inputRef={register}
              label="Organization"
              variant="outlined"
              margin="dense"
            />
          )}
        </>
      )}

      <BoxFieldStyled>
        <ButtonLoading
          isSubmit
          text="Save"
          size="medium"
          Icon={<CheckIcon />}
          callbackClick={onSubmitForm}
          loading={loading}
          className="text-tranform-none mt-8"
        />
      </BoxFieldStyled>
    </>
  );
};
export default UpdateProfileComponent;
