import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { defaultTheme } from 'share/component_css';
import 'react-mde/lib/styles/css/react-mde-all.css';
import 'tabulator-tables/dist/css/tabulator.min.css';
import 'survey-analytics/survey.analytics.tabulator.css';
import 'react-sortable-tree/style.css';
import 'survey-react/survey.min.css';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { PromtInstallProvider } from 'components';
import { ClientProvider } from 'client/ClientProvider';

// check update token when exprired
// const checkUpdateToken = new ApolloLink((operation, forward) => {
//   return forward(operation).map(response => {
//     return response;
//   });
// });

// client.onResetStore(() => cache.writeData({ data }));
ReactDOM.render(
  <ClientProvider>
    <DndProvider backend={HTML5Backend}>
      <MuiThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            autoHideDuration={2000}
            maxSnack={3}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <PromtInstallProvider>
                <App />
              </PromtInstallProvider>
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </MuiThemeProvider>
    </DndProvider>
  </ClientProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
