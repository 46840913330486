import React, { useContext, useMemo } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import {
  PatientCptCodeStatus,
  PatientTimeRecordFragment,
  PatientTimeReportFragment,
  ShowSaveRecordingQuery,
  ShowSaveRecordingQueryVariables,
  TSaveRecording,
} from 'types.d';
import {
  TypoThreeDot,
  ChipStyled,
  ProgressButtonStyled,
  WrapButtonProgress,
  TypographyBold,
} from 'share/component_css';
import {
  PatientContext,
  PatientDetailContext,
  PatientTagsContext,
} from 'share/context';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ListAltIcon from '@material-ui/icons/ListAlt';
import StopIcon from '@material-ui/icons/Stop';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { usePopover, useToogleDialog, useTimeRecord } from 'hooks';
import DialogBilling from './DialogBilling';
import { renderTime } from 'share/utils';
import { DeleteConfirm } from 'components';
import { DialogAddTimeRecord } from './DialogAddTimeRecord';
import DeleteIcon from '@material-ui/icons/Delete';
import { useServices } from '../../services';
import { format, isSameMonth, isSameYear, max } from 'date-fns';
import { styled } from '@material-ui/core/styles';
import { DEFAULT_COLOR_TAG } from 'CONST';
import { blue, green, red } from '@material-ui/core/colors';
import { useQuery } from '@apollo/react-hooks';
import { SHOW_SAVE_RECORDING } from 'gql';
import { useSnackbar } from 'notistack';

type Props = {
  item: PatientTimeRecordFragment;
  openDialogRecord: (report: PatientTimeRecordFragment) => void;
  record: React.MutableRefObject<PatientTimeRecordFragment | undefined>;
};

const TypographyTimer = styled(Typography)({
  color: '#FFC700',
  marginLeft: '4px',
});

export const ReportTag: React.FC<Props> = ({
  item,
  openDialogRecord,
  record,
}) => {
  const patientTagsContext = useContext(PatientTagsContext);

  const {
    handleDeleteRecord,
    loadingDeleteRecord,
    handleCreateRecord,
    loadingCreateRecord,
    loadingToggleStatus,
    toogleStatusRecord,
    toogleSaveRecording,
  } = useServices();

  const {
    convertDateTimeByTimezonecallback,
    isHavingPermissionTimeRecord,
    isAbleToDeleteModule,
    getLastRecord,
  } = useTimeRecord();

  const [openDelete, setOpenDelete] = useToogleDialog();

  const { enqueueSnackbar } = useSnackbar();

  const [openDialogAddRecord, setOpenDialogAddRecord] = useToogleDialog();

  const [open, close, DefaultPopover, defaultPopoverProps] = usePopover();

  const [openDialogBilling, setOpenDialogBilling] = useToogleDialog();

  const patientContext = useContext(PatientContext);

  const patientDetailContext = useContext(PatientDetailContext);

  const { data: dataShowSaveRecord } = useQuery<
    ShowSaveRecordingQuery,
    ShowSaveRecordingQueryVariables
  >(SHOW_SAVE_RECORDING);

  const lastRecord = useMemo(() => {
    return getLastRecord(item);
  }, [item, getLastRecord]);

  const isCheckingPermissionTimeRecord = useMemo(() => {
    return isHavingPermissionTimeRecord(lastRecord?.owner.idOwner || '');
  }, [lastRecord, isHavingPermissionTimeRecord]);

  const isOnTab = useMemo(() => {
    return patientTagsContext?.isExtend && patientTagsContext?.indexTab === 4;
  }, [patientTagsContext]);

  const deleteRecord = () => {
    if (isOnTab) {
      patientTagsContext?.handleSetDialogOpen(
        `deleteRecord-${record.current?._id}`,
      );
      return;
    }
    setOpenDelete(true);
  };

  const startRecord = () => {
    if (isOnTab) {
      record.current = item;
      patientTagsContext?.handleSetDialogOpen(
        `startNow-${record.current?._id}`,
      );
      return;
    }
    handleCreateRecord(item?._id, () => {});
  };

  const continueRecord = (report: PatientTimeReportFragment) => {
    patientContext?.handleContinue(report?.second || 0);
    toogleSaveRecording({
      variables: {
        input: {
          _id: report?._id,
          startedAt: report?.startedAt,
          stoppedAt: report?.stoppedAt,
          idCptCode: item?._id,
          idPatient: item?.patient,
          __typename: 'TSaveRecording',
        } as TSaveRecording,
      },
    });
    enqueueSnackbar('Keep running the record', {
      variant: 'success',
    });
  };

  const handleStartRecord = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (lastRecord?.state === PatientCptCodeStatus.Pause) {
      toogleStatusRecord(
        () => {
          continueRecord(lastRecord as PatientTimeReportFragment);
        },
        lastRecord?._id,
        PatientCptCodeStatus.Running,
      );
      return;
    }
    startRecord();
  };
  const endAdornment = () => {
    return (
      <WrapButtonProgress className="ml-8">
        <IconButton
          color={
            lastRecord?.state === PatientCptCodeStatus.Running
              ? 'secondary'
              : 'inherit'
          }
          size="small"
        >
          {lastRecord?.state === PatientCptCodeStatus.Running &&
            isCheckingPermissionTimeRecord && (
              <Tooltip title="Stop">
                <StopIcon
                  fontSize="small"
                  onClick={() => openDialogRecord(item)}
                />
              </Tooltip>
            )}
          {!dataShowSaveRecord?.showSaveRecording && (
            <>
              {lastRecord?.state === PatientCptCodeStatus.Pause &&
                isCheckingPermissionTimeRecord && (
                  <Tooltip title="Continue">
                    <PlayCircleFilledIcon
                      fontSize="small"
                      onClick={handleStartRecord}
                    />
                  </Tooltip>
                )}
              {(lastRecord?.state === PatientCptCodeStatus.Stopped ||
                !lastRecord) && (
                <Tooltip title="Start">
                  <PlayCircleFilledIcon
                    fontSize="small"
                    onClick={handleStartRecord}
                  />
                </Tooltip>
              )}
            </>
          )}
        </IconButton>
        {(loadingCreateRecord || loadingToggleStatus) && (
          <ProgressButtonStyled size={25} sizearound="small" color="inherit" />
        )}
      </WrapButtonProgress>
    );
  };

  const time = useMemo(() => {
    return patientContext?.timer;
  }, [patientContext]);

  const getClosestMonth = (data: PatientTimeReportFragment[] | null) => {
    return data?.filter(
      item =>
        isSameMonth(
          new Date(item?.stoppedAt),
          max(data?.map(item => new Date(item?.stoppedAt))),
        ) &&
        isSameYear(
          new Date(item?.stoppedAt),
          max(data?.map(item => new Date(item?.stoppedAt))),
        ),
    );
  };

  const renderColor = (record?: PatientTimeReportFragment) => {
    switch (record?.state) {
      case PatientCptCodeStatus.Pause:
        return red[700];
      case PatientCptCodeStatus.Running:
        return green[700];
      case PatientCptCodeStatus.Stopped:
        return blue[700];
      default:
        return blue[700];
    }
  };

  const renderTimeLabel = (record?: PatientTimeReportFragment) => {
    const recordRunning = dataShowSaveRecord?.showSaveRecording;
    if (record?.state === PatientCptCodeStatus.Stopped || !lastRecord) {
      return '';
    }
    return ` - ${renderTime(
      time &&
        time?.seconds > 0 &&
        recordRunning?.idPatient === patientDetailContext?.patient?._id &&
        recordRunning?.idCptCode === item?._id
        ? time.seconds
        : record?.second || 0,
    )}`;
  };

  return (
    <>
      {openDialogBilling && (
        <DialogBilling
          openDialogBilling={openDialogBilling}
          setOpenDialogBilling={setOpenDialogBilling}
          listRecord={item?.timeReport || []}
          code={item.code}
        />
      )}
      {/*  */}
      {openDelete && (
        <DeleteConfirm
          loading={loadingDeleteRecord}
          handleConfirm={() =>
            handleDeleteRecord(record.current?._id, () => {
              if (
                getLastRecord(record.current)?.state ===
                PatientCptCodeStatus.Running
              ) {
                patientContext?.handleStop();
                toogleSaveRecording({
                  variables: {
                    input: null,
                  },
                });
              }
              close();
              setOpenDelete(false);
            })
          }
          open={openDelete}
          toogle={setOpenDelete}
        />
      )}
      {openDialogAddRecord && (
        <DialogAddTimeRecord
          openDialogAddRecord={openDialogAddRecord}
          setOpenDialogAddRecord={setOpenDialogAddRecord}
          recordSelected={getLastRecord(record.current)}
          idCptCode={record.current?._id}
        />
      )}

      <DefaultPopover {...defaultPopoverProps}>
        <Box p={0.5} display="flex" flexDirection="column">
          <Tooltip title="Delete">
            <IconButton
              onClick={deleteRecord}
              aria-label="delete"
              color="secondary"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </DefaultPopover>

      <ChipStyled
        className="cursor-pointer"
        key={item?._id}
        backgroundcolor={renderColor(lastRecord) || DEFAULT_COLOR_TAG}
        onClick={() => openDialogRecord(item)}
        label={
          <Box alignItems="center" display="flex">
            <TypoThreeDot variant="subtitle2">{item?.code}</TypoThreeDot>
            <TypographyBold variant="subtitle2" className="ml-4">
              -{' '}
              {convertDateTimeByTimezonecallback(date => {
                return format(new Date(date), 'MM/yy');
              }, item?.lastStartedAt)}{' '}
              -
            </TypographyBold>
            <TypographyTimer variant="subtitle2">
              {renderTime(
                getClosestMonth(item?.timeReport)?.reduce(
                  (acc, curr) => acc + (curr.second ? curr?.second : 0),
                  0,
                ) || 0,
              )}
            </TypographyTimer>

            <Typography variant="subtitle2" className="ml-4">
              {renderTimeLabel(lastRecord)}
            </Typography>
            {endAdornment()}
            {/* no display billing if last record of cpt code is paused */}
            {item?.lastStoppedAt &&
              lastRecord?.state !== PatientCptCodeStatus.Pause && (
                <Tooltip title="Billing">
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      setOpenDialogBilling(true);
                      record.current = item;
                    }}
                    size="small"
                    color="inherit"
                  >
                    <ListAltIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
          </Box>
        }
        onDelete={e => {
          open(e);
          record.current = item;
        }}
        deleteIcon={
          !patientTagsContext?.isInactive &&
          isAbleToDeleteModule(item?.timeReport || []) ? (
            <MoreHorizIcon />
          ) : (
            <></>
          )
        }
      />
    </>
  );
};

export default React.memo(ReportTag);
