import React from 'react';
import { styled } from '@material-ui/core/styles';
import { DEFAULT_IMAGE } from 'CONST';
import { renderMedia } from 'share/utils';
import { ImageStyled, ReactPlayerStyled } from 'share/component_css';
type Props = {
  mainImage: string;
  mainVideo: string;
};

const ImgStyled = styled(ImageStyled)(({ src }: { src: string }) => ({
  backgroundImage: `url(${src || DEFAULT_IMAGE})`,
}));

export const MediaTreatment: React.FC<Props> = ({ mainVideo, mainImage }) => {
  const renderThumbnail = () => {
    return mainVideo ? (
      <ReactPlayerStyled
        isdisplay="true"
        playing={true}
        playsinline
        controls
        url={renderMedia(mainVideo)}
        width="100%"
        height="100%"
        muted
        config={{
          file: {
            attributes: {
              muted: true,
            },
          },
        }}
      />
    ) : (
      <ImgStyled src={mainImage ? renderMedia(mainImage) : DEFAULT_IMAGE} />
    );
  };

  return <>{renderThumbnail()}</>;
};
export default React.memo(MediaTreatment);
