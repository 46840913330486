import React, { useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import RedditIcon from '@material-ui/icons/Reddit';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import { Download } from 'mdi-material-ui';
import { getLocalStorage, handleError, renderPDFLink } from 'share/utils';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  GetTreatmentPDFQuery,
  GetTreatmentPDFQueryVariables,
  TreatmentDetailFragment,
} from 'types.d';
import { GET_TREEATMENT_PDF } from 'gql';
import { useCopyToClipBoard } from 'hooks';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useSnackbar } from 'notistack';
import { trackButtonClickEvent } from 'modules/google_tag_manager';
import { DEFAULT_VOICE_SETTINGS, ORG_LOGO } from 'CONST';

type Props = {
  data: TreatmentDetailFragment;
};

export const ICON_FONT_SIZE = 25;

export const SocialShare: React.FC<Props> = ({ data }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { loadingCopy, handleCopyToClipBoard } = useCopyToClipBoard();

  const [disable, setDisable] = useState(false)

  const [snackbarKey, setSnackbarKey] = useState<
    string | number | null | undefined
  >(null);

  const getURL = useCallback(
    (url: string) => `https://www.treatmentgps.com/treatment/${url}`,
    [],
  );

  var synth = window.speechSynthesis;

  const encodedTitle = encodeURIComponent(data!.name!);

  const encodedUrl = encodeURIComponent(getURL(data!.slug!));

  const socialShares = [
    {
      name: 'Email',
      url: `mailto:?subject=${encodedTitle}&body=From TreatmentGPS:%0A%0A${data!
        .name!}%0A%0A${getURL(data!.slug!)}`,
      icon: (
        <EmailIcon
          style={{ color: '#8B8585', fontSize: `${ICON_FONT_SIZE}px ` }}
        />
      ),
    },
    {
      name: 'WhatsApp',
      url: `https://api.whatsapp.com/send?text=${encodedTitle} ${encodedUrl}`,
      icon: (
        <WhatsAppIcon
          style={{ color: '#25D366', fontSize: `${ICON_FONT_SIZE}px ` }}
        />
      ),
    },
    {
      name: 'Facebook',
      url: `https://www.facebook.com/dialog/feed?app_id=${process.env.REACT_APP_ID_FACEBOOK!}&link=${encodedUrl}&name=${encodedTitle}`,
      icon: (
        <FacebookIcon
          style={{ color: '#1877F2', fontSize: `${ICON_FONT_SIZE}px ` }}
        />
      ),
    },
    {
      name: 'Twitter',
      url: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`,
      icon: (
        <TwitterIcon
          style={{ color: '#1DA1F2', fontSize: `${ICON_FONT_SIZE}px ` }}
        />
      ),
    },
    {
      name: 'Reddit',
      url: `http://www.reddit.com/submit?url=${encodedUrl}&title=${encodedTitle}`,
      icon: (
        <RedditIcon
          style={{ color: '#FF4500', fontSize: `${ICON_FONT_SIZE}px ` }}
        />
      ),
    },
  ];

  const [exportPDF] = useLazyQuery<
    GetTreatmentPDFQuery,
    GetTreatmentPDFQueryVariables
  >(GET_TREEATMENT_PDF, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (snackbarKey) closeSnackbar(snackbarKey);

      if (data?.getTreatmentPDF) {
        window.open(data.getTreatmentPDF, '_blank');
        trackButtonClickEvent('Treatment exported to PDF');
        setDisable(false)
      }
    },
    onError(error) {
      if (snackbarKey) closeSnackbar(snackbarKey);

      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
      setDisable(false)
    },
  });

  const handleExportPDF = () => {
    var default_voice_settings = JSON.parse(
      getLocalStorage(DEFAULT_VOICE_SETTINGS)!,
    )
    setDisable(true)
    const treatmentPDFExists = data?.pdfLink;
    const voices = synth.getVoices();
    var params: any =
    {
      _id: data?._id,
    }

    if (voices[default_voice_settings.voice].lang.includes('zh')) {
      if (voices[default_voice_settings.voice].lang == 'zh-HK') {
        params.targetLanguage = 'zh-CN';
      } else {
        params.targetLanguage = voices[default_voice_settings.voice].lang;
      }
    } else {
      params.targetLanguage = voices[default_voice_settings.voice].lang.replace(
        /-\w+$/,
        '',
      );
    }

    if (treatmentPDFExists?.includes("/" + params.targetLanguage + "/")) {
      window.open(renderPDFLink(data!.pdfLink!), '_blank');
    } else {
      setSnackbarKey(
        enqueueSnackbar('PDF Downloading', {
          persist: true,
          variant: 'info',
        }),
      );
      var logo = getLocalStorage(ORG_LOGO)
      var params: any =
      {
        _id: data?._id,
      }
      if (logo)
        params.logoURL = logo;

      if (voices[default_voice_settings.voice].lang.includes('zh')) {
        if (voices[default_voice_settings.voice].lang == 'zh-HK') {
          params.targetLanguage = 'zh-CN';
        } else {
          params.targetLanguage = voices[default_voice_settings.voice].lang;
        }
      } else {
        params.targetLanguage = voices[default_voice_settings.voice].lang.replace(
          /-\w+$/,
          '',
        );
      }

      exportPDF({
        variables: {
          params,
        },
      });
    }
  };

  const trackTreatmentSharingForGA = (socialPlatform: string) =>
    trackButtonClickEvent('Treatment shared on/via ' + socialPlatform);

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Tooltip arrow title={'Copy Link'}>
        <IconButton
          component="a"
          disabled={loadingCopy}
          color="primary"
          onClick={() =>
            handleCopyToClipBoard(getURL(data!.slug!), '', () =>
              trackButtonClickEvent('copied link'),
            )
          }
          style={{ margin: '0px 2px' }}
        >
          <FileCopyIcon style={{ fontSize: `22px` }} />
        </IconButton>
      </Tooltip>
      {socialShares.map((social, index) => (
        <Tooltip title={`Share on ${social.name}`} key={index}>
          <IconButton
            component="a"
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`Share on ${social.name}`}
            onClick={() => trackTreatmentSharingForGA(social.name)}
          >
            {social.icon}
          </IconButton>
        </Tooltip>
      ))}
      <Tooltip title={`Export to PDF`}>
        <IconButton component="a" color="secondary" onClick={handleExportPDF} disabled={disable}>
          <Download
            style={{ fontSize: `${ICON_FONT_SIZE}px `, paddingTop: '3px' }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default React.memo(SocialShare);
