import React, { useRef, useMemo, useContext } from 'react';
import {
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  Checkbox,
  Chip,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { formatDate } from 'share/utils';
import {
  PaperBorder,
  TableCellMaxWidthStyled,
  TableContainerStyled,
  TypoThreeDotsLine,
} from 'share/component_css';
import EditIcon from '@material-ui/icons/Edit';
import { ButtonDelete, NoDataTable } from 'components';
import { PatientNoteFragment, NoteStatus, SubNoteFragment } from 'types.d';
import { PatientDetailContext } from 'share/context';

type Props = {
  subNotes?: PatientNoteFragment['notes'];
  changeCheck: (id?: string) => void;
  checkedAll: boolean;
  listCheckedSign: string[];
  editSubNote: (item: SubNoteFragment) => void;
  loadingDeleteSubNote: boolean;
  handleDeleteSub: (id: string) => void;
};

const colorChipByStatus: {
  [key: string]: 'primary' | 'secondary' | 'default';
} = {
  SIGNED: 'primary',
  PUBLISHED: 'secondary',
  DRAFT: 'default',
};

export const TableNotes: React.FC<Props> = ({
  subNotes,
  changeCheck,
  checkedAll,
  listCheckedSign,
  editSubNote,
  loadingDeleteSubNote,
  handleDeleteSub,
}) => {
  const patientDetailContext = useContext(PatientDetailContext);
  const subNoteDelete = useRef('');

  const subNotesSort = useMemo(() => {
    return subNotes?.sort((a, b) => {
      return (
        b.status.localeCompare(a.status) ||
        new Date(b?.notedAt).getTime() - new Date(a?.notedAt).getTime()
      );
    });
  }, [subNotes]);

  const isInactive = useMemo(() => {
    return patientDetailContext?.isInactive;
  }, [patientDetailContext]);
  return (
    <>
      <TableContainerStyled component={PaperBorder}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {!isInactive && (
                <TableCell>
                  <Checkbox
                    size="small"
                    style={{ padding: 0 }}
                    checked={checkedAll}
                    onChange={() => changeCheck()}
                  />
                </TableCell>
              )}
              <TableCell>Description</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Updated At</TableCell>
              <TableCell>Type</TableCell>
              <TableCell align={!isInactive ? 'inherit' : 'right'}>
                Status
              </TableCell>
              {!isInactive && <TableCell align="right">Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {!subNotes && <NoDataTable colSpan={6} marginTop={1} />}
            {subNotes &&
              subNotes?.length !== 0 &&
              subNotesSort?.map(item => (
                <TableRow key={item.idNote}>
                  {!isInactive && (
                    <TableCell>
                      <Checkbox
                        style={{ padding: 0 }}
                        size="small"
                        disabled={
                          item.status === NoteStatus.Signed ||
                          item.status === NoteStatus.Published
                        }
                        checked={listCheckedSign.includes(item.idNote)}
                        onChange={() => changeCheck(item.idNote)}
                      />
                    </TableCell>
                  )}
                  <TableCellMaxWidthStyled maxwidth="2000px">
                    <TypoThreeDotsLine variant="subtitle2">
                      {item.description}
                    </TypoThreeDotsLine>
                  </TableCellMaxWidthStyled>
                  <TableCell>{formatDate(item.notedAt, true)}</TableCell>
                  <TableCell>{formatDate(item.updatedAt, true)}</TableCell>
                  <TableCell>{item.type?.name}</TableCell>
                  <TableCell align={!isInactive ? 'inherit' : 'right'}>
                    <Chip
                      label={item.status}
                      color={colorChipByStatus[item.status]}
                    />
                  </TableCell>
                  {!isInactive && (
                    <TableCell align="right">
                      {item.status === NoteStatus.Draft && (
                        <>
                          <Tooltip arrow title="Edit">
                            <IconButton
                              onClick={() => editSubNote(item)}
                              color="primary"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <ButtonDelete
                            loadingDelete={loadingDeleteSubNote}
                            deleteItem={(key: string) => {
                              subNoteDelete.current = item.idNote;
                              handleDeleteSub(key);
                            }}
                            id={item.idNote}
                          />
                        </>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainerStyled>
    </>
  );
};

export default React.memo(TableNotes);
