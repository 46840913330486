import React, { useEffect, useMemo, useCallback, useRef } from 'react';
import {
  Typography,
  DialogActions,
  DialogContent,
  Dialog,
} from '@material-ui/core';
import { DialogTitleClose, AutoCompleteSelect, CheckboxList } from 'components';
import { useMutationCustom } from 'hooks';
import {
  ADD_MORE_TREATMENT,
  ADD_MORE_TREATMENT_NOTE_TYPE,
  GET_COMMON_DATA,
  GET_FOLDER_TASK_TYPE,
} from 'gql';
import {
  AddMoreTreatmentMutation,
  AddMoreTreatmentMutationVariables,
  GetCommonDataQuery,
  GetCommonDataQueryVariables,
  TreatmentType,
  CommonTreatmentFragment,
  GetFolderTaskTypeQuery,
  GetFolderTaskTypeQueryVariables,
  AddMoreTreatmentNoteTypeMutation,
  AddMoreTreatmentNoteTypeMutationVariables,
} from 'types.d';
import DialogButton from 'components/DialogButton';
import { useForm } from 'react-hook-form';
import { trimObjectValue } from 'share/utils';
import { useLazyQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
  addedTreatments: string[];
  taskTypeId?: string;
  noteTypeId?: string;
  isAddingFolder?: boolean;
};

type FormData = {
  treatments: CommonTreatmentFragment[];
  folders: CommonTreatmentFragment[];
};

export const DialogSelectTreatment: React.FC<Props> = ({
  open,
  toogleDialog,
  addedTreatments,
  taskTypeId,
  isAddingFolder,
  noteTypeId,
}) => {
  const { handleSubmit, errors, setValue, control, watch } = useForm<FormData>({
    mode: 'onBlur',
  });

  const watchTreatments = watch('treatments');

  const watchFolders = watch('folders');

  const listRemovedFolder = useRef<string[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const [getListTreatment, { data: dataListTreatment }] = useLazyQuery<
    GetCommonDataQuery,
    GetCommonDataQueryVariables
  >(GET_COMMON_DATA, {
    fetchPolicy: 'no-cache',
  });
  const [getFolder, { data: dataFolder }] = useLazyQuery<
    GetFolderTaskTypeQuery,
    GetFolderTaskTypeQueryVariables
  >(GET_FOLDER_TASK_TYPE, {
    fetchPolicy: 'no-cache',
  });

  const [addMoreTreatment, { loading }] = useMutationCustom<
    AddMoreTreatmentMutation,
    AddMoreTreatmentMutationVariables
  >({
    api: ADD_MORE_TREATMENT,
    textSuccess: `Add more ${
      isAddingFolder ? 'folder' : 'associated treatment'
    } successfully`,
    callbackSuccess: data => {
      handleCancel();
    },
  });

  const [
    addMoreTreatmentNoteType,
    { loading: loadingNote },
  ] = useMutationCustom<
    AddMoreTreatmentNoteTypeMutation,
    AddMoreTreatmentNoteTypeMutationVariables
  >({
    api: ADD_MORE_TREATMENT_NOTE_TYPE,
    textSuccess: `Add more ${
      isAddingFolder ? 'folder' : 'associated treatment'
    } successfully`,
    callbackSuccess: data => {
      handleCancel();
    },
  });

  const handleSave = handleSubmit(data => {
    const dataTrim = { ...trimObjectValue(data) };
    if (noteTypeId) {
      addMoreTreatmentNoteType({
        variables: {
          params: {
            patientNoteTypeId: noteTypeId,
            treatmentIds: isAddingFolder
              ? dataTrim.folders.map(item => item._id) || null
              : dataTrim?.treatments?.map(item => item._id) || null,
          },
        },
      });
      return;
    }
    addMoreTreatment({
      variables: {
        params: {
          followUpTypeId: taskTypeId,
          treatmentIds: isAddingFolder
            ? dataTrim.folders.map(item => item._id) || null
            : dataTrim?.treatments?.map(item => item._id) || null,
        },
      },
    });
  });

  const enterDialog = () => {
    if (isAddingFolder) {
      getFolder();
      return;
    }
    getListTreatment({
      variables: {
        params: {
          type: [TreatmentType.Treatment],
          sortByOrder: { name: 1 },
        },
      },
    });
  };

  const handleCancel = () => {
    toogleDialog(false);
  };

  //check set 10 treatments for assign treatment
  useEffect(() => {
    if (watchTreatments?.length === 11) {
      const listTreatment = [...watchTreatments];
      listTreatment.pop();
      enqueueSnackbar('Please input no more than 10 items', {
        variant: 'error',
      });
      setValue('treatments', listTreatment);
    }
  }, [enqueueSnackbar, setValue, watchTreatments]);

  useEffect(() => {
    if (watchFolders?.length === 11) {
      const listFolders = [...watchFolders];
      listFolders.pop();
      setValue('folders', listFolders);
      enqueueSnackbar('Please input no more than 10 items', {
        variant: 'error',
      });
      return;
    }
    if (watchFolders && watchFolders.length !== 0) {
      const folderFilter = [
        ...watchFolders.filter(
          item => !listRemovedFolder.current.includes(item._id),
        ),
      ];
      if (folderFilter.length !== watchFolders.length) {
        setValue('folders', [
          ...watchFolders.filter(
            item => !listRemovedFolder.current.includes(item._id),
          ),
        ]);
      }
    }
  }, [enqueueSnackbar, setValue, watchFolders]);

  const getChildren = useCallback(
    (ids: string[], isIncluded?: boolean) => {
      if (ids.length !== 0) {
        if (isIncluded) {
          listRemovedFolder.current = [...listRemovedFolder.current, ...ids];
        }
        ids.forEach(item => {
          const subs = dataFolder?.getFolder.nodes.filter(
            folder => folder.parentId === item,
          );
          if (subs?.length !== 0) {
            getChildren(subs?.map(item => item._id) || [], true);
          }
        });
      }
    },
    [dataFolder],
  );

  const listFolder = useMemo(() => {
    if (watchFolders && watchFolders.length !== 0) {
      getChildren([...addedTreatments, ...watchFolders.map(item => item._id)]);
    } else {
      getChildren([...addedTreatments]);
    }
    return dataFolder?.getFolder.nodes.filter(item => {
      return (
        item.subSections &&
        item.subSections?.filter(sub => sub.type === TreatmentType.Treatment)
          ?.length !== 0 &&
        !listRemovedFolder.current.includes(item._id)
      );
    });
  }, [addedTreatments, dataFolder, getChildren, watchFolders]);

  // const listFolder = useMemo(() => {
  //   return dataFolder?.getFolder.nodes.filter(item => {
  //     return (
  //       item.subSections &&
  //       item.subSections?.filter(sub => sub.type === TreatmentType.Treatment)
  //         ?.length !== 0
  //     );
  //   });
  // }, [dataFolder]);
  return (
    <>
      <Dialog onEntered={enterDialog} open={open} maxWidth="xs" fullWidth>
        <DialogTitleClose
          title={`Select ${isAddingFolder ? 'Folders' : 'Treatments'}`}
          onClose={() => toogleDialog(false)}
        />
        <DialogContent>
          {isAddingFolder ? (
            <AutoCompleteSelect
              small
              disablePortal
              required
              multiple
              error={!!errors.folders}
              name="folders"
              label="Folders"
              disabledOptions={addedTreatments || []}
              control={control}
              nameOption="name"
              options={listFolder || []}
              callbackRenderOption={(option, selected) => (
                <CheckboxList
                  checked={selected}
                  option={
                    <Typography variant="subtitle2">{option?.name}</Typography>
                  }
                />
              )}
            />
          ) : (
            <AutoCompleteSelect
              small
              disablePortal
              required
              multiple
              error={!!errors.treatments}
              name="treatments"
              label="Treatments"
              control={control}
              nameOption="name"
              options={dataListTreatment?.getTreatments.nodes || []}
              disabledOptions={addedTreatments || []}
              callbackRenderOption={(option, selected) => (
                <CheckboxList
                  checked={selected}
                  option={
                    <Typography variant="subtitle2">{option?.name}</Typography>
                  }
                />
              )}
            />
          )}
        </DialogContent>
        <DialogActions>
          <DialogButton isCancel onClickButton={handleCancel} />
          <DialogButton
            loading={loading || loadingNote}
            onClickButton={handleSave}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(DialogSelectTreatment);
