import React, { useState } from 'react';
import { DialogContent, Dialog } from '@material-ui/core';
import { DialogTitleClose } from 'components';
import SurveyDisplay from 'modules/patients/components/SurveyDisplay';
import { DynamicFormData } from 'types.d';
import { useEffectOnlyOnce } from 'hooks';
import * as Survey from 'survey-react';

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
  item: DynamicFormData;
};

export const DialogDynamicSurveyView: React.FC<Props> = ({
  open,
  toogleDialog,
  item,
}) => {
  const [survey, setSurvey] = useState<Survey.ReactSurveyModel | undefined>(
    undefined,
  );

  useEffectOnlyOnce(() => {
    const survey = new Survey.Model(JSON.parse(item.formJson));
    survey.data = JSON.parse(item?.answersJson);
    survey.showNavigationButtons = false;
    survey.mode = 'display';
    survey.showCompletedPage = false;
    setSurvey(survey);
  });

  return (
    <>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitleClose
          title={'Survey Response'}
          onClose={() => toogleDialog(false)}
        />
        <DialogContent>
          {survey && <SurveyDisplay survey={survey} />}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default React.memo(DialogDynamicSurveyView);
