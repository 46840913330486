import React, { useState, useCallback, useMemo } from 'react';
import { Popover } from '@material-ui/core';

type DefaultPopoverProps = {
  isOpen: boolean;
  anchorEl: HTMLElement | SVGSVGElement | null;
  close: (e?: any) => void;
};

type PopoverProps = DefaultPopoverProps & { callbackEnter?: () => void };

export const usePopover = (
  callbackClose?: () => void,
): [
  (event: React.MouseEvent<HTMLElement | SVGSVGElement>) => void,
  (e?: any) => void,
  any,
  DefaultPopoverProps,
  boolean,
  HTMLElement | SVGSVGElement | null,
] => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | SVGSVGElement | null>(
    null,
  );

  const open = useCallback(
    (event: React.MouseEvent<HTMLElement | SVGSVGElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const close = useCallback(
    e => {
      if (e) {
        e.stopPropagation();
      }
      setAnchorEl(null);
      if (callbackClose) {
        callbackClose();
      }
    },
    [callbackClose],
  );

  const isOpen = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);

  const defaultPopoverProps = {
    isOpen,
    anchorEl,
    close,
  };

  return [open, close, DefaultPopover, defaultPopoverProps, isOpen, anchorEl];
};

export const DefaultPopover: React.FC<PopoverProps> = ({
  children,
  isOpen,
  anchorEl,
  close,
  callbackEnter,
}) => {
  return (
    <Popover
      onEnter={callbackEnter}
      open={!!isOpen}
      onClose={close}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {children}
    </Popover>
  );
};
