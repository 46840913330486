import React, { useState, useCallback, useContext } from 'react';
import { Box, Grid, Hidden } from '@material-ui/core';
import { GroupCommentsFragment } from 'types.d';
import Comments from 'modules/treatments/components/Comments';
import { BoxCenterComment } from 'components';
import ListCommentByPatients from './ListCommentByPatients';
import { PatientDetailContext } from 'share/context';

type Props = {
  isSplitScreen: boolean;
};

export const CommentByPatient: React.FC<Props> = ({ isSplitScreen }) => {
  const [
    treatmentSelected,
    setTreatmentSelected,
  ] = useState<GroupCommentsFragment | null>(null);
  const patientDetailContext = useContext(PatientDetailContext);

  const handleSetTreatmentSelected = useCallback(
    (treatment: GroupCommentsFragment | null) => {
      setTreatmentSelected(treatment);
    },
    [],
  );

  return (
    <Grid container spacing={2}>
      <Hidden only="xs">
        <Grid item xs={12} sm={isSplitScreen ? 12 : 4}>
          <Box position="relative">
            <ListCommentByPatients
              treatmentSelected={treatmentSelected}
              setTreatmentSelected={handleSetTreatmentSelected}
            />
          </Box>
        </Grid>
      </Hidden>
      <Grid
        className="position-relative"
        item
        xs={12}
        sm={isSplitScreen ? 12 : 8}
      >
        {treatmentSelected ? (
          <Comments
            treatmentOwner={treatmentSelected?.treatment?.ownerId}
            treatmentId={treatmentSelected?.treatment?._id}
            contributors={treatmentSelected?.treatment?.contributorIds}
            hasBoxNoComments
            fromPatient={patientDetailContext?.patient?.owner?.user?.idUser}
            fixedHeight
          />
        ) : (
          <Box
            width="100%"
            borderColor="grey.300"
            borderRadius={8}
            border={1}
            position="relative"
            overflow="auto"
            height={500}
            px={2}
          >
            <BoxCenterComment />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(CommentByPatient);
