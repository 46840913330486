import React from 'react';
import ChangePassword from './components/ChangePassword';
import EmailAlias from './components/EmailAlias';
import { EmailPlus } from 'mdi-material-ui';
import {
  AvatarStyled,
  BoxProfileStyled,
  ProfileStyled,
  TypographyStyled,
} from './styles';
import { ME_CLIENT, ME } from 'gql';
import UpdateProfileComponent from './components/UpdateProfileForm';
import { Box, Grid, Typography, Tab } from '@material-ui/core';
import { admin, DEFAULT_AVATAR, navigator, user } from 'CONST';
import { TypographyBold, TypoThreeDot } from 'share/component_css';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EditIcon from '@material-ui/icons/Edit';
import { Tabs, Container } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { MeQuery, MeQueryVariables } from 'types.d';
import { Phone, useUpdateMeClient } from 'hooks';
import { formatPhoneNumber, isBotMail, setDataLocalStorage } from 'share/utils';
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box hidden={value !== index} className="h-100" {...other}>
        {value === index && (
          <Box pt={3} pb={3}>
            {children}
          </Box>
        )}
      </Box>
    </div>
  );
}

const Profile = () => {
  const { handleUpdateMeClient } = useUpdateMeClient();

  const { data } = useQuery(ME_CLIENT);

  const registerPhone = (data.meClient?.phones as Phone[])?.find(
    item => item.default,
  );

  const [value, setValue] = React.useState(0);

  useQuery<MeQuery, MeQueryVariables>(ME, {
    onCompleted(data) {
      handleUpdateMeClient(data.me?.remoteUser ? data.me.remoteUser : data.me);
      setDataLocalStorage(
        'remoteUser',
        JSON.stringify(data?.me?.remoteUser || null),
      );
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container className="mt-24" maxWidth="lg">
      <Grid container spacing={3}>
        {data && (
          <Grid item md={5} xs={12}>
            <BoxProfileStyled
              border={1}
              borderColor="grey.200"
              borderRadius={8}
              padding={0.1}
            >
              <ProfileStyled>
                <Box width="40%" my={0} mx="auto" style={{ marginTop: '30px' }}>
                  <Box position="relative" width="100%" paddingTop="100%">
                    <AvatarStyled
                      variant="circle"
                      alt="avatar"
                      src={DEFAULT_AVATAR}
                    />
                  </Box>
                </Box>
              </ProfileStyled>

              <Grid item xs={12}>
                <TypographyStyled
                  align="center"
                  variant="h4"
                  gutterBottom
                  color="primary"
                  style={{ overflowWrap: 'break-word' }}
                >{`${data.meClient?.firstName} ${data.meClient?.middleName ||
                  ''} ${data.meClient?.lastName}`}</TypographyStyled>
              </Grid>
              <Grid container>
                <Grid item sm={7} xs={12}>
                  <Box
                    bgcolor="grey.50"
                    p={2}
                    border={1}
                    borderLeft={0}
                    borderRight={0}
                    borderColor="grey.200"
                  >
                    <TypographyBold variant="h6" align="center">
                      Email
                    </TypographyBold>
                    <TypoThreeDot
                      color="textSecondary"
                      variant="subtitle1"
                      align="center"
                      className="w-100"
                    >
                      {!isBotMail(data.meClient?.email)
                        ? data.meClient?.email
                        : 'N/A'}
                    </TypoThreeDot>
                  </Box>
                </Grid>
                <Grid item sm={5} xs={12}>
                  <Box
                    bgcolor="grey.50"
                    p={2}
                    border={1}
                    borderRight={0}
                    borderColor="grey.200"
                  >
                    <TypographyBold variant="h6" align="center">
                      Phone
                    </TypographyBold>
                    <TypoThreeDot
                      color="textSecondary"
                      variant="subtitle1"
                      align="center"
                      className="w-100"
                    >
                      {registerPhone?.phone
                        ? formatPhoneNumber(registerPhone?.phone)
                        : 'N/A'}
                    </TypoThreeDot>
                  </Box>
                </Grid>
              </Grid>
              {(data.meClient?.role === navigator ||
                data.meClient?.role === admin ||
                data.meClient?.role === user) && (
                <Grid container>
                  <Grid item sm={7} xs={12}>
                    <Box
                      bgcolor="grey.50"
                      p={2}
                      border={1}
                      borderLeft={0}
                      borderRight={0}
                      borderColor="grey.200"
                    >
                      <TypographyBold variant="h6" align="center">
                        Organization
                      </TypographyBold>
                      <TypographyStyled
                        color="textSecondary"
                        variant="subtitle1"
                        align="center"
                      >
                        {data.meClient?.organization || 'N/A'}
                      </TypographyStyled>
                    </Box>
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <Box
                      bgcolor="grey.50"
                      p={2}
                      border={1}
                      borderRight={0}
                      borderColor="grey.200"
                    >
                      <TypographyBold variant="h6" align="center">
                        Role
                      </TypographyBold>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        align="center"
                      >
                        {data.meClient?.role}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </BoxProfileStyled>
          </Grid>
        )}
        <Grid item md={7} xs={12}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={value}
            onChange={handleChange}
            variant="standard"
          >
            <Tab label="Update profile" icon={<EditIcon />} />
            {data.meClient.isRegister && (
              <Tab label="Change password" icon={<LockOpenIcon />} />
            )}
            {data.meClient.isRegister && (
              <Tab label="Email alias" icon={<EmailPlus />} />
            )}
          </Tabs>
          <TabPanel value={value} index={0}>
            <UpdateProfileComponent />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ChangePassword />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <EmailAlias listEmailAlias={data.meClient?.secondaryEmails} />
          </TabPanel>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Profile;
