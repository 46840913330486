import React from 'react';
import { Typography, Box } from '@material-ui/core';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { styled } from '@material-ui/core/styles';

type Props = {
  text?: string;
};

export const BoxCenterStyled = styled(Box)({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
});

export const BoxCenterComment: React.FC<Props> = ({ text }) => {
  return (
    <BoxCenterStyled>
      <QuestionAnswerIcon color="disabled" style={{ fontSize: '4em' }} />
      <Typography variant="subtitle1" color="textSecondary">
        {text || 'You need to select treatment to get comments'}
      </Typography>
    </BoxCenterStyled>
  );
};

export default React.memo(BoxCenterComment);
