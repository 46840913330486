import React, { useState, useMemo } from 'react';
import {
  NoteTypeInput,
  UpdatePatientTimeRecordStatusMutation,
  UpdatePatientTimeRecordStatusMutationVariables,
  GetCptCodeDetailsQuery,
  GetCptCodeDetailsQueryVariables,
  PatientNoteTypeSettingFragment,
} from 'types.d';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Box,
  Typography,
} from '@material-ui/core';
import { DialogButton, DialogTitleClose } from 'components';
import { UPDATE_PATIENT_TIME_RECORD_STATUS, GET_CPT_CODE_DETAIL } from 'gql';
import {
  PatientTimeReportFragmentExtend,
  useMutationCustom,
  useTimeRecord,
  useUpdateMeClient,
} from 'hooks';
import { useLazyQuery } from '@apollo/react-hooks';
import { useNoteTypes } from '../../services/useNoteTypes';
import { TypographyBold } from 'share/component_css';
import InputMask from 'react-input-mask';
import { format, subSeconds, addSeconds, isSameDay } from 'date-fns';
import { renderTime } from 'share/utils';
import { customEvent } from 'modules/google_tag_manager';
type Props = {
  open: boolean;
  toogle: (status: boolean) => void;
  timeRecordSelected: PatientTimeReportFragmentExtend | null;
  idCode: string;
  codeName: string;
};

export const DialogUpdateRecord: React.FC<Props> = ({
  open,
  toogle,
  timeRecordSelected,
  idCode,
  codeName,
}) => {
  const [listNotes, setListNotes] = useState<PatientNoteTypeSettingFragment[]>(
    [],
  );

  const { meClient } = useUpdateMeClient();

  const {
    convertDateTimeByTimezone,
    convertDateTimeByTimezonecallback,
  } = useTimeRecord();

  const [timeSpent, setTimeSpent] = useState('');

  const objPatientNoteTypes = useMemo(() => {
    return (
      timeRecordSelected?.patientNoteTypes?.reduce((init, currentItem) => {
        return {
          ...init,
          [currentItem?.id._id as string]: currentItem?.description || '',
        };
      }, {} as { [key: string]: string }) || {}
    );
  }, [timeRecordSelected]);

  const {
    NoteTypeTable,
    noteTypeTableProps,
    NoteTypeAutocomplete,
  } = useNoteTypes(listNotes, setListNotes);

  const [getCptCodeDetail, { data: cptCodeDetail }] = useLazyQuery<
    GetCptCodeDetailsQuery,
    GetCptCodeDetailsQueryVariables
  >(GET_CPT_CODE_DETAIL, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      //set default value for note type box
      const noteTypesIds = timeRecordSelected?.patientNoteTypes?.map(
        item => item?.id._id,
      );
      const init: { [key: string]: PatientNoteTypeSettingFragment } = {};
      const noteType = [
        ...(timeRecordSelected?.patientNoteTypeSettings || []),
        ...(data.getCPTCodeDetails?.patientNoteTypes || []),
      ].reduce((total, current) => {
        return { ...total, [current._id]: current };
      }, init);

      setListNotes(
        (
          Object.values(noteType).filter(item => {
            return noteTypesIds?.includes(item._id);
          }) || []
        ).map(item => {
          return {
            ...item,
            description: objPatientNoteTypes[item._id] || '',
          };
        }),
      );
    },
  });
  const [
    updateNoteType,
    { loading: loadingUpdateNoteType },
  ] = useMutationCustom<
    UpdatePatientTimeRecordStatusMutation,
    UpdatePatientTimeRecordStatusMutationVariables
  >({
    api: UPDATE_PATIENT_TIME_RECORD_STATUS,
    callbackSuccess: () => {
      customEvent("UpdateTimeRecordPD", "NAVIGATOR")
      handleCancel();
    },
    textSuccess: 'Update Time record successfully',
  });

  const enterDialog = () => {
    getCptCodeDetail({
      variables: { params: { _id: idCode } },
    });
    setTimeSpent(renderTime(timeRecordSelected?.second || 0));
  };

  const handleCancel = () => {
    toogle(false);
  };

  const noteTypeOptions = useMemo(() => {
    return (
      cptCodeDetail?.getCPTCodeDetails?.patientNoteTypes?.map(item => {
        return { ...item, description: objPatientNoteTypes[item._id] };
      }) || []
    );
  }, [objPatientNoteTypes, cptCodeDetail]);
  const onSubmit = () => {
    if (timeRecordSelected && !errorTimeSpentInput()) {
      const { _id, state } = timeRecordSelected;
      const params = {
        _id,
        state,
        stoppedAt: stoppedAtRendered,
        patientNoteTypes: listNotes?.map(item => {
          return {
            id: item?._id || '',
            description: item?.description || '',
          };
        }) as NoteTypeInput[],
      };

      updateNoteType({ variables: { params } });
    }
  };

  const getSecondsByTime = useMemo(() => {
    const [hours, minutes, seconds] = timeSpent.split(':');
    const timeSpentBySeconds =
      Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
    return isNaN(timeSpentBySeconds) ? undefined : timeSpentBySeconds;
  }, [timeSpent]);

  const stoppedAtRendered = useMemo(() => {
    if (timeRecordSelected?.originalStoppedAt) {
      if (getSecondsByTime) {
        return new Date(
          addSeconds(
            subSeconds(
              new Date(timeRecordSelected?.originalStoppedAt),
              timeRecordSelected?.second || 0,
            ),
            getSecondsByTime || 0,
          ),
        ).toISOString();
      }
      return new Date(timeRecordSelected?.originalStoppedAt).toISOString();
    }
    return undefined;
  }, [timeRecordSelected, getSecondsByTime]);

  const errorTimeSpentInput = () => {
    const pattern = /^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9])$/;
    if (!pattern.test(timeSpent)) {
      return 'Time spent should have hh:mm:ss format';
    }
    if (getSecondsByTime === 0) {
      return 'Time Spent at least 00:00:01';
    }

    if (stoppedAtRendered) {
      if (
        !isSameDay(
          new Date(convertDateTimeByTimezone(stoppedAtRendered)),
          new Date(timeRecordSelected?.startedAt),
        )
      ) {
        return 'Stop Time should be ended within the same day';
      }
    }
    return '';
  };

  const renderInfo = () => {
    let infor = [
      { key: 'Module', value: codeName },
      {
        key: 'Navigator',
        value: `${meClient.firstName} ${meClient.middleName || ''} ${meClient.lastName
          }`,
      },
      {
        key: 'Start time',
        value: timeRecordSelected?.startedAt
          ? format(
            new Date(timeRecordSelected?.startedAt),
            'MM/dd/yyyy HH:mm:ss',
          )
          : 'N/A',
      },
      {
        key: 'Time spent',
        value: renderTime(timeRecordSelected?.second || 0),
      },
      {
        key: 'Stop Time',
        value: convertDateTimeByTimezonecallback(date => {
          return format(new Date(date), 'MM/dd/yyyy HH:mm:ss');
        }, stoppedAtRendered),
      },
    ];

    return infor?.map(item => {
      return (
        <Grid item xs={4} key={item?.key}>
          <Box display="flex">
            <TypographyBold
              className="mr-16"
              color="textSecondary"
              variant="subtitle2"
            >
              {item?.key}
            </TypographyBold>
            <Typography variant="subtitle2">{item?.value}</Typography>
          </Box>
        </Grid>
      );
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCancel}
        onEnter={enterDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitleClose title="Update Record" onClose={handleCancel} />
        <DialogContent>
          <Grid container spacing={2}>
            {renderInfo()}
            <Grid item xs={12} sm={6}>
              <NoteTypeAutocomplete
                {...noteTypeTableProps}
                noteTypeOptions={noteTypeOptions}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputMask
                mask="99:99:99"
                value={timeSpent}
                onChange={e => {
                  setTimeSpent(e.target.value);
                }}
              >
                <TextField
                  error={!!errorTimeSpentInput()}
                  helperText={errorTimeSpentInput()}
                  label="Time Spent"
                  placeholder="hh:mm:ss"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </InputMask>
            </Grid>
            <Grid item xs={12}>
              <NoteTypeTable {...noteTypeTableProps} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton isCancel onClickButton={handleCancel} />
          <DialogButton
            onClickButton={onSubmit}
            loading={loadingUpdateNoteType}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(DialogUpdateRecord);
