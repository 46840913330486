import React, {
  useState,
  useRef,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { TypographyItalic } from 'share/component_css';
import {
  Box,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { formatDate } from 'share/utils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {
  PatientSurveyFragment,
  PatientNoteFragment,
  SurveyResultEmbeddedFragment,
  ResendSurveyMutation,
  ResendSurveyMutationVariables,
  ArchiveSurveyResultMutation,
  ArchiveSurveyResultMutationVariables,
  TreatmentSendStatus,
} from 'types.d';
import DialogAllQuestions from '../DialogAllQuestions';
import { TableRowStyled } from '../../styles';
import NoteIcon from '@material-ui/icons/Note';
import DialogNote from '../Note/DialogNote';
import ResendIcon from 'modules/patients/components/icons/ResendIcon';
import SmsIcon from '@material-ui/icons/Sms';
import {
  RESEND_SURVEY,
  ARCHIVE_SURVEY_RESULT,
  GET_SURVEY_DATA_BY_PATIENT,
} from 'gql';
import { PatientDetailContext } from 'share/context';
import {
  useToogleDialog,
  usePopover,
  useMutationCustom,
  useFormPhone,
  useInputMentions,
} from 'hooks';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { useServices } from '../../services';
import gql from 'graphql-tag';
import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { DialogTitleClose, ButtonConfirm, DeleteConfirm } from 'components';
import DeleteIcon from '@material-ui/icons/Delete';
import SurveyResult from './SurveyResult';
import { customEvent } from 'modules/google_tag_manager';

type Props = {
  item: PatientSurveyFragment | null;
  callbackAfterModify: () => void;
};

export type TInfoSurveyWhenCreate = {
  result: string;
  survey: string;
};

export const TableCellAlignCenter = styled(TableCell)({
  alignItems: 'baseline',
  display: 'flex',
});
export const Survey: React.FC<Props> = ({ item, callbackAfterModify }) => {
  const [isExpand, setIsExpand] = useState(false);

  const [isOpenDialogNote, toogleDialogNote] = useToogleDialog();

  const [
    isOpenDeleteSurveyResult,
    toogleDialogDeleteSurveyResult,
  ] = useToogleDialog();

  const {
    valueCustom,
    defaulInputMentionsProps,
    InputMentions,
  } = useInputMentions(false, item?.smsMessage || '');

  const [isShowDialog, setIsShowDialog] = useToogleDialog();

  const [openDialogConfirm, setOpenDialogConfirm] = useToogleDialog();

  const isUpdate = useRef(false);

  const [checkedSendSMS, setCheckedSendSMS] = useState(false);

  const noteBySurveyResult = useRef<PatientNoteFragment | undefined>(undefined);

  const detailSurveyResult = useRef<SurveyResultEmbeddedFragment | undefined>(
    undefined,
  );

  const infoSurveyWhenCreate = useRef<TInfoSurveyWhenCreate | undefined>(
    undefined,
  );

  const { variablesSurveyTags } = useServices();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, close, DefaultPopover, defaultPopoverProps] = usePopover();

  const { handleDeleteSurveyResult, loadingDeleteSurveyResult } = useServices();

  const patientDetailContext = useContext(PatientDetailContext);

  const {
    FormPhoneComponent,
    formPhoneComponentProps,
    isDisplayPhone,
    setIsDisplayPhone,
    ButtonUpdatePhone,
  } = useFormPhone();

  const [resendSurvey, { loading }] = useMutationCustom<
    ResendSurveyMutation,
    ResendSurveyMutationVariables
  >({
    api: RESEND_SURVEY,
    textSuccess: checkedSendSMS
      ? 'Resend SMS successfully'
      : 'Resend email successfully',
    callbackSuccess: data => {
      customEvent("Resend" + (checkedSendSMS ? "SMS" : "Email") + "SurveyPD", "NAVIGATOR")
      setOpenDialogConfirm(false);
    },
  });

  const [unArchive, { loading: loadingArchive, client }] = useMutationCustom<
    ArchiveSurveyResultMutation,
    ArchiveSurveyResultMutationVariables
  >({
    refetchQueries: [
      {
        query: GET_SURVEY_DATA_BY_PATIENT,
        variables: variablesSurveyTags,
      },
    ],
    api: ARCHIVE_SURVEY_RESULT,
    textSuccess: 'Unarchive successfully',
    callbackSuccess: () => {
      //update survey result embedded
      client?.writeFragment({
        id: `SurveyResultEmbedded:${detailSurveyResult.current?._id}`,
        fragment: gql`
          fragment SurveyResultEmbeddedUpdate on SurveyResultEmbedded {
            isArchive
          }
        `,
        data: {
          isArchive: false,
          __typename: 'SurveyResultEmbedded',
        },
      });
      close();
    },
  });

  const showDetailSurvey = () => {
    setIsExpand(!isExpand);
  };

  const editSurvey = useCallback(
    (isUpdateSurvey?: boolean) => {
      isUpdate.current = isUpdateSurvey || false;
      setIsShowDialog(true);
    },
    [setIsShowDialog],
  );

  const openDialogNote = (
    objNote?: PatientNoteFragment | TInfoSurveyWhenCreate,
  ) => {
    toogleDialogNote(true);
    if ((objNote as PatientNoteFragment)._id !== undefined) {
      noteBySurveyResult.current = objNote as PatientNoteFragment;
      infoSurveyWhenCreate.current = undefined;
    } else {
      noteBySurveyResult.current = undefined;
      infoSurveyWhenCreate.current = objNote as TInfoSurveyWhenCreate;
    }
  };

  const surveyResultOrdered = useMemo(() => {
    return (
      item?.surveyResult?.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      }) || []
    );
  }, [item]);

  const handleResendSurvey = () => {
    setCheckedSendSMS(false);
    if (!loading) {
      resendSurvey({
        variables: {
          params: {
            surveyId: surveyResultOrdered[0]?.surveyDetail?.originSurvey,
            patientInfo: [
              {
                id: patientDetailContext?.patient.owner?.idOwner,
                sendEmail: true,
                sendSMS: false,
              },
            ],
            treatmentSendStatus: TreatmentSendStatus.ReSend,
            version: item?.version,
          },
        },
      });
    }
  };

  const handleResendSMS = () => {
    if (
      !patientDetailContext?.patient?.owner?.phones?.find(item => item.default)
    ) {
      setIsDisplayPhone(true);
      return;
    }
    setCheckedSendSMS(true);
    if (!loading) {
      resendSurvey({
        variables: {
          params: {
            surveyId: surveyResultOrdered[0]?.surveyDetail?.originSurvey,
            patientInfo: [
              {
                id: patientDetailContext?.patient.owner?.idOwner,
                sendSMS: true,
                sendEmail: false,
              },
            ],
            treatmentSendStatus: TreatmentSendStatus.ReSend,
            smsMessage: valueCustom,
            version: item?.version,
          },
        },
      });
    }
  };

  const handleUnArchive = () => {
    unArchive({
      variables: {
        params: {
          surveyResultId: detailSurveyResult.current?._id,
          isArchive: false,
          patientId: patientDetailContext?.patient?._id,
        },
      },
    });
  };

  const closeDialogSetDefault = () => {
    setIsDisplayPhone(false);
  };

  const openDialogDelete = () => {
    close();
    toogleDialogDeleteSurveyResult(true);
  };

  const handleDeleteResult = () => {
    handleDeleteSurveyResult(detailSurveyResult?.current?._id, () => {
      toogleDialogDeleteSurveyResult(false);
      callbackAfterModify();
    });
  };

  return (
    <>
      {isDisplayPhone && (
        <Dialog open={isDisplayPhone} onClose={closeDialogSetDefault} fullWidth>
          <DialogTitleClose
            title="Set Default phone"
            onClose={closeDialogSetDefault}
          />
          <DialogContent>
            <Grid container spacing={2}>
              <FormPhoneComponent {...formPhoneComponentProps} />
            </Grid>
          </DialogContent>
          <DialogActions>
            <ButtonUpdatePhone {...formPhoneComponentProps} />
          </DialogActions>
        </Dialog>
      )}

      <DeleteConfirm
        title="Are you sure you want to delete this survey result"
        loading={loadingDeleteSurveyResult}
        handleConfirm={handleDeleteResult}
        open={isOpenDeleteSurveyResult}
        toogle={e => {
          toogleDialogDeleteSurveyResult(false);
          close();
        }}
      />
      <DeleteConfirm
        loading={loading}
        handleConfirm={handleResendSurvey}
        open={openDialogConfirm}
        toogle={setOpenDialogConfirm}
        icon={<ResendIcon isResend />}
        action="update"
        buttonText="Send"
        title="Are you sure you want to resend email?"
      />
      <DefaultPopover {...defaultPopoverProps}>
        <Box display="grid" p={0.5}>
          <Tooltip title="View PDF">
            <IconButton color="default" onClick={() => editSurvey()}>
              <PictureAsPdfIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              detailSurveyResult.current?.patientNote
                ? 'Update Note'
                : 'Add Note'
            }
          >
            <IconButton
              color="primary"
              onClick={() =>
                openDialogNote(
                  detailSurveyResult.current?.patientNote || {
                    result: detailSurveyResult.current?._id,
                    survey:
                      item?.surveyResult &&
                      item?.surveyResult[0]?.surveyDetail?.surveyId,
                  },
                )
              }
            >
              <NoteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          {detailSurveyResult.current?.isArchive && (
            <Tooltip title="Unarchive">
              <IconButton onClick={handleUnArchive}>
                {loadingArchive ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  <UnarchiveIcon color="secondary" fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Delete">
            <IconButton onClick={openDialogDelete} color="secondary">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </DefaultPopover>
      {isShowDialog && (
        <DialogAllQuestions
          isUpdate={isUpdate.current}
          detailSurveyResult={detailSurveyResult.current}
          idSurvey={
            item?.surveyResult && item?.surveyResult[0]?.surveyDetail?.surveyId
          }
          open={isShowDialog}
          toogleDialog={setIsShowDialog}
          surveyName={item?.name}
          isExportPdf
          callbackAfterModify={callbackAfterModify}
        />
      )}
      {isOpenDialogNote && (
        <DialogNote
          noteSelected={noteBySurveyResult.current}
          open={isOpenDialogNote}
          toogleDialog={toogleDialogNote}
          infoSurveyWhenCreate={infoSurveyWhenCreate.current}
          notAbleToUpdateSurvey
        />
      )}
      <TableRowStyled>
        <TableCell>
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => showDetailSurvey()} color="primary">
              {isExpand ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </IconButton>
            {item?.name}
          </Box>
        </TableCell>
        <TableCell>
          {item?.updatedAt ? formatDate(item?.updatedAt, true) : 'N/A'}
        </TableCell>
        <TableCell>{`${item?.version}.0`}</TableCell>
        {!patientDetailContext?.isInactive && (
          <TableCellAlignCenter align="right">
            <IconButton
              onClick={() => setOpenDialogConfirm(true)}
              aria-label="delete"
              color="primary"
            >
              <ResendIcon isResend />
            </IconButton>
            <ButtonConfirm
              loadingConfirm={loading}
              handleConfirm={handleResendSMS}
              title="Are you sure you want to resend sms?"
              tooltipTitle="Resend SMS"
              content={
                <Grid container spacing={2} className="pt-8">
                  <InputMentions {...defaulInputMentionsProps} />
                </Grid>
              }
              icon={<SmsIcon fontSize="small" />}
            />
          </TableCellAlignCenter>
        )}
      </TableRowStyled>
      {isExpand && (
        <TableRow>
          <TableCell colSpan={4}>
            <Box display="flex" flexWrap="wrap" gridGap={8}>
              {surveyResultOrdered.map((result, index) => {
                return (
                  <SurveyResult
                    key={index}
                    result={result}
                    editSurvey={editSurvey}
                    detailSurveyResult={detailSurveyResult}
                    open={open}
                  />
                );
              })}
            </Box>
            {item?.surveyResult?.length === 0 && (
              <TypographyItalic
                align="center"
                variant="subtitle1"
                color="textSecondary"
              >
                There are no Survey Results
              </TypographyItalic>
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default React.memo(Survey);
