import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

type ValueType = {
  value: string;
  label?: string;
};

type OnlyChecked = {
  checked: string[];
  handleSetChecked: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => void;
  value?: ValueType[];
  disabled?: boolean;
  isMediumSize?: boolean;
};

export const FormControlLabelStyled = styled(FormControlLabel)({
  '&.MuiFormControlLabel-root': {
    marginLeft: '0px',
  },
});
export const useOnlyChecked = (noPhone?: boolean) => {
  const [checked, setChecked] = useState<string[]>([]);

  const handleSetChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    if (event.target.checked) {
      setChecked([...checked, value]);
      return;
    }
    setChecked([...checked].filter(item => item !== value));
  };

  useEffect(() => {
    if (noPhone && checked.includes('sendSMS')) {
      setChecked(checked.filter(item => item !== 'sendSMS'));
    }
  }, [checked, noPhone]);

  const defaulCheckedProps = {
    checked,
    handleSetChecked,
  };

  return { checked, setChecked, defaulCheckedProps, OnlyChecked };
};

export const OnlyChecked: React.FC<OnlyChecked> = ({
  checked,
  handleSetChecked,
  value,
  disabled,
  isMediumSize,
}) => {
  return (
    <>
      {value?.map(item => (
        <FormControlLabelStyled
          key={item.value}
          disabled={disabled}
          control={
            <Checkbox
              size={isMediumSize ? 'medium' : 'small'}
              color="primary"
              value={item.value}
              checked={checked.includes(item.value)}
              onChange={e => handleSetChecked(e, e.target.value)}
            />
          }
          label={<Typography variant="subtitle2">{item.label}</Typography>}
        />
      ))}
    </>
  );
};
