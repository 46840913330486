import React, { useMemo } from 'react';
import { TConfigMenu } from '../config_menu';
import { LinkRouterStyled } from 'share/component_css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListItemMenu from './ListItemMenu';
import { Box } from '@material-ui/core';

import {
  TOOGLE_SHOW_COMMENT_FROM,
  SHOWING_COMMENT_FROM,
  TOOGLE_MENU_STATUS,
  MENU_STATUS,
  SHOWING_MENU_ADMINISTRATION,
  TOOGLE_SHOWING_MENU_ADMINISTRATION,
  TOOGLE_SHOWING_QUICK_VIEW,
  SHOWING_QUICK_VIEW,
  TOOGLE_SHOWING_MENU_SETTING,
  SHOWING_MENU_SETTING,
} from 'gql';
import {
  ToogleShowCommentFromMutation,
  ShowingQuickViewQuery,
  ToogleShowCommentFromMutationVariables,
  ShowingCommentFromQuery,
  ShowingMenuAdministrationQuery,
  ToogleShowMenuAdministrationMutation,
  ToogleShowMenuAdministrationMutationVariables,
  ToogleShowQuickViewMutation,
  ToogleShowQuickViewMutationVariables,
  ToogleShowMenuSettingMutationVariables,
  ToogleShowMenuSettingMutation,
  ShowingMenuSettingQuery,
} from 'types.d';
import { useTheme } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import SubTreatments from './Subtreatments';
import SubPatientDashboards from './SubPatientDashboards';

type Props = {
  detail: TConfigMenu;
  hasPadding?: boolean;
  unreadComment?: {
    treatment: number;
    patient: number;
  };
  unreadCommentPatient?: number;
  unreadCommentTreatment?: number;
};

export const MenuInDrawer: React.FC<Props> = ({
  detail,
  hasPadding,
  unreadComment,
  unreadCommentTreatment,
  unreadCommentPatient,
}) => {
  const theme = useTheme();

  const { data: dataMenuStatus } = useQuery(MENU_STATUS);

  const isToogleMenu = useMediaQuery(theme.breakpoints.down('md'));

  const { data: dataShowingCommentFrom } = useQuery<ShowingCommentFromQuery>(
    SHOWING_COMMENT_FROM,
  );
  const { data: dataShowingQuickView } = useQuery<ShowingQuickViewQuery>(
    SHOWING_QUICK_VIEW,
  );

  const { data: dataShowingMenuAdministration } = useQuery<
    ShowingMenuAdministrationQuery
  >(SHOWING_MENU_ADMINISTRATION);

  const { data: dataShowingMenuSetting } = useQuery<ShowingMenuSettingQuery>(
    SHOWING_MENU_SETTING,
  );

  const [toogleMenuStatus] = useMutation(TOOGLE_MENU_STATUS);

  const [toogleShowCommentFrom] = useMutation<
    ToogleShowCommentFromMutation,
    ToogleShowCommentFromMutationVariables
  >(TOOGLE_SHOW_COMMENT_FROM);

  const [toogleShowQuickView] = useMutation<
    ToogleShowQuickViewMutation,
    ToogleShowQuickViewMutationVariables
  >(TOOGLE_SHOWING_QUICK_VIEW);

  const [toogleShowAdministration] = useMutation<
    ToogleShowMenuAdministrationMutation,
    ToogleShowMenuAdministrationMutationVariables
  >(TOOGLE_SHOWING_MENU_ADMINISTRATION);

  const [toogleShowSetting] = useMutation<
    ToogleShowMenuSettingMutation,
    ToogleShowMenuSettingMutationVariables
  >(TOOGLE_SHOWING_MENU_SETTING);

  const clickOnMenu = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    url: string,
  ) => {
    if (Object.is(url, '/question/')) {
      e.preventDefault();
      toogleShowCommentFrom({
        variables: { input: !dataShowingCommentFrom?.showingCommentFrom },
      });
      return;
    }

    if (Object.is(url, '/administration/')) {
      e.preventDefault();
      toogleShowAdministration({
        variables: {
          input: !dataShowingMenuAdministration?.showingMenuAdministration,
        },
      });
      return;
    }
    if (Object.is(url, '/setting/')) {
      e.preventDefault();
      toogleShowSetting({
        variables: {
          input: !dataShowingMenuSetting?.showingMenuSetting,
        },
      });
      return;
    }
    if (Object.is(url, '/quick-view/')) {
      e.preventDefault();
      toogleShowQuickView({
        variables: {
          input: !dataShowingQuickView?.showingQuickView,
        },
      });
      return;
    }

    if (isToogleMenu) {
      toogleMenuStatus({ variables: { status: !dataMenuStatus.menuStatus } });
    }
  };

  const checkDisplay = useMemo(() => {
    return (
      dataMenuStatus.menuStatus || (!dataMenuStatus.menuStatus && isToogleMenu)
    );
  }, [dataMenuStatus.menuStatus, isToogleMenu]);

  return (
    <>
      {['/question/', '/administration/', '/setting/', '/quick-view/'].includes(
        detail.url,
      ) ? (
        <Box
          onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
            clickOnMenu(e, detail.url)
          }
        >
          <ListItemMenu
            detail={detail}
            hasPadding={hasPadding}
            unreadComment={unreadComment}
            unreadCommentPatient={unreadCommentPatient}
            unreadCommentTreatment={unreadCommentTreatment}
          />
        </Box>
      ) : (
        <LinkRouterStyled
          onClick={e => clickOnMenu(e, detail.url)}
          to={detail.url}
        >
          <ListItemMenu
            detail={detail}
            hasPadding={hasPadding}
            unreadComment={unreadComment}
            unreadCommentPatient={unreadCommentPatient}
            unreadCommentTreatment={unreadCommentTreatment}
          />
        </LinkRouterStyled>
      )}

      {checkDisplay && Object.is(detail.url, '/assigned-treatments/') && (
        <SubTreatments />
      )}
      {checkDisplay && Object.is(detail.url, '/quick-view/') && (
        <SubPatientDashboards />
      )}
    </>
  );
};

export default React.memo(MenuInDrawer);
