import React, { useEffect, useContext, useState } from 'react';
import {
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  MenuItem,
  Box,
  Divider,
  Tooltip,
} from '@material-ui/core';
import { DialogButton, DialogTitleClose } from 'components';
import { useMutationCustom, useFormPhone, useOnlyChecked, Phone } from 'hooks';
import {
  UPDATE_PATIENT_INFO,
  PatientFragment as PatientFragmentGql,
  SHARE_MULTI_TREATMENT_TO_PATIENT,
} from 'gql';
import {
  UpdatePatientInfoMutation,
  UpdatePatientInfoMutationVariables,
  PatientFragment,
  Gender,
  Race,
  ShareMultiTreatmentToPatientMutation,
  ShareMultiTreatmentToPatientMutationVariables,
  CommonTreatmentFragment,
  TreatmentType,
  TreatmentRegimenAddPatientFragment,
} from 'types.d';
import { useForm, Controller, FormContext } from 'react-hook-form';
import {
  trimObjectValue,
  convertDateWithoutUTC,
  formatDoB,
  defaultChecked,
  isBotMail,
} from 'share/utils';
import 'react-phone-input-2/lib/material.css';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useApolloClient } from '@apollo/react-hooks';
import { LIST_GENDER, LIST_RACE } from 'CONST';
import CloseIcon from '@material-ui/icons/Close';
import { InputAdornmentStyled } from '../../styles';
import { PatientDetailContext } from 'share/context';
import { OptionEmrProvider, useProvider } from '../../services/useProvider';
import { TypographyBold } from 'share/component_css';
import { Lock, LockOpen } from 'mdi-material-ui';
import { AssignBy } from '../DialogAddPatient';
import AssignTreatmentUpdate from '../AssignTreatmentUpdate';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { customEvent } from 'modules/google_tag_manager';

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
  infoPatient?: PatientFragment;
  idPatient: string;
  isFromHome?: boolean;
  treatmentSelected?: string;
};

export type FormData = {
  dob: string | null;
  mrn: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  phones: Phone[];
  treatmentIds: CommonTreatmentFragment[];
  surveyIds: CommonTreatmentFragment[];
  gender: Gender | string;
  race: Race | string;
  provider?: OptionEmrProvider;
  emrProvider?: OptionEmrProvider;
  address: string;
  group: TreatmentRegimenAddPatientFragment;
  patientId?: string;
  userId?: string;
  email?: string;
};

export const DialogUpdateInfo: React.FC<Props> = ({
  open,
  toogleDialog,
  infoPatient,
  idPatient,
  isFromHome,
  treatmentSelected,
}) => {
  const client = useApolloClient();

  const [lockField, setLockField] = useState(isFromHome ? true : false);

  const [assignBy, setAssignBy] = useState<AssignBy>(AssignBy.TREATMENT);

  const methods = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      mrn: '',
      dob: null,
      phones: [],
      provider: undefined,
    },
  });

  const patientDetailContext = useContext(PatientDetailContext);

  const {
    handleSubmit,
    errors,
    register,
    control,
    reset,
    watch,
    setValue,
    formState: { touched },
  } = methods;

  const {
    optionEmrProviders,
    optionProviders,
    providerComponentProps,
    ProviderComponent,
    getProviders,
  } = useProvider(infoPatient, methods);

  const watchRace = watch('race');

  const { FormPhoneComponent, formPhoneComponentProps } = useFormPhone<
    FormData
  >(
    methods,
    isFromHome && infoPatient?.owner?.phones
      ? infoPatient?.owner?.phones
      : undefined,
  );

  const {
    checked,
    setChecked,
    defaulCheckedProps,
    OnlyChecked,
  } = useOnlyChecked(formPhoneComponentProps.fields?.length === 0);

  const [
    updatePatientInfo,
    { loading: loadingUpdatePatientInfo },
  ] = useMutationCustom<
    UpdatePatientInfoMutation,
    UpdatePatientInfoMutationVariables
  >({
    api: UPDATE_PATIENT_INFO,
    textSuccess: 'Update information of patient successfully',
    callbackSuccess: data => {
      customEvent("updatePatientInfo", "NAVIGATOR")
      client.writeFragment({
        id: `PatientRes:${infoPatient?._id}`,
        fragment: PatientFragmentGql,
        fragmentName: 'Patient',
        data: {
          ...infoPatient,
          owner: {
            ...data.updatePatientInfo,
          },
        },
      });
      toogleDialog(false);
    },
  });

  const [shareMultiTreatment] = useMutationCustom<
    ShareMultiTreatmentToPatientMutation,
    ShareMultiTreatmentToPatientMutationVariables
  >({
    api: SHARE_MULTI_TREATMENT_TO_PATIENT,
  });

  const onSubmit = handleSubmit(data => {
    const { phones, ...restValue } = data;
    if (
      phones &&
      formPhoneComponentProps.indexDefault !== undefined &&
      phones[formPhoneComponentProps.indexDefault]
    ) {
      phones[formPhoneComponentProps.indexDefault].default = true;
    }
    handleSave({
      ...restValue,
      phones: phones?.map(item => {
        return {
          ...item,
          phone: item?.phone?.includes('+') ? item?.phone : `+${item?.phone}`,
        };
      }),
    });
    if (isFromHome) {
      shareMultiTreatment({
        variables: {
          params: {
            patientId:
              patientDetailContext?.patient.owner?.idOwner || idPatient,
            treatmentIds: [AssignBy.TREATMENT, AssignBy.FAVORITES].includes(
              assignBy,
            )
              ? data?.treatmentIds.map(item => item._id)
              : data?.group?.regimenConfig
                .filter(
                  item => item.treatmentType === TreatmentType.Treatment,
                )
                ?.map(item => item.treatment?._id) || [],
          },
        },
      });
    }
  });

  const handleSave = (data: FormData) => {
    const { treatmentIds, group, ...dataTrim } = trimObjectValue(data);
    if (!isBotMail(infoPatient?.owner?.email) || dataTrim.email === '') {
      delete dataTrim.email;
    }
    updatePatientInfo({
      variables: {
        params: {
          _id: idPatient,
          ...dataTrim,
          middleName: dataTrim?.middleName ? dataTrim?.middleName : null,
          provider: dataTrim.provider?._id ? [dataTrim.provider._id] : null,
          emrProvider: dataTrim.emrProvider?._id
            ? [dataTrim.emrProvider._id]
            : null,
          phones: dataTrim.phones?.filter(Boolean) || [],
          dob: convertDateWithoutUTC(dataTrim.dob as string),
          mrn: dataTrim.mrn || null,
          gender: dataTrim.gender as Gender,
          race: dataTrim.race === '' ? null : (dataTrim.race as Race),
          sendEmail: checked.includes('sendEmail'),
          sendSMS: checked.includes('sendSMS'),
        },
      },
    });
  };

  useEffect(() => {
    if (infoPatient && Object.keys(touched).length === 0) {
      const { owner } = infoPatient;
      reset({
        provider: optionProviders?.find(item => {
          return owner?.provider && owner?.provider[0]._id === item._id;
        }),
        emrProvider: optionEmrProviders.find(item => {
          return owner?.emrProvider && owner?.emrProvider[0]._id === item._id;
        }),
        dob: owner?.dob ? formatDoB(owner?.dob) : null,
        firstName: owner?.firstName || '',
        middleName: owner?.middleName || '',
        lastName: owner?.lastName || '',
        email: isBotMail(owner?.email) ? '' : owner?.email,
        mrn: owner?.mrn || '',
        phones: owner?.phones || [],
        gender: (owner?.gender as Gender) || '',
        race: (owner?.race as Race) || null,
        address: owner?.address || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoPatient, optionProviders, optionEmrProviders]);

  const enterDialog = () => {
    getProviders({
      variables: {
        params: {
          page: 1,
          sortByOrder: { provider: 1 },
        },
      },
    });
    setChecked(
      defaultChecked(
        infoPatient?.owner?.patientConfig?.contactConfig?.method || [],
      ),
    );
  };

  return (
    <>
      <FormContext {...methods}>
        <Dialog onEnter={enterDialog} open={open} maxWidth="sm" fullWidth>
          <DialogTitleClose
            title="Update Patient Information"
            onClose={() => toogleDialog(false)}
          />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TypographyBold variant="h6">Information</TypographyBold>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  inputProps={{ maxLength: 255 }}
                  label="First Name"
                  size="small"
                  name="firstName"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  inputRef={register({
                    validate: {
                      required: value =>
                        value.trim() !== '' || 'This field is required',
                    },
                  })}
                  error={!!errors.firstName}
                  helperText={errors.firstName ? errors.firstName.message : ''}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  inputProps={{ maxLength: 255 }}
                  inputRef={register({
                    validate: {
                      required: value =>
                        value.trim() !== '' || 'This field is required',
                    },
                  })}
                  required
                  label="Last Name"
                  size="small"
                  name="lastName"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  error={!!errors.lastName}
                  helperText={errors.lastName ? errors.lastName.message : ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  inputProps={{ maxLength: 255 }}
                  label="Middle Name"
                  size="small"
                  name="middleName"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  as={
                    <KeyboardDatePicker
                      required
                      disabled={!!lockField}
                      placeholder="mm/dd/yyyy"
                      inputVariant="outlined"
                      label="Date of Birth"
                      format="MM/dd/yyyy"
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      value={() => { }}
                      onChange={() => { }}
                      variant="inline"
                      autoOk
                      error={!!errors?.dob}
                      helperText={
                        errors?.dob
                          ? errors?.dob?.types?.message || errors?.dob.message
                          : ''
                      }
                      keyboardIcon={<DateRangeIcon />}
                    />
                  }
                  name="dob"
                  control={control}
                  rules={{
                    validate: {
                      required: value =>
                        value !== null || 'This field is required',
                      pattern: value => {
                        return (
                          !isNaN(new Date(value).getTime()) ||
                          'Invalid date format'
                        );
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TypographyBold variant="h6">
                  Prefered Communication
                </TypographyBold>
              </Grid>
              <Grid item xs={1}>
                <OnlyChecked
                  disabled={watch('email')?.length === 0 || lockField}
                  value={[{ value: 'sendEmail' }]}
                  {...defaulCheckedProps}
                />
              </Grid>
              <Grid item xs={11}>
                <TextField
                  inputProps={{ maxLength: 255 }}
                  label="Email"
                  size="small"
                  name="email"
                  disabled={!isBotMail(infoPatient?.owner?.email) || lockField}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  inputRef={register({
                    pattern: {
                      value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: 'Invalid email address',
                    },
                  })}
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ''}
                />
              </Grid>
              <Grid item xs={1}>
                <OnlyChecked
                  disabled={
                    formPhoneComponentProps.fields.length === 0 || lockField
                  }
                  value={[{ value: 'sendSMS' }]}
                  {...defaulCheckedProps}
                />
              </Grid>
              <Grid item xs={11} container spacing={2}>
                <FormPhoneComponent
                  disabledActions={
                    patientDetailContext?.isInactive || lockField
                  }
                  {...formPhoneComponentProps}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="gender"
                  control={control}
                  rules={{
                    validate: {
                      required: value =>
                        value !== '' || 'This field is required',
                    },
                  }}
                  defaultValue={''}
                  as={
                    <TextField
                      required
                      size="small"
                      fullWidth
                      label="Gender"
                      InputLabelProps={{ shrink: true }}
                      select
                      variant="outlined"
                      SelectProps={{
                        displayEmpty: true,
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        },
                        renderValue: value => {
                          return value ? (
                            <>{LIST_GENDER[value as Gender]}</>
                          ) : (
                            <Box color="text.secondary">Choose</Box>
                          );
                        },
                      }}
                      error={!!errors?.gender}
                      helperText={errors?.gender ? errors?.gender?.message : ''}
                      disabled={!!lockField}
                    >
                      {Object.keys(LIST_GENDER).map(item => {
                        return (
                          <MenuItem key={item} value={item}>
                            {LIST_GENDER[item]}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  inputProps={{ maxLength: 255 }}
                  label="MRN"
                  size="small"
                  name="mrn"
                  inputRef={methods!.register({
                    pattern: {
                      value: /[0-9]+-[0-9]+/,
                      message: 'This field is wrong format',
                    },
                  })}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  error={!!errors.mrn}
                  helperText={errors.mrn ? errors.mrn.message : ''}
                  disabled={!!lockField}
                />
              </Grid>
              <ProviderComponent
                disable={!!lockField}
                {...providerComponentProps}
              />
              <Grid item xs={6}>
                <TextField
                  inputProps={{ maxLength: 255 }}
                  label="Address"
                  size="small"
                  name="address"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  inputRef={register}
                  disabled={!!lockField}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="race"
                  control={control}
                  defaultValue=""
                  as={
                    <TextField
                      size="small"
                      fullWidth
                      label="Race"
                      InputLabelProps={{ shrink: true }}
                      select
                      disabled={!!lockField}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <>
                            {watchRace && watchRace !== '' && !lockField ? (
                              <InputAdornmentStyled
                                position="end"
                                className="cursor-pointer"
                                onClick={() => {
                                  setValue('race', '');
                                }}
                              >
                                <CloseIcon color="secondary" fontSize="small" />
                              </InputAdornmentStyled>
                            ) : null}
                          </>
                        ),
                      }}
                      SelectProps={{
                        displayEmpty: true,
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        },
                        renderValue: value => {
                          return value ? (
                            <>{LIST_RACE[value as Race]}</>
                          ) : (
                            <Box color="text.secondary">Choose</Box>
                          );
                        },
                      }}
                    >
                      {Object.keys(LIST_RACE).map(item => {
                        return (
                          <MenuItem key={item} value={item}>
                            {LIST_RACE[item]}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  }
                />
              </Grid>
              {isFromHome && (
                <AssignTreatmentUpdate
                  methods={methods}
                  assignBy={assignBy}
                  setAssignBy={setAssignBy}
                  treatmentSelected={treatmentSelected}
                  isUpdateInfo
                />
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            {isFromHome && (
              <Box mr="auto">
                <Tooltip
                  title={`Click to ${lockField ? 'allow' : 'block'} editting`}
                >
                  {lockField ? (
                    <Lock
                      color="primary"
                      onClick={() => setLockField(!lockField)}
                      className="cursor-pointer"
                    />
                  ) : (
                    <LockOpen
                      color="primary"
                      onClick={() => setLockField(!lockField)}
                      className="cursor-pointer"
                    />
                  )}
                </Tooltip>
              </Box>
            )}
            <DialogButton isCancel onClickButton={() => toogleDialog(false)} />
            {!patientDetailContext?.isInactive && (
              <DialogButton
                loading={loadingUpdatePatientInfo}
                onClickButton={onSubmit}
              />
            )}
          </DialogActions>
        </Dialog>
      </FormContext>
    </>
  );
};

export default React.memo(DialogUpdateInfo);
