import React, { useMemo } from 'react';
import { TConfigMenu } from '../config_menu';
import {
  Tooltip,
  useMediaQuery,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Badge,
} from '@material-ui/core';
import { Theme, styled } from '@material-ui/core/styles';
import {
  SHOWING_COMMENT_FROM,
  MENU_STATUS,
  SHOWING_MENU_ADMINISTRATION,
  SHOWING_MENU_SETTING,
} from 'gql';
import {
  ShowingCommentFromQuery,
  ShowingMenuAdministrationQuery,
  ShowingMenuSettingQuery,
} from 'types.d';
import { useTheme } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { ToogleExpand } from 'components';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CommentIcon from '@material-ui/icons/Comment';

type Props = {
  detail: TConfigMenu;
  hasPadding?: boolean;
  unreadComment?: {
    treatment: number;
    patient: number;
  };
  unreadCommentPatient?: number;
  unreadCommentTreatment?: number;
};

const ListItemStyled = styled(ListItem)(
  ({
    haspadding,
    theme,
    isparent,
  }: {
    haspadding: string;
    theme: Theme;
    isparent: string;
  }) => ({
    '&.Mui-selected': {
      backgroundColor: Object.is(isparent, 'isParent')
        ? theme.palette.common.white
        : '#e3edfd',
    },
    borderTopRightRadius: theme.spacing(3),
    borderBottomRightRadius: theme.spacing(3),
    paddingLeft: Object.is(haspadding, 'hasPadding')
      ? theme.spacing(3)
      : theme.spacing(2),
  }),
);

const ListItemIconStyled = styled(ListItemIcon)(
  ({ menuStatus }: { menuStatus: boolean }) => ({
    display: Object.is(menuStatus, false) ? 'flex' : 'unset',
    justifyContent: Object.is(menuStatus, false) ? 'center' : 'unset',
  }),
);

export const ListItemMenu: React.FC<Props> = ({
  detail,
  hasPadding,
  unreadComment,
  unreadCommentTreatment,
  unreadCommentPatient,
}) => {
  const Icon = detail.icon;

  const history = useHistory();

  const theme = useTheme();

  const { data: dataMenuStatus } = useQuery(MENU_STATUS);

  const isToogleMenu = useMediaQuery(theme.breakpoints.down('md'));

  const { data: dataShowingCommentFrom } = useQuery<ShowingCommentFromQuery>(
    SHOWING_COMMENT_FROM,
  );

  const { data: dataShowingMenuAdministration } = useQuery<
    ShowingMenuAdministrationQuery
  >(SHOWING_MENU_ADMINISTRATION);

  const { data: dataShowingMenuSetting } = useQuery<ShowingMenuSettingQuery>(
    SHOWING_MENU_SETTING,
  );

  const checkSelected = (path: string) => {
    if (path === '/administration/') {
      return [
        '/users/',
        '/note-type/',
        '/tag/',
        '/task-type/',
        '/condition/',
        '/organizations/',
        '/group-users/',
        '/providers/',
        '/administration/emr-logs/',
        '/administration/release-notes/',
        '/group-settings/',
        '/treatment-flows',
        '/work-type/',
      ].includes(history.location.pathname);
    }
    if (path === '/setting/') {
      return [
        '/setting/providers/',
        '/setting/task-type/',
        '/setting/tag/',
        '/setting/group-settings/',
        '/emr-logs/',
        '/twilio-logs/',
        '/common-configs/',
        '/release-notes/',
        '/organization-information/',
      ].includes(history.location.pathname);
    }
    if (path === '/question/') {
      return ['/question-treatment/', '/question-patient/'].includes(
        history.location.pathname,
      );
    }
    return history.location.pathname === path;
  };

  const checkDisplay = useMemo(() => {
    return (
      dataMenuStatus.menuStatus || (!dataMenuStatus.menuStatus && isToogleMenu)
    );
  }, [dataMenuStatus.menuStatus, isToogleMenu]);

  return (
    <>
      <ListItemStyled
        haspadding={hasPadding ? 'hasPadding' : ''}
        button
        selected={checkSelected(detail.url)}
        isparent={
          ['/administration/', '/setting/', '/question/'].includes(detail.url)
            ? 'isParent'
            : ''
        }
      >
        <Tooltip title={detail.title}>
          <ListItemIconStyled menuStatus={dataMenuStatus.menuStatus}>
            <Icon />
          </ListItemIconStyled>
        </Tooltip>
        {(isToogleMenu || dataMenuStatus.menuStatus) && (
          <ListItemText primary={detail.title} />
        )}
        {checkDisplay && '/administration/' === detail.url && (
          <Box position="absolute" top={10} right={3}>
            <ToogleExpand
              isExpand={
                !!dataShowingMenuAdministration?.showingMenuAdministration
              }
            />
          </Box>
        )}
        {checkDisplay && '/setting/' === detail.url && (
          <Box position="absolute" top={10} right={3}>
            <ToogleExpand
              isExpand={!!dataShowingMenuSetting?.showingMenuSetting}
            />
          </Box>
        )}
        {checkDisplay &&
          detail.url === '/question-treatment/' &&
          unreadCommentTreatment !== 0 && (
            <Box position="absolute" top={10} right={3}>
              <Badge
                className="ml-auto"
                badgeContent={unreadCommentTreatment}
                color="secondary"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <CommentIcon fontSize="small" color="action" />
              </Badge>
            </Box>
          )}
        {checkDisplay &&
          detail.url === '/question-patient/' &&
          unreadCommentPatient !== 0 && (
            <Box position="absolute" top={10} right={3}>
              <Badge
                className="ml-auto"
                badgeContent={unreadCommentPatient}
                color="secondary"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <CommentIcon fontSize="small" color="action" />
              </Badge>
            </Box>
          )}
        {checkDisplay && detail.url === '/question/' && (
          <Box
            display="flex"
            alignItems="center"
            position="absolute"
            top={10}
            right={3}
          >
            {unreadComment?.patient !== 0 && (
              <Tooltip title="Patient">
                <Badge
                  className="mr-8"
                  badgeContent={unreadComment?.patient}
                  color="secondary"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <LocalHospitalIcon fontSize="small" color="action" />
                </Badge>
              </Tooltip>
            )}
            {unreadComment?.treatment !== 0 && (
              <Tooltip title="Treatment">
                <Badge
                  badgeContent={unreadComment?.treatment}
                  color="secondary"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <AssignmentIcon fontSize="small" color="action" />
                </Badge>
              </Tooltip>
            )}
            <ToogleExpand
              isExpand={!!dataShowingCommentFrom?.showingCommentFrom}
            />
          </Box>
        )}
      </ListItemStyled>
    </>
  );
};

export default React.memo(ListItemMenu);
