import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { SurveyResultEmbeddedFragment } from 'types.d';
import { DataEditSurveyResultInterface } from '../interfaces';
import { formatDate } from 'share/utils';
import {
  PaperBorder,
  TableCellMaxWidthStyled,
  TypographyBold,
  TypoThreeDotsLine,
} from 'share/component_css';

type Props = {
  detailSurveyResult?:
    | SurveyResultEmbeddedFragment
    | DataEditSurveyResultInterface;
  isEdit: (
    data?:
      | SurveyResultEmbeddedFragment
      | DataEditSurveyResultInterface
      | undefined,
  ) => data is SurveyResultEmbeddedFragment;
};

export const TableNoteSurvey: React.FC<Props> = ({
  detailSurveyResult,
  isEdit,
}) => {
  return (
    <>
      {(detailSurveyResult as SurveyResultEmbeddedFragment).patientNote
        ?.notes &&
        (detailSurveyResult as SurveyResultEmbeddedFragment).patientNote?.notes
          ?.length !== 0 && (
          <>
            <TypographyBold className="mb-8" variant="h6">
              Notes
            </TypographyBold>
            <TableContainer component={PaperBorder}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell align="left">Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(detailSurveyResult as SurveyResultEmbeddedFragment).patientNote?.notes?.map(
                    item => {
                      return (
                        <TableRow key={item.idNote}>
                          <TableCellMaxWidthStyled maxwidth="200px">
                            <TypoThreeDotsLine variant="subtitle2">
                              {item.description}
                            </TypoThreeDotsLine>
                          </TableCellMaxWidthStyled>
                          <TableCell align="left">
                            {formatDate(item.notedAt, true)}
                          </TableCell>
                        </TableRow>
                      );
                    },
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
    </>
  );
};

export default React.memo(TableNoteSurvey);
