import React, { useEffect } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  GetPd4QuickViewQuery,
  GetPd4QuickViewQueryVariables,
  ShowingQuickViewQuery,
  ToogleShowQuickViewMutation,
  ToogleShowQuickViewMutationVariables,
} from 'types.d';
import Skeleton from '@material-ui/lab/Skeleton';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  GET_PD4_QUICK_VIEW,
  SHOWING_QUICK_VIEW,
  TOOGLE_SHOWING_QUICK_VIEW,
} from 'gql';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SubPatientDashboard from './SubPatientDashboard';
import { Box } from '@material-ui/core';
import { LinkRouterStyled, TypographyItalic } from 'share/component_css';
import { ButtonLoading } from 'components';
type Props = {};

export const SubPatientDashboards: React.FC<Props> = () => {
  const [getQuickView, { data, loading }] = useLazyQuery<
    GetPd4QuickViewQuery,
    GetPd4QuickViewQueryVariables
  >(GET_PD4_QUICK_VIEW, {
    fetchPolicy: 'cache-and-network',
  });

  const { data: dataShowQuickView } = useQuery<ShowingQuickViewQuery>(
    SHOWING_QUICK_VIEW,
  );

  const [toogleShowQuickView] = useMutation<
    ToogleShowQuickViewMutation,
    ToogleShowQuickViewMutationVariables
  >(TOOGLE_SHOWING_QUICK_VIEW);
  const toogleQuickView = () => {
    toogleShowQuickView({
      variables: { input: !dataShowQuickView?.showingQuickView },
    });
    getQuickView();
  };

  useEffect(() => {
    if (dataShowQuickView?.showingQuickView) {
      getQuickView();
    }
  }, [dataShowQuickView, getQuickView]);
  return (
    <>
      <Box display="flex" position="relative" flexDirection="column">
        <Box
          className="cursor-pointer"
          position="absolute"
          top="-34px"
          right="3px"
          onClick={toogleQuickView}
        >
          {dataShowQuickView?.showingQuickView ? (
            <ExpandMoreIcon color="action" />
          ) : (
            <ChevronRightIcon color="action" />
          )}
        </Box>
        {loading &&
          Array.from(Array(3).keys()).map(item => {
            return (
              <Box key={item} pt={1} display="flex" alignItems="center">
                <Skeleton variant="rect" width="100%" height={50} />
              </Box>
            );
          })}
        {!loading &&
          dataShowQuickView?.showingQuickView &&
          (data?.getPD4QuickView.length !== 0 ? (
            data?.getPD4QuickView.map(item => (
              <SubPatientDashboard key={item._id} item={item} />
            ))
          ) : (
            <Box display="flex" flexDirection="column">
              <TypographyItalic
                variant="subtitle2"
                align="center"
                gutterBottom
                color="textSecondary"
              >
                * Go to patient dashboard to add more items for quick view
              </TypographyItalic>
              <LinkRouterStyled to="/patients/">
                <Box px={2}>
                  <ButtonLoading
                    fullWidth
                    text="Patient Dashboard"
                    Icon={<ArrowForwardIcon />}
                  />
                </Box>
              </LinkRouterStyled>
            </Box>
          ))}
      </Box>
    </>
  );
};

export default React.memo(SubPatientDashboards);
