import React, {
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  MenuItem,
  Box,
  Divider,
  Tabs,
  Tab,
  Chip,
} from '@material-ui/core';
import {
  DialogTitleClose,
  DialogButton,
  ButtonLoading,
  AutoCompleteSelect,
} from 'components';
import { useMutationCustom } from 'hooks';
import {
  UPDATE_PATIENT_NOTE,
  CREATE_PATIENT_NOTE,
  ADD_MORE_PATIENT_NOTE,
  PatientNoteFragment as PatientNoteFragmentGql,
  REMOVE_PATIENT_NOTE,
  CHANGE_SURVEY_RESULT_OF_NOTE,
  GET_DATA_FOR_NOTE,
  GET_PATIENT_NOTES,
  GET_NOTE_TAG,
} from 'gql';
import {
  PatientNoteFragment,
  CreatePatientNoteMutation,
  CreatePatientNoteMutationVariables,
  AddMorePatientNoteMutation,
  AddMorePatientNoteMutationVariables,
  NoteStatus,
  SubNoteFragment,
  UpdatePatientNoteMutation,
  UpdatePatientNoteMutationVariables,
  RemovePatientNoteMutation,
  RemovePatientNoteMutationVariables,
  ChangeSurveyResultOfNoteMutation,
  ChangeSurveyResultOfNoteMutationVariables,
  GetDataForNoteQuery,
  GetDataForNoteQueryVariables,
  PatientNoteTypeSetting,
} from 'types.d';
import { useApolloClient } from '@apollo/react-hooks';
import { PatientDetailContext } from 'share/context';
import { useForm, Controller } from 'react-hook-form';
import { formatDate, trimObjectValue } from 'share/utils';
import { DateTimePicker } from '@material-ui/pickers';
import AddIcon from '@material-ui/icons/Add';
import TableNotes from './TableNotes';
import { PaperBorder, TypographyBold } from 'share/component_css';
import EditIcon from '@material-ui/icons/Edit';
import { TInfoSurveyWhenCreate } from '../Survey/Survey';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useDialogNote } from '../../services/hooks';
import FollowUp from '../FollowUp';
import Checklist from '../Checklist';
import Surveys from '../Survey';
import { customEvent } from 'modules/google_tag_manager';

type Props = {
  open?: boolean;
  toogleDialog?: (status: boolean, isExtend?: boolean) => void;
  noteSelected?: PatientNoteFragment;
  infoSurveyWhenCreate?: TInfoSurveyWhenCreate;
  notAbleToUpdateSurvey?: boolean;
  isOnDialog?: boolean;
};

type NoteType = {
  idType?: string;
} & Pick<PatientNoteTypeSetting, 'name' | 'description'>;

type FormData = {
  description: string;
  survey: string;
  surveyResultId: string;
  notedAt: Date | null;
  type?: NoteType | null;
};

export const DialogNote: React.FC<Props> = ({
  open,
  toogleDialog,
  noteSelected,
  infoSurveyWhenCreate,
  notAbleToUpdateSurvey,
  isOnDialog,
}) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    errors,
  } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      description: '',
      notedAt: new Date(),
      survey: '',
      surveyResultId: '',
    },
  });

  const [
    renderSignedNotesButton,
    listCheckedSign,
    patientNoteDetail,
    checkedAll,
    handleSetListCheckedSign,
    handleSetPatientNoteDetail,
    setCheckedAll,
  ] = useDialogNote();

  const [idSubNoteEdit, setIdSubNoteEdit] = useState('');

  const currentSurveyResult = useRef('');

  const watchSurvey = watch('survey');

  const watchSurveyResult = watch('surveyResultId');

  const watchType = watch('type');

  const [tabIndex, setTabIndex] = useState(0);

  const patientDetailContext = useContext(PatientDetailContext);

  const client = useApolloClient();

  const [getDataForNote, { data: dataForNote }] = useLazyQuery<
    GetDataForNoteQuery,
    GetDataForNoteQueryVariables
  >(GET_DATA_FOR_NOTE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setValue(
        'survey',
        data?.getSurveyResultGroup?.nodes?.find(item =>
          item?.surveyResult
            ?.map(item => item?.surveyResultId)
            ?.includes(noteSelected?.surveyResult?.surveyResultId),
        )?.idSurvey,
      );
    },
  });

  const changeCheck = (id?: string) => {
    if (id) {
      const indexSubNote = listCheckedSign.indexOf(id);
      const checkedSignData = [...listCheckedSign];
      if (indexSubNote > -1) {
        checkedSignData.splice(indexSubNote, 1);
        handleSetListCheckedSign(checkedSignData);
        return;
      }
      handleSetListCheckedSign([...checkedSignData, id]);
    } else {
      setCheckedAll(!checkedAll);
      handleSetListCheckedSign(
        checkedAll
          ? []
          : patientNoteDetail?.notes
            ?.filter(item => item.status === NoteStatus.Draft)
            .map(item => item.idNote) || [],
      );
    }
  };

  const arrayFetchQueries = () => {
    if (
      patientDetailContext?.isExtend &&
      patientDetailContext?.indexTab === 1
    ) {
      return [
        {
          query: GET_NOTE_TAG,
          variables: {
            params: {
              patientId: patientDetailContext?.patient.owner?.idOwner,
              page: 1,
              limit: 5,
            },
          },
        },
        {
          query: GET_PATIENT_NOTES,
          variables: {
            params: {
              patientId: patientDetailContext?.patient.owner?.idOwner,
            },
          },
        },
      ];
    }
    return [
      {
        query: GET_NOTE_TAG,
        variables: {
          params: {
            patientId: patientDetailContext?.patient.owner?.idOwner,
            page: 1,
            limit: 5,
          },
        },
      },
    ];
  };

  const setDataAfterAddDraft = (noteDetail: PatientNoteFragment | null) => {
    handleSetPatientNoteDetail(noteDetail);
    setValue('notedAt', new Date());
    setValue('description', '');
    setValue('type', null);
  };

  const updateSurveyResultLocal = (
    note: PatientNoteFragment | null,
    currentSurveyResult?: string,
  ) => {
    if (note) {
      try {
        client.writeFragment({
          id: `SurveyResultEmbedded:${note.surveyResult?.surveyResultId}`,
          fragment: gql`
            fragment SurveyResultEmbeddedUpdate on SurveyResultEmbedded {
              patientNote {
                ...PatientNote
              }
            }
            ${PatientNoteFragmentGql}
          `,
          fragmentName: 'SurveyResultEmbeddedUpdate',
          data: {
            patientNote: { ...note },
            __typename: 'SurveyResultEmbedded',
          },
        });
      } catch (err) {
        console.log(err);
      }
    }
    if (currentSurveyResult) {
      try {
        client.writeFragment({
          id: `SurveyResultEmbedded:${currentSurveyResult}`,
          fragment: gql`
            fragment SurveyResultEmbeddedUpdate on SurveyResultEmbedded {
              patientNote {
                ...PatientNote
              }
            }
            ${PatientNoteFragmentGql}
          `,
          fragmentName: 'SurveyResultEmbeddedUpdate',
          data: {
            patientNote: null,
            __typename: 'SurveyResultEmbedded',
          },
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const [createPatientNote, { loading: loadingCreateNote }] = useMutationCustom<
    CreatePatientNoteMutation,
    CreatePatientNoteMutationVariables
  >({
    api: CREATE_PATIENT_NOTE,
    refetchQueries: arrayFetchQueries(),
    textSuccess: 'Add note successfully',
    callbackSuccess: data => {
      customEvent("addNotePD", "NAVIGATOR")
      updateSurveyResultLocal(data.createPatientNote);
      setDataAfterAddDraft(data.createPatientNote);
    },
  });

  const [addMorePatientNote, { loading: loadingAddMore }] = useMutationCustom<
    AddMorePatientNoteMutation,
    AddMorePatientNoteMutationVariables
  >({
    api: ADD_MORE_PATIENT_NOTE,
    textSuccess: 'Add more note successfully',
    callbackSuccess: data => {
      customEvent("AddMoreNotePD", "NAVIGATOR")
      // updateSurveyResultLocal(data.addMorePatientNote);
      setDataAfterAddDraft(data.addMorePatientNote);
    },
  });

  const [
    changeSurveyOfNote,
    { loading: loadingChangeSurveyOfNote },
  ] = useMutationCustom<
    ChangeSurveyResultOfNoteMutation,
    ChangeSurveyResultOfNoteMutationVariables
  >({
    api: CHANGE_SURVEY_RESULT_OF_NOTE,
    textSuccess: 'Change survey result of note successfully',
    callbackSuccess: data => {
      customEvent("ChangeSurveyResultNotePD", "NAVIGATOR")
      updateSurveyResultLocal(
        data.changeSurveyResultOfNote,
        currentSurveyResult.current,
      );
      handleSetPatientNoteDetail(data.changeSurveyResultOfNote);
    },
  });

  const [
    updatePatientNote,
    { loading: loadingUpdatePatient },
  ] = useMutationCustom<
    UpdatePatientNoteMutation,
    UpdatePatientNoteMutationVariables
  >({
    api: UPDATE_PATIENT_NOTE,
    textSuccess: 'Update Sub-note successfully',
    callbackSuccess: data => {
      customEvent("UpdateSubNotePD", "NAVIGATOR")
      setIdSubNoteEdit('');
      setDataAfterAddDraft(data.updatePatientNote);
    },
  });

  const [
    removePatientNote,
    { loading: loadingRemovePatientNote },
  ] = useMutationCustom<
    RemovePatientNoteMutation,
    RemovePatientNoteMutationVariables
  >({
    api: REMOVE_PATIENT_NOTE,
    textSuccess: 'Delete note successfully',
    callbackSuccess: data => {
      customEvent("DeleteNotePD", "NAVIGATOR")
      handleSetPatientNoteDetail(data.removePatientNote);
    },
  });

  const setUpData = useCallback(() => {
    getDataForNote({
      variables: {
        paramsNote: {
          patientId: patientDetailContext?.patient.owner?.idOwner,
          sortByOrder: { name: 1 },
        },
        paramsType: {
          page: 1,
          sortByOrder: { name: 1 },
          patientId: patientDetailContext?.patient?._id,
        },
      },
    });
    handleSetPatientNoteDetail(noteSelected || null);
    setValue(
      'survey',
      noteSelected?.survey?.surveyId || infoSurveyWhenCreate?.survey || '',
    );
    setValue(
      'surveyResultId',
      noteSelected?.surveyResult?.surveyResultId ||
      infoSurveyWhenCreate?.result ||
      '',
    );
    currentSurveyResult.current =
      noteSelected?.surveyResult?.surveyResultId ||
      infoSurveyWhenCreate?.result ||
      '';
  }, [
    getDataForNote,
    handleSetPatientNoteDetail,
    infoSurveyWhenCreate,
    noteSelected,
    patientDetailContext,
    setValue,
  ]);

  const enterDialog = () => {
    setUpData();
  };

  useEffect(() => {
    if (isOnDialog) {
      setUpData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnDialog]);

  useEffect(() => {
    if (watchType) {
      if (watchType?.description) {
        setValue('description', watchType?.description);
      }
      return;
    }
    setValue('description', '');
  }, [setValue, watchType]);

  const saveDraft = handleSubmit(data => {
    // console.log(currentSurveyResult.current);
    // return;
    const dataTrim = { ...trimObjectValue(data) };
    const { surveyResultId, notedAt, description, type } = dataTrim;
    if (idSubNoteEdit) {
      updatePatientNote({
        variables: {
          params: {
            noteId: patientNoteDetail?._id,
            patientId: patientDetailContext?.patient.owner?.idOwner,
            subNoteId: idSubNoteEdit,
            description,
            notedAt,
            type: type?.idType,
          },
        },
      });
      return;
    }

    if (patientNoteDetail) {
      addMorePatientNote({
        variables: {
          params: {
            noteId: patientNoteDetail._id,
            description,
            notedAt,
            patientId: patientDetailContext?.patient.owner?.idOwner,
            type: type?.idType,
          },
        },
      });
    } else {
      createPatientNote({
        variables: {
          params: {
            surveyResultId: surveyResultId || null,
            notedAt,
            description,
            userId: patientDetailContext?.patient.owner?.idOwner,
            type: type?.idType,
          },
        },
      });
    }
  });

  const editSubNote = useCallback(
    (item?: SubNoteFragment) => {
      setIdSubNoteEdit(item?.idNote || '');
      setValue('notedAt', item?.notedAt || new Date());
      setValue('description', item?.description || '');
      setValue('type', item?.type || null);
    },
    [setValue],
  );

  const handleDeleteSub = useCallback(
    (id: string) => {
      removePatientNote({
        variables: {
          params: {
            noteId: patientNoteDetail?._id,
            patientId: patientDetailContext?.patient.owner?.idOwner,
            subNoteId: id,
          },
        },
      });
    },
    [patientDetailContext, patientNoteDetail, removePatientNote],
  );

  const updateSurveyResult = () => {
    changeSurveyOfNote({
      variables: {
        params: {
          noteId: patientNoteDetail?._id,
          patientId: patientDetailContext?.patient.owner?.idOwner,
          surveyResultId: getValues('surveyResultId') || null,
        },
      },
    });
  };

  const closeDialog = () => {
    toogleDialog!(false);
    patientDetailContext?.handleSetDialogOpen('');
    if (patientNoteDetail) {
      client.writeFragment({
        id: `PatientNote:${patientNoteDetail?._id}`,
        fragment: PatientNoteFragmentGql,
        fragmentName: 'PatientNote',
        data: { ...patientNoteDetail },
      });
    }
  };

  const listSurveyResult = useMemo(() => {
    return watchSurvey
      ? dataForNote?.getSurveyResultGroup?.nodes.find(item =>
        Object.is(item.idSurvey, watchSurvey),
      )?.surveyResult || []
      : [];
  }, [dataForNote, watchSurvey]);

  const onChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const isInactive = useMemo(() => {
    return patientDetailContext?.isInactive;
  }, [patientDetailContext]);

  const renderContentDialog = () => {
    return (
      <Grid container spacing={2}>
        {!isInactive && (
          <>
            {!notAbleToUpdateSurvey && (
              <Grid item xs={12}>
                <TypographyBold variant="h6">
                  Select Survey Result
                </TypographyBold>
              </Grid>
            )}
            <Grid item xs={12} sm={5}>
              <Controller
                name="survey"
                control={control}
                onChange={([, data]) => {
                  setValue('surveyResultId', '');
                  return data?.key || '';
                }}
                defaultValue={''}
                as={
                  <TextField
                    label="Survey"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    select
                    variant="outlined"
                    SelectProps={{
                      displayEmpty: true,
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      },
                    }}
                    disabled={!!notAbleToUpdateSurvey}
                    value={getValues('survey') || ''}
                    fullWidth
                  >
                    <MenuItem value="">None</MenuItem>
                    {dataForNote?.getSurveyResultGroup?.nodes?.map(item => {
                      return (
                        <MenuItem key={item.idSurvey} value={item.idSurvey}>
                          {item?.name} - v{item?.version}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                }
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <Controller
                name="surveyResultId"
                control={control}
                fullWidth
                as={
                  <TextField
                    label="Survey Result"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    select
                    disabled={!!notAbleToUpdateSurvey}
                    variant="outlined"
                    SelectProps={{
                      displayEmpty: true,
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    {listSurveyResult?.map(item => {
                      return (
                        <MenuItem
                          disabled={
                            !!item.patientNote &&
                            currentSurveyResult.current !== item.surveyResultId
                          }
                          key={item.surveyResultId}
                          value={item.surveyResultId}
                        >
                          {formatDate(item.createdAt, true)}
                          {!!item.patientNote &&
                            currentSurveyResult.current !==
                            item.surveyResultId && (
                              <Chip
                                className="ml-8"
                                label="Used"
                                color="default"
                              />
                            )}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                }
              />
            </Grid>
            {patientNoteDetail && !notAbleToUpdateSurvey && (
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row-reverse">
                  <ButtonLoading
                    disabled={currentSurveyResult.current === watchSurveyResult}
                    text="Update Survey Result"
                    callbackClick={updateSurveyResult}
                    loading={loadingChangeSurveyOfNote}
                    Icon={<EditIcon />}
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TypographyBold variant="h6">Add Note</TypographyBold>
            </Grid>
            <Grid item xs={12} sm={4}>
              <AutoCompleteSelect
                small
                disablePortal
                name="type"
                label="Type"
                control={control}
                nameOption="name"
                options={dataForNote?.getPatientNoteTypes?.nodes || []}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                helperText={
                  !!errors.description ? errors.description.message : ''
                }
                required
                error={!!errors.description}
                label="Description"
                InputLabelProps={{ shrink: true }}
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                name="description"
                inputRef={register({
                  validate: {
                    required: value =>
                      value.trim() !== '' || 'This field is required',
                  },
                })}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6}>
          <Controller
            as={
              <DateTimePicker
                placeholder="mm/dd/yyyy hh:mm"
                variant="inline"
                label="Time"
                format="MM/dd/yyyy HH:mm"
                InputLabelProps={{ shrink: true }}
                size="small"
                fullWidth
                value={() => { }}
                onChange={() => { }}
                autoOk
                inputVariant="outlined"
              />
            }
            name="notedAt"
            control={control}
          />
        </Grid>
        {!isInactive && (
          <Grid item xs={12} sm={6} className="h-100">
            <Box display="flex" flexDirection="row-reverse">
              <ButtonLoading
                text={idSubNoteEdit ? 'Update Note' : 'Save Draft'}
                callbackClick={saveDraft}
                loading={
                  loadingCreateNote || loadingAddMore || loadingUpdatePatient
                }
                Icon={idSubNoteEdit ? <EditIcon /> : <AddIcon />}
                className="ml-8"
              />
              {idSubNoteEdit && (
                <DialogButton isCancel onClickButton={() => editSubNote()} />
              )}
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <TableNotes
            changeCheck={changeCheck}
            checkedAll={checkedAll}
            listCheckedSign={listCheckedSign}
            subNotes={patientNoteDetail?.notes}
            editSubNote={editSubNote}
            handleDeleteSub={handleDeleteSub}
            loadingDeleteSubNote={loadingRemovePatientNote}
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      {isOnDialog ? (
        renderContentDialog()
      ) : (
        <Dialog
          onEntered={enterDialog}
          open={!!open}
          onClose={closeDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitleClose
            title={noteSelected ? 'Update Note' : 'Add Note'}
            onClose={closeDialog}
          />
          <DialogContent>
            <PaperBorder square>
              <Tabs
                indicatorColor="primary"
                value={tabIndex}
                onChange={onChangeTab}
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab className="text-tranform-none" label="Note" />
                <Tab className="text-tranform-none" label="Tasks" />
                <Tab className="text-tranform-none" label="Checklists" />
                <Tab className="text-tranform-none" label="Surveys" />
              </Tabs>
            </PaperBorder>
            <Box mt={2}>
              {Object.is(tabIndex, 0) && renderContentDialog()}
              {Object.is(tabIndex, 1) && <FollowUp tabIndexDialog={tabIndex} />}
              {Object.is(tabIndex, 2) && <Checklist />}
              {Object.is(tabIndex, 3) && <Surveys />}
            </Box>
          </DialogContent>
          <DialogActions>
            <DialogButton isCancel onClickButton={closeDialog} />
            {!isInactive && (
              <>{Object.is(tabIndex, 0) && <>{renderSignedNotesButton()}</>}</>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default React.memo(DialogNote);
