import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { ButtonLoading } from 'components';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButtonDeleteStyled, TypographyBold } from 'share/component_css';
import { styled } from '@material-ui/core/styles';

type Props = {
  open: boolean;
  toogle: (status: boolean) => void;
  handleConfirm?: () => void;
  title?: string;
  loading?: boolean;
  content?: any;
  icon?: React.ReactNode;
  action?: string;
  buttonText?: string;
  hasCancel?: boolean;
  callbackClose?: () => void;
};

const TypoBoldStyled = styled(TypographyBold)({
  fontSize: '1.1rem',
  padding: '16px',
});

export const DeleteConfirm: React.FC<Props> = ({
  toogle,
  open,
  handleConfirm,
  title,
  loading,
  content,
  icon,
  action,
  buttonText,
  hasCancel,
  callbackClose,
}) => {
  const handleClose = () => {
    if (callbackClose) {
      callbackClose();
    }
    if (action === 'warning') {
      return;
    }
    toogle(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="xs">
        <DialogContent className="pt-0">
          <Grid container spacing={2}>
            <Grid item xs={12} container justify="center">
              <IconButtonDeleteStyled
                aria-label="delete"
                typeaction={action ? action : 'error'}
              >
                {icon ? icon : <DeleteIcon />}
              </IconButtonDeleteStyled>
            </Grid>
            <Grid item xs={12}>
              <TypoBoldStyled align="center" style={{ fontWeight: 500 }}>
                {title ? title : 'Are you sure you want to delete this item?'}
              </TypoBoldStyled>
            </Grid>
            {content && (
              <Typography align="center" color="textSecondary">
                {content}
              </Typography>
            )}
          </Grid>
        </DialogContent>
        <DialogActions className={content ? 'pt-16' : 'pt-8'}>
          {(action !== 'warning' || hasCancel) && (
            <ButtonLoading
              callbackClick={handleClose}
              text="Cancel"
              color="default"
              width="50%"
            />
          )}
          <ButtonLoading
            callbackClick={handleConfirm}
            text={buttonText ? buttonText : 'Delete'}
            loading={!!loading}
            width={action !== 'warning' || hasCancel ? '50%' : '100%'}
            variant="contained"
            colorCustom={action ? action : 'error'}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DeleteConfirm;
