import React from 'react';
import { BoxWrapTreatment, AvatarListStyled } from '../styles';
import { TypoThreeDot } from 'share/component_css';
import { Badge, Box, Chip } from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';
import { UserStatus } from 'types.d';

type Props = {
  name: string;
  callbackOnClick: () => void;
  unreadCount?: number;
  addedComponent?: React.ReactElement;
  isSelected?: boolean;
  status?: UserStatus;
};

export const WrapItem: React.FC<Props> = ({
  callbackOnClick,
  name,
  unreadCount,
  addedComponent,
  isSelected,
  status,
}) => {
  return (
    <BoxWrapTreatment
      p={1.5}
      className="cursor-pointer"
      width="100%"
      onClick={callbackOnClick}
      display="flex"
      alignItems="center"
      selected={isSelected ? 'isSelected' : ''}
    >
      <AvatarListStyled className="mr-16">
        {name[0] ? name[0].toUpperCase() : 'N'}
      </AvatarListStyled>
      <TypoThreeDot
        color={isSelected ? 'inherit' : 'primary'}
        variant="subtitle1"
      >
        {name || 'N/A'}
      </TypoThreeDot>
      {status === UserStatus.Inactive && (
        <Chip
          className="ml-8"
          size="small"
          variant="default"
          label="Inactive"
        />
      )}
      <Box className="ml-auto">
        {!!unreadCount && (
          <Badge
            className={addedComponent ? 'mr-8' : ''}
            badgeContent={unreadCount}
            color="secondary"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <CommentIcon color="action" />
          </Badge>
        )}
        {addedComponent}
      </Box>
    </BoxWrapTreatment>
  );
};

export default React.memo(WrapItem);
