import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

type Props = {};

export const LoadingTags: React.FC<Props> = () => {
  return (
    <>
      {Array.from(Array(4).keys()).map(item => {
        return (
          <Skeleton
            key={item}
            className="mr-8"
            width={150}
            height={32}
            variant="rect"
          />
        );
      })}
    </>
  );
};

export default React.memo(LoadingTags);
