import React, { useCallback, useContext, useRef, useMemo } from 'react';
import { ContentPatientContext, PatientTagsContext } from 'share/context';
import AddIcon from '@material-ui/icons/Add';
import LoadingItem from './LoadingTags';
import { PatientFollowUpFragment } from 'types.d';
import { Tooltip, Box, IconButton } from '@material-ui/core';
import {
  formatDate,
  convertArrayToObject,
  checkFilterFollowUp,
  sortTaskType,
} from 'share/utils';
import { FOLLOW_UP_STATUS } from 'CONST';
import { TypoThreeDot, ChipStyled } from 'share/component_css';
import DialogFollowUp from 'modules/patients/components/FollowUp/DialogFollowUp';
import { useToogleDialog, usePopover } from 'hooks';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { DeleteConfirm, ScrollStyled } from 'components';
import DeleteIcon from '@material-ui/icons/Delete';
import { useServices } from '../../services';
import BoxShowMore from './BoxShowMore';
import { Rule } from 'modules/patients/interfaces';
import { DataFilterDate } from 'modules/dashboard/interfaces';
type Props = {
  loadingDataGroup: boolean;
  patientFollowUps?: PatientFollowUpFragment[];
};

const objFollowUp = convertArrayToObject(FOLLOW_UP_STATUS, 'value');

export const TaskTag: React.FC<Props> = ({
  loadingDataGroup,
  patientFollowUps,
}) => {
  const patientTagsContext = useContext(PatientTagsContext);

  const contentPatientContext = useContext(ContentPatientContext);

  const followUpsFilter = (contentPatientContext?.filterFromConfig as Rule[])?.find(
    item => item?.followUpDate || item?.followUpType,
  );

  let listFollowUps = [] as PatientFollowUpFragment[];

  const listPatientFollowUps = useMemo(() => {
    patientFollowUps?.map(item =>
      checkFilterFollowUp(
        item,
        followUpsFilter?.followUpType,
        followUpsFilter?.followUpDate as DataFilterDate,
      )
        ? listFollowUps.unshift(item)
        : listFollowUps.push(item),
    );
    return sortTaskType(listFollowUps, followUpsFilter);
  }, [followUpsFilter, listFollowUps, patientFollowUps]);

  const { handleDeleteFollowUp, loadingDeleteFollowUp } = useServices();

  const [open, close, DefaultPopover, defaultPopoverProps] = usePopover();

  const [isOpenFollowUp, setOpenFollowUp] = useToogleDialog();

  const [isOpenDelete, setOpenDelete] = useToogleDialog();

  const followUpSelected = useRef<PatientFollowUpFragment | undefined>(
    undefined,
  );

  const isOnTab = useMemo(() => {
    return patientTagsContext?.isExtend && patientTagsContext?.indexTab === 2;
  }, [patientTagsContext]);

  const openAddDialog = useCallback(() => {
    //open dialog from task tab
    if (isOnTab) {
      patientTagsContext?.handleSetDialogOpen('add-followup');
      return;
    }
    //open dialog from task tag
    setOpenFollowUp(true);
    followUpSelected.current = undefined;
  }, [isOnTab, patientTagsContext, setOpenFollowUp]);

  const handleEdit = (item: PatientFollowUpFragment) => {
    followUpSelected.current = item;
    //open dialog from task tab
    if (isOnTab) {
      patientTagsContext?.handleSetDialogOpen(`editFollowup`, item);
      return;
    }
    //open dialog from task tag
    setOpenFollowUp(true);
  };

  const handleDelete = () => {
    handleDeleteFollowUp(followUpSelected.current?._id, () => {
      setOpenDelete(false);
    });
  };

  const openDialogDelete = () => {
    close();
    //open dialog from task tab
    if (isOnTab) {
      patientTagsContext?.handleSetDialogOpen(
        `deleteFollowup-${followUpSelected.current?._id}`,
      );
      return;
    }
    //open dialog from task tag
    setOpenDelete(true);
  };
  return (
    <>
      <DeleteConfirm
        loading={loadingDeleteFollowUp}
        handleConfirm={handleDelete}
        open={isOpenDelete}
        toogle={setOpenDelete}
      />
      {isOpenFollowUp && (
        <DialogFollowUp
          followUpSelected={followUpSelected.current}
          open={isOpenFollowUp}
          toogleDialog={setOpenFollowUp}
        />
      )}
      <DefaultPopover {...defaultPopoverProps}>
        <Box display="flex" flexDirection="column" p={0.5}>
          <Tooltip title="Delete">
            <IconButton
              onClick={openDialogDelete}
              aria-label="delete"
              color="secondary"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </DefaultPopover>
      {!patientTagsContext?.isInactive && (
        <ChipStyled
          colortext="true"
          className="mr-8"
          onClick={openAddDialog}
          label={
            <Box display="flex">
              <AddIcon fontSize="small" />
            </Box>
          }
        />
      )}
      {patientFollowUps?.length !== 0 && <BoxShowMore index={2} />}

      {loadingDataGroup && <LoadingItem />}
      {patientFollowUps && patientFollowUps?.length > 0 && !loadingDataGroup && (
        <ScrollStyled
          loading={loadingDataGroup}
          listLength={listPatientFollowUps.slice(0, 5)?.length}
        >
          {listPatientFollowUps.slice(0, 5).map(item => {
            return (
              <ChipStyled
                className="cursor-pointer"
                onClick={() => handleEdit(item)}
                key={item._id}
                backgroundcolor={item.type?.color || undefined}
                label={
                  <Tooltip
                    key={item._id}
                    title={
                      <Box display="flex" flexDirection="column">
                        {patientTagsContext?.generateInformation([
                          {
                            name: 'Description',
                            info: item.note,
                          },
                          {
                            name: 'Task Type',
                            info: item.type?.name,
                          },
                          {
                            name: 'Task Date',
                            info: item.followUpDate
                              ? formatDate(item.followUpDate)
                              : 'N/A',
                          },
                          {
                            name: 'Created Date',
                            info: item.createdAt
                              ? formatDate(item.createdAt)
                              : 'N/A',
                          },
                          {
                            name: 'Status',
                            info: objFollowUp[item.status].title || 'N/A',
                          },
                        ])}
                      </Box>
                    }
                  >
                    <TypoThreeDot variant="subtitle2">
                      {`${item.note} - ${formatDate(
                        item.followUpDate,
                      )} - ${objFollowUp[item.status].title || 'N/A'}
                      `}
                    </TypoThreeDot>
                  </Tooltip>
                }
                onDelete={e => {
                  open(e);
                  followUpSelected.current = item;
                }}
                deleteIcon={
                  !patientTagsContext?.isInactive ? <MoreHorizIcon /> : <></>
                }
              />
            );
          })}
        </ScrollStyled>
      )}
    </>
  );
};

export default React.memo(TaskTag);
