import React from 'react';
import { ButtonLoading } from 'components';
import DialogShareAssignTreatment from './DialogShareAssignTreatment';
import { useToogleDialog } from 'hooks';
import MessageIcon from '@material-ui/icons/Message';

type Props = {
  idTreatment?: string | null;
  smsMessage?: string;
};

export const ButtonSendSMS: React.FC<Props> = ({ idTreatment, smsMessage }) => {
  const [isOpen, toogleDialog] = useToogleDialog();

  return (
    <>
      {isOpen && (
        <DialogShareAssignTreatment
          open={isOpen}
          setOpenModal={toogleDialog}
          idTreatment={idTreatment}
          smsMessage={smsMessage}
          isSendSMS
        />
      )}
      <ButtonLoading
        fullWidth
        Icon={<MessageIcon />}
        text="Text"
        callbackClick={() => toogleDialog(true)}
        variant="contained"
      />
    </>
  );
};
export default ButtonSendSMS;
