import React, { useContext, useMemo, useRef, useState } from 'react';
import { TableRow, TableCell, Tooltip, IconButton } from '@material-ui/core';
import { ChipAttachStyled, ChipStatus } from '../../styles';
import { COLOR_STATUS_CHECKLIST } from 'CONST';
import ButtonDetachChecklist from './ButtonDetachChecklist';
import DialogChecklist from './DialogChecklist';
import DialogResultChecklist from './DialogResultChecklist';
import { PatientChecklistFragment, ResultChecklist } from 'types.d';
import { TableRowStyled } from '../../styles';
import { useServices } from '../../services';
import { Box, Typography } from '@material-ui/core';
import { TypographyItalic } from 'share/component_css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { formatDate } from 'share/utils';
import { useToogleDialog } from 'hooks';
import { PlaylistEdit } from 'mdi-material-ui';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { PatientDetailContext } from 'share/context';

type Props = {
  item: PatientChecklistFragment;
  callbackAfterAttach: (isDetach?: boolean) => void;
};

export const Checklist: React.FC<Props> = ({ item, callbackAfterAttach }) => {
  const [isExpand, setIsExpand] = useState(false);

  const patientDetailContext = useContext(PatientDetailContext);

  const isInactive = patientDetailContext?.isInactive;

  const showDetailChecklist = () => {
    setIsExpand(!isExpand);
  };

  const detailChecklistResult = useRef<ResultChecklist | undefined>(undefined);

  const [isOpenDialogResult, toogleDialogResult] = useToogleDialog();

  const { getPatientChecklists } = useServices();

  const [
    isOpenDialogChangeChecklist,
    toogleDialogChangeChecklist,
  ] = useToogleDialog();

  const patientChecklists = useMemo(() => {
    return getPatientChecklists(item);
  }, [getPatientChecklists, item]);

  const checklistResultOrdered = useMemo(() => {
    return (
      item?.patientChecklists?.resultChecklist?.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      }) || []
    );
  }, [item]);

  return (
    <>
      {isOpenDialogChangeChecklist && (
        <DialogChecklist
          isOpen={isOpenDialogChangeChecklist}
          toogleOpen={toogleDialogChangeChecklist}
          checklistAttach={
            patientChecklists?.checklist?._id ||
            patientChecklists?.checklistAvailable?._id
          }
          patientChecklistId={patientChecklists?.patientChecklistId}
          checklistId={item.patientChecklistMonoId}
          callbackAfterAttach={callbackAfterAttach}
        />
      )}
      {isOpenDialogResult && (
        <DialogResultChecklist
          open={isOpenDialogResult}
          toogleDialog={toogleDialogResult}
          checklistId={item.patientChecklistMonoId}
          patientChecklistId={patientChecklists?.patientChecklistId}
          result={detailChecklistResult.current}
          latestChecklistId={item?.patientChecklists?.checklist?._id}
          checklistName={
            item?.patientChecklists?.checklist?.name ||
            item?.patientChecklists?.checklistAvailable?.name ||
            ''
          }
        />
      )}
      <TableRowStyled>
        <TableCell>
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => showDetailChecklist()} color="primary">
              {isExpand ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </IconButton>
            {patientChecklists?.checklist?.name ||
              patientChecklists?.checklistAvailable?.name ||
              'N/A'}
          </Box>
        </TableCell>

        <TableCell>{item.treatment?.name || 'N/A'}</TableCell>
        <TableCell>{item.survey?.name || 'N/A'}</TableCell>
        <TableCell align={!isInactive ? 'inherit' : 'right'}>
          <ChipAttachStyled
            label={patientChecklists?.checklist ? 'Manual' : 'Auto'}
            type={patientChecklists?.checklist ? 'manual' : 'auto'}
          />
        </TableCell>
        {!isInactive && (
          <TableCell align="right">
            <Tooltip title="Replace Checklist">
              <IconButton
                color="primary"
                onClick={() => toogleDialogChangeChecklist(true)}
              >
                <PlaylistEdit />
              </IconButton>
            </Tooltip>
            {patientChecklists?.checklist && (
              <>
                <ButtonDetachChecklist
                  isDetach={!!item.treatment || !!item.survey}
                  patientChecklistId={patientChecklists.patientChecklistId}
                  checklistId={item.patientChecklistMonoId}
                  callbackAfterSucessfully={callbackAfterAttach}
                />
              </>
            )}
          </TableCell>
        )}
      </TableRowStyled>
      {isExpand && (
        <TableRow>
          <TableCell colSpan={5}>
            <Box display="flex" flexWrap="wrap">
              {item?.patientChecklists?.patientChecklistId &&
                checklistResultOrdered.map(result => {
                  return (
                    <ChipStatus
                      className="mr-8 mb-8"
                      key={result?._id}
                      label={
                        <Box display="flex" alignItems="center">
                          <Typography className="ml-8" variant="subtitle2">
                            {`Updated ${formatDate(result.updatedAt, true)} - ${
                              COLOR_STATUS_CHECKLIST[result?.status].text
                            }`}
                          </Typography>
                        </Box>
                      }
                      bgcolor={COLOR_STATUS_CHECKLIST[result?.status].color}
                      onClick={() => {
                        detailChecklistResult.current = result;
                        toogleDialogResult(true);
                      }}
                      deleteIcon={<MoreHorizIcon />}
                    />
                  );
                })}
            </Box>

            {!item?.patientChecklists?.resultChecklist && (
              <TypographyItalic
                align="center"
                variant="subtitle1"
                color="textSecondary"
              >
                There are no Checklist Results
              </TypographyItalic>
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default React.memo(Checklist);
