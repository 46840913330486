import gql from 'graphql-tag';
import { MetaFragment } from './fragments';
export const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!, $params: UploadInput!) {
    uploadFile(file: $file, params: $params) {
      _id
      name
    }
  }
`;

export const GET_LIST_MEDIA = gql`
  query GetListMedia($params: GetListMediaInput) {
    getListMedias(params: $params) {
      nodes {
        _id
        name
        srcUrl
        createdAt
        mimeType
        thumbNailsUrls
        owner {
          firstName
          lastName
          middleName
          idOwner: _id
        }
      }
      meta {
        ...Meta
      }
    }
  }
  ${MetaFragment}
`;

export const DELETE_MEDIA = gql`
  mutation DeleteMedia($params: IdInputType!) {
    deleteMedia(params: $params)
  }
`;
