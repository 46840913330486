import { createUploadLink } from 'apollo-upload-client';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { split } from 'apollo-link';

const subcriptionClient = new SubscriptionClient(
  process.env.REACT_APP_WS_URL as string,
  {
    reconnect: true,
  },
);
const httpLink = createUploadLink({
  uri: process.env.REACT_APP_URL_API,
  credentials: 'include',
});
const websocketLink = new WebSocketLink(subcriptionClient);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  websocketLink,
  httpLink,
);
export const linkClient = {
  splitLink,
  subcriptionClient,
};
