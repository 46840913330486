import React, { useState, useContext } from 'react';
import { QRCODE_CREATE } from 'gql';
import { QrcodeCreateMutation, QrcodeCreateMutationVariables } from 'types.d';
import { useSnackbar } from 'notistack';
import { handleError } from 'share/utils';
import { ButtonLoading, QrCodeIcon } from 'components';
import ViewQrCode from './DialogViewQrCode';
import { TreatmentTreeContext } from 'share/context';
import { useMutationCustom } from 'hooks';
type Props = {
  qrCode?: string | null;
  urlSharing?: string | null;
  idTreatment?: string | null;
  smsMessage?: string;
  handleClose?: () => void;
  nameTreatment?: string | null;
};

export const ButtonQrCode: React.FC<Props> = ({
  qrCode,
  urlSharing,
  idTreatment,
  handleClose,
  smsMessage,
  nameTreatment,
}) => {
  const [openDialog, setOpenDialog] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const treatmentContext = useContext(TreatmentTreeContext);

  const [
    qrCodeCreate,
    { loading: loadingQrCodeCreate, error },
  ] = useMutationCustom<QrcodeCreateMutation, QrcodeCreateMutationVariables>({
    api: QRCODE_CREATE,
    textSuccess: 'Create QR Code successfully',
    callbackSuccess: () => {
      treatmentContext?.updateTreeQrCode();
      setOpenDialog('qrCode');
    },
    callbackError: () => {
      const arrError = handleError(error?.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
  });

  const handleQrCode = () => {
    if (qrCode) {
      setOpenDialog('qrCode');
    } else {
      qrCodeCreate({
        variables: { params: { _id: idTreatment } },
      });
    }
  };

  return (
    <>
      <ViewQrCode
        idTreatment={idTreatment}
        link={`${process.env.REACT_APP_LINK_S3}${process.env.REACT_APP_MEDIA_BUCKET}/${qrCode}`}
        open={openDialog}
        setOpenModal={setOpenDialog}
        urlSharing={`${process.env.REACT_APP_DOMAIN}${urlSharing}`}
        close={handleClose}
        smsMessage={smsMessage}
        nameTreatment={nameTreatment}
      />
      <ButtonLoading
        text="Share"
        callbackClick={handleQrCode}
        Icon={<QrCodeIcon />}
        loading={loadingQrCodeCreate}
        variant="text"
        flexStart
      />
    </>
  );
};

export default React.memo(ButtonQrCode);
