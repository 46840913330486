import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Tooltip,
  Grid,
  IconButton,
  TextareaAutosize,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, styled, Theme, useTheme } from '@material-ui/core/styles';
import { FillSurvey } from './FillSurvey';
import { useCheckLogin } from 'hooks/useCheckLogin';
import { useSnackbar } from 'notistack';
import ReactMarkdown from 'react-markdown';
import { useCustomPermission, useEffectOnlyOnce, useToogleDialog } from 'hooks';
import {
  CONTEXT_TREATMENTS,
  KEY_LOCAL_ASSIGN_TREATMENT,
  KEY_LOCAL_ASSIGNED_SURVEYS,
  KEY_SESSION_ORGANIZATION_ID,
} from 'CONST';
import {
  getLocalStorage,
  getSessionStorage,
  handleError,
  handleScrollClick,
} from 'share/utils';
import { LinkRouterStyled } from 'share/component_css';
import LoadingChatbot from 'components/LoadingChatbot';
import SendIcon from '@material-ui/icons/Send';
import Feedback from './Feedback';
import CommentModal from './CommentModal';
import { StepWrapper } from 'components';
import SettingsModal from './SettingsModal';
import { Cancel, Settings } from '@material-ui/icons';
import ContextSettingsModal from './ContextSettingsModal';
import { LayoutContext } from 'share/context';
import ReactDOMServer from 'react-dom/server';
import { useParams } from 'react-router-dom';
import ChatbotSurvey from './ChatbotSurvey';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  GetChatBotFormQuery,
  GetChatBotFormQueryVariables,
  QueryChatBotArgs,
} from 'types.d';
import { GET_CHATBOT_FORM } from 'gql/server/chatbot';

type Props = {
  assignedSurvey?: boolean;
  setAssignedSurvey?: Function;
  loading: boolean;
  messages: any;
  setMessages: any;
  chatBodyRef: any;
  inputMessage: any;
  setInputMessage: any;
  handleUserInput: Function;
  surveyInitiated: any;
  setSurveyInitiated: any;
  selectedSlug: any;
  setSelectedSlug: any;
  selectedSurvey: any;
  setSelectedSurvey: any;
  initiateSurvey: Function;
  isMobile?: boolean;
  fromAnotherComp?: any;
  scrollToBottom?: () => void;
  setResponseAdded: Function;
  setTreatmentContextFilteredIds?: React.Dispatch<
    React.SetStateAction<string[]>
  >;
  handleSurveyOpen?: (flag: boolean) => void;
  surveyOpened?: boolean;
};

const MessageBoxStyled = styled(Box)({
  borderRadius: '5px',
  display: 'inline-block',
  maxWidth: '20rem',
  marginBottom: '10px',
  padding: '10px 15px',
});

const SurveyMessageBoxStyled = styled(Box)({
  backgroundColor: '#e0e0e0',
  color: 'black',
  borderRadius: '5px',
  display: 'inline-block',
  maxWidth: '20rem',
  marginTop: '5px',
  marginBottom: '10px',
  padding: '5px 15px',
  marginLeft: '',
});

const ChatSectionBoxStyled = styled(Box)({
  width: '100%',
  height: '100%',
  background: 'white',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const ArticleNameStyled = styled(Box)({
  '&:hover': {
    textDecoration: 'underline',
  },
});

const ArticleBoxStyled = styled(Box)({
  borderRadius: '10px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#3374ba21',
  },
});

export const GridMarkDownStyled = styled(Grid)({
  '& p': {
    margin: '0px',
  },
});

const useStyles = makeStyles(theme => ({
  '@keyframes blink': {
    '50%': {
      opacity: 0,
    },
  },
  '.blinking': {
    animation: '$blink 2.5s linear infinite',
  },
}));

const TextareaAutosizeStyled = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  border: 'none',
  borderRadius: '2px',
  marginTop: '2px',
  fontSize: '0.875rem',
  fontFamily: 'Roboto',
  fontWeight: 400,
  lineHeight: 1.43,
  letterSpacing: '0.01071em',
  '&:focus-visible': {
    outline: 'none',
    boxShadow: 'none',
  },
}));

const ReactMarkdownStyled = styled(ReactMarkdown)({
  '& p': {
    margin: 0,
  },
});

const HeaderBoxStyled = styled(Box)(({ theme }: { theme: Theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 20px',
  marginTop: '65px',
  zIndex: 1,
}));

const SettingsButtonStyled = styled(IconButton)({
  color: 'white',
});

export const Chatbot: React.FC<Props> = (props: Props) => {
  let { id } = useParams();

  const classes = useStyles();

  const theme = useTheme();

  const isLogin = useCheckLogin();

  const { isPatient, isUser, isAdmin, isNavigator } = useCustomPermission();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [pageLoading, setPageLoading] = useState(true);

  const [highlightedIndex, setHighlightedIndex] = useState<number>();

  const [textWords, setTextWords] = useState<string>('');

  const [settingsModal, setSettingsModal] = useToogleDialog();

  const layoutContext = useContext(LayoutContext);

  useEffectOnlyOnce(() => {
    setTimeout(() => {
      setPageLoading(false);
    }, 1000);
  });

  const [getChatbotForm, { loading }] = useLazyQuery<
    GetChatBotFormQuery,
    GetChatBotFormQueryVariables
  >(GET_CHATBOT_FORM, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      closeSnackbar();

      const form = JSON.parse(data.chatbotform).form;

      props.setSelectedSurvey({
        elements: form,
        dynamicForm: true,
      });

      props.setSurveyInitiated(false);

      if (props.handleSurveyOpen) props.handleSurveyOpen(true);

      enqueueSnackbar('Form Loaded.', {
        variant: 'success',
      });
    },
    onError(error) {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
  });

  const handleKeyPress = (e: { key: string }) => {
    if (e.key === 'Enter') {
      props.handleUserInput();
    }
  };

  const surveysBinding = (item: any) => {
    if (isLogin) {
      var assignedSurveys = JSON.parse(
        getLocalStorage(KEY_LOCAL_ASSIGNED_SURVEYS)!,
      );
    } else {
      var assignedSurveys = JSON.parse(
        getSessionStorage(KEY_LOCAL_ASSIGNED_SURVEYS)!,
      );
    }

    if (assignedSurveys != null && assignedSurveys.length > 0) {
      const index = assignedSurveys.findIndex(
        (obj: any) => obj.token === item.token,
      );
      if (index !== -1) {
        props.setAssignedSurvey!(true);
      }
    }
    if (isLogin && (isPatient || isUser)) {
      if (item.token && !props.assignedSurvey) {
        enqueueSnackbar('You have successfully submitted this survey.', {
          variant: 'info',
        });
        return;
      }

      const survey = JSON.parse(item.survey.toString());
      if (survey.pages[0].description) {
        props.setMessages([
          ...props.messages,
          { text: survey.pages[0].description, type: 'bot' },
        ]);
      }

      props.setSelectedSurvey({
        elements: survey.pages[0].elements,
        surveyId: item.surveyId,
        expiredDate: item.expiredDate,
        token: item.token ? item.token.toString() : null,
        surveyVersion: item.surveyVersion ? item.surveyVersion : null,
      });
      props.setSelectedSlug(item.slug);
      props.setSurveyInitiated(false);
    } else if (
      props.assignedSurvey &&
      item.token != undefined &&
      item.token != null
    ) {
      const survey = JSON.parse(item.survey.toString());
      if (survey.pages[0].description) {
        props.setMessages([
          ...props.messages,
          { text: survey.pages[0].description, type: 'bot' },
        ]);
      }
      props.setSelectedSurvey({
        elements: survey.pages[0].elements,
        surveyId: item.surveyId,
        token: item.token.toString(),
        surveyVersion: item.surveyVersion,
        expiredDate: item.expiredDate,
      });
      props.setSelectedSlug(item.slug);
      props.setSurveyInitiated(false);
    } else if (!isLogin && item.token && !props.assignedSurvey) {
      enqueueSnackbar('You have successfully submitted this survey.', {
        variant: 'info',
      });
      return;
    } else if (!isLogin) {
      const survey = JSON.parse(item.survey.toString());
      if (survey.pages[0].description) {
        props.setMessages([
          ...props.messages,
          { text: survey.pages[0].description, type: 'bot' },
        ]);
      }

      props.setSelectedSurvey({
        elements: survey.pages[0].elements,
        surveyId: item.surveyId,
        expiredDate: item.expiredDate,
        token: item.token ? item.token.toString() : null,
        surveyVersion: item.surveyVersion ? item.surveyVersion : null,
      });
      props.setSelectedSlug(item.slug);
      props.setSurveyInitiated(false);
    } else {
      enqueueSnackbar(
        'You are currently not logged in.Please log in to continue.',
        {
          variant: 'error',
        },
      );
    }
  };

  const handleDynamicSurveyClick = (message: any) => {
    const storedValue = getLocalStorage(CONTEXT_TREATMENTS);
    const treatmentContextFilteredIds = storedValue
      ? storedValue.split(',')
      : [];

    const treatmentAssigneeEmail =
      JSON.parse(getLocalStorage(KEY_LOCAL_ASSIGN_TREATMENT)!) != null &&
      JSON.parse(getLocalStorage(KEY_LOCAL_ASSIGN_TREATMENT)!).length > 0
        ? JSON.parse(getLocalStorage(KEY_LOCAL_ASSIGN_TREATMENT)!).find(
            (treatment: { email: any }) => treatment.email,
          )?.email
        : null;

    var treatmentsAssigned = JSON.parse(
      getLocalStorage(KEY_LOCAL_ASSIGN_TREATMENT)! as string,
    );
    var organization = getSessionStorage(KEY_SESSION_ORGANIZATION_ID)!;
    let params: QueryChatBotArgs['params'] = {
      query: message.formName,
    };
    if (treatmentsAssigned != null && treatmentsAssigned.length > 0) {
      params['treatmentAssignmentInput'] = treatmentsAssigned;
      params['nologkey'] = treatmentAssigneeEmail
        ? treatmentAssigneeEmail
        : message.logKey != null && message.logKey != 'null'
        ? message.logKey
        : message.historyKey;
    } else if (message.logKey != null && message.logKey != 'null') {
      params['nologkey'] = message.logKey;
    } else if (
      !isLogin &&
      (message.logKey == null || message.logKey == 'null')
    ) {
      params['nologkey'] = message.historyKey;
    }
    if (organization != null || organization != 'null' || organization != '') {
      params['organization'] = organization;
    }

    if (treatmentContextFilteredIds.length > 0) {
      params['contextTreatments'] = treatmentContextFilteredIds;
      params['contextRequired'] = true;
    }

    if ((!isLogin && params.nologkey) || isLogin) {
      enqueueSnackbar('Form Loading...', {
        persist: true,
        variant: 'info',
      });

      getChatbotForm({
        variables: {
          params,
        },
      });
    }
  };

  const handleContextClick = (start: string, end: string) => {
    if (layoutContext) {
      const filteredContent = (
        <ReactMarkdown
          source={layoutContext?.openedTreatment.markdown.slice(
            parseInt(start),
            parseInt(end),
          )}
        />
      );
      const innerHtml = ReactDOMServer.renderToString(filteredContent);

      layoutContext?.scrollToElement(innerHtml);
    }
  };

  const handleRedirectLink = (id: string, start: string, end: string) => {
    return `/treatment/${id}?start=${start}&end=${end}`;
  };

  return (
    <>
      {settingsModal && props.setTreatmentContextFilteredIds && (
        <ContextSettingsModal
          open={settingsModal}
          toogleDialog={setSettingsModal}
          setTreatmentContextFilteredIds={props.setTreatmentContextFilteredIds}
        />
      )}
      <Grid container>
        <Grid item xs={12}>
          {!window.location.href.includes("chatbotsearch") && (isAdmin || isNavigator) && !isMobile && (
            <HeaderBoxStyled>
              <Box color={'white'} fontSize={'16px'} fontWeight={'bold'}>
                TGPS Assistant
              </Box>
              <Box display={'flex'}>
                <SettingsButtonStyled
                  onClick={e => {
                    setSettingsModal(true);
                  }}
                  aria-label="settings"
                >
                  <Settings />
                </SettingsButtonStyled>
                {props.surveyOpened && (
                  <SettingsButtonStyled
                    onClick={e => {
                      props.setSurveyInitiated(true);

                      if (props.handleSurveyOpen) props.handleSurveyOpen(false);
                    }}
                    aria-label="chatbot-button"
                  >
                    <Cancel />
                  </SettingsButtonStyled>
                )}
              </Box>
            </HeaderBoxStyled>
          )}
        </Grid>
        <Grid
          item
          xs={
            props.surveyOpened &&
            props.surveyInitiated == false &&
            props.selectedSurvey != null
              ? 6
              : 12
          }
        >
          {!pageLoading ? (
            <ChatSectionBoxStyled
              key={1}
              position={'relative'}
              padding={!isMobile ? '20px' : '0px'}
            >
              <Box
                marginTop={(isAdmin || isNavigator) && !isMobile && '50px'}
                style={{
                  overflowY: 'scroll',
                  height: isMobile ? '60vh' : '70vh',
                }}
                {...{ ref: props.chatBodyRef }}
              >
                {props.messages &&
                  props.messages.length > 0 &&
                  props.messages.map(
                    (message: any, index: React.Key | undefined) => (
                      <Box
                        key={index}
                        textAlign={'right'}
                        style={{
                          textAlign: message.type == 'user' ? 'right' : 'left',
                          marginRight: '2px',
                        }}
                      >
                        {message.surveys ? (
                          <Box key={index}>
                            <SurveyMessageBoxStyled key={index}>
                              <Typography variant="body2">
                                Your Health, Your Voice: Contribute to enhancing
                                our care by filling surveys:
                              </Typography>
                            </SurveyMessageBoxStyled>
                            {message.surveys.map(
                              (
                                item: {
                                  token: any;
                                  surveyId: any;
                                  survey: { survey: any };
                                  slug: any;
                                  name:
                                    | boolean
                                    | React.ReactChild
                                    | React.ReactFragment
                                    | React.ReactPortal
                                    | null
                                    | undefined;
                                },
                                ind: any,
                              ) => (
                                <Box key={ind} style={{ marginBottom: '5px' }}>
                                  {item.token ? (
                                    <Tooltip
                                      title="Assigned survey"
                                      placement="right"
                                    >
                                      <ColorButton
                                        variant="contained"
                                        className={classes['.blinking']}
                                        onClick={() => {
                                          surveysBinding(item);
                                        }}
                                      >
                                        {item.name}
                                      </ColorButton>
                                    </Tooltip>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      onClick={() => {
                                        surveysBinding(item);
                                      }}
                                    >
                                      {item.name}
                                    </Button>
                                  )}
                                </Box>
                              ),
                            )}
                          </Box>
                        ) : (
                          <MessageBoxStyled
                            key={index}
                            style={{
                              backgroundColor:
                                message.type == 'user'
                                  ? ' rgb(59 130 246 / 1)'
                                  : 'rgb(224 224 224 / 50%)',
                              color: message.type == 'user' ? 'white' : 'black',
                              marginLeft: message.type == 'user' ? 'auto' : '',
                              width: message.type == 'bot' ? '20rem' : 'auto',
                            }}
                          >
                            {message.isMarkdown ? (
                              <div>
                                <ReactMarkdownStyled children={message.text} />
                                {message.type == 'bot' && (
                                  <Feedback
                                    inputMessage={props.inputMessage}
                                    msg={message}
                                    messages={props.messages}
                                    setMessages={props.setMessages}
                                    setResponseAdded={props.setResponseAdded}
                                    highlightedIndex={highlightedIndex}
                                    setHighlightedIndex={setHighlightedIndex}
                                    textWords={textWords}
                                    setTextWords={setTextWords}
                                  />
                                )}
                                {message.type == 'bot' && message.feedback && (
                                  <CommentModal
                                    msg={message}
                                    messages={props.messages}
                                    setMessages={props.setMessages}
                                    setResponseAdded={props.setResponseAdded}
                                  />
                                )}
                                {message.type == 'bot' && message.settings && (
                                  <SettingsModal
                                    msg={message}
                                    messages={props.messages}
                                    setMessages={props.setMessages}
                                    setResponseAdded={props.setResponseAdded}
                                  />
                                )}
                              </div>
                            ) : (
                              <div>
                                <Typography variant="body2">
                                  {message.text}
                                </Typography>
                                {message.type == 'bot' && (
                                  <Feedback
                                    inputMessage={props.inputMessage}
                                    msg={message}
                                    messages={props.messages}
                                    setMessages={props.setMessages}
                                    setResponseAdded={props.setResponseAdded}
                                    highlightedIndex={highlightedIndex}
                                    setHighlightedIndex={setHighlightedIndex}
                                    textWords={textWords}
                                    setTextWords={setTextWords}
                                  />
                                )}
                                {message.type == 'bot' && message.feedback && (
                                  <CommentModal
                                    msg={message}
                                    messages={props.messages}
                                    setMessages={props.setMessages}
                                    setResponseAdded={props.setResponseAdded}
                                  />
                                )}
                                {message.type == 'bot' && message.settings && (
                                  <SettingsModal
                                    msg={message}
                                    messages={props.messages}
                                    setMessages={props.setMessages}
                                    setResponseAdded={props.setResponseAdded}
                                  />
                                )}
                              </div>
                            )}
                            {message.isForm && (
                              <Button
                                variant="contained"
                                className="mt-8 mb-8"
                                fullWidth
                                onClick={() => {
                                  handleDynamicSurveyClick(message);
                                }}
                              >
                                {message.formName}
                              </Button>
                            )}
                            {message.contexts && message.contexts.length > 0 && (
                              <Box mb={5}>
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontWeight: 500,
                                    marginBottom: '3px',
                                  }}
                                >
                                  References
                                </Typography>
                                {message.contexts
                                  .slice(0, 5)
                                  .map((context: any, index: number) => (
                                    <ArticleBoxStyled
                                      key={index}
                                      display={'flex'}
                                      alignItems={'center'}
                                      border={'1px solid lightgray'}
                                      mb={1}
                                    >
                                      <Box
                                        p={1}
                                        borderRight={'1px solid lightgray'}
                                        style={{
                                          backgroundColor: '#3374ba21',
                                        }}
                                      >
                                        <Typography variant="body2">
                                          {index + 1}.
                                        </Typography>
                                      </Box>
                                      {context?.slug === id ? (
                                        <ArticleNameStyled
                                          ml={1}
                                          onClick={() => {
                                            handleContextClick(
                                              context.start,
                                              context.end,
                                            );
                                          }}
                                        >
                                          {context?.title}
                                        </ArticleNameStyled>
                                      ) : (
                                        <ArticleNameStyled ml={1}>
                                          <LinkRouterStyled
                                            to={handleRedirectLink(
                                              context?.slug,
                                              context?.start,
                                              context?.end,
                                            )}
                                          >
                                            {context?.title}
                                          </LinkRouterStyled>
                                        </ArticleNameStyled>
                                      )}
                                    </ArticleBoxStyled>
                                  ))}
                              </Box>
                            )}
                            {message?.relatedArticles &&
                              message?.relatedArticles.length > 0 && (
                                <Box margin={'5px 0px'}>
                                  <Typography
                                    variant="body2"
                                    style={{ fontWeight: 500, margin: '3px' }}
                                  >
                                    Learn More
                                  </Typography>
                                  {message?.relatedArticles.map(
                                    (article: any, index: number) => (
                                      <ArticleBoxStyled
                                        key={index}
                                        display={'flex'}
                                        alignItems={'center'}
                                        border={'1px solid lightgray'}
                                        mb={1}
                                      >
                                        <Box
                                          p={1}
                                          borderRight={'1px solid lightgray'}
                                          style={{
                                            backgroundColor: '#3374ba21',
                                          }}
                                        >
                                          <Typography variant="body2">
                                            {index + 1}.
                                          </Typography>
                                        </Box>
                                        <ArticleNameStyled
                                          ml={1}
                                          onClick={handleScrollClick}
                                        >
                                          <LinkRouterStyled
                                            to={`/treatment/${article?.slug}`}
                                          >
                                            {article?.name}
                                          </LinkRouterStyled>
                                        </ArticleNameStyled>
                                      </ArticleBoxStyled>
                                    ),
                                  )}
                                </Box>
                              )}
                          </MessageBoxStyled>
                        )}
                      </Box>
                    ),
                  )}
                {(props.loading || loading) && <LoadingChatbot />}

                {/* {props.surveyInitiated == false &&
                  props.selectedSurvey != null &&
                  props.surveyOpened !== true && (
                    <FillSurvey
                      messages={props.messages}
                      setMessages={props.setMessages}
                      selectedSurvey={props.selectedSurvey}
                      setSurveyInitiated={props.setSurveyInitiated}
                      assignedSurvey={props.assignedSurvey}
                      setAssignedSurvey={props.setAssignedSurvey}
                      scrollToBottom={props.scrollToBottom}
                      dynamicSurvey={props.selectedSurvey.elements}
                    />
                  )} */}
              </Box>
              {
                <StepWrapper
                  isfocus={''}
                  disable={''}
                  style={{
                    paddingBottom: '0px',
                    borderRadius: '12px',
                    position: 'sticky',
                    bottom: 0,
                    background: 'white',
                  }}
                >
                  <TextareaAutosizeStyled
                    placeholder={
                      props.loading
                        ? ' Loading...'
                        : 'Ask me a follow up question'
                    }
                    maxLength={500}
                    onKeyPress={handleKeyPress}
                    value={props.loading ? '' : props.inputMessage}
                    rowsMin={3}
                    onChange={e => {
                      if (
                        props.inputMessage.length + e.target.value.length >
                        500
                      ) {
                        enqueueSnackbar(
                          'Message must be less than 500 characters',
                          {
                            variant: 'error',
                          },
                        );
                        return;
                      }
                      props.setInputMessage(e.target.value.slice(0, 500));
                    }}
                  />
                  <Box display="flex" alignItems="center" justifyContent="end">
                    <Typography color="textSecondary" variant="subtitle2">
                      {`${
                        props.inputMessage.length < 10
                          ? `${props.inputMessage.length}`
                          : `${props.inputMessage.length}`
                      }/500`}
                    </Typography>
                    <IconButton
                      aria-label="input"
                      onClick={() => props.handleUserInput()}
                    >
                      <SendIcon
                        color={
                          props.inputMessage.length <= 0 || props.loading
                            ? 'disabled'
                            : 'primary'
                        }
                      />
                    </IconButton>
                  </Box>
                </StepWrapper>
              }
            </ChatSectionBoxStyled>
          ) : (
            <Box margin={1}>
              <LoadingChatbot />
            </Box>
          )}
        </Grid>
        {props.surveyOpened && props.handleSurveyOpen && !isMobile && (
          <Grid
            xs={6}
            style={{ height: '90vh', overflowY: 'scroll', marginTop: '75px' }}
          >
            <ChatbotSurvey
              surveyData={props.selectedSurvey.elements}
              handleSurveyOpen={props.handleSurveyOpen}
              setSurveyInitiated={props.setSurveyInitiated}
            />
          </Grid>
        )}
        {props.surveyOpened && props.handleSurveyOpen && isMobile && (
          <ChatbotSurvey
            surveyData={props.selectedSurvey.elements}
            handleSurveyOpen={props.handleSurveyOpen}
            setSurveyInitiated={props.setSurveyInitiated}
          />
        )}
      </Grid>
    </>
  );
};
