import { Box, IconButton, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { Settings } from '@material-ui/icons';
import PauseIcon from '@material-ui/icons/Stop';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { TreatmentDetailFragment } from 'types.d';
import SettingsModal from 'modules/treatments/components/SettingsModal';
import { DEFAULT_VOICE_SETTINGS } from 'CONST';
import { chunkText, getLocalStorage } from 'share/utils';

type Props = {
    data: TreatmentDetailFragment;
    setHighlightedIndex: Function;
    textWords: any;
    setTextWords: Function;
};

export const Speak: React.FC<Props> = (props: Props) => {
    var default_voice_settings = JSON.parse(
        getLocalStorage(DEFAULT_VOICE_SETTINGS)!,
    );

    const [disabled, setDisabled] = useState(false);

    const [played, setPlayed] = useState<any>(undefined);

    const [open, setOpen] = useState(false);

    const [voiceSettings, setVoiceSettings] = useState({
        rate: default_voice_settings.rate,
        voice: default_voice_settings.voice,
        pitch: default_voice_settings.pitch,
    });

    function handleSpeak(pause?: Boolean) {
        // Using SpeechSynthesis API to speak the message
        const voices = window.speechSynthesis.getVoices();

        var default_voice_settings = JSON.parse(
            getLocalStorage(DEFAULT_VOICE_SETTINGS)!,
        );
        if (default_voice_settings.rate != voiceSettings.rate) {
            voiceSettings.rate = default_voice_settings.rate;
        }
        if (default_voice_settings.pitch != voiceSettings.pitch) {
            voiceSettings.pitch = default_voice_settings.pitch;
        }
        if (default_voice_settings.voice != voiceSettings.voice) {
            voiceSettings.voice = default_voice_settings.voice;
        }
        setVoiceSettings(voiceSettings);
        if (props.data?.description && props.data?.content) {
            var text = chunkText(
                props.data.description.replace(/[^\w\s]|#/g, '') + props.data?.content.replace(
                    /[^\w\s]|#/g,
                    '',
                ),
                25,
            );
            speak(voices, text, 0, pause);
        }
    }

    function speak(voices: any, text: any, ind: any, pause?: Boolean) {
        if (pause) {
            setDisabled(false);
            setPlayed(false);
            window.speechSynthesis.pause();
        } else if (pause == false && pause != undefined) {
            //  disableSettings()
            window.speechSynthesis.resume();
            setPlayed(true);
            disableSettings();
        } else {
            window.speechSynthesis.cancel();
            if (ind >= text.length) {
                return;
            }
            const utterance = new SpeechSynthesisUtterance(text[ind]);
            utterance.voice = voices[voiceSettings.voice];
            utterance.rate = voiceSettings.rate;
            utterance.pitch = voiceSettings.pitch;

            utterance.onstart = () => {
                props.setHighlightedIndex(true);
                setPlayed(true);
                disableSettings();
            };
            voices.forEach((voc: any, index: any) => {
                if (voiceSettings.voice == index) {
                    utterance.lang = voices[voiceSettings.voice].lang;
                    //utterance.volume = voiceSettings.vol
                }
            });
            window.speechSynthesis.speak(utterance);
            //var length = props.data.description?.length ? (props.data.description?.length) : 0;
            // utterance.onboundary = (event: any) => {
            //     console.log("as")
            //     const textContent = event.utterance.text.substring(0, event.charIndex);
            //     // words = textContent.split(' ');
            //     // props.setHighlightedIndex(utterance.text.indexOf(textContent)); // Update the index for highlighting
            //     // lastCharIndex = event.charIndex
            //     // props.setTextWords(textContent)
            //     if (textContent.length >= length) {
            //         props.setHighlightedIndex(false)
            //     }
            // };
            utterance.onend = () => {
                setPlayed(undefined);
                setDisabled(false);
                props.setHighlightedIndex(undefined);
                speak(voices, text, ind + 1, pause);
            };
        }
    }

    function openSettingsModal(open?: boolean): void {
        var default_voice_settings = JSON.parse(
            getLocalStorage(DEFAULT_VOICE_SETTINGS)!,
        );
        if (open) {
            if (voiceSettings.rate != default_voice_settings.rate) {
                voiceSettings.rate = default_voice_settings.rate;
            }
            if (voiceSettings.pitch != default_voice_settings.pitch) {
                voiceSettings.pitch = default_voice_settings.pitch;
            }
            if (voiceSettings.voice != default_voice_settings.voice) {
                voiceSettings.voice = default_voice_settings.voice;
            }
            setVoiceSettings(voiceSettings);
            setOpen(true);
        }
    }

    function disableSettings() {
        setDisabled(true);
        openSettingsModal();
    }

    return (
        <>
            <Box display="flex" flexDirection="row">
                <Tooltip
                    title={played == undefined ? `Listen` : played ? `Pause` : `Resume`}
                >
                    <IconButton
                        onClick={() => {
                            if (played == undefined) {
                                handleSpeak();
                            } else if (played) {
                                handleSpeak(true);
                            } else {
                                handleSpeak(false);
                            }
                        }}
                        color="primary"
                    >
                        {!played ? (
                            <VolumeUpIcon fontSize="inherit" />
                        ) : (
                            <PauseIcon fontSize="inherit" />
                        )}
                    </IconButton>
                </Tooltip>
                <Tooltip title={`Settings`}>
                    <IconButton
                        disabled={disabled}
                        onClick={() => openSettingsModal(true)}
                        color="secondary"
                    >
                        <Settings fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            </Box>
            {open && (
                <SettingsModal
                    voiceSettings={voiceSettings}
                    setVoiceSettings={setVoiceSettings}
                    open={open}
                    setOpen={setOpen}
                    setPlayed={setPlayed}
                />
            )}
        </>
    );
};
export default React.memo(Speak);
