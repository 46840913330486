import React from 'react';
import {
  DefaultTable,
  ButtonDelete,
  AutoCompleteSelect,
  CheckboxList,
} from 'components';
import { PatientNoteTypeSettingFragment } from 'types.d';
import { Typography, TextField } from '@material-ui/core';
import { sortObject } from 'share/utils';
type NoteTypeTableProps = {};

type ExtendNoteTypeTableProps = NoteTypeTableProps & {
  listNotes: PatientNoteTypeSettingFragment[];
  handleChangeDes: (id: string, value: string) => void;
  onDelete: (id: string) => void;
  setListNotes: (list: PatientNoteTypeSettingFragment[]) => void;
};

type ExtendNoteTypeAutocompleteProps = ExtendNoteTypeTableProps & {
  noteTypeOptions: PatientNoteTypeSettingFragment[];
};

export const useNoteTypes = (
  listNotes: PatientNoteTypeSettingFragment[],
  setListNotes: (list: PatientNoteTypeSettingFragment[]) => void,
) => {
  const handleChangeDes = (id: string, value: string) => {
    const updateRows = [...listNotes];
    const index = updateRows.findIndex(row => row._id === id);
    updateRows[index].description = value;
    setListNotes(updateRows);
  };

  const onDelete = (id: string) => {
    setListNotes(listNotes?.filter(item => item?._id !== id));
  };

  const noteTypeTableProps = {
    listNotes,
    handleChangeDes,
    onDelete,
    setListNotes,
  };
  return {
    noteTypeTableProps,
    NoteTypeTable,
    handleChangeDes,
    NoteTypeAutocomplete,
  };
};

export const NoteTypeTable: React.FC<ExtendNoteTypeTableProps> = ({
  listNotes,
  handleChangeDes,
  onDelete,
}) => {
  return (
    <>
      <DefaultTable
        isOnComponent
        minWidth="100%"
        isWrapped
        heads={[
          { text: 'Name', width: '150px' },
          { text: 'Description' },
          { text: 'Action' },
        ]}
        loading={false}
        content={
          listNotes.map(row => {
            return {
              key: row._id,
              cells: [
                <Typography variant="subtitle2">{row?.name}</Typography>,
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="dense"
                  label="Description"
                  multiline
                  variant="outlined"
                  value={row?.description || ''}
                  onChange={e => handleChangeDes(row?._id, e.target.value)}
                  style={{ width: '70%' }}
                />,
                <ButtonDelete
                  loadingDelete={false}
                  deleteItem={onDelete}
                  id={row._id}
                  title={'Are you sure you want to delete this item?'}
                  tooltipTitle="Delete"
                />,
              ],
            };
          }) || []
        }
      />
    </>
  );
};

export const NoteTypeAutocomplete: React.FC<ExtendNoteTypeAutocompleteProps> = ({
  listNotes,
  setListNotes,
  noteTypeOptions,
}) => {
  return (
    <AutoCompleteSelect
      small
      multiple
      disablePortal
      name="noteTypes"
      label="Note Types"
      nameOption="name"
      defaultValue={listNotes?.map(item => item?._id)}
      callbackChangeValue={(_, newValue) => setListNotes(newValue)}
      options={sortObject(noteTypeOptions) || []}
      callbackRenderOption={(option, selected) => (
        <CheckboxList
          checked={selected}
          option={<Typography variant="subtitle2">{option?.name}</Typography>}
        />
      )}
    />
  );
};
