import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  UPDATE_ME_CLIENT,
  ME_CLIENT,
  TOOGLE_BANNER_STATUS,
  TOOGLE_SHOW_SUB_SIDE_BAR,
} from 'gql';
import {
  ResponseLoginFragment,
  AuthenticationSource,
  ResponseRemoteLoginFragment,
  ToogleShowSubTreatmentsSideBarMutation,
  ToogleShowSubTreatmentsSideBarMutationVariables,
} from 'types.d';

export const useUpdateMeClient = () => {
  const [updateMeClient, { loading: loadingUpdateMeClient }] = useMutation(
    UPDATE_ME_CLIENT,
  );

  const [toogleBannerStatus] = useMutation(TOOGLE_BANNER_STATUS);

  const [toogleShowSubSideBar] = useMutation<
    ToogleShowSubTreatmentsSideBarMutation,
    ToogleShowSubTreatmentsSideBarMutationVariables
  >(TOOGLE_SHOW_SUB_SIDE_BAR);

  const { data: dataMeClient } = useQuery(ME_CLIENT);

  const returnOrgInfo = (
    value: ResponseLoginFragment | ResponseRemoteLoginFragment | null,
  ) => {
    return (
      (value as ResponseLoginFragment)?.patient?.organization ||
      (value as ResponseRemoteLoginFragment)?.organization
    );
  };

  const handleUpdateMeClient = (
    dataUpdate?: ResponseLoginFragment | ResponseRemoteLoginFragment | null,
  ) => {
    const variables = dataUpdate
      ? {
          ...dataUpdate,
          isRegister: Boolean(
            dataUpdate?.authenticationInfo?.find(
              item =>
                item.authenticationSource === AuthenticationSource.Register,
            ),
          ),
          timezone:
            (dataUpdate as ResponseLoginFragment)?.organization?.timezone ||
            dataUpdate.organization?.timezone ||
            null,
          organization:
            dataUpdate?.organization?.name ||
            returnOrgInfo(dataUpdate)?.name ||
            '',
          patient:
            (dataUpdate as ResponseLoginFragment)?.patient?.idPatient || '',
          hasDefaultTreatment: !!dataUpdate.organization?.defaultTreatment
            ?.idTreatment,
          secondaryEmails:
            dataUpdate?.secondaryEmail?.map(item => {
              return {
                ...item,
              };
            }) || [],
          organizationId:
            returnOrgInfo(dataUpdate)?.idOrg ||
            dataUpdate.organization?.idOrg ||
            '',
          setting: dataUpdate.setting || null,
        }
      : {
          email: '',
          firstName: '',
          middleName: '',
          lastName: '',
          role: '',
          _id: '',
          organization: '',
          isRegister: false,
          patient: '',
          hasDefaultTreatment: false,
          organizationId: '',
          secondaryEmails: [],
          setting: null,
          timezone: null,
        };

    updateMeClient({
      variables,
    });
  };
  const updateFieldMeClient = (field: string, value: any) => {
    const variables = {
      ...dataMeClient.meClient,
      [field]: value,
    };

    updateMeClient({
      variables,
    });
  };

  const functionAfterLogin = (
    dataUpdate?: ResponseLoginFragment | ResponseRemoteLoginFragment | null,
  ) => {
    handleUpdateMeClient(dataUpdate);
    toogleBannerStatus({
      variables: { status: false },
    });
    toogleShowSubSideBar({ variables: { input: { status: true } } });
  };
  return {
    meClient: dataMeClient.meClient,
    loadingUpdateMeClient,
    handleUpdateMeClient,
    updateFieldMeClient,
    functionAfterLogin,
  };
};
