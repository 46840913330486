import { useRef, useContext } from 'react';
import { HEIGHT_A4, WIDTH_A4 } from 'CONST';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { font } from 'font';
import autoTable from 'jspdf-autotable';
import { TableChart } from 'modules/dashboard/interfaces';
import { formatDate } from 'share/utils';
import {
  DataAggregateInterface,
  DashboardShowModeContext,
} from 'share/context';

const tMargin = 10;
const lMargin = 5;
const rMargin = 5;

export const useCapturePdfAnalytics = () => {
  const dashboardShowModeContext = useContext(DashboardShowModeContext);
  const elementByPage = useRef<{ element: Element; height: number }[][]>([[]]);
  const allPromiseCanvas = useRef<Promise<HTMLCanvasElement>[]>([]);
  const heightPageReal = useRef(0);
  const heightPageRealA4 = useRef(0);
  const doc = new jsPDF('p', 'mm', 'a4');
  doc.addFileToVFS('WorkSans-normal.ttf', font);
  doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal');
  doc.setFont('WorkSans');
  let height = tMargin;

  const getElement = (element: HTMLElement) => {
    //get height of a4 by px
    heightPageReal.current = Number(
      (
        ((element?.offsetWidth as number) * (HEIGHT_A4 - height)) /
        (WIDTH_A4 - lMargin - rMargin)
      ).toFixed(2),
    );

    heightPageRealA4.current = Number(
      (((element?.offsetWidth as number) * HEIGHT_A4) / WIDTH_A4).toFixed(2),
    );
    for (let i = 0; i < element.childNodes.length; i += 2) {
      const height =
        elementByPage.current[elementByPage.current.length - 1].length === 0
          ? Number(
              element.children[i].getBoundingClientRect().height.toFixed(2),
            )
          : Number(
              (
                elementByPage.current[elementByPage.current.length - 1][
                  elementByPage.current[elementByPage.current.length - 1]
                    .length - 1
                ].height +
                Number(
                  element.children[i].getBoundingClientRect().height.toFixed(2),
                )
              ).toFixed(2),
            );
      if (height <= heightPageReal.current) {
        elementByPage.current[elementByPage.current.length - 1].push({
          element: element.children[i],
          height,
        });
        if (element.children[i + 1]) {
          elementByPage.current[elementByPage.current.length - 1].push({
            element: element.children[i + 1],
            height,
          });
        }
      } else {
        heightPageReal.current =
          elementByPage.current[elementByPage.current.length - 1][
            elementByPage.current[elementByPage.current.length - 1].length - 1
          ].height + heightPageRealA4.current;
        const currentElement = [
          {
            element: element.children[i],
            height,
          },
        ];
        if (element.children[i + 1]) {
          currentElement.push({
            element: element.children[i + 1],
            height,
          });
        }
        elementByPage.current.push(currentElement);
      }
    }
  };

  const isAddingPageTable = () => {
    if (height + 40 > HEIGHT_A4 - tMargin) {
      return true;
    }
    return false;
  };

  const handleCheckAddPage = () => {
    if (isAddingPageTable()) {
      doc.addPage();
      height = tMargin;
    }
  };

  const drawTable = (params: {
    label: string;
    body: DataAggregateInterface[];
  }) => {
    handleCheckAddPage();
    const { label, body } = params;
    doc.setFont('WorkSans', 'bold');
    doc.setFontSize(12);
    doc.setTextColor(89, 89, 89);
    const patientInfomationText = doc.splitTextToSize(
      label,
      WIDTH_A4 - lMargin - rMargin,
    );
    doc.text(patientInfomationText, lMargin, height);
    height += doc.getTextDimensions(patientInfomationText).h;
    autoTable(doc, {
      styles: {
        fontSize: 10,
        font: 'WorkSans',
      },
      margin: { left: lMargin, top: tMargin },
      startY: height,
      tableWidth: WIDTH_A4 - lMargin - rMargin,
      head: [['User', 'Answer', 'Date']],
      body: body.map(item => {
        return [
          dashboardShowModeContext?.isHidingNameUser ? '******' : item.name,
          item.value,
          formatDate(item.time, true),
        ];
      }),
      didParseCell: function(table) {
        if (table.section === 'head') {
          table.cell.styles.fillColor = '#868688';
          table.cell.styles.textColor = '#252727';
        }
      },
    });
  };
  const drawLabel = (text: string) => {
    doc.setFont('WorkSans', 'bold');
    doc.setFontSize(15);
    doc.setTextColor(89, 89, 89);
    const patientInfomationText = doc.splitTextToSize(
      text,
      WIDTH_A4 - lMargin - rMargin,
    );
    doc.text(patientInfomationText, lMargin, height);
    height += doc.getTextDimensions(patientInfomationText).h + 2;
  };
  const drawSurvey = async ({
    elem,
    tableChart,
    isDrawChart,
    nameDashboard,
  }: {
    elem: HTMLElement | null;
    tableChart: TableChart[];
    isDrawChart?: boolean;
    nameDashboard: string;
  }) => {
    if (elem) {
      drawLabel(nameDashboard);
      if (isDrawChart) {
        window.scrollTo(0, 0);
        //remove select box type
        const inputChangeTypes = Array.from(
          (elem as HTMLElement).querySelectorAll('.ignore-export'),
        );
        inputChangeTypes.forEach(elem => {
          elem.remove();
        });

        //break page a4
        getElement(elem as HTMLElement);
        const elementGenerate = document.getElementById('generate-analystic');
        if (elementGenerate) {
          elementByPage.current.forEach(item => {
            item.forEach(question => {
              elementGenerate.appendChild(question.element);
            });
            const promiseCanvas = html2canvas(elementGenerate, {
              useCORS: true,
              allowTaint: true,
            });
            allPromiseCanvas.current.push(promiseCanvas);
            elementGenerate.innerHTML = '';
          });
          await Promise.all(allPromiseCanvas.current).then(items => {
            items.forEach((item, index) => {
              if (index === 0) {
                doc.addImage(
                  item,
                  'JPEG',
                  lMargin,
                  height,
                  WIDTH_A4 - lMargin - rMargin,
                  (item.height * (WIDTH_A4 - lMargin - rMargin)) / item.width,
                );
              } else {
                height = tMargin;
                doc.addPage();
                doc.addImage(
                  item,
                  'JPEG',
                  lMargin,
                  tMargin,
                  WIDTH_A4 - lMargin - rMargin,
                  (item.height * (WIDTH_A4 - lMargin - rMargin)) / item.width,
                );
              }
              if (index === items.length - 1) {
                height +=
                  (item.height * (WIDTH_A4 - lMargin - rMargin)) / item.width;
              }
            });
          });
          //add space between chart and table
          height += 10;
        }
      }
      tableChart.forEach(item => {
        drawTable({ label: item.name, body: item.dataAggreate });
        height = (doc as any).lastAutoTable.finalY || height;
        //add space between each charts
        height += 10;
      });
      elementByPage.current = [[]];
      allPromiseCanvas.current = [];
    }
  };

  const createPdf = async (
    tableChart: TableChart[],
    isDrawChart: boolean,
    nameDashboard: string,
  ) => {
    await drawSurvey({
      elem: document.getElementById('analytics-dashboard'),
      tableChart,
      isDrawChart,
      nameDashboard,
    });
    return doc;
  };
  return {
    createPdf,
  };
};
