import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useMediaQuery, Fab, Drawer } from '@material-ui/core';
import { styled, useTheme, Theme } from '@material-ui/core/styles';
import { Cancel, Chat } from '@material-ui/icons';
import {
  CONTEXT_TREATMENTS,
  DEFAULT_VOICE_SETTINGS,
  KEY_LOCAL_ASSIGNED_SURVEYS,
  KEY_LOCAL_ASSIGN_TREATMENT,
  KEY_LOCAL_NO_LOG_KEY,
  KEY_SESSION_ORGANIZATION_ID,
  SHOW_FORMS,
} from 'CONST';
import { BANNER_STATUS, MENU_STATUS, TOOGLE_MENU_STATUS } from 'gql';
import {
  ChatBot_History,
  ChatBot_LogKey,
  ChatBot_Query,
} from 'gql/server/chatbot';
import {
  useCheckLogin,
  useCustomPermission,
  useEffectOnlyOnce,
  useHandlePathExists,
} from 'hooks';
import { Chatbot } from 'modules/chatbot/components/Chatbot';
import MobileChatBot from 'modules/chatbot/components/MobileChatbot';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ChatbotDrawerContext } from 'share/context';
import {
  getLocalStorage,
  getSessionStorage,
  processContexts,
  setDataLocalStorage,
  setDataSessionStorage,
} from 'share/utils';
import {
  ChatBotHistoryKeyQuery,
  ChatBotHistoryQuery,
  ChatBotQuery,
  QueryChatBotArgs,
  QueryChatBotHistoryArgs,
} from 'types.d';

export const DrawerStyled = styled(Drawer)(
  ({
    banner,
    surveyOpened,
    theme,
  }: {
    banner: boolean | undefined;
    surveyOpened: boolean;
    theme: Theme;
  }) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: surveyOpened ? '800px' : '400px',
    paddingTop: banner ? '65px' : '65px',
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: surveyOpened ? '800px' : '400px',
      boxSizing: 'border-box',
      paddingTop: banner ? '65px' : '65px',
    },
  }),
);

const FloatingChatStyled = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(8),
  right: theme.spacing(2),
  zIndex: 11112,
}));

export type ContextData = {
  name: string;
  title: string;
  content: string;
  relevancyScore: string;
  treatmentId: string;
};

type Props = {
  chatbotDrawer: boolean;
  setChatbotDrawer: any;
};

export const ChatbotDrawer: React.FC<Props> = ({
  chatbotDrawer,
  setChatbotDrawer,
}) => {
  const theme = useTheme();

  const isLogin = useCheckLogin();

  const location = useLocation();

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { isUser, isPatient } = useCustomPermission();

  const [toogleMenuStatus] = useMutation(TOOGLE_MENU_STATUS);

  const { data: dataBannerStatus } = useQuery(BANNER_STATUS);

  const { data: dataMenuStatus } = useQuery(MENU_STATUS);

  const queryParams: any = new URLSearchParams(location.search);

  const chatBodyRef = useRef<HTMLDivElement>(null);

  const [message, setMessage] = useState('');

  const [loading, setLoading] = useState(false);

  const [surveyOpened, setSurveyOpened] = useState(false);

  const treatmentAssigneeEmail =
    JSON.parse(getLocalStorage(KEY_LOCAL_ASSIGN_TREATMENT)!) != null &&
    JSON.parse(getLocalStorage(KEY_LOCAL_ASSIGN_TREATMENT)!).length > 0
      ? JSON.parse(getLocalStorage(KEY_LOCAL_ASSIGN_TREATMENT)!).find(
          (treatment: { email: any }) => treatment.email,
        )?.email
      : null;

  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [messages, setMessages] = useState<any[]>([]);
  const [assignedSurvey, setAssignedSurvey] = useState<any>(false);
  const [surveyInitiated, setSurveyInitiated] = useState(false);
  const [selectedSlug, setSelectedSlug] = useState(null);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [inputMessage, setInputMessage] = useState('');
  const [queryMessage, setQueryMessage] = useState('');
  const [displayChatbot, setDisplayChatBot] = useState(false);
  const [logKey, setLogKey] = useState(
    treatmentAssigneeEmail != null && treatmentAssigneeEmail != 'null'
      ? treatmentAssigneeEmail
      : getLocalStorage(KEY_LOCAL_NO_LOG_KEY)!,
  );
  const [historyKey, setHistoryKey] = useState<any>(null);
  const [responseAdded, setResponseAdded] = useState(false);

  const [
    treatmentContextFilteredIds,
    setTreatmentContextFilteredIds,
  ] = useState<string[]>(() => {
    const storedValue = getLocalStorage(CONTEXT_TREATMENTS);
    return storedValue ? storedValue.split(',') : [];
  });

  let params: QueryChatBotHistoryArgs['params'] = {
    nologkey: logKey,
  };

  var voice_settings = JSON.parse(getLocalStorage(DEFAULT_VOICE_SETTINGS)!);

  const pathExists = useHandlePathExists();

  // function to get log key
  const [getlogkey] = useLazyQuery<ChatBotHistoryKeyQuery>(ChatBot_LogKey, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data: any) {
      setHistoryKey(data.getHistoryKey);
    },
  });

  const { loading: loadingd, data: historydata } = useQuery<
    ChatBotHistoryQuery,
    QueryChatBotHistoryArgs
  >(ChatBot_History, {
    //skip: !params,
    variables: {
      params,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (data != null && data.history != null) {
        const tempMessages: React.SetStateAction<any[]> = [];
        var temporarySurveys: any[] = [];

        data.history.forEach((msg: any) => {
          if (msg.role == 'user') {
            tempMessages.push({ text: msg.content, type: 'user' });
          } else {
            tempMessages.push({
              text: msg.content,
              responseId: msg.responseId,
              type: 'bot',
              like: msg.like,
              comment: msg.comment,
              history: true,
              isMarkdown: true,
              pitch: voice_settings.pitch,
              rate: voice_settings.rate,
              //vol: 0.8,
              voice: voice_settings.voice,
              settings: false,
              played: undefined,
              relatedArticles:
                msg.relatedArticles?.length > 0 ? msg.relatedArticles : [],
            });
          }
        });
        if (messages.length == 0 && inputMessage) {
          tempMessages.push({ text: inputMessage, type: 'user' });
        }
        if (data.history?.length > 0) {
          data.history[data.history?.length - 1].relatedArticles.forEach(
            (rt: { survey: any[] | null }) => {
              if (
                (!isLogin && rt?.survey != null && rt?.survey.length > 0) ||
                (rt?.survey != null &&
                  rt?.survey.length > 0 &&
                  isLogin &&
                  (isPatient || isUser))
              ) {
                rt.survey.forEach(survey => {
                  temporarySurveys.push({
                    surveyId: survey._id,
                    name: survey.name,
                    slug: survey.slug,
                    survey: survey.surveyData,
                  });
                });
              }
            },
          );
        }
        temporarySurveys = getAssignedSurveys(temporarySurveys);
        if (temporarySurveys.length > 0) {
          tempMessages.push({
            text: '',
            type: 'bot',
            isMarkdown: true,
            surveys: temporarySurveys,
          });
        }

        if (data && data?.history && data?.history.length === 0) {
          tempMessages.push({
            text:
              "Welcome to TGPS Assistant, your go-to guide for instant answers! 🌟 I'm here to help. Dive in and ask away",
            type: 'user',
          });
        }
        setResponseAdded(true);
        setMessages(tempMessages);
        setLoading(false);
      } else {
        setLoading(false);
      }
    },
  });

  const [
    chatbot,
    { loading: loadingData, data: dataPublicTreatment },
  ] = useLazyQuery<ChatBotQuery, QueryChatBotArgs>(ChatBot_Query, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setInputMessage('');
      const results = data.chatbot?.answer;

      var temporarySurveys: any[] = [];

      if (messages[messages.length - 1].text === '/reset') {
        setMessages([
          {
            text:
              "Welcome to TGPS Assistant, your go-to guide for instant answers! 🌟 I'm here to help. Dive in and ask away",
            type: 'user',
          },
        ]);

        enqueueSnackbar('You search history has been cleared successfully.', {
          variant: 'info',
        });
      } else {
        if (data.chatbot?.relatedtreatments.length > 0) {
          setSearchResults(data.chatbot?.relatedtreatments);
          data.chatbot.relatedtreatments.forEach(rt => {
            if (rt.survey != null && isLogin && (isPatient || isUser)) {
              rt.survey.forEach((survey: any) => {
                temporarySurveys.push({
                  surveyId: survey._id,
                  name: survey.name,
                  slug: survey.slug,
                  survey: survey.surveyData,
                });
              });
            }
          });
        }

        temporarySurveys = getAssignedSurveys(temporarySurveys);

        if (results) {
          if (messages.length == 1) {
            if (!isLogin && inputMessage.trim() === '') {
              messages.push({ text: queryMessage, type: 'user' });
            } else {
              messages.push({ text: inputMessage, type: 'user' });
            }
          }

          if (temporarySurveys.length > 0) {
            setMessages([
              ...messages,
              {
                text: results,
                type: 'bot',
                responseId: data.chatbot?.responseId,
                feedback: false,
                settings: false,
                isMarkdown: true,
                pitch: voice_settings.pitch,
                rate: voice_settings.rate,
                voice: voice_settings.voice,
                played: undefined,
                relatedArticles: data.chatbot?.relatedtreatments,
                contexts: data.chatbot.contexts
                  ? processContexts(data.chatbot.contexts)
                  : [],
                isForm: data.chatbot?.isForm,
                formName: data.chatbot.formName,
                logKey,
                historyKey,
              },
              { text: '', type: 'bot', surveys: temporarySurveys },
            ]);
          } else {
            setMessages([
              ...messages,
              {
                text: results,
                type: 'bot',
                responseId: data.chatbot?.responseId,
                isMarkdown: true,
                feedback: false,
                settings: false,
                pitch: voice_settings.pitch,
                rate: voice_settings.rate,
                voice: voice_settings.voice,
                played: undefined,
                relatedArticles: data.chatbot?.relatedtreatments,
                contexts: data.chatbot.contexts
                  ? processContexts(data.chatbot.contexts)
                  : [],
                isForm: data.chatbot?.isForm,
                formName: data.chatbot.formName,
                logKey,
                historyKey,
              },
            ]);
          }
          setResponseAdded(true);
        }
      }

      if (queryParams.has('search')) {
        queryParams.delete('search');
        history.replace({
          search: queryParams.toString(),
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    },
    onError(errors) {
      setLoading(false);
      setInputMessage('');
    },
  });

  useEffectOnlyOnce(() => {
    getlogkey();
  });

  function getAssignedSurveys(temporarySurveys: any) {
    if (isLogin) {
      var assignedSurveys = JSON.parse(
        getLocalStorage(KEY_LOCAL_ASSIGNED_SURVEYS)!,
      );
    } else {
      var assignedSurveys = JSON.parse(
        getSessionStorage(KEY_LOCAL_ASSIGNED_SURVEYS)!,
      );
    }
    if (assignedSurveys != null && assignedSurveys.length > 0) {
      assignedSurveys.filter((survey: any) => {
        if (new Date() < new Date(survey.expiredDate))
          temporarySurveys.push(survey);
      });
      setAssignedSurvey(true);
      if (isLogin) {
        setDataLocalStorage(
          KEY_LOCAL_ASSIGNED_SURVEYS,
          JSON.stringify(assignedSurveys),
        );
      } else {
        setDataSessionStorage(
          KEY_LOCAL_ASSIGNED_SURVEYS,
          JSON.stringify(assignedSurveys),
        );
      }
    }
    return temporarySurveys;
  }

  useEffect(() => {
    if (message) handleUserInput(message, true);
  }, [location, message]);

  useEffect(() => {}, [messages]);

  useEffect(() => {
    if (chatBodyRef && chatBodyRef.current && responseAdded) scrollToBottom();
  }, [messages]);

  useEffectOnlyOnce(() => {
    if (chatBodyRef && chatBodyRef.current) scrollToBottom();
  });

  const scrollToBottom = () => {
    if (isMobile) {
      // chatBodyRef!.current!.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'end',
      //   inline: 'nearest',
      // });
      chatBodyRef!.current!.scrollTop = chatBodyRef!.current!.scrollHeight;
    } else {
      chatBodyRef!.current!.scrollTop = chatBodyRef!.current!.scrollHeight;
    }
  };

  useEffect(() => {
    if (historyKey && message) handleUserInput();
  }, [historyKey]);

  // Function to handle user input
  const handleUserInput = (message?: any, flg?: boolean) => {
    if (message != '' && flg != undefined) {
      setLoading(true);
      setInputMessage(message);
    } else {
      if (
        inputMessage.trim() === '' ||
        (message == undefined && message == '')
      ) {
        setLoading(false);
        return;
      } else if (!isLogin) {
        setQueryMessage(inputMessage);
      }
    }
    setLoading(true);
    // Add the user's message to the chat
    setMessages([
      ...messages,
      { text: flg != undefined ? message : inputMessage, type: 'user' },
    ]);
    var treatmentsAssigned = JSON.parse(
      getLocalStorage(KEY_LOCAL_ASSIGN_TREATMENT)! as string,
    );
    var organization = getSessionStorage(KEY_SESSION_ORGANIZATION_ID)!;

    let params: QueryChatBotArgs['params'] = {
      query:
        flg != undefined
          ? message.replace(/\n/g, '')
          : inputMessage.replace(/\n/g, ''),
    };

    if (treatmentsAssigned != null && treatmentsAssigned.length > 0) {
      params['treatmentAssignmentInput'] = treatmentsAssigned;
      params['nologkey'] = treatmentAssigneeEmail
        ? treatmentAssigneeEmail
        : logKey != null && logKey != 'null'
        ? logKey
        : historyKey;
      if (!treatmentAssigneeEmail && !logKey) {
        setLogKey(historyKey);
        setDataLocalStorage(
          KEY_LOCAL_NO_LOG_KEY,
          params.nologkey ? params.nologkey : historyKey,
        );
      }
    } else if (logKey != null && logKey != 'null') {
      params['nologkey'] = logKey;
    } else if (!isLogin && (logKey == null || logKey == 'null')) {
      params['nologkey'] = historyKey;
      setLogKey(historyKey);
      setDataLocalStorage(KEY_LOCAL_NO_LOG_KEY, historyKey);
    }
    if (organization != null || organization != 'null' || organization != '') {
      params['organization'] = organization;
    }

    if (treatmentContextFilteredIds.length > 0) {
      params['contextTreatments'] = treatmentContextFilteredIds;
      params['contextRequired'] = true;
    }

    const isForm = Boolean(JSON.parse(getLocalStorage(SHOW_FORMS)! as string));

    params['formRequired'] = isForm;
    params['isOut'] = false;

    if (!isLogin && params.nologkey) {
      chatbot({
        variables: {
          params,
        },
      });
    } else if (isLogin) {
      chatbot({
        variables: {
          params,
        },
      });
    } else {
      return;
    }
  };

  const initiateSurvey = () => {
    if (inputMessage != '')
      setMessages([...messages, { text: inputMessage, type: 'user' }]);

    if (surveyInitiated == null) setSurveyInitiated(false);
  };

  useEffect(() => {
    if (chatbotDrawer && isMobile) {
      if (!displayChatbot) setChatbotDrawer(false);
      else setChatbotDrawer(true);
    }
  }, [isMobile, displayChatbot]);

  const isTreatmentOrHomepage =
    window.location.pathname.includes('/treatment/') ||
    window.location.pathname.includes('/home/') ||
    window.location.pathname.includes('/section/') ||
    !pathExists;

  const handleSurveyOpen = (flag: boolean) => {
    setSurveyOpened(flag);
  };

  return (
    <>
      <ChatbotDrawerContext.Provider
        value={{
          messages,
          setMessages,
        }}
      >
        {isTreatmentOrHomepage && (
          <FloatingChatStyled
            color="primary"
            aria-label="add"
            onClick={() => {
              if (isMobile) setDisplayChatBot(!displayChatbot);
              setChatbotDrawer(!chatbotDrawer);
              if (dataMenuStatus.menuStatus) {
                toogleMenuStatus({
                  variables: { status: !dataMenuStatus.menuStatus },
                });
              }
            }}
          >
            {chatbotDrawer ? <Cancel /> : <Chat />}
          </FloatingChatStyled>
        )}
        <DrawerStyled
          banner={dataBannerStatus.bannerStatus.toString()}
          surveyOpened={surveyOpened}
          anchor={'right'}
          open={!isMobile && chatbotDrawer && isTreatmentOrHomepage}
          variant="persistent"
          onClose={() => setChatbotDrawer(false)}
          style={{
            display:
              !isMobile && chatbotDrawer && isTreatmentOrHomepage
                ? 'block'
                : 'none',
          }}
        >
          <Chatbot
            assignedSurvey={assignedSurvey}
            setAssignedSurvey={setAssignedSurvey}
            loading={loading}
            messages={messages}
            setMessages={setMessages}
            chatBodyRef={chatBodyRef}
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            handleUserInput={handleUserInput}
            selectedSlug={selectedSlug}
            setSelectedSlug={setSelectedSlug}
            surveyInitiated={surveyInitiated}
            initiateSurvey={initiateSurvey}
            setSurveyInitiated={setSurveyInitiated}
            selectedSurvey={selectedSurvey}
            setSelectedSurvey={setSelectedSurvey}
            fromAnotherComp={true}
            setResponseAdded={setResponseAdded}
            setTreatmentContextFilteredIds={setTreatmentContextFilteredIds}
            handleSurveyOpen={handleSurveyOpen}
            surveyOpened={surveyOpened}
            scrollToBottom={scrollToBottom}
          />
        </DrawerStyled>
        {isMobile && (
          <MobileChatBot
            assignedSurvey={assignedSurvey}
            setAssignedSurvey={setAssignedSurvey}
            loading={loading}
            messages={messages}
            setMessages={setMessages}
            chatBodyRef={chatBodyRef}
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            handleUserInput={handleUserInput}
            selectedSlug={selectedSlug}
            setSelectedSlug={setSelectedSlug}
            surveyInitiated={surveyInitiated}
            setSurveyInitiated={setSurveyInitiated}
            selectedSurvey={selectedSurvey}
            setSelectedSurvey={setSelectedSurvey}
            initiateSurvey={initiateSurvey}
            displayChatbot={displayChatbot}
            setDisplayChatBot={setDisplayChatBot}
            setResponseAdded={setResponseAdded}
            scrollToBottom={scrollToBottom}
            handleSurveyOpen={handleSurveyOpen}
            surveyOpened={surveyOpened}
          />
        )}
      </ChatbotDrawerContext.Provider>
    </>
  );
};

export default React.memo(ChatbotDrawer);
