import React, { useMemo, useContext } from 'react';
import { useToogleDialog } from 'hooks';
import { Tooltip, IconButton } from '@material-ui/core';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { DeleteConfirm } from 'components';
import {
  DETACH_PATIENT_CHECKLIST,
  DELETE_PATIENT_CHECKLIST,
  GET_PATIENT_CHECKLISTS,
} from 'gql';
import { useMutationCustom } from 'hooks';
import {
  DetachPatientChecklistMutation,
  DetachPatientChecklistMutationVariables,
  DeletePatientChecklistMutation,
  DeletePatientChecklistMutationVariables,
} from 'types.d';
import { WrapButtonProgress, ProgressButtonStyled } from 'share/component_css';
import DeleteIcon from '@material-ui/icons/Delete';
import { useServices } from '../../services';
import { PatientDetailContext } from 'share/context';
import { customEvent } from 'modules/google_tag_manager';

type Props = {
  checklistId: string;
  patientChecklistId: string;
  isDetach?: boolean;
  callbackAfterSucessfully?: (isDetach?: boolean) => void;
};

export const ButtonDetachChecklist: React.FC<Props> = ({
  checklistId,
  patientChecklistId,
  isDetach,
  callbackAfterSucessfully,
}) => {
  const [isOpenDialog, toogleDialog] = useToogleDialog();

  const patientDetailContext = useContext(PatientDetailContext);

  const { variablesChecklistTag } = useServices();

  const [
    detachChecklist,
    { loading: loadingDetachChecklist },
  ] = useMutationCustom<
    DetachPatientChecklistMutation,
    DetachPatientChecklistMutationVariables
  >({
    api: DETACH_PATIENT_CHECKLIST,
    textSuccess: 'Detach this checklist successfully',
    callbackSuccess: () => {
      if (callbackAfterSucessfully) {
        callbackAfterSucessfully(true);
      }
    },
  });

  //check if checklist tab is opening
  const isOnChecklistTab = useMemo(() => {
    return (
      patientDetailContext?.isExtend && patientDetailContext?.indexTab === 6
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetailContext?.isExtend, patientDetailContext?.indexTab]);

  const [
    deleteChecklist,
    { loading: loadingDeleteChecklist },
  ] = useMutationCustom<
    DeletePatientChecklistMutation,
    DeletePatientChecklistMutationVariables
  >({
    api: DELETE_PATIENT_CHECKLIST,
    refetchQueries: [
      {
        query: GET_PATIENT_CHECKLISTS,
        variables: variablesChecklistTag,
      },
    ],
    textSuccess: 'Delete checklist successfully',
    callbackSuccess: data => {
      customEvent("DeleteChecklistOnPatientPD", "NAVIGATOR")

      //update checklist table
      if (isOnChecklistTab) {
        patientDetailContext?.setIsRefreshChecklist(true);
      }
      if (callbackAfterSucessfully) {
        callbackAfterSucessfully(true);
      }
    },
  });

  const handleDelete = () => {
    if (isDetach) {
      detachChecklist({
        variables: {
          params: {
            _id: checklistId,
            checklistResultId: patientChecklistId,
          },
        },
      });
      return;
    }

    deleteChecklist({
      variables: {
        params: {
          _id: checklistId,
          checklistResultId: patientChecklistId,
        },
      },
    });

    toogleDialog(false);
  };

  return (
    <>
      <DeleteConfirm
        title={`Are you sure you want ${isDetach ? 'detach' : 'delete'
          } this checklist?`}
        handleConfirm={handleDelete}
        open={isOpenDialog}
        toogle={status => {
          toogleDialog(status);
          if (!status && callbackAfterSucessfully) {
            callbackAfterSucessfully();
          }
        }}
        buttonText={isDetach ? 'Detach' : 'Delete'}
      />
      <WrapButtonProgress>
        <Tooltip title={isDetach ? 'Detach Checklist' : 'Delete Checklist'}>
          <IconButton color="secondary" onClick={() => toogleDialog(true)}>
            {isDetach ? (
              <LinkOffIcon fontSize="small" />
            ) : (
              <DeleteIcon fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
        {(loadingDetachChecklist || loadingDeleteChecklist) && (
          <ProgressButtonStyled size={45} />
        )}
      </WrapButtonProgress>
    </>
  );
};

export default React.memo(ButtonDetachChecklist);
