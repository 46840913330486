import gql from 'graphql-tag';
import {
  CommonTreatmentFragment,
  MetaFragment,
  ResponseShareTreatmentResFragment,
  TreatmentRegimenFragment,
} from './fragments';

export const GET_DATA_TREATMENT = gql`
  query GetDataTreatment($params: GetTreatmentsInput!) {
    getTreatments(params: $params) {
      nodes {
        ...CommonTreatment
      }
    }
  }
  ${CommonTreatmentFragment}
`;

export const CREATE_TREATMENT_REGIMEN = gql`
  mutation CreateTreatmentRegimen($params: CreateTreatmentRegimenInput!) {
    createTreatmentRegimen(params: $params) {
      ...TreatmentRegimen
    }
  }
  ${TreatmentRegimenFragment}
`;

export const UPDATE_TREATMENT_REGIMEN = gql`
  mutation UpdateTreatmentRegimen($params: UpdateTreatmentRegimenInput!) {
    updateTreatmentRegimen(params: $params) {
      ...TreatmentRegimen
    }
  }
  ${TreatmentRegimenFragment}
`;

export const DELETE_TREATMENT_REGIMEN = gql`
  mutation DeleteTreatmentRegimen($params: DeleteTreatmentRegimenInput!) {
    deleteTreatmentRegimen(params: $params)
  }
`;

export const CREATE_CALENDAR_CONFIG_BY_REGIMEN = gql`
  mutation CreateCalendarConfigByRegimen(
    $params: CreateCalendarConfigByRegimenInput!
  ) {
    createCalendarConfigByRegimen(params: $params) {
      ...ResponseShareTreatmentRes
    }
  }
  ${ResponseShareTreatmentResFragment}
`;

export const GET_TREATMENT_REGIMEN_ADD_PATIENT = gql`
  query GetTreatmentRegimenAddPatient($params: GetTreatmentRegimenInput!) {
    getTreatmentRegimen(params: $params) {
      nodes {
        ...TreatmentRegimen
      }
    }
  }
  ${TreatmentRegimenFragment}
`;

export const GET_TREATMENT_REGIMEN = gql`
  query GetTreatmentRegimen($params: GetTreatmentRegimenInput!) {
    getTreatmentRegimen(params: $params) {
      nodes {
        ...TreatmentRegimen
      }
      meta {
        ...Meta
      }
    }
  }
  ${TreatmentRegimenFragment}
  ${MetaFragment}
`;
