import React, { useContext, useMemo, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  AutoCompleteLoadData,
  ButtonLoading,
  DeleteConfirm,
  DialogButton,
  DialogTitleClose,
} from 'components';
import { useForm } from 'react-hook-form';
import { formatDate, renderTime } from 'share/utils';
import StopIcon from '@material-ui/icons/Stop';
import { PatientContext, PatientDetailContext } from 'share/context';
import {
  CptCodeFragment,
  GetCptCodeDetailsQuery,
  GetCptCodeQuery,
  GetCptCodeQueryVariables,
  PatientCptCodeStatus,
  PatientNoteTypeSettingFragment,
  PatientTimeReportFragment,
  GetCptCodeDetailsQueryVariables,
  ShowSaveRecordingQuery,
  ShowSaveRecordingQueryVariables,
  ToogleIsOpenDialogStopTimeRecordMutation,
  ToogleIsOpenDialogStopTimeRecordMutationVariables,
} from 'types.d';
import {
  GET_CPT_CODE,
  GET_CPT_CODE_DETAIL,
  SHOW_SAVE_RECORDING,
  TOOGLE_IS_OPEN_DIALOG_STOP_TIME_RECORD,
} from 'gql';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { useToogleDialog, useUpdateMeClient } from 'hooks';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { useServices } from '../../services';
import { useNoteTypes } from '../../services/useNoteTypes';
import { addSeconds, format } from 'date-fns';
import { TypographyBold } from 'share/component_css';
import { useSnackbar } from 'notistack';
import { DEFAULT_LIMIT } from 'CONST';
import { useTimeRecord } from 'hooks';
import { customEvent } from 'modules/google_tag_manager';
type Props = {
  openDialogAddRecord: boolean;
  setOpenDialogAddRecord: (status: boolean) => void;
  recordSelected?: PatientTimeReportFragment;
  callback?: () => void;
  resetCountdownTime?: () => void;
  idCptCode?: string;
};

export type FormData = {
  cptCodes: CptCodeFragment;
};

export const DialogAddTimeRecord: React.FC<Props> = ({
  openDialogAddRecord,
  setOpenDialogAddRecord,
  recordSelected,
  callback,
  resetCountdownTime,
  idCptCode,
}) => {
  const patientContext = useContext(PatientContext);
  const { meClient } = useUpdateMeClient();

  const [openStop, setOpenStop] = useToogleDialog();

  const patientDetailContext = useContext(PatientDetailContext);

  const [listNotes, setListNotes] = useState<PatientNoteTypeSettingFragment[]>(
    [],
  );

  const {
    NoteTypeTable,
    noteTypeTableProps,
    NoteTypeAutocomplete,
  } = useNoteTypes(listNotes, setListNotes);

  const { convertDateTimeByTimezonecallback } = useTimeRecord();
  const methods = useForm<FormData>({
    mode: 'onBlur',
  });
  const watchCptCode = methods.watch('cptCodes');

  const { enqueueSnackbar } = useSnackbar();

  const { control, errors, handleSubmit } = methods;

  const {
    handleCreateRecord,
    loadingCreateRecord,
    toogleStatusRecord,
    loadingToggleStatus,
    toogleSaveRecording,
  } = useServices();

  const time = useMemo(() => {
    return patientContext?.timer;
  }, [patientContext]);

  const [toogleIsOpenDialogStopTimeRecord] = useMutation<
    ToogleIsOpenDialogStopTimeRecordMutation,
    ToogleIsOpenDialogStopTimeRecordMutationVariables
  >(TOOGLE_IS_OPEN_DIALOG_STOP_TIME_RECORD);

  const { data: dataShowSaveRecord } = useQuery<
    ShowSaveRecordingQuery,
    ShowSaveRecordingQueryVariables
  >(SHOW_SAVE_RECORDING);

  const [
    getCPTCode,
    { loading: loadingCPTCodes, data: listCPTCode },
  ] = useLazyQuery<GetCptCodeQuery, GetCptCodeQueryVariables>(GET_CPT_CODE, {
    fetchPolicy: 'no-cache',
  });
  const [getCptCodeDetail, { data: cptCodeDetail }] = useLazyQuery<
    GetCptCodeDetailsQuery,
    GetCptCodeDetailsQueryVariables
  >(GET_CPT_CODE_DETAIL, {
    fetchPolicy: 'no-cache',
  });
  const enterDialog = () => {
    if (idCptCode) {
      getCptCodeDetail({ variables: { params: { _id: idCptCode } } });
      toogleIsOpenDialogStopTimeRecord({
        variables: { input: { status: true } },
      });
      //patientContext?.removeCountdown();
      return;
    }
    getCPTCode({
      variables: {
        params: {
          page: 1,
          limit: DEFAULT_LIMIT,
          sortByOrder: { createdAt: -1 },
        },
      },
    });
  };

  const isStopRecord = useMemo(() => {
    return (
      recordSelected && recordSelected.state === PatientCptCodeStatus.Running
    );
  }, [recordSelected]);

  const createTimeRecord = handleSubmit(data => {
    if (isStopRecord) {
      setOpenStop(true);
      return;
    }
    handleCreateRecord(idCptCode || data.cptCodes._id, () => {
      setOpenDialogAddRecord(false);
      patientDetailContext?.handleSetDialogOpen('');
      if (callback) {
        callback();
      }
    });
  });

  const handleCancel = (isResetCoundownTime?: boolean) => {
    toogleIsOpenDialogStopTimeRecord({
      variables: { input: { status: false } },
    });
    //patientContext?.continueToCountdown();
    setOpenDialogAddRecord(false);
    patientDetailContext?.handleSetDialogOpen('');
    if (resetCountdownTime && isResetCoundownTime) {
      resetCountdownTime();
    }
  };

  const stopTimeRecord = () => {
    toogleStatusRecord(
      () => {
        handleCancel();
        patientContext?.handleStop();
        toogleSaveRecording({
          variables: {
            input: null,
          },
        });
        if (callback) {
          callback();
        }
        customEvent("StopTimeRecordPD", "NAVIGATOR")
        enqueueSnackbar('Stop time record successfully', {
          variant: 'success',
        });
      },
      recordSelected && recordSelected?._id,
      PatientCptCodeStatus.Stopped,
      addSeconds(
        new Date(recordSelected?.startedAt),
        patientContext?.timer.seconds || 0,
      ),
      listNotes,
    );
  };

  const handleTextInput = (value: string) => {
    if (value) {
      getCPTCode({
        variables: {
          params: {
            page: 1,
            sortByOrder: { createdAt: -1 },
            search: value,
            limit: DEFAULT_LIMIT,
          },
        },
      });
    }
  };

  const timeByState = () => {
    switch (recordSelected?.state) {
      case PatientCptCodeStatus.Stopped:
        return recordSelected?.second || 0;
      case PatientCptCodeStatus.Pause:
        return recordSelected?.second || 0 + (time?.seconds || 0);
      case PatientCptCodeStatus.Running:
        return time?.seconds || 0;
      default:
        return 0;
    }
  };
  const renderInfo = () => {
    let infor = [];
    if (idCptCode) {
      infor.push({
        key: 'Module',
        value: cptCodeDetail?.getCPTCodeDetails?.code as string,
      });
    }
    infor = [
      ...infor,
      {
        key: 'Navigator',
        value: `${meClient.firstName} ${meClient.middleName || ''} ${meClient.lastName
          }`,
      },
      {
        key: 'Start time',
        value: convertDateTimeByTimezonecallback(date => {
          return format(new Date(date), 'HH:mm:ss');
        }, recordSelected?.startedAt || undefined),
      },
      {
        key: 'Time spent',
        value: renderTime(timeByState()),
      },
      {
        key: 'Date',
        value: convertDateTimeByTimezonecallback(date => {
          return formatDate(date);
        }, recordSelected?.startedAt || undefined),
      },
    ];

    return infor?.map(item => {
      return (
        <Grid item xs={4} key={item?.key}>
          <Box display="flex">
            <TypographyBold
              className="mr-16"
              color="textSecondary"
              variant="subtitle2"
            >
              {item?.key}
            </TypographyBold>
            <Typography variant="subtitle2">{item?.value}</Typography>
          </Box>
        </Grid>
      );
    });
  };

  const noteTypeOptions = useMemo(() => {
    if (idCptCode) {
      return cptCodeDetail?.getCPTCodeDetails?.patientNoteTypes || [];
    }
    return (
      listCPTCode?.getCPTCode.nodes.find(item => item._id === watchCptCode?._id)
        ?.patientNoteTypes || []
    );
  }, [listCPTCode, idCptCode, cptCodeDetail, watchCptCode]);
  return (
    <>
      {openStop && (
        <DeleteConfirm
          loading={loadingToggleStatus}
          handleConfirm={stopTimeRecord}
          title="Are you sure you want to stop this record?"
          buttonText="Stop"
          icon={<StopIcon />}
          open={openStop}
          toogle={setOpenStop}
        />
      )}
      <Dialog
        open={openDialogAddRecord}
        onEnter={enterDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitleClose
          title={isStopRecord ? 'Stop Time Record' : 'Start Time Record'}
          onClose={() => handleCancel(true)}
        />
        <DialogContent>
          <Grid container spacing={2}>
            {renderInfo()}
            {!idCptCode && (
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <AutoCompleteLoadData
                    hasOptionsTextNull
                    label="Module"
                    nameOption="name"
                    required
                    loadList={loadingCPTCodes}
                    options={
                      listCPTCode?.getCPTCode?.nodes?.map(item => {
                        return { ...item, name: item?.code };
                      }) || []
                    }
                    small
                    defaultValue={null}
                    name="cptCodes"
                    control={control}
                    error={!!errors.cptCodes}
                    callbackHandleText={value => handleTextInput(value)}
                  />
                </Grid>
              </Grid>
            )}
            {isStopRecord && (
              <>
                <Grid item xs={6}>
                  <NoteTypeAutocomplete
                    {...noteTypeTableProps}
                    noteTypeOptions={noteTypeOptions}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NoteTypeTable {...noteTypeTableProps} />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton isCancel onClickButton={() => handleCancel(true)} />
          {recordSelected &&
            recordSelected.state === PatientCptCodeStatus.Running ? (
            <ButtonLoading
              text="Stop"
              color="secondary"
              Icon={<StopIcon />}
              loading={loadingToggleStatus}
              callbackClick={createTimeRecord}
            />
          ) : dataShowSaveRecord?.showSaveRecording ? (
            <></>
          ) : (
            <ButtonLoading
              text="Start"
              color="primary"
              Icon={<PlayCircleFilledIcon />}
              callbackClick={createTimeRecord}
              loading={loadingCreateRecord}
            />
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(DialogAddTimeRecord);
