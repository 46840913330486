import React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@material-ui/core';
import { styled, useTheme } from '@material-ui/core/styles';
import { Chatbot } from './Chatbot';
import { Close } from '@material-ui/icons';

type Props = {
  loading: boolean;
  messages: any;
  setMessages: any;
  chatBodyRef: any;
  inputMessage: any;
  setInputMessage: any;
  handleUserInput: Function;
  surveyInitiated: any;
  setSurveyInitiated: any;
  selectedSlug: any;
  setSelectedSlug: any;
  selectedSurvey: any;
  setSelectedSurvey: any;
  initiateSurvey: Function;
  assignedSurvey?: any;
  setAssignedSurvey?: Function;
  displayChatbot: boolean;
  setDisplayChatBot: React.Dispatch<React.SetStateAction<any>>;
  fromAnotherComp?: boolean;
  setResponseAdded: Function;
  scrollToBottom?: () => void;
  handleSurveyOpen?: (flag: boolean) => void;
  surveyOpened?: boolean;
};

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

const SearchBoxStyled = styled(Box)({
  marginTop: '5px',
  position: 'relative',
  width: '100%',
});

const DialogStyled = styled(Dialog)({
  zIndex: 99999,
  '& .MuiDialog-paperWidthSm': {
    minWidth: '300px',
    minHeight: '80vh',
  },
});

export const MobileChatBot: React.FC<Props> = (props: Props) => {
  const theme = useTheme();

  return (
    <DialogStyled
      onClose={() => props.setDisplayChatBot(false)}
      open={props.displayChatbot}
      scroll="paper"
    >
      <DialogTitle
        style={{
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.dark,
        }}
      >
        TGPS Assistant
        <IconButtonStyled
          aria-label="close"
          onClick={() => props.setDisplayChatBot(false)}
        >
          <Close />
        </IconButtonStyled>
      </DialogTitle>
      <DialogContent>
        <Chatbot
          assignedSurvey={props.assignedSurvey}
          setAssignedSurvey={props.setAssignedSurvey}
          loading={props.loading}
          messages={props.messages}
          setMessages={props.setMessages}
          chatBodyRef={props.chatBodyRef}
          inputMessage={props.inputMessage}
          setInputMessage={props.setInputMessage}
          handleUserInput={props.handleUserInput}
          selectedSlug={props.selectedSlug}
          setSelectedSlug={props.setSelectedSlug}
          surveyInitiated={props.surveyInitiated}
          setSurveyInitiated={props.setSurveyInitiated}
          selectedSurvey={props.selectedSurvey}
          setSelectedSurvey={props.setSelectedSurvey}
          initiateSurvey={props.initiateSurvey}
          isMobile={true}
          fromAnotherComp={props.fromAnotherComp}
          setResponseAdded={props.setResponseAdded}
          scrollToBottom={props.scrollToBottom}
          handleSurveyOpen={props.handleSurveyOpen}
          surveyOpened={props.surveyOpened}
        />
      </DialogContent>
    </DialogStyled>
  );
};
export default MobileChatBot;
