import React from 'react';
import * as Survey from 'survey-react';
import { useEffectOnlyOnce, useUpdateMeClient } from 'hooks';
import { useTheme } from '@material-ui/core/styles';
import { CREATE_SURVEY_RESULT, SUBMIT_FORM_DATA } from 'gql';
import { useMutationCustom } from 'hooks';
import {
  CreateDynamicFormDataInput,
  CreateSurveyResultMutation,
  CreateSurveyResultMutationVariables,
  FormStatus,
  SubmitFormDataMutation,
  SubmitFormDataMutationVariables,
} from 'types.d';
import { BackdropComponent, DialogTitleClose } from 'components';
import { getSessionStorage } from 'share/utils';
import { KEY_SESSION_ORGANIZATION_ID } from 'CONST';
import { Dialog, DialogContent, useMediaQuery } from '@material-ui/core';

type Props = {
  surveyData: any;
  handleSurveyOpen: (flag: boolean) => void;
  setSurveyInitiated: (flag: boolean) => void;
};
export const ChatbotSurvey: React.FC<Props> = ({
  surveyData,
  handleSurveyOpen,
  setSurveyInitiated,
}) => {
  const { meClient } = useUpdateMeClient();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let surveyJson = {};

  try {
    surveyJson = {
      ...surveyData,
      focusFirstQuestionAutomatic: false,
      mode: 'edit',
      showCompletedPage: true,
    };
  } catch (e) {
    surveyJson = {};
  }

  useEffectOnlyOnce(() => {
    const mainColor = theme.palette.primary.main;
    //set main color for creator survey
    const defaultThemeColorsSurvey =
      Survey.StylesManager.ThemeColors['default'];
    defaultThemeColorsSurvey['$main-color'] = mainColor;
    Survey.StylesManager.applyTheme();
  });

  const [createDynamicFormData, { loading }] = useMutationCustom<
    SubmitFormDataMutation,
    SubmitFormDataMutationVariables
  >({
    api: SUBMIT_FORM_DATA,
    textSuccess: 'Thank you for completing survey',
  });

  const onComplete = (survey: Survey.SurveyModel, options: any) => {
    let params: CreateDynamicFormDataInput = {
      status: FormStatus.New,
      formJson: JSON.stringify(surveyData),
      answersJson: JSON.stringify(survey.data),
    };

    const { _id, organizationId } = meClient;
    const orgId = getSessionStorage(KEY_SESSION_ORGANIZATION_ID)!;

    if (_id) params['userId'] = _id;

    if (organizationId) params['organization'] = organizationId;
    else if (orgId) params['organization'] = orgId;

    createDynamicFormData({
      variables: {
        params: params,
      },
    }).then(data => {
      survey.clear(true);

      setSurveyInitiated(true);

      handleSurveyOpen(false);
    });
  };

  const handleClose = () => {
    setSurveyInitiated(true);

    handleSurveyOpen(false);
  };

  return (
    <>
      {!isMobile ? (
        <>
          <BackdropComponent loading={loading} />
          <Survey.Survey json={surveyJson} onComplete={onComplete} />
        </>
      ) : (
        <Dialog open={true} onClose={handleClose} fullWidth={true}>
          <DialogTitleClose onClose={handleClose} title={'Survey'} />
          <DialogContent className="pt-0">
            <BackdropComponent loading={loading} />
            <Survey.Survey json={surveyJson} onComplete={onComplete} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default React.memo(ChatbotSurvey);
