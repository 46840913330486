import gql from 'graphql-tag';
import {
  DuplicateUserFragment,
  EmrLogFragment,
  MetaFragment,
  SameNewPatientFragment,
} from './fragments';

export const GET_EMR_LOGS = gql`
  query GetEmrLogs($params: GetInboundEmrLogInput!) {
    getEmrLogs(params: $params) {
      nodes {
        ...EmrLog
      }
      meta {
        ...Meta
      }
    }
  }
  ${EmrLogFragment}
  ${MetaFragment}
`;

export const EMR_MATCHING_PATIENT = gql`
  mutation EmrMatchingPatient($params: MatchingPatientInput!) {
    emrMatchingPatient(params: $params) {
      result
      data {
        ...SameNewPatient
      }
      userExists {
        ...DuplicateUser
      }
    }
  }
  ${SameNewPatientFragment}
  ${DuplicateUserFragment}
`;

export const UPDATE_EMR_LOG = gql`
  mutation UpdateEmrLog($params: IdInputType!) {
    updateEmrLog(params: $params) {
      ...EmrLog
    }
  }
  ${EmrLogFragment}
`;
