import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { styled, Theme } from '@material-ui/core/styles';

type Props = {
  props?: SvgIconProps;
  hasMargin?: boolean;
};
const SvgIconStyled = styled(SvgIcon)(
  ({ theme, hasmargin }: { theme: Theme; hasmargin: string }) => ({
    fontSize: theme.spacing(8),
    marginRight: Object.is(hasmargin, 'true') ? theme.spacing(1) : 0,
    height: '0.7em',
  }),
);

export const GpsIcon: React.FC<Props> = ({ props, hasMargin }) => {
  return (
    <SvgIconStyled
      hasmargin={hasMargin ? 'true' : 'false'}
      viewBox="0 0 82 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.8618 13.6953V32.1155L48.2839 23.5999C49.9806 22.5732 51.3744 21.1842 52.7075 19.4932C54.1013 17.8021 54.1013 16.0507 54.1013 14.0577C54.1013 12.0043 53.7377 9.95092 52.7075 8.25989C51.6773 6.56887 50.2836 5.17981 48.5868 4.15311C46.8901 3.12642 44.8298 2.46208 43.133 2.46208C41.0727 2.46208 39.0124 2.82445 37.3156 3.85114C35.6189 4.87784 34.2251 6.56887 33.1949 8.25989C32.1648 9.95092 31.8618 11.642 31.8618 13.6953ZM48.9504 25.291L29.8015 35.1956V13.6953C29.8015 11.2796 30.468 8.92423 31.4982 6.87084C32.5284 4.81744 34.2251 3.12642 36.2854 2.09972C38.3458 1.07302 40.7091 0.408691 43.133 0.408691C45.5569 0.408691 47.9203 1.07302 49.617 2.46208C51.6773 3.85114 53.3741 5.54217 54.4043 7.59556C55.4344 9.64895 56.101 12.0043 56.101 14.4201C56.101 16.8358 55.4344 19.1912 54.0407 20.8822C53.0711 22.5732 51.0108 24.2643 48.9504 25.291Z"
        fill="#3374BA"
      />
      <path
        d="M43.8602 17.3795H38.1034V7.95801H43.8602C46.1023 7.95801 48.5868 9.76982 48.5868 12.6687C48.5262 14.9033 46.7689 17.3795 43.8602 17.3795ZM40.6485 14.7825H43.7996C45.1328 14.7825 45.9205 13.6954 45.9205 12.6687C45.9205 11.3401 44.8298 10.5549 43.7996 10.5549H40.6485V14.7825Z"
        fill="#3374BA"
      />
      <path
        d="M26.8322 31.0886C25.6202 32.5381 23.9235 33.6252 21.7419 34.4103C19.5604 35.1954 17.1365 35.6182 14.4702 35.6182C11.6827 35.6182 9.19814 35.0142 7.07721 33.8063C4.95628 32.5985 3.32013 30.7866 2.16877 28.4917C0.95681 26.1967 0.411428 23.4186 0.35083 20.3385V18.1643C0.35083 14.9634 0.896212 12.1249 1.98698 9.76957C3.07774 7.41421 4.65329 5.6024 6.71362 4.33412C8.77395 3.06585 11.1979 2.46191 13.9248 2.46191C17.7425 2.46191 20.7724 3.36782 22.9539 5.17964C25.1354 6.99146 26.408 9.64879 26.8322 13.1516H20.2876C19.9846 11.2794 19.318 9.95076 18.2878 9.10524C17.2577 8.25973 15.8639 7.83697 14.1066 7.83697C11.8645 7.83697 10.1071 8.68248 8.89515 10.3735C7.68319 12.0645 7.07721 14.6011 7.07721 17.9831V20.0365C7.07721 23.4186 7.74379 25.9551 9.01634 27.7066C10.2889 29.458 12.228 30.3035 14.7126 30.3035C17.1971 30.3035 19.015 29.76 20.1058 28.7333V23.177H14.046V18.2851H26.7716V31.0886H26.8322Z"
        fill="#3374BA"
      />
      <path
        d="M74.4016 26.7403C74.4016 25.472 73.9774 24.5057 73.0684 23.8413C72.1595 23.177 70.5839 22.4523 68.2812 21.7276C65.9785 21.0028 64.1605 20.2177 62.7668 19.493C59.0703 17.5 57.2523 14.8427 57.2523 11.4606C57.2523 9.70918 57.7371 8.13894 58.7067 6.81027C59.6763 5.42121 61.1306 4.39452 62.9486 3.6094C64.7665 2.82428 66.8874 2.46191 69.1901 2.46191C71.4929 2.46191 73.5532 2.88467 75.3711 3.73019C77.1891 4.5757 78.5828 5.72318 79.613 7.29342C80.6432 8.80327 81.128 10.5547 81.128 12.4873H74.4622C74.4622 11.0378 73.9774 9.82997 73.0684 9.04485C72.1595 8.19933 70.8263 7.83697 69.1296 7.83697C67.4934 7.83697 66.2208 8.19933 65.3119 8.86366C64.4029 9.528 63.9787 10.4339 63.9787 11.5814C63.9787 12.6081 64.5241 13.514 65.5543 14.2387C66.645 14.9634 68.16 15.6278 70.2203 16.2317C74.038 17.3792 76.7649 18.7683 78.5222 20.4593C80.2796 22.1503 81.128 24.2641 81.128 26.7403C81.128 29.5184 80.0372 31.7529 77.9163 33.3232C75.7953 34.8934 72.9472 35.6785 69.3719 35.6785C66.8874 35.6785 64.5847 35.1954 62.585 34.2895C60.5246 33.3836 58.9491 32.1153 57.9189 30.5451C56.8282 28.9748 56.2828 27.1026 56.2828 25.0492H63.0092C63.0092 28.6125 65.1301 30.4243 69.4325 30.4243C71.0081 30.4243 72.2807 30.1223 73.129 29.458C73.9774 28.7936 74.4016 27.8877 74.4016 26.7403Z"
        fill="#3374BA"
      />
    </SvgIconStyled>
  );
};
export default GpsIcon;
