import gql from 'graphql-tag';
import { MetaFragment, PatientNoteTypeSettingFragment } from './fragments';

export const GET_NOTE_TYPES = gql`
  query GetPatientNoteTypes($params: GetPatientNoteTypesInput!) {
    getPatientNoteTypes(params: $params) {
      nodes {
        ...PatientNoteTypeSetting
      }
      meta {
        ...Meta
      }
    }
  }
  ${MetaFragment}
  ${PatientNoteTypeSettingFragment}
`;

export const CREATE_NOTE_TYPE = gql`
  mutation CreatePatientNoteType($params: CreatePatientNoteTypeInput!) {
    createPatientNoteType(params: $params) {
      idNote: _id
    }
  }
`;

export const UPDATE_NOTE_TYPE = gql`
  mutation UpdatePatientNoteType($params: UpdatePatientNoteTypeInput!) {
    updatePatientNoteType(params: $params) {
      ...PatientNoteTypeSetting
    }
  }
  ${PatientNoteTypeSettingFragment}
`;

export const DELETE_NOTE_TYPE = gql`
  mutation DeletePatientNoteType($params: DeletePatientNoteTypeInput!) {
    deletePatientNoteType(params: $params)
  }
`;

export const ADD_MORE_TREATMENT_NOTE_TYPE = gql`
  mutation AddMoreTreatmentNoteType($params: AddMoreTreatmentNoteTypeInput!) {
    addMoreTreatmentNoteType(params: $params) {
      _id
      treatments {
        _id
        name
      }
      folder {
        _id
        name
      }
    }
  }
`;

export const REMOVE_ONE_TREATMENT_NOTE_TYPE = gql`
  mutation RemoveOneTreatmentNoteType(
    $params: RemoveOneTreatmentNoteTypeInput!
  ) {
    removeOneTreatmentNoteType(params: $params) {
      _id
      treatments {
        _id
        name
      }
      folder {
        _id
        name
      }
    }
  }
`;
