import React, { useState, useContext } from 'react';
import { Box, Tooltip, IconButton } from '@material-ui/core';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import {
  TagFragment,
  CreatePatientTagMutation,
  CreatePatientTagMutationVariables,
  TagEmbeddedOnPatientFragment,
} from 'types.d';
import { CREATE_PATIENT_TAG, PatientFragment as PatientFragmentGql } from 'gql';
import {
  CreateUpdateTreatmentContext,
  PatientDetailContext,
} from 'share/context';
import { handleError, trimObjectValue } from 'share/utils';
import {
  WrapButtonProgress,
  ProgressButtonStyled,
  ChipStyled,
} from 'share/component_css';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import { useToogleDialog } from 'hooks';
import AutoCompleteAddTag from './AutoCompleteAddTag';
import { customEvent } from 'modules/google_tag_manager';

type Props = {
  saveTagImport?: (tag: TagFragment, color: string) => void;
  createTagWhenUpdate?: (tag: TagFragment) => void;
  idFolder?: string;
};
const defaultColorTag = '#1976d2';

export const AddTag: React.FC<Props> = ({
  saveTagImport,
  createTagWhenUpdate,
  idFolder,
}) => {
  const [color, setColor] = useState(defaultColorTag);

  const patientDetailContext = useContext(PatientDetailContext);

  const createUpdateTreatmentContext = useContext(CreateUpdateTreatmentContext);

  const tags = createUpdateTreatmentContext?.tags;

  const setTags = createUpdateTreatmentContext?.setTags;

  const { enqueueSnackbar } = useSnackbar();

  const [isDisplayFormAdd, toogleDisplayFormAdd] = useToogleDialog();

  const [tagCreate, setTagCreate] = useState<TagFragment | null>(null);

  const client = useApolloClient();

  const [createPatientTag, { loading: loadingCreatePatientTag }] = useMutation<
    CreatePatientTagMutation,
    CreatePatientTagMutationVariables
  >(CREATE_PATIENT_TAG, {
    onCompleted: data => {
      if (setTags && tags) {
        if (
          tags?.find(
            item => item?.tag?.name === data?.createPatientTag?.tag?.name,
          )
        ) {
          const newTags = tags.map(tag =>
            tag.tag?.name === data?.createPatientTag?.tag?.name
              ? {
                ...tag,
                tag: { ...data?.createPatientTag?.tag, color },
              }
              : tag,
          );
          setTags(newTags);
          customEvent("UpdateTagPD", "NAVIGATOR")
          enqueueSnackbar(`Update tag successfully`, {
            variant: 'success',
          });
          return;
        }
        if (createTagWhenUpdate && idFolder) {
          createTagWhenUpdate(data?.createPatientTag?.tag);
          customEvent("CreateTagPD", "NAVIGATOR")
          enqueueSnackbar(`Create tag successfully`, {
            variant: 'success',
          });
        }
        setTags([
          ...tags,
          {
            tag: { ...data?.createPatientTag?.tag, color },
            name: data?.createPatientTag?.tag?.name,
            isAssignTag: false,
          },
        ]);
      }
    },
  });

  const updateTagPatient = (tags: TagEmbeddedOnPatientFragment[]) => {
    client.writeFragment({
      id: `PatientRes:${patientDetailContext?.patient.owner?.idOwner}`,
      fragment: PatientFragmentGql,
      fragmentName: 'Patient',
      data: {
        ...patientDetailContext?.patient,
        owner: {
          ...patientDetailContext?.patient.owner,
          tags: tags || [],
        },
      },
    });
  };

  const saveTag = (tagCreate: TagFragment, color: string) => {
    const params = {
      name: typeof tagCreate === 'string' ? tagCreate : tagCreate?.name,
      patientId: patientDetailContext?.patient.owner?.idOwner,
      color,
    };
    createPatientTag({
      variables: {
        params: {
          ...trimObjectValue(params),
        },
      },
    })
      .then(dataCallback => {
        if (!idFolder) {
          customEvent("CreateTagPD", "NAVIGATOR")
          enqueueSnackbar('Create tag successfully', { variant: 'success' });
        }
        toogleDisplayFormAdd(false);
        setColor(defaultColorTag);
        setTagCreate(null);
        if (
          dataCallback?.data?.createPatientTag &&
          dataCallback?.data?.createPatientTag.added
        ) {
          updateTagPatient([
            ...((patientDetailContext?.patient?.owner?.tags ||
              []) as TagEmbeddedOnPatientFragment[]),
            {
              tag: {
                _id: dataCallback?.data?.createPatientTag.tag._id,
                __typename: 'Tag',
              },
              __typename: 'TagEmbedded',
            },
          ]);
        }
      })
      .catch(error => {
        const arrError = handleError(error.graphQLErrors[0]!);
        enqueueSnackbar(arrError.join(', '), { variant: 'error' });
      });
  };

  const createTag = () => {
    if (tagCreate) {
      saveTag(tagCreate, color);
    }
  };

  const creatTagImport = () => {
    if (tagCreate && saveTagImport) {
      saveTagImport(tagCreate, color);
      toogleDisplayFormAdd(false);
      setTagCreate(null);
      setColor(defaultColorTag);
    }
  };

  const cancelAddTag = () => {
    setColor(defaultColorTag);
    toogleDisplayFormAdd(false);
    setTagCreate(null);
  };

  return (
    <>
      {isDisplayFormAdd ? (
        <Box display="flex" alignItems="center" mr={1}>
          <AutoCompleteAddTag
            tagCreate={tagCreate}
            setTagCreate={setTagCreate}
            color={color}
            setColor={setColor}
            isPatientDashboard
          />
          <Tooltip title="Save tag">
            <WrapButtonProgress>
              <IconButton
                disabled={!!loadingCreatePatientTag}
                onClick={saveTagImport ? creatTagImport : createTag}
                size="small"
                color="primary"
              >
                <CheckIcon />
              </IconButton>
              {!!loadingCreatePatientTag && (
                <ProgressButtonStyled sizearound="small" size={30} />
              )}
            </WrapButtonProgress>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton onClick={cancelAddTag} size="small">
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <ChipStyled
          colortext="true"
          className={idFolder ? '' : 'mr-8'}
          onClick={() => toogleDisplayFormAdd(true)}
          label={
            <Box display="flex">
              <AddIcon fontSize="small" />
            </Box>
          }
        />
      )}
    </>
  );
};

export default React.memo(AddTag);
