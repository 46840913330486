import { styled } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

export const ChipStyled = styled(Chip)({
  '&.MuiChip-outlined': {
    color: green[500],
    borderColor: green[500],
  },
});
