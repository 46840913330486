import React from 'react';
import { GridDropActiveStyled } from '../../styles';
import { Grid, Box } from '@material-ui/core';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { PaperBorder, TypographyItalic } from 'share/component_css';

type Props = {};

export const GridDrop: React.FC<Props> = () => {
  return (
    <GridDropActiveStyled container spacing={2}>
      <Grid item xs={12}>
        <PaperBorder className="h-100 position-relative">
          <Box
            display="flex"
            position="absolute"
            flexDirection="column"
            alignItems="center"
            top="50%"
            left="50%"
            style={{ transform: 'translate(-50%, -50%)' }}
          >
            <SystemUpdateAltIcon color="action" style={{ fontSize: '5rem' }} />
            <TypographyItalic
              className="font-weight-bold"
              color="textSecondary"
              variant="h5"
            >
              Drop A Tab Here
            </TypographyItalic>
          </Box>
        </PaperBorder>
      </Grid>
    </GridDropActiveStyled>
  );
};

export default React.memo(GridDrop);
