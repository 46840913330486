import React, { useCallback, useContext, useRef, useMemo } from 'react';
import {
  PatientNoteFragment,
  SubNoteFragment,
  DeletePatientNoteMutation,
  DeletePatientNoteMutationVariables,
  GetPatientNotesQuery,
  GetPatientNotesQueryVariables,
} from 'types.d';
import { useToogleDialog, usePopover, useUpdateMeClient } from 'hooks';
import { Box, Tooltip, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import LoadingItem from './LoadingTags';
import { generateLabelNote, formatDate } from 'share/utils';
import { TypoThreeDot, ChipStyled } from 'share/component_css';
import PickColorNote from './PickColorNote';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DialogNote from '../Note/DialogNote';
import { PatientTagsContext } from 'share/context';
import { ButtonDelete, ScrollStyled } from 'components';
import { DELETE_PATIENT_NOTE, GET_NOTE_TAG, GET_PATIENT_NOTES } from 'gql';
import { useMutationCustom } from 'hooks';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import { useServices } from '../../services';
import BoxShowMore from './BoxShowMore';

type Props = {
  loadingDataGroup: boolean;
  patientNotes?: PatientNoteFragment[];
};

export const NoteTag: React.FC<Props> = ({
  loadingDataGroup,
  patientNotes,
}) => {
  const [isOpenNote, setOpenNote] = useToogleDialog();

  const [isOpenPickColor, setOpenPickColor] = useToogleDialog();

  const patientTagsContext = useContext(PatientTagsContext);

  const { updateSurveyResultLocal } = useServices();

  const inforNode = useRef<{
    note: PatientNoteFragment | undefined;
    lastNote: SubNoteFragment | null;
  }>({
    note: undefined,
    lastNote: null,
  });

  const [open, close, DefaultPopover, defaultPopoverProps] = usePopover();

  const { meClient } = useUpdateMeClient();

  //check if note tab is opening
  const isOnNoteTab = useMemo(() => {
    return patientTagsContext?.isExtend && patientTagsContext?.indexTab === 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientTagsContext?.isExtend, patientTagsContext?.indexTab]);

  const refetchQueries = useMemo(() => {
    if (isOnNoteTab) {
      return [];
    }
    return [
      {
        query: GET_NOTE_TAG,
        variables: {
          params: {
            page: 1,
            limit: meClient.setting?.perPage || 5,
            patientId: patientTagsContext?.patient.owner?.idOwner,
          },
        },
      },
    ];
  }, [isOnNoteTab, meClient.setting, patientTagsContext]);

  const [
    deletePatientNote,
    { loading: loadingDeletePatientNote, client },
  ] = useMutationCustom<
    DeletePatientNoteMutation,
    DeletePatientNoteMutationVariables
  >({
    api: DELETE_PATIENT_NOTE,
    refetchQueries: refetchQueries,
    textSuccess: 'Delete note successfully',
    callbackSuccess: data => {
      //all notes
      if (isOnNoteTab) {
        const infoPatientNotes = client?.readQuery<
          GetPatientNotesQuery,
          GetPatientNotesQueryVariables
        >({
          query: GET_PATIENT_NOTES,
          variables: {
            params: {
              patientId: patientTagsContext?.patient.owner?.idOwner,
            },
          },
        });
        //update cache all notes
        if (infoPatientNotes) {
          client?.writeQuery({
            query: GET_PATIENT_NOTES,
            variables: {
              params: {
                patientId: patientTagsContext?.patient.owner?.idOwner,
              },
            },
            data: {
              getPatientNotes: {
                ...infoPatientNotes.getPatientNotes,
                nodes: [
                  ...(infoPatientNotes?.getPatientNotes?.nodes.filter(
                    item => item._id !== inforNode.current.note?._id,
                  ) || []),
                ],
              },
            },
          });
          //update cache notes on tag
          client?.writeQuery({
            query: GET_NOTE_TAG,
            variables: {
              params: {
                patientId: patientTagsContext?.patient.owner?.idOwner,
                page: 1,
                limit: meClient.setting?.perPage || 5,
              },
            },
            data: {
              getPatientNotes: {
                ...infoPatientNotes.getPatientNotes,
                nodes: [
                  ...(
                    infoPatientNotes?.getPatientNotes?.nodes.filter(
                      item => item._id !== inforNode.current.note?._id,
                    ) || []
                  ).slice(0, 5),
                ],
              },
            },
          });
        }
      }

      //remove patient note of survey result local
      updateSurveyResultLocal(
        inforNode.current.note?.surveyResult?.surveyResultId,
      );
    },
  });

  const openAddDialog = useCallback(() => {
    //open dialog from task tab
    if (patientTagsContext?.isExtend && patientTagsContext?.indexTab === 1) {
      patientTagsContext.handleSetDialogOpen('add-note');
      return;
    }
    //open dialog from note tag
    setOpenNote(true);
    inforNode.current = { note: undefined, lastNote: null };
  }, [patientTagsContext, setOpenNote]);

  const setSelectedNote = (
    note: PatientNoteFragment,
    lastNote: SubNoteFragment | null,
  ) => {
    inforNode.current = { note, lastNote };
  };

  const handleDeleteNote = (key: string) => {
    deletePatientNote({
      variables: {
        params: {
          noteId: key,
          patientId: patientTagsContext?.patient.owner?.idOwner,
        },
      },
    });
    close();
  };

  const closePickColor = () => {
    setOpenPickColor(false);
    close();
  };

  return (
    <>
      {isOpenNote && (
        <DialogNote
          noteSelected={inforNode.current.note}
          open={isOpenNote}
          toogleDialog={setOpenNote}
        />
      )}
      <DefaultPopover {...defaultPopoverProps}>
        {isOpenPickColor ? (
          <Box p={1}>
            <PickColorNote
              updatedNote={inforNode.current.lastNote}
              patientNoteId={inforNode.current.note?._id}
              close={closePickColor}
            />
          </Box>
        ) : (
          <Box p={0.5} display="flex" flexDirection="column">
            <Tooltip title="Pick color">
              <IconButton
                onClick={() => setOpenPickColor(true)}
                color="default"
              >
                <FormatColorFillIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <ButtonDelete
              loadingDelete={loadingDeletePatientNote}
              deleteItem={handleDeleteNote}
              id={inforNode.current.note?._id}
            />
          </Box>
        )}
      </DefaultPopover>
      {!patientTagsContext?.isInactive && (
        <ChipStyled
          colortext="true"
          className="mr-8"
          onClick={openAddDialog}
          label={
            <Box display="flex">
              <AddIcon fontSize="small" />
            </Box>
          }
        />
      )}

      {patientNotes?.length !== 0 && <BoxShowMore index={1} />}
      {loadingDataGroup && <LoadingItem />}

      {patientNotes && patientNotes?.length > 0 && !loadingDataGroup && (
        <ScrollStyled
          loading={loadingDataGroup}
          listLength={patientNotes?.length}
        >
          {!loadingDataGroup &&
            patientNotes.map(item => {
              const lastNote = generateLabelNote(item.notes);
              return (
                <ChipStyled
                  onClick={() => {
                    setSelectedNote(item, lastNote);
                    setOpenNote(true);
                    close();
                  }}
                  className="cursor-pointer"
                  key={item._id}
                  backgroundcolor={
                    lastNote?.color || lastNote?.type?.color || undefined
                  }
                  label={
                    <Tooltip
                      key={item._id}
                      title={
                        <Box display="flex" flexDirection="column">
                          {patientTagsContext?.generateInformation([
                            {
                              name: 'Name',
                              info:
                                lastNote !== null
                                  ? lastNote.description
                                  : 'N/A',
                            },
                            {
                              name: 'Created Date',
                              info: item?.createdAt
                                ? formatDate(item?.createdAt)
                                : 'N/A',
                            },
                            {
                              name: 'Survey',
                              info: item.survey?.name || 'N/A',
                            },
                          ])}
                        </Box>
                      }
                    >
                      <TypoThreeDot variant="subtitle2">
                        {`${
                          lastNote !== null ? lastNote.description : 'N/A'
                        } - ${formatDate(item?.createdAt)}`}
                      </TypoThreeDot>
                    </Tooltip>
                  }
                  onDelete={e => {
                    setOpenPickColor(false);
                    open(e);
                    setSelectedNote(item, lastNote);
                  }}
                  deleteIcon={
                    !patientTagsContext?.isInactive ? <MoreHorizIcon /> : <></>
                  }
                />
              );
            })}
        </ScrollStyled>
      )}
    </>
  );
};

export default React.memo(NoteTag);
