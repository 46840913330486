import gql from 'graphql-tag';
import { ResponseModifyTreatmentFragment } from './fragments';

export const CREATE_FOLDER = gql`
  mutation CreateFolder($params: CreateFolderInput!) {
    createFolder(params: $params) {
      ...ResponseModifyTreatment
    }
  }
  ${ResponseModifyTreatmentFragment}
`;

export const UPDATE_FOLDER_INFO = gql`
  mutation UpdateFolderInfo($params: UpdateFolderInfoInput!) {
    updateFolderInfo(params: $params) {
      ...ResponseModifyTreatment
    }
  }
  ${ResponseModifyTreatmentFragment}
`;
