import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  AutocompleteGetTagProps,
} from '@material-ui/lab';
import {
  CircularProgress,
  TextField,
  Popper,
  PopperProps,
  Box,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { Controller, Control } from 'react-hook-form';
import { debounce } from 'share/utils';
import { styled } from '@material-ui/core/styles';
import { FormContextValues } from 'react-hook-form';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';

type OptionValue = {
  [key: string]: any;
  _id?: string | null;
  name?: string | null;
  label?: string | null;
};

export const StyledIconCircular = styled(CircularProgress)({
  verticalAlign: 'middle',
});

type OptionExtend<TOption> = TOption & OptionValue;

type Props<TOption> = {
  options: OptionExtend<TOption>[];
  getOptionDisabled?: OptionExtend<TOption>[];
  control?: Control;
  nameOption: 'name' | 'label';
  name?: string;
  label: string;
  multiple?: boolean;
  small?: boolean;
  defaultValue?: TOption | TOption[] | null;
  callbackChangeValue?: (event: any, newValue: any) => void;
  callbackRenderOption?: (
    option: OptionExtend<TOption>,
    selected: boolean,
  ) => JSX.Element;
  callbackRenderTags?: (
    tagValue: OptionExtend<TOption>[],
    getTagProps: AutocompleteGetTagProps,
  ) => React.ReactNode[];
  error?: boolean;
  required?: boolean;
  callbackHandleText?: (text: string) => void;
  loadList?: boolean;
  freeSolo?: boolean;
  disableCloseOnSelect?: boolean;
  hasOptionsTextNull?: boolean;
  disabled?: boolean;
  placeholder?: string;
  limitTags?: number;
  disablePortal?: boolean;
  defaultOption?: OptionExtend<TOption>;
  idOption?: string;
  methods?: FormContextValues<any>;
};

export const AutoCompleteLoadData = <TOption extends object>({
  options,
  getOptionDisabled,
  control,
  nameOption,
  name,
  label,
  multiple,
  small,
  defaultValue,
  callbackChangeValue,
  callbackRenderOption,
  callbackRenderTags,
  error,
  required,
  callbackHandleText,
  freeSolo,
  disableCloseOnSelect,
  disabled,
  loadList,
  placeholder,
  limitTags,
  disablePortal,
  defaultOption,
  idOption,
  methods,
}: Props<TOption>) => {
  const [isNullOptions, setIsNullOptions] = useState(false);
  const [noOptionsTextInit, setNoOptionsTextInit] = useState('No result');
  const [isGettingDefault, setIsGettingDefault] = useState(false);
  const isSettingDefaultOptions = useRef<boolean>(true);
  const defaultOptions = useRef<OptionExtend<TOption>[]>([]);
  const changeValueAutoComplete = (event: any, newValue: any) => {
    if (callbackChangeValue) {
      if (!newValue || newValue.length === 0) {
        //get default value when no value on autocomplete input
        setIsGettingDefault(true);
      }
      callbackChangeValue(event, newValue);
    }
  };

  const renderOption = useCallback(
    (option: OptionExtend<TOption>, selected: boolean) => {
      if (callbackRenderOption) {
        return callbackRenderOption(option, selected);
      } else {
        return option[nameOption];
      }
    },
    [callbackRenderOption, nameOption],
  );

  const handleTextInput = (text: string) => {
    isSettingDefaultOptions.current = false;
    if (!multiple && name) {
      methods?.setValue(name, null);
    }
    if (text === '') {
      setIsNullOptions(false);
      setIsGettingDefault(true);
    } else {
      if (text.length < 3) {
        setNoOptionsTextInit('Type at least 3 characters');
        return;
      }
      setIsGettingDefault(false);
      setIsNullOptions(false);
      setNoOptionsTextInit('No result');
      if (callbackHandleText) {
        callbackHandleText(text);
      }
    }
  };

  const debounceOnChange = useCallback(debounce(400, handleTextInput), []);

  const renderTags = useCallback(
    (
      tagValue: OptionExtend<TOption>[],
      getTagProps: AutocompleteGetTagProps,
    ) => {
      if (callbackRenderTags) {
        return (
          <Box display="flex" flexWrap="wrap" alignItems="center">
            {callbackRenderTags(tagValue, getTagProps)
              .splice(0, limitTags || 0)
              .map(tags => {
                return tags;
              })}
            {tagValue.length > (limitTags ? limitTags : 2) && (
              <Typography variant="subtitle2">{`+${tagValue.length -
                (limitTags ? limitTags : 2)}`}</Typography>
            )}
          </Box>
        );
      }
    },
    [callbackRenderTags, limitTags],
  );

  const renderOptionNotDuplicate = useMemo(() => {
    const optionNotDuplicate =
      defaultOption && isSettingDefaultOptions.current
        ? [defaultOption, ...options]
        : options;
    return optionNotDuplicate.filter(
      (value, index, self) =>
        index ===
        self.findIndex(t => {
          if (idOption) {
            return t[idOption] === value[idOption];
          }
          return t?._id === value?._id;
        }),
    );
  }, [defaultOption, options, idOption]);
  const listOptions = useMemo(() => {
    if (isNullOptions) {
      return [];
    }
    if (loadList) {
      return [];
    }
    //if input of autocomplete is no value or value of autocomplete is empty array or undefined, set options of autocomplete is init data(default options)
    return isGettingDefault ? defaultOptions.current : renderOptionNotDuplicate;
  }, [loadList, isNullOptions, isGettingDefault, renderOptionNotDuplicate]);
  const CustomizedPopper = useCallback(
    (props: PopperProps) => {
      const style =
        freeSolo && listOptions.length === 0
          ? { display: 'none' }
          : { display: 'block' };
      return <Popper {...props} style={{ ...props.style, ...style }} />;
    },
    [freeSolo, listOptions.length],
  );

  const optionsAutoComplete = useMemo(() => {
    let options: AutocompleteProps<any, any, any, any> = {
      disablePortal: !!disablePortal,
      limitTags: limitTags || 2,
      disabled,
      disableCloseOnSelect: !!disableCloseOnSelect,
      autoHighlight: true,
      freeSolo: !!freeSolo,
      options: listOptions,
      loading: loadList,
      noOptionsText: noOptionsTextInit,
      getOptionDisabled: (option: any) =>
        !!getOptionDisabled?.includes(option as OptionExtend<TOption>),
      getOptionLabel: (option: any) =>
        (option as OptionExtend<TOption>)[nameOption] || '',
      renderOption: (option: any, optionMore: { selected: boolean }) => (
        <Box display="flex" alignItems="center">
          <Typography variant="body1">
            {renderOption(option, optionMore.selected)}
          </Typography>
          {option.forOSCProcedures && (
            <Tooltip title="OSC Procedures Treatment">
              <AssignmentLateIcon
                fontSize="small"
                color="primary"
                style={{ marginLeft: '5px', marginTop: '2px' }}
              />
            </Tooltip>
          )}
        </Box>
      ),
      filterOptions: (options: any) => options,
      renderInput: (params: AutocompleteRenderInputParams) => (
        <TextField
          required={required}
          InputLabelProps={{ shrink: true }}
          {...{
            InputProps: params.InputProps,
            id: params.id,
            disabled: params.disabled,
            inputProps: params.inputProps,
          }}
          size={small ? 'small' : 'medium'}
          label={label}
          variant="outlined"
          fullWidth
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            debounceOnChange(e.target.value)
          }
          onFocus={() => setIsGettingDefault(true)}
          placeholder={placeholder || 'Type at least 3 characters'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadList ? (
                  <StyledIconCircular color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      ),
      PopperComponent: CustomizedPopper,
      getOptionSelected: (option, value) => {
        if (idOption) {
          return option[idOption] === value[idOption];
        }
        if (typeof option === 'string') {
          return option === value;
        }
        return option._id === value._id;
      },
    };
    if (callbackRenderTags) {
      options.renderTags = (
        tagValue: any[],
        getTagProps: AutocompleteGetTagProps,
      ) => {
        return renderTags(tagValue, getTagProps);
      };
    }
    return options;
  }, [
    CustomizedPopper,
    callbackRenderTags,
    debounceOnChange,
    disableCloseOnSelect,
    disablePortal,
    disabled,
    freeSolo,
    getOptionDisabled,
    label,
    limitTags,
    listOptions,
    loadList,
    nameOption,
    noOptionsTextInit,
    renderOption,
    renderTags,
    required,
    small,
    idOption,
    placeholder,
  ]);

  const renderAutoComplete = () => {
    return multiple ? (
      <Autocomplete
        multiple
        value={defaultValue as OptionExtend<TOption>[]}
        size={small ? 'small' : 'medium'}
        onChange={(event: any, newValue: any) =>
          changeValueAutoComplete(event, newValue)
        }
        {...optionsAutoComplete}
      />
    ) : (
      <Autocomplete
        size={small ? 'small' : 'medium'}
        value={defaultValue as OptionExtend<TOption>}
        onChange={(event: any, newValue: any) =>
          changeValueAutoComplete(event, newValue)
        }
        {...optionsAutoComplete}
      />
    );
  };

  useEffect(() => {
    if (isSettingDefaultOptions.current) {
      defaultOptions.current = renderOptionNotDuplicate;
    }
  }, [options, renderOptionNotDuplicate]);

  return (
    <>
      {control ? (
        <Controller
          as={
            <Autocomplete
              {...optionsAutoComplete}
              getOptionDisabled={option => option.disabled}
              multiple={!!multiple}
              renderTags={renderTags}
              renderInput={params => (
                <TextField
                  required={required}
                  InputLabelProps={{ shrink: true }}
                  {...{
                    InputProps: params.InputProps,
                    id: params.id,
                    disabled: params.disabled,
                    inputProps: params.inputProps,
                  }}
                  disabled={!!disabled}
                  placeholder={placeholder || 'Type at least 3 characters'}
                  label={label}
                  size={small ? 'small' : 'medium'}
                  variant="outlined"
                  fullWidth
                  error={error}
                  helperText={error ? 'This field is required' : ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    debounceOnChange(e.target.value)
                  }
                  onFocus={() => setIsGettingDefault(true)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadList ? (
                          <StyledIconCircular color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          }
          name={name || ''}
          onChange={([, data]) => {
            if (!data) {
              //get default value when no value on autocomplete input(apply for react hook form)
              setIsGettingDefault(true);
            }
            return data;
          }}
          control={control}
          rules={{
            validate: {
              required: value => {
                if (required) {
                  return multiple
                    ? (value && value.length !== 0) || 'This field is required'
                    : Boolean(value) || 'This field is required';
                }
                return true;
              },
            },
          }}
          defaultValue={defaultValue}
        />
      ) : (
        renderAutoComplete()
      )}
    </>
  );
};

export default React.memo(AutoCompleteLoadData);
