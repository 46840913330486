import gql from 'graphql-tag';
import {
  TreatmentFragment,
  SubTreatmentFragment,
  MetaFragment,
  ResponseModifyTreatmentFragment,
  TreatmentDetailFragment,
  CheckHightLightFragment,
  TreatmentFromDeleteFragment,
  PathToTreatmentFragment,
  AssignTreatmentResFragment,
  TreatmentTreeFragment,
  ReadabilityScoresFragment,
} from './fragments';
//get treatments in home page for user
export const GET_PUBLIC_TREATMENTS = gql`
  query GetPublicTreatments($params: GetPublicTreatment!) {
    getPublicTreatments(params: $params) {
      nodes {
        ...Treatment
      }
      meta {
        ...Meta
      }
    }
  }
  ${TreatmentFragment}
  ${MetaFragment}
`;

//get treatments in home page for admin and navigator
export const GET_TREATMENTS_HOME_PAGE = gql`
  query GetTreatmentsHomePage($params: GetTreatmentsInput!) {
    getTreatments(params: $params) {
      nodes {
        ...Treatment
      }
      meta {
        ...Meta
      }
    }
  }
  ${TreatmentFragment}
  ${MetaFragment}
`;

//get list assign treatments
export const GET_ASSIGN_TREATMENTS = gql`
  query GetAssignTreatments($params: GetAssignTreatmentInput!) {
    getAssignTreatments(params: $params) {
      nodes {
        ...Treatment
      }
      meta {
        ...Meta
      }
    }
  }
  ${TreatmentFragment}
  ${MetaFragment}
`;

//get data for treatment detail page
export const GET_DATA_DETAIL_TREATMENT = gql`
  query GetDataDetailTreatment($paramsDetail: GetTreatmentInput!) {
    getTreatment(params: $paramsDetail) {
      ...TreatmentDetail
    }
    checkHightLight {
      ...CheckHightLight
    }
  }
  ${TreatmentDetailFragment}
  ${CheckHightLightFragment}
`;

export const GET_PATH_TO_TREATMENT = gql`
  query GetPathToTreatment($params: IdInputType!) {
    getPathToTreatment(params: $params) {
      ...PathToTreatment
    }
  }
  ${PathToTreatmentFragment}
`;

export const GET_TREEATMENT_PDF = gql`
  query GetTreatmentPDF($params: GetTreatmentPDFInput!) {
    getTreatmentPDF(params: $params)
  }
`;

//get treatment for sub treatment page
export const GET_SUB_TREATMENT = gql`
  query GetSubTreatment($params: GetTreatmentInput!) {
    getTreatment(params: $params) {
      _id
      name
      subSections {
        ...SubTreatment
      }
    }
  }
  ${SubTreatmentFragment}
`;

export const GET_TREATMENT_PARENT = gql`
  query GetTreatmentParent($params: GetTreatmentsForUpdateInput!) {
    getTreatmentsForUpdate(params: $params) {
      nodes {
        _id
        name
        type
        owner {
          idOwner: _id
        }
      }
    }
  }
`;

export const CREATE_TREATMENT = gql`
  mutation CreateTreatment($params: CreateTreatmentInput!) {
    createTreatment(params: $params) {
      ...ResponseModifyTreatment
      qrCode
      urlSharing
      isDefault
    }
  }
  ${ResponseModifyTreatmentFragment}
`;

export const UPDATE_TREATMENT_INFO = gql`
  mutation UpdateTreatmentInfo($params: UpdateTreatmentInfoInput!) {
    updateTreatmentInfo(params: $params) {
      ...ResponseModifyTreatment
      qrCode
      urlSharing
      isDefault
    }
  }
  ${ResponseModifyTreatmentFragment}
`;

export const DELETE_TREATMENT = gql`
  mutation DeleteTreatment($params: IdInputType!) {
    deleteTreatment(params: $params) {
      status
      data {
        ...TreatmentFromDelete
      }
    }
  }
  ${TreatmentFromDeleteFragment}
`;

//get sub treatment for deleted treatment when this treatment has sub and survey
export const GET_SUB_TREATMENT_FOR_DELETE = gql`
  query GetSubTreatmentForDelete($params: GetTreatmentInput!) {
    getTreatment(params: $params) {
      _id
      subSections {
        _id
        name
        type
      }
      survey {
        idSurvey: _id
        name
        type
      }
    }
  }
`;

//update multiple treatment
export const BULK_UPDATE_TREATMENT = gql`
  mutation BulkUpdateTreatment($params: [UpdateTreatmentTreeInput!]!) {
    bulkUpdateTreatmentTree(params: $params)
  }
`;

//sync treatment assign
export const TREATMENT_ASSIGN_SYNC = gql`
  mutation TreatmentAssignSync($params: [AssignTreatmentInput!]!) {
    syncAssignTreatment(params: $params)
  }
`;

export const ASSIGN_TREATMENT = gql`
  mutation AssignTreatment($params: AssignInput!) {
    assignTreatment(params: $params) {
      ...AssignTreatmentRes
    }
  }
  ${AssignTreatmentResFragment}
`;

export const QRCODE_CREATE = gql`
  mutation QrcodeCreate($params: IdInputType!) {
    createQRCodeTreatment(params: $params) {
      ...TreatmentTree
    }
  }
  ${TreatmentTreeFragment}
`;

export const INVITE_CONTRIBUTOR = gql`
  mutation InviteContributor($params: InviteContributorInput!) {
    inviteContributor(params: $params)
  }
`;

export const GET_CONTRIBUTORS_OF_TREATMENT = gql`
  query GetContributors($params: GetTreatmentInput!) {
    getTreatment(params: $params) {
      _id
      contributors {
        firstName
        lastName
        middleName
        email
        idUser: _id
      }
    }
  }
`;

export const GET_NAVIGATORS_TO_INVITE = gql`
  query GetNavigatorsToInvite($params: GetNavigatorsInput!) {
    getNavigatorsToInvite(params: $params) {
      nodes {
        _id
        email
        firstName
        middleName
        lastName
        status
        role
      }
    }
  }
`;

export const SET_OWNERSHIP_TO_CONTRIBUTOR = gql`
  mutation SetOwnershipToContributor($params: TakeOwnershipInput!) {
    takeOwnership(params: $params)
  }
`;

export const REMOVE_CONTRIBUTOR = gql`
  mutation RemoveContributor($params: TakeOwnershipInput!) {
    removeContributor(params: $params)
  }
`;

export const CHECK_HIGHLIGHT = gql`
  query CheckHightLight {
    checkHightLight {
      ...CheckHightLight
    }
  }
  ${CheckHightLightFragment}
`;

//get assign treatments on side bar
export const GET_ASSIGN_TREATMENTS_SIDE_BAR = gql`
  query GetAssignTreatmentsSideBar(
    $params: GetAssignTreatmentInput! = { page: 1 }
  ) {
    getAssignTreatments(params: $params) {
      nodes {
        _id
        name
        label
        subSections {
          _id
          name
          label
          subSectionIds
          order
          createdAt
          slug
          parentId
        }
        subSectionIds
        order
        createdAt
        slug
      }
    }
  }
`;

export const GET_SUB_TREATMENT_SIDE_BAR = gql`
  query GetSubTreatmentSideBar($params: GetTreatmentInput!) {
    getTreatment(params: $params) {
      _id
      subSections {
        _id
        name
        label
        subSectionIds
        order
        parentId
        createdAt
      }
    }
  }
`;

export const GET_ANALYZE_CONTENT = gql`
  query GetAnalyzeContent($params: AnalyzerInput!) {
    analyzeContent(params: $params) {
      avgAnswerableScore
      avgSearchScore
      questionAnalysis {
        answerableScore
        searchScore
        question
      }
      readabilityscores {
        ...ReadabilityScores
      }
    }
  }
  ${ReadabilityScoresFragment}
`;

export const GET_ANALYZE_WITHOUT_TREATMENT = gql`
  query GetAnalyzeWithoutTreatment(
    $params: AnalyzerInputFromTreatmentDesigner!
  ) {
    analyzewithoutTreatment(params: $params) {
      avgAnswerableScore
      avgSearchScore
      questionAnalysis {
        answerableScore
        searchScore
        question
      }
      readabilityscores {
        ...ReadabilityScores
      }
    }
  }
  ${ReadabilityScoresFragment}
`;
