import React, { useRef, useState, useEffect } from 'react';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { useMutationCustom, useToogleDialog } from 'hooks';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import {
  DeleteDynamicFormDataMutation,
  DeleteDynamicFormDataMutationVariables,
  DynamicFormData,
} from 'types.d';
import DialogDynamicSurveyView from './DialogDynamicSurveyView';
import { useCommonPdf } from 'hooks';
import * as Survey from 'survey-react';
import { HEIGHT_A4, WIDTH_A4 } from 'CONST';
import html2canvas from 'html2canvas';
import { PictureAsPdf } from '@material-ui/icons';
import { BoxWrapSurvey } from 'modules/patients/styles';
import SurveyDisplay from 'modules/patients/components/SurveyDisplay';
import { ButtonDelete } from 'components';
import { DELETE_DYNAMIC_FORM_DATA } from 'gql/server/pdf_conversions';

type Props = {
  item: DynamicFormData;
  callbackDelete: () => void;
};

export const RowSurvey: React.FC<Props> = ({ item, callbackDelete }) => {
  const surveyName = JSON.parse(item.formJson).title;

  const [openDialogSurveyView, setOpenDialogSurveyView] = useToogleDialog();
  const [survey, setSurvey] = useState<Survey.ReactSurveyModel | undefined>(
    undefined,
  );

  useEffect(() => {
    handleSurveySave();
  }, []);

  const handleSurveySave = () => {
    const survey = new Survey.Model(JSON.parse(item.formJson));
    survey.data = JSON.parse(item?.answersJson);
    survey.showNavigationButtons = false;
    survey.mode = 'display';
    survey.showCompletedPage = false;
    setSurvey(survey);
  };

  const elementByPage = useRef<{ element: Element; height: number }[][]>([[]]);
  const allPromiseCanvas = useRef<Promise<HTMLCanvasElement>[]>([]);
  const heightPageReal = useRef(0);

  const {
    doc,
    drawLine,
    addSpace,
    setHeight,
    lMargin,
    rMargin,
    tMargin,
    getHeight,
  } = useCommonPdf();

  const setCssForLabel = () => {
    doc.setFont('WorkSans', 'bold');
    doc.setFontSize(13);
    doc.setTextColor(89, 89, 89);
  };

  const getSvRow = (element: HTMLElement, svRowItems?: Element[]) => {
    const allItems = svRowItems || [];
    Array.from(element.children).forEach(item => {
      if (item?.className === 'sv_row') {
        allItems.push(item);
        return;
      }
      if (item.childNodes.length === 0) {
        return;
      } else {
        getSvRow(item as HTMLElement, allItems);
      }
    });
    return allItems;
  };

  const getElement = (element: HTMLElement, startHeight?: number) => {
    const svRowItems = getSvRow(element);
    const heightOfPage = startHeight || getHeight();
    heightPageReal.current = Number(
      (
        ((element?.offsetWidth as number) *
          (HEIGHT_A4 - tMargin - heightOfPage)) /
        (WIDTH_A4 - lMargin - rMargin)
      ).toFixed(2),
    );
    svRowItems.forEach(item => {
      const heightElem =
        elementByPage.current[elementByPage.current.length - 1].length === 0
          ? Number(item.getBoundingClientRect().height.toFixed(2))
          : Number(
              (
                elementByPage.current[elementByPage.current.length - 1][
                  elementByPage.current[elementByPage.current.length - 1]
                    .length - 1
                ].height +
                Number(item.getBoundingClientRect().height.toFixed(2))
              ).toFixed(2),
            );
      if (heightElem <= heightPageReal.current) {
        elementByPage.current[elementByPage.current.length - 1].push({
          element: item,
          height: heightElem,
        });
      } else {
        heightPageReal.current = heightPageReal.current + 100;
        elementByPage.current.push([
          {
            element: item,
            height: Number(item.getBoundingClientRect().height.toFixed(2)),
          },
        ]);
      }
    });
  };

  const isAddingPageTable = () => {
    if (getHeight() + 40 > HEIGHT_A4 - tMargin) {
      return true;
    }
    return false;
  };

  const handleCheckAddPage = () => {
    if (isAddingPageTable()) {
      doc.addPage();
      setHeight(tMargin);
    } else {
      addSpace(5);
      drawLine();
      addSpace(8);
    }
  };

  const drawSurvey = async (element: HTMLElement | null, name?: string) => {
    if (element) {
      window.scrollTo(0, 0);
      handleCheckAddPage();
      setCssForLabel();
      const textSurveyName = doc.splitTextToSize(
        surveyName,
        WIDTH_A4 - lMargin - rMargin,
      );
      doc.text(
        doc.splitTextToSize(
          name || textSurveyName,
          WIDTH_A4 - lMargin - rMargin,
        ),
        lMargin,
        getHeight(),
      );
      setHeight(getHeight() + doc.getTextDimensions(textSurveyName).h + 2);
      const heightBeforeGetElement = getHeight();
      const imgTag = Array.from(
        (element as HTMLElement).querySelectorAll('img'),
      );
      imgTag.forEach(elem => {
        elem.setAttribute('crossOrigin', 'anonymous');
        elem.style.maxWidth = '100%';
      });
      getElement(element as HTMLElement);
      setHeight(heightBeforeGetElement);
      const elementGenerate = document.getElementById('generate-question');
      if (elementGenerate) {
        elementByPage.current.forEach(item => {
          item.forEach(question => {
            elementGenerate.appendChild(question.element);
          });

          // Ensure the element has a valid height before capturing
          if (elementGenerate.offsetHeight > 0) {
            const promiseCanvas = html2canvas(elementGenerate, {
              useCORS: true,
              allowTaint: true,
            });
            allPromiseCanvas.current.push(promiseCanvas);
          } else {
            console.error('Element has zero height and cannot be captured.');
          }

          elementGenerate.innerHTML = '';
        });

        await Promise.all(allPromiseCanvas.current).then(items => {
          items.forEach((item, index) => {
            if (index === 0) {
              doc.addImage(
                item,
                'JPEG',
                lMargin,
                getHeight(),
                WIDTH_A4 - lMargin - rMargin,
                (item.height * (WIDTH_A4 - lMargin - rMargin)) / item.width,
              );
            } else {
              setHeight(tMargin);
              doc.addPage();
              doc.addImage(
                item,
                'JPEG',
                lMargin,
                tMargin,
                WIDTH_A4 - lMargin - rMargin,
                (item.height * (WIDTH_A4 - lMargin - rMargin)) / item.width,
              );
            }
            if (index === items.length - 1) {
              setHeight(
                getHeight() +
                  (item.height * (WIDTH_A4 - lMargin - rMargin)) / item.width,
              );
            }
          });
        });
        elementByPage.current = [[]];
        allPromiseCanvas.current = [];
      }
    }
  };

  const createPdf = async () => {
    // Reset state for new PDF generation
    elementByPage.current = [[]];
    allPromiseCanvas.current = [];
    heightPageReal.current = 0;
    doc.setFont('WorkSans', 'normal');
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    setHeight(tMargin);

    await drawSurvey(document.getElementById('main-survey'));
    doc.save(surveyName);
  };

  const [deleteForm, { loading: loadingDelete }] = useMutationCustom<
    DeleteDynamicFormDataMutation,
    DeleteDynamicFormDataMutationVariables
  >({
    api: DELETE_DYNAMIC_FORM_DATA,
    textSuccess: 'Form data successfully deleted',
    callbackSuccess: data => {
      callbackDelete();
    },
  });

  const handleDeleteFollowUpType = (key: string) => {
    deleteForm({
      variables: {
        params: {
          formId: item._id,
        },
      },
    });
  };

  return (
    <>
      {openDialogSurveyView && (
        <DialogDynamicSurveyView
          open={openDialogSurveyView}
          toogleDialog={setOpenDialogSurveyView}
          item={item}
        />
      )}

      {/* <IconButton color="primary" onClick={createPdf}>
        <PictureAsPdf />
      </IconButton> */}

      <Tooltip title="View" arrow>
        <IconButton
          onClick={() => setOpenDialogSurveyView(true)}
          color="primary"
        >
          <RemoveRedEyeIcon />
        </IconButton>
      </Tooltip>

      <ButtonDelete
        loadingDelete={loadingDelete}
        deleteItem={handleDeleteFollowUpType}
        id={item?._id}
      />

      {survey && (
        <div id="main-survey" style={{ display: 'none' }}>
          <SurveyDisplay survey={survey} />
        </div>
      )}

      <BoxWrapSurvey zIndex="-1" width="100%">
        <Box className="sv_main sv_default_css">
          <Box className="sv_container">
            <Box id="sp_100" className="sv_body">
              <Box className="sv_p_root" style={{ textAlign: 'left' }}>
                <Box id="generate-question"></Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </BoxWrapSurvey>
    </>
  );
};

export default React.memo(RowSurvey);
