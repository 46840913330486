/* eslint-disable no-loop-func */
import React, { useState, useContext, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import * as Survey from 'survey-react';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  GetPatientChecklistQuery,
  GetPatientChecklistQueryVariables,
  UpdateChecklistResultMutation,
  UpdateChecklistResultMutationVariables,
  ChecklistStatus,
  ResultChecklist,
  GetLatestChecklistDataQuery,
  GetLatestChecklistDataQueryVariables,
} from 'types.d';
import {
  GET_PATIENT_CHECKLIST,
  UPDATE_CHECKLIST_RESULT,
  GET_PATIENT_CHECKLISTS,
  GET_LATEST_CHECKLIST_DATA,
} from 'gql';
import { ButtonLoading, DialogButton, DialogTitleClose } from 'components';
import SurveyDisplay from '../SurveyDisplay';
import Skeleton from '@material-ui/lab/Skeleton';
import { useMutationCustom } from 'hooks';
import { PaperBorder, TypographyBold } from 'share/component_css';
import { PatientDetailContext } from 'share/context';
import { COLOR_STATUS_CHECKLIST } from 'CONST';
import FollowUp from '../FollowUp';
import Note from '../Note';
import Surveys from '../Survey';
import { useServices } from '../../services';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useSnackbar } from 'notistack';
import { formatDate } from 'share/utils';

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
  checklistId?: string;
  patientChecklistId?: string;
  result?: ResultChecklist;
  latestChecklistId: string;
  checklistName: string;
};

export const DialogResultChecklist: React.FC<Props> = ({
  open,
  toogleDialog,
  patientChecklistId,
  checklistId,
  result,
  latestChecklistId,
  checklistName,
}) => {
  const patientDetailContext = useContext(PatientDetailContext);

  const { enqueueSnackbar } = useSnackbar();

  const { variablesChecklistTag } = useServices();

  const currentStatus = useRef('');

  const [tabIndex, setTabIndex] = useState(0);

  const [survey, setSurvey] = useState<Survey.ReactSurveyModel | undefined>(
    undefined,
  );

  const [status, setStatus] = useState('');

  const [
    updateChecklistResult,
    { loading: loadingUpdateChecklistResult },
  ] = useMutationCustom<
    UpdateChecklistResultMutation,
    UpdateChecklistResultMutationVariables
  >({
    refetchQueries: [
      {
        query: GET_PATIENT_CHECKLISTS,
        variables: variablesChecklistTag,
      },
    ],
    api: UPDATE_CHECKLIST_RESULT,
    textSuccess: 'Update checklist result sucessfully',
    callbackSuccess: data => {
      toogleDialog(false);
    },
  });

  const [
    getLatestChecklistData,
    { loading: loadGetChecklistValue },
  ] = useLazyQuery<
    GetLatestChecklistDataQuery,
    GetLatestChecklistDataQueryVariables
  >(GET_LATEST_CHECKLIST_DATA, {
    fetchPolicy: 'cache-and-network',

    onCompleted: data => {
      enqueueSnackbar('Checklist has been updated with the latest values', {
        variant: 'success',
      });
      if (survey && data?.getLatestChecklistData?.length !== 0) {
        const patientChecklist = data.getLatestChecklistData[0];
        survey.data = patientChecklist?.checklistResult;
      }
    },
  });

  const [getChecklistSurvey, { loading: loadChecklist }] = useLazyQuery<
    GetPatientChecklistQuery,
    GetPatientChecklistQueryVariables
  >(GET_PATIENT_CHECKLIST, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const patientChecklist = data.getPatientChecklist?.patientChecklists;
      const surveyData =
        patientChecklist?.checklist?.surveyData ||
        patientChecklist?.checklistAvailable?.surveyData;
      const survey = new Survey.Model(surveyData);
      survey.completedHtml = '<h3>Thank you for completing the survey!</h3>';
      survey.data =
        result?.checklistResult || patientChecklist?.checklistResult;
      survey.showNavigationButtons = false;
      setSurvey(survey);
      if (result) {
        setStatus(result.status);
        currentStatus.current = result.status;
      }
    },
  });

  const onEnter = () => {
    getChecklistSurvey({
      variables: {
        params: {
          patientChecklistId: checklistId as string,
          checklistResultId: patientChecklistId,
        },
      },
    });
  };

  const getLatestChecklistValue = () => {
    getLatestChecklistData({
      variables: {
        params: {
          checklistId: latestChecklistId,
          patientId: patientDetailContext?.patient?._id,
          resultChecklistId: result ? result?._id : patientChecklistId,
        },
      },
    });
  };

  const handleUpdateChecklistResult = () => {
    if (survey) {
      updateChecklistResult({
        variables: {
          params: {
            patientId: patientDetailContext?.patient.owner?.idOwner,
            checklistResult: survey.data,
            checklistResultId: patientChecklistId,
            status: status as ChecklistStatus,
            _id: checklistId,
            resultId: result ? result?._id : patientChecklistId,
          },
        },
      });
    }
  };

  const onChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Dialog onEnter={onEnter} open={open} maxWidth="md" fullWidth>
        <DialogTitleClose
          title="Update Checklist Result"
          onClose={() => toogleDialog(false)}
        />
        <DialogContent>
          <PaperBorder square>
            <Tabs
              indicatorColor="primary"
              value={tabIndex}
              onChange={onChangeTab}
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab className="text-tranform-none" label="Checklist" />
              <Tab className="text-tranform-none" label="Tasks" />
              <Tab className="text-tranform-none" label="Notes" />
              <Tab className="text-tranform-none" label="Surveys" />
            </Tabs>
          </PaperBorder>
          <Grid container spacing={2} className="mt-8">
            {Object.is(tabIndex, 0) && (
              <>
                {!loadChecklist && survey && (
                  <>
                    <Grid item xs={12}>
                      <TypographyBold variant="h6">Status</TypographyBold>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <RadioGroup
                          aria-label="position"
                          name="position"
                          value={status}
                          onChange={(e, value) => setStatus(value)}
                          row
                        >
                          {Object.keys(COLOR_STATUS_CHECKLIST).map(item => {
                            return (
                              <FormControlLabel
                                key={item}
                                disabled={
                                  (item === ChecklistStatus.NotYetStarted &&
                                    currentStatus.current !==
                                      ChecklistStatus.NotYetStarted) ||
                                  patientDetailContext?.isInactive
                                }
                                value={item}
                                control={<Radio color="primary" />}
                                label={COLOR_STATUS_CHECKLIST[item].text}
                              />
                            );
                          })}
                        </RadioGroup>
                        <ButtonLoading
                          callbackClick={getLatestChecklistValue}
                          Icon={<BorderColorIcon />}
                          text="Prior"
                          color="primary"
                          loading={loadGetChecklistValue}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center" className="mt-8">
                        <TypographyBold variant="h6">
                          {checklistName}
                        </TypographyBold>
                        <TypographyBold variant="h6">
                          - {formatDate(result?.createdAt)}
                        </TypographyBold>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <SurveyDisplay survey={survey} />
                    </Grid>
                  </>
                )}
              </>
            )}
            <Grid item xs={12} className="mt-8">
              {Object.is(tabIndex, 1) && <FollowUp tabIndexDialog={tabIndex} />}
              {Object.is(tabIndex, 2) && <Note />}
              {Object.is(tabIndex, 3) && <Surveys />}
            </Grid>
          </Grid>

          {loadChecklist && (
            <Skeleton variant="rect" width="100%" height={300} />
          )}
        </DialogContent>
        <DialogActions>
          <DialogButton isCancel onClickButton={() => toogleDialog(false)} />
          {Object.is(tabIndex, 0) && !patientDetailContext?.isInactive && (
            <DialogButton
              loading={loadingUpdateChecklistResult}
              onClickButton={handleUpdateChecklistResult}
            />
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(DialogResultChecklist);
