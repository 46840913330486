import { styled, Theme } from '@material-ui/core/styles';
import {
  Box,
  Popover,
  FormControlLabel,
  Typography,
  TableContainer,
  TextField,
  CardHeader,
  Grid,
  IconButton,
  FormControl,
} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import EditIcon from '@material-ui/icons/Edit';

export const FormControlStyle = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 120,
}));

export const IconButtonStyled = styled(IconButton)({
  '& .MuiSvgIcon-root': {
    fontSize: '1.2rem',
  },
});

export const CardHeaderStyled = styled(CardHeader)(({ theme }) => ({
  '& .MuiCardHeader-title': {
    fontSize: '1.2rem',
  },
  paddingBottom: 0,
}));

export const TextFieldChartStyled = styled(TextField)(({ theme }) => ({
  width: theme.spacing(15),
}));

export const TableChartContainerStyled = styled(TableContainer)({
  maxHeight: '400px',
  '& .MuiTable-root': {
    minWidth: 0,
  },
});

export const TypographyTitleStyled = styled(Typography)({
  fontSize: '1.12rem',
});

export const GridWrapDashboard = styled(Grid)(
  ({ isedit }: { isedit: string }) => ({
    overflow: 'auto',
    maxHeight: Object.is(isedit, 'true') ? 'calc(100vh - 372px)' : 'auto',
  }),
);

export const GridCustomBorder = styled(Grid)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.grey[300]}`,
}));

export const FormControlLabelStyled = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    display: 'contents',
  },
});

export const PopoverStyled = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper ': {
    overflow: 'visible',
  },
}));

export const WrapDate = styled(Box)(
  ({ theme, error }: { theme: Theme; error?: string }) => ({
    '& .MuiPickersDay-current': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[900],
    },
    '& .selected': {
      '& .MuiPickersDay-daySelected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.grey[50],
      },
    },
    '& .MuiFormLabel-root': {
      color: error === 'true' ? '#f44336' : grey[500],
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: error === 'true' ? '#f44336' : grey[500],
    },
  }),
);

export const TypoEditedNameReport = styled(Typography)({
  display: 'inline',
  verticalAlign: 'middle',
});

export const EditIconStyled = styled(EditIcon)({
  display: 'inline',
  verticalAlign: 'middle',
});

export const Sector = styled('div')(
  ({ align }: { align: 'left' | 'right' }) => ({
    width: '100%',
    overflow: 'hidden',
    height: '100%',
    lineHeight: 'normal',
    textAlign: align,
    wordBreak: 'break-word',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  }),
);
