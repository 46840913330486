import gql from 'graphql-tag';
import { SaveRecordingFragment } from './fragments';

export const SHOW_SUB_TREATMENTS_SIDE_BAR = gql`
  query ShowSubTreatmentsSideBar {
    showSubTreatmentsSideBar @client
  }
`;
export const TOOGLE_SHOW_SUB_SIDE_BAR = gql`
  mutation ToogleShowSubTreatmentsSideBar($input: statusShowSubSideBar) {
    toogleShowSubTreatmentsSideBar(input: $input) @client
  }
`;

export const SHOW_STATUS_HASING_RECORD = gql`
  query ShowStatusHasingRecord {
    showStatusHasingRecord @client
  }
`;

export const TOOGLE_STATUS_HASING_RECORD = gql`
  mutation ToogleStatusHasingRecord($input: statusRecord) {
    toogleStatusHasingRecord(input: $input) @client
  }
`;

export const SHOW_SAVE_RECORDING = gql`
  query ShowSaveRecording {
    showSaveRecording @client {
      ...SaveRecording
    }
  }
  ${SaveRecordingFragment}
`;

export const TOOGLE_SAVE_RECORDING = gql`
  mutation ToogleSaveRecording($input: inputRecording) {
    toogleSaveRecording(input: $input) @client {
      ...SaveRecording
    }
  }
  ${SaveRecordingFragment}
`;

export const SHOWING_TREATMENTS_SIDEBAR = gql`
  query ShowingTreatmentsSidebar {
    showingTreatmentsSidebar @client
  }
`;
export const ADD_SHOWING_TREATMENT_SIDEBAR = gql`
  mutation AddShowingTreatmentSidebar($input: String) {
    addShowingTreatmentSidebar(input: $input) @client
  }
`;

export const SHOWING_COMMENT_FROM = gql`
  query ShowingCommentFrom {
    showingCommentFrom @client
  }
`;
export const TOOGLE_SHOW_COMMENT_FROM = gql`
  mutation ToogleShowCommentFrom($input: Boolean) {
    toogleShowCommentFrom(input: $input) @client
  }
`;

export const SHOWING_MENU_ADMINISTRATION = gql`
  query ShowingMenuAdministration {
    showingMenuAdministration @client
  }
`;
export const TOOGLE_SHOWING_MENU_ADMINISTRATION = gql`
  mutation ToogleShowMenuAdministration($input: Boolean) {
    toogleShowMenuAdministration(input: $input) @client
  }
`;
export const SHOWING_MENU_SETTING = gql`
  query ShowingMenuSetting {
    showingMenuSetting @client
  }
`;
export const TOOGLE_SHOWING_MENU_SETTING = gql`
  mutation ToogleShowMenuSetting($input: Boolean) {
    toogleShowMenuSetting(input: $input) @client
  }
`;
export const SHOWING_QUICK_VIEW = gql`
  query ShowingQuickView {
    showingQuickView @client
  }
`;
export const TOOGLE_SHOWING_QUICK_VIEW = gql`
  mutation ToogleShowQuickView($input: Boolean) {
    toogleShowQuickView(input: $input) @client
  }
`;

export const IS_OPEN_DIALOG_STOP_TIME_RECORD = gql`
  query IsOpenDialogStopTimeRecord {
    isOpenDialogStopTimeRecord @client
  }
`;
export const TOOGLE_IS_OPEN_DIALOG_STOP_TIME_RECORD = gql`
  mutation ToogleIsOpenDialogStopTimeRecord(
    $input: inputOpenDialogStopTimeRecord
  ) {
    toogleIsOpenDialogStopTimeRecord(input: $input) @client
  }
`;
