import React from 'react';
import Box from '@material-ui/core/Box';
import { FailedIcon, TypographyBold } from 'share/component_css';
import Typography from '@material-ui/core/Typography';

type Props = {
  text?: React.ReactNode;
  component?: React.ReactElement;
  title?: string;
};
export const FailedLayout: React.FC<Props> = ({ text, component, title }) => {
  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      <FailedIcon />
      <TypographyBold align="center" variant="h6">
        {title || 'Failed!'}
      </TypographyBold>
      {text && (
        <Typography className="mt-16" align="center">
          {text}
        </Typography>
      )}
      {component}
    </Box>
  );
};
