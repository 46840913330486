import React from 'react';
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from '@material-ui/core';
import { PaperBorder, TableFullWidthStyled } from 'share/component_css';
import { GetSurveyResultTaskQuery } from 'types.d';
import { formatDate } from 'share/utils';
import { NoDataTable } from 'components';

type Props = {
  tasks?: GetSurveyResultTaskQuery['getPatientFollowUps'];
  checkedAll: boolean;
  checkAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkItem: (event: React.ChangeEvent<HTMLInputElement>, item: string) => void;
  checkedList: string[];
};

export const TableTask: React.FC<Props> = ({
  tasks,
  checkedAll,
  checkAll,
  checkItem,
  checkedList,
}) => {
  return (
    <>
      <TableContainer component={PaperBorder}>
        <TableFullWidthStyled stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Checkbox
                  checked={checkedAll}
                  onChange={checkAll}
                  color="primary"
                />
              </TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">Task Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks?.nodes?.length === 0 && <NoDataTable colSpan={3} />}
            {tasks?.nodes?.map(item => {
              return (
                <TableRow key={item._id}>
                  <TableCell align="left">
                    <Checkbox
                      onChange={e => checkItem(e, item._id)}
                      color="primary"
                      checked={checkedList.includes(item._id)}
                    />
                  </TableCell>
                  <TableCell align="left">{item.note}</TableCell>
                  <TableCell align="left">
                    {formatDate(item.followUpDate)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </TableFullWidthStyled>
      </TableContainer>
    </>
  );
};
export default React.memo(TableTask);
