import { styled, Theme } from '@material-ui/core/styles';
import { Avatar, Box } from '@material-ui/core';

export const AvatarListStyled = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(4.5),
  height: theme.spacing(4.5),
}));

export const AvatarStyled = styled(Avatar)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
}));

export const BoxWrapTreatment = styled(Box)(
  ({ theme, selected }: { theme: Theme; selected: string }) => ({
    backgroundColor: Object.is(selected, 'isSelected')
      ? theme.palette.primary.light
      : theme.palette.background.paper,
    color: Object.is(selected, 'isSelected')
      ? theme.palette.common.white
      : theme.palette.common.black,
    '&:hover': {
      backgroundColor: Object.is(selected, 'isSelected')
        ? theme.palette.primary.light
        : theme.palette.grey[200],
      '& .MuiTypography-colorPrimary': {
        color: theme.palette.common.black,
      },
    },
  }),
);
