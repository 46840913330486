import React, { useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  RadioGroup,
  Radio,
  DialogActions,
} from '@material-ui/core';
import { ButtonLoading, DialogTitleClose } from 'components';
import EditIcon from '@material-ui/icons/Edit';
import {
  PaperBorder,
  TableCellMaxWidthStyled,
  TableFullWidthStyled,
  TypographyItalic,
  TypoThreeDot,
} from 'share/component_css';
import { SameNewPatientFragment, Gender, DuplicateUserFragment } from 'types.d';
import { formatDoB, formatPhoneNumber, isBotMail } from 'share/utils';
import CheckIcon from '@material-ui/icons/Check';
import { LIST_GENDER } from 'CONST';
import CloseIcon from '@material-ui/icons/Close';

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
  patients: SameNewPatientFragment[] | null;
  sameUsers: DuplicateUserFragment[] | null;
  updatePatient: () => void;
  createPatient: () => void;
  callbackCancel: () => void;
  setUserId: (userId: string) => void;
  userId: string;
  setPatientId: (patientId: string) => void;
  patientId: string;
  loading: boolean;
  updateRejectEmr?: () => void;
  loadingUpdate?: boolean;
};

export const DialogSamePatients: React.FC<Props> = ({
  open,
  toogleDialog,
  patients,
  createPatient,
  callbackCancel,
  setPatientId,
  updatePatient,
  patientId,
  setUserId,
  loading,
  sameUsers,
  userId,
  updateRejectEmr,
  loadingUpdate,
}) => {
  const continueToCreate = () => {
    setPatientId('');
    setUserId('');
    createPatient();
  };

  const isDisable = useMemo(() => {
    if (sameUsers && userId) {
      return false;
    }
    if (patients && patientId) {
      return false;
    }
    return true;
  }, [patientId, patients, sameUsers, userId]);

  const handleUpdateUser = () => {
    sameUsers = null;
    toogleDialog(false);
    updatePatient();
  };

  return (
    <>
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitleClose
          title={sameUsers ? 'Duplicate user info' : 'Duplicate patient info'}
          onClose={callbackCancel}
        />
        <DialogContent>
          <TypographyItalic
            className="mb-4"
            color="textSecondary"
            variant="subtitle2"
          >
            {sameUsers
              ? '* You must choose one user to merge account'
              : '* You can choose one patient to merge account or continue to create patient.'}
          </TypographyItalic>
          <TableContainer component={PaperBorder}>
            <TableFullWidthStyled stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell>DoB</TableCell>
                  {!sameUsers && <TableCell>Gender</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {sameUsers && sameUsers?.length !== 0
                  ? sameUsers.map(item => {
                      return (
                        <TableRow
                          key={item._id}
                          onClick={() => setUserId && setUserId(item._id)}
                        >
                          <TableCell padding="checkbox">
                            <RadioGroup
                              aria-label="position"
                              name="position"
                              value={userId}
                              onChange={(e, value) =>
                                setUserId && setUserId(value)
                              }
                            >
                              <Radio
                                size="small"
                                value={item._id}
                                name="radio-button"
                                color="primary"
                              />
                            </RadioGroup>
                          </TableCell>
                          <TableCell>
                            {`${item.firstName} ${item.middleName || ''} ${
                              item.lastName
                            }`}
                          </TableCell>
                          <TableCellMaxWidthStyled maxwidth="300px">
                            <TypoThreeDot variant="subtitle2">
                              {!isBotMail(item?.email) ? item?.email : 'N/A'}
                            </TypoThreeDot>
                          </TableCellMaxWidthStyled>
                          <TableCell>
                            {item?.phones && item?.phones?.length !== 0
                              ? formatPhoneNumber(item?.phones[0]?.phone)
                              : 'N/A'}
                          </TableCell>
                          <TableCell>{formatDoB(item.dob)}</TableCell>
                        </TableRow>
                      );
                    })
                  : patients?.map(item => {
                      return (
                        <TableRow
                          key={item.idUser}
                          onClick={() => setPatientId(item.idUser)}
                        >
                          <TableCell padding="checkbox">
                            <RadioGroup
                              aria-label="position"
                              name="position"
                              value={patientId}
                              onChange={(e, value) => setPatientId(value)}
                            >
                              <Radio
                                size="small"
                                value={item.idUser}
                                name="radio-button"
                                color="primary"
                              />
                            </RadioGroup>
                          </TableCell>
                          <TableCell>
                            {`${item.firstName} ${item.middleName || ''} ${
                              item.lastName
                            }`}
                          </TableCell>
                          <TableCellMaxWidthStyled maxwidth="300px">
                            <TypoThreeDot variant="subtitle2">
                              {!isBotMail(item?.email) ? item?.email : '-'}
                            </TypoThreeDot>
                          </TableCellMaxWidthStyled>
                          <TableCell>
                            {item?.phones && item?.phones?.length !== 0
                              ? formatPhoneNumber(item?.phones[0]?.phone)
                              : 'N/A'}
                          </TableCell>
                          <TableCell>{formatDoB(item.dob)}</TableCell>
                          <TableCell>
                            {LIST_GENDER[item.gender || Gender.Other]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </TableFullWidthStyled>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          {updateRejectEmr && (
            <ButtonLoading
              Icon={<CloseIcon />}
              loading={loadingUpdate}
              text="Reject"
              callbackClick={updateRejectEmr}
              color="secondary"
            />
          )}
          <ButtonLoading
            disabled={isDisable}
            Icon={<EditIcon />}
            loading={!sameUsers && (patientId ? loading : false)}
            text="Merge account"
            callbackClick={sameUsers ? handleUpdateUser : updatePatient}
            className="mr-8"
          />
          {!sameUsers && (
            <ButtonLoading
              Icon={<CheckIcon />}
              loading={!patientId && loading}
              text="Continue to create"
              callbackClick={continueToCreate}
            />
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(DialogSamePatients);
