import React, { useContext } from 'react';
import {
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Chip,
  CircularProgress,
} from '@material-ui/core';
import {
  CurrentStageFragment,
  ShareStatus,
  ShareAssignTreatmentMutation,
  ShareAssignTreatmentMutationVariables,
  UpdateStageChangedLogMutation,
  SendTreatmentReminderMutation,
  SendTreatmentReminderMutationVariables,
} from 'types.d';
import ResendIcon from '../icons/ResendIcon';
import EditIcon from '@material-ui/icons/Edit';
import { PatientDetailContext } from 'share/context';
import { useMutationCustom, useToogleDialog } from 'hooks';
import { SHARE_ASSIGN_TREATMENT, SEND_TREATMENT_REMINDER } from 'gql';
import DialogUpdateStage from './DialogUpdateStage';
type Props = {
  item: CurrentStageFragment;
};
export const CurrentStage: React.FC<Props> = ({ item }) => {
  const [isOpenDialogStage, toogleDialogStage] = useToogleDialog();

  const patientDetailContext = useContext(PatientDetailContext);

  const [resendAssignTreatment, { loading: loadingResend }] = useMutationCustom<
    ShareAssignTreatmentMutation,
    ShareAssignTreatmentMutationVariables
  >({
    api: SHARE_ASSIGN_TREATMENT,
    textSuccess: 'Resend successfully',
  });

  const [
    sendTreatmentReminder,
    { loading: loadingReminder },
  ] = useMutationCustom<
    SendTreatmentReminderMutation,
    SendTreatmentReminderMutationVariables
  >({
    api: SEND_TREATMENT_REMINDER,
    textSuccess: 'Resend successfully',
  });

  const renderStatus = (status?: string | null) => {
    if (status) {
      return status === ShareStatus.Sending ? (
        <Chip label="Sent" />
      ) : (
        <Chip label="Open" color="primary" />
      );
    }
    return <Chip label="N/A" disabled />;
  };

  const updateStageForRoot = (
    dataUpdate: UpdateStageChangedLogMutation['updateStageChangedLog'],
  ) => {
    // if (dataUpdate?.treatmentStageEmbed) {
    //   const rootTreatmentUpdated = [...rootTreatments];
    //   const indexRootTreatmentUpdated = rootTreatmentUpdated.findIndex(item => {
    //     return item._id === dataUpdateStage.current?._id;
    //   });
    //   rootTreatmentUpdated[indexRootTreatmentUpdated] = {
    //     ...rootTreatmentUpdated[indexRootTreatmentUpdated],
    //     stage: dataUpdate?.treatmentStageEmbed,
    //   };
    //   setRootTreatments(rootTreatmentUpdated);
    // }
  };

  const handleResendAssignTreatment = () => {
    if (patientDetailContext?.patient.owner) {
      const { email, idOwner } = patientDetailContext?.patient.owner;

      if (item.status === 'OPEN') {
        sendTreatmentReminder({
          variables: {
            params: {
              treatmentId: item.assignedTreatment?.idAssignTreatment,
              patientId: idOwner,
            },
          },
        });

        return;
      }
      resendAssignTreatment({
        variables: {
          params: {
            sharedUsers: [
              {
                email,
                id: patientDetailContext?.patient.owner?.user?.idUser,
                sendEmail: true,
              },
            ],
            treatmentId: item.assignedTreatment?.idAssignTreatment,
          },
        },
      });
    }
  };

  return (
    <>
      {isOpenDialogStage && (
        <DialogUpdateStage
          open={isOpenDialogStage}
          toogleDialog={toogleDialogStage}
          dataStage={item}
          updateStageForRoot={updateStageForRoot}
        />
      )}
      <TableRow>
        <TableCell>{item.rootTreatment?.name}</TableCell>
        <TableCell>{item.assignedTreatment?.name}</TableCell>
        <TableCell>{item.stage?.name || 'N/A'}</TableCell>
        <TableCell
          align={!patientDetailContext?.isInactive ? 'inherit' : 'right'}
        >
          {renderStatus(item?.status)}
        </TableCell>
        {!patientDetailContext?.isInactive && (
          <TableCell align="right">
            {item.status && (
              <IconButton onClick={handleResendAssignTreatment}>
                {loadingResend || loadingReminder ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  <ResendIcon isResend={item.status === 'SENDING'} />
                )}
              </IconButton>
            )}
            <Tooltip arrow title="Edit">
              <IconButton
                color="primary"
                onClick={() => toogleDialogStage(true)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

export default React.memo(CurrentStage);
