import React, { useEffect, useState } from 'react';
import { TextField, useMediaQuery } from '@material-ui/core';
import { AI_PLACEHOLDER, AI_PLACEHOLDER_DELAY_BEFORE_RESTART } from 'CONST';
import { useHistory } from 'react-router-dom';
import { styled, useTheme } from '@material-ui/core/styles';

const TextFieldStyled = styled(TextField)(
  ({ isMobile }: { isMobile: boolean }) => ({
    width: isMobile ? '100%' : '25%',
    marginLeft: isMobile ? '0px' : '20px',
    borderColor: 'primary.dark',
  }),
);

type Props = {};

export const Searchbar: React.FC<Props> = ({}) => {
  const history = useHistory();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [aiPlaceholder, setAiPlaceholder] = useState('');

  const [message, setMessage] = useState('');

  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    let typingTimeout: number;
    // Typing Effect
    if (isTyping && aiPlaceholder.length < AI_PLACEHOLDER.length) {
      typingTimeout = setTimeout(() => {
        setAiPlaceholder(AI_PLACEHOLDER.substring(0, aiPlaceholder.length + 1));
      }, 500);
    }

    // Delay before Restarting Typing
    if (!isTyping) {
      typingTimeout = setTimeout(() => {
        setIsTyping(true);
        setAiPlaceholder('');
      }, AI_PLACEHOLDER_DELAY_BEFORE_RESTART);
    }

    // Cleanup
    return () => clearTimeout(typingTimeout);
  }, [aiPlaceholder, isTyping]);

  // Restart Typing
  useEffect(() => {
    if (aiPlaceholder.length === AI_PLACEHOLDER.length) {
      setIsTyping(false);
    }
  }, [aiPlaceholder]);

  const handleKeyPress = (e: { key: string }) => {
    if (e.key === 'Enter') {
      setMessage('');
      history.push('/chatbotsearch?search=' + message);
    }
  };

  return (
    <TextFieldStyled
      isMobile={isMobile}
      variant="outlined"
      placeholder={aiPlaceholder}
      value={message}
      size="small"
      onChange={e => setMessage(e.target.value)}
      onKeyPress={handleKeyPress}
    ></TextFieldStyled>
  );
};

export default React.memo(Searchbar);
