import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Grid, CircularProgress, Box } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import {
  VERIFY_EMAIL_FORGOT_PASSWORD,
  VERIFY_PHONE_FORGOT_PASSWORD,
} from 'gql';
import {
  VerifyEmailForgotPasswordMutation,
  VerifyEmailForgotPasswordMutationVariables,
  VerifyPhoneNumberForgotPasswordMutation,
  VerifyPhoneNumberForgotPasswordMutationVariables,
} from 'types.d';
import { useSnackbar } from 'notistack';
import { handleError } from 'share/utils';
import { useForm } from 'react-hook-form';
import { TypographyCursor, ButtonSizeIcon } from 'share/component_css';
import { styled } from '@material-ui/core/styles';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import EmailPhoneInput from './EmailPhoneInput';

type Props = {
  handleCloseModal?: (status: boolean) => void;
  setTab: React.Dispatch<React.SetStateAction<string>>;
};

const FormStyled = styled('form')(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '100%',
}));

type FormData = {
  email: string;
  phoneNumber: string;
};

export const ForgotPasswordForm: React.FC<Props> = ({
  handleCloseModal,
  setTab,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [verifyEmailForgotPassword, { loading: loadingSend }] = useMutation<
    VerifyEmailForgotPasswordMutation,
    VerifyEmailForgotPasswordMutationVariables
  >(VERIFY_EMAIL_FORGOT_PASSWORD, {
    onCompleted(data) {
      setTab('confirmResetPasswordByEmail');
    },
    onError(error) {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
  });

  const [verifyPhoneForgotPassword, { loading }] = useMutation<
    VerifyPhoneNumberForgotPasswordMutation,
    VerifyPhoneNumberForgotPasswordMutationVariables
  >(VERIFY_PHONE_FORGOT_PASSWORD, {
    onCompleted(data) {
      setTab('confirmResetPasswordByPhone');
    },
    onError(error) {
      const arrError = handleError(error.graphQLErrors[0]!);
      enqueueSnackbar(arrError.join(', '), { variant: 'error' });
    },
  });

  const { handleSubmit, control, errors, register, watch, setValue } = useForm<
    FormData
  >({
    mode: 'onBlur',
  });

  const sendTokenToEmail = handleSubmit(({ email, phoneNumber }) => {
    if (email) {
      verifyEmailForgotPassword({
        variables: { params: { email: email.trim() } },
      });
      return;
    }
    verifyPhoneForgotPassword({
      variables: { params: { phone: `+${phoneNumber}` } },
    });
  });

  const handleSetValue = (value: string, isEmail?: boolean) => {
    if (isEmail) {
      setValue('email', value);
      return;
    }
    setValue('phoneNumber', value);
  };

  return (
    <>
      <FormStyled onSubmit={sendTokenToEmail} noValidate>
        <EmailPhoneInput
          watch={watch}
          handleSetValue={handleSetValue}
          register={register}
          errorsEmail={errors.email}
          errorsPhone={errors.phoneNumber}
          control={control}
        />
        <Grid
          container
          justify="space-between"
          className="mt-16"
          alignItems="center"
        >
          <Grid item>
            <Box
              className="cursor"
              onClick={() => setTab('login')}
              display="flex"
              alignItems="center"
            >
              <KeyboardBackspaceIcon color="primary" className="mr-8" />
              <TypographyCursor variant="subtitle2" color="primary">
                Back to Sign In
              </TypographyCursor>
            </Box>
          </Grid>

          <Grid item>
            <ButtonSizeIcon
              variant="outlined"
              color="primary"
              type="submit"
              disabled={loadingSend || loading}
              startIcon={
                loadingSend || loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <SendIcon />
                )
              }
            >
              Send
            </ButtonSizeIcon>
          </Grid>
        </Grid>
      </FormStyled>
    </>
  );
};

export default ForgotPasswordForm;
