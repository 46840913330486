import React, { useContext } from 'react';
import { Dialog, DialogActions, Box, DialogContent } from '@material-ui/core';
import { styled, Theme } from '@material-ui/core/styles';
import PrintIcon from '@material-ui/icons/Print';

import {
  ChatbotDrawerContext,
  PopoverContext,
  SubcriptionClientContext,
} from 'share/context';
import { TypoThreeDot } from 'share/component_css';

import { ButtonCopyLink, ButtonLoading } from 'components';
import { Logout } from 'mdi-material-ui';

import {
  AddShowingTreatmentSidebarMutation,
  AddShowingTreatmentSidebarMutationVariables,
  ToogleShowSubTreatmentsSideBarMutation,
  ToogleShowSubTreatmentsSideBarMutationVariables,
  ToogleShowCommentFromMutation,
  ToogleShowCommentFromMutationVariables,
  ToogleStatusHasingRecordMutationVariables,
  ToogleStatusHasingRecordMutation,
  StatusRecordStop,
  ShowSaveRecordingQuery,
  ShowSaveRecordingQueryVariables,
} from 'types.d';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  ADD_SHOWING_TREATMENT_SIDEBAR,
  ADD_SUB_TREATMENTS_SIDE_BAR,
  LOG_OUT,
  SHOW_SAVE_RECORDING,
  TOOGLE_SHOW_COMMENT_FROM,
  TOOGLE_SHOW_SUB_SIDE_BAR,
  TOOGLE_STATUS_HASING_RECORD,
} from 'gql';
import { removeLocalStorage, removeSessionStorage } from 'share/utils';
import {
  CONTEXT_TREATMENTS,
  KEY_LOCAL_ASSIGNED_SURVEYS,
  KEY_SESSION_ORGANIZATION_ID,
  ORG_LOGO,
} from 'CONST';
import { useHistory } from 'react-router-dom';
import { useMutationCustom, useUpdateMeClient } from 'hooks';

type Props = {
  open: string;
  setOpenModal: React.Dispatch<React.SetStateAction<string>>;
  link: string;
  urlSharing?: string | null | undefined;
  close?: () => void;
  nameTreatment?: string | null;
  redirectUrl: string | null | undefined;
};

const ViewQrCodeStyled = styled('div')(
  ({ src, theme }: { src: string; theme: Theme }) => ({
    backgroundImage: `url(${src})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.common.black,
  }),
);

const DialogStyled = styled(Dialog)({
  '& .MuiPaper-root': {
    maxWidth: '500px',
  },
});

const DialogContentStyled = styled(DialogContent)({
  padding: '10px 100px',
});

const DialogActionStyled = styled(DialogActions)({
  padding: '24px',
  gap: '8px',
});

const WrapImageQrCode = styled(Box)({
  position: 'relative',
  width: '100%',
  paddingTop: '100%',
});

export const ViewQrCode: React.FC<Props> = ({
  open,
  setOpenModal,
  link,
  urlSharing,
  close,
  nameTreatment,
  redirectUrl,
}) => {
  const history = useHistory();

  const popoverContext = useContext(PopoverContext);

  const subcriptionClientContext = useContext(SubcriptionClientContext);

  const chatbotDrawerContext = useContext(ChatbotDrawerContext);

  const { handleUpdateMeClient } = useUpdateMeClient();

  const [addTreatmentSideBarShow] = useMutation<
    AddShowingTreatmentSidebarMutation,
    AddShowingTreatmentSidebarMutationVariables
  >(ADD_SHOWING_TREATMENT_SIDEBAR);

  const [toogleShowCommentFrom] = useMutation<
    ToogleShowCommentFromMutation,
    ToogleShowCommentFromMutationVariables
  >(TOOGLE_SHOW_COMMENT_FROM);

  const [toogleShowSubSideBar] = useMutation<
    ToogleShowSubTreatmentsSideBarMutation,
    ToogleShowSubTreatmentsSideBarMutationVariables
  >(TOOGLE_SHOW_SUB_SIDE_BAR);

  const [toogleStatusHasingRecord] = useMutation<
    ToogleStatusHasingRecordMutation,
    ToogleStatusHasingRecordMutationVariables
  >(TOOGLE_STATUS_HASING_RECORD);

  const { data: dataShowSaveRecord } = useQuery<
    ShowSaveRecordingQuery,
    ShowSaveRecordingQueryVariables
  >(SHOW_SAVE_RECORDING);

  const [addSubTreatmentsSideBar] = useMutationCustom({
    api: ADD_SUB_TREATMENTS_SIDE_BAR,
  });

  const doLogout = () => {
    handleClose();
    if (dataShowSaveRecord?.showSaveRecording) {
      toogleStatusHasingRecord({
        variables: {
          input: { status: StatusRecordStop.Logout },
        },
      });
      return;
    }
    setTimeout(() => {
      logout();
    }, 1000);
  };

  const [logout, { loading }] = useMutation(LOG_OUT, {
    async onCompleted(data) {
      chatbotDrawerContext?.setMessages([]);
      removeLocalStorage('remoteUser');
      removeLocalStorage(KEY_LOCAL_ASSIGNED_SURVEYS);
      removeLocalStorage(ORG_LOGO);
      removeSessionStorage(KEY_SESSION_ORGANIZATION_ID);
      removeLocalStorage(CONTEXT_TREATMENTS);

      // Close connect ws
      if (subcriptionClientContext?.subcriptionClient) {
        await subcriptionClientContext?.subcriptionClient.unsubscribeAll();
        subcriptionClientContext?.subcriptionClient.close();
      }

      // Close list comment
      await toogleShowCommentFrom({
        variables: { input: false },
      });

      // Close list assign treatment on sidebar
      await toogleShowSubSideBar({ variables: { input: { status: false } } });

      // Remove list treatment show on sidebar
      await addTreatmentSideBarShow({ variables: { input: null } });

      // Update me as anonymous
      await handleUpdateMeClient(null);

      // Remove assign treatment on sidebar
      await addSubTreatmentsSideBar({
        variables: {
          subTreatments: null,
        },
      });

      // Clear all cookies, including domain and path-specific cookies
      clearAllCookies();

      // Once all operations are completed, redirect
      if (redirectUrl) {
        history.push(redirectUrl);
      }

      // Add a new user welcome message if needed
      chatbotDrawerContext?.setMessages([
        {
          text:
            "Welcome to TGPS Assistant, your go-to guide for instant answers! 🌟 I'm here to help. Dive in and ask away",
          type: 'user',
        },
      ]);
    },
  });

  // Function to clear all cookies, including secure cookies
  const clearAllCookies = () => {
    const cookies = document.cookie.split('; ');
    cookies.forEach(cookie => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

      // Delete cookie for the specified domain and path
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=api-stag.treatmentgps.com;`;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=api-stag.treatmentgps.com;secure;`;

      // Attempt to clear cookies for subdomains as well
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.api-stag.treatmentgps.com;`;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.api-stag.treatmentgps.com;secure;`;

      document.cookie = `access-token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=api-stag.treatmentgps.com;secure;`;
      document.cookie = `refresh-token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=api-stag.treatmentgps.com;secure;`;
    });

    const deleteCookie = (name: string, path: string, domain: string) => {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=${path};domain=${domain};`;
    };

    // Delete access-token and refresh-token cookies
    deleteCookie('access-token', '/', 'api-stag.treatmentgps.com');
    deleteCookie('refresh-token', '/', 'api-stag.treatmentgps.com');
  };

  const handleClose = () => {
    setOpenModal('');
    if (close) {
      close();
    }
  };

  return (
    <>
      <DialogStyled
        open={open === 'qrCode'}
        onClose={handleClose}
        fullWidth={true}
        onExited={() => {
          popoverContext?.closePopover();
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gridGap={8}
          p="16px 16px 0px 16px"
        >
          <TypoThreeDot align="center" variant="h6">
            {nameTreatment}
          </TypoThreeDot>
          <div id="append-view-qrcode">
            <ButtonCopyLink
              hasElemAppend="append-view-qrcode"
              link={urlSharing as string}
            />
          </div>
        </Box>
        <DialogContentStyled style={{ padding: '10px 100px' }}>
          <WrapImageQrCode>
            <ViewQrCodeStyled src={link} />
          </WrapImageQrCode>
        </DialogContentStyled>
        <DialogActionStyled>
          {/* <a href={'#'} style={{ width: '100%' }} aria-label="print"> */}
          <ButtonLoading
            fullWidth
            Icon={<Logout />}
            text="Open"
            variant="contained"
            callbackClick={doLogout}
            loading={loading}
          />
          {/* </a> */}
          <a href={link} style={{ width: '100%' }} aria-label="print">
            <ButtonLoading
              fullWidth
              Icon={<PrintIcon />}
              text="Print"
              variant="contained"
            />
          </a>
        </DialogActionStyled>
      </DialogStyled>
    </>
  );
};
export default ViewQrCode;
