import React from 'react';
import { Controller, FormContextValues } from 'react-hook-form';
import { countryCode } from 'share/utils';
import PhoneInput from 'react-phone-input-2';
import { TypographyRedStyled, BoxStyled } from 'share/component_css';
import { FormData } from 'modules/profile/components/UpdateProfileForm';
import { FormDataUpdate } from 'modules/user/interfaces';

type Props = {
  methodsProfile?: FormContextValues<FormData>;
  methodsUser?: FormContextValues<FormDataUpdate>;
  phone?: string;
};

export const UpdatePhoneField: React.FC<Props> = ({
  methodsProfile,
  methodsUser,
  phone,
}) => {
  const optionCountryCode = countryCode();

  const methods = methodsUser ? methodsUser : methodsProfile;

  return (
    <BoxStyled
      error={methods!.errors!.phoneNumber ? 'true' : 'false'}
      className="mt-8 mb-8"
      required={phone ? 'true' : 'false'}
    >
      <Controller
        control={methods!.control}
        name="phoneNumber"
        defaultValue={phone ? phone : ''}
        rules={{
          validate: phone
            ? {
                required: value => value !== '' || 'This field is required',
                compare: value => value !== '1' || 'This field is required',
              }
            : {},
          pattern: optionCountryCode.pattern,
        }}
        as={
          <PhoneInput
            inputStyle={{
              color: 'black',
            }}
            country={'us'}
            onlyCountries={optionCountryCode.countryCodes}
            enableAreaCodeStretch
            containerClass="container-phone"
            autoFormat={true}
            specialLabel="Phone "
            disableDropdown={!(process.env.REACT_APP_HAS_VN_CODE === 'true')}
            countryCodeEditable={true}
          />
        }
      />
      {methods!.errors.phoneNumber && (
        <TypographyRedStyled variant="caption" display="block">
          {methods!.errors.phoneNumber.message}
        </TypographyRedStyled>
      )}
    </BoxStyled>
  );
};
export default UpdatePhoneField;
