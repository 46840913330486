import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Collapse } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Thumbnail, SurveyDisplay } from 'components';
import { TreatmentFragment, SubTreatmentFragment } from 'types.d';
import { PaperStyled } from '../styles';
import {
  LinkRouterStyled,
  TypographyUnderline,
  MediaStyled,
  GridSurveyStyled,
} from 'share/component_css';
import { sortTreatment } from 'share/utils';
import CarouselSubTreatment from './CarouselSubTreatment';
import { useCustomPermission } from 'hooks';
import PopoverComponent from './PopoverComponent';

type Props = {
  data?: TreatmentFragment | SubTreatmentFragment | null;
  playing?: boolean;
  displayDes?: boolean;
  isSubHightLight?: boolean;
  index: number;
};

export const CardComponent: React.FC<Props> = ({
  data,
  playing,
  displayDes,
  isSubHightLight,
  index,
}) => {
  const [isDisplayDes, setIsDisplayDes] = useState(false);

  const { isAdmin, isNavigator } = useCustomPermission();

  const location = useLocation();

  const isSubTreatment = (
    treatment: TreatmentFragment | SubTreatmentFragment,
  ): treatment is SubTreatmentFragment => {
    return (treatment as SubTreatmentFragment).order !== undefined;
  };

  useEffect(() => {
    setIsDisplayDes(!!displayDes);
  }, [displayDes]);

  const linkDetail = (slug: string, id?: string) => {
    if (slug) {
      return `/treatment/${slug}/`;
    }
    const path = location.pathname.split('/').filter(Boolean);
    return `/${path[0]}/treatment/${id}/`;
  };

  return (
    <>
      <PaperStyled ishightlight={isSubHightLight ? 'true' : 'false'}>
        <Grid container spacing={2}>
          <Grid xs={12} sm={7} md={8} item>
            <Box display="flex" alignItems="center" className="mb-8">
              <TypographyUnderline
                onMouseEnter={() => setIsDisplayDes(true)}
                onMouseLeave={() => setIsDisplayDes(false)}
                variantMapping={{
                  h5: 'h3',
                }}
                className="font-weight-bold"
                variant="h5"
              >
                <LinkRouterStyled
                  to={`${linkDetail(data?.slug || '', data?._id)}`}
                >
                  {data!.name}
                </LinkRouterStyled>
              </TypographyUnderline>
              {(isAdmin || isNavigator) && (
                <PopoverComponent
                  treatmentSelected={data}
                  qrCode={data?.qrCode as string}
                  urlSharing={data?.urlSharing as string}
                />
              )}
            </Box>
            <Typography color="textSecondary" variant="body1" className="mb-8">
              {data!.shortDescription!}
            </Typography>
            <Collapse in={isDisplayDes} timeout={300} unmountOnExit>
              <Typography color="textSecondary" variant="body1">
                {data!.description!}
              </Typography>
            </Collapse>
          </Grid>
          <Grid xs={12} sm={5} md={4} item>
            <LinkRouterStyled to={`${linkDetail(data?.slug || '', data?._id)}`}>
              <Box style={{ pointerEvents: 'none' }}>
                <MediaStyled>
                  <Thumbnail
                    mainVideo={data!.mainVideo!}
                    mainImage={data!.mainImage!}
                    id={data!._id}
                    index={index}
                    thumbnail={data!.thumbnail!}
                  />
                </MediaStyled>
              </Box>
            </LinkRouterStyled>
          </Grid>
          {data && !isSubTreatment(data) && data?.subSections?.length && (
            <Grid item xs={12}>
              <CarouselSubTreatment subTreatments={data?.subSections} />
            </Grid>
          )}
          {data!.isSurveyInline && data?.survey && data?.survey.length !== 0 && (
            <GridSurveyStyled item xs={12}>
              {sortTreatment(data?.survey).map(item => {
                return (
                  <SurveyDisplay
                    key={item.idSurvey}
                    idSurvey={item.idSurvey}
                    surveyData={item.surveyData as string}
                  />
                );
              })}
            </GridSurveyStyled>
          )}
        </Grid>
      </PaperStyled>
    </>
  );
};
export default CardComponent;
