import React, { useContext, useState, useCallback } from 'react';
import {
  DialogContent,
  Dialog,
  Typography,
  Box,
  Checkbox,
  TextField,
  CircularProgress,
  DialogActions,
} from '@material-ui/core';
import { DialogTitleClose } from 'components';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  CommonTreatmentFragment,
  GetCommonDataQuery,
  GetCommonDataQueryVariables,
  TreatmentType,
} from 'types.d';
import { GET_COMMON_DATA } from 'gql';
import { useEffectOnlyOnce } from 'hooks';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import DialogButton from 'components/DialogButton';
import { LayoutContext } from 'share/context';
import { getLocalStorage, setDataLocalStorage } from 'share/utils';
import { CONTEXT_TREATMENTS, SHOW_FORMS } from 'CONST';

type Props = {
  open: boolean;
  toogleDialog: (status: boolean) => void;
  setTreatmentContextFilteredIds: React.Dispatch<
    React.SetStateAction<string[]>
  >;
};

const ContextSettingsModal: React.FC<Props> = ({
  open,
  toogleDialog,
  setTreatmentContextFilteredIds,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const layoutContext = useContext(LayoutContext);

  const [selectedTreatments, setSelectedTreatments] = useState<
    CommonTreatmentFragment[]
  >([]);
  const [currentTreatment, setCurrentTreatment] = useState(false);
  const [showForms, setShowForms] = useState(false);

  const defaultParamsTreatment = {
    type: [TreatmentType.Treatment],
    sortByOrder: { name: 1 },
  };

  const [
    getListTreatments,
    { loading: loadingListTreatments, data: dataListTreatments },
  ] = useLazyQuery<GetCommonDataQuery, GetCommonDataQueryVariables>(
    GET_COMMON_DATA,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        const treatmentIds = getLocalStorage(CONTEXT_TREATMENTS)?.split(',');
        if (treatmentIds?.length) {
          const filteredTreatments =
            data.getTreatments?.nodes.filter(treatment =>
              treatmentIds.includes(treatment._id),
            ) || [];
          setSelectedTreatments(filteredTreatments);
        }
      },
    },
  );

  useEffectOnlyOnce(() => {
    getListTreatments({ variables: { params: defaultParamsTreatment } });
  });

  const handleCancel = useCallback(() => toogleDialog(false), [toogleDialog]);

  const handleSave = useCallback(() => {
    const treatmentIds = currentTreatment
      ? [layoutContext?.openedTreatment._id]
      : selectedTreatments.map(item => item._id);

    setDataLocalStorage(SHOW_FORMS, `${showForms}`);
    setDataLocalStorage(CONTEXT_TREATMENTS, treatmentIds.join(','));
    setTreatmentContextFilteredIds(treatmentIds);
    handleCancel();
  }, [
    showForms,
    currentTreatment,
    layoutContext,
    selectedTreatments,
    setTreatmentContextFilteredIds,
    handleCancel,
  ]);

  const handleSelectCurrentTreatment = (checked: boolean) => {
    setCurrentTreatment(checked);
  };

  const handleShowForms = (checked: boolean) => {
    setShowForms(checked);
  };

  const onEnterDialog = () => {
    const treatmentIds = getLocalStorage(CONTEXT_TREATMENTS)?.split(',');
    const isForms = Boolean(JSON.parse(getLocalStorage(SHOW_FORMS)! as string));

    setShowForms(isForms);

    if (
      treatmentIds?.length === 1 &&
      treatmentIds[0] === layoutContext?.openedTreatment._id
    ) {
      setCurrentTreatment(true);
    }
  };

  return (
    <Dialog onEntering={onEnterDialog} open={open} maxWidth="sm" fullWidth>
      <DialogTitleClose title="Chatbot Configurations" onClose={handleCancel} />
      <DialogContent>
        <Box display="flex" alignItems="center">
          <Checkbox
            onChange={(e, checked) => handleShowForms(checked)}
            className="mr-8"
            checked={showForms}
          />
          Show Forms
        </Box>
        {layoutContext?.openedTreatment._id && (
          <Box display="flex" alignItems="center">
            <Checkbox
              onChange={(e, checked) => handleSelectCurrentTreatment(checked)}
              className="mr-8"
              checked={currentTreatment}
            />
            Search on current treatment
          </Box>
        )}
        <Autocomplete
          value={selectedTreatments}
          className="w-100 mt-16"
          multiple
          onChange={(event, newValue) => {
            if (newValue.length <= 10) {
              setSelectedTreatments(newValue);
            } else {
              enqueueSnackbar('Please input no more than 10 items', {
                variant: 'error',
              });
            }
          }}
          disableCloseOnSelect
          filterOptions={createFilterOptions({
            matchFrom: 'any',
            ignoreCase: true,
            ignoreAccents: true,
            stringify: (option: CommonTreatmentFragment) => option.name || '',
          })}
          options={dataListTreatments?.getTreatments?.nodes || []}
          getOptionLabel={option => option.name || ''}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                className="mr-8"
                checked={selected}
              />
              <Typography variant="subtitle1">{option.name}</Typography>
            </>
          )}
          disabled={loadingListTreatments || currentTreatment}
          loading={loadingListTreatments}
          renderInput={params => (
            <TextField
              {...params}
              size="small"
              fullWidth
              label="Treatments"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingListTreatments && (
                      <CircularProgress color="inherit" size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <DialogButton isCancel onClickButton={handleCancel} />
        <DialogButton onClickButton={handleSave} />
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ContextSettingsModal);
