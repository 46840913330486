import React from 'react';
import { ButtonLoading } from 'components';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import FilterListIcon from '@material-ui/icons/FilterList';

type Props = {
  loading?: boolean;
  resetFilter?: () => void;
  callBackClick: (event: React.FormEvent<HTMLFormElement>) => void;
};

export const GroupButtonFilter: React.FC<Props> = ({
  loading,
  resetFilter,
  callBackClick,
}) => {
  return (
    <>
      <ButtonLoading
        className="button-fit-input w-100"
        text="Render View"
        isSubmit
        variant="contained"
        Icon={<FilterListIcon />}
        callbackClick={e => callBackClick(e)}
        loading={loading}
      />
      <ButtonLoading
        disabled={loading}
        className="button-fit-input w-100"
        text="Reset"
        variant="contained"
        Icon={<RotateLeftIcon />}
        callbackClick={resetFilter}
      />
    </>
  );
};

export default GroupButtonFilter;
