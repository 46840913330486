import gql from 'graphql-tag';

export const AGGREGATE_USER = gql`
  query AggregateUser($params: AggregateInput!) {
    aggregateUser(params: $params) {
      name
      value
    }
  }
`;

export const AGGREGATE_TREATMENT = gql`
  query AggregateTreatment($params: AggregateInput!) {
    aggregateTreatment(params: $params) {
      name
      value
    }
  }
`;

export const AGGREGATE_COMMENT = gql`
  query AggregateComment($params: AggregateInput!) {
    aggregateComment(params: $params) {
      name
      value
      valueOfReply
    }
  }
`;
export const AGGREGATE_SURVEY_RESULT = gql`
  query AggregateSurveyResult($params: AggregateInput!) {
    aggregateSurveyResult(params: $params) {
      name
      fromEmail
      fromSMS
    }
  }
`;
