import React, { useContext, useMemo, useRef } from 'react';
import {
  PatientContext,
  PatientDetailContext,
  ContentPatientContext,
} from 'share/context';
import {
  SurveyPatientDashboardFragment,
  SurveyResultOnPatientFragment,
} from 'types.d';
import {
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Grid,
  IconButton,
} from '@material-ui/core';
import {
  formatDate,
  renderTitleAnswer,
  convertArrayToObject,
  getAllQuestion,
} from 'share/utils';
import EditIcon from '@material-ui/icons/Edit';
import { ElementsPageInterface } from 'modules/dashboard/interfaces';
import DialogCustomQuestion from '../DialogCustomQuestion';
import { DataEditSurveyResultInterface } from '../../interfaces';
import { useToogleDialog } from 'hooks';
import { NoDataTable } from 'components';
import { PaperBorder } from 'share/component_css';

type Props = {};

export const SurveyResponse: React.FC<Props> = () => {
  const dataEditSurveyResult = useRef<
    DataEditSurveyResultInterface | undefined
  >(undefined);
  const patientContext = useContext(PatientContext);

  const patientDetailContext = useContext(PatientDetailContext);

  const contentPatientContext = useContext(ContentPatientContext);

  const [isOpen, toogleDialog] = useToogleDialog();

  const questions = useMemo(() => {
    return (
      (contentPatientContext?.dataFilterForm as {
        [key: string]: any;
      }[]).filter(item => item.question) || []
    );
  }, [contentPatientContext]);

  const surveyResult = useMemo(() => {
    return patientDetailContext?.patient.data || [];
  }, [patientDetailContext]);

  const surveys: {
    [key: string]: SurveyPatientDashboardFragment & {
      questions: { [key: string]: ElementsPageInterface };
    };
  } = useMemo(() => {
    return patientContext?.surveys
      ? convertArrayToObject(
          patientContext?.surveys
            .filter(survey => {
              return questions?.map(item => item.survey).includes(survey._id);
            })
            .map(survey => {
              return {
                ...survey,
                questions: convertArrayToObject(
                  getAllQuestion(survey.surveyData as string),
                  'name',
                ),
              };
            }),
          '_id',
        )
      : [];
  }, [patientContext, questions]);

  const getQuestion = (questionName: string, survey: string) => {
    if (surveys) {
      const question = surveys[survey].questions[questionName];

      return { questionName: question ? question.title ?? question.name : '' };
    }
    return undefined;
  };

  const responses = useMemo(() => {
    if (surveyResult && questions) {
      return surveyResult.filter(item => {
        return (
          questions
            .map(item => {
              return item.survey;
            })
            .includes(item.survey) && Object.keys(item).length !== 0
        );
      });
    }
    return [];
  }, [questions, surveyResult]);

  const getResponse = (
    question: string,
    response: SurveyResultOnPatientFragment,
  ) => {
    const questionDetail = surveys[response.survey].questions[question];
    return response?.result && response.result[question] !== undefined
      ? renderTitleAnswer(response.result, questionDetail) ?? 'N/A'
      : 'N/A';
  };

  const editSurveyResult = (
    response: SurveyResultOnPatientFragment,
    question: string,
    version: number,
  ) => {
    toogleDialog(true);
    dataEditSurveyResult.current = {
      response,
      question,
      indexResponse:
        surveyResult?.findIndex(item => item._id === response._id) ?? 0,
      version,
    };
  };
  return (
    <>
      <DialogCustomQuestion
        detailSurveyResult={dataEditSurveyResult.current}
        idSurvey={dataEditSurveyResult.current?.response.survey}
        open={isOpen}
        toogleDialog={toogleDialog}
        surveyName={
          surveys[dataEditSurveyResult.current?.response.survey as string]
            ?.name || ''
        }
      />
      <Grid item xs={12}>
        <TableContainer component={PaperBorder}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Survey</TableCell>
                <TableCell>Question</TableCell>
                <TableCell>Response</TableCell>
                <TableCell>Execution Date</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(responses?.length === 0 || questions?.length === 0) && (
                <NoDataTable colSpan={5} />
              )}
              {questions?.map((question, index) => {
                return responses
                  .filter(item => item.survey === question.survey)
                  .map(response => {
                    return (
                      <TableRow key={`${response?._id}${question.question}`}>
                        <TableCell>{surveys[question.survey].name}</TableCell>
                        <TableCell>
                          {
                            getQuestion(question.question, question.survey)
                              ?.questionName
                          }
                        </TableCell>
                        <TableCell>
                          {getResponse(question.question, response)}
                        </TableCell>
                        <TableCell>
                          {formatDate(response.createdAt, true)}
                        </TableCell>

                        <TableCell align="right">
                          <IconButton
                            onClick={() =>
                              editSurveyResult(
                                response,
                                question.question,
                                patientContext?.surveys?.find(
                                  item => item._id === response.survey,
                                )?.version as number,
                              )
                            }
                            color="primary"
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  });
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default React.memo(SurveyResponse);
