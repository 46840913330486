import { useQuery } from '@apollo/react-hooks';
import { ME_CLIENT } from 'gql';
import { UserRole } from 'types.d';
export const useCustomPermission = () => {
  const { data: dataMeClient } = useQuery(ME_CLIENT);
  const isSuperAdmin = Object.is(
    dataMeClient?.meClient.role,
    UserRole.SuperAdmin,
  );
  const isNavigator = Object.is(
    dataMeClient?.meClient.role,
    UserRole.Navigator,
  );
  const isUser = Object.is(dataMeClient?.meClient.role, UserRole.User);
  const isAdmin = Object.is(dataMeClient?.meClient.role, UserRole.Admin);
  const isPatient = dataMeClient?.meClient.patient !== '';

  const idMe = dataMeClient?.meClient._id;

  const isOwner = (owner: string) =>
    Object.is(dataMeClient?.meClient._id, owner);

  const isContributor = (contributors: string[] = []) => {
    if (contributors) {
      return contributors.includes(dataMeClient?.meClient._id);
    }
    return false;
  };

  const isOwnerAndContributors = (listPerson: any[]) => {
    if (listPerson) {
      return listPerson.includes(dataMeClient?.meClient._id);
    }
    return false;
  };

  const checkUserForMenu = (type: string) => {
    if (dataMeClient.meClient?.role) {
      var index = dataMeClient.meClient?.setting?.leftDrawer?.findIndex(
        (ele: any) => ele.treatmentType == type && ele.showInDrawer == true,
      );
      return index > -1 ? true : false;
    } else {
      return false;
    }
  };

  return {
    checkUserForMenu,
    isOwnerAndContributors,
    isAdmin,
    isOwner,
    isContributor,
    isNavigator,
    isUser,
    idMe,
    isSuperAdmin,
    isPatient,
  };
};
