import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';

type Props = {
  skeletonNumber?: number;
};

export const LoadingNotification: React.FC<Props> = ({
  skeletonNumber = 1,
}) => {
  const renderSkeleton = () => {
    const arrElement: any = [];
    for (let i = 0; i < skeletonNumber && i < 3; i++) {
      arrElement.push(
        <ListItem key={i}>
          <ListItemAvatar>
            <Skeleton
              animation="wave"
              variant="circle"
              width={40}
              height={40}
            />
          </ListItemAvatar>
          <ListItemText>
            <Skeleton animation="wave" height={20} width="100%" />
            <Skeleton animation="wave" height={20} width="20%" />
          </ListItemText>
        </ListItem>,
      );
    }
    return arrElement;
  };
  return <>{renderSkeleton()}</>;
};

export default LoadingNotification;
