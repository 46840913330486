import React, { useContext, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  DialogTitle,
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import ArrowBack from '@material-ui/icons/ArrowBack';
import MediaTreatment from 'components/MediaTreatment';
import GridSubTreatment from 'modules/treatments/components/GridSubTreatment';
import { GridMarkDownStyled } from 'modules/treatments/styles';
import ReactMarkdown from 'react-markdown';
import {
  GridSurveyStyled,
  LinkRouterStyled,
  MediaStyled,
} from 'share/component_css';
import { styled } from '@material-ui/core/styles';
import { LayoutContext } from 'share/context';
import {
  GetDataDetailTreatmentQuery,
  GetDataDetailTreatmentQueryVariables,
  GetTreatmentPDFQuery,
  GetTreatmentPDFQueryVariables,
} from 'types.d';
import { GET_DATA_DETAIL_TREATMENT, GET_TREEATMENT_PDF } from 'gql';
import { useLazyQuery } from '@apollo/react-hooks';
import { ButtonLoading, LoadingTreatment } from 'components';
import { renderPDFLink, sortTreatment } from 'share/utils';
import SurveyDisplay from 'components/SurveyDisplay';
import SocialShare from 'modules/treatments/components/SocialShare';
import GetAppIcon from '@material-ui/icons/GetApp';
import Speak from 'modules/treatments/components/Speak';

const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  '& .MuiTypography-h6': {
    width: '100%',
  },
}));

const IconStyled = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
}));

const ArrowBackStyled = styled(ArrowBack)(({ theme }) => ({
  cursor: 'pointer',
}));

type Props = {
  quickView: boolean;
  setQuickView: any;
  chatbot?: boolean;
  slug?: string;
};

export const QuickViewModal: React.FC<Props> = ({
  quickView,
  setQuickView,
  chatbot,
  slug,
}) => {
  const layoutContext = useContext(LayoutContext);

  const [highlightedIndex, setHighlightedIndex] = useState<number>();

  const [textWords, setTextWords] = useState<string>('');

  const handleClose = () => {
    layoutContext?.setQuickViewCards([]);
    setQuickView(false);
  };

  const handleBack = () => {
    const updatedQuickViewCards = [...layoutContext?.quickViewCards];
    updatedQuickViewCards.pop();
    layoutContext?.setQuickViewCards(updatedQuickViewCards);
  };

  const [loadTreatment, { data, error, loading }] = useLazyQuery<
    GetDataDetailTreatmentQuery,
    GetDataDetailTreatmentQueryVariables
  >(GET_DATA_DETAIL_TREATMENT, {
    fetchPolicy: 'cache-and-network',
  });

  const [exportPDF, { loading: loadingExport }] = useLazyQuery<
    GetTreatmentPDFQuery,
    GetTreatmentPDFQueryVariables
  >(GET_TREEATMENT_PDF, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data?.getTreatmentPDF) {
        window.open(data.getTreatmentPDF, '_blank');
      }
    },
  });

  useEffect(() => {
    if (slug !== '') {
      loadTreatment({
        variables: {
          paramsDetail: {
            slug: slug,
            isQuickView: true,
          },
        },
      });
    }
  }, [slug]);

  const handleExportPDF = () => {
    const treatmentPDFExists = data?.getTreatment?.pdfLink;

    if (treatmentPDFExists) {
      window.open(renderPDFLink(data!.getTreatment!.pdfLink!), '_blank');
    } else {
      exportPDF({
        variables: {
          params: {
            _id: data?.getTreatment?._id,
          },
        },
      });
    }
  };

  return (
    <Dialog open={quickView} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitleStyled style={{ width: '100%' }}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box display={'flex'} alignItems={'center'}>
            {layoutContext?.quickViewCards.length > 1 && (
              <ArrowBackStyled onClick={handleBack} />
            )}
            <Box marginLeft={layoutContext?.quickViewCards.length > 1 ? 1 : 0}>
              <Typography variant="h6">{data?.getTreatment?.name}</Typography>
            </Box>
          </Box>
          <IconStyled onClick={handleClose}>
            <CloseOutlined />
          </IconStyled>
        </Box>
      </DialogTitleStyled>
      <DialogContent tabIndex={0}>
        {(!loading && data) ? (
          <>
            <Box mb={2}>
              <Typography color="textSecondary" variant="body1">
                {data?.getTreatment?.description}
              </Typography>
            </Box>
            <Box display="flex" justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} mb={2}>
              <SocialShare data={data!.getTreatment!} />
              <Speak data={data!.getTreatment!}
                setHighlightedIndex={setHighlightedIndex}
                textWords={textWords}
                setTextWords={setTextWords}
              />
            </Box>
            <MediaStyled>
              <MediaTreatment
                mainVideo={data?.getTreatment?.mainVideo!}
                mainImage={data?.getTreatment?.mainImage!}
              />
            </MediaStyled>
            <GridMarkDownStyled
              xs={12}
              item
              style={{ margin: '0', padding: '0' }}
            >
              <ReactMarkdown
                linkTarget="_blank"
                source={
                  data?.getTreatment?.content ? data?.getTreatment?.content : ''
                }
              />
            </GridMarkDownStyled>
            {data?.getTreatment?.survey?.length && (
              <GridSurveyStyled xs={12} item>
                {sortTreatment(data?.getTreatment?.survey).map(
                  item =>
                    !item.isPrivate && (
                      <SurveyDisplay
                        key={item.idSurvey}
                        idSurvey={item.idSurvey}
                        surveyData={item.surveyData as string}
                      />
                    ),
                )}
              </GridSurveyStyled>
            )}
            {data?.getTreatment?.subSections && (
              <>
                {data?.getTreatment?.subSections.length > 0 && (
                  <Box mb={2}>
                    <Typography variant="h6">Further Reading</Typography>
                  </Box>
                )}
                <GridSubTreatment
                  subTreatments={data?.getTreatment?.subSections}
                />
              </>
            )}
          </>
        ) : (
          <Box height={'80vh'}>
            <LoadingTreatment />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <LinkRouterStyled
          className={chatbot ? 'rltd' : ''}
          to={`/treatment/${data?.getTreatment?.slug}`}
        >
          <Button variant="contained" color="primary">
            View Full Article
          </Button>
        </LinkRouterStyled>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(QuickViewModal);
