import React, { useCallback, useRef, useContext, useMemo } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Box } from '@material-ui/core';
import { PatientTimeRecordFragment } from 'types.d';
import { ChipStyled } from 'share/component_css';
import { useTimeRecord, useToogleDialog } from 'hooks';
import { ScrollStyled } from 'components';
import BoxShowMore from './BoxShowMore';
import { PatientContext, PatientTagsContext } from 'share/context';
import DialogAddTimeRecord from '../TimeRecord/DialogAddTimeRecord';
import LoadingTags from './LoadingTags';
import ReportTag from '../TimeRecord/ReportTag';

type Props = {
  loadingDataGroup: boolean;
  records?: PatientTimeRecordFragment[];
};

export const TimeRecordsTag: React.FC<Props> = ({
  loadingDataGroup,
  records,
}) => {
  const record = useRef<PatientTimeRecordFragment | undefined>(undefined);

  const patientTagsContext = useContext(PatientTagsContext);

  const patientContext = useContext(PatientContext);

  const [openDialogAddRecord, setOpenDialogAddRecord] = useToogleDialog();
  const { getLastRecord } = useTimeRecord();
  //check survey tab is opened
  const isOnTab = useMemo(() => {
    return patientTagsContext?.isExtend && patientTagsContext?.indexTab === 4;
  }, [patientTagsContext]);

  const openAddDialog = useCallback(() => {
    //open dialog from tab
    if (isOnTab) {
      patientTagsContext?.handleSetDialogOpen('add-record');
      return;
    }
    //open dialog from tag
    setOpenDialogAddRecord(true);
    record.current = undefined;
  }, [isOnTab, patientTagsContext, setOpenDialogAddRecord]);

  const openDialogRecord = (item: PatientTimeRecordFragment) => {
    //stop dialog from tab
    if (isOnTab) {
      patientTagsContext?.handleSetDialogOpen('stop-record', item);
      return;
    }
    record.current = item;
    setOpenDialogAddRecord(true);
  };

  return (
    <>
      {openDialogAddRecord && (
        <DialogAddTimeRecord
          openDialogAddRecord={openDialogAddRecord}
          setOpenDialogAddRecord={setOpenDialogAddRecord}
          recordSelected={getLastRecord(record.current)}
          idCptCode={record.current?._id}
        />
      )}

      {!patientTagsContext?.isInactive && (
        <ChipStyled
          colortext="true"
          className="mr-8"
          onClick={openAddDialog}
          disabled={patientContext?.timer?.seconds !== 0}
          label={
            <Box display="flex">
              <AddIcon fontSize="small" />
            </Box>
          }
        />
      )}
      {records?.length !== 0 && <BoxShowMore index={4} />}
      {loadingDataGroup && <LoadingTags />}
      {records && records.length > 0 && (
        <ScrollStyled loading={false} listLength={records?.length}>
          {records.map(item => {
            return (
              <ReportTag
                key={item?._id}
                item={item}
                openDialogRecord={openDialogRecord}
                record={record}
              />
            );
          })}
        </ScrollStyled>
      )}
    </>
  );
};

export default React.memo(TimeRecordsTag);
