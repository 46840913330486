import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import RouterComponent from './routers';
import { ME } from 'gql';
import { Loading, DialogNewFeatures } from './components';
import {
  useToogleDialog,
  useUpdateMeClient,
  useEffectOnlyOnce,
  useAdingTreatmentSidebars,
  useCheckLogin,
  useCustomPermission,
} from 'hooks';
import { MeQueryVariables, MeQuery } from 'types.d';
import * as serviceWorker from './serviceWorker';
import { useSnackbar } from 'notistack';
import { Button } from '@material-ui/core';
import { useUpdateHomepageClient } from 'hooks/useUpdateHomepage';
import {
  getLocalStorage,
  getSessionStorage,
  removeSessionStorage,
  setDataLocalStorage,
  setDataSessionStorage,
} from 'share/utils';
import {
  DEFAULT_VOICE_SETTINGS,
  KEY_LOCAL_ASSIGNED_SURVEYS,
  KEY_SESSION_ORGANIZATION_ID,
  ORG_LOGO,
} from 'CONST';
import { GET_HOMEPAGE } from 'gql/server/homepage';
import { useLocation } from 'react-router-dom';
import {
  useZoomLevelTracking,
  initializeTagManager,
  usePageTracking,
  organizationEvent,
} from 'modules/google_tag_manager';
import { useHandlePathExists } from 'hooks/useHandlePathExists';

const App: React.FC = () => {
  const location = useLocation();

  const pathExists = useHandlePathExists();

  const [loaded, setLoaded] = useState(false);

  const [homepageLoaded, setHomepageLoaded] = useState(false);

  const { meClient, handleUpdateMeClient } = useUpdateMeClient();

  const isLogin = useCheckLogin();

  const { handleUpdateHomepageClient } = useUpdateHomepageClient();

  const [openNewFeatures, setOpenNewFeatures] = useToogleDialog();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const waitingWorker = useRef<ServiceWorker | null>(null);

  const { getSubTreaments } = useAdingTreatmentSidebars();

  const remoteUser = JSON.parse(getLocalStorage('remoteUser')!);

  const isSuperAdmin = useCustomPermission();

  const { isAdmin } = useCustomPermission();

  var default_voice_settings = JSON.parse(
    getLocalStorage(DEFAULT_VOICE_SETTINGS)!,
  );

  const refreshAction = useCallback(() => {
    //render the snackbar button
    return (
      <Button
        className="snackbar-button"
        size="small"
        onClick={() => {
          setOpenNewFeatures(true);
          closeSnackbar();
        }}
      >
        Show more
      </Button>
    );
  }, [closeSnackbar, setOpenNewFeatures]);

  useEffectOnlyOnce(() => {
    getSubTreaments();
    if (default_voice_settings == null || default_voice_settings == 'null')
      setDataLocalStorage(
        DEFAULT_VOICE_SETTINGS,
        JSON.stringify({ rate: 1, pitch: 1, voice: 0 }),
      );
  });

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      serviceWorker.register({
        onUpdate: registration => {
          if (registration) {
            waitingWorker.current = registration.waiting;
            //show snackbar with refresh button
            enqueueSnackbar('A new version was released', {
              persist: true,
              variant: 'success',
              action: refreshAction(),
            });
          }
        },
      });
    }
  }, [enqueueSnackbar, refreshAction]);

  useQuery<MeQuery, MeQueryVariables>(ME, {
    onCompleted(data) {
      setLoaded(true);
      handleUpdateMeClient(
        data?.me?.remoteUser ? data?.me?.remoteUser : data.me,
      );
      setDataLocalStorage(
        'remoteUser',
        JSON.stringify(data?.me?.remoteUser || null),
      );
    },
    fetchPolicy: 'cache-and-network',
  });

  const [getHomepage] = useLazyQuery(GET_HOMEPAGE, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setHomepageLoaded(true);

      handleUpdateHomepageClient(data);

      if (data && data?.getHomePageConfig?.organization.name) {
        var obj: any = {
          id: data?.getHomePageConfig?.organization._id,
          name: data?.getHomePageConfig?.organization.name,
        };
        if (isLogin) {
          var middleName = meClient?.middleName ? meClient?.middleName : '';
          var lastName = meClient?.lastName! ? meClient?.lastName : '';
          obj['userId'] = meClient?._id;
          obj['userName'] =
            meClient?.firstName + ' ' + middleName + ' ' + lastName;
        }
        organizationEvent(obj);
      }
      if (
        (!isLogin && data && data?.getHomePageConfig?.organization._id) ||
        (isLogin && isAdmin)
      ) {
        setDataSessionStorage(
          KEY_SESSION_ORGANIZATION_ID,
          data?.getHomePageConfig?.organization._id,
        );
      }
      if (data?.getHomePageConfig?.logoURL)
        setDataLocalStorage(ORG_LOGO, data?.getHomePageConfig?.logoURL);

      assignedSurveys();
    },
    onError() {
      setHomepageLoaded(true);
    },
  });

  const retrieveHomepage = () => {
    const isTreatmentRoute = location.pathname.split('/')[1] === 'treatment';

    if (!isTreatmentRoute) {
      getHomepage({
        variables: {
          params: !pathExists
            ? {
              slug: location.pathname.split('/')[1],
            }
            : {
              organization:
                isSuperAdmin && remoteUser
                  ? remoteUser?.organization?.idOrg
                  : getSessionStorage(KEY_SESSION_ORGANIZATION_ID)
                    ? getSessionStorage(KEY_SESSION_ORGANIZATION_ID)
                    : meClient?.organizationId.length > 0
                      ? meClient?.organizationId
                      : null,
            },
        },
      });
    } else {
      setHomepageLoaded(true);
    }
  };

  useEffect(() => {
    retrieveHomepage();
  }, [meClient]);

  function assignedSurveys() {
    if (isLogin) {
      var assignedSurveys = JSON.parse(
        getLocalStorage(KEY_LOCAL_ASSIGNED_SURVEYS)!,
      );
      removeSessionStorage(KEY_LOCAL_ASSIGNED_SURVEYS);
    } else {
      var assignedSurveys = JSON.parse(
        getSessionStorage(KEY_LOCAL_ASSIGNED_SURVEYS)!,
      );
    }
    if (assignedSurveys != null && assignedSurveys.length > 0) {
      assignedSurveys.filter((survey: any) => {
        if (new Date() < new Date(survey.expiredDate)) return survey;
      });
      if (isLogin) {
        setDataLocalStorage(
          KEY_LOCAL_ASSIGNED_SURVEYS,
          JSON.stringify(assignedSurveys),
        );
      } else {
        setDataSessionStorage(
          KEY_LOCAL_ASSIGNED_SURVEYS,
          JSON.stringify(assignedSurveys),
        );
      }
    }
  }

  const refresh = useCallback(() => {
    if (waitingWorker.current) {
      waitingWorker.current.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    }
  }, []);

  const cancel = useCallback(() => {
    setOpenNewFeatures(false);
  }, [setOpenNewFeatures]);

  initializeTagManager();
  usePageTracking();
  useZoomLevelTracking();
  return (
    <>
      <DialogNewFeatures
        open={openNewFeatures}
        refresh={refresh}
        cancel={cancel}
      />
      {loaded && homepageLoaded ? <RouterComponent /> : <Loading />}
    </>
  );
};

export default App;
