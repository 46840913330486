import React from 'react';
import {
  PatientFragment,
  RegimenConfigEmbedded,
  UserStatus
} from 'types.d';
import { Box, Tooltip, IconButton, Link } from '@material-ui/core';
import { useToogleDialog } from 'hooks';
import EventIcon from '@material-ui/icons/Event';
import DialogCalendar from 'modules/patients/components/Dialogs/DialogCalendar';
import DialogUpdateInfo from 'modules/patients/components/Dialogs/DialogUpdateInfo';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import { getDataOwner, isBotMail } from 'share/utils';
import { customEvent } from 'modules/google_tag_manager';
type Props = {
  patient: PatientFragment;
};

export type Regimen = RegimenConfigEmbedded & {
  days: number;
};

export const ActionPatientProcedures: React.FC<Props> = ({
  patient
}) => {
  const [isOpen, toogleDialog] = useToogleDialog();

  const [isOpenCalendar, toogleDialogCalendar] = useToogleDialog();

  return (
    <>
      {isOpen && (
        <DialogUpdateInfo
          open={isOpen}
          infoPatient={patient}
          toogleDialog={toogleDialog}
          idPatient={patient?._id}
        />
      )}
      {isOpenCalendar && (
        <DialogCalendar
          openDialog={isOpenCalendar}
          toogleDialog={toogleDialogCalendar}
          patientDetail={patient}
        />
      )}
      <Box display="flex" alignItems="center" justifyContent="end">
        {!isBotMail(patient?.owner?.email) && !(patient.owner?.user?.status === UserStatus.Inactive) && (
          <>
            <Tooltip title="Send mail" arrow>
              <Link
                aria-label='send email'
                onClick={() => customEvent("sendEmailToPatientOnPD", "NAVIGATOR")}
                href={`mailto:${getDataOwner(
                  'email',
                  patient?.owner,
                )}`}
                style={{ padding: "0px 3px 3px", height: 23 }}
              >
                <EmailIcon />
              </Link>
            </Tooltip>
          </>
        )}
        <Tooltip arrow title="Edit information">
          <IconButton
            size="small"
            color="primary"
            onClick={() => toogleDialog(true)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="View patient schedule">
          <IconButton
            size="small"
            color="primary"
            onClick={() => toogleDialogCalendar(true)}
          >
            <EventIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
};

export default React.memo(ActionPatientProcedures);
