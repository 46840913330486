import React, { useMemo } from 'react';
import { Popper, Typography, Box } from '@material-ui/core';
import { PASSWORD_RULE } from 'CONST';
import { CheckBold, CloseThick } from 'mdi-material-ui';
import { styled } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { PaperBorder } from 'share/component_css';

type Props = {
  anchorEl: HTMLInputElement | HTMLTextAreaElement | null;
  watchPassword: string;
};

const PopperStyled = styled(Popper)(({ theme }) => ({
  zIndex: 1500,
  '& .MuiPaper-elevation1': {
    padding: theme.spacing(2),
  },
}));

export const PopperRulePassword: React.FC<Props> = ({
  anchorEl,
  watchPassword,
}) => {
  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  const checkPassword = useMemo(() => {
    let rules = [];
    if (!watchPassword) {
      return;
    }
    // Use 8 or more characters
    if (watchPassword.length < 8) {
      rules.push(0);
    }
    // Include at least one lowercase (a-z) and one uppercase (A-Z)
    if (!/^(?=.*[a-z])(?=.*[A-Z])/.test(watchPassword)) {
      rules.push(1);
    }
    // Include at least one special character (e.g. !@#$&)
    if (!/[`~!@#$%^&*()_|+\-=?^()[\]\\.;:@"]/.test(watchPassword)) {
      rules.push(2);
    }
    // Does not contain blank spaces or the following special characters: <>,
    if (/[<>,\s]/.test(watchPassword)) {
      rules.push(3);
    }
    // Include at least one digit (0-9)
    if (!/\d/.test(watchPassword)) {
      rules.push(4);
    }
    return rules;
  }, [watchPassword]);

  return (
    <PopperStyled
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
    >
      <PaperBorder>
        <Typography className="mb-8" variant="subtitle2">
          Password must include the following:
        </Typography>
        {PASSWORD_RULE?.map(item => {
          return {
            ...item,
            iconPass: (
              <CheckBold fontSize="small" style={{ color: green[700] }} />
            ),
            iconFail: <CloseThick fontSize="small" color="secondary" />,
          };
        })?.map((item, index) => (
          <Box
            display="flex"
            alignItems="centers"
            gridGap={8}
            className="p-4"
            key={item.title}
          >
            {checkPassword?.includes(index) || !checkPassword ? (
              <>
                {item.iconFail}
                <Typography variant="subtitle2" color="secondary">
                  {item?.title}
                </Typography>
              </>
            ) : (
              <>
                {item.iconPass}
                <Typography variant="subtitle2" style={{ color: green[700] }}>
                  {item?.title}
                </Typography>
              </>
            )}
          </Box>
        ))}
      </PaperBorder>
    </PopperStyled>
  );
};
export default PopperRulePassword;
